import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";

import styles from "../../../styles/styles.module.scss";

const EntranceDoor = ({ door, selectedDoors, setSelectedDoors }) => {
  const history = useHistory();

  let checked = false;
  if (selectedDoors) {
    for (let i = 0; i < selectedDoors.length; i++) {
      if (selectedDoors[i] === door.id) {
        checked = true;
        break;
      }
    }
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.borderBottomGrayF4}`}>
      <div
        className={`${styles.ml10} ${styles.mr10} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
        onClick={() => {
          let _selectedDoors = [...selectedDoors];

          if (checked) {
            let idx = _selectedDoors.indexOf(door.id);
            if (idx > -1) {
              _selectedDoors.splice(idx, 1);
            }
          } else {
            _selectedDoors.push(door.id);
          }

          setSelectedDoors(_selectedDoors);
        }}
      >
        {checked && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite}`} style={{ flex: 8 }}>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{door.name}</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{door.relay === "true" || door.relay === "1" ? "잠금 해제" : "문 잠김"}</p>
        </div>
      </div>
    </div>
  );
};

export default EntranceDoor;
