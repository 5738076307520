import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const Machine = ({ machine, deleteMachine }) => {
  const history = useHistory();

  const date = new Date(Number(machine.createdAt));
  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{machine.username}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{machine.name}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {machine.status === "inuse" && "사용중"}
          {machine.status === "notuse" && "미사용"}
          {machine.status === "check" && "점검중"}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{numberWithCommas(machine.productTotal)}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{`${date.getFullYear()}/${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}/${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`}</p>
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: `/device/edit/`,
              state: {
                machine: machine.username,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
        </div>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
          onClick={() =>
            history.push({
              pathname: `/device/payment/statics/`,
              state: {
                machine: machine.username,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>판매 통계</p>
        </div>

        {/**
                <img
                    src={
                        require('../../../assets/images/icon_trash.png').default
                    }
                    alt={'삭제'}
                    className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml30}`}
                    onClick={deleteMachine}
                /> */}
      </div>
    </div>
  );
};

export default Machine;
