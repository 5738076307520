import React from "react";

import styles from "../../../styles/styles.module.scss";

const ExpireModal = () => {
  const handleCunsult = () => {
    window.open("https://pf.kakao.com/_jWexgK", "_blank");
  };

  return (
    <div className={`${styles.bgWhite} ${styles.textCenter} ${styles.widthFull}`}>
      <div>
        <p className={`${styles.header} ${styles.fontB} ${styles.font30} ${styles.mt5} ${styles.mb30}`}>로그인 오류</p>
        <div className={`${styles.widthFull} ${styles.borderNone} ${styles.font18} ${styles.mb50}`}>
          현재 이용료 미결제 등의 사유로
          <br />
          계정이 만료 상태입니다.
          <br />
          관리자에게 문의해주시길 바랍니다.
        </div>
        <button onClick={handleCunsult} className={`${styles.bgBlack} ${styles.borderRadius10} ${styles.widthFull} ${styles.height8} ${styles.font20} ${styles.white} ${styles.mb1}`}>
          문의 하기
        </button>
      </div>
    </div>
  );
};

export default ExpireModal;
