import React, {Component, useMemo} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class NoticeEditor extends Component{
    constructor(props){
        super(props);
    }

    formats = [
        //'font',
        'header', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'align', 'color', 'background',
    ]

    render(){
        const { value, onChange, _modules, _ref } = this.props;
        console.log('_modules: ', _modules);
        console.log('_ref: ', _ref);

        return(
            <div style={{height: "650px"}}>
                <ReactQuill
                    ref={_ref}
                    style={{height: "600px"}}
                    theme="snow"
                    modules={_modules}
                    formats={this.formats}
                    value={value || ''}
                    onChange={(content, delta, source, editor) => onChange(editor.getHTML())} />
            </div>
        )
    }
}
export default NoticeEditor
