import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import Modal from "react-modal";

import styles from "../../../styles/styles.module.scss";
import { bill } from "../../../common/modal";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";

const DETAIL = gql`
  query getLocker($machine: String!, $lockerId: Int!) {
    getLocker(machine: $machine, lockerId: $lockerId) {
      id
      lockerArea {
        id
        name
      }
      product {
        id
        name
        duration
      }
      member {
        id
        name
        mobile
      }
      number
      start
      end
      status
      description
    }
  }
`;

const PRODUCT = gql`
  query getProductsAll($machine: String!) {
    getProductsAll(type: "locker", machine: $machine) {
      id
      name
      duration
    }
  }
`;

const EDIT = gql`
  mutation editLocker($machine: String!, $lockerId: Int!, $start: String, $end: String, $status: String!, $productId: Int, $memberId: Int, $memberName: String, $memberMobile: String, $description: String) {
    editLocker(machine: $machine, lockerId: $lockerId, start: $start, end: $end, status: $status, productId: $productId, memberId: $memberId, memberName: $memberName, memberMobile: $memberMobile, description: $description) {
      locker {
        id
        lockerArea {
          id
          name
        }
        product {
          id
          name
        }
        member {
          id
          name
          mobile
        }
        number
        start
        end
        status
        description
      }
      error
    }
  }
`;

const MACHINE = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
    }
  }
`;

const MEMBER = gql`
  query searchMember($machine: String!, $q: String) {
    searchMember(machine: $machine, q: $q) {
      id
      name
      mobile
    }
  }
`;

const LockerDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const q = useInput("");
  const name = useInput("");
  const mobile = useInput("");
  const description = useInput("");

  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [product, setProduct] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [member, setMember] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showMember, setShowMember] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [start, setStart] = useState(null);
  const [showStart, setShowStart] = useState(false);
  const [end, setEnd] = useState(null);
  const [showEnd, setShowEnd] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine,
      lockerId: location.state.lockerId,
    },
  });
  const { data: products } = useQuery(PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: machine } = useQuery(MACHINE, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: members } = useQuery(MEMBER, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      q: q.value,
    },
  });

  const [editLockerM] = useMutation(EDIT);

  const handleProduct = (product) => {
    setProduct(product);
    setShowProduct(false);
  };

  const handleStart = (start) => {
    setStart(start);
    if (product) {
      setEnd(new Date(start.getTime() + 1000 * 60 * 60 * 24 * (product.duration - 1)));
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setEnd(end);
    setShowEnd(false);
  };

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const handleMobile = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      mobile.onChange(e);
    }
  };

  const handleMember = () => {
    if (isAdd) {
      if (name.value && mobile.value) {
        if (mobile.value.length === 8) {
          setMember({
            id: -1,
            name: name.value,
            mobile: mobile.value,
          });
          setShowMember(false);
        } else {
          alert("올바른 휴대전화 번호를 입력해주세요.");
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    } else {
      if (selectedMember) {
        setMember(selectedMember);
      }
      setShowMember(false);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getLocker) {
        if (!status) {
          alert("락커 상태를 선택해주세요.");
          return;
        }
        if (start && end) {
          if (start > end) {
            alert("이용 시작일이 이용 종료일보다 느립니다.");
            return;
          }
        }
        if (!product && status === "inuse") {
          alert("이용권을 선택 해주세요.");
          return;
        }
        if (!member && status === "inuse") {
          alert("구매자명 또는 구매자 연락처를 선택 해주세요.");
          return;
        }
        let confirm = true;
        if (status === "available") {
          confirm = window.confirm("사용가능으로 변경시 이용권 및 구매자 정보 등이 사라집니다.\n계속 하시겠습니까?");
        } else if (status === "expired") {
          confirm = window.confirm("만료로 변경시 이용권 및 회원정보는 저장되지 않습니다.\n계속 하시겠습니까?");
        }
        if (confirm) {
          setLoading(true);
          try {
            const { data: result } = await editLockerM({
              variables: {
                machine: location.state.machine ? location.state.machine : "",
                lockerId: data.getLocker.id,
                start: start ? `${start.getTime()}` : null,
                end: end ? `${end.getTime()}` : null,
                status,
                productId: product ? product.id : null,
                memberId: member ? member.id : null,
                memberName: member ? member.name : null,
                memberMobile: member ? member.mobile : null,
                description: description.value,
              },
            });
            setLoading(false);
            if (result.editLocker && result.editLocker.locker) {
              initialize(result.editLocker.locker);
              alert("수정하였습니다.");
            } else if (result.editLocker.error) {
              alert(result.editLocker.error);
            } else {
              alert("오류가 발생하였습니다.");
            }
          } catch (e) {
            console.log(e);
            setLoading(false);
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const makeAvailable = async () => {
    if (!loading && data && data.getLocker) {
      setLoading(true);
      try {
        const { data: result } = await editLockerM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            lockerId: data.getLocker.id,
            start: null,
            end: null,
            status: "available",
            productId: null,
            memberId: null,
            memberName: null,
            memberMobile: null,
            description: description.value,
          },
        });
        setLoading(false);
        if (result.editLocker && result.editLocker.locker) {
          initialize(result.editLocker.locker);
          alert("회수처리 하였습니다.");
        } else if (result.editLocker.error) {
          alert(result.editLocker.error);
        } else {
          alert("오류가 발생하였습니다.");
        }
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    }
  };

  const initialize = (locker) => {
    console.log("locker ???", locker);
    if (locker) {
      setStatus(locker.status);
      setStart(locker.start ? new Date(Number(locker.start)) : null);
      setEnd(locker.end ? new Date(Number(locker.end)) : null);
      setProduct(locker.product ? locker.product : null);
      setShowProduct(false);
      setMember(locker.member ? locker.member : null);
      setSelectedMember(locker.member ? locker.member : null);
      setShowMember(false);
      description.setValue(locker.description);
    } else {
      if (data && data.getLocker) {
        setStatus(data.getLocker.status);
        setStart(data.getLocker.start ? new Date(Number(data.getLocker.start)) : null);
        setEnd(data.getLocker.end ? new Date(Number(data.getLocker.end)) : null);
        setProduct(data.getLocker.product ? data.getLocker.product : null);
        setShowProduct(false);
        setMember(data.getLocker.member ? data.getLocker.member : null);
        setSelectedMember(data.getLocker.member ? data.getLocker.member : null);
        setShowMember(false);
        description.setValue(data.getLocker.description);
      }
    }
  };

  useEffect(() => {
    name.setValue("");
    mobile.setValue("");
  }, [isAdd]);

  useEffect(() => {
    q.setValue("");
    setIsAdd(false);
    if (showMember) {
      setSelectedMember(member ? member : null);
    }
  }, [showMember]);

  useEffect(() => {
    if (data && data.getLocker) {
      initialize();
    }
  }, [data]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`락커 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 구역</p>
                  </div>
                  <input type={"text"} name={"name"} value={data && data.getLocker ? data.getLocker.lockerArea.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showProduct ? 0 : 10,
                      borderBottomRightRadius: showProduct ? 0 : 10,
                    }}
                    onClick={() => setShowProduct(!showProduct)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>이용권명</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black}`}>{product ? product.name : ""}</p>
                      <img src={showProduct ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"이용권 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showProduct && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div
                            style={{
                              maxHeight: 300,
                              overflowY: "scroll",
                            }}
                          >
                            {products && products.getProductsAll
                              ? products.getProductsAll.map((p) => {
                                  return (
                                    <div key={p.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleProduct(p)}>
                                      <p className={`${styles.font16} ${styles.black}`}>{p.name}</p>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 번호</p>
                  </div>
                  <input type={"text"} name={"number"} value={data && data.getLocker ? data.getLocker.number : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} style={{ width: 615 }} onClick={() => setShowMember(!showMember)}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자명</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                    <p className={`${styles.font16} ${styles.black}`}>{member ? member.name : ""}</p>
                    <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} style={{ width: 615 }} onClick={() => setShowMember(!showMember)}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자 연락처</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                    <p className={`${styles.font16} ${styles.black}`}>{member ? `010${member.mobile}` : ""}</p>
                    <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showStart ? 0 : 10,
                      borderBottomRightRadius: showStart ? 0 : 10,
                    }}
                    onClick={() => setShowStart(!showStart)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>이용 시작일</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black}`}>{start ? `${start.getFullYear()}-${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}-${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : ""}</p>
                      <img src={showStart ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showStart && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <Calendar
                            calendarType={"US"}
                            value={start}
                            minDetail={"month"}
                            maxDate={end}
                            nextLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                              </span>
                            }
                            next2Label={null}
                            prevLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                              </span>
                            }
                            prev2Label={null}
                            navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                            onChange={handleStart}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showEnd ? 0 : 10,
                      borderBottomRightRadius: showEnd ? 0 : 10,
                    }}
                    onClick={() => setShowEnd(!showEnd)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>이용 종료일</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${end ? styles.black : styles.grayA2}`}>{end ? `${end.getFullYear()}-${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}-${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}</p>
                      <img src={showEnd ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"상품 분류 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showEnd && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <Calendar
                            calendarType={"US"}
                            value={end}
                            minDetail={"month"}
                            minDate={start}
                            nextLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                              </span>
                            }
                            next2Label={null}
                            prevLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                              </span>
                            }
                            prev2Label={null}
                            navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                            onChange={handleEnd}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      borderBottomLeftRadius: showStatus ? 0 : 10,
                      borderBottomRightRadius: showStatus ? 0 : 10,
                    }}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 상태</p>
                    </div>
                    {data && data.getLocker ? (
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                        <p className={`${styles.fontR} ${styles.font16} ${styles.black}`}>
                          {status === "available" && "사용가능"}
                          {status === "expired" && "만료"}
                          {status === "inuse" && "사용중"}
                        </p>
                        <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"상태 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                      </div>
                    ) : (
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}></div>
                    )}
                  </div>
                  {showStatus && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("available")}>
                            <p className={`${styles.font16} ${styles.black}`}>사용가능</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("expired")}>
                            <p className={`${styles.font16} ${styles.black}`}>만료</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("inuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>사용중</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>비고</p>
                    <textarea name={"description"} value={description.value} onChange={description.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 200 }} placeholder={"비고를 입력해주세요."} maxLength={5000} />
                  </div>
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                {data && data.getLocker.status === "expired" && (
                  <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={makeAvailable}>
                    <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>회수</p>
                  </div>
                )}
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>저장</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal isOpen={showMember} onRequestClose={() => setShowMember(false)} style={bill}>
        <div className={`${styles.bgWhite}`}>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.py15} ${styles.px15}`}>
            {isAdd ? <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.hidden}`}>취소</p> : <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.hidden}`}>회원 추가</p>}
            <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>회원 검색</p>
            {isAdd ? (
              <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => setIsAdd(false)}>
                취소
              </p>
            ) : (
              <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => setIsAdd(true)}>
                회원 추가
              </p>
            )}
          </div>
          {isAdd ? (
            <div className={`${styles.py10}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>회원명</p>
              <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhiteUnderline} ${styles.widthFull} ${styles.py10}`} placeholder={"회원명을 입력해주세요."} />
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>회원 연락처</p>
              <input type={"tel"} name={"mobile"} value={mobile.value} onChange={handleMobile} className={`${styles.inputWhiteUnderline} ${styles.widthFull} ${styles.py10}`} placeholder={"(010제외)"} maxLength={8} />
            </div>
          ) : (
            <div>
              <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhiteUnderline} ${styles.widthFull} ${styles.py10}`} placeholder={"이름 및 연락처 검색 (010제외)"} />
              <div
                className={`${styles.bgWhite} ${styles.hideScroll}`}
                style={{
                  height: 300,
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
              >
                {members
                  ? members.searchMember.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${selectedMember ? (selectedMember.id === item.id ? styles.bgGrayEf : styles.bgWhite) : styles.bgWhite} ${styles.cursorPointer}`}
                          onClick={() => setSelectedMember(item)}
                        >
                          <p className={`${styles.font16} ${styles.black}`}>{`${item.name} (010${item.mobile})`}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          )}
          <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={handleMember}>
            <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LockerDetail;
