import React from "react";
import { useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";

const LockerProductsPaymentDetail = () => {
  const location = useLocation();

  let machine = location.state.machine;
  let payment = location.state.payment;
  console.log("paymentDetail", payment);

  // 영수증 출력
  const showBill = () => {
    window.open("https://admin.ven-brothers.com/receipt/" + payment.approvalDate + payment.approvalNo);
  };

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`락커 판매 현황 (${machine})`}</p>
          <div className={`${styles.mt40} ${styles.px20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자명</p>
              </div>
              <input type={"text"} name={"userName"} value={payment.userName} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>이용권명</p>
              </div>
              <input type={"text"} name={"product"} value={payment.productName} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자 연락처</p>
              </div>
              <input type={"text"} name={"userContact"} value={payment.userContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>시작일</p>
              </div>
              <input type={"text"} name={"startDate"} value={payment.startDate} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>락커번호</p>
              </div>
              <input type={"text"} name={"startDate"} value={payment.lockerNumber} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제상태</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                <input type={"text"} value={payment.status === "paid" ? "결제완료" : payment.status === "cancelled" ? "결제취소" : payment.status === "refund" ? "환불" : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} style={{ minWidth: 20 }} readOnly={true} />
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.fontM} ${styles.font16} ${styles.bgWhite} ${styles.black} ${styles.py10}`} style={{ width: "100%", textAlign: "center" }} onClick={showBill}>
                영 수 증
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerProductsPaymentDetail;
