import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { ResponsiveBar } from "@nivo/bar";

import styles from "../../../styles/styles.module.scss";

const tmpResult = {
  region_month: { "2022-11-01": 0.7805908302777331, "2022-12-01": 0.8097121469501094, "2023-01-01": 0.9997392729197838, "2023-02-01": 0.8062663052430015, "2023-03-01": 0.8095937726966229 },
  region_week: { "2022-11-01": 0.7805908302777331, "2022-12-01": 0.8097121469501094, "2023-01-01": 0.9997392729197838, "2023-02-01": 0.8062663052430015, "2023-03-01": 0.8095937726966229 },
  review_summary: "고기를 화덕에서 초벌한 후 솥뚜껑에서 구워 먹는 방식이며, 특별하지는 않지만 맛있음. 반찬은 셀프 서비스로 제공되며, 고기 자르기만 직원이 해주고 나머지는 자율적으로 익혀야 함. 매장은 깨끗하고 테이블이 널찍하여 편하게 식사할 수 있으며, 분위기는 무난하고 서비스도 좋음.",
  month_revenue: { "2022-11-01": 40000, "2022-12-01": 40000, "2023-01-01": 130000, "2023-02-01": 80000, "2023-03-01": 180000 },
  month_revenue_normal: { "2022-11-01": 0.0, "2022-12-01": 0.0, "2023-01-01": 0.6428571428571429, "2023-02-01": 0.2857142857142857, "2023-03-01": 1.0 },
  week_revenue: {
    "2022-11-06": 10000,
    "2022-11-13": 10000,
    "2022-11-20": 10000,
    "2022-11-27": 10000,
    "2022-12-04": 10000,
    "2022-12-11": 10000,
    "2022-12-18": 10000,
    "2022-12-25": 10000,
    "2023-01-01": 10000,
    "2023-01-08": 20000,
    "2023-01-15": 30000,
    "2023-01-22": 40000,
    "2023-01-29": 30000,
    "2023-02-05": 20000,
    "2023-02-12": 10000,
    "2023-02-19": 20000,
    "2023-02-26": 30000,
    "2023-03-04": 30000,
    "2023-03-11": 40000,
    "2023-03-18": 50000,
    "2023-03-25": 60000,
  },
  week_revenue_normal: {
    "2022-11-06": 0.0,
    "2022-11-13": 0.0,
    "2022-11-20": 0.0,
    "2022-11-27": 0.0,
    "2022-12-04": 0.0,
    "2022-12-11": 0.0,
    "2022-12-18": 0.0,
    "2022-12-25": 0.0,
    "2023-01-01": 0.0,
    "2023-01-08": 0.2,
    "2023-01-15": 0.4,
    "2023-01-22": 0.6,
    "2023-01-29": 0.4,
    "2023-02-05": 0.2,
    "2023-02-12": 0.0,
    "2023-02-19": 0.2,
    "2023-02-26": 0.4,
    "2023-03-04": 0.4,
    "2023-03-11": 0.6,
    "2023-03-18": 0.8,
    "2023-03-25": 1.0,
  },
  reviews: [
    "[ 잠실 고깃집 맛집 리뷰 ] English translation at the bottom\n\n1. 품질 & 맛\n고기를 화덕에 먼저 초벌하고 솥뚜껑 위에 구워먹는 시스템입니다. 엄청 특별한 맛은 아니지만, 맛있었습니다.\n\n2. 서비스 & 친절\n반찬은 셀프로 더 가져다먹을 수 있습니다. 고기 자르는 것까지만 직원이 해주고, 알아서 익혀 먹어야 합니다.\n\n3. 위생 & 분위기\n매장 위생은 전반적으로 깨끗했습니다.",
    "고기가 초벌돼 나오고 먹기 좋게 잘라주시기 때문에 편하게 먹을수 있습니다.\n고기도 맛있어요",
    "분위기도 괜찮고 맛도 무난한 고기집. 테이블이 상당히 커서 둘이서가면 되게 널널하고 4명이서 가도 충분한 넓이.\n굽느데 시간이 좀 걸리고 화덕에서 초벌 해주는데 맛이 막 뛰어나게 좋아진다라는 느낌이 들지는 않음. 평범했다.",
    "고기도 구워 주시고... 좀 지나서는 알아서 ㅎ\n일하시는 분들이 서비스도 좋으시고 전문가 같습니다.\n음식들도 맛있구요.\n좀 부산스럽기는 합니다.\n어쩌다 화장실 앞쪽에 앉았는데 나오는 분들과 아이컨택이 되는건 좀..\n화장실도 남여 입구가 하나라 좀..",
    "화덕이라 신기했고 낵아 먹어본 삼겹살중 탑3안에 들어감\n셀프바도 잘 되어있어서 눈치보지않고 갔다 먹을수있다.\n친절하고 분위기좋고 또 방문하고 싶다.",
  ],
  store_name: "예시 이름",
};

const ReportResult = () => {
  const history = useHistory();
  let resData = tmpResult;
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();

  const [reviewSummary, setReviewSummary] = useState(tmpResult.review_summary);
  const [reviews, setReviews] = useState(tmpResult.reviews);

  const init = () => {
    if (!resData) {
      history.push({
        pathname: `/report`,
      });
    }

    let result = [];

    //1. 현재 매출 추세 분석 데이터 삽입
    for (var i in resData.week_revenue) {
      let tmp = { date: i, revenue: resData.week_revenue[i] };
      result.push(tmp);
    }

    //2. 타매장 비교 분석
    for (var i in resData.week_revenue) {
      let tmp = { date: i, revenue: resData.week_revenue[i] };
      result.push(tmp);
    }

    setData1(result);
  };

  const handleGoReport = () => {
    history.push({
      pathname: `/report`,
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#111111", alignItems: "center", height: "100%" }}>
      <div className={`${styles.font35} ${styles.fontB} ${styles.my40}`}>
        <label style={{ color: "#32bbff" }}>데이터 &nbsp;</label>
        <label style={{ color: "white" }}>분석 결과</label>
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.font19} ${styles.justifyContentCenter}`} style={{ minWidth: "555px", width: "50%" }}>
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.py15}`} style={{ minWidth: "272px", borderTop: "solid 2px #32bbff", width: "49%", backgroundColor: "#252525", cursor: "pointer" }} onClick={handleGoReport}>
          <label style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}>새로운 데이터</label>
          <label style={{ color: "white", cursor: "pointer" }}>&nbsp;분석하기</label>
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.ml10} ${styles.py15}`} style={{ minWidth: "272px", borderTop: "solid 2px #32bbff", width: "49%", backgroundColor: "#252525", cursor: "pointer" }}>
          <label style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}>데이터 분석 결과</label>
          <label style={{ color: "white", cursor: "pointer" }}>&nbsp;메일로 보내기</label>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {/**1. 현재 매출 추세 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <div className={`${styles.font19}`}>
              <label style={{ color: "white", fontWeight: "bold" }}>1. 현재 매출 추세 분석</label>
            </div>
          </div>
          <div className={`${styles.mt20}`} style={{ width: "50%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <label className={`${styles.font35}`} style={{ color: "#33FFBE" }}>
                상승중
              </label>
              <label className={`${styles.font25}`} style={{ color: "#767676" }}>
                2023.09 ~ 2024.09 기준
              </label>
            </div>
          </div>
          <div style={{ width: "80%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data1}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["revenue"]}
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 100 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.3}
              /**
               * chart 색상
               */
              colors={["#32bbff"]} // 커스터하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                /**
                 * legend style (default로 우측 하단에 있는 색상별 key 표시)
                 */
                /*
                legends: {
                  text: {
                    fontSize: 12,
                    fill: "#ffffff",
                  },
                },*/
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
              /**
               * bar 클릭 이벤트
               */
              //onClick={handle.barClick}
              /**
               * legend 설정 (default로 우측 하단에 있는 색상별 key 표시)
               */
              /*
              legends={[
                {
                  dataFrom: "keys", // 보일 데이터 형태
                  anchor: "bottom-right", // 위치
                  direction: "column", // item 그려지는 방향
                  justify: false, // 글씨, 색상간 간격 justify 적용 여부
                  translateX: 120, // chart와 X 간격
                  translateY: 0, // chart와 Y 간격
                  itemsSpacing: 2, // item간 간격
                  itemWidth: 100, // item width
                  itemHeight: 20, // item height
                  itemDirection: "left-to-right", // item 내부에 그려지는 방향
                  itemOpacity: 0.85, // item opacity
                  symbolSize: 20, // symbol (색상 표기) 크기
                  effects: [
                    {
                      // 추가 효과 설정 (hover하면 item opacity 1로 변경)
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                  //onClick: handle.legendClick, // legend 클릭 이벤트
                },
              ]}*/
            />
          </div>
        </div>
        {/** 2. 타매장 비교 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>2. 타매장 비교 분석</label>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt5}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                  <div style={{ backgroundColor: "#33BBFF", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#33BBFF" }}>
                    &nbsp;내 매장
                  </label>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.ml30}`}>
                  <div style={{ backgroundColor: "#FD395D", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#FD395D" }}>
                    &nbsp;내 매장과 가장 유사한 매장
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "80%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data1}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["revenue"]}
              groupMode="grouped"
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 100 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.3}
              /**
               * chart 색상
               */
              colors={["#32bbff"]} // 커스터하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                /**
                 * legend style (default로 우측 하단에 있는 색상별 key 표시)
                 */
                /*
                legends: {
                  text: {
                    fontSize: 12,
                    fill: "#ffffff",
                  },
                },*/
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
              /**
               * bar 클릭 이벤트
               */
              //onClick={handle.barClick}
              /**
               * legend 설정 (default로 우측 하단에 있는 색상별 key 표시)
               */
              /*
              legends={[
                {
                  dataFrom: "keys", // 보일 데이터 형태
                  anchor: "bottom-right", // 위치
                  direction: "column", // item 그려지는 방향
                  justify: false, // 글씨, 색상간 간격 justify 적용 여부
                  translateX: 120, // chart와 X 간격
                  translateY: 0, // chart와 Y 간격
                  itemsSpacing: 2, // item간 간격
                  itemWidth: 100, // item width
                  itemHeight: 20, // item height
                  itemDirection: "left-to-right", // item 내부에 그려지는 방향
                  itemOpacity: 0.85, // item opacity
                  symbolSize: 20, // symbol (색상 표기) 크기
                  effects: [
                    {
                      // 추가 효과 설정 (hover하면 item opacity 1로 변경)
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                  //onClick: handle.legendClick, // legend 클릭 이벤트
                },
              ]}*/
            />
          </div>
        </div>
        {/** 3. 미래 매출 예측 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>3. 미래 매출 예측 분석</label>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt5}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                  <div style={{ backgroundColor: "#33BBFF", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#33BBFF" }}>
                    &nbsp;내 매장
                  </label>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.ml30}`}>
                  <div style={{ backgroundColor: "#FD395D", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#FD395D" }}>
                    &nbsp;내 매장과 가장 유사한 매장
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "80%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data1}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["revenue"]}
              groupMode="grouped"
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 100 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.3}
              /**
               * chart 색상
               */
              colors={["#32bbff"]} // 커스터하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                /**
                 * legend style (default로 우측 하단에 있는 색상별 key 표시)
                 */
                /*
                legends: {
                  text: {
                    fontSize: 12,
                    fill: "#ffffff",
                  },
                },*/
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
              /**
               * bar 클릭 이벤트
               */
              //onClick={handle.barClick}
              /**
               * legend 설정 (default로 우측 하단에 있는 색상별 key 표시)
               */
              /*
              legends={[
                {
                  dataFrom: "keys", // 보일 데이터 형태
                  anchor: "bottom-right", // 위치
                  direction: "column", // item 그려지는 방향
                  justify: false, // 글씨, 색상간 간격 justify 적용 여부
                  translateX: 120, // chart와 X 간격
                  translateY: 0, // chart와 Y 간격
                  itemsSpacing: 2, // item간 간격
                  itemWidth: 100, // item width
                  itemHeight: 20, // item height
                  itemDirection: "left-to-right", // item 내부에 그려지는 방향
                  itemOpacity: 0.85, // item opacity
                  symbolSize: 20, // symbol (색상 표기) 크기
                  effects: [
                    {
                      // 추가 효과 설정 (hover하면 item opacity 1로 변경)
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                  //onClick: handle.legendClick, // legend 클릭 이벤트
                },
              ]}*/
            />
          </div>
        </div>
        {/** 4. 업장 리뷰 요약 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "50%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>4. 업장 리뷰 요약</label>
              </div>
            </div>
            <div className={`${styles.mt5}`}>
              <label className={`${styles.font16}`} style={{ color: "#33BBFF" }}>
                {reviewSummary}
              </label>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.mt30} ${styles.widthFull} ${styles.justifyContentBetween}`}>
              {reviews &&
                reviews.map((item, index) => (
                  <div className={`${styles.py20} ${styles.px20}`} style={{ width: "47%", borderTop: "solid 1px #767676", borderBottom: "solid 1px #767676", position: "relative" }}>
                    <div className={`${styles.font14} ${styles.mb50}`} style={{ color: "white" }}>
                      {item}
                    </div>
                    <div className={`${styles.font13}`} style={{ color: "white", bottom: "20px", position: "absolute" }}>
                      구글 맵 리뷰
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.mt80}`} style={{ display: "flex", flexDirection: "row", borderTop: "solid 1px #252525", height: "250px", width: "100%" }}>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font18}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font15}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
      </div>
    </div>
  );
};

export default ReportResult;
