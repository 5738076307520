import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const ContractLg = ({
    contract
}) => {
    const history = useHistory()

    const date = new Date(Number(contract.createdAt))
    const termDate = new Date(Number(contract.terms.createdAt))
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <a target={"_blank"} href={contract.terms.file}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline}`}>
                        {contract.terms.name}
                    </p>
                </a>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.cursorPointer}`} style={{ flex: 7 }} onClick={() => history.push({
                pathname: `/contract/detail/`,
                state: {
                    contract
                }
            })}>
                <div className={`${styles.flex1}`}>
                    {termDate ? (
                        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                            {`${termDate.getFullYear()}-${termDate.getMonth() + 1 < 10 ? '0' + String(termDate.getMonth() + 1) : termDate.getMonth() + 1}-${termDate.getDate() < 10 ? '0' + String(termDate.getDate()) : termDate.getDate()}`}<br/>
                            {`${termDate.getHours() < 10 ? '0' + termDate.getHours() : termDate.getHours()}:${termDate.getMinutes() < 10 ? '0' + termDate.getMinutes() : termDate.getMinutes()}:${termDate.getSeconds() < 10 ? '0' + termDate.getSeconds() : termDate.getSeconds()}`}
                        </p>
                    ) : (
                        null
                    )}
                </div>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0' + String(date.getDate()) : date.getDate()}`}<br/>
                        {`${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {contract.userName}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {contract.userContact}
                    </p>
                </div>
                <div className={`${styles.flex1} ${styles.center}`}>
                    <div className={`${styles.icon20} ${styles.borderRadius5} ${styles.borderGrayA2} ${styles.center}`}>
                        {contract.checkTerm && (
                            <div className={`${styles.bgOrange}`} style={{ width: 14, height: 14, borderRadius: 3 }} />
                        )}
                    </div>
                </div>
                <div className={`${styles.flex1} ${styles.center}`}>
                    <div className={`${styles.icon20} ${styles.borderRadius5} ${styles.borderGrayA2} ${styles.center}`}>
                        {contract.agreeTerm && (
                            <div className={`${styles.bgOrange}`} style={{ width: 14, height: 14, borderRadius: 3 }} />
                        )}
                    </div>
                </div>
                <div className={`${styles.flex1} ${styles.center}`}>
                    <img src={contract.sign} style={{ maxWidth: 50, maxHeight: 50 }} />
                </div>
            </div>
        </div>
    )
}

export default ContractLg;