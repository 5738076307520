import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import xlsx from 'xlsx';

import styles from '../../../styles/styles.module.scss';
import ConsultProduct from '../../../components/Consult/Mobile/ConsultProduct';
import useInput from '../../../hooks/useInput';
import { billSm } from '../../../common/modal';

const PT = gql`
    query getPtRequests(
        $machine: String!
        $skip: Int!
        $order: String!
        $search: String
    ) {
        getPtRequests(
            machine: $machine
            skip: $skip
            take: 10
            order: $order
            search: $search
        ) {
            ptRequests {
                id
                name
                contact
                category {
                    id
                    name
                }
                extra
                status
                machine {
                    id
                    username
                    name
                }
                createdAt
                result
            }
            total
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const EXPORT = gql`
    query exportPtRequest(
        $machine: String!
        $isAll: Boolean!
        $start: String
        $end: String
    ) {
        exportPtRequest(
            machine: $machine
            isAll: $isAll
            start: $start
            end: $end
        ) {
            id
            name
            contact
            category {
                id
                name
            }
            extra
            form
            status
            createdAt
            result
        }
    }
`;

const ConsultProducts = () => {
    const history = useHistory();
    const location = useLocation();

    const search = useInput('');

    const [showDate, setShowDate] = useState(false);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [isAll, setIsAll] = useState(true);
    const [step, setStep] = useState(1);
    const [exporting, setExporting] = useState(false);

    const { data, loading, refetch } = useQuery(PT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            skip: 0,
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'date_dsc',
            search: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });
    const { refetch: exportPtRequest } = useQuery(EXPORT, {
        fetchPolicy: 'network-only',
        skip: true,
    });

    const submit = e => {
        e.preventDefault();
        history.push({
            pathname: `/consult/product/list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&page=1&q=${search.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/consult/product/list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&page=${page.selected + 1}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const exportExcel = async () => {
        if (!isAll) {
            if (!(start || end)) {
                alert('기간을 선택해주세요.');
                return;
            }
        }
        setExporting(true);
        try {
            const { data: result } = await exportPtRequest({
                machine: location.state.machine ? location.state.machine : '',
                isAll,
                start: start ? `${start.getTime()}` : null,
                end: end ? `${end.getTime()}` : null,
            });
            if (result && result.exportPtRequest.length > 0) {
                let data = [];
                let titles = [];
                for (const item of result.exportPtRequest) {
                    const date = new Date(Number(item.createdAt));
                    if (item.form) {
                        const form = JSON.parse(item.form);
                        let row = {
                            '신청 일시': `${date.getFullYear()}/${
                                date.getMonth() + 1 < 10
                                    ? '0' + String(date.getMonth() + 1)
                                    : date.getMonth() + 1
                            }/${
                                date.getDate() < 10
                                    ? '0' + String(date.getDate())
                                    : date.getDate()
                            }`,
                            신청자명: item.name,
                            '신청자 연락처': item.contact,
                            분류: item.category
                                ? item.category.name
                                : '상담신청',
                            '처리 여부':
                                item.status === 'ready'
                                    ? '미처리'
                                    : item.status === 'progress'
                                    ? '처리중'
                                    : item.status === 'done'
                                    ? '처리완료'
                                    : '',
                            '처리 내용': item.result,
                            비고: item.extra,
                        };
                        for (const formData of form) {
                            titles.push(formData.title);
                            if (formData.type === 'text') {
                                row[formData.title] = formData.value;
                            } else if (formData.type === 'select') {
                                let selected = '';
                                for (const s of formData.selected) {
                                    if (selected) {
                                        selected += s.title;
                                    } else {
                                        selected += ` ${s.title}`;
                                    }
                                }
                                row[formData.title] = selected;
                            }
                        }
                        data.push(row);
                    } else {
                        data.push({
                            '신청 일시': `${date.getFullYear()}/${
                                date.getMonth() + 1 < 10
                                    ? '0' + String(date.getMonth() + 1)
                                    : date.getMonth() + 1
                            }/${
                                date.getDate() < 10
                                    ? '0' + String(date.getDate())
                                    : date.getDate()
                            }`,
                            신청자명: item.name,
                            '신청자 연락처': item.contact,
                            분류: item.category
                                ? item.category.name
                                : '상담신청',
                            '처리 여부':
                                item.status === 'ready'
                                    ? '미처리'
                                    : item.status === 'progress'
                                    ? '처리중'
                                    : item.status === 'done'
                                    ? '처리완료'
                                    : '',
                            '처리 내용': item.result,
                            비고: item.extra,
                        });
                    }
                }
                const ws = xlsx.utils.json_to_sheet(data);
                const wb = xlsx.utils.book_new();
                var wscols = [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 50 },
                    { wpx: 50 },
                    { wpx: 200 },
                    { wpx: 200 },
                ];
                for (var i = 0; i < [...new Set(titles)].length; i++) {
                    wscols.push({
                        wpx: 200,
                    });
                }
                ws['!cols'] = wscols;
                xlsx.utils.book_append_sheet(wb, ws, '상담신청내역');
                xlsx.writeFile(
                    wb,
                    `Ven-Brothers_상담신청내역_${machine.getMachine.name}.xlsx`,
                );
                setExporting(false);
                setShowDate(false);
            } else {
                setExporting(false);
                alert('내보낼 상담 신청 내역이 없습니다.');
            }
        } catch {
            setExporting(false);
            alert('오류가 발생하였습니다.');
        }
    };

    useEffect(() => {
        setStart(null);
        setEnd(null);
        setIsAll(true);
        setStep(1);
    }, [showDate]);

    useEffect(() => {
        setStart(null);
        setEnd(null);
        setStep(1);
    }, [isAll]);

    useEffect(() => {
        if (location.state.machine) {
            if (
                queryString.parse(location.search).page &&
                !isNaN(Number(queryString.parse(location.search).page))
            ) {
                window.scrollTo(0, 0);
                refetch({
                    skip:
                        10 *
                        (Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'date_dsc',
                    search: queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : '',
                });
            } else {
                history.replace({
                    pathname: `/consult/product/list/`,
                    state: location.state,
                    search: `?order=${
                        queryString.parse(location.search).order
                            ? queryString.parse(location.search).order
                            : 'date_dsc'
                    }&page=1&q=${
                        queryString.parse(location.search).q
                            ? queryString.parse(location.search).q
                            : ''
                    }`,
                });
            }
        } else {
            alert('잘못된 접근입니다.');
            history.goBack();
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`상담 신청 관리${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() =>
                                history.push({
                                    pathname: `/consult/form/edit/`,
                                    state: {
                                        machine: location.state.machine
                                            ? location.state.machine
                                            : '',
                                    },
                                })
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                상담 양식 관리
                            </p>
                        </div>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
                            onClick={() =>
                                history.push({
                                    pathname: `/consult/category/edit/`,
                                    state: {
                                        machine: location.state.machine
                                            ? location.state.machine
                                            : '',
                                    },
                                })
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                상담 카테고리 관리
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.center} ${styles.mt15}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() => setShowDate(true)}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                내보내기
                            </p>
                        </div>
                    </div>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt15} ${styles.px40}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img
                                src={
                                    require('../../../assets/images/icon_search.png')
                                        .default
                                }
                                alt={'search'}
                                className={`${styles.icon20} ${styles.cursorPointer}`}
                                onClick={submit}
                            />
                            <input
                                type={'text'}
                                name={'search'}
                                value={search.value}
                                onChange={search.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                placeholder={
                                    '신청자명, 연락처, 항목 및 처리 내용 검색'
                                }
                            />
                        </div>
                    </form>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                신청일시
                            </p>
                        </div>
                        <div className={`${styles.flex1} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                신청자명
                            </p>
                        </div>
                        <div className={`${styles.flex1}`}></div>
                    </div>
                    <div>
                        {loading ? (
                            <Skeleton height={50} count={3} />
                        ) : data &&
                          data.getPtRequests &&
                          data.getPtRequests.ptRequests.length > 0 ? (
                            data.getPtRequests.ptRequests.map(pt => {
                                return <ConsultProduct key={pt.id} pt={pt} />;
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    아직 등록된 상담이 없습니다.
                                </p>
                            </div>
                        )}
                        {data?.getPtRequests?.total > 5 ? (
                            <div
                                className={`${styles.mt35}`}
                                style={{ position: 'relative' }}>
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_back.png')
                                                    .default
                                            }
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_front.png')
                                                    .default
                                            }
                                            alt={'다음'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    breakLabel={
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    }
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={
                                        data?.getPtRequests?.total
                                            ? parseInt(
                                                  (data?.getPtRequests?.total -
                                                      1) /
                                                      10,
                                              ) + 1
                                            : 1
                                    }
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loading ? null : handlePage}
                                    initialPage={
                                        queryString.parse(location.search).page
                                            ? Number(
                                                  queryString.parse(
                                                      location.search,
                                                  ).page,
                                              ) - 1
                                            : 0
                                    }
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.4)',
                                        }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
                <Modal
                    isOpen={showDate}
                    onRequestClose={() => setShowDate(false)}
                    style={billSm}>
                    <div className={`${styles.py10} ${styles.px20}`}>
                        <div className={`${styles.row} ${styles.mx0}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={() => setIsAll(!isAll)}>
                                <div
                                    className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>
                                    {isAll && (
                                        <div
                                            className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                            style={{ width: 14, height: 14 }}
                                        />
                                    )}
                                </div>
                                <p
                                    className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
                                    전체 기간
                                </p>
                            </div>
                        </div>
                        {isAll ? (
                            <p
                                className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt30}`}>
                                전체 기간에 대해 상담 내역을 내보냅니다.
                            </p>
                        ) : (
                            <div className={`${styles.mt30}`}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.mb20}`}>
                                    <p
                                        className={`${styles.fonrR} ${
                                            styles.font14
                                        } ${styles.black} ${
                                            step === 1
                                                ? styles.hidden
                                                : styles.cursorPointer
                                        }`}
                                        onClick={
                                            step === 2 ? () => setStep(1) : null
                                        }>
                                        이전
                                    </p>
                                    <p
                                        className={`${styles.fonrR} ${styles.font14} ${styles.black}`}>
                                        {step === 1 ? '시작일' : '종료일'}
                                    </p>
                                    <p
                                        className={`${styles.fonrR} ${
                                            styles.font14
                                        } ${styles.black} ${
                                            step === 2
                                                ? styles.hidden
                                                : styles.cursorPointer
                                        }`}
                                        onClick={
                                            step === 1 ? () => setStep(2) : null
                                        }>
                                        다음
                                    </p>
                                </div>
                                {step === 1 ? (
                                    <Calendar
                                        calendarType={'US'}
                                        value={start}
                                        maxDate={end}
                                        nextLabel={
                                            <span>
                                                <img
                                                    src={
                                                        require('../../../assets/images/icon_dropdown_right.png')
                                                            .default
                                                    }
                                                    alt={'다음'}
                                                    className={`${styles.icon15}`}
                                                />
                                            </span>
                                        }
                                        next2Label={null}
                                        prevLabel={
                                            <span>
                                                <img
                                                    src={
                                                        require('../../../assets/images/icon_dropdown_left.png')
                                                            .default
                                                    }
                                                    alt={'이전'}
                                                    className={`${styles.icon15}`}
                                                />
                                            </span>
                                        }
                                        prev2Label={null}
                                        navigationLabel={({ label }) => (
                                            <p
                                                className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px30}`}>
                                                {label}
                                            </p>
                                        )}
                                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                        onChange={setStart}
                                    />
                                ) : (
                                    <Calendar
                                        calendarType={'US'}
                                        value={end}
                                        minDate={start}
                                        nextLabel={
                                            <span>
                                                <img
                                                    src={
                                                        require('../../../assets/images/icon_dropdown_right.png')
                                                            .default
                                                    }
                                                    alt={'다음'}
                                                    className={`${styles.icon15}`}
                                                />
                                            </span>
                                        }
                                        next2Label={null}
                                        prevLabel={
                                            <span>
                                                <img
                                                    src={
                                                        require('../../../assets/images/icon_dropdown_left.png')
                                                            .default
                                                    }
                                                    alt={'이전'}
                                                    className={`${styles.icon15}`}
                                                />
                                            </span>
                                        }
                                        prev2Label={null}
                                        navigationLabel={({ label }) => (
                                            <p
                                                className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px30}`}>
                                                {label}
                                            </p>
                                        )}
                                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                        onChange={setEnd}
                                    />
                                )}
                            </div>
                        )}
                        <div
                            className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`}
                            style={{ opacity: exporting ? 0.4 : 1 }}
                            onClick={exportExcel}>
                            <p
                                className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>
                                확인
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ConsultProducts;
