import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Calendar from 'react-calendar';

import styles from '../../../styles/styles.module.scss';
import { bill } from '../../../common/modal';
import useInput from '../../../hooks/useInput';

const MembershipHoldingItem = ({
    holding: defaultHolding,
    index,
    remove,
    edit,
}) => {
    const reason = useInput(defaultHolding.reason ? defaultHolding.reason : '');

    const [holding, setHolding] = useState({
        ...defaultHolding,
        start: defaultHolding.start
            ? new Date(Number(defaultHolding.start))
            : null,
        end: defaultHolding.end ? new Date(Number(defaultHolding.end)) : null,
    });
    const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleStart = start => {
        setHolding({
            ...holding,
            start,
        });
        setShowStart(false);
    };

    const handleEnd = end => {
        setHolding({
            ...holding,
            end,
        });
        setShowEnd(false);
    };

    const editHolding = async () => {
        if (holding.start && holding.end) {
            await edit({
                ...holding,
                reason: reason.value,
            });
            setEditing(false);
        } else {
            alert('시작일과 종료일을 선택해주세요.');
        }
    };

    useEffect(() => {
        setHolding({
            ...defaultHolding,
            start: defaultHolding.start
                ? new Date(Number(defaultHolding.start))
                : null,
            end: defaultHolding.end
                ? new Date(Number(defaultHolding.end))
                : null,
        });
        reason.setValue(defaultHolding.reason ? defaultHolding.reason : '');
    }, [editing, defaultHolding]);

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${
                styles.alignItemsCenter
            } ${styles.justifyContentBetween} ${styles.py15} ${styles.px15} ${
                index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf
            }`}>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                    {index + 1}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                onClick={editing ? () => setShowStart(!showStart) : null}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                    {holding.start
                        ? `${holding.start.getFullYear()}/${
                              holding.start.getMonth() + 1 < 10
                                  ? '0' + String(holding.start.getMonth() + 1)
                                  : holding.start.getMonth() + 1
                          }/${
                              holding.start.getDate() < 10
                                  ? '0' + String(holding.start.getDate())
                                  : holding.start.getDate()
                          }`
                        : ''}
                </p>
                {editing && (
                    <img
                        src={
                            require('../../../assets/images/icon_sort_down.png')
                                .default
                        }
                        alt={'dsc'}
                        className={`${styles.cursorPointer} ${styles.mt5}`}
                        style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                    />
                )}
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                onClick={editing ? () => setShowEnd(!showEnd) : null}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                    {holding.end
                        ? `${holding.end.getFullYear()}/${
                              holding.end.getMonth() + 1 < 10
                                  ? '0' + String(holding.end.getMonth() + 1)
                                  : holding.end.getMonth() + 1
                          }/${
                              holding.end.getDate() < 10
                                  ? '0' + String(holding.end.getDate())
                                  : holding.end.getDate()
                          }`
                        : ''}
                </p>
                {editing && (
                    <img
                        src={
                            require('../../../assets/images/icon_sort_down.png')
                                .default
                        }
                        alt={'dsc'}
                        className={`${styles.cursorPointer} ${styles.mt5}`}
                        style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                    />
                )}
            </div>
            <p
                className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                {holding.status === 'active' && '홀딩중'}
                {holding.status === 'ready' && '홀딩예정'}
                {holding.status === 'expired' && '종료'}
            </p>
            {editing ? (
                <div className={`${styles.flex2}`}>
                    <input
                        type={'text'}
                        name={'reason'}
                        value={reason.value}
                        onChange={reason.onChange}
                        className={`${styles.inputTransparent} ${styles.flex1}`}
                        placeholder={'입력해주세요. (선택사항)'}
                        style={{
                            width: '100%',
                            minWidth: 20,
                        }}
                    />
                </div>
            ) : (
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex2}`}>
                    {holding.reason}
                </p>
            )}
            {editing ? (
                <div
                    className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                        onClick={editHolding}>
                        저장
                    </p>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
                        onClick={() => setEditing(false)}>
                        취소
                    </p>
                </div>
            ) : (
                <div
                    className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                        onClick={() => setEditing(true)}>
                        수정
                    </p>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
                        onClick={remove}>
                        삭제
                    </p>
                </div>
            )}
            <Modal
                isOpen={showStart}
                onRequestClose={() => setShowStart(false)}
                style={bill}>
                <Calendar
                    calendarType={'US'}
                    value={holding.start}
                    maxDate={holding.end ? holding.end : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleStart}
                />
            </Modal>
            <Modal
                isOpen={showEnd}
                onRequestClose={() => setShowEnd(false)}
                style={bill}>
                <Calendar
                    calendarType={'US'}
                    value={holding.end}
                    minDate={holding.start ? holding.start : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleEnd}
                />
            </Modal>
        </div>
    );
};

export default MembershipHoldingItem;
