import React, { useEffect, useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

import styles from "../../../styles/styles.module.scss";

import "../../../styles/scheduler_css.css";
import reportHeader from "../../../assets/images/report_header.png";
import reportCheck from "../../../assets/images/report_check.png";
import reportCheckBlue from "../../../assets/images/report_checkblue.png";
import reportSearch from "../../../assets/images/report_search.png";
import dropUp from "../../../assets/images/report_dropdup.png";
import dropDown from "../../../assets/images/report_dropdown.png";
import add from "../../../assets/images/report_add.png";

import sidoData from "./sidoData.json";
import upjongData from "./upjongData.json";

const ProfitInfo = () => {
  //num1 state
  const [inputDongSearch, setInputDongSearch] = useState("");
  const [searchedSidoData, setSearchedSidoData] = useState([]);
  const [sidodong, setSidodong] = useState("");

  //num2 state
  const [upjongstr, setUpjongstr] = useState("");
  const [inputUpjongSearch, setInputUpjongSearch] = useState("");
  const [searchedUpjongData, setSearchedUpjongData] = useState([]);
  const [upjong, setUpjong] = useState("");

  //num3 state
  const [shopName, setShopName] = useState("");

  //num4 state
  const [inputProfit, setInputProfit] = useState([{ date: "", profit: "", isDropDown: false }]);
  const [dropDate, setDropDate] = useState([]);
  const [num4chk, setNum4chk] = useState(false);

  const addComma = (str) => {
    return parseInt(str).toLocaleString("ko-KR");
  };

  const getInitDate = async () => {
    let inputDate = [];
    let tmpDate = "";
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() + 1 - i);
      //2024-00으로 나오는경우가 있어 아래와 같이 처리
      let tmpYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      let tmpMonth00 = date.getMonth() + 1 > 10 ? date.getMonth() : "0" + date.getMonth();
      let tmpMonth = tmpMonth00 === "00" ? "12" : tmpMonth00;

      let tmpDate = tmpYear + "-" + tmpMonth;
      inputDate.push(tmpDate);
    }

    setDropDate([...inputDate]);
    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[0].date = inputDate[0];
    setInputProfit([...tmpInputProfit]);
  };

  const init = async () => {
    await getInitDate();
    setSearchedSidoData([...sidoData.regions]);
    setSearchedUpjongData([...upjongData.upjongs]);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let test = false;

    for (let i = 0; i < inputProfit.length; i++) {
      if (inputProfit[i].date !== "" && inputProfit[i].profit !== "") {
        continue;
      } else {
        test = true;
        break;
      }
    }
    setNum4chk(!test);
  }, [inputProfit]);

  const onChangeProfit = (e, index) => {
    let tmpStr = e.target.value;
    let regex = /[^0-9]/g;
    let result = tmpStr.replaceAll(regex, ""); // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경

    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[index].profit = result;
    setInputProfit([...tmpInputProfit]);
  };

  const handleDateAdd = () => {
    let tmpInputProfit = [...inputProfit];
    let index = inputProfit.length;
    if (index < 12) {
      let tmpArr = { date: dropDate[index], profit: "", isDropDown: false };

      tmpInputProfit.push(tmpArr);
      setInputProfit([...tmpInputProfit]);
    }
  };

  const handleSearchDong = (e) => {
    let tmpStr = e.target.value;
    let tmpArr = [...sidoData.regions];
    let tmpSearcedArr = [];
    setInputDongSearch(tmpStr);

    if (tmpStr === "") {
      setSearchedSidoData([...sidoData.regions]);
    } else {
      for (let i = 0; i < tmpArr.length; i++) {
        if (String(tmpArr[i]["region"]).indexOf(tmpStr) > -1) {
          tmpSearcedArr.push(tmpArr[i]);
        }
      }
      setSearchedSidoData([...tmpSearcedArr]);
    }
  };

  const handleSearchUpjong = (e) => {
    let tmpStr = e.target.value;
    let tmpArr = [...upjongData.upjongs];
    let tmpSearcedArr = [];
    setInputUpjongSearch(tmpStr);

    if (tmpStr === "") {
      setSearchedUpjongData([...upjongData.upjongs]);
    } else {
      for (let i = 0; i < tmpArr.length; i++) {
        if (String(tmpArr[i]["upjong"]).indexOf(tmpStr) > -1) {
          tmpSearcedArr.push(tmpArr[i]);
        }
      }
      setSearchedUpjongData([...tmpSearcedArr]);
    }
  };

  const handleSelectDong = (i) => {
    let tmpArr = [...searchedSidoData];

    setSidodong(tmpArr[i]["region"]);
  };

  const handleSelectUpjong = (i) => {
    let tmpArr = [...searchedUpjongData];

    setUpjong(tmpArr[i]["upjong"]);
  };

  const handleDropDownDate = (e, index) => {
    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[index].isDropDown = !tmpInputProfit[index].isDropDown;

    setInputProfit([...tmpInputProfit]);
  };

  const handleInputShop = (e) => {
    setShopName(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#111111", alignItems: "center", height: "100%" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <div className={`${styles.font35} ${styles.fontB}`} style={{ position: "absolute", width: "724px", top: "50%", left: "50%", transform: `translate(-50%, -50%)`, zIndex: 10 }}>
          <label style={{ color: "white" }}>지금 내 매출을 입력하고</label>
          <label style={{ color: "#32bbff" }}>&nbsp;빅데이터 기반</label>
          <label style={{ color: "white" }}>
            으로 예측된
            <br /> 향후 예상 매출을 확인해보세요!
          </label>
        </div>
        <img src={reportHeader} style={{ backgroundColor: "#ffffff", opacity: "0.5", height: "40vh", width: "100%", backgroundSize: "cover" }} />
      </div>
      <div className={`${styles.py30}`} style={{ minWidth: "700px", width: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div className={`${styles.py50} ${styles.widthFull}`} style={{ backgroundColor: "#111111" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <label className={`${styles.fontB} ${styles.font30}`} style={{ color: "white" }}>{`분석하실 `}</label>
            <label className={`${styles.fontB} ${styles.font30}`} style={{ color: "#32bbff" }}>
              &nbsp; 데이터
            </label>
            <label className={`${styles.fontB} ${styles.font30}`} style={{ color: "white" }}>{`를 입력해주세요.`}</label>
          </div>
          <div className={`${styles.pt70} ${styles.widthFull}`}>
            <div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  1. 운영중인 업장의 행정동을 선택해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${sidodong !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${sidodong !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${sidodong !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${sidodong !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${inputDongSearch !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                      style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${inputDongSearch !== "" ? "white" : "#767676"}` }}
                      placeholder={"행정동을 검색해 보세요."}
                      value={inputDongSearch || ""}
                      onChange={(e) => handleSearchDong(e)}
                    ></input>
                    <img className={`${styles.mx10}`} src={reportSearch} width={20} height={20} />
                  </label>
                </div>
                <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull} ${styles.ml10}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${sidodong !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>{`${
                    sidodong !== "" ? sidodong : "행정동을 검색후 선택해 주세요."
                  }`}</label>
                </div>
              </div>
              <div className={`${styles.mt10}`}>
                <div className={`${styles.widthFull} ${styles.hideScroll} ${styles.py10}`} style={{ height: "150px", backgroundColor: "#252525", display: "flex", flexDirection: "column", overflow: "scroll" }}>
                  {searchedSidoData &&
                    searchedSidoData.map((item, index) => (
                      <label key={index} className={`${styles.reportHoverItem} ${styles.pl15}`} style={{ width: "100%", color: "white" }} onClick={() => handleSelectDong(index)}>
                        {item.region}
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  2. 운영중인 업장의 업종을 선택해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${upjong !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${upjong !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${upjong !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${upjong !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${inputUpjongSearch !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                      style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${inputUpjongSearch !== "" ? "white" : "#767676"}` }}
                      placeholder={"업종을 검색해 보세요."}
                      value={inputUpjongSearch}
                      onChange={(e) => handleSearchUpjong(e)}
                    ></input>
                    <img className={`${styles.mx10}`} src={reportSearch} width={20} height={20} />
                  </label>
                </div>
                <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull} ${styles.ml10}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjong !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>{`${upjong !== "" ? upjong : "업종을 선택해 주세요."}`}</label>
                </div>
              </div>
              <div className={`${styles.mt10}`}>
                <div className={`${styles.widthFull} ${styles.hideScroll} ${styles.py10}`} style={{ height: "150px", backgroundColor: "#252525", display: "flex", flexDirection: "column", overflow: "scroll" }}>
                  {searchedUpjongData &&
                    searchedUpjongData.map((item, index) => (
                      <label key={index} className={`${styles.reportHoverItem} ${styles.pl15}`} style={{ width: "100%", color: "white" }} onClick={() => handleSelectUpjong(index)}>
                        {item.upjong}
                      </label>
                    ))}
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  3. 운영중인 업장의 이름을 입력해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${shopName !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${shopName !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${shopName !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${shopName !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15} ${styles.widthFull}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${shopName !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                      style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${shopName !== "" ? "white" : "#767676"}` }}
                      placeholder={"업장명을 입력해 주세요."}
                      value={shopName}
                      onChange={(e) => handleInputShop(e)}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  4. 월 매출을 입력해 주세요. (최대 12개월까지 입력 가능)
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${num4chk === true ? "#32bbff" : "#767676"}` }}>
                    {`${num4chk === true ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${num4chk === true ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${num4chk === true ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              {inputProfit.length > 0 &&
                inputProfit.map((item, index) => (
                  <div key={index} className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                      className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}
                      style={{ position: "relative" }}
                      onClick={(e) => {
                        handleDropDownDate(e, index);
                      }}
                    >
                      <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${item.date !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                        {`${item.date !== "" ? item.date : "날짜를 선택해 주세요."}`}
                        <img className={`${styles.mx10}`} src={item.isDropDown ? dropUp : dropDown} width={20} height={13} />
                      </label>
                      {item.isDropDown && (
                        <div className={`${styles.hideScroll} ${styles.pb5}`} style={{ zIndex: 10, position: "absolute", top: "40px", width: "100%", height: "150px", overflowY: "scroll", backgroundColor: "#252525" }}>
                          {dropDate &&
                            dropDate.map((dropItem, dropIndex) => (
                              <div key={dropIndex} className={`${styles.reportHoverItem} ${styles.py3}`} style={{ paddingLeft: "15px" }}>
                                <div style={{ color: "white" }}>{dropItem}</div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>

                    <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.ml10}`}>
                      <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjongstr !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <input
                          className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                          style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${item.profit !== "" ? "white" : "#767676"}`, textAlign: "right" }}
                          placeholder={"매출을 입력해 주세요. (숫자만 입력가능)"}
                          value={addComma(item.profit) === "NaN" ? "" : addComma(item.profit)}
                          onChange={(e) => onChangeProfit(e, index)}
                        ></input>
                        <label className={`${styles.mr10}`} style={{ color: "white" }}>
                          원
                        </label>
                      </label>
                    </div>
                  </div>
                ))}

              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.mt30}`}>
                <div style={{ border: "solid 2px #767676", borderRadius: "50px", width: "36px", height: "36px", padding: "6px 0px 0px 6px", cursor: "pointer" }} onClick={handleDateAdd}>
                  <img src={add} width={20} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.mb100} ${styles.mt30}`} style={{ borderTop: "solid 2px #32bbff", backgroundColor: "#252525", width: "50%", display: "flex", justifyContent: "center" }}>
        <div className={`${styles.row} ${styles.mx0} ${styles.font20} ${styles.py15}`} style={{ width: "50%", justifyContent: "center" }}>
          <label style={{ color: "white", fontWeight: "bold" }}>데이터&nbsp;</label>
          <label style={{ color: "white" }}>분석하기</label>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", borderTop: "solid 1px #252525", height: "250px", width: "100%" }}>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font18}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font15}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
      </div>
    </div>
  );
};

export default ProfitInfo;
