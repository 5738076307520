import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import MembershipProductDiscount from '../../../components/Membership/PC/MembershipProductDiscount';

const PRODUCT = gql`
    query getProducts($machine: String!, $order: String!) {
        getProducts(type: "membership", machine: $machine, order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
                discountStart
                discountEnd
            }
            total
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            username
            name
        }
    }
`;

const REMOVE_ALL = gql`
    mutation removeAllDiscount($machine: String!, $order: String!) {
        removeAllDiscount(
            machine: $machine
            type: "membership"
            order: $order
        ) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
                discountStart
                discountEnd
            }
            total
        }
    }
`;

const REMOVE = gql`
    mutation removeDiscount($productId: Int!) {
        removeDiscount(productId: $productId) {
            id
            category {
                id
                name
            }
            image
            name
            price
            stock
            ignoreStock
            description
            isDiscount
            discountPrice
            discountPercent
            discountStart
            discountEnd
        }
    }
`;

const MembershipProductsDiscount = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const {
        data,
        loading: loadingProduct,
        refetch,
    } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [removeAllDiscountM] = useMutation(REMOVE_ALL);
    const [removeDiscountM] = useMutation(REMOVE);

    const removeAllDiscount = async () => {
        if (!loading) {
            const confirm = window.confirm('정말 해제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await removeAllDiscountM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        order: queryString.parse(location.search).order
                            ? queryString.parse(location.search).order
                            : 'name_asc',
                    },
                });
                setLoading(false);
                if (data && data.removeAllDiscount) {
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const removeDiscount = async product => {
        if (!loading) {
            if (product && product.isDiscount && product.discountPrice > 0) {
                const confirm = window.confirm('정말 해제하시겠습니까?');
                if (confirm) {
                    setLoading(true);
                    const { data } = await removeDiscountM({
                        variables: {
                            productId: product.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.removeDiscount) {
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                }
            }
        }
    };

    const handleOrder = order => {
        if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'price') {
            if (queryString.parse(location.search).order === 'price_asc') {
                applyOrder('price_dsc');
            } else if (
                queryString.parse(location.search).order === 'price_dsc'
            ) {
                applyOrder('price_asc');
            } else {
                applyOrder('price_asc');
            }
        } else if (order === 'start') {
            if (queryString.parse(location.search).order === 'start_asc') {
                applyOrder('start_dsc');
            } else if (
                queryString.parse(location.search).order === 'start_dsc'
            ) {
                applyOrder('start_asc');
            } else {
                applyOrder('start_asc');
            }
        } else if (order === 'end') {
            if (queryString.parse(location.search).order === 'end_asc') {
                applyOrder('end_dsc');
            } else if (queryString.parse(location.search).order === 'end_dsc') {
                applyOrder('end_asc');
            } else {
                applyOrder('end_asc');
            }
        } else {
            applyOrder('name_asc');
        }
    };

    const applyOrder = order => {
        history.replace({
            pathname: '/membership/product/discount/',
            state: location.state,
            search: `?order=${order}`,
        });
    };

    useEffect(() => {
        if (location.state.machine) {
            window.scrollTo(0, 0);
            refetch({
                machine: location.state.machine,
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc',
            });
        } else {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, [queryString.parse(location.search).order]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`할인 적용 관리${
                                    machine && machine.getMachine
                                        ? ` (${machine.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={removeAllDiscount}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        모든 할인 해제
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                    onClick={() =>
                                        history.push({
                                            pathname: `/membership/product/discount/add/`,
                                            state: {
                                                machine: location.state.machine
                                                    ? location.state.machine
                                                    : '',
                                            },
                                        })
                                    }>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        할인 추가하기
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`}
                                    onClick={history.goBack}>
                                    <img
                                        src={
                                            require('../../../assets/images/icon_back.png')
                                                .default
                                        }
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex2}`}></div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('name')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    할인
                                </p>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('price')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    가격
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'price_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'price_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('start')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    할인 적용 시작일
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'start_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'start_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('end')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    할인 적용 종료일
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'end_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'end_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.flex2}`}></div>
                        </div>
                        <div style={{ minHeight: 600 }}>
                            {loadingProduct ? (
                                <Skeleton height={50} count={3} />
                            ) : data &&
                              data.getProducts &&
                              data.getProducts.products.length > 0 ? (
                                data.getProducts.products.map(product => {
                                    return (
                                        <MembershipProductDiscount
                                            key={product.id}
                                            product={product}
                                            removeDiscount={() =>
                                                removeDiscount(product)
                                            }
                                            machine={
                                                machine && machine.getMachine
                                                    ? machine.getMachine
                                                    : {}
                                            }
                                        />
                                    );
                                })
                            ) : (
                                <div
                                    className={`${styles.center}`}
                                    style={{ height: 600 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        아직 등록된 회원권이 없습니다.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipProductsDiscount;
