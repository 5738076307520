import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const OrderMachine = ({
    machine
}) => {
    const history = useHistory()

    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {machine.name}
                </p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`} onClick={() => history.push({
                    pathname: `/order/product/list/`,
                    state: {
                        machine: machine.username
                    },
                    search: '?order=name_asc'
                })}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        관리
                    </p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => history.push({
                    pathname: `/order/product/payment/status/`,
                    state: {
                        machine: machine.username
                    },
                    search: '?order=date_dsc&page=1&q='
                })}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        판매 현황
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OrderMachine;