import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Calendar from "react-calendar";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";
import { bill } from "../../../common/modal";
import { numberReg } from "../../../common/regex";
import useInput from "../../../hooks/useInput";
import NumberFormat from "react-number-format";

const MembershipHistoryItem = ({ membership: defaultMembership, index, removeMembership, editMembership, memberships, confirmHisEditing }) => {
  const price = useInput("");
  const date = useInput("");

  const [editing, setEditing] = useState(false);
  const [membership, setMembership] = useState({
    ...defaultMembership,
    start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
    end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
    membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
    cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
  });
  const [showMembership, setShowMembership] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [calcMembershipCount, setCalcMembershipCount] = useState(Number(membership.membershipCount) - Number(membership.totalDeduct));
  const [calcCancelCount, setCalcCancelCount] = useState(Number(membership.cancelCount) - Number(membership.totalCancel));
  const [addType, setAddType] = useState(membership.payment ? membership.payment.type : "added_by_admin");

  const handleMembership = (product) => {
    if (membership.isUsed) {
      alert("사용중인 회원권은 회원권 변경이 불가능합니다.");
      return;
    }

    if (product.membershipType === 2) {
      let end = new Date();
      end.setFullYear(end.getFullYear() + 10);
      membership.end = end;
    } else {
      membership.start = new Date();
      membership.end = null;
    }

    setMembership({
      ...membership,
      product,
    });
    setShowMembership(false);
  };

  const handleStart = (start) => {
    setMembership({
      ...membership,
      start,
    });
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setMembership({
      ...membership,
      end,
    });
    setShowEnd(false);
  };

  const handlePrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      price.setValue(value);
      setMembership({ ...membership, price: value });
    }
  };

  const handleDate = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      date.setValue(value);
      setMembership({ ...membership, date: value });
    }
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcMembershipCount(value);
      setMembership({
        ...membership,
        membershipCount: Number(value) + (membership.totalDeduct ? Number(membership.totalDeduct) : 0),
      });
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcCancelCount(value);
      setMembership({
        ...membership,
        cancelCount: Number(value) + (membership.totalCancel ? Number(membership.totalCancel) : 0),
      });
    }
  };

  const edit = async () => {
    let tmpPrice = price.value !== "" ? Number(price.value) : null;
    let approvalDate = date.value !== "" ? date.value.substring(0, 6) : null;
    let approvalTime = date.value !== "" ? `${date.value.substring(6, 10)}00` : null;

    if ((tmpPrice === null && date.value !== "") || (tmpPrice !== null && date.value === "")) {
      alert("결제금액과 결제일시는 같이 입력되어야 합니다.");
      return;
    }
    if (approvalTime && approvalTime.length !== 6 && approvalDate && approvalDate.length !== 6) {
      alert("올바른 결제일시를 입력해주세요.");
      return;
    }

    await editMembership({
      ...membership,
      payment: {
        id: -1,
        totalAmount: tmpPrice,
        approvalDate: approvalDate,
        approvalTime: approvalTime,
      },
    });
    setEditing(false);
  };

  const cancelEdit = () => {
    setMembership({
      ...defaultMembership,
      start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
      end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
      membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
      cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
    });
    setEditing(false);
  };

  useEffect(() => {
    price.setValue(membership.payment ? membership.payment.totalAmount : "");
    date.setValue(membership.payment ? membership.payment.approvalDate + membership.payment.approvalTime : "");
  }, [membership.payment]);

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.py15} ${styles.px15} ${index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf}`}>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={editing ? () => setShowMembership(!showMembership) : null}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>{membership.product.name}</p>
        {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}>
        <div style={{ display: membership.product.membershipType === 1 || membership.product.membershipType === 3 ? "flex" : "none" }} onClick={editing ? () => setShowStart(!showStart) : null}>
          <p className={`${styles.fontR} ${styles.font14} ${!membership.start ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.start
              ? `${membership.start.getFullYear()}/${membership.start.getMonth() + 1 < 10 ? "0" + String(membership.start.getMonth() + 1) : membership.start.getMonth() + 1}/${membership.start.getDate() < 10 ? "0" + String(membership.start.getDate()) : membership.start.getDate()}`
              : "선택해주세요."}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}>
        <div style={{ display: membership.product.membershipType === 1 || membership.product.membershipType === 3 ? "flex" : "none" }} onClick={editing ? () => setShowEnd(!showEnd) : null}>
          <p className={`${styles.fontR} ${styles.font14} ${!membership.end ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.end ? `${membership.end.getFullYear()}/${membership.end.getMonth() + 1 < 10 ? "0" + String(membership.end.getMonth() + 1) : membership.end.getMonth() + 1}/${membership.end.getDate() < 10 ? "0" + String(membership.end.getDate()) : membership.end.getDate()}` : "선택해주세요."}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
      </div>
      <div className={`${styles.flex1}`}>
        <NumberFormat
          value={calcMembershipCount}
          thousandSeparator={true}
          className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
          type={"tel"}
          displayType={"input"}
          onValueChange={handleMembershipCount}
          placeholder={editing ? "횟수를 입력해주세요. (숫자만 입력)" : ""}
          style={{
            width: "100%",
            minWidth: 20,
            display: membership.product.membershipType === 2 || membership.product.membershipType === 3 ? "block" : "none",
          }}
          readOnly={!editing}
        />
      </div>

      <div className={`${styles.flex1}`}>
        <NumberFormat
          value={calcCancelCount}
          thousandSeparator={true}
          className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
          type={"tel"}
          displayType={"input"}
          onValueChange={handleCancelCount}
          placeholder={editing ? "취소 가능 횟수를 입력해주세요. (숫자만 입력)" : ""}
          style={{
            width: "100%",
            minWidth: 20,
            display: membership.product.membershipType === 2 || membership.product.membershipType === 3 ? "block" : "none",
          }}
          readOnly={!editing}
        />
      </div>
      <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
        {membership.status === "inactive" && "비활성"}
        {membership.status === "active" && "유효"}
        {membership.status === "ready" && "사용대기"}
        {membership.status === "expired" && "만료"}
        {membership.status === "holding" && "홀딩중"}
      </p>
      {editing && addType === "added_by_admin" ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={price.value}
            thousandSeparator={true}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handlePrice}
            placeholder={"결제 금액을 입력해주세요. (숫자만 입력)"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : (
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>{price.value !== null ? numberWithCommas(price.value) : ""}</p>
      )}
      {editing && addType === "added_by_admin" ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={date.value}
            thousandSeparator={false}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handleDate}
            placeholder={"YYMMDD HH:MM"}
            format={"###### ##:##"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : (
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
          {date.value ? `${date.value.slice(0, 2)}/${date.value.slice(2, 4)}/${date.value.slice(4, 6)}` : ""}
          <br />
          {date.value ? `${date.value.slice(6, 8)}:${date.value.slice(8, 10)}:${date.value.slice(10, 12) === "" ? "00" : date.value.slice(10, 12)}` : ""}
        </p>
      )}

      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        {editing ? (
          <>
            <p
              className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
              onClick={() => {
                edit();
                confirmHisEditing(false);
              }}
            >
              확인
            </p>
            <p
              className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
              onClick={() => {
                cancelEdit();
                confirmHisEditing(false);
              }}
            >
              취소
            </p>
          </>
        ) : (
          <>
            <p
              className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
              onClick={() => {
                setEditing(true);
                confirmHisEditing(true);
              }}
            >
              변경
            </p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={removeMembership}>
              삭제
            </p>
          </>
        )}
      </div>
      <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={bill}>
        <div
          className={`${styles.bgWhite} ${styles.hideScroll}`}
          style={{
            maxHeight: 300,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {memberships
            ? memberships.map((item) => {
                return (
                  <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                    <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                  </div>
                );
              })
            : null}
        </div>
        <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
          <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
        </div>
      </Modal>
      <Modal isOpen={showStart} onRequestClose={() => setShowStart(false)} style={bill}>
        <Calendar
          calendarType={"US"}
          value={membership.start}
          maxDate={membership.end ? membership.end : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleStart}
        />
      </Modal>
      <Modal isOpen={showEnd} onRequestClose={() => setShowEnd(false)} style={bill}>
        <Calendar
          calendarType={"US"}
          value={membership.end}
          minDate={membership.start ? membership.start : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleEnd}
        />
      </Modal>
    </div>
  );
};

export default MembershipHistoryItem;
