import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';
import queryString from "query-string";

const Device = ({ device, location }) => {
    const history = useHistory();

    const date = new Date(Number(device.createdAt));

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()}`}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {device.name}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {device.status === 'inuse' && '사용중'}
                    {device.status === 'notuse' && '미사용'}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {device.user?.name}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(device.productTotal)}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(device.advertiseTotal)}
                </p>
            </div>
            <div
                className={`${styles.flex3} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/video/',
                            state: {
                                device,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        영상
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/edit/',
                            state: {
                                device,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        광고
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/detail/',
                            state: {
                                machine: device.username,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        기기
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() => {
                        location.state = {
                            machine: device.username,
                            machineId: device.id,
                        }

                        history.push({
                            pathname: '/member/list/',
                            state: location.state,
                            search: `?order=name_asc&page=1&q=`,
                        })
                    }}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        회원
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() => {
                        history.push({
                            pathname: '/entrance/edit/',
                            state: {
                                machine: device.username
                            }
                        })
                    }}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        출입 통제
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Device;
