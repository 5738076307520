import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import SiteProduct from "../../../components/Site/PC/SiteProduct";
import cache from "../../../apollo/cache";

const PRODUCT = gql`
  query getProducts($machine: String!, $order: String!) {
    getProducts(type: "site", machine: $machine, order: $order) {
      products {
        id
        category {
          id
          name
        }
        image
        name
        price
        stock
        ignoreStock
        slot
        description
        isDiscount
        discountPrice
        discountPercent
        isDisplay
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      name
    }
  }
`;

const DELETE = gql`
  mutation deleteProduct($machine: String!, $productId: Int!) {
    deleteProduct(machine: $machine, productId: $productId)
  }
`;

const SiteProducts = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: loadingProduct,
    refetch,
  } = useQuery(PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
    },
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [deleteProductM] = useMutation(DELETE);

  const handleOrder = (order) => {
    if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "category") {
      if (queryString.parse(location.search).order === "category_asc") {
        applyOrder("category_dsc");
      } else if (queryString.parse(location.search).order === "category_dsc") {
        applyOrder("category_asc");
      } else {
        applyOrder("category_asc");
      }
    } else if (order === "slot") {
      if (queryString.parse(location.search).order === "slot_asc") {
        applyOrder("slot_dsc");
      } else if (queryString.parse(location.search).order === "slot_dsc") {
        applyOrder("slot_asc");
      } else {
        applyOrder("slot_asc");
      }
    } else if (order === "price") {
      if (queryString.parse(location.search).order === "price_asc") {
        applyOrder("price_dsc");
      } else if (queryString.parse(location.search).order === "price_dsc") {
        applyOrder("price_asc");
      } else {
        applyOrder("price_asc");
      }
    } else if (order === "stock") {
      if (queryString.parse(location.search).order === "stock_asc") {
        applyOrder("stock_dsc");
      } else if (queryString.parse(location.search).order === "stock_dsc") {
        applyOrder("stock_asc");
      } else {
        applyOrder("stock_asc");
      }
    } else if (order === "display") {
      if (queryString.parse(location.search).order === "display_asc") {
        applyOrder("display_dsc");
      } else if (queryString.parse(location.search).order === "display_dsc") {
        applyOrder("display_asc");
      } else {
        applyOrder("display_asc");
      }
    } else {
      applyOrder("name_asc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/site/product/list/",
      state: location.state,
      search: `?order=${order}`,
    });
  };

  const remove = async (product) => {
    if (!loading) {
      const confirm = window.confirm("정말 삭제하시겠습니까?");
      if (confirm) {
        setLoading(true);
        const { data } = await deleteProductM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            productId: product.id,
          },
        });
        setLoading(false);
        if (data && data.deleteProduct) {
          cache.evict({
            id: `Product:${product.id}`,
          });
          refetch();
          alert("상품을 삭제하였습니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (location.state.machine) {
      window.scrollTo(0, 0);
      refetch({
        machine: location.state.machine,
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
      });
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).order]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`현장 판매 상품 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer}`}
                  onClick={() =>
                    history.push({
                      pathname: `/site/product/ordering/`,
                      state: {
                        machine: location.state.machine ? location.state.machine : "",
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>노출 순서 변경</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  onClick={() =>
                    history.push({
                      pathname: `/site/product/discount/`,
                      state: {
                        machine: location.state.machine ? location.state.machine : "",
                      },
                      search: "?order=name_asc",
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>할인 적용 관리</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  onClick={() =>
                    history.push({
                      pathname: `/site/product/add/`,
                      state: {
                        machine: location.state.machine ? location.state.machine : "",
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>상품 추가하기</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img
                    src={require("../../../assets/images/icon_back.png").default}
                    alt={"이전"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 45 * 0.2,
                      height: 79 * 0.2,
                    }}
                  />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("display")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>전시 상태</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "display_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "display_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex20}`}></div>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상품명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex30} ${styles.px30}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상품설명</p>
              </div>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("category")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>분류</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "category_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "category_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("slot")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>슬롯 번호</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "slot_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "slot_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("price")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>가격</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "price_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "price_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex10} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("stock")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>현재 수량</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "stock_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "stock_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex10}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>할인</p>
              </div>
              <div className={`${styles.flex18}`}></div>
            </div>
            <div style={{ minHeight: 600 }}>
              {loadingProduct ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getProducts && data.getProducts.products.length > 0 ? (
                data.getProducts.products.map((product) => {
                  return <SiteProduct key={product.id} product={product} remove={() => remove(product)} machine={machine && machine.getMachine ? machine.getMachine : {}} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>아직 등록된 상품이 없습니다.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteProducts;
