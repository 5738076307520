import React, { useEffect, useRef, useState } from "react";

import styles from "../../../styles/styles.module.scss";
import MenuModule from "./MenuModule";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const MenuControlModal = ({ closeModal, menuList, handleSaveMenuList }) => {
  let dragfrom;
  let dragto;

  const [modalmenuList, setModalMenuList] = useState([
    { menuName: "회원권 구매", menuText: menuList.txtBuyMembership, ischked: menuList.buyMembership, seq: menuList.seqBuyMembership },
    { menuName: "운동 가이드 영상 보기", menuText: menuList.txtShowGuideMovie, ischked: menuList.showGuideMovie, seq: menuList.seqShowGuideMovie },
    { menuName: "상담 신청", menuText: menuList.txtCallCunsult, ischked: menuList.callCunsult, seq: menuList.seqCallCunsult },
    { menuName: "출석체크", menuText: menuList.txtEntranceCheck, ischked: menuList.entranceCheck, seq: menuList.seqEntranceCheck },
    { menuName: "현장 판매 상품 구매", menuText: menuList.txtBuyCurItem, ischked: menuList.buyCurItem, seq: menuList.seqBuyCurItem },
    { menuName: "주문 판매 상품 구매", menuText: menuList.txtBuyorderItem, ischked: menuList.buyorderItem, seq: menuList.seqBuyorderItem },
  ]);

  const handleModifyMenuList = (from, to) => {
    let newmenuList = [...modalmenuList];
    newmenuList.splice(from, 1);
    newmenuList.splice(to, 0, modalmenuList[from]);

    for (let i = 0; i < newmenuList.length; i++) {
      newmenuList[i].seq = i + 1;
    }
    sortList(newmenuList);

    setModalMenuList([...newmenuList]);
  };

  const handleChecked = (item, index) => {
    let newmenuList = [...modalmenuList];
    newmenuList[index].ischked = !newmenuList[index].ischked;

    setModalMenuList([...newmenuList]);
  };

  const handleMenuText = (e, index) => {
    let newmenuList = [...modalmenuList];
    newmenuList[index].menuText = e.target.value;
    setModalMenuList([...newmenuList]);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    dragfrom = result.source.index;
    dragto = result.destination.index;

    handleModifyMenuList(dragfrom, dragto);
  };

  const sortList = (list) => {
    list.sort(function (a, b) {
      return a.seq - b.seq;
    });
  };

  useEffect(() => {
    let newmodalmenuList = modalmenuList;
    sortList(newmodalmenuList);
    setModalMenuList([...newmodalmenuList]);
  }, []);

  return (
    <div className={`${styles.bgWhite}`}>
      <div className={`${styles.mx0} ${styles.px70} ${styles.py30} ${styles.center}`}>
        <div className={`${styles.row} ${styles.justifyContentBetween} ${styles.flex1} ${styles.widthFull}`}>
          <div>
            <div className={`${styles.mb50} ${styles.fontB} ${styles.font20} ${styles.black}`}>
              <p>기기 메뉴 관리</p>
            </div>
          </div>
          <div>
            <img
              src={require("../../../assets/images/icon_close.png").default}
              alt={"이전"}
              className={`${styles.cursorPointer}`}
              style={{
                width: 45 * 0.6,
                height: 45 * 0.6,
              }}
              onClick={closeModal}
            />
          </div>
        </div>
        <div className={`${styles.right}`} style={{ width: 615 }}>
          <div>
            <p className={`${styles.grayA2} ${styles.font12}`}>*드래그하여 메뉴 순서를 변경할 수 있습니다.</p>
            <p className={`${styles.grayA2} ${styles.font12}`}>*줄바꿈은 /n을 입력해주세요.</p>
          </div>
        </div>
        <div className={`${styles.borderGrayA2} ${styles.mx0} ${styles.borderRadius10} ${styles.mt10} ${styles.flex1} ${styles.justifyContentBetween}`} style={{ width: 615 }}>
          <div className={`${styles.row} ${styles.flex1} ${styles.mx0} ${styles.textCenter}`}>
            <div className={`${styles.font16} ${styles.fontM} ${styles.px10} ${styles.py15} ${styles.borderRightGrayA2}`} style={{ width: 175 }}>
              메뉴명
            </div>
            <div className={`${styles.font16} ${styles.fontM} ${styles.px10} ${styles.py15} ${styles.borderRightGrayA2} ${styles.textCenter}`} style={{ width: 395.5 }}>
              표시할 메뉴명
            </div>
            <div className={`${styles.font16} ${styles.fontM} ${styles.px10} ${styles.py15}`} style={{ width: 25 }}></div>
          </div>
          <div style={{ minHeight: 264 }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable_1">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {modalmenuList &&
                      modalmenuList.map((item, index) => (
                        <Draggable key={`${item.menuName}-${index}`} draggableId={`${item.menuName}-${index}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                left: snapshot.isDragging ?? "0px important!",
                                top: snapshot.isDragging ?? "0px important!",
                              }}
                            >
                              <MenuModule index={index} item={item} handleChecked={handleChecked} handleMenuText={handleMenuText} handleModifyMenuList={handleModifyMenuList} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div className={`${styles.center}`}>
          <div className={`${styles.footer} ${styles.textCenter} ${styles.column}`}>
            <div className={`${styles.mb50} ${styles.justifyContentBetween}`}>
              <button onClick={closeModal} className={`${styles.px100} ${styles.py15} ${styles.mx10} ${styles.mt10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                취소
              </button>
              <button onClick={() => handleSaveMenuList(modalmenuList)} className={`${styles.px100} ${styles.py15} ${styles.mx10} ${styles.mt10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuControlModal;
