import React  from 'react';
import {numberWithCommas} from "../../common/utility";

const BarChartTooltip = (d) => {
    return (
        <div
            style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
            }}
        >
            <div>{d.data.x}({d.data.count}개): <span style={{fontWeight: 'bold'}}>{numberWithCommas(d.data.y)}</span></div>
        </div>
    );
};

export default BarChartTooltip;
