import React, { useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg } from '../../../common/regex';
import IntroImage from '../../../components/Home/IntroImage';
import { FETCH_URL } from '../../../config';

const CATEGORY = gql`
    {
        getVideoCategory {
            id
            name
            hasDetailCategory
        }
    }
`;

const DETAIL_CATEGORY = gql`
    {
        getVideoDetailCategoryAll {
            id
            categoryId
            name
        }
    }
`;

const ADD = gql`
    mutation addVideo(
        $videoId: String!
        $name: String!
        $categoryId: Int!
        $detailCategoryId: Int
        $duration: Int!
        $status: String!
        $qr: String!
        $image: String!
    ) {
        addVideo(
            videoId: $videoId
            name: $name
            categoryId: $categoryId
            detailCategoryId: $detailCategoryId
            duration: $duration
            status: $status
            qr: $qr
            image: $image
        ) {
            success
            error
            result {
                id
                name
                category {
                    id
                    name
                }
                detailCategory {
                    id
                    name
                }
                status
                machinesCount
            }
        }
    }
`;

const VideoAdd = () => {
    const history = useHistory();

    const videoId = useInput('');
    const duration = useInput('');
    const name = useInput('');

    const imageRef = useRef();

    const [category, setCategory] = useState({
        id: -1,
        name: '',
        hasDetailCategory: false,
    });
    const [showCategory, setShowCategory] = useState(false);
    const [detailCategory, setDetailCategory] = useState({
        id: -1,
        categoryId: -1,
        name: '',
    });
    const [showDetailCategory, setShowDetailCategory] = useState(false);
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const [qR, setQR] = useState('');
    const [uploadQR, setUploadQR] = useState(null);
    const [image, setImage] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const { data: categorys } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: detailCategorys } = useQuery(DETAIL_CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });

    const [addVideoM] = useMutation(ADD);

    const handleDuration = e => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value)) {
            duration.onChange(e);
        }
    };

    const handleCategory = category => {
        setCategory(category);
        setShowCategory(false);
        setDetailCategory({
            id: -1,
            categoryId: -1,
            name: '',
        });
    };

    const handleDetailCategory = detailCategory => {
        setDetailCategory(detailCategory);
        setShowDetailCategory(false);
    };

    const handleStatus = status => {
        setStatus(status);
        setShowStatus(false);
    };

    const addQR = async e => {
        if (!loadingImage) {
            const files = e.target.files;

            let error = false;
            if (
                files[0].type === 'image/jpg' ||
                files[0].type === 'image/jpeg' ||
                files[0].type === 'image/png'
            ) {
            } else {
                error = true;
            }
            if (error) {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            } else {
                setLoadingImage(true);
                let file = files[0];
                setQR(file.name);
                setUploadQR(file);
                setLoadingImage(false);
            }
        }
    };

    const addImage = async e => {
        if (!loadingImage) {
            const file = e.target.files[0];
            if (
                file &&
                (file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png')
            ) {
                var _URL = window.URL || window.webkitURL;
                setLoadingImage(true);
                setUploadImage(file);
                var img = new Image();
                var reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result);
                };
                img.src = await _URL.createObjectURL(file);
                await reader.readAsDataURL(file);
                setLoadingImage(false);
            } else {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            }
        }
    };

    const removeImage = () => {
        setImage(null);
        setUploadImage(null);
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (
                !(
                    videoId.value &&
                    name.value &&
                    category.id > -1 &&
                    duration.value &&
                    status &&
                    qR &&
                    uploadQR &&
                    image &&
                    uploadImage
                )
            ) {
                alert('위 정보를 입력해주세요.');
                return;
            }
            if (category.hasDetailCategory) {
                if (!(detailCategory.id > -1)) {
                    alert('세부 카테고리를 선택해주세요.');
                    return;
                }
            }
            setLoading(true);
            try {
                let saveQR = '';
                const qrData = new FormData();
                qrData.append('productimage', uploadQR, uploadQR.name);
                const { data: dataQR } = await axios.post(
                    `${FETCH_URL}/api/upload`,
                    qrData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                );
                if (dataQR && dataQR.location) {
                    saveQR = dataQR.location;
                }
                let saveImage = '';
                const imageData = new FormData();
                imageData.append('productimage', uploadImage, uploadImage.name);
                const { data: dataImage } = await axios.post(
                    `${FETCH_URL}/api/upload`,
                    imageData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                );
                if (dataImage && dataImage.location) {
                    saveImage = dataImage.location;
                }
                if (!(saveQR && saveImage)) {
                    alert('오류가 발생하였습니다.');
                    return;
                }
                const { data } = await addVideoM({
                    variables: {
                        videoId: videoId.value,
                        name: name.value,
                        categoryId: category.id,
                        detailCategoryId:
                            detailCategory.id > -1 ? detailCategory.id : null,
                        duration: Number(duration.value),
                        status,
                        qr: saveQR,
                        image: saveImage,
                    },
                });
                setLoading(false);
                if (data && data.addVideo.success) {
                    alert('등록되었습니다.');
                    initialize();
                } else if (data && data.addVideo.error) {
                    alert(data.addVideo.error);
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        }
    };

    const initialize = () => {
        videoId.setValue('');
        duration.setValue('');
        name.setValue('');
        setCategory({
            id: -1,
            name: '',
            hasDetailCategory: false,
        });
        setShowCategory(false);
        setDetailCategory({
            id: -1,
            categoryId: -1,
            name: '',
        });
        setShowDetailCategory(false);
        setStatus('');
        setShowStatus(false);
        setQR('');
        setUploadQR(null);
        setImage(null);
        setUploadImage(null);
        setLoading(false);
        setLoadingImage(false);
        if (imageRef.current) {
            imageRef.current.value = '';
        }
    };

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                새 영상 등록
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            영상 ID*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'videoId'}
                                        value={videoId.value}
                                        onChange={videoId.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={'영상 ID를 입력해주세요.'}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            영상 시간*
                                        </p>
                                    </div>
                                    <input
                                        type={'tel'}
                                        name={'duration'}
                                        value={duration.value}
                                        onChange={handleDuration}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '영상 시간을 입력해주세요. (초)'
                                        }
                                        maxLength={20}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            영상명*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        value={name.value}
                                        onChange={name.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={'영상명을 입력해주세요.'}
                                    />
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius: showCategory
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius:
                                                showCategory ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowCategory(!showCategory)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                카테고리*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    category && category.id > -1
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {category && category.id > -1
                                                    ? category.name
                                                    : '카테고리를 선택해주세요.'}
                                            </p>
                                            <img
                                                src={
                                                    showCategory
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'카테고리 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showCategory && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.hideScroll}`}
                                                style={{
                                                    width: 615,
                                                    maxHeight: 300,
                                                    overflowY: 'scroll',
                                                }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    {categorys &&
                                                    categorys.getVideoCategory
                                                        ? categorys.getVideoCategory.map(
                                                              c => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              c.id
                                                                          }
                                                                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                          onClick={() =>
                                                                              handleCategory(
                                                                                  c,
                                                                              )
                                                                          }>
                                                                          <p
                                                                              className={`${styles.font16} ${styles.black}`}>
                                                                              {
                                                                                  c.name
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius: showStatus
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showStatus
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() =>
                                            setShowStatus(!showStatus)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                상태*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    status
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {status === '' &&
                                                    '상태를 선택해주세요.'}
                                                {status === 'inuse' &&
                                                    '전체송출'}
                                                {status === 'selective' &&
                                                    '선택송출'}
                                                {status === 'stop' &&
                                                    '송출중지'}
                                            </p>
                                            <img
                                                src={
                                                    showStatus
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'날짜 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showStatus && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus(
                                                                'inuse',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            전체송출
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus(
                                                                'selective',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            선택송출
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus('stop')
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            송출중지
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius:
                                                showDetailCategory ? 0 : 10,
                                            borderBottomRightRadius:
                                                showDetailCategory ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowDetailCategory(
                                                !showDetailCategory,
                                            )
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                세부 카테고리
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    detailCategory &&
                                                    detailCategory.id > -1
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {detailCategory &&
                                                detailCategory.id > -1
                                                    ? detailCategory.name
                                                    : '세부 카테고리를 선택해주세요.'}
                                            </p>
                                            <img
                                                src={
                                                    showDetailCategory
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'세부 카테고리 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showDetailCategory && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.hideScroll}`}
                                                style={{
                                                    width: 615,
                                                    maxHeight: 300,
                                                    overflowY: 'scroll',
                                                }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    {detailCategorys &&
                                                    detailCategorys.getVideoDetailCategoryAll
                                                        ? detailCategorys.getVideoDetailCategoryAll.map(
                                                              c => {
                                                                  if (
                                                                      category.id ===
                                                                      c.categoryId
                                                                  ) {
                                                                      return (
                                                                          <div
                                                                              key={
                                                                                  c.id
                                                                              }
                                                                              className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                              onClick={() =>
                                                                                  handleDetailCategory(
                                                                                      c,
                                                                                  )
                                                                              }>
                                                                              <p
                                                                                  className={`${styles.font16} ${styles.black}`}>
                                                                                  {
                                                                                      c.name
                                                                                  }
                                                                              </p>
                                                                          </div>
                                                                      );
                                                                  }
                                                              },
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 1250,
                                        }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                QR 코드*
                                            </p>
                                        </div>
                                        <label
                                            htmlFor="qr"
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.cursorPointer} ${styles.textAbbr}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    qR
                                                        ? styles.black
                                                        : styles.grayA2
                                                } ${styles.flex1}`}>
                                                {qR === ''
                                                    ? 'QR 코드를 선택해주세요.'
                                                    : qR.startsWith(
                                                          'https://venbrothers.s3.ap-northeast-2.amazonaws.com/',
                                                      )
                                                    ? qR.replace(
                                                          'https://venbrothers.s3.ap-northeast-2.amazonaws.com/',
                                                          '',
                                                      )
                                                    : qR}
                                            </p>
                                            <input
                                                id={'qr'}
                                                className={`${styles.none}`}
                                                type={'file'}
                                                accept={'.jpg,.jpeg,.png'}
                                                onChange={addQR}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        이미지*
                                    </p>
                                    <label htmlFor="image">
                                        <div
                                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                            style={{
                                                opacity: loadingImage ? 0.4 : 1,
                                            }}>
                                            <p
                                                className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                                이미지 추가하기
                                            </p>
                                        </div>
                                    </label>
                                    <input
                                        id={'image'}
                                        ref={imageRef}
                                        className={`${styles.none}`}
                                        type={'file'}
                                        accept={'.jpg,.jpeg,.png'}
                                        onChange={addImage}
                                    />
                                </div>
                                {image ? (
                                    <div
                                        className={`${styles.px40} ${styles.mt30}`}>
                                        <IntroImage
                                            image={image}
                                            index={0}
                                            removeImage={removeImage}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`${styles.px50} ${styles.mt30}`}>
                                        <label htmlFor="image">
                                            <div
                                                className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer}`}
                                                style={{
                                                    width: 205.6,
                                                    height: 150,
                                                }}>
                                                <img
                                                    src={
                                                        require('../../../assets/images/icon_add.png')
                                                            .default
                                                    }
                                                    alt={'add'}
                                                    className={`${styles.icon20}`}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        완료
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoAdd;
