import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from "../../../apollo/cache";

const EDIT = gql`
    mutation editPtRequest(
        $id: Int!
        $status: String!
        $result: String
    ){
        editPtRequest(
            id: $id
            status: $status
            result: $result
        ){
            ptRequest{
                id
                name
                contact
                category{
                    id
                    name
                }
                extra
                form
                status
                createdAt
                result
            }
            error
        }
    }
`

const DELETE = gql`
    mutation deletePtRequest($id: Int!){
        deletePtRequest(id: $id)
    }
`

const PRODUCT = gql`
    query getPtRequest($id: Int!){
        getPtRequest(id: $id){
            id
            name
            contact
            category{
                id
                name
            }
            extra
            form
            status
            createdAt
            result
        }
    }
`

const ConsultProductEdit = () => {
    const history = useHistory()
    const location = useLocation()

    const result = useInput("")
    
    const [form, setForm] = useState([])
    const [status, setStatus] = useState("ready")
    const [showStatus, setShowStatus] = useState(false)
    const [date, setDate] = useState(new Date())
    const [loading, setLoading] = useState(false)

    const { data:product } = useQuery(PRODUCT, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: location.state.consultId ? location.state.consultId : -1
        },
        onError: () => {
            alert("상담이 존재하지 않습니다.")
            history.goBack()
        }
    })

    const [editPtRequestM] = useMutation(EDIT)
    const [deletePtRequestM] = useMutation(DELETE)

    const handleStatus = (status) => {
        setStatus(status)
        setShowStatus(false)
    }

    const submit = async(e) => {
        e.preventDefault()
        if(!loading){
            if(product && product.getPtRequest){
                if(status){
                    setLoading(true)
                    try{
                        const { data } = await editPtRequestM({
                            variables: {
                                id: product.getPtRequest.id,
                                status,
                                result: result.value
                            }
                        })
                        if(data && data.editPtRequest){
                            if(data.editPtRequest.error){
                                setLoading(false)
                                alert(data.editProduct.error)
                            }
                            else{
                                setLoading(false)
                                alert("상담을 수정하였습니다.")
                                history.goBack()
                            }
                        }
                        else{
                            setLoading(false)
                            alert("오류가 발생하였습니다.")
                        }
                    }
                    catch{
                        setLoading(false)
                        alert("오류가 발생하였습니다.")
                    }
                }
                else{
                    alert("위의 정보를 입력해주세요.")
                }
            }
        }
    }

    const remove = async() => {
        if(!loading){
            if(product && product.getPtRequest){
                const confirm = window.confirm("정말 삭제하시겠습니까?")
                if(confirm){
                    setLoading(true)
                    try{
                        const { data } = await deletePtRequestM({
                            variables: {
                                id: product.getPtRequest.id
                            }
                        })
                        setLoading(false)
                        if(data && data.deletePtRequest){
                            alert("삭제하였습니다.")
                            cache.evict({
                                id: `PtRequest:${product.getPtRequest.id}`,
                            });
                            history.goBack()
                        }
                        else{
                            alert("오류가 발생하였습니다.")
                        }
                    }
                    catch{
                        setLoading(false)
                        alert("오류가 발생하였습니다.")
                    }
                }
            }
        }
    }

    const initialize = async() => {
        if(product && product.getPtRequest){
            result.setValue(product.getPtRequest.result ? product.getPtRequest.result : "")
            setForm(product.getPtRequest.form ? JSON.parse(product.getPtRequest.form) : [])
            setDate(new Date(Number(product.getPtRequest.createdAt)))
            setStatus(product.getPtRequest.status)
            setShowStatus(false)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(product && product.getPtRequest){
            initialize()
        }
    }, [product])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        상담 신청 내용 확인
                    </p>
                    {(product && product.getPtRequest) ? (
                        <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        신청일시
                                    </p>
                                </div>
                                <input 
                                type={"text"} 
                                name={"date"} 
                                value={`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0' + String(date.getDate()) : date.getDate()} ${date.getHours() < 10 ? '0' + String(date.getHours()) : date.getHours()}:${date.getMinutes() < 10 ? '0' + String(date.getMinutes()) : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + String(date.getSeconds()) : date.getSeconds()}`} 
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} 
                                readOnly={true}
                                />
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        신청자명
                                    </p>
                                </div>
                                <input 
                                type={"text"} 
                                name={"name"} 
                                value={product.getPtRequest.name} 
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} 
                                readOnly={true}
                                />
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        신청자 연락처
                                    </p>
                                </div>
                                <input 
                                type={"text"} 
                                name={"contact"} 
                                value={product.getPtRequest.contact} 
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} 
                                readOnly={true}
                                />
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        신청 내용
                                    </p>
                                </div>
                                <input 
                                type={"text"} 
                                name={"category"} 
                                value={product.getPtRequest.category ? product.getPtRequest.category.name : "상담신청"} 
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} 
                                readOnly={true}
                                />
                            </div>
                            {(form.length > 0) ? (
                                form.map(item => {
                                    if(item.type === "text"){
                                        return(
                                            <div key={item.id} className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                    {item.title}
                                                </p>
                                                <textarea 
                                                name={`${item.id}`}
                                                value={item.value}
                                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                                                readOnly={true}
                                                />
                                            </div>
                                        )
                                    }
                                    else if(item.type === "select"){
                                        return(
                                            <div key={item.id} className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                    {item.title}
                                                </p>
                                                <div className={`${styles.bgWhite} ${styles.py10} ${styles.widthFull}`}>
                                                    {item.selected ? item.selected.map((selected, index) => {
                                                        return(
                                                            <div key={selected.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${(index === 0) ? "" : styles.mt15}`}>
                                                                <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon15}`}>
                                                                    <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 9, height: 9 }} />
                                                                </div>
                                                                <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.flex1} ${styles.ml10}`}>
                                                                    {selected.title}
                                                                </p>
                                                            </div>
                                                        )
                                                    }) : null}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            ) : (
                                null
                            )}
                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    비고
                                </p>
                                <textarea 
                                name={"extra"}
                                value={product.getPtRequest.extra}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                                style={{ height: 120 }}
                                readOnly={true}
                                />
                            </div>
                            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} style={{ borderBottomLeftRadius: showStatus ? 0 : 10, borderBottomRightRadius: showStatus ? 0 : 10 }} onClick={() => setShowStatus(!showStatus)}>
                                    <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                        <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            처리 여부
                                        </p>
                                    </div>
                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                        <input 
                                        type={"text"} 
                                        value={(status === "ready") ? (
                                            "미처리"
                                        ) : (
                                            (status === "progress") ? (
                                                "처리중"
                                            ) : (
                                                (status === "done") ? (
                                                    "처리완료"
                                                ) : (
                                                    ""
                                                )
                                            )
                                        )}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                        style={{ minWidth: 20 }}
                                        placeholder={"추가할 수량을 입력해주세요."}
                                        readOnly={true}
                                        />
                                        <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"상품 분류 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                                    </div>
                                </div>
                                {showStatus && (
                                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                            <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}>
                                                
                                            </div>
                                            <div className={`${styles.py10} ${styles.flex1}`}>
                                                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("ready")}>
                                                    미처리
                                                </p>
                                                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("progress")}>
                                                    처리중
                                                </p>
                                                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("done")}>
                                                    처리완료
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    처리 내용
                                </p>
                                <textarea 
                                name={"result"}
                                value={result.value}
                                onChange={result.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                                style={{ height: 120 }}
                                placeholder={"처리 내용을 입력해주세요."}
                                maxLength={50}
                                />
                            </div>
                            <div className={`${styles.mt45}`}>
                                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={remove}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        삭제
                                    </p>
                                </div>
                            </div>
                        </form>
                    ) : (
                        null
                    )}
                </div>
            </div>
        </div>
    )
}

export default ConsultProductEdit;