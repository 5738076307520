import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const OrderHomePayment = ({ payment }) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`}
            onClick={() =>
                history.push({
                    pathname: '/order/product/payment/detail/',
                    state: {
                        payment,
                        machine: payment.machine.username,
                    },
                })
            }>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.approvalDate.slice(
                        0,
                        2,
                    )}/${payment.approvalDate.slice(
                        2,
                        4,
                    )}/${payment.approvalDate.slice(
                        4,
                        6,
                    )} - ${payment.approvalTime.slice(
                        0,
                        2,
                    )}:${payment.approvalTime.slice(
                        2,
                        4,
                    )}:${payment.approvalTime.slice(4, 6)}`}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.machine.name}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.userName}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.userContact}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.productName}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(payment.totalAmount)} 원
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.issuerInfo ? payment.issuerInfo : ''}(${
                        payment.installment === 0
                            ? '일시불'
                            : `${payment.installment}개월`
                    })`}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline}`}>
                    {payment.status === 'paid' && '결제완료'}
                    {payment.status === 'cancelled' && '결제취소'}
                    {payment.status === 'refund' && '환불'}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline}`}>
                    {payment.receiveStatus === 'none' && '미처리'}
                    {payment.receiveStatus === 'prepare' && '상품준비중'}
                    {payment.receiveStatus === 'ready' && '상품준비완료'}
                    {payment.receiveStatus === 'delivering' && '배송중'}
                    {payment.receiveStatus === 'done' && '수령 완료'}
                </p>
            </div>
        </div>
    );
};

export default OrderHomePayment;
