import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import ReservationListItem from "../../../components/Schedule/PC/ReservationListItem";
import {setDateFormatKor_aahhmm, setDateFormatKor_day, setDateFormatKor_yyyyMMdd} from "../../../common/utility";

const DETAIL_SCHEDULE = gql`
    query getSchedule($scheduleId: Int) {
        getSchedule(scheduleId: $scheduleId) {
            schedule {
                id
                name
                limit
                minusCount
                availableTime
                cancelTime
                date
                startTime
                endTime
#                reservationCnt
                reservationStatus1And3Cnt
                waitingCnt
                category {
                    name
                }
                teacher {
                    name
                }
            }
        }
    }
`;

const RESERVATION = gql`
    query getReservation($scheduleId: Int!, $order: String!, $skip: Int!) {
        getReservation(scheduleId: $scheduleId, order: $order, skip: $skip) {
            memberships{
                id
                product{
                    id
                    name
                    membershipType
                }
                member{
                    name
                    gender
                    mobile
                }
                scheduleReservations {
                    id
                    status
                    isAttendance
                }
                start
                end
                membershipCount
                totalDeduct
                scheduleReservationStatus
            }
            total
        }
    }
`;

const WAITING = gql`
    query getWaiting($scheduleId: Int!, $order: String!, $skip: Int!) {
        getWaiting(scheduleId: $scheduleId, order: $order, skip: $skip) {
            memberships{
                id
                product{
                    id
                    name
                    membershipType
                }
                member{
                    name
                    gender
                    mobile
                }
                scheduleReservations {
                    id
                    status
                    isAttendance
                }
                start
                end
                membershipCount
                totalDeduct
                scheduleReservationStatus
            }
            total
        }
    }
`;

const CHANGE_STATUS = gql`
    mutation changeReservationStatus(
        $machine: String!
        $scheduleId: Int!
        $membershipId: Int!
        $status: Int!
    ) {
        changeReservationStatus(machine: $machine, scheduleId: $scheduleId, membershipId: $membershipId, status: $status) {
            success
            error
        }
    }
`;


const ReservationList = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const [changeReservationStatusM] = useMutation(CHANGE_STATUS);

    const { data:schedule, loading:loadingSchedule, refetch:scheduleRefetch } = useQuery(DETAIL_SCHEDULE, {
        fetchPolicy: "cache-and-network",
        variables: {
            scheduleId: location.state.scheduleId ? location.state.scheduleId : -1,
        }
    });

    const { data:reservation, loading:loadingReservation, refetch:reservationRefetch } = useQuery(RESERVATION, {
        fetchPolicy: "cache-and-network",
        variables: {
            scheduleId: location.state.scheduleId? location.state.scheduleId:-1,
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "reservation_name_dsc",
            skip: 0
        },
        onCompleted: rev => {
            console.log(rev);
        }
    });

    const { data:waiting, loading:loadingWaiting, refetch:waitingRefetch } = useQuery(WAITING, {
        fetchPolicy: "cache-and-network",
        variables: {
            scheduleId: location.state.scheduleId? location.state.scheduleId:-1,
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "waiting_name_dsc",
            skip: 0
        },
        onCompleted: wai => {
            console.log(wai);
        }
    });

    const handlePage = (page) => {
        history.replace({
            pathname: `/schedule/reservation/list/`,
            state: location.state,
            search: `?page=${page.selected + 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "reservation_name_dsc"}`
        })
    }

    const handleTPage = (page) => {
        history.replace({
            pathname: `/schedule/reservation/list/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "reservation_name_dsc"}`
        })
    }

    const handleOrder = (order) => {
        if(order === "reservation_name"){
            if(queryString.parse(location.search).order === "reservation_name_asc"){
                applyOrder("reservation_name_dsc")
            }
            else if(queryString.parse(location.search).order === "reservation_name_dsc"){
                applyOrder("reservation_name_asc")
            }
            else{
                applyOrder("reservation_name_dsc")
            }
        } else if(order === "waiting_name"){
            if(queryString.parse(location.search).order === "waiting_name_asc"){
                applyOrder("waiting_name_dsc")
            }
            else if(queryString.parse(location.search).order === "waiting_name_dsc"){
                applyOrder("waiting_name_asc")
            }
            else{
                applyOrder("waiting_name_dsc")
            }
        } else{
            applyOrder("reservation_name_dsc")
        }
    }

    const applyOrder = (order) => {
        history.replace({
            pathname: `/schedule/reservation/list/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${order}`
        })
    }

    const toScheduleMemberships = status => {
        history.push({
            pathname: `/schedule/membership/list/`,
            state: {
                machine: location.state.machine,
                scheduleId: location.state.scheduleId,
                status: status,
            },
            search: '?order=name_asc&page=1&q=',
        });
    }

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            reservationRefetch({
                scheduleId: location.state.scheduleId ? location.state.scheduleId : -1,
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "reservation_name_dsc",
                skip: 1*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order, schedule])

    useEffect(() => {
        if(queryString.parse(location.search).tpage && !isNaN(Number(queryString.parse(location.search).tpage))){
            window.scrollTo(0,0)
            waitingRefetch({
                scheduleId: location.state.scheduleId ? location.state.scheduleId : -1,
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "reservation_name_dsc",
                skip: (Number(queryString.parse(location.search).tpage) - 1)
            })
        }
    }, [queryString.parse(location.search).tpage, queryString.parse(location.search).order, schedule])

    useEffect(() => {
        if (!location.state.machine||!location.state.scheduleId) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, [])

    const changeStatus = async (membership, status) => {
        if (!loading) {
            if (reservation) {
                if(status !== 5 || window.confirm("정말 취소하시겠습니까?")) {
                    setLoading(true);
                    const {data} = await changeReservationStatusM({
                        variables: {
                            machine: location.state.machine
                                ? location.state.machine
                                : '',
                            scheduleId: location.state.scheduleId,
                            membershipId: membership.id,
                            status: status,
                        },
                    });
                    setLoading(false);
                    const result = data.changeReservationStatus;
                    if (result.success) {
                        await reservationRefetch();
                        await waitingRefetch();
                        await scheduleRefetch();
                    } else {
                        alert(result.error ?? "오류가 발생하였습니다.");
                    }
                }
            }
        }
    };

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.px10} ${styles.pb30}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsEnd}`}>
                                    <p
                                        className={`${styles.fontB} ${styles.font35} ${styles.black} ${styles.mr15}`}>
                                        {schedule
                                            ? `${schedule.getSchedule.schedule.name}`
                                            : ''}
                                    </p>
                                    <p
                                        className={`${styles.font20} ${styles.black} ${styles.mb7}`}>
                                        {schedule
                                            ? `${schedule.getSchedule.schedule.category.name}`
                                            : ''}
                                    </p>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                        <img
                                            src={require('../../../assets/images/icon_back.png').default}
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                        <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.mt10}`}>
                                <p
                                    className={`${styles.font18} ${styles.black}`}>
                                    {schedule && schedule.getSchedule ? schedule.getSchedule.schedule.teacher.name : ''}
                                    {`\u00A0`}|{`\u00A0`}
                                </p>
                                <p
                                    className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                    {schedule && schedule.getSchedule ?
                                        setDateFormatKor_yyyyMMdd(new Date(schedule.getSchedule.schedule.date)) + " " + setDateFormatKor_day(new Date(schedule.getSchedule.schedule.date), true)
                                        : ''}
                                    {`\u00A0`}
                                </p>
                                <p
                                    className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                    {schedule && schedule.getSchedule ?
                                        setDateFormatKor_aahhmm(new Date(schedule.getSchedule.schedule.date + " " + schedule.getSchedule.schedule.startTime)) + " ~ " + setDateFormatKor_aahhmm(new Date(schedule.getSchedule.schedule.date + " " + schedule.getSchedule.schedule.endTime), true)
                                        : ''}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentStart} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`예약 관리${location.state.machine ? ` (${location.state.machine})` : ''}`}
                            </p>
                        </div>

                        <div className={`${styles.mt40} ${styles.center}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            수강 인원 제한
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        disabled={true}
                                        value={schedule?.getSchedule?.schedule.limit}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            예약 인원
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        disabled={true}
                                        value={schedule?.getSchedule?.schedule.reservationStatus1And3Cnt}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            예약 가능 인원
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        disabled={true}
                                        value={schedule?.getSchedule?.schedule.limit-reservation?.getReservation?.total}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            대기 인원
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        disabled={true}
                                        value={schedule?.getSchedule?.schedule.waitingCnt}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                예약자 목록
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                     style={{minWidth: 150, marginTop: 43}}
                                     onClick={() => toScheduleMemberships(1) }>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white} ${styles.textCenter}`}>예약자 추가</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("reservation_name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "reservation_name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "reservation_name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    연락처
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    성별
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권 기간
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권 남은 횟수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태
                                </p>
                            </div>
                        </div>
                        {loadingReservation ? (
                            <Skeleton height={50} count={5}/>
                        ) : (
                            reservation?.getReservation?.memberships?.map(membership => {
                                return(
                                    <ReservationListItem key={membership.id} reservation={membership} changeStatus={changeStatus} isWait={false}/>
                                )
                            })
                        )}
                        {(reservation?.getReservation?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(reservation?.getReservation?.total) ? parseInt((reservation?.getReservation?.total - 1)/5) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingReservation ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingReservation && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                대기자 목록
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                     style={{minWidth: 150, marginTop: 43}}
                                     onClick={() => toScheduleMemberships(2) }>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white} ${styles.textCenter}`}>대기자 추가</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("waiting_name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "waiting_name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "waiting_name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    연락처
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    성별
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권 기간
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원권 남은 횟수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태
                                </p>
                            </div>
                        </div>
                        {loadingWaiting ? (
                            <Skeleton height={50} count={5}/>
                        ) : (
                            waiting?.getWaiting?.memberships?.map(membership => {
                                return(
                                    <ReservationListItem key={membership.id} reservation={membership} changeStatus={changeStatus} isWait={true} />
                                )
                            })
                        )}
                        {(waiting?.getWaiting?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(waiting?.getWaiting?.total) ? parseInt((waiting?.getWaiting?.total - 1)/5) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingWaiting ? null : handleTPage}
                                    initialPage={queryString.parse(location.search).tpage ? Number(queryString.parse(location.search).tpage) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingWaiting && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReservationList;
