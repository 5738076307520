import React, { useEffect, useState } from 'react';

import styles from '../../styles/styles.module.scss';
import { useAuthContext } from '../../context/Auth';
import useWindowDimensions from '../../hooks/useWindowSize';
import LoggedInRoutePC from '../../routes/PC/LoggedInRoute';
import LoggedOutRoutePC from '../../routes/PC/LoggedOutRoute';
import LoggedInRouteMobile from '../../routes/Mobile/LoggedInRoute';
import LoggedOutRouteMobile from '../../routes/Mobile/LoggedOutRoute';
import CustomerBill from '../../screens/Global/CustomerBill';
import { OPTION_SAVE_TOKEN_KEY, TOKEN_KEY } from '../../common/constants';

const NavController = () => {
    const [initialized, setInitialized] = useState(false);

    const AuthContext = useAuthContext();

    const { width } = useWindowDimensions();

    const initialize = async () => {
        if (!initialized) {
            const isSaveToken = localStorage.getItem(OPTION_SAVE_TOKEN_KEY);
            if (isSaveToken === 'true') {
                const isLoggedIn = localStorage.getItem(TOKEN_KEY);
                if (isLoggedIn) {
                    await AuthContext.setIsLoggedIn(true);
                } else {
                    await AuthContext.setIsLoggedIn(false);
                }
            } else {
                localStorage.removeItem(TOKEN_KEY);
                await AuthContext.setIsLoggedIn(false);
            }
            setInitialized(true);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    return window.location.pathname.startsWith('/receipt/') ? (
        <CustomerBill />
    ) : initialized ? (
        AuthContext.isLoggedIn ? (
            width < 992 ? (
                <LoggedInRouteMobile />
            ) : (
                <LoggedInRoutePC />
            )
        ) : width < 992 ? (
            <LoggedOutRouteMobile />
        ) : (
            <LoggedOutRoutePC />
        )
    ) : (
        window.location.pathname.startsWith('/agree') ? (<div/>) : (
        <div
            className={`${styles.widthFull} ${styles.bgOrange}`}
            style={{ minWidth: 320 }}>
            <div className={`${styles.minHeightFull} ${styles.center}`}>
                <img
                    src={require('../../assets/images/logo_lg.png').default}
                    alt={'VEN Brothers'}
                    className={`${styles.p3}`}
                    style={{ maxWidth: 300 }}
                />
            </div>
        </div>
        )
    );
};

export default NavController;
