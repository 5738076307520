import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { bill, billSm } from "../../../common/modal";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import EntranceDoor from "../../../components/Entrance/PC/EntranceDoor.js";

import "react-circular-progressbar/dist/styles.css";
import Loading from "../../../assets/images/loading.gif";

const DOOR = gql`
  query getDoor($machine: String!, $order: String!, $skip: Int!, $q: String, $deviceIds: [String]) {
    getDoor(type: "custom", machine: $machine, order: $order, skip: $skip, q: $q, deviceIds: $deviceIds) {
      doors {
        id
        name
        deviceId
        status
        relay
      }
    }
  }
`;

const CONTROL = gql`
  query doorControl($action: String!, $doorId: Int!) {
    doorControl(order: $action, doorId: $doorId) {
      responses {
        message
        deviceId
      }
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const EntranceDoors = () => {
  const history = useHistory();
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  const [currentDoorId, setCurrentDoorId] = useState(0);

  const [selectedDoors, setSelectedDoors] = useState([]);
  const [selectedAllDoors, setSelectedAllDoors] = useState([]);

  const {
    data: control,
    loading: loadingControl,
    refetch: refetchControl,
  } = useQuery(CONTROL, {
    variables: { action: "release", doorId: 1 },
    skip: true,
  });

  const handleSetStatus = async (action, doorIds) => {
    if (doorIds.length < 1) {
      alert("출입문을 선택해주세요");
      return;
    }
    setLoadingAction(true);

    doorIds.forEach(async (doorId) => {
      try {
        const { data } = await refetchControl({ action, doorId });
        console.log("쿼리 결과: ", data);

        if (data.doorControl && data.doorControl.responses.length > 0) {
          //setMessage(data.doorControl.responses[0].message);
          await refetchDoor();
          if (action === "open") {
            setMessage("출입문 제어에 성공하였습니다.\n현재 상태 : 문 1회 열림");
            setTimeout(async () => {
              await refetchDoor();
            }, 3500); // 4초 지연
          } else if (action === "unlock") {
            setMessage("출입문 제어에 성공하였습니다.\n 현재 상태 : 열림상태 유지");
          } else if (action === "release") {
            setMessage("출입문 제어에 성공하였습니다.\n현재 상태 : 닫힘");
          }
        } else {
          setMessage("출입문을 제어하는 도중 오류가 발생했습니다.");
        }
      } catch (error) {
        console.error("쿼리 오류 발생: ", error);
        setMessage("서버와의 연결이 원활하지 않습니다.");
      } finally {
        setLoadingAction(false);
        setIsModalOpen(true);
      }
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessage("");
  };

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const {
    data: doorData,
    loading: loadingPayment,
    refetch: refetchDoor,
  } = useQuery(DOOR, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      deviceId: location.state.deviceId ? location.state.deviceId.split(",") : [],
    },
  });

  //const filteredDoors = doorData && doorData.getDoor && doorData.getDoor.doors.filter((door) => parseInt(door.deviceId) === parseInt(location.state.deviceId));
  const deviceIds = location.state.deviceId ? location.state.deviceId.split(",") : [];
  const filteredDoors = doorData && doorData.getDoor && doorData.getDoor.doors.filter((door) => deviceIds.includes(door.deviceId.toString()));
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/entrance/door/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  useEffect(() => {
    let selectedAll = doorData?.getDoor?.doors.length > 0 && selectedDoors.length === doorData.getDoor.doors.length;
    setSelectedAllDoors(selectedAll);
  }, [selectedDoors, doorData]);

  useEffect(() => {
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetchDoor({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
          deviceId: location.state.deviceId ? location.state.deviceId : "",
        });
      } else {
        history.replace({
          pathname: `entrance/door/list/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`출입문 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} `} onClick={() => handleSetStatus("unlock", selectedDoors)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>열린 상태 유지</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10} `} onClick={() => handleSetStatus("release", selectedDoors)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>닫힘</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => handleSetStatus("open", selectedDoors)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>1회 열림</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>

            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={bill}>
              <div>
                <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>출입문 관리</p>
                <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`} style={{ whiteSpace: "pre-wrap" }}>
                  {message}
                </div>
                <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
                  <div
                    className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`}
                    style={{ opacity: 1 }}
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                  </div>
                </div>
              </div>
            </Modal>

            {loadingAction && (
              <div className={`${styles.widthFull} ${styles.heightFull}`} style={{ position: "fixed", display: "flex", top: 0, left: 0, zIndex: 999, justifyContent: "center", pointerEvents: "none" }}>
                <div style={{ position: "absolute", display: "flex", top: "50%", marginTop: "-75px", width: 150, height: 150, backgroundColor: "white", justifyContent: "center" }}>
                  <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img style={{ width: 100, height: 100 }} src={Loading} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div
                className={`${styles.ml10} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
                onClick={() => {
                  let _selectedDoors = [];
                  if (!selectedAllDoors) {
                    doorData.getDoor.doors.forEach((item) => {
                      _selectedDoors.push(item.id);
                    });
                  }
                  setSelectedDoors(_selectedDoors);
                }}
              >
                {selectedAllDoors && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} `}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>출입문 이름</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} `}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>잠김 유형</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} `}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>출입문 상태</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} `}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>알람</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} `}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>마지막 이벤트</p>
              </div>
            </div>

            <div style={{ minHeight: 600 }}>
              {loadingPayment ? (
                <Skeleton height={50} count={3} />
              ) : filteredDoors && filteredDoors.length > 0 ? (
                filteredDoors.map((door) => {
                  return <EntranceDoor key={door.id} door={door} selectedDoors={selectedDoors} setSelectedDoors={setSelectedDoors} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>등록된 출입문이 없습니다.</p>
                </div>
              )}

              {doorData?.getDoor?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={doorData?.getDoor?.total ? parseInt((doorData?.getDoor?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingPayment ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {loadingPayment && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntranceDoors;
