import React from "react";
import { gql, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import MembershipHistoryItem from "./MembershipHistoryItem";

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      membershipType
    }
  }
`;

const MembershipHistory = ({ machine, memberships, close, removeMembership, editMembership, loading, machineId, confirmHisEditing }) => {
  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine,
      machineId,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className={`${styles.bgWhite}`}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py10} ${styles.px15} ${styles.bgGrayF4}`}>
        <div className={`${styles.flex2}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권명</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>시작일</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>종료일</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>횟수</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>취소 가능 횟수</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제 금액</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제 일시</p>
        </div>
        <div className={`${styles.flex1}`}></div>
      </div>
      <div className={`${styles.bgWhite} ${styles.hideScroll}`} style={{ overflowY: "scroll", maxHeight: 400 }}>
        {memberships.map((membership, index) => {
          if (membership.status === "expired") {
            return <MembershipHistoryItem key={membership.id} membership={membership} index={index} removeMembership={() => removeMembership(membership)} editMembership={editMembership} memberships={data?.getMemberships} confirmHisEditing={confirmHisEditing} />;
          }
        })}
      </div>
      <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={close}>
        <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
      </div>
    </div>
  );
};

export default MembershipHistory;
