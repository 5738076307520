import React from 'react';
import { gql, useQuery } from '@apollo/client';

import styles from '../../../styles/styles.module.scss';

const RESERVATION_TERMS = gql`
    query detailReservationTerms($type: Int) {
        detailReservationTerms(type: $type) {
            id
            type
            title
            contents
            delYn
        }
    }
`;

const AgreeWebView = (type) => {
    console.log("type:::", type);
    const { data } = useQuery(RESERVATION_TERMS, {
        fetchPolicy: 'network-only',
        variables: {
            type: parseInt(type.location.search ? type.location.search.split('=')[1] : '0'),
        },
    });
    console.log("type:::", type.location.search.split('=')[1]);
    console.log("data:::", data);

    return (
        <div
            className={`${styles.bgWhite} ${styles.minHeightFull} ${styles.center}`}
            style={{ justifyContent: 'flex-start', padding: 15 }}>
            <div>
                {data ? data.detailReservationTerms.contents : ''}
            </div>
        </div>
    );
};

export default AgreeWebView;
