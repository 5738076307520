import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from '../../../apollo/cache';
import Category from '../../../components/Consult/PC/Category';

const CATEGORY = gql`
    query getPtCategory($machine: String!){
        getPtCategory(machine: $machine){
            id
            name
        }
    }
`

const ADD = gql`
    mutation addPtCategory($machine: String!, $name: String!){
        addPtCategory(machine: $machine, name: $name){
            category{
                id
                name
            }
            error
        }
    }
`

const DELETE = gql`
    mutation deletePtCategory($categoryId: Int!){
        deletePtCategory(categoryId: $categoryId)
    }
`

const ConsultCategoryEdit = () => {
    const history = useHistory()
    const location = useLocation()

    const name = useInput("")

    const [loading, setLoading] = useState(false)

    const { data:category, refetch:refetchCategory } = useQuery(CATEGORY, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [addCategoryM] = useMutation(ADD)
    const [deleteCategoryM] = useMutation(DELETE)

    const addCategory = async(e) => {
        e.preventDefault()
        if(!loading){
            if(name.value){
                setLoading(true)
                const { data } = await addCategoryM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        name: name.value
                    }
                })
                setLoading(false)
                if(data && data.addPtCategory){
                    if(data.addPtCategory.error){
                        alert(data.addPtCategory.error)
                    }
                    else{
                        name.setValue("")
                        refetchCategory()
                        alert("분류를 추가하였습니다.")
                    }
                }
                else{
                    alert("오류가 발생하였습니다.")
                }
            }
            else{
                alert("분류명을 입력해주세요.")
            }
        }
    }

    const remove = async(category) => {
        if(!loading){
            if(category){
                const confirm = window.confirm("정말 삭제하시겠습니까?")
                if(confirm){
                    setLoading(true)
                    const { data } = await deleteCategoryM({
                        variables: {
                            categoryId: category.id
                        }
                    })
                    setLoading(false)
                    if(data && data.deletePtCategory){
                        cache.evict({
                            id: `PtRequestCategory:${category.id}`
                        })
                    }
                    else{
                        alert("해당 카테고리에 현재 신청된 상담이 존재합니다.")
                    }
                }
            }
        }
    }

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        상담 카테고리 관리
                    </p>
                    <form action={""} onSubmit={addCategory} className={`${styles.mt45} ${styles.px20}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.flex1} ${styles.overflowHidden}`}>
                            <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    카테고리명
                                </p>
                            </div>
                            <input 
                            type={"name"} 
                            name={"name"} 
                            value={name.value} 
                            onChange={name.onChange}
                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} 
                            placeholder={"새로운 상담 카테고리명을 입력해주세요."}
                            />
                        </div>
                        <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadius10} ${styles.widthFull} ${styles.center} ${styles.py10} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                            <p className={`${styles.fontM} ${styles.font14} ${styles.white}`}>
                                추가
                            </p>
                        </button>
                        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                현재 상담 카테고리 목록
                            </p>
                            <div className={`${styles.inputWhite} ${styles.flex1} ${styles.row} ${styles.mx0} ${styles.flexWrap} ${styles.mt25}`} style={{ minHeight: 120 }}>
                                {category?.getPtCategory?.map((c, index) => {
                                    return(
                                        <Category key={c.id} category={c} remove={() => remove(c)} index={index} />
                                    )
                                })}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ConsultCategoryEdit;