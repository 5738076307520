import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const TodayReservationMember = ({scheduleReservations, machine}) => {
    const history = useHistory();

    console.log("machine:::", machine);

    let gender = scheduleReservations && scheduleReservations.gender;

    return(
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`} >
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {scheduleReservations.schedulename}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {scheduleReservations.schedulestart} ~ {scheduleReservations.scheduleendtime}
                    </p>
                </div>
                <div
                    onClick={() => {
                        history.push({
                            pathname: '/schedule/reservation/list/',
                            state: {
                                machine: machine.username,
                                machineId: machine.id,
                                scheduleId: scheduleReservations ? parseInt(scheduleReservations.scheduleid) : -1
                            },
                            search: '?page=1&tpage=1'
                        });
                    }}
                    className={`${styles.flex1} ${styles.cursorPointer}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {scheduleReservations && scheduleReservations.membername}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {scheduleReservations && scheduleReservations.mobile}
                    </p>
                </div>
                {/*<div className={`${styles.width10}`}>*/}
                {/*    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>*/}
                {/*        {gender === 'male' ? '남성' : '여성'}*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default TodayReservationMember;
