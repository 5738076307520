import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from '../../../apollo/cache';
import Category from '../../../components/Consult/PC/Category';

const CATEGORY = gql`
    query getPtCategory($machine: String!){
        getPtCategory(machine: $machine){
            id
            name
        }
    }
`

const ADD = gql`
    mutation addPtCategory($machine: String!, $name: String!){
        addPtCategory(machine: $machine, name: $name){
            category{
                id
                name
            }
            error
        }
    }
`

const DELETE = gql`
    mutation deletePtCategory($categoryId: Int!){
        deletePtCategory(categoryId: $categoryId)
    }
`

const ConsultCategoryEdit = () => {
    const history = useHistory()
    const location = useLocation()

    const name = useInput("")

    const [loading, setLoading] = useState(false)

    const { data:category, refetch:refetchCategory } = useQuery(CATEGORY, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [addCategoryM] = useMutation(ADD)
    const [deleteCategoryM] = useMutation(DELETE)

    const addCategory = async(e) => {
        e.preventDefault()
        if(!loading){
            if(name.value){
                setLoading(true)
                const { data } = await addCategoryM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        name: name.value
                    }
                })
                setLoading(false)
                if(data && data.addPtCategory){
                    if(data.addPtCategory.error){
                        alert(data.addPtCategory.error)
                    }
                    else{
                        name.setValue("")
                        refetchCategory()
                        alert("분류를 추가하였습니다.")
                    }
                }
                else{
                    alert("오류가 발생하였습니다.")
                }
            }
            else{
                alert("분류명을 입력해주세요.")
            }
        }
    }

    const remove = async(category) => {
        if(!loading){
            if(category){
                const confirm = window.confirm("정말 삭제하시겠습니까?")
                if(confirm){
                    setLoading(true)
                    const { data } = await deleteCategoryM({
                        variables: {
                            categoryId: category.id
                        }
                    })
                    setLoading(false)
                    if(data && data.deletePtCategory){
                        cache.evict({
                            id: `PtRequestCategory:${category.id}`
                        })
                    }
                    else{
                        alert("해당 카테고리에 현재 신청된 상담이 존재합니다.")
                    }
                }
            }
        }
    }

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                상담 카테고리 관리
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form action={""} onSubmit={addCategory} className={`${styles.mt40} ${styles.center}`}>
                            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`} style={{ width: 1250 }}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.flex1} ${styles.overflowHidden}`}>
                                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 230 }}>
                                        <p className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                            상담 카테고리명
                                        </p>
                                    </div>
                                    <input 
                                    type={"name"} 
                                    name={"name"} 
                                    value={name.value} 
                                    onChange={name.onChange}
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.px10}`} 
                                    placeholder={"새로운 상담 카테고리명을 입력해주세요."}
                                    />
                                </div>
                                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px80} ${styles.py15} ${styles.cursorPointer} ${styles.ml20}`} style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        추가
                                    </p>
                                </button>
                            </div>
                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20} ${styles.mb60}`} style={{ width: 1250 }}>
                                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    현재 상담 카테고리 목록
                                </p>
                                <div className={`${styles.inputWhite} ${styles.flex1} ${styles.py15} ${styles.row} ${styles.mx0} ${styles.flexWrap}`} style={{ width: 1208, height: 120, overflowY: "scroll" }}>
                                    {category?.getPtCategory?.map((c, index) => {
                                        return(
                                            <Category key={c.id} category={c} remove={() => remove(c)} index={index} />
                                        )
                                    })}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsultCategoryEdit;