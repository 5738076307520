import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import EntranceProduct from '../../../components/Entrance/PC/EntranceProduct';
import EntranceCategory from '../../../components/Entrance/PC/EntranceCategory';

const MACHINE = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
            username
            entranceIp
            deviceId
            entranceSystem
            entranceMachine
            machineEntrances {
                id
                type
                code
                isMale
                isFemale
                isNon
                machineEntranceAuths {
                    id
                    categoryId
                    productId
                }
            }
        }
    }
`;

const ENTRANCE_FNAME = gql`
    query getEntranceFullName($entranceIp: String!) {
        getEntranceFullName(entranceIp: $entranceIp){
            id
            name
        }
    }
`;

const PRODUCT = gql`
    query getProducts($machine: String!, $order: String!) {
        getProducts(type: "membership", machine: $machine, order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
            }
            total
        }
    }
`;

const CATEGORY = gql`
    query getCategoryAdmin($machine: String!) {
        getCategoryAdmin(type: "schedule", machine: $machine) {
            id
            name
        }
    }
`;

const EDIT = gql`
    mutation saveMachineEntrance(
        $username: String!
        $entranceSystem: String
        $entranceIp: String
        $deviceId: String
        $entranceMachine: String
        $group1ListStr: String
        $group2ListStr: String
    ) {
        saveMachineEntrance(
            username: $username
            entranceSystem: $entranceSystem
            entranceIp: $entranceIp
            deviceId: $deviceId
            entranceMachine: $entranceMachine
            group1ListStr: $group1ListStr
            group2ListStr: $group2ListStr
        ) {
            success
            error
        }
    }
`;

const ENTRANCE_MACHINES = [
    {
        name: '미사용',
        value: 'none',
        systems: [
            {
                name: '미사용',
                value: 'none',
            },
        ],
    },
    {
        name: 'N2',
        value: 'N2',
        systems: [
            {
                name: '지문 인증',
                value: 'F',
            },
            {
                name: '카드 인증',
                value: 'C',
            },
        ],
    },
    {
        name: 'F2',
        value: 'F2',
        systems: [
            {
                name: '카드 인증',
                value: 'C',
            },
            {
                name: '얼굴 인증',
                value: 'M',
            },
        ],
    },
];

const EntranceEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const entranceIp = useInput('');
    const deviceId = useInput('');

    const [entranceMachine, setEntranceMachine] = useState('none');
    const [entranceSystem, setEntranceSystem] = useState('none');
    const [showEntranceSystem, setShowEntranceSystem] = useState(false);
    const [showEntranceMachine, setShowEntranceMachine] = useState(false);
    const [group1List, setGroup1List] = useState([]);
    const [group2List, setGroup2List] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accessGroupsList, setAccessGroupsList] = useState(false);

    const { data, refetch: refetchMachine } = useQuery(MACHINE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const { data: products, refetch: productsRefetch } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: 'name_asc',
        },
    });

    const { data: categories, refetch: refetchCategory } = useQuery(CATEGORY, {
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const { data: accessGroups, refetch} = useQuery(ENTRANCE_FNAME, {
        fetchPolicy: 'only-network',        
        variables: {
            entranceIp: entranceIp?.value
                        ? entranceIp?.value
                        : '',
          },
    });

    const [saveMachineEntranceM] = useMutation(EDIT);

    const handleEntranceSystem = entranceSystem => {
        setEntranceSystem(entranceSystem);
        setShowEntranceSystem(false);
    };

    const handleEntranceMachine = entranceMachine => {
        setEntranceMachine(entranceMachine);
        setShowEntranceMachine(false);
    };

    const handleInputBlur = () =>{        
        setAccessGroupsList(true);
    };

    const onRemoveGroup1List = index => {
        if (group1List.length < index) {
            return;
        }

        let _group1List = [...group1List];
        _group1List.splice(index, 1);

        setGroup1List(_group1List);
    };

    const onRemoveGroup2List = index => {
        if (group2List.length < index) {
            return;
        }

        let _group2List = [...group2List];
        _group2List.splice(index, 1);

        setGroup2List(_group2List);
    };

    const initialize = () => {
        if (data) {
            if (data.getMachine) {
                entranceIp.setValue(
                    data.getMachine.entranceIp
                        ? data.getMachine.entranceIp
                        : '',
                );

                deviceId.setValue(
                    data.getMachine.deviceId
                        ? data.getMachine.deviceId
                        : '',
                );

                setEntranceSystem(data.getMachine.entranceSystem);
                setShowEntranceSystem(false);
                setEntranceMachine(
                    data.getMachine.entranceMachine
                        ? data.getMachine.entranceMachine
                        : 'none',
                );
                setShowEntranceMachine(false);

                let _group1List = [];
                let _group2List = [];

                // 회원권 / 카테고리 선택값
                for (
                    var i = 0;
                    i < data.getMachine.machineEntrances.length;
                    i++
                ) {
                    let machineEntrance = {
                        ...data.getMachine.machineEntrances[i],
                    };
                    let _machineEntranceAuths = [
                        ...machineEntrance.machineEntranceAuths,
                    ];

                    if (machineEntrance.type === 1) {
                        let _products =
                            products && products.getProducts
                                ? [...products.getProducts.products]
                                : [];

                        for (var j = 0; j < _products.length; j++) {
                            let _product = { ..._products[j] };
                            for (
                                var k = _machineEntranceAuths.length - 1;
                                k >= 0;
                                k--
                            ) {
                                let machineEntranceAuth =
                                    _machineEntranceAuths[k];
                                if (machineEntranceAuth.productId < 1) {
                                    _machineEntranceAuths.splice(k, 1);
                                    continue;
                                }

                                if (
                                    machineEntranceAuth.productId ===
                                    _product.id
                                ) {
                                    _machineEntranceAuths.splice(k, 1);
                                    _product.checked = true;
                                    break;
                                }
                            }

                            _products[j] = _product;
                        }
                        machineEntrance.products = _products;

                        _group1List.push(machineEntrance);
                    } else {
                        let _categories =
                            categories && categories.getCategoryAdmin
                                ? [...categories.getCategoryAdmin]
                                : [];

                        for (var j = 0; j < _categories.length; j++) {
                            let _category = { ..._categories[j] };
                            for (
                                var k = _machineEntranceAuths.length - 1;
                                k >= 0;
                                k--
                            ) {
                                let machineEntranceAuth =
                                    _machineEntranceAuths[k];
                                if (machineEntranceAuth.categoryId < 1) {
                                    _machineEntranceAuths.splice(k, 1);
                                    continue;
                                }

                                if (
                                    machineEntranceAuth.categoryId ===
                                    _category.id
                                ) {
                                    _machineEntranceAuths.splice(k, 1);
                                    _category.checked = true;
                                    break;
                                }
                            }

                            _categories[j] = _category;
                        }
                        machineEntrance.categories = _categories;

                        _group2List.push(machineEntrance);
                    }
                }

                setGroup1List(_group1List);
                setGroup2List(_group2List);
            } else {
                alert('존재하지 않는 기기입니다.');
                history.goBack();
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (data && data.getMachine) {
                if (entranceIp.value && entranceSystem && entranceMachine) {
                    if (
                        entranceMachine !== 'none' &&
                        !(entranceIp.value && entranceSystem !== 'none')
                    ) {
                        alert('출입관리 정보를 입력해주세요.');
                        return;
                    }
                    const machineIndex = ENTRANCE_MACHINES.findIndex(
                        m => m.value === entranceMachine,
                    );
                    if (machineIndex < 0) {
                        alert('사용할 수 없는 인증 기기입니다.');
                        return;
                    }
                    if (
                        ENTRANCE_MACHINES[machineIndex].systems.findIndex(
                            s => s.value === entranceSystem,
                        ) < 0
                    ) {
                        alert(
                            '선택한 인증 기기에서 사용할 수 없는 인증수단입니다.',
                        );
                        return;
                    }

                    let skipMaleIdx = -1; // 남성
                    let skipFemaleIdx = -1; // 여성
                    let skipNonIdx = -1; // 미상
                    let skipMaleFemaleIdx = -1; // 남성,여성
                    let skipMaleNonIdx = -1; // 남성,미상
                    let skipFemaleNonIdx = -1; // 여성,미상
                    let skipAllIdx = -1; // 남성,여성,미상
                    for (var i = 0; i < group1List.length; i++) {
                        let item = group1List[i];

                        if (!item.code || item.code.length < 1) {
                            alert('출입 그룹을 입력해주세요.');
                            return;
                        }

                        if (
                            (item.isFemale === undefined &&
                                item.isMale === undefined &&
                                item.isNon === undefined) ||
                            (!item.isFemale && !item.isMale && !item.isNon)
                        ) {
                            alert('성별을 선택해주세요.');
                            return;
                        }

                        if (
                            item.isFemale &&
                            item.isMale &&
                            item.isNon &&
                            skipAllIdx < 0
                        ) {
                            skipAllIdx = i;
                        } else if (
                            item.isMale &&
                            item.isFemale &&
                            skipMaleFemaleIdx < 0
                        ) {
                            skipMaleFemaleIdx = i;
                        } else if (
                            item.isMale &&
                            item.isNon &&
                            skipMaleNonIdx < 0
                        ) {
                            skipMaleNonIdx = i;
                        } else if (
                            item.isFemale &&
                            item.isNon &&
                            skipFemaleNonIdx < 0
                        ) {
                            skipFemaleNonIdx = i;
                        } else if (item.isFemale && skipFemaleIdx < 0) {
                            skipFemaleIdx = i;
                        } else if (item.isMale && skipMaleIdx < 0) {
                            skipMaleIdx = i;
                        } else if (item.isNon && skipNonIdx < 0) {
                            skipNonIdx = i;
                        }

                        let skipProductCheck =
                            skipAllIdx === i ||
                            skipMaleFemaleIdx === i ||
                            skipMaleNonIdx === i ||
                            skipFemaleNonIdx === i ||
                            skipFemaleIdx === i ||
                            skipMaleIdx === i ||
                            skipNonIdx === i;

                        if (skipProductCheck) {
                            continue;
                        }

                        if (!item.products) {
                            alert('회원권을 선택해주세요.');
                            return;
                        }

                        let checkProducts = false;
                        for (var j = 0; j < item.products.length; j++) {
                            if (item.products[j].checked) {
                                checkProducts = true;
                                break;
                            }
                        }

                        if (!checkProducts) {
                            alert('회원권을 선택해주세요.');
                            return;
                        }
                    }

                    let skipProducts = false;
                    if (group2List.length === 1) {
                        skipProducts = true;
                    }

                    for (var i = 0; i < group2List.length; i++) {
                        let item = group2List[i];

                        if (!item.code || item.code.length < 1) {
                            alert('출입 그룹을 입력해주세요.');
                            return;
                        }

                        if (skipProducts) {
                            continue;
                        }

                        if (!item.categories) {
                            alert('카테고리를 선택해주세요.');
                            return;
                        }

                        let checkProducts = false;
                        for (var j = 0; j < item.categories.length; j++) {
                            if (item.categories[j].checked && !skipProducts) {
                                checkProducts = true;
                                break;
                            }
                        }

                        if (!checkProducts) {
                            alert('카테고리를 선택해주세요.');
                            return;
                        }
                    }

                    setLoading(true);

                    const { data: result } = await saveMachineEntranceM({
                        variables: {
                            username: data.getMachine.username,
                            entranceIp: entranceIp.value,
                            deviceId: deviceId.value,
                            entranceSystem,
                            entranceMachine,
                            group1ListStr: JSON.stringify(group1List),
                            group2ListStr: JSON.stringify(group2List),
                        },
                    });
                    if (result && result.saveMachineEntrance) {
                        if (result.saveMachineEntrance.error) {
                            setLoading(false);
                            alert(result.saveMachineEntrance.error);
                        } else {
                            setLoading(false);
                            alert('출입 통제를 수정하였습니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } else {
                    alert('위의 정보를 입력해주세요.');
                }
            }
        }
    };

    useEffect(() => {
        initialize();
    }, [data]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다.');
            history.goBack();
        }
    }, []);

    useEffect(() => {
        if (accessGroupsList) {
            refetch();
            setAccessGroupsList(false);
        }
    }, [accessGroupsList, refetch]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                출입통제 관리{' '}
                                {`${
                                    data && data.getMachine
                                        ? ` (${data.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt12}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius:
                                                showEntranceSystem ? 0 : 10,
                                            borderBottomRightRadius:
                                                showEntranceSystem ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowEntranceSystem(
                                                !showEntranceSystem,
                                            )
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                출입관리 형태*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    entranceSystem
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {entranceSystem ? (
                                                    <>
                                                        {entranceSystem ===
                                                            'none' && '미사용'}
                                                        {entranceSystem ===
                                                            'C' && '카드 인증'}
                                                        {entranceSystem ===
                                                            'F' && '지문 인증'}
                                                        {entranceSystem ===
                                                            'M' && '얼굴 인증'}
                                                    </>
                                                ) : (
                                                    '출입관리 형태를 선택해주세요.'
                                                )}
                                            </p>
                                            <img
                                                src={
                                                    showEntranceSystem
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'출입관리 형태 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEntranceSystem && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    {ENTRANCE_MACHINES[
                                                        ENTRANCE_MACHINES.findIndex(
                                                            m =>
                                                                m.value ===
                                                                entranceMachine,
                                                        )
                                                    ].systems.map(s => {
                                                        return (
                                                            <div
                                                                key={s.value}
                                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                onClick={() =>
                                                                    handleEntranceSystem(
                                                                        s.value,
                                                                    )
                                                                }>
                                                                <p
                                                                    className={`${styles.font16} ${styles.black}`}>
                                                                    {s.name}
                                                                </p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius:
                                                showEntranceMachine ? 0 : 10,
                                            borderBottomRightRadius:
                                                showEntranceMachine ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowEntranceMachine(
                                                !showEntranceMachine,
                                            )
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                인증 기기
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    entranceMachine
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {entranceMachine ? (
                                                    <>
                                                        {entranceMachine ===
                                                        'none'
                                                            ? '미사용'
                                                            : entranceMachine}
                                                    </>
                                                ) : (
                                                    '인증 기기를 선택해주세요.'
                                                )}
                                            </p>
                                            <img
                                                src={
                                                    showEntranceMachine
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'인증 기기 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEntranceMachine && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'none',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            미사용
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'N2',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            N2
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'F2',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            F2
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            {(!accessGroups?.getEntranceFullName || accessGroups?.getEntranceFullName?.length == 0) ? '출입관리 IP' : '출입관리 IP (CLOUD)'}
                                        </p>
                                    </div>
                                    <input
                                        type={'tel'}
                                        name={'entranceIp'}
                                        value={entranceIp.value}
                                        onChange={entranceIp.onChange}
                                        onBlur={handleInputBlur}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '출입관리 IP를 입력해주세요.'
                                        }
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            장치 ID
                                        </p>
                            </div>
                                    <input
                                        type={'tel'}
                                        name={'deviceId'}
                                        value={deviceId.value}
                                        onChange={deviceId.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '사용자 인증 정보를 등록할 장치 ID를 입력해주세요.'
                                        }
                                    />
                                </div>
                            </div>
                            
                            <div
                                className={`${styles.mt60}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                    출입 권한 조건 1 (회원권 구매/추가 시 및
                                    회원권/성별 수정 시 적용)
                                </p>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.mx0} ${styles.borderGrayA2} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.px20} ${styles.py15} ${styles.mx0} ${styles.justifyContentBetween} ${styles.borderBottomGrayA2}`}>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        조건 추가
                                    </p>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            let _group1List = [...group1List];
                                            _group1List.push({});

                                            setGroup1List(_group1List);
                                        }}>
                                        <img
                                            src={
                                                require('../../../assets/images/icon_add.png')
                                                    .default
                                            }
                                            className={`${styles.icon10} ${styles.mr5}`}
                                        />
                                        새로운 조건 추가
                                    </p>
                                </div>
                                {group1List.length < 1 && (
                                    <div
                                        className={`${styles.row} ${styles.px20} ${styles.py15} ${styles.mx0}`}>
                                        출입 권한 조건을 추가해주세요.
                                    </div>
                                )}
                                {group1List.map((group, index) => {
                                    return (
                                        <EntranceProduct
                                            machineEntrance={group}
                                            idx={index}
                                            isLast={
                                                group1List.length - 1 === index
                                            }
                                            onRemove={onRemoveGroup1List}
                                            products={
                                                products && products.getProducts
                                                    ? products.getProducts
                                                          .products
                                                    : []
                                            }
                                            entranceNames={accessGroups ? accessGroups : []}
                                            onChange={data => {
                                                let _group1List = [
                                                    ...group1List,
                                                ];
                                                _group1List[index] = data;

                                                setGroup1List(_group1List);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                className={`${styles.mt60}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                    출입 권한 조건 2 (클래스 예약 시 적용)
                                </p>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.px20} ${styles.py15} ${styles.mx0} ${styles.justifyContentBetween} ${styles.borderBottomGrayA2}`}>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        조건 추가
                                    </p>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            let _group2List = [...group2List];
                                            _group2List.push({});

                                            setGroup2List(_group2List);
                                        }}>
                                        <img
                                            src={
                                                require('../../../assets/images/icon_add.png')
                                                    .default
                                            }
                                            className={`${styles.icon10} ${styles.mr5}`}
                                        />
                                        새로운 조건 추가
                                    </p>
                                </div>
                                {group2List.length < 1 && (
                                    <div
                                        className={`${styles.row} ${styles.px20} ${styles.py15} ${styles.mx0}`}>
                                        출입 권한 조건을 추가해주세요.
                                    </div>
                                )}
                                {group2List.map((group, index) => {
                                    return (
                                        <EntranceCategory
                                            machineEntrance={group}
                                            idx={index}
                                            isLast={
                                                group2List.length - 1 === index
                                            }
                                            entranceNames={accessGroups ? accessGroups : []}
                                            onRemove={onRemoveGroup2List}
                                            categories={
                                                categories &&
                                                categories.getCategoryAdmin
                                                    ? categories.getCategoryAdmin
                                                    : []
                                            }
                                            onChange={data => {
                                                let _group2List = [
                                                    ...group2List,
                                                ];
                                                _group2List[index] = data;

                                                setGroup2List(_group2List);
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntranceEdit;
