import React from 'react';

import styles from '../../../styles/styles.module.scss';

const Category = ({ category, selected, select, edit, remove }) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${
                styles.alignItemsCenter
            } ${styles.justifyContentBetween} ${styles.px15} ${styles.py10} ${
                styles.cursorPointer
            } ${styles.borderBottomGrayA2} ${
                selected ? styles.bgGrayEf : styles.bgWhite
            }`}
            onClick={select}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                {category.name}
            </p>
            <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    onClick={edit}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        수정
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.ml10} ${styles.cursorPointer}`}
                    onClick={remove}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        삭제
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Category;
