import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { ResponsiveBar } from "@nivo/bar";
import html2pdf from "html2pdf.js";

import styles from "../../../styles/styles.module.scss";

import message from "../../../assets/images/report_message.png";

const ReportResult = () => {
  const history = useHistory();
  const location = useLocation();
  let resData = location?.state?.data !== null ? location?.state?.data : false;
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [period, setPeriod] = useState("");

  const [trendJudge, setTrendJudge] = useState(resData?.trend_judge);
  const [reviewSummary, setReviewSummary] = useState(resData?.review_summary || "이 매장에 작성된 리뷰가 없습니다.");
  const [reviews, setReviews] = useState(resData?.reviews);

  const downloadPDF = () => {
    let storeName = resData.store_name;
    const element = document.getElementById("pdf-download"); // PDF로 변환할 요소 선택

    html2pdf()
      .from(element)
      .set({
        filename: `report_${storeName}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, backgroundColor: "#111111", ignoreElements: true },
        jsPDF: { orientation: "portrait", unit: "mm", format: "dl", compressPDF: true },
      })
      .save();
  };

  const init = () => {
    if (!resData) {
      history.push({
        pathname: `/report`,
      });
      return;
    }
    window.scrollTo({ top: 0 });

    let result1 = [];
    let result2 = [];
    let result3 = [];

    //1. 현재 매출 추세 분석 데이터 삽입
    for (let i in resData.month_revenue) {
      let tmp = { date: i, 매출: resData.month_revenue[i] };
      result1.push(tmp);
    }
    //기간 세팅
    let tmpPeriod = `${result1[0].date.substring(0, 7)} ~ ${result1[result1.length - 1].date.substring(0, 7)} 기준`;
    setPeriod(tmpPeriod);

    //2. 타매장 비교 분석
    for (let i in resData.month_revenue) {
      let tmp = { date: i, "내 매장": resData.month_revenue[i], "유사 매장": parseInt(resData.region_month_raw == null ? 0 : resData.region_month_raw[i]) };
      result2.push(tmp);
    }

    //3. 매출 예측
    for (let i in resData.month_predict) {
      let tmp = { date: i, "예상 매출": resData.month_predict[i] };
      result3.push(tmp);
    }

    setData1(result1);
    setData2(result2);
    setData3(result3);
  };

  const handleGoReport = () => {
    history.push({
      pathname: `/report`,
    });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div id="pdf-download" style={{ display: "flex", flexDirection: "column", backgroundColor: "#111111", alignItems: "center", height: "100%" }}>
      <div className={`${styles.font35} ${styles.fontB} ${styles.my40}`}>
        <label style={{ color: "#32bbff" }}>데이터 &nbsp;</label>
        <label style={{ color: "white" }}>분석 결과</label>
      </div>
      <div data-html2canvas-ignore={true} className={`${styles.row} ${styles.mx0} ${styles.font19} ${styles.justifyContentCenter}`} style={{ minWidth: "400px", width: "90%" }}>
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.py15}`} style={{ minWidth: "150px", borderTop: "solid 2px #32bbff", width: "44%", backgroundColor: "#252525", cursor: "pointer" }} onClick={handleGoReport}>
          <label style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}>새로운 데이터</label>
          <label style={{ color: "white", cursor: "pointer" }}>&nbsp;분석하기</label>
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.ml10} ${styles.py15}`} style={{ minWidth: "150px", borderTop: "solid 2px #32bbff", width: "44%", backgroundColor: "#252525", cursor: "pointer" }} onClick={downloadPDF}>
          <label style={{ color: "white", fontWeight: "bold", cursor: "pointer" }}>데이터 분석 결과</label>
          <label style={{ color: "white", cursor: "pointer" }}>&nbsp;PDF 다운로드</label>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {/**1. 현재 매출 추세 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "80%" }}>
            <div className={`${styles.font19}`}>
              <label style={{ color: "white", fontWeight: "bold" }}>1. 현재 매출 추세 분석</label>
            </div>
          </div>
          <div className={`${styles.mt20}`} style={{ width: "80%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <label className={`${styles.font23}`} style={{ color: `${trendJudge === "상승" ? "#33FFBE" : trendJudge === "하강" ? "#fd395e" : "#ffd84d"}` }}>
                {trendJudge === "상승" ? "상승중" : trendJudge === "하강" ? "하락중" : "비슷하게 유지중"}
              </label>
              <label className={`${styles.font18}`} style={{ color: "#767676" }}>
                {period}
              </label>
            </div>
          </div>
          <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data1}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["매출"]}
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.3}
              /**
               * chart 색상
               */
              colors={["#32bbff"]} // 커스터하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={null}
              /*
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}*/
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
            />
          </div>
        </div>
        {/** 2. 타매장 비교 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "80%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>2. 타매장 비교 분석</label>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt5}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                  <div style={{ backgroundColor: "#33BBFF", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#33BBFF" }}>
                    &nbsp;내 매장
                  </label>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.ml30}`}>
                  <div style={{ backgroundColor: "#FD395D", width: "15px", height: "10px", marginTop: "7px" }}></div>
                  <label className={`${styles.font15}`} style={{ color: "#FD395D" }}>
                    &nbsp;내 매장과 가장 유사한 매장
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data2}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["내 매장", "유사 매장"]}
              groupMode="grouped"
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.4} //데이터간
              innerPadding={3} //그룹간
              /**
               * chart 색상
               */
              colors={["#32bbff", "#FD395D"]} // 커스텀하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={null}
              /*
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}*/
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
            />
          </div>
        </div>
        {/** 3. 미래 매출 예측 분석 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "80%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>3. 미래 매출 예측 분석</label>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", height: "400px", margin: "0 auto" }}>
            <ResponsiveBar
              /**
               * chart에 사용될 데이터
               */
              data={data3}
              /**
               * chart에 보여질 데이터 key (측정되는 값)
               */
              keys={["예상 매출"]}
              /**c
               * keys들을 그룹화하는 index key (분류하는 값)
               */
              indexBy="date"
              /**
               * chart margin
               */
              margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
              /**
               * chart padding (bar간 간격)
               */
              padding={0.3}
              /**
               * chart 색상
               */
              colors={["#32bbff"]} // 커스터하여 사용할 때
              // colors={{ scheme: 'nivo' }} // nivo에서 제공해주는 색상 조합 사용할 때
              /**
               * color 적용 방식
               */
              colorBy="id" // 색상을 keys 요소들에 각각 적용
              // colorBy="indexValue" // indexBy로 묵인 인덱스별로 각각 적용
              valueFormat={" >-,.2d"}
              theme={{
                /**
                 * label style (bar에 표현되는 글씨)
                 */
                labels: {
                  text: {
                    fontSize: 10,
                    fill: "#000000",
                  },
                },
                axis: {
                  /**
                   * axis legend style (bottom, left에 있는 글씨)
                   */
                  legend: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                  /**
                   * axis ticks style (bottom, left에 있는 값)
                   */
                  ticks: {
                    text: {
                      fontSize: 10,
                      fill: "#ffffff",
                    },
                  },
                },
              }}
              /**
               * axis bottom 설정
               */
              axisBottom={null}
              /*
              axisBottom={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "bottle", // bottom 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: 40, // 글씨와 chart간 간격
              }}*/
              /**
               * axis left 설정
               */
              axisLeft={{
                tickSize: 5, // 값 설명하기 위해 튀어나오는 점 크기
                tickPadding: 5, // tick padding
                tickRotation: 0, // tick 기울기
                //legend: "price", // left 글씨
                legendPosition: "middle", // 글씨 위치
                legendOffset: -60, // 글씨와 chart간 간격
                format: " >-,.2d",
              }}
              /**
               * label 안보이게 할 기준 width
               */
              labelSkipWidth={36}
              /**
               * label 안보이게 할 기준 height
               */
              labelSkipHeight={12}
            />
          </div>
        </div>
        {/** 4. 업장 리뷰 요약 */}
        <div className={`${styles.my50}`} style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
          <div style={{ width: "80%" }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
              <div className={`${styles.font19}`}>
                <label style={{ color: "white", fontWeight: "bold" }}>4. 업장 리뷰 요약</label>
              </div>
            </div>
            <div className={`${styles.mt5}`}>
              <label className={`${styles.font16}`} style={{ color: "#33BBFF" }}>
                {reviewSummary}
              </label>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.mt30} ${styles.widthFull} ${styles.justifyContentBetween} ${styles.hideScroll}`} style={{ height: "1000px", overflow: "scroll" }}>
              {reviews &&
                reviews.map((item, index) => (
                  <div key={index} className={`${styles.py20} ${styles.px5}`} style={{ width: "47%", borderTop: "solid 1px #767676", borderBottom: "solid 1px #767676", position: "relative" }}>
                    <div className={`${styles.font14} ${styles.mb50}`} style={{ color: "white" }}>
                      {item}
                    </div>
                    <div className={`${styles.font13}`} style={{ color: "white", bottom: "20px", position: "absolute" }}>
                      구글 맵 리뷰
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.mt80}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderTop: "solid 1px #252525", height: "250px", width: "100%" }}>
        <div className={`${styles.width300}`}></div>
        <div className={`${styles.pl0} ${styles.pt30} ${styles.font18}`} style={{ display: "flex", flexDirection: "column", color: "white", width: "30%", minWidth: "343px" }}>
          <div className={`${styles.font11}`}>
            <a href="https://www.ven-brothers.com/terms" target="_blank" className={`${styles.px10}`} style={{ color: "white" }}>
              이용약관
            </a>
            <label>|</label>
            <a href="https://app.catchsecu.com/document/P/e025b657ce8369f" target="_blank" className={`${styles.px10}`} style={{ color: "white" }}>
              개인정보처리방침(가맹점)
            </a>
            <label>|</label>
            <a href="https://app.catchsecu.com/document/P/78baff28da1c6ea" target="_blank" className={`${styles.px10}`} style={{ color: "white" }}>
              개인정보처리방침(고객)
            </a>
          </div>
          <div className={`${styles.pl10} ${styles.pt20} ${styles.font11}`}>
            <label style={{ color: "white" }}>Copyright ⓒ 2024 Working Cow Inc. All right reserved.</label>
          </div>
          <div className={`${styles.pl10} ${styles.pt20} ${styles.font11}`}>
            <label style={{ color: "white" }}>주식회사 워킹카우&nbsp;&nbsp; | &nbsp;&nbsp;대표자 : 이승민 </label>
          </div>
          <div className={`${styles.pl10} ${styles.pt5} ${styles.font11}`}>
            <label style={{ color: "white" }}>주소 : &nbsp;</label>
            <a href="https://www.ven-brothers.com/location" target="_blank" style={{ color: "white" }}>
              서울특별시 송파구 올림픽로35길 93, 지하1층 B102-1호&nbsp;
            </a>
          </div>
          <div className={`${styles.pl10} ${styles.pt5} ${styles.font11}`}>
            <label style={{ color: "white" }}>사업자등록번호 : 616-87-01372 </label>
          </div>
          <div className={`${styles.pl10} ${styles.pt5} ${styles.font11}`}>
            <label style={{ color: "white" }}>통신판매업신고번호 : 2020-서울송파-0110</label>
          </div>
          <div className={`${styles.pl10} ${styles.pt5} ${styles.font11}`}>
            <label style={{ color: "white" }}>전화번호 : &nbsp;</label>
            <a href="tel:+8250713403502" style={{ color: "white" }}>
              0507-1340-3502&nbsp;
            </a>
            <label>&nbsp;|&nbsp;</label>
            <label style={{ color: "white" }}>이메일 : &nbsp;</label>
            <a href="mailto:cs@working-cow.com" style={{ color: "white" }}>
              cs@working-cow.com&nbsp;
            </a>
          </div>
        </div>

        <div className={`${styles.pt60} ${styles.font15}`} style={{ display: "flex", flexDirection: "column", color: "white", width: "20%" }}>
          <div className={`${styles.px0}`}>
            <a href="https://ven-brothers.channel.io/" target="_blank">
              <img src={message} width={45} height={45} />
            </a>
          </div>
          <div className={`${styles.px0}`}>
            <a href="https://www.instagram.com/ven_brothers/" target="_blank">
              <img src="https://static.wixstatic.com/media/81af6121f84c41a5b4391d7d37fce12a.png/v1/fill/w_39,h_39,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/81af6121f84c41a5b4391d7d37fce12a.png" />
            </a>
          </div>
          <div className={`${styles.px0} ${styles.pt8}`}>
            <a href="https://pf.kakao.com/_jWexgK" target="_blank">
              <img src="https://static.wixstatic.com/media/9100c9_6a7d0d51a8bd419485a37673c048d27d~mv2.png/v1/fill/w_67,h_20,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/kakao_ci_screen_edited.png" />
            </a>
          </div>
        </div>
        <div className={`${styles.width300}`}></div>
      </div>
    </div>
  );
};

export default ReportResult;
