import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import User from "../../../components/User/Mobile/User";
import cache from "../../../apollo/cache";
import { useAuthContext } from "../../../context/Auth";
import { numberWithCommas } from "../../../common/utility";
import xlsx from "xlsx";

const USERS = gql`
  query getUsers($order: String!, $skip: Int!, $q: String) {
    getUsers(order: $order, skip: $skip, q: $q) {
      users {
        id
        username
        createdAt
        name
        status
        machines
        nmachines
      }
      total
    }
  }
`;

const REMOVE = gql`
  mutation removeUser($userId: Int!) {
    removeUser(userId: $userId) {
      success
      error
    }
  }
`;

const CONVERSION = gql`
  mutation conversionUser($userId: Int!) {
    conversionUser(userId: $userId) {
      success
      token
      adminToken
      error
    }
  }
`;

const EXCEL_USERS = gql`
  query getExcelUsers {
    getExcelUsers {
      users {
        id
        username
        createdAt
        name
        status
        mobile
        machines
        nmachines
        isAdvertiseAgree
      }
    }
  }
`;

const UserList = () => {
  const history = useHistory();
  const location = useLocation();

  const AuthContext = useAuthContext();

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const [removing, setRemoving] = useState(false);

  const { data, loading, refetch } = useQuery(USERS, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
    },
  });

  const [removeUserM] = useMutation(REMOVE);
  const [conversionUserM] = useMutation(CONVERSION, {
    fetchPolicy: "no-cache",
  });

  const { data: excel_data, refetch: excelFetch } = useQuery(EXCEL_USERS, {
    fetchPolicy: "cache-and-network",
  });

  const submit = async (e) => {
    e.preventDefault();
    history.push({
      pathname: `/`,
      state: location.state,
      search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&q=${q.value}`,
    });
  };

  const handlePage = (page) => {
    history.push({
      pathname: `/`,
      state: location.state,
      search: `?page=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const remove = async (userId) => {
    if (!removing && userId) {
      const confirm = window.confirm("해당 관리자를 삭제하시겠습니까?");
      if (confirm) {
        setRemoving(true);
        try {
          const { data } = await removeUserM({
            variables: {
              userId,
            },
          });
          setRemoving(false);
          if (data && data.removeUser.success) {
            alert("삭제하였습니다.");
            await refetch({
              skip: queryString.parse(location.search).page ? 10 * (Number(queryString.parse(location.search).page) - 1) : 0,
              order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
              q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
            });
          } else if (data && data.removeUser.error) {
            alert(data.removeUser.error);
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setRemoving(false);
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const conversion = async (userId) => {
    if (userId) {
      const confirm = window.confirm("해당 관리자로 접속하시겠습니까?");
      if (confirm) {
        try {
          await cache.reset();
          const { data } = await conversionUserM({
            variables: {
              userId,
            },
          });
          if (data && data.conversionUser.success) {
            AuthContext.login(data.conversionUser.token, true);
            // window.location.reload();
            localStorage.setItem("adminToken", data.conversionUser.adminToken);
            window.location.href = "/?page=1";
          } else if (data && data.conversionUser.error) {
            alert(data.conversionUser.error);
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setRemoving(false);
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const [downloading, setDownloading] = useState(false);
  const exportExcel = async () => {
    if (!downloading && data) {
      setDownloading(true);
      try {
        const { data: result } = await excelFetch();
        if (result && result.getExcelUsers.users.length > 0) {
          let data = [];
          for (const user of result.getExcelUsers.users) {
            console.log(user);
            let _status = user.status === "inuse" ? "사용중" : user.status === "request_withdrawal" ? "탈퇴 요청" : "탈퇴";
            data.push({
              이름: user.name,
              연락처: `${user.mobile}`,
              "계정 상태": _status,
              "등록된 기기수": numberWithCommas(user.machines),
              "미사용 기기": numberWithCommas(user.nmachines),
            });
          }
          const ws = xlsx.utils.json_to_sheet(data);
          const wb = xlsx.utils.book_new();
          let wscols = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
          ws["!cols"] = wscols;
          xlsx.utils.book_append_sheet(wb, ws, "광고성 정보수신 동의자 목록");
          xlsx.writeFile(wb, `Ven-Brothers_광고성수신동의자_명단.xlsx`);
        } else {
          alert("내보낼 명단이 없습니다.");
        }
      } catch {
        alert("오류가 발생하였습니다.");
      } finally {
        setDownloading(false);
      }
    }
  };

  useEffect(() => {
    if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
      window.scrollTo(0, 0);
      refetch({
        skip: 10 * (Number(queryString.parse(location.search).page) - 1),
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      });
      q.setValue(queryString.parse(location.search).q);
    } else {
      history.replace({
        pathname: `/?page=1&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        state: location.state,
      });
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>관리자 계정 관리</p>
          <form action={""} onSubmit={submit} className={`${styles.mt45} ${styles.px40}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
              <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
              <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} placeholder={"이름 검색"} />
            </div>
          </form>
          <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer}`} style={{ width: "75%", margin: "20px auto" }} onClick={exportExcel}>
            <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>광고 수신 동의자 내보내기</p>
          </div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
            <div className={`${styles.flex1}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>이름</p>
            </div>
            <div className={`${styles.flex3}`}></div>
          </div>
          <div>
            {loading ? (
              <Skeleton height={50} count={3} />
            ) : data && data.getUsers && data.getUsers.users.length > 0 ? (
              data.getUsers.users.map((user) => {
                return <User key={user.id} user={user} remove={() => remove(user.id)} removing={removing} conversion={() => conversion(user.id)} />;
              })
            ) : (
              <div className={`${styles.center}`} style={{ height: 600 }}>
                <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>유저가 없습니다.</p>
              </div>
            )}
            {data?.getUsers?.total > 10 ? (
              <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getUsers?.total ? parseInt((data?.getUsers?.total - 1) / 10) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loading ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loading && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
