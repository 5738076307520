import React from 'react';

import {Appointments} from "@devexpress/dx-react-scheduler-material-ui";

const ScheduleMainItem = ({
                              data,
                              children,
                              style,
                              restProps,
                              onAppointmentMetaChange,
                              toggleTooltipVisibility
}) => (
    <Appointments.Appointment
        {...restProps}
        style={{
            ...style,
            backgroundColor: data.color,
            borderRadius: '8px',
            filter: data.limit <= data.reservationCnt ? "contrast(0.5)" : "contrast(1)"
        }}
        onClick={({target}) => {
            toggleTooltipVisibility();

            let hasClass = target.classList.contains('Appointment-clickableAppointment');

            let t = target.parentElement;

            // target search
            if (!hasClass) {
                while (!hasClass) {
                    hasClass = t.classList.contains('Appointment-clickableAppointment');
                    t = t.parentElement;
                }
            }

            onAppointmentMetaChange({
                target: t,
                data: data
            });
        }}
    >
        {children}
    </Appointments.Appointment>
);

export default ScheduleMainItem;
