import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Locker = ({ locker }) => {
    const history = useHistory();

    const start = locker.start
        ? new Date(Number(locker.start))
        : null;
    const end = locker.end ? new Date(Number(locker.end)) : null;

    let startStr = '';
    if (start) {
        startStr = `${start.getFullYear()}/${
            start.getMonth() + 1 < 10
                ? '0' + String(start.getMonth() + 1)
                : start.getMonth() + 1
        }/${
            start.getDate() < 10
                ? '0' + String(start.getDate())
                : start.getDate()
        }`;
    }

    let endStr = '';
    if (end) {
        endStr = `${end.getFullYear()}/${
            end.getMonth() + 1 < 10
                ? '0' + String(end.getMonth() + 1)
                : end.getMonth() + 1
        }/${
            end.getDate() < 10
                ? '0' + String(end.getDate())
                : end.getDate()
        }`
    }

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`}
            onClick={() =>
                history.push({
                    pathname: `/locker/detail/`,
                    state: {
                        machine: locker.lockerArea.machine.username,
                        lockerId: locker.id,
                    },
                })
            }>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.lockerArea.machine.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.member?.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.member ? `010${locker.member.mobile}` : ''}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.product?.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.number}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {startStr}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {endStr}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.status === 'available'
                        ? '사용가능'
                        : locker.status === 'expired'
                            ? '만료'
                            : locker.status === 'inuse'
                                ? '사용중'
                                : ''}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {locker.payment ? (
                        <span>
                            {`${locker.payment.approvalDate.slice(
                                0,
                                2,
                            )}-${locker.payment.approvalDate.slice(
                                2,
                                4,
                            )}-${locker.payment.approvalDate.slice(4)}`}
                            <br />
                            {`${locker.payment.approvalTime.slice(
                                0,
                                2,
                            )}:${locker.payment.approvalTime.slice(
                                2,
                                4,
                            )}:${locker.payment.approvalTime.slice(4)}`}
                        </span>
                    ) : (
                        ''
                    )}
                </p>
            </div>
        </div>
    );
};

export default Locker;
