import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import Calendar from "react-calendar";
import DaumPostcode from "react-daum-postcode";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import axios from "axios";
import { FETCH_URL } from "../../../config";
import queryString from "query-string";
import SelectEntranceType from "../../../components/Member/SelectEntranceType";
import AddEntrance from "../../../components/Member/AddEntrance";

const CREATE = gql`
  mutation addMember(
    $memberId: Int
    $machine: String!
    $name: String!
    $mobile: String!
    $birth: String
    $gender: String
    $membershipId: Int
    $membershipType: Int
    $start: String
    $end: String
    $membershipCount: Int
    $cancelCount: Int
    $ignoreCancel: Boolean
    $address: String
    $extraAddress: String
    $addressType: String
    $sido: String
    $sigungu: String
    $bname: String
    $purpose: String
    $extra: String
    $profileImage: String
  ) {
    addMember(
      memberId: $memberId
      machine: $machine
      name: $name
      mobile: $mobile
      birth: $birth
      gender: $gender
      membershipId: $membershipId
      membershipType: $membershipType
      start: $start
      end: $end
      membershipCount: $membershipCount
      cancelCount: $cancelCount
      ignoreCancel: $ignoreCancel
      address: $address
      extraAddress: $extraAddress
      addressType: $addressType
      sido: $sido
      sigungu: $sigungu
      bname: $bname
      purpose: $purpose
      extra: $extra
      profileImage: $profileImage
    ) {
      member {
        id
        name
        mobile
        createdAt
        defaultMembership {
          id
          product {
            id
            name
          }
          start
          end
          status
        }
      }
      error
    }
  }
`;

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      duration
      membershipType
      membership
      cancel
      ignoreCancel
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
      entranceMachine
      deviceId
    }
  }
`;

const MemberAdd = () => {
  const history = useHistory();
  const location = useLocation();

  const profileImageRef = useRef();

  const name = useInput("");
  const mobile = useInput("");
  const birth = useInput("");
  const extraAddress = useInput("");
  const purpose = useInput("");
  const extra = useInput("");

  const [profileImage, setProfileImage] = useState(null);
  const [previewProfileImage, setPreviewProfileImage] = useState("");
  const [gender, setGender] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [membership, setMembership] = useState({});
  const [showMembership, setShowMembership] = useState(false);
  const [start, setStart] = useState(null);
  const [showStart, setShowStart] = useState(false);
  const [end, setEnd] = useState(null);
  const [showEnd, setShowEnd] = useState(false);
  const [membershipCount, setMembershipCount] = useState("");
  const [cancelCount, setCancelCount] = useState("");
  const [address, setAddress] = useState({
    address: "",
    addressType: "",
    sido: "",
    sigungu: "",
    bname: "",
  });
  const [showAddress, setShowAddress] = useState(false);
  const [showDaum, setShowDaum] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEntrance, setIsEntrance] = useState(false);
  const [showAddEntranceModal, setShowAddEntranceModal] = useState(false);
  const [member, setMember] = useState(null);

  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      machineId: location.state.machineId ? location.state.machineId : null,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [addMemberM] = useMutation(CREATE);

  const handleMobile = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      mobile.setValue(value);
    }
  };

  const handleBirth = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      birth.setValue(value);
    }
  };

  const handleGender = (value) => {
    setGender(value);
    setShowGender(false);
  };

  const handleMembership = (membership) => {
    setMembership(membership);
    setShowMembership(false);

    if (membership.membershipType === 2) {
      setStart(new Date());
      let end = new Date();
      end.setFullYear(end.getFullYear() + 10);
      setEnd(end);
    } else {
      setStart(null);
      setEnd(null);
    }

    if (membership.membershipType === 2 || membership.membershipType === 3) {
      setMembershipCount(membership.membership);
      setCancelCount(membership.cancel);
    }
  };

  const handleStart = (start) => {
    setStart(start);
    if (membership.duration) {
      setEnd(new Date(start.getTime() + 1000 * 60 * 60 * 24 * (membership.duration - 1)));
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setEnd(end);
    setShowEnd(false);
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setMembershipCount(value);
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCancelCount(value);
    }
  };

  const handleAddress = (data) => {
    setAddress({
      address: data.address,
      addressType: data.addressType,
      sido: data.sido,
      sigungu: data.sigungu,
      bname: data.bname,
    });
    setShowDaum(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (name.value && mobile.value) {
        if (mobile.value.length !== 8) {
          alert("전화번호 11자리를 입력해주세요");
          return;
        }

        if (isEntrance) {
          if (!membership.id) {
            alert("회원권을 선택해주세요.");
            return;
          }
        }

        if (membership) {
          if (membership.membershipType === 1 || membership.membershipType === 3) {
            if (!start) {
              alert("회원권 시작일을 입력해주세요.");
              return;
            }

            if (!end) {
              alert("회원권 종료일을 입력해주세요.");
              return;
            }
          }

          if (membership.membershipType === 2 || membership.membershipType === 3) {
            if (!membershipCount) {
              alert("횟수를 입력해주세요.");
              return;
            }

            if (!membership.ignoreCancel && !cancelCount) {
              alert("취소 가능 횟수를 입력해주세요.");
              return;
            }
          }
        }

        if (isEntrance) {
          setMember({
            name: name.value,
            mobile: mobile.value,
          });
        } else {
          await addMemberMachine(null);
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  const addMemberMachine = async (memberId) => {
    setLoading(true);

    try {
      let uploadResult = await uploadProfileImage();
      if (uploadResult.result === "ok") {
        const { data: result } = await addMemberM({
          variables: {
            memberId,
            machine: location.state.machine ? location.state.machine : "",
            name: name.value,
            mobile: mobile.value,
            birth: birth.value,
            gender,
            membershipId: membership.id,
            membershipType: membership.membershipType,
            start: start ? `${start.getTime()}` : null,
            end: end ? `${end.getTime()}` : null,
            membershipCount: membershipCount ? Number(membershipCount) : null,
            cancelCount: !membership.ignoreCancel && cancelCount ? Number(cancelCount) : null,
            ignoreCancel: membership.ignoreCancel,
            address: address.address,
            extraAddress: extraAddress.value,
            addressType: address.addressType,
            sido: address.sido,
            sigungu: address.sigungu,
            bname: address.bname,
            purpose: purpose.value,
            extra: extra.value,
            profileImage: uploadResult.profileImage,
          },
        });
        if (result && result.addMember) {
          if (result.addMember.error) {
            setLoading(false);
            alert(result.addMember.error);
          } else {
            setLoading(false);
            alert("회원을 등록하였습니다.");
            initialize();
          }
        } else {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      } else {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    } catch {
      setLoading(false);
      alert("오류가 발생하였습니다.");
    }
  };

  const uploadProfileImage = async () => {
    if (!profileImage) {
      return {
        result: "ok",
        profileImage: "",
      };
    }

    const formData = new FormData();
    const resizedImage = await resizeImage(profileImage);
    formData.append("excel", resizedImage, profileImage.name);
    const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data && data.location) {
      return {
        result: "ok",
        profileImage: data.location,
      };
    } else {
      return {
        result: "fail",
        profileImage: "",
      };
    }
  };

  const delProfileImageFile = () => {
    profileImageRef.current.value = null;
    setProfileImage(null);
  };

  const initialize = () => {
    name.setValue("");
    mobile.setValue("");
    birth.setValue("");
    extraAddress.setValue("");
    purpose.setValue("");
    extra.setValue("");
    setGender("");
    setShowGender(false);
    setMembership({});
    setShowMembership(false);
    setStart(null);
    setShowStart(false);
    setEnd(null);
    setShowEnd(false);
    setMembershipCount("");
    setCancelCount("");
    setAddress({
      address: "",
      addressType: "",
      sido: "",
      sigungu: "",
      bname: "",
    });
    setShowAddress(false);
    setShowDaum(false);
    setLoading(false);
    delProfileImageFile();
  };

  useEffect(() => {
    if (member) {
      setShowAddEntranceModal(true);
    }
  }, [member]);

  useEffect(() => {
    setShowDaum(false);
  }, [showAddress]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (profileImage) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setPreviewProfileImage(reader.result);
      };
      reader.readAsDataURL(profileImage);
    } else {
      setPreviewProfileImage("");
    }
  }, [profileImage]);

  useEffect(() => {
    let _isEntrance = false;
    if (machine && machine.getMachine) {
      _isEntrance = machine.getMachine.entranceSystem !== "none";
    }

    setIsEntrance(_isEntrance);
  }, [machine]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`회원 등록${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div style={{ width: 1250 }}>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
              </div>
              <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ width: 1250 }}>
                <div className={`${styles.mx0} ${styles.justifyContentBetween} ${styles.mt25}`} style={{ width: 250, display: "flex", flexDirection: "column", rowGap: 5 }}>
                  <div className={`${styles.flex1} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1} ${styles.textCenter} ${styles.center}`}>
                    {profileImage ? (
                      <img
                        style={{
                          height: 280,
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        src={previewProfileImage}
                      />
                    ) : (
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>사진</p>
                    )}
                    <input ref={profileImageRef} id={"profile_image"} className={`${styles.none}`} type={"file"} accept={"image/*"} onChange={(e) => setProfileImage(e.target.files[0])} />
                  </div>
                  <div className={`${styles.row} ${styles.px15}`}>
                    <label className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter}`} htmlFor={"profile_image"}>
                      <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>변경</p>
                    </label>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter}`} onClick={delProfileImageFile}>
                      <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
                    </div>
                  </div>
                </div>
                <div className={`${styles.mx0} ${styles.justifyContentBetween} ${styles.mt15}`} style={{ width: 990 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10} ${styles.flex1}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원명*</p>
                      </div>
                      <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"회원명을 입력해주세요."} />
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원 연락처*</p>
                      </div>
                      <NumberFormat value={mobile.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} type={"tel"} displayType={"input"} onValueChange={handleMobile} placeholder={"회원 연락처를 입력해주세요."} format={"010-####-####"} />
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20} ${styles.flex1}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>생년월일</p>
                      </div>
                      <NumberFormat value={birth.value} thousandSeparator={true} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} type={"tel"} displayType={"input"} onValueChange={handleBirth} placeholder={"회원의 생년월일을 입력해주세요.(YYMMDD)"} format={"######"} />
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                        style={{
                          borderBottomLeftRadius: showGender ? 0 : 10,
                          borderBottomRightRadius: showGender ? 0 : 10,
                        }}
                        onClick={() => setShowGender(!showGender)}
                      >
                        <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>성별</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                          <p className={`${styles.font16} ${gender === "male" || gender === "female" ? styles.black : styles.grayA2}`}>{gender === "male" || gender === "female" ? <span>{gender === "male" ? "남성" : "여성"}</span> : "회원의 성별을 선택해주세요."}</p>
                          <img src={showGender ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"성별 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                        </div>
                      </div>
                      {showGender && (
                        <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.widthFull}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                            <div
                              className={`${styles.borderRightGrayA2} ${styles.px20}`}
                              style={{
                                width: 190,
                              }}
                            ></div>
                            <div className={`${styles.py10} ${styles.flex1}`}>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("male")}>
                                <p className={`${styles.font16} ${styles.black}`}>남성</p>
                              </div>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("female")}>
                                <p className={`${styles.font16} ${styles.black}`}>여성</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.mt20} ${styles.flex1}`} style={{ position: "relative" }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>운동 목적</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                        <input type={"text"} name={"purpose"} value={purpose.value} onChange={purpose.onChange} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"회원의 운동 목적을 입력해주세요."} />
                      </div>
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                        style={{
                          borderBottomLeftRadius: showMembership ? 0 : 10,
                          borderBottomRightRadius: showMembership ? 0 : 10,
                        }}
                        onClick={() => setShowMembership(!showMembership)}
                      >
                        <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원권{isEntrance ? "*" : ""}</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`} style={{ flexWrap: "nowrap" }}>
                          <p className={`${styles.font16} ${membership && membership.id ? styles.black : styles.grayA2}`}>{membership && membership.id ? membership.name : "회원이 사용중인 회원권을 선택해주세요."}</p>
                          <img src={showMembership ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                        </div>
                      </div>
                      {showMembership && (
                        <div
                          className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll} ${styles.widthFull}`}
                          style={{
                            maxHeight: 300,
                            overflowX: "hidden",
                            overflowY: "scroll",
                          }}
                        >
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                            <div
                              className={`${styles.borderRightGrayA2} ${styles.px20}`}
                              style={{
                                width: 190,
                              }}
                            ></div>
                            <div className={`${styles.py10} ${styles.flex1}`}>
                              {data?.getMemberships?.map((item) => {
                                if (!(item.membershipType === 2 && isEntrance)) {
                                  return (
                                    <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                                      <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20} ${styles.flex1}`} style={{ display: membership.membershipType === 1 || membership.membershipType === 3 ? "flex" : "none" }}>
                    <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                        style={{
                          borderBottomLeftRadius: showStart ? 0 : 10,
                          borderBottomRightRadius: showStart ? 0 : 10,
                        }}
                        onClick={() => setShowStart(!showStart)}
                      >
                        <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원권 시작일{isEntrance ? "*" : ""}</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                          <p className={`${styles.font16} ${start ? styles.black : styles.grayA2}`}>
                            {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : "회원권 시작일을 선택해주세요."}
                          </p>
                          <img src={showStart ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 시작일 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                        </div>
                      </div>
                      {showStart && (
                        <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.widthFull}`}>
                          <div className={`${styles.bgWhite} ${styles.py10} ${styles.flex1}`}>
                            <Calendar
                              calendarType={"US"}
                              value={start}
                              maxDate={end ? end : null}
                              minDetail={"month"}
                              nextLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                                </span>
                              }
                              next2Label={null}
                              prevLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                                </span>
                              }
                              prev2Label={null}
                              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                              onChange={handleStart}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                        style={{
                          borderBottomLeftRadius: showEnd ? 0 : 10,
                          borderBottomRightRadius: showEnd ? 0 : 10,
                        }}
                        onClick={() => setShowEnd(!showEnd)}
                      >
                        <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원권 종료일{isEntrance ? "*" : ""}</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                          <p className={`${styles.font16} ${end ? styles.black : styles.grayA2}`}>
                            {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : "회원권 종료일을 선택해주세요."}
                          </p>
                          <img src={showEnd ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 종료일 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                        </div>
                      </div>
                      {showEnd && (
                        <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.widthFull}`}>
                          <div className={`${styles.bgWhite} ${styles.py10} ${styles.flex1}`}>
                            <Calendar
                              calendarType={"US"}
                              value={end}
                              minDate={start ? start : null}
                              minDetail={"month"}
                              nextLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                                </span>
                              }
                              next2Label={null}
                              prevLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                                </span>
                              }
                              prev2Label={null}
                              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                              onChange={handleEnd}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20} ${styles.flex1}`} style={{ display: membership.membershipType === 2 || membership.membershipType === 3 ? "flex" : "none" }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>횟수*</p>
                      </div>
                      <NumberFormat
                        value={membershipCount}
                        thousandSeparator={true}
                        className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px20}`}
                        type={"tel"}
                        displayType={"input"}
                        onValueChange={handleMembershipCount}
                        placeholder={"횟수를 입력해주세요. (숫자만 입력)"}
                        style={{
                          width: "100%",
                          minWidth: 20,
                        }}
                      />
                    </div>
                    <div style={{ width: 10 }}></div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.flex1}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>취소 가능 횟수*</p>
                      </div>
                      {membership.ignoreCancel ? (
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                          <input type={"text"} name={"ignoreCancel"} value={"무제한"} className={`${styles.inputWhite} ${styles.flex1}`} readOnly={true} />
                        </div>
                      ) : (
                        <NumberFormat
                          value={cancelCount}
                          thousandSeparator={true}
                          className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px20}`}
                          type={"tel"}
                          displayType={"input"}
                          onValueChange={handleCancelCount}
                          placeholder={"취소 가능 횟수를 입력해주세요. (숫자만 입력)"}
                          style={{
                            width: "100%",
                            minWidth: 20,
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20} ${styles.flex1}`}>
                    <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                        style={{
                          borderBottomLeftRadius: showAddress ? 0 : 10,
                          borderBottomRightRadius: showAddress ? 0 : 10,
                        }}
                        onClick={() => setShowAddress(!showAddress)}
                      >
                        <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원 주소</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                          <input type={"text"} value={address.address ? `${address.address} ${extraAddress.value}` : ""} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"회원의 주소지를 입력해주세요."} readOnly={true} />
                          {showAddress ? (
                            <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                              완료
                            </p>
                          ) : (
                            <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                              변경
                            </p>
                          )}
                        </div>
                      </div>
                      {showAddress && (
                        <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.flex1} ${styles.widthFull}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                            <div
                              className={`${styles.borderRightGrayA2} ${styles.px20}`}
                              style={{
                                width: 190,
                              }}
                            ></div>
                            {showDaum ? (
                              <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                                <DaumPostcode onComplete={handleAddress} />
                              </div>
                            ) : (
                              <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                                  <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>도 / 시</p>
                                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sido}</p>
                                    <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                                  </div>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                                  <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>시 / 군 / 구</p>
                                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sigungu}</p>
                                    <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                                  </div>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                                  <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>동 / 면 / 읍</p>
                                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.bname}</p>
                                    <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                                  </div>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.borderBottomGrayA2} ${styles.flex1} ${styles.mb60}`}>
                                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상세주소</p>
                                  <input type={"text"} name={"extraAddress"} value={extraAddress.value} onChange={extraAddress.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"상세주소를 입력해주세요."} />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>비고</p>
                <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"비고"} maxLength={5000} />
              </div>
              <div className={`${styles.mt20}`} style={{ width: 1250, display: "none" }}>
                <div className={`${styles.row} ${styles.mx0}`}>
                  <p className={`${styles.fontR} ${styles.font13} ${styles.black}`}>*경고:</p>
                  <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.flex1} ${styles.ml10}`}>
                    벤브라더스 무인 출입 시스템(Suprema)을 이용하는 경우, 현재 보고 계신 관리자 웹이 아닌, 키오스크 관리자 기능을 통해서 회원을 등록하십시오!
                    <br />
                    현재 보고 계신 관리자 웹에서 회원을 등록할 경우, 무인 출입 시스템과 연동되지 않습니다!
                  </p>
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>{isEntrance ? "다음" : "완료"}</p>
                </button>
              </div>
            </form>
          </div>
          <AddEntrance
            machine={machine && machine.getMachine ? machine.getMachine : null}
            showModal={showAddEntranceModal}
            cancel={() => setShowAddEntranceModal(false)}
            addMemberType={"add"}
            member={member}
            start={start}
            end={end}
            membershipId={membership.id}
            setLoading={setLoading}
            addMember={addMemberMachine}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberAdd;
