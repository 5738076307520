import React, {useState} from 'react';
import Modal from "react-modal";
import styles from '../../../styles/styles.module.scss';
import { bill, membership as membershipModal } from "../../../common/modal";

const EntranceProduct = ({ idx, machineEntrance, isLast, onRemove, products, entranceNames, onChange }) => {
    const code = machineEntrance.code ? machineEntrance.code : '';

    const [showProducts, setShowProducts] = useState(false);
    const [showGender, setShowGender] = useState(false);
    const [showMembership, setShowMembership] = useState(false);

    var jsonData = entranceNames?.getEntranceFullName;
    const entranceNamesFinal = jsonData ? jsonData.map(item => {
        return {
            id: item.id, 
            //name: item.name
            name: `${item.name}(${item.id})`
        };
    }) : null;
    
    let selectProducts = '';
    if (machineEntrance.products) {
        machineEntrance.products.forEach(product => {
            if (product.checked) {
                if (selectProducts.length > 0) {
                    selectProducts += ", ";
                }

                selectProducts += product.name;
            }
        });
    }

    const handleMembership = (product) => {        
        let _machineEntrance = {...machineEntrance};
        _machineEntrance.code = product.name;
        onChange(_machineEntrance);
        setShowMembership(false);
    };

    let selectGenders = '';
    if (machineEntrance.isMale) {
        selectGenders = "남성";
    }
    if (machineEntrance.isFemale) {
        if (selectGenders.length > 0) {
            selectGenders += ", "
        }
        selectGenders += "여성";
    }
    if (machineEntrance.isNon) {
        if (selectGenders.length > 0) {
            selectGenders += ", "
        }
        selectGenders += "미상";
    }

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${isLast ? null : styles.borderBottomGrayA2}`}>
            <div className={`${styles.flex1} ${styles.mx0} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`}>
                <p
                    className={`${styles.fontB} ${styles.font16} ${styles.black}`}>
                    {`조건 ${idx + 1}`}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`}>
                <p
                    className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                    회원권
                </p>
            </div>
            <div
                className={`${styles.flex4}`}
                style={{position: 'relative'}}>
                <div className={`${styles.flex1} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`}>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.cursorPointer}`}
                        onClick={() => setShowProducts(!showProducts)}>
                        <p
                            className={`${styles.flex1} ${styles.fontR} ${styles.font16} ${selectProducts.length > 0 ? styles.black : styles.grayA2}`}>
                            {selectProducts.length > 0 ? selectProducts : '회원권을 선택해주세요.'}
                        </p>
                        <img
                            src={
                                showProducts
                                    ? require('../../../assets/images/icon_dropdown_up.png')
                                        .default
                                    : require('../../../assets/images/icon_dropdown_down.png')
                                        .default
                            }
                            alt={'회원권 형태 선택'}
                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                        />
                    </div>
                </div>

                {showProducts && (
                    <div
                        className={`${styles.bgWhite} ${styles.flex3} ${styles.dropdown} ${styles.overflowHidden} ${styles.py10}`}
                        style={{left: -1, right: 0, borderTop: '1px solid #a2a2a2'}}>
                        <div
                            className={`${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                            <div>
                                {products ? products.map(
                                        (c, index) => {
                                            return (
                                                <div
                                                    key={
                                                        c.id
                                                    }
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10}`}
                                                    onClick={() => {
                                                        let _machineEntrance = {...machineEntrance};
                                                        if (_machineEntrance.products) {
                                                            let _product = {..._machineEntrance.products[index]};

                                                            if (_product.checked) {
                                                                _product.checked = !_product.checked;
                                                            } else {
                                                                _product.checked = true;
                                                            }

                                                            _machineEntrance.products[index] = _product;
                                                        } else {
                                                            let _products = [...products];
                                                            _products[index] = {..._products[index], checked: true};

                                                            _machineEntrance.products = _products;
                                                        }

                                                        onChange(_machineEntrance);
                                                    }}>
                                                    <div
                                                        className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                                        {machineEntrance.products && machineEntrance.products[index] && machineEntrance.products[index].checked && (
                                                            <div
                                                                className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                                style={{
                                                                    width: 14,
                                                                    height: 14,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <p
                                                        className={`${styles.font16} ${styles.flex1} ${styles.black} ${styles.cursorPointer}`}>
                                                        {
                                                            c.name
                                                        }
                                                    </p>
                                                </div>
                                            );
                                        },
                                    )
                                    : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={`${styles.flex1} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`}>
                <p
                    className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                    성별
                </p>
            </div>
            <div
                className={`${styles.flex3} ${styles.borderRightGrayA2}`}
                style={{position: 'relative'}}>
                <div className={`${styles.flex1} ${styles.px15} ${styles.py15}`}>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.cursorPointer}`}
                        onClick={() => setShowGender(!showGender)}>
                        <p
                            className={`${styles.fontR} ${styles.font16} ${selectGenders.length > 0 ? styles.black : styles.grayA2}`}>
                            {selectGenders.length > 0 ? selectGenders : '성별을 선택해주세요.'}
                        </p>
                        <img
                            src={
                                showGender
                                    ? require('../../../assets/images/icon_dropdown_up.png')
                                        .default
                                    : require('../../../assets/images/icon_dropdown_down.png')
                                        .default
                            }
                            alt={'성별 형태 선택'}
                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                        />
                    </div>
                </div>

                {showGender && (
                    <div
                        className={`${styles.bgWhite} ${styles.flex3} ${styles.dropdown} ${styles.overflowHidden} ${styles.py10}`}
                        style={{left: -1, right: 0, borderTop: '1px solid #a2a2a2'}}>
                        <div
                            className={`${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                onClick={() => {
                                    let _machineEntrance = {...machineEntrance};
                                    _machineEntrance.isMale = _machineEntrance.isMale ? !_machineEntrance.isMale : true;

                                    onChange(_machineEntrance);
                                }}>
                                <div
                                    className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                    {machineEntrance.isMale && (
                                        <div
                                            className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                            style={{
                                                width: 14,
                                                height: 14,
                                            }}
                                        />
                                    )}
                                </div>
                                <p
                                    className={`${styles.font16} ${styles.black}`}>
                                    남성
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                onClick={() => {
                                    let _machineEntrance = {...machineEntrance};
                                    _machineEntrance.isFemale = _machineEntrance.isFemale ? !_machineEntrance.isFemale : true;

                                    onChange(_machineEntrance);
                                }}>
                                <div
                                    className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                    {machineEntrance.isFemale && (
                                        <div
                                            className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                            style={{
                                                width: 14,
                                                height: 14,
                                            }}
                                        />
                                    )}
                                </div>
                                <p
                                    className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                    여성
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                onClick={() => {
                                    let _machineEntrance = {...machineEntrance};
                                    _machineEntrance.isNon = _machineEntrance.isNon ? !_machineEntrance.isNon : true;

                                    onChange(_machineEntrance);
                                }}>
                                <div
                                    className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                    {machineEntrance.isNon && (
                                        <div
                                            className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                            style={{
                                                width: 14,
                                                height: 14,
                                            }}
                                        />
                                    )}
                                </div>
                                <p
                                    className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                    미상
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className={`${styles.flex1} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`}>
                <p
                    className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                    출입그룹
                </p>
            </div>
            {entranceNamesFinal ? 
            <div className={`${styles.flex4} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`} onClick={() => setShowMembership(!showMembership)}>                                    
                <p
                    className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                    {machineEntrance.code}
                </p>
            </div>
            :
            <div className={`${styles.flex4} ${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`} >
                <input
                    type={'tel'}
                    name={'code'}
                    value={code}
                    onChange={(e) => {
                        const { target : { value } } = e;

                        let _machineEntrance = {...machineEntrance};
                        _machineEntrance.code = value;
                        onChange(_machineEntrance);
                    }}
                    className={`${styles.inputWhite} ${styles.fontR} ${styles.font16} ${styles.widthFull}`}
                    placeholder={
                        '출입그룹 코드를 입력해주세요.'
                    }
                />
            </div>
            }
            <div
                className={`${styles.mx10} ${styles.cursorPointer} ${styles.alignSelfCenter} ${styles.justifyContentCenter}`}
                onClick={() => onRemove(idx)}>
                <img
                    src={require('../../../assets/images/icon_close.png').default}
                    className={`${styles.icon20} ${styles.mt5} ${styles.ml5}`}
                />
            </div>
            <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={bill}>
                <div
                className={`${styles.bgWhite} ${styles.hideScroll}`}
                style={{
                    maxHeight: 300,
                    overflowX: "hidden",
                    overflowY: "scroll",
                }}
                >
                {entranceNamesFinal
                    ? entranceNamesFinal.map((item) => {                        
                        return (
                            <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} 
                            ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                            <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                            </div>
                        );
                    })
                    : null}
                </div>
                <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center}
                 ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
                <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                </div>
            </Modal>
        </div>
    );
};

export default EntranceProduct;
