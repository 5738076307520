import React, { useState } from 'react';
import Modal from 'react-modal';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';
import { bill } from '../../../common/modal';
import Bill from '../../Global/Bill';

const LockerPayment = ({ payment }) => {
    const [showModal, setShowModal] = useState(false);

    const start = payment.lockerStart
        ? new Date(Number(payment.lockerStart))
        : null;
    const end = payment.lockerEnd ? new Date(Number(payment.lockerEnd)) : null;

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.approvalDate.slice(
                        0,
                        2,
                    )}/${payment.approvalDate.slice(
                        2,
                        4,
                    )}/${payment.approvalDate.slice(4, 6)}`}
                    <br />
                    {`${payment.approvalTime.slice(
                        0,
                        2,
                    )}:${payment.approvalTime.slice(
                        2,
                        4,
                    )}:${payment.approvalTime.slice(4, 6)}`}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.userName}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.userContact}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.lockerAreaName}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.productName}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.lockerNumber}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {start
                        ? `${start.getFullYear()}/${
                              start.getMonth() + 1 < 10
                                  ? '0' + String(start.getMonth() + 1)
                                  : start.getMonth() + 1
                          }/${
                              start.getDate() < 10
                                  ? '0' + String(start.getDate())
                                  : start.getDate()
                          } ~`
                        : ' ~'}
                    <br />
                    {end
                        ? `${end.getFullYear()}/${
                              end.getMonth() + 1 < 10
                                  ? '0' + String(end.getMonth() + 1)
                                  : end.getMonth() + 1
                          }/${
                              end.getDate() < 10
                                  ? '0' + String(end.getDate())
                                  : end.getDate()
                          }`
                        : ''}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                    style={
                        payment.status === 'refund'
                            ? { textDecorationLine: 'line-through' }
                            : null
                    }>
                    {numberWithCommas(payment.totalAmount)}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.issuerInfo ? payment.issuerInfo : ''}(${
                        payment.installment === 0
                            ? '일시불'
                            : `${payment.installment}개월`
                    })`}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                    onClick={() => setShowModal(!showModal)}>
                    영수증
                </p>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={bill}>
                <Bill payment={payment} />
            </Modal>
        </div>
    );
};

export default LockerPayment;
