import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TOKEN_KEY } from '../common/constants';
import { FETCH_URL } from '../config';

const httpLink = createHttpLink({
    uri: `${FETCH_URL}`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(TOKEN_KEY);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const options = {
    link: authLink.concat(httpLink),
};

export default options;
