import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const FindPasswordClear = () => {
    const history = useHistory()
    const location = useLocation()

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
            <div className={`${styles.container} ${styles.overflowHidden} ${styles.py70}`} style={{ maxWidth: 600 }}>
                <div className={`${styles.textCenter}`}>
                    <img src={require("../../../assets/images/logo_lg.png").default} alt={"VEN Brothers"} style={{ width: 200 }} />
                </div>
                <div className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px15} ${styles.pAuthBox} ${styles.mx15} ${styles.mt40} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font25} ${styles.black} ${styles.textCenter}`}>
                        {(location.state && location.state.username) ? "비밀번호 찾기 완료" : "잘못된 접근"}
                    </p>
                    {(location.state && location.state.username) ? (
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.mt75}`}>
                            입력하신 이메일 주소({`${location.state.username}`}) 로 <br/>새로운 비밀번호 설정 메일이 전송되었습니다.
                        </p>
                    ) : (
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.mt75}`}>
                            잘못된 접근시도입니다.
                        </p>
                    )}
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer} ${styles.containerInput}`} style={{ marginTop: 215 }} onClick={() => history.push("/")}>
                        <p className={`${styles.fontB} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FindPasswordClear;