import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const DETAIL = gql`
  query getPayment($machine: String!, $paymentId: Int!) {
    getPayment(machine: $machine, paymentId: $paymentId) {
      id
      productName
      userName
      userContact
      userAddress
      totalAmount
      status
      receiveStatus
      issuerInfo
      installment
      approvalDate
      approvalTime
      approvalNo
    }
  }
`;

const EDIT = gql`
  mutation changeReceiveStatus($machine: String!, $paymentId: Int!, $status: String!) {
    changeReceiveStatus(machine: $machine, paymentId: $paymentId, status: $status) {
      success
      result {
        id
        receiveStatus
      }
    }
  }
`;

const EditOrderReceiveStatus = () => {
  const history = useHistory();
  const location = useLocation();

  const [showStatus, setShowStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine,
      paymentId: location.state.payment.id,
    },
  });

  const [changeReceiveStatusM] = useMutation(EDIT);

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getPayment) {
        setLoading(true);
        try {
          const { data: result } = await changeReceiveStatusM({
            variables: {
              machine: location.state.machine,
              paymentId: data.getPayment.id,
              status,
            },
          });
          if (result.changeReceiveStatus && result.changeReceiveStatus.success) {
            alert("주문 판매 내역을 수정하였습니다.");
            history.goBack();
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          alert("오류가 발생하였습니다.");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  // 영수증 출력
  const showBill = (e) => {
    e.preventDefault();
    window.open("https://admin.ven-brothers.com/receipt/" + data.getPayment.approvalDate + data.getPayment.approvalNo);
  };

  useEffect(() => {
    if (data && data.getPayment) {
      setStatus(data.getPayment.receiveStatus);
    }
  }, [data]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>수령 상태 내용 확인</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>판매일시</p>
              </div>
              <input
                type={"text"}
                name={"date"}
                value={
                  data && data.getPayment
                    ? `${data.getPayment.approvalDate.slice(0, 2)}/${data.getPayment.approvalDate.slice(2, 4)}/${data.getPayment.approvalDate.slice(4, 6)} - ${data.getPayment.approvalTime.slice(0, 2)}:${data.getPayment.approvalTime.slice(2, 4)}:${data.getPayment.approvalTime.slice(4, 6)}`
                    : ""
                }
                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                readOnly={true}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자명</p>
              </div>
              <input type={"text"} name={"userName"} value={data && data.getPayment ? data.getPayment.userName : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자 연락처</p>
              </div>
              <input type={"text"} name={"userContact"} value={data && data.getPayment ? data.getPayment.userContact : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자 주소</p>
              </div>
              <input type={"text"} name={"userAddress"} value={data && data.getPayment ? data.getPayment.userAddress : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품명</p>
              </div>
              <input type={"text"} name={"product"} value={data && data.getPayment ? data.getPayment.productName : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>판매 수량</p>
              </div>
              <input type={"text"} name={"count"} value={data && data.getPayment ? "1" : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제 금액</p>
              </div>
              <input type={"text"} name={"product"} value={data && data.getPayment ? `${numberWithCommas(data.getPayment.totalAmount)} 원` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제 수단</p>
              </div>
              <input
                type={"text"}
                name={"issuer"}
                value={data && data.getPayment ? `${data.getPayment.issuerInfo ? data.getPayment.issuerInfo : ""}(${data.getPayment.installment === 0 ? "일시불" : `${data.getPayment.installment}개월`})` : ""}
                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                readOnly={true}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제 상태</p>
              </div>
              {data && data.getPayment ? (
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input
                    type={"text"}
                    value={data.getPayment.status === "paid" ? "결제완료" : data.getPayment.status === "cancelled" ? "결제취소" : data.getPayment.status === "refund" ? "환불" : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                    style={{ minWidth: 20 }}
                    readOnly={true}
                  />
                </div>
              ) : (
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15}`}></div>
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showStatus ? 0 : 10,
                  borderBottomRightRadius: showStatus ? 0 : 10,
                }}
                onClick={() => setShowStatus(!showStatus)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>수령 상태</p>
                </div>
                {data && data.getPayment ? (
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                    <input
                      type={"text"}
                      value={status === "none" ? "미처리" : status === "prepare" ? "상품준비중" : status === "ready" ? "상품준비완료" : status === "delivering" ? "배송중" : status === "done" ? "수령완료" : ""}
                      className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                      style={{ minWidth: 20 }}
                      readOnly={true}
                    />
                    <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"수령 상태 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                ) : (
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15}`}></div>
                )}
              </div>
              {showStatus && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    <div className={`${styles.py10} ${styles.flex1}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("none")}>
                        <p className={`${styles.font14} ${styles.black}`}>미처리</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("prepare")}>
                        <p className={`${styles.font14} ${styles.black}`}>상품준비중</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("ready")}>
                        <p className={`${styles.font14} ${styles.black}`}>상품준비완료</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("delivering")}>
                        <p className={`${styles.font14} ${styles.black}`}>배송중</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("done")}>
                        <p className={`${styles.font14} ${styles.black}`}>수령 완료</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.fontM} ${styles.font16} ${styles.bgWhite} ${styles.black} ${styles.py10}`} style={{ width: "100%", textAlign: "center" }} onClick={showBill}>
                영 수 증
              </div>
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} `} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOrderReceiveStatus;
