import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";

const CheckInMachine = ({ machine }) => {
  const history = useHistory();

  return (
    <div
      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}
    >
      <div className={`${styles.flex1}`}>
        <p
          className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
        >
          {machine.username}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p
          className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
        >
          {machine.name}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p
          className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
        >
          {machine.status === "inuse" && "사용중"}
          {machine.status === "notuse" && "미사용"}
          {machine.status === "check" && "점검중"}
        </p>
      </div>
      <div className={`${styles.flex1}`}></div>
      <div className={`${styles.flex1}`}></div>
      <div className={`${styles.flex1}`}></div>
      <div
        className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}
      >
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mx10}`}
          onClick={() =>
            history.push({
              pathname: `/checkin/status/`,
              state: {
                machine: machine.username,
              },
              search: "?order=date_dsc&page=1&q=",
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
            출석 현황
          </p>
        </div>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: `/checkin/statics/`,
              state: {
                machine: machine.username,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
            출석 통계
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckInMachine;
