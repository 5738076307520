import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import {numberWithCommas} from "../../../common/utility";

const NoticeMachine = ({ machine, setShowModal }) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.username}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.status === 'inuse' && '사용중'}
                    {machine.status === 'notuse' && '미사용'}
                    {machine.status === 'check' && '점검중'}
                </p>
            </div>
            {/*<div className={`${styles.flex1}`}>*/}
            {/*    <p*/}
            {/*        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>*/}
            {/*        {numberWithCommas(machine.scheduleTotal)}*/}
            {/*    </p>*/}
            {/*</div>*/}
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(machine.noticeTotal)}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(machine.teacherTotal)}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(machine.memberReservationTotal)}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    onClick={() => {
                        if(machine.useSchedule) {
                            history.push({
                                pathname: `/notice/main/`,
                                state: {
                                    machine: machine.username
                                }
                            });
                        } else {
                            setShowModal(true);
                        }
                    }}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        공지사항 관리
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NoticeMachine;
