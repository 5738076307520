import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';

import styles from '../../../styles/styles.module.scss';
import { billSm } from '../../../common/modal';
import IntroImage from '../../../components/Home/IntroImage';
import { FETCH_URL } from '../../../config';
import Category from '../../../components/Video/Mobile/Category';

const CATEGORY = gql`
    {
        getVideoCategory {
            id
            name
            image
        }
    }
`;

const DETAIL_CATEGORY = gql`
    {
        getVideoDetailCategoryAll {
            id
            categoryId
            name
            image
        }
    }
`;

const CU_CATEGORY = gql`
    mutation addVideoCategory($id: Int!, $name: String!, $image: String!) {
        addVideoCategory(id: $id, name: $name, image: $image) {
            id
            name
            image
        }
    }
`;

const CU_DETAIL_CATEGORY = gql`
    mutation addVideoDetailCategory(
        $id: Int!
        $categoryId: Int!
        $name: String!
        $image: String!
    ) {
        addVideoDetailCategory(
            id: $id
            categoryId: $categoryId
            name: $name
            image: $image
        ) {
            id
            categoryId
            name
            image
        }
    }
`;

const DELETE_CATEGORY = gql`
    mutation deleteVideoCategory($categoryId: Int!) {
        deleteVideoCategory(categoryId: $categoryId)
    }
`;

const DELETE_DETAIL_CATEGORY = gql`
    mutation deleteVideoDetailCategory($detailCategoryId: Int!) {
        deleteVideoDetailCategory(detailCategoryId: $detailCategoryId)
    }
`;

const DEFAULT_CATEGORY = {
    id: -1,
    name: '',
    image: '',
};

const DEFAULT_DETAIL_CATEGORY = {
    id: -1,
    categoryId: -1,
    name: '',
    image: '',
};

const VideoCategory = () => {
    const history = useHistory();

    const imageRef = useRef();
    const detailImageRef = useRef();

    const [category, setCategory] = useState(DEFAULT_CATEGORY);
    const [showCategory, setShowCategory] = useState(null);
    const [showDetailCategory, setShowDetailCategory] = useState(null);
    const [categoryImage, setCategoryImage] = useState('');
    const [uploadCategoryImage, setUploadCategoryImage] = useState(null);
    const [detailCategoryImage, setDetailCategoryImage] = useState('');
    const [uploadDetailCategoryImage, setUploadDetailCategoryImage] =
        useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const { data: categorys, refetch: refetchCategory } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: detailCategorys, refetch: refetchDetailCategory } = useQuery(
        DETAIL_CATEGORY,
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const [addVideoCategoryM] = useMutation(CU_CATEGORY);
    const [deleteVideoCategoryM] = useMutation(DELETE_CATEGORY);
    const [addVideoDetailCategoryM] = useMutation(CU_DETAIL_CATEGORY);
    const [deleteVideoDetailCategoryM] = useMutation(DELETE_DETAIL_CATEGORY);

    const handleCategoryName = e => {
        const {
            target: { value },
        } = e;
        if (showCategory) {
            setShowCategory({
                ...showCategory,
                name: value,
            });
        }
    };

    const handleDetailCategoryName = e => {
        const {
            target: { value },
        } = e;
        if (showDetailCategory) {
            setShowDetailCategory({
                ...showDetailCategory,
                name: value,
            });
        }
    };

    const addCategoryImage = async e => {
        if (!loadingImage) {
            const file = e.target.files[0];
            if (
                file &&
                (file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png')
            ) {
                var _URL = window.URL || window.webkitURL;
                setLoadingImage(true);
                setUploadCategoryImage(file);
                var img = new Image();
                var reader = new FileReader();
                reader.onloadend = () => {
                    setCategoryImage(reader.result);
                };
                img.src = await _URL.createObjectURL(file);
                await reader.readAsDataURL(file);
                setLoadingImage(false);
            } else {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            }
        }
    };

    const addDetailCategoryImage = async e => {
        if (!loadingImage) {
            const file = e.target.files[0];
            if (
                file &&
                (file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png')
            ) {
                var _URL = window.URL || window.webkitURL;
                setLoadingImage(true);
                setUploadDetailCategoryImage(file);
                var img = new Image();
                var reader = new FileReader();
                reader.onloadend = () => {
                    setDetailCategoryImage(reader.result);
                };
                img.src = await _URL.createObjectURL(file);
                await reader.readAsDataURL(file);
                setLoadingImage(false);
            } else {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            }
        }
    };

    const removeCategoryImage = () => {
        setCategoryImage('');
        setUploadCategoryImage(null);
    };

    const removeDetailCategoryImage = () => {
        setDetailCategoryImage('');
        setUploadDetailCategoryImage(null);
    };

    const submitCategory = async () => {
        if (!loading && !loadingImage) {
            if (!(showCategory && showCategory.name && categoryImage)) {
                alert('위 정보를 입력해주세요.');
                return;
            }
            setLoading(true);
            try {
                let image = categoryImage;
                if (uploadCategoryImage) {
                    const imageData = new FormData();
                    imageData.append(
                        'productimage',
                        uploadCategoryImage,
                        uploadCategoryImage.name,
                    );
                    const { data } = await axios.post(
                        `${FETCH_URL}/api/upload`,
                        imageData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );
                    if (data && data.location) {
                        image = data.location;
                    }
                }
                const { data } = await addVideoCategoryM({
                    variables: {
                        id: showCategory.id,
                        name: showCategory.name,
                        image,
                    },
                });
                setLoading(false);
                if (data && data.addVideoCategory) {
                    setShowCategory(null);
                    refetchCategory();
                    refetchDetailCategory();
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        }
    };

    const submitDetailCategory = async () => {
        if (!loading && !loadingImage) {
            if (category.id < 0) {
                alert('카테고리를 선택해주세요.');
                return;
            }
            if (
                !(
                    showDetailCategory &&
                    showDetailCategory.name &&
                    detailCategoryImage
                )
            ) {
                alert('위 정보를 입력해주세요.');
                return;
            }
            setLoading(true);
            try {
                let image = detailCategoryImage;
                if (uploadDetailCategoryImage) {
                    const imageData = new FormData();
                    imageData.append(
                        'productimage',
                        uploadDetailCategoryImage,
                        uploadDetailCategoryImage.name,
                    );
                    const { data } = await axios.post(
                        `${FETCH_URL}/api/upload`,
                        imageData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );
                    if (data && data.location) {
                        image = data.location;
                    }
                }
                const { data } = await addVideoDetailCategoryM({
                    variables: {
                        id: showDetailCategory.id,
                        categoryId: category.id,
                        name: showDetailCategory.name,
                        image,
                    },
                });
                setLoading(false);
                if (data && data.addVideoDetailCategory) {
                    setShowDetailCategory(null);
                    refetchCategory();
                    refetchDetailCategory();
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        }
    };

    const removeCategory = async item => {
        if (!loading && item) {
            const confirm = window.confirm(
                '정말 삭제하시겠습니까?\n영상 카테고리를 삭제하면 관련된 영상과 세부카테고리가 모두 삭제됩니다.',
            );
            if (confirm) {
                setLoading(true);
                const { data } = await deleteVideoCategoryM({
                    variables: {
                        categoryId: item.id,
                    },
                });
                setLoading(false);
                if (data && data.deleteVideoCategory) {
                    if (category.id === item.id) {
                        setCategory(DEFAULT_CATEGORY);
                    }
                    refetchCategory();
                    refetchDetailCategory();
                    alert('삭제하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const removeDetailCategory = async item => {
        if (!loading && item) {
            const confirm = window.confirm(
                '정말 삭제하시겠습니까?\n영상 세부 카테고리를 삭제하면 관련된 영상이 모두 삭제됩니다.',
            );
            if (confirm) {
                setLoading(true);
                const { data } = await deleteVideoDetailCategoryM({
                    variables: {
                        detailCategoryId: item.id,
                    },
                });
                setLoading(false);
                if (data && data.deleteVideoDetailCategory) {
                    refetchCategory();
                    refetchDetailCategory();
                    alert('삭제하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (showCategory) {
            setCategoryImage(showCategory.image);
        } else {
            setCategoryImage('');
            setUploadCategoryImage(null);
            setLoadingImage(false);
            if (imageRef.current) {
                imageRef.current.value = '';
            }
        }
    }, [showCategory]);

    useEffect(() => {
        if (showDetailCategory) {
            setDetailCategoryImage(showDetailCategory.image);
        } else {
            setDetailCategoryImage('');
            setUploadDetailCategoryImage(null);
            setLoadingImage(false);
            if (detailImageRef.current) {
                detailImageRef.current.value = '';
            }
        }
    }, [showDetailCategory]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        영상 카테고리 관리
                    </p>
                    <div className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    카테고리
                                </p>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                                    onClick={() =>
                                        setShowCategory(DEFAULT_CATEGORY)
                                    }>
                                    <p
                                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        추가
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                style={{
                                    height: 200,
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }}>
                                {categorys?.getVideoCategory.map(c => {
                                    return (
                                        <Category
                                            key={c.id}
                                            category={c}
                                            selected={category.id === c.id}
                                            select={() => setCategory(c)}
                                            edit={() => setShowCategory(c)}
                                            remove={() => removeCategory(c)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`${styles.mt10}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    세부 카테고리
                                </p>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                                    onClick={
                                        category.id > -1
                                            ? () =>
                                                  setShowDetailCategory(
                                                      DEFAULT_DETAIL_CATEGORY,
                                                  )
                                            : null
                                    }>
                                    <p
                                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        추가
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                style={{
                                    height: 200,
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }}>
                                {detailCategorys?.getVideoDetailCategoryAll.map(
                                    c => {
                                        if (c.categoryId === category.id) {
                                            return (
                                                <Category
                                                    key={c.id}
                                                    category={c}
                                                    selected={false}
                                                    select={null}
                                                    edit={() =>
                                                        setShowDetailCategory(c)
                                                    }
                                                    remove={() =>
                                                        removeDetailCategory(c)
                                                    }
                                                />
                                            );
                                        }
                                    },
                                )}
                            </div>
                        </div>
                        <div className={`${styles.mt45}`}>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showCategory !== null}
                onRequestClose={() => setShowCategory(null)}
                style={billSm}>
                {showCategory && (
                    <div>
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                            카테고리 명
                        </p>
                        <input
                            type={'text'}
                            name={'categoryName'}
                            value={showCategory.name}
                            onChange={handleCategoryName}
                            className={`${styles.inputWhiteUnderline} ${styles.widthFull} ${styles.px10} ${styles.textCenter} ${styles.mt10}`}
                        />
                        <div className={`${styles.center}`}>
                            {categoryImage ? (
                                <div
                                    className={`${styles.px10} ${styles.mt10}`}>
                                    <div
                                        style={{
                                            width: 205.6,
                                            height: 150,
                                        }}>
                                        <IntroImage
                                            image={categoryImage}
                                            index={0}
                                            removeImage={removeCategoryImage}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${styles.px10} ${styles.mt10}`}>
                                    <label htmlFor="image">
                                        <div
                                            className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer}`}
                                            style={{
                                                width: 205.6,
                                                height: 150,
                                            }}>
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_add.png')
                                                        .default
                                                }
                                                alt={'add'}
                                                className={`${styles.icon20}`}
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                            <input
                                id={'image'}
                                ref={imageRef}
                                className={`${styles.none}`}
                                type={'file'}
                                accept={'.jpg,.jpeg,.png'}
                                onChange={addCategoryImage}
                            />
                        </div>
                        <div
                            className={`${styles.mt20} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`}
                            style={{
                                opacity: loading ? 0.4 : 1,
                            }}
                            onClick={submitCategory}>
                            <p
                                className={`${styles.fontR} ${styles.font14} ${styles.grayEf} ${styles.textCenter}`}>
                                저장
                            </p>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={showDetailCategory !== null}
                onRequestClose={() => setShowDetailCategory(null)}
                style={billSm}>
                {showDetailCategory && (
                    <div>
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                            세부 카테고리 명
                        </p>
                        <input
                            type={'text'}
                            name={'detailCategoryName'}
                            value={showDetailCategory.name}
                            onChange={handleDetailCategoryName}
                            className={`${styles.inputWhiteUnderline} ${styles.widthFull} ${styles.px10} ${styles.textCenter} ${styles.mt10}`}
                        />
                        <div className={`${styles.center}`}>
                            {detailCategoryImage ? (
                                <div
                                    className={`${styles.px10} ${styles.mt10}`}>
                                    <div
                                        style={{
                                            width: 205.6,
                                            height: 150,
                                        }}>
                                        <IntroImage
                                            image={detailCategoryImage}
                                            index={0}
                                            removeImage={
                                                removeDetailCategoryImage
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${styles.px10} ${styles.mt10}`}>
                                    <label htmlFor="detailImage">
                                        <div
                                            className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer}`}
                                            style={{
                                                width: 205.6,
                                                height: 150,
                                            }}>
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_add.png')
                                                        .default
                                                }
                                                alt={'add'}
                                                className={`${styles.icon20}`}
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                            <input
                                id={'detailImage'}
                                ref={detailImageRef}
                                className={`${styles.none}`}
                                type={'file'}
                                accept={'.jpg,.jpeg,.png'}
                                onChange={addDetailCategoryImage}
                            />
                        </div>
                        <div
                            className={`${styles.mt20} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`}
                            style={{
                                opacity: loading ? 0.4 : 1,
                            }}
                            onClick={submitDetailCategory}>
                            <p
                                className={`${styles.fontR} ${styles.font14} ${styles.grayEf} ${styles.textCenter}`}>
                                저장
                            </p>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default VideoCategory;
