import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";

const Machine = ({ machine, deleteMachine }) => {
  const history = useHistory();

  const date = new Date(Number(machine.createdAt));
  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>{machine.name}</p>
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: `/device/edit/`,
              state: {
                machine: machine.username,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
        </div>
        {/*<img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml15}`} onClick={deleteMachine} />*/}
      </div>
    </div>
  );
};

export default Machine;
