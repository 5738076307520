import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import {
    numberWithCommas,
} from "../../../common/utility";

const PointList = ({ point }) => {
    let cardNameText = point.userPointPayment && point.userPointPayment.cardName ? point.userPointPayment.cardName : '-';
    let installmentText = point.userPointPayment && (point.userPointPayment.installment === 0 ? '일시불' : point.userPointPayment.installment + '개월');
    let createdAtArr = point.createdAt ? new Date(parseInt(point.createdAt)+32400000)?.toISOString()?.split('T') : '';
    return (
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                </p>
                {createdAtArr.length > 1 ?
                    createdAtArr[0]?.replace('-', '/').replace('-', '/') + " · " + createdAtArr[1]?.split('.')[0]
                : '-'}
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {!point.type === 1 && '-'}
                    {(point.type === 1 && point.division !== 6) && '충전'}
                    {(point.type === 1 && point.division === 6) && '환불'}
                    {point.type === 2 && '사용'}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {point.point ? numberWithCommas(point.point) + ' P' : '-'}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {point.type === 1 && point.userPointPayment ?
                        (cardNameText) + ' (' + installmentText + ')' : '-'}
                </p>
            </div>
        </div>
    );
};

export default PointList;
