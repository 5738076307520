import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import {
    numberWithCommas,
    setDateFormat_yyyyMMdd,
    setDateFormatKor_aahhmm,
    setDateFormatKor_day
} from "../../../common/utility";

const ScheduleListItem = ({ schedule, deleteSchedule, toScheduleEdit, isShowEditBtns }) => {
    const history = useHistory();

    return (
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {schedule.name}
                </p>
            </div>
            {isShowEditBtns && (
                <div className={`${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                         onClick={() => toScheduleEdit(schedule.id)}>
                        <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            해당 스케쥴만 수정
                        </p>
                    </div>
                    <div
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.mt10} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                        onClick={() => deleteSchedule(schedule.id)}>
                        <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            해당 스케쥴만 삭제
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleListItem;
