import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useAuthContext } from "../../context/Auth";
import { TOKEN_KEY } from "../../common/constants";

import MachineHome from "../../screens/Mobile/Machine/MachineHome";
import MachineAdd from "../../screens/Mobile/Machine/MachineAdd";
import MachineFind from "../../screens/Mobile/Machine/MachineFind";
import MachineEdit from "../../screens/Mobile/Machine/MachineEdit";

import ProfileEdit from "../../screens/Mobile/Profile/ProfileEdit";

import SiteHome from "../../screens/Mobile/Site/SiteHome";
import SiteProducts from "../../screens/Mobile/Site/SiteProducts";
import SiteProductsOrdering from "../../screens/Mobile/Site/SiteProductsOrdering";
import SiteCategoryOrdering from "../../screens/Mobile/Site/SiteCategoryOrdering";
import SiteProductAdd from "../../screens/Mobile/Site/SiteProductAdd";
import SiteProductEdit from "../../screens/Mobile/Site/SiteProductEdit";
import SiteProductsDiscount from "../../screens/Mobile/Site/SiteProductsDiscount";
import AddSiteDiscount from "../../screens/Mobile/Site/AddSiteDiscount";
import EditSiteDiscount from "../../screens/Mobile/Site/EditSiteDiscount";
import SiteProductsPaymentStatus from "../../screens/Mobile/Site/SiteProductsPaymentStatus";

import OrderHome from "../../screens/Mobile/Order/OrderHome";
import OrderProducts from "../../screens/Mobile/Order/OrderProducts";
import OrderProductsOrdering from "../../screens/Mobile/Order/OrderProductsOrdering";
import OrderCategoryOrdering from "../../screens/Mobile/Order/OrderCategoryOrdering";
import OrderProductAdd from "../../screens/Mobile/Order/OrderProductAdd";
import OrderProductEdit from "../../screens/Mobile/Order/OrderProductEdit";
import OrderProductsDiscount from "../../screens/Mobile/Order/OrderProductsDiscount";
import AddOrderDiscount from "../../screens/Mobile/Order/AddOrderDiscount";
import EditOrderDiscount from "../../screens/Mobile/Order/EditOrderDiscount";
import OrderProductNotice from "../../screens/Mobile/Order/OrderProductNotice";
import OrderProductsPaymentStatus from "../../screens/Mobile/Order/OrderProductsPaymentStatus";
import EditOrderReceiveStatus from "../../screens/Mobile/Order/EditOrderReceiveStatus";

import MembershipHome from "../../screens/Mobile/Membership/MembershipHome";
import MembershipProducts from "../../screens/Mobile/Membership/MembershipProducts";
import MembershipProductsOrdering from "../../screens/Mobile/Membership/MembershipProductsOrdering";
import MembershipCategoryOrdering from "../../screens/Mobile/Membership/MembershipCategoryOrdering";
import MembershipProductAdd from "../../screens/Mobile/Membership/MembershipProductAdd";
import MembershipProductEdit from "../../screens/Mobile/Membership/MembershipProductEdit";
import MembershipProductsDiscount from "../../screens/Mobile/Membership/MembershipProductsDiscount";
import AddMembershipDiscount from "../../screens/Mobile/Membership/AddMembershipDiscount";
import EditMembershipDiscount from "../../screens/Mobile/Membership/EditMembershipDiscount";
import MembershipProductsPaymentStatus from "../../screens/Mobile/Membership/MembershipProductsPaymentStatus";
import EditMembershipType from "../../screens/Mobile/Membership/EditMembershipType";

import LockerHome from "../../screens/Mobile/Locker/LockerHome";
import LockerList from "../../screens/Mobile/Locker/LockerList";
import LockerEdit from "../../screens/Mobile/Locker/LockerEdit";
import LockerDetail from "../../screens/Mobile/Locker/LockerDetail";
import LockerProducts from "../../screens/Mobile/Locker/LockerProducts";
import LockerProductAdd from "../../screens/Mobile/Locker/LockerProductAdd";
import LockerProductEdit from "../../screens/Mobile/Locker/LockerProductEdit";
import LockerProductsDiscount from "../../screens/Mobile/Locker/LockerProductsDiscount";
import AddLockerDiscount from "../../screens/Mobile/Locker/AddLockerDiscount";
import EditLockerDiscount from "../../screens/Mobile/Locker/EditLockerDiscount";
import LockerProductsPaymentStatus from "../../screens/Mobile/Locker/LockerProductsPaymentStatus";
import LockerProductsPaymentDetail from "../../screens/Mobile/Locker/LockerProductsPaymentDetail";

import ConsultHome from "../../screens/Mobile/Consult/ConsultHome";
import ConsultProducts from "../../screens/Mobile/Consult/ConsultProducts";
import ConsultProductEdit from "../../screens/Mobile/Consult/ConsultProductEdit";
import ConsultFormEdit from "../../screens/Mobile/Consult/ConsultFormEdit";
import ConsultCategoryEdit from "../../screens/Mobile/Consult/ConsultCategoryEdit";

import CustomPaymentHome from "../../screens/Mobile/Custom/CustomPaymentHome";
import CustomPaymentStatus from "../../screens/Mobile/Custom/CustomPaymentStatus";
import EditCustomPayment from "../../screens/Mobile/Custom/EditCustomPayment";

import CashPaymentHome from "../../screens/Mobile/Cash/CashPaymentHome";
import CashPaymentStatus from "../../screens/Mobile/Cash/CashPaymentStatus";
import EditCashPayment from "../../screens/Mobile/Cash/EditCashPayment";
import AddCashPayment from "../../screens/Mobile/Cash/AddCashPayment";

import CheckInHome from "../../screens/Mobile/CheckIn/CheckInHome";
import CheckInStatus from "../../screens/Mobile/CheckIn/CheckInStatus";

////
import SearchEvent from "../../screens/Mobile/EventLog/SearchEvent";
import EntranceDoors from "../../screens/Mobile/Entrance/EntranceDoors";
import EntranceHome from "../../screens/Mobile/Entrance/EntranceHome";
///

import MemberHome from "../../screens/Mobile/Member/MemberHome";
import MemberList from "../../screens/Mobile/Member/MemberList";
import MemberAdd from "../../screens/Mobile/Member/MemberAdd";
import MemberEdit from "../../screens/Mobile/Member/MemberEdit";

import ContractHome from "../../screens/Mobile/Contract/ContractHome";
import ContractList from "../../screens/Mobile/Contract/ContractList";
import ContractDetail from "../../screens/Mobile/Contract/ContractDetail";
import ContractTermList from "../../screens/Mobile/Contract/ContractTermList";
import ContractTermAdd from "../../screens/Mobile/Contract/ContractTermAdd";
import ContractTermDetail from "../../screens/Mobile/Contract/ContractTermDetail";

import UserList from "../../screens/Mobile/User/UserList";
import UserEdit from "../../screens/Mobile/User/UserEdit";

import AdvertiseHome from "../../screens/Mobile/Advertise/AdvertiseHome";
import AdvertiseEdit from "../../screens/Mobile/Advertise/AdvertiseEdit";

import DeviceList from "../../screens/Mobile/Device/DeviceList";
import DeviceEdit from "../../screens/Mobile/Device/DeviceEdit";
import DeviceDetail from "../../screens/Mobile/Device/DeviceDetail";
import DeviceVideo from "../../screens/Mobile/Device/DeviceVideo";

import VideoHome from "../../screens/Mobile/Video/VideoHome";
import VideoAdd from "../../screens/Mobile/Video/VideoAdd";
import VideoEdit from "../../screens/Mobile/Video/VideoEdit";
import VideoCategory from "../../screens/Mobile/Video/VideoCategory";

import Error404 from "../../screens/Global/404";

import Navigation from "../../components/Global/Mobile/Navigation";
import ScheduleHome from "../../screens/Mobile/Schedule/ScheduleHome";
import ScheduleMain from "../../screens/Mobile/Schedule/ScheduleMain";
import ScheduleAdd from "../../screens/Mobile/Schedule/ScheduleAdd";
import ScheduleList from "../../screens/Mobile/Schedule/ScheduleList";
import EntranceEdit from "../../screens/Mobile/Entrance/EntranceEdit";
import TeacherMain from "../../screens/Mobile/Teacher/TeacherMain";
import TeacherAdd from "../../screens/Mobile/Teacher/TeacherAdd";
import TeacherEdit from "../../screens/Mobile/Teacher/TeacherEdit";
import CategoryMain from "../../screens/Mobile/Category/CategoryMain";
import CategoryAdd from "../../screens/Mobile/Category/CategoryAdd";
import CategoryEdit from "../../screens/Mobile/Category/CategoryEdit";
import NoticeHome from "../../screens/Mobile/Notice/NoticeHome";
import NoticeMain from "../../screens/Mobile/Notice/NoticeMain";
import NoticeAdd from "../../screens/Mobile/Notice/NoticeAdd";
import NoticeEdit from "../../screens/Mobile/Notice/NoticeEdit";
import ReservationList from "../../screens/Mobile/Schedule/ReservationList";
import HolidayMain from "../../screens/Mobile/Holiday/HolidayMain";
import HolidayAdd from "../../screens/Mobile/Holiday/HolidayAdd";
import HolidayEdit from "../../screens/Mobile/Holiday/HolidayEdit";
import ScheduleMembershipList from "../../screens/Mobile/Schedule/ScheduleMembershipList";
import MarketingHome from "../../screens/Mobile/MemberReservation/MarketingHome";
import TodayReservationList from "../../screens/Mobile/Schedule/TodayReservationList";
import PointUserMain from "../../screens/Mobile/Point/PointUserMain";
import PointUserAdd from "../../screens/Mobile/Point/PointUserAdd";
import ScheduleSearchList from "../../screens/Mobile/Schedule/ScheduleSearchList";
import ReservationLimit from "../../screens/Mobile/Schedule/ReservationLimit";

import ProfitInfo from "../../screens/Mobile/ProfitInfo/ProfitInfo";
import ReportResult from "../../screens/Mobile/ProfitInfo/ReportResult";

const PROFILE = gql`
  {
    getProfileAdmin {
      id
      username
      name
      status
      mobile
      lastLoginDate
      superuser
    }
  }
`;

const LOGIN = gql`
  mutation setLogin {
    setLogin
  }
`;

const LoggedInRoute = () => {
  const { data } = useQuery(PROFILE, {
    fetchPolicy: "cache-and-network",
  });

  const [setLoginM] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
  });

  let path = window.location.pathname;

  const AuthContext = useAuthContext();

  useEffect(() => {
    if (data && data.getProfileAdmin) {
      let isAdmin = localStorage.getItem("viaItem");
      console.log(data.getProfileAdmin.status);
      if (data.getProfileAdmin.status === "expire" && !isAdmin) {
        localStorage.removeItem(TOKEN_KEY);
        AuthContext.setIsLoggedIn(false);
      }
    }
  }, [data]);

  useEffect(() => {
    setLoginM();
  }, []);

  return (
    <Router>
      {data && data.getProfileAdmin ? (
        data.getProfileAdmin.superuser ? (
          <Switch>
            <Route exact path={"/"} component={UserList} />
            <Route exact path={"/user/edit/"} component={UserEdit} />
            <Route exact path={"/advertise/"} component={AdvertiseHome} />
            <Route exact path={"/advertise/edit/"} component={AdvertiseEdit} />
            <Route exact path={"/video/"} component={VideoHome} />
            <Route exact path={"/video/add/"} component={VideoAdd} />
            <Route exact path={"/video/edit/"} component={VideoEdit} />
            <Route exact path={"/video/category/edit/"} component={VideoCategory} />
            <Route exact path={"/profile/edit/"} component={ProfileEdit} />
            <Route exact path={"/device/"} component={DeviceList} />
            <Route exact path={"/device/edit/"} component={DeviceEdit} />
            <Route exact path={"/device/detail/"} component={DeviceDetail} />
            <Route exact path={"/device/video/"} component={DeviceVideo} />
            <Route exact path={"/member/list/"} component={MemberList} />
            <Route exact path={"/member/add/"} component={MemberAdd} />
            <Route exact path={"/member/edit/"} component={MemberEdit} />
            <Route exact path={"/entrance/edit/"} component={EntranceEdit} />
            <Route exact path={"/member_reservation/marketing/"} component={MarketingHome} />
            <Route exact path={"/report/"} component={ProfitInfo} />
            <Route exact path={"/reportresult/"} component={ReportResult} />
            <Route component={Error404} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path={"/"} component={MachineHome} />
            <Route exact path={"/device/add/"} component={MachineAdd} />
            <Route exact path={"/device/find/"} component={MachineFind} />
            <Route exact path={"/device/edit/"} component={MachineEdit} />
            <Route exact path={"/profile/edit/"} component={ProfileEdit} />
            <Route exact path={"/site/"} component={SiteHome} />
            <Route exact path={"/site/product/list/"} component={SiteProducts} />
            <Route exact path={"/site/product/ordering/"} component={SiteProductsOrdering} />
            <Route exact path={"/site/category/ordering/"} component={SiteCategoryOrdering} />
            <Route exact path={"/site/product/add/"} component={SiteProductAdd} />
            <Route exact path={"/site/product/edit/"} component={SiteProductEdit} />
            <Route exact path={"/site/product/discount/"} component={SiteProductsDiscount} />
            <Route exact path={"/site/product/discount/add/"} component={AddSiteDiscount} />
            <Route exact path={"/site/product/discount/edit/"} component={EditSiteDiscount} />
            <Route exact path={"/site/product/payment/status/"} component={SiteProductsPaymentStatus} />
            <Route exact path={"/order/"} component={OrderHome} />
            <Route exact path={"/order/product/list/"} component={OrderProducts} />
            <Route exact path={"/order/product/ordering/"} component={OrderProductsOrdering} />
            <Route exact path={"/order/category/ordering/"} component={OrderCategoryOrdering} />
            <Route exact path={"/order/product/add/"} component={OrderProductAdd} />
            <Route exact path={"/order/product/edit/"} component={OrderProductEdit} />
            <Route exact path={"/order/product/discount/"} component={OrderProductsDiscount} />
            <Route exact path={"/order/product/discount/add/"} component={AddOrderDiscount} />
            <Route exact path={"/order/product/discount/edit/"} component={EditOrderDiscount} />
            <Route exact path={"/order/product/notice/"} component={OrderProductNotice} />
            <Route exact path={"/order/product/payment/status/"} component={OrderProductsPaymentStatus} />
            <Route exact path={"/order/product/payment/detail/"} component={EditOrderReceiveStatus} />
            <Route exact path={"/membership/"} component={MembershipHome} />
            <Route exact path={"/membership/product/list/"} component={MembershipProducts} />
            <Route exact path={"/membership/product/ordering/"} component={MembershipProductsOrdering} />
            <Route exact path={"/membership/category/ordering/"} component={MembershipCategoryOrdering} />
            <Route exact path={"/membership/product/add/"} component={MembershipProductAdd} />
            <Route exact path={"/membership/product/edit/"} component={MembershipProductEdit} />
            <Route exact path={"/membership/product/discount/"} component={MembershipProductsDiscount} />
            <Route exact path={"/membership/product/discount/add/"} component={AddMembershipDiscount} />
            <Route exact path={"/membership/product/discount/edit/"} component={EditMembershipDiscount} />
            <Route exact path={"/membership/product/payment/status/"} component={MembershipProductsPaymentStatus} />
            <Route exact path={"/membership/product/payment/detail/"} component={EditMembershipType} />
            <Route exact path={"/locker/"} component={LockerHome} />
            <Route exact path={"/locker/list/"} component={LockerList} />
            <Route exact path={"/locker/edit/"} component={LockerEdit} />
            <Route exact path={"/locker/detail/"} component={LockerDetail} />
            <Route exact path={"/locker/product/list/"} component={LockerProducts} />
            <Route exact path={"/locker/product/add/"} component={LockerProductAdd} />
            <Route exact path={"/locker/product/edit/"} component={LockerProductEdit} />
            <Route exact path={"/locker/product/discount/"} component={LockerProductsDiscount} />
            <Route exact path={"/locker/product/discount/add/"} component={AddLockerDiscount} />
            <Route exact path={"/locker/product/discount/edit/"} component={EditLockerDiscount} />
            <Route exact path={"/locker/product/payment/status/"} component={LockerProductsPaymentStatus} />
            <Route exact path={"/locker/product/payment/detail/"} component={LockerProductsPaymentDetail} />
            <Route exact path={"/consult/"} component={ConsultHome} />
            <Route exact path={"/consult/product/list/"} component={ConsultProducts} />
            <Route exact path={"/consult/product/edit/"} component={ConsultProductEdit} />
            <Route exact path={"/consult/form/edit/"} component={ConsultFormEdit} />
            <Route exact path={"/consult/category/edit/"} component={ConsultCategoryEdit} />
            <Route exact path={"/custom/"} component={CustomPaymentHome} />
            <Route exact path={"/custom/payment/status/"} component={CustomPaymentStatus} />
            <Route exact path={"/custom/payment/detail/"} component={EditCustomPayment} />
            <Route exact path={"/cash/"} component={CashPaymentHome} />
            <Route exact path={"/cash/payment/status/"} component={CashPaymentStatus} />
            <Route exact path={"/cash/payment/detail/"} component={EditCashPayment} />
            <Route exact path={"/cash/payment/add/"} component={AddCashPayment} />
            <Route exact path={"/checkin/"} component={CheckInHome} />
            <Route exact path={"/checkin/status/"} component={CheckInStatus} />
            <Route exact path={"/entrance/door/log"} component={SearchEvent} />
            <Route exact path={"/entrance/"} component={EntranceHome} />
            <Route exact path={"/entrance/door/list/"} component={EntranceDoors} />

            <Route exact path={"/member/"} component={MemberHome} />
            <Route exact path={"/member/list/"} component={MemberList} />
            <Route exact path={"/member/add/"} component={MemberAdd} />
            <Route exact path={"/member/edit/"} component={MemberEdit} />
            <Route exact path={"/contract/"} component={ContractHome} />
            <Route exact path={"/contract/list/"} component={ContractList} />
            <Route exact path={"/contract/detail/"} component={ContractDetail} />
            <Route exact path={"/contract/terms/list/"} component={ContractTermList} />
            <Route exact path={"/contract/terms/add/"} component={ContractTermAdd} />
            <Route exact path={"/contract/terms/detail/"} component={ContractTermDetail} />
            <Route exact path={"/schedule/"} component={ScheduleHome} />
            <Route exact path={"/schedule/main/"} component={ScheduleMain} />
            <Route exact path={"/schedule/add/"} component={ScheduleAdd} />
            <Route exact path={"/schedule/list/"} component={ScheduleList} />
            <Route exact path={"/schedule/membership/list/"} component={ScheduleMembershipList} />
            <Route exact path={"/schedule/reservation/search_list/"} component={ScheduleSearchList} />
            <Route exact path={"/schedule/reservation/limit/"} component={ReservationLimit} />
            <Route exact path={"/teacher/main/"} component={TeacherMain} />
            <Route exact path={"/teacher/add/"} component={TeacherAdd} />
            <Route exact path={"/teacher/edit/"} component={TeacherEdit} />
            <Route exact path={"/category/main/"} component={CategoryMain} />
            <Route exact path={"/category/add/"} component={CategoryAdd} />
            <Route exact path={"/category/edit/"} component={CategoryEdit} />
            <Route exact path={"/notice/"} component={NoticeHome} />
            <Route exact path={"/notice/main/"} component={NoticeMain} />
            <Route exact path={"/notice/add/"} component={NoticeAdd} />
            <Route exact path={"/notice/edit/"} component={NoticeEdit} />
            <Route exact path={"/schedule/reservation/list/"} component={ReservationList} />
            <Route exact path={"/schedule/reservation/today_list/"} component={TodayReservationList} />
            <Route exact path={"/holiday/main/"} component={HolidayMain} />
            <Route exact path={"/holiday/add/"} component={HolidayAdd} />
            <Route exact path={"/holiday/edit/"} component={HolidayEdit} />
            <Route exact path={"/point/user/main/"} component={PointUserMain} />
            <Route exact path={"/point/user/add/"} component={PointUserAdd} />
            <Route exact path={"/report/"} component={ProfitInfo} />
            <Route exact path={"/reportresult/"} component={ReportResult} />
            <Route component={Error404} />
          </Switch>
        )
      ) : null}
      {path !== "/report" && path !== "/reportresult" && <Navigation />}
    </Router>
  );
};

export default LoggedInRoute;
