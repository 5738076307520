import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Video = ({ video, remove, loading }) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {video.name}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={
                        loading
                            ? null
                            : () =>
                                  history.push({
                                      pathname: '/video/edit/',
                                      state: {
                                          video,
                                      },
                                  })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        수정
                    </p>
                </div>
                <img
                    src={
                        require('../../../assets/images/icon_trash.png').default
                    }
                    alt={'삭제'}
                    className={`${styles.icon20} ${styles.ml20} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={remove}
                />
            </div>
        </div>
    );
};

export default Video;
