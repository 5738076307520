import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from "../../../apollo/cache";

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const DETAIL_CATEGORY = gql`
    query detailScheduleCategory($id: Int!) {
        detailScheduleCategory(id: $id) {
            name
        }
    }
`;

const EDIT_CATEGORY = gql`
    mutation editScheduleCategory($id: Int!, $name: String!) {
        editScheduleCategory(id: $id, name:$name) {
            success
            error
        }
    }
`;

const DELETE_CATEGORY = gql`
    mutation deleteScheduleCategory($id: Int!) {
        deleteScheduleCategory(id: $id) {
            success
            error
        }
    }
`;

const CategoryEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const name = useInput('');
    const [editCategory] = useMutation(EDIT_CATEGORY);

    const [loading, setLoading] = useState(false);

    const [deleteScheduleCategoryM] = useMutation(DELETE_CATEGORY);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const { data: categoryData, loading:loadingCategory, refetch:categoryRefetch } = useQuery(DETAIL_CATEGORY, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: location.state.category ? location.state.category.id : -1
        }
    });

    const deleteCategory = async () => {
        if (!loading) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await deleteScheduleCategoryM({
                    variables: {
                        id: location.state.category.id
                    }
                });
                setLoading(false);
                console.log(data)
                if (data && data.deleteScheduleCategory.success) {
                    cache.evict({
                        id: `Category:${location.state.category.id}`,
                    });
                    alert('카테고리를 삭제하였습니다.');
                    history.goBack();
                } else {
                    alert(data.deleteScheduleCategory.error);
                }
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);
            if (data && data.getMachine) {
                if (name.value) {
                    if (name.value.length<=10) {
                        console.log('name : ',name.value);
                        const { data: result } = await editCategory({
                            variables: {
                                id: location.state.category ? location.state.category.id : -1,
                                name: name.value
                            }
                        });
                        if (result && result.editScheduleCategory) {
                            if (result.editScheduleCategory.error) {
                                setLoading(false);
                                alert(result.editScheduleCategory.error);
                            } else {
                                setLoading(false);
                                alert('카테고리 정보를 수정하였습니다.');
                                categoryRefetch();
                            }
                        } else {
                            setLoading(false);
                            alert('오류가 발생하였습니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('카테고리명을 10글자 이내로 작성해주세요.');
                    }
                } else {
                    setLoading(false);
                    alert('필수 값을 모두 입력해주세요.');
                }
            }
        }
    }

    useEffect(() => {
        if (categoryData) {
            console.log(categoryData.detailScheduleCategory);
            name.setValue(categoryData.detailScheduleCategory.name);
        }
    }, [categoryData]);

    useEffect(() => {
        if (!location.state.machine&&!location.state.category) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                카테고리 관리
                                {`${
                                    data && data.getMachine
                                        ? ` (${data.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div style={{ width: 1250 }}>
                                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            카테고리명*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        value={name.value}
                                        onChange={name.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '카테고리명을 입력해주세요.'
                                        }
                                        maxLength={10}
                                    />
                                </div>
                            </div>

                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb40}`} style={{ width: 1250, marginTop:'30%' }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={deleteCategory}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        삭제
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py17} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryEdit;
