import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from "../../../hooks/useInput";
import NumberFormat from "react-number-format";

const PROFILE = gql`
    {
        getProfileAdmin {
            id
            username
            name
            superuser
        }
    }
`;

const ADD_POINT_PAY = gql`
    mutation addPointPay($charge: Int!) {
        addPointPay(charge:$charge) {
            success
            error
            id
            url
        }
    }
`;

const POINT = gql`
    {
        getUserPointAdmin {
            id
            point
            balance
        }
    }
`;

const PointUserAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const charge = useInput('');
    const [addPointPay] = useMutation(ADD_POINT_PAY);

    const [loading, setLoading] = useState(false);

    const { data: point_data } = useQuery(POINT, {
        fetchPolicy: 'cache-and-network',
    });

    const { data, loading:loadingProfile } = useQuery(PROFILE, {
        fetchPolicy: 'cache-and-network',
    });

    let payPop;
    const openInicis = async (id, url) => {
        payPop.location = url + "/point/getInicisForm?id=" + id + "&type=mobile";
    }

    const newPayment = async () => {

        if(loading) {
            return {
                result: false
            };
        }

        if(isNaN(parseInt(charge.value)) || parseInt(charge.value) === 0 || parseInt(charge.value) % 10000 > 0) {
            // alert("만원 단위로 충전가능합니다");
            return {
                result: false,
                message: "만원 단위로 충전가능합니다"
            };
        }

        setLoading(true);
        const { data: result } = await addPointPay({
            variables: {
                charge: parseInt(charge.value)
            }
        });
        if (result && result.addPointPay) {
            if (result.addPointPay.error) {
                setLoading(false);
                return {
                    result: false,
                    message: result.addPointPay.error
                };
            } else {
                return {
                    result: true,
                    id: result.addPointPay.id,
                    url: result.addPointPay.url
                };
            }
        } else {
            setLoading(false);
            alert('오류가 발생하였습니다.');
        }
        return {
            result: false
        };
    };

    const handleCharge = values => {
        const {value} = values;
        charge.setValue(value);
    };

    useEffect(() => {
        if (!data) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    window.onmessage = function(e) {
        window.location.reload();
    }

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        알림톡 포인트 충전
                    </p>
                    <form
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p
                                className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    남은 포인트
                                </p>
                            </div>
                            <NumberFormat
                                value={point_data && point_data.getUserPointAdmin ? point_data.getUserPointAdmin.balance : 0}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                type={'tel'}
                                displayType={'input'}
                                readOnly
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    충전할 액수*
                                </p>
                            </div>
                            <NumberFormat
                                value={charge.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleCharge}
                                placeholder={
                                    '충전할 액수를 입력해주세요.'
                                }
                            />
                        </div>
                        <div className={`${styles.mt45}`}>
                            <button
                                type={'button'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={() => {
                                    payPop = window.open();
                                    newPayment().then(value => {
                                        if(value.result) {
                                            openInicis(value.id, value.url);
                                        } else {
                                            payPop.close();
                                            if(value.message) {
                                                setTimeout(function () {
                                                    alert(value.message);
                                                }, 200);
                                            }
                                        }
                                    });
                                }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    충전하기
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PointUserAdd;
