import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const CATEGORY = gql`
    {
        getVideoCategory {
            id
            name
            hasDetailCategory
        }
    }
`;

const DETAIL_CATEGORY = gql`
    {
        getVideoDetailCategoryAll {
            id
            categoryId
            name
        }
    }
`;

const CONNECT = gql`
    mutation connectVideo($machineId: Int!, $ids: String!) {
        connectVideo(machineId: $machineId, ids: $ids)
    }
`;

const DISCONNECT = gql`
    mutation disconnectVideo($machineId: Int!, $id: Int!) {
        disconnectVideo(machineId: $machineId, id: $id)
    }
`;

const ACTIVE = gql`
    query getActiveVideo($id: Int!) {
        getActiveVideo(id: $id) {
            id
            name
            categoryId
            detailCategoryId
            status
        }
    }
`;

const INACTIVE = gql`
    query getInactiveVideo($id: Int!) {
        getInactiveVideo(id: $id) {
            id
            name
            categoryId
            detailCategoryId
            status
        }
    }
`;

const DeviceVideo = () => {
    const history = useHistory();
    const location = useLocation();

    const device = location.state.device;

    const [category, setCategory] = useState({
        id: -1,
        name: '',
        hasDetailCategory: false,
    });
    const [showCategory, setShowCategory] = useState(false);
    const [detailCategory, setDetailCategory] = useState({
        id: -1,
        categoryId: -1,
        name: '',
    });
    const [showDetailCategory, setShowDetailCategory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const { data: categorys } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: detailCategorys } = useQuery(DETAIL_CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: active, refetch: refetchActive } = useQuery(ACTIVE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: device.id,
        },
    });
    const { data: inactive, refetch: refetchInactive } = useQuery(INACTIVE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: device.id,
        },
    });

    const [connectVideoM] = useMutation(CONNECT);
    const [disconnectVideoM] = useMutation(DISCONNECT);

    const handleCategory = category => {
        setCategory(category);
        setShowCategory(false);
        setDetailCategory({
            id: -1,
            categoryId: -1,
            name: '',
        });
    };

    const handleDetailCategory = detailCategory => {
        setDetailCategory(detailCategory);
        setShowDetailCategory(false);
    };

    const handleSelected = video => {
        const findIndex = selected.findIndex(item => item === video.id);
        if (findIndex > -1) {
            let newSelected = [...selected];
            newSelected.splice(findIndex, 1);
            setSelected(newSelected);
        } else {
            setSelected([...selected, video.id]);
        }
    };

    const submit = async () => {
        if (!loading && location.state.device) {
            if (selected.length > 0) {
                setLoading(true);
                const { data } = await connectVideoM({
                    variables: {
                        machineId: device.id,
                        ids: JSON.stringify(selected),
                    },
                });
                setLoading(false);
                if (data && data.connectVideo) {
                    alert('저장하였습니다.');
                    refetchActive();
                    refetchInactive();
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const remove = async video => {
        if (!loading && location.state.device) {
            if (video.status === 'inuse') {
                alert('전체 송출 영상은 송출을 중단할 수 없습니다.');
            } else {
                const confirm = window.confirm(
                    '기기에서 해당 영상을 송출 중단하시겠습니까?',
                );
                if (confirm) {
                    setLoading(true);
                    const { data } = await disconnectVideoM({
                        variables: {
                            machineId: device.id,
                            id: video.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.disconnectVideo) {
                        alert('저장하였습니다.');
                        refetchActive();
                        refetchInactive();
                    } else {
                        alert('송출 중단할 수 없는 영상입니다.');
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (!location.state.device) {
            alert('잘못된 접근입니다.');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        기기 영상 관리
                    </p>
                    <div className={`${styles.mt40} ${styles.px20}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    기기명
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                <input
                                    type={'text'}
                                    value={device.name ? device.name : ''}
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                    style={{ minWidth: 20 }}
                                    placeholder={'기기명'}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showCategory
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showCategory
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowCategory(!showCategory)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        카테고리
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            category && category.id > -1
                                                ? category.name
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={'카테고리를 선택해주세요.'}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showCategory
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                      .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                      .default
                                        }
                                        alt={'카테고리 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showCategory && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll}`}
                                    style={{
                                        left: 0,
                                        right: 0,
                                        maxHeight: 200,
                                        overflowY: 'scroll',
                                    }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            {categorys &&
                                            categorys.getVideoCategory
                                                ? categorys.getVideoCategory.map(
                                                      c => {
                                                          return (
                                                              <div
                                                                  key={c.id}
                                                                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                  <p
                                                                      className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                      onClick={() =>
                                                                          handleCategory(
                                                                              c,
                                                                          )
                                                                      }>
                                                                      {c.name}
                                                                  </p>
                                                              </div>
                                                          );
                                                      },
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showDetailCategory
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showDetailCategory
                                        ? 0
                                        : 10,
                                }}
                                onClick={() =>
                                    setShowDetailCategory(!showDetailCategory)
                                }>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        세부 카테고리
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            detailCategory &&
                                            detailCategory.id > -1
                                                ? detailCategory.name
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={
                                            '세부 카테고리를 선택해주세요.'
                                        }
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showDetailCategory
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                      .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                      .default
                                        }
                                        alt={'세부 카테고리 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showDetailCategory && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll}`}
                                    style={{
                                        left: 0,
                                        right: 0,
                                        maxHeight: 200,
                                        overflowY: 'scroll',
                                    }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            {detailCategorys &&
                                            detailCategorys.getVideoDetailCategoryAll
                                                ? detailCategorys.getVideoDetailCategoryAll.map(
                                                      c => {
                                                          if (
                                                              category.id ===
                                                              c.categoryId
                                                          ) {
                                                              return (
                                                                  <div
                                                                      key={c.id}
                                                                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                      <p
                                                                          className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                          onClick={() =>
                                                                              handleDetailCategory(
                                                                                  c,
                                                                              )
                                                                          }>
                                                                          {
                                                                              c.name
                                                                          }
                                                                      </p>
                                                                  </div>
                                                              );
                                                          }
                                                      },
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                송출 가능한 영상
                            </p>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                style={{
                                    height: 200,
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }}>
                                {inactive?.getInactiveVideo.map(video => {
                                    const isSelected =
                                        selected.findIndex(
                                            item => item === video.id,
                                        ) > -1;
                                    let render = true;
                                    if (category.id > -1) {
                                        if (category.id !== video.categoryId) {
                                            render = false;
                                        }
                                    }
                                    if (detailCategory.id > -1) {
                                        if (
                                            detailCategory.id !==
                                            video.detailCategoryId
                                        ) {
                                            render = false;
                                        }
                                    }
                                    if (render) {
                                        return (
                                            <div
                                                key={video.id}
                                                className={`${styles.px15} ${
                                                    styles.py10
                                                } ${styles.cursorPointer} ${
                                                    styles.borderBottomGrayA2
                                                } ${
                                                    isSelected
                                                        ? styles.bgGrayEf
                                                        : styles.bgWhite
                                                }`}
                                                onClick={() =>
                                                    handleSelected(video)
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                    {video.name}
                                                </p>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div className={`${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                현재 송출중인 영상
                            </p>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                style={{
                                    height: 200,
                                    overflowY: 'scroll',
                                    overflowX: 'hidden',
                                }}>
                                {active?.getActiveVideo.map(video => {
                                    return (
                                        <div
                                            key={video.id}
                                            className={`${styles.px15} ${
                                                styles.py10
                                            } ${styles.cursorPointer} ${
                                                styles.borderBottomGrayA2
                                            } ${
                                                video.status === 'inuse'
                                                    ? styles.bgGrayEf
                                                    : styles.bgWhite
                                            }`}
                                            style={{
                                                opacity:
                                                    video.status === 'inuse'
                                                        ? 0.4
                                                        : 1,
                                            }}
                                            onClick={() => remove(video)}>
                                            <p
                                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                {video.name}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`${styles.mt45}`}>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={submit}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    저장
                                </p>
                            </div>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceVideo;
