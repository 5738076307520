import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { emailReg } from '../../../common/regex';

const USERNAME = gql`
    query findMachine($email: String!) {
        findMachine(email: $email) {
            id
            username
        }
    }
`;

const PASSWORD = gql`
    mutation findMachinePassword($username: String!, $email: String!) {
        findMachinePassword(username: $username, email: $email)
    }
`;

const MachineFind = () => {
    const history = useHistory();

    const email = useInput('');
    const email2 = useInput('');
    const username = useInput('');

    const [usernames, setUsernames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [finded, setFinded] = useState(false);

    const { refetch } = useQuery(USERNAME, {
        fetchPolicy: 'no-cache',
        skip: true,
    });

    const [findPasswordM] = useMutation(PASSWORD);

    const findUsername = async e => {
        e.preventDefault();
        if (!loading) {
            if (email.value) {
                if (emailReg.test(email.value)) {
                    setLoading(true);
                    const { data } = await refetch({
                        email: email.value,
                    });
                    setLoading(false);
                    if (data && data.findMachine) {
                        setFinded(true);
                        setUsernames(data.findMachine);
                    } else {
                        setFinded(false);
                        alert('오류가 발생하였습니다.');
                    }
                } else {
                    alert('올바른 형식의 이메일을 입력해주세요.');
                }
            } else {
                alert('이메일 주소를 입력해주세요.');
            }
        }
    };

    const findPassword = async e => {
        e.preventDefault();
        if (!loading) {
            if (username.value && email2.value) {
                if (emailReg.test(email2.value)) {
                    setLoading(true);
                    const { data } = await findPasswordM({
                        variables: {
                            username: username.value,
                            email: email2.value,
                        },
                    });
                    setLoading(false);
                    if (data && data.findMachinePassword) {
                        if (data.findMachinePassword === 'success') {
                            alert('임시비밀번호를 발송하였습니다.');
                            username.setValue('');
                            email2.setValue('');
                        } else {
                            alert(data.findMachinePassword);
                        }
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } else {
                    alert('올바른 형식의 이메일을 입력해주세요.');
                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                기기계정 찾기
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={findUsername}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.flex1} ${styles.overflowHidden}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 230 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                            가입한 이메일 주소
                                        </p>
                                    </div>
                                    <input
                                        type={'email'}
                                        name={'email'}
                                        value={email.value}
                                        onChange={email.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px10}`}
                                        placeholder={
                                            '기기에서 가입할 때 적은 이메일 주소를 입력해주세요.'
                                        }
                                    />
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px80} ${styles.py15} ${styles.cursorPointer} ${styles.ml20}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        찾기
                                    </p>
                                </button>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20} ${styles.mb60}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    나의 기기 계정
                                </p>
                                <div
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`}
                                    style={{
                                        width: 1208,
                                        height: 120,
                                        overflowY: 'scroll',
                                    }}>
                                    {finded ? (
                                        usernames && usernames.length > 0 ? (
                                            usernames.map((u, index) => (
                                                <p
                                                    key={index}
                                                    className={`${
                                                        styles.font16
                                                    } ${styles.black} ${
                                                        index === 0
                                                            ? ''
                                                            : styles.mt15
                                                    }`}>
                                                    {u.username}
                                                </p>
                                            ))
                                        ) : (
                                            <p
                                                className={`${styles.font16} ${styles.black}`}>
                                                입력하신 이메일로 가입한 기기가
                                                없습니다.
                                            </p>
                                        )
                                    ) : (
                                        <p
                                            className={`${styles.font16} ${styles.grayA2}`}>
                                            위에 입력하신 이메일 주소로 가입한
                                            모든 기기 계정이 표시됩니다.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                기기 비밀번호 찾기
                            </p>
                        </div>
                        <form
                            action={''}
                            onSubmit={findPassword}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            기기 계정
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'username'}
                                        value={username.value}
                                        onChange={username.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px10}`}
                                        placeholder={
                                            '기기에서 가입하신 기기 계정을 입력해주세요.'
                                        }
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            가입한 이메일 주소
                                        </p>
                                    </div>
                                    <input
                                        type={'email'}
                                        name={'email2'}
                                        value={email2.value}
                                        onChange={email2.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px10}`}
                                        placeholder={
                                            '기기에서 가입할 때 적은 이메일 주소를 입력해주세요.'
                                        }
                                    />
                                </div>
                            </div>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px90} ${styles.py15} ${styles.cursorPointer} ${styles.mt100} ${styles.mb70}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                    위 이메일 주소로 임시 비밀번호 전송
                                </p>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MachineFind;
