import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";

const LockerArea = ({ lockerArea, remove, machine, loading }) => {
  const EMPTY_LOCKER = [
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
  ];

  const history = useHistory();

  const [locker, setLocker] = useState([...EMPTY_LOCKER]);

  const initialize = () => {
    let newLocker = [...locker];
    for (const item of lockerArea.lockers) {
      newLocker[item.positionY][item.positionX].type = item.status;
      newLocker[item.positionY][item.positionX].number = item.number;
      newLocker[item.positionY][item.positionX].id = item.id;
    }
    setLocker(newLocker);
  };

  useEffect(() => {
    initialize();
  }, [lockerArea]);

  return (
    <div className={`${styles.borderRadius10} ${styles.borderBlack} ${styles.py25} ${styles.px20} ${styles.mt25}`} style={{ width: 615 }}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
        <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>
          {lockerArea.name}
          {` (${lockerArea.isDisplay ? "전시 중" : "전시 중단"})`}
        </p>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.ml10}`}>
          <div
            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer}`}
            onClick={() =>
              history.push({
                pathname: `/locker/edit/`,
                state: {
                  machine: machine.username,
                  lockerId: lockerArea.id,
                },
              })
            }
          >
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
          </div>
          <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.ml15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={remove} />
        </div>
      </div>
      <table className={`${styles.mt20}`}>
        <tbody>
          {locker.map((row, y) => {
            return (
              <tr key={y}>
                {row.map((col, x) => {
                  return (
                    <td
                      key={`${y}-${x}`}
                      className={`${col.type === "none" ? "" : styles.cursorPointer}`}
                      style={{
                        width: 571 / 15 - 2,
                        height: 571 / 15 - 2,
                        borderRadius: 5,
                        backgroundColor: col.type === "available" ? "#7b99ff" : col.type === "expired" ? "#edb9b9" : col.type === "inuse" ? "#d8d8d8" : "#fff",
                        opacity: col.type === "none" ? 0 : 1,
                      }}
                      onClick={
                        col.id
                          ? () =>
                              history.push({
                                pathname: `/locker/detail/`,
                                state: {
                                  machine: machine.username,
                                  lockerId: col.id,
                                },
                              })
                          : null
                      }
                    >
                      <p className={`${styles.fontR} ${styles.font13} ${styles.white} ${styles.textCenter}`}>{col.number}</p>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LockerArea;
