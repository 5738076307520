import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useState } from "react";
import Switch from "react-switch";
import { gql, useMutation, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { emailReg, numberReg } from "../../../common/regex";

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      contact
      email
      address
      extraAddress
      addressType
      sido
      sigungu
      bname
      name
      logo
      status
      color
      notice
      intro
      installmentPrice
      createdAt
      images {
        id
        image
      }
      talkContact
      talkPayment
      talkStock
      talkConsult
      talkCustomPayment
      useShopBill
      useAppTalk
      isAppPushSchedule
      isAppPushNotice
      isAppPushMembership
      appPushScheduleTime
      strappPushMembership1Day
      strappPushMembership2Cnt
      strappPushMembership3Day
      strappPushMembership3Cnt
      useSchedule
      useReportTalk
      useMembershipExpiry
    }
  }
`;

const AlarmModalFree = ({ closeModal, talkContact, talks, handleSetTalkContact }) => {
  const location = useLocation();

  const [curTalks, setCurTalks] = useState(talks);
  const [showTalk, setShowTalk] = useState(false);
  const [curTalkContact, setCurTalkContact] = useState(talkContact);

  //debugger;

  const handleTalks = (talk) => {
    console.log(talk);
    let newTalks = [...curTalks];
    const findIndex = curTalks.findIndex((t) => t === talk);
    if (findIndex > -1) {
      newTalks.splice(findIndex, 1);
      setCurTalks(newTalks);
    } else {
      if (talk === "useReportTalk") {
        if (!curTalkContact) {
          alert("먼저 기기 알림 수신 연락처를 작성해주세요.");
        } else {
          setCurTalks([...curTalks, talk]);
        }
      } else {
        setCurTalks([...curTalks, talk]);
      }
    }
  };

  const handleTalkContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      setCurTalkContact(value);
    }
  };

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  return (
    <div className={`${styles.bgWhite}`}>
      <div className={`${styles.px70} ${styles.py30}`}>
        <div className={`${styles.row} ${styles.justifyContentBetween} ${styles.flex}`}>
          <div className={`${styles.mb100} ${styles.fontB} ${styles.font20} ${styles.black}`}>
            <p>기기 알림 수신 설정(무료)</p>
          </div>
          <div>
            <img
              src={require("../../../assets/images/icon_close.png").default}
              alt={"이전"}
              className={`${styles.cursorPointer}`}
              style={{
                width: 45 * 0.6,
                height: 45 * 0.6,
              }}
              onClick={closeModal}
            />
          </div>
        </div>
        <div className={`${styles.center}`}>
          <div>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 680 }}>
                <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 270 }}>
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 알림 수신 설정(무료) 연락처</p>
                </div>
                <input type={"text"} value={curTalkContact} onChange={handleTalkContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder="수신할 연락처를 입력해주세요." />
              </div>
            </div>
            <div className={`${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  width: 680,
                  borderBottomLeftRadius: showTalk ? 0 : 10,
                  borderBottomRightRadius: showTalk ? 0 : 10,
                }}
                onClick={() => setShowTalk(!showTalk)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>수신할 기기 알림</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                  <p className={`${styles.font16} ${curTalks.length > 0 ? styles.black : styles.grayA2} ${styles.flex1}`}>{curTalks.length > 0 ? `${curTalks.length}개의 알림` : "수신할 알림을 선택해주세요."}</p>
                  <img src={showTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"알림 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showTalk && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 680 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 680 }}>
                    <div
                      className={`${styles.borderRightGrayA2} ${styles.px20}`}
                      style={{
                        width: 190,
                      }}
                    ></div>
                    <div className={`${styles.py10} ${styles.flex1}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                        <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>주문판매/회원권판매 결제 알림</p>
                        <Switch onChange={() => handleTalks("payment")} checked={curTalks.findIndex((t) => t === "payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                        <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>재고 부족 알림</p>
                        <Switch onChange={() => handleTalks("stock")} checked={curTalks.findIndex((t) => t === "stock") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                        <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>상담 신청 알림</p>
                        <Switch onChange={() => handleTalks("consult")} checked={curTalks.findIndex((t) => t === "consult") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                        <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>자유/현금 결제 알림</p>
                        <Switch onChange={() => handleTalks("custom_payment")} checked={curTalks.findIndex((t) => t === "custom_payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                        <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>일일 리포트</p>
                        <Switch onChange={() => handleTalks("useReportTalk")} checked={curTalks.findIndex((t) => t === "useReportTalk") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={`${styles.footer} ${styles.center} ${styles.row}`}>
            <div className={`${styles.mb80} ${styles.justifyContentBetween}`}>
              <button onClick={closeModal} className={`${styles.px100} ${styles.py20} ${styles.mx10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                취소
              </button>
              <button onClick={(e) => handleSetTalkContact(curTalkContact, curTalks, true)} className={`${styles.px100} ${styles.py20} ${styles.mx10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                저장
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmModalFree;
