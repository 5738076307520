import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { SketchPicker } from "react-color";
import DaumPostcode from "react-daum-postcode";
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import EXIF from "exif-js";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { sleep } from "../../../common/utility";
import IntroImage from "../../../components/Home/IntroImage";
import { emailReg, numberReg } from "../../../common/regex";
import { FETCH_URL } from "../../../config";
import { alramNumber } from "../../../common/modal";
import AlarmModalFree from "../Schedule/AlarmModalFree";
import AlarmModalPay from "../Schedule/AlarmModalPay";
import MenuControlModal from "./MenuControlModal";

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      contact
      email
      address
      extraAddress
      addressType
      sido
      sigungu
      bname
      name
      logo
      status
      color
      notice
      intro
      installmentPrice
      createdAt
      isBigNotice
      images {
        id
        image
        orientation
      }
      machineEntrances {
        delYn
      }
      talkContact
      talkPayment
      talkStock
      talkConsult
      talkCustomPayment
      useShopBill
      useAppTalk
      isAppPushSchedule
      isAppPushNotice
      isAppPushMembership
      appPushScheduleTime
      strappPushMembership1Day
      strappPushMembership2Cnt
      strappPushMembership3Day
      strappPushMembership3Cnt
      useSchedule
      useReportTalk
      useMembershipExpiry
      extraTalk {
        id
        seqOrder
        tel
        talkPayment
        talkStock
        talkConsult
        talkCustomPayment
        useReportTalk
      }
      machineMenuList {
        buyMembership
        seqBuyMembership
        txtBuyMembership
        callCunsult
        seqCallCunsult
        txtCallCunsult
        showGuideMovie
        seqShowGuideMovie
        txtShowGuideMovie
        entranceCheck
        seqEntranceCheck
        txtEntranceCheck
        buyCurItem
        seqBuyCurItem
        txtBuyCurItem
        buyorderItem
        seqBuyorderItem
        txtBuyorderItem
      }
    }
  }
`;

const EDIT = gql`
  mutation editMachine(
    $username: String!
    $password: String
    $name: String!
    $status: String!
    $logo: String
    $color: String
    $contact: String!
    $email: String!
    $address: String!
    $addressType: String!
    $sido: String!
    $sigungu: String!
    $bname: String!
    $extraAddress: String!
    $installmentPrice: Int!
    $notice: String
    $intro: String
    $images: String!
    $talkContact: String
    $talkPayment: Boolean!
    $talkStock: Boolean!
    $talkConsult: Boolean!
    $talkCustomPayment: Boolean!
    $useShopBill: Boolean
    $useAppTalk: Boolean
    $isAppPushSchedule: Boolean
    $isAppPushNotice: Boolean
    $isAppPushMembership: Boolean
    $appPushScheduleTime: Int
    $strappPushMembership1Day: String
    $strappPushMembership2Cnt: String
    $strappPushMembership3Day: String
    $strappPushMembership3Cnt: String
    $useReportTalk: Boolean
    $useMembershipExpiry: Boolean
    $extraTalk: String
    $machineMenuList: String
    $isBigNotice: Boolean
  ) {
    editMachine(
      username: $username
      password: $password
      name: $name
      status: $status
      logo: $logo
      color: $color
      contact: $contact
      email: $email
      address: $address
      addressType: $addressType
      sido: $sido
      sigungu: $sigungu
      bname: $bname
      extraAddress: $extraAddress
      installmentPrice: $installmentPrice
      notice: $notice
      intro: $intro
      images: $images
      talkContact: $talkContact
      talkPayment: $talkPayment
      talkStock: $talkStock
      talkConsult: $talkConsult
      talkCustomPayment: $talkCustomPayment
      useShopBill: $useShopBill
      useAppTalk: $useAppTalk
      isAppPushSchedule: $isAppPushSchedule
      isAppPushNotice: $isAppPushNotice
      isAppPushMembership: $isAppPushMembership
      appPushScheduleTime: $appPushScheduleTime
      strappPushMembership1Day: $strappPushMembership1Day
      strappPushMembership2Cnt: $strappPushMembership2Cnt
      strappPushMembership3Day: $strappPushMembership3Day
      strappPushMembership3Cnt: $strappPushMembership3Cnt
      useReportTalk: $useReportTalk
      useMembershipExpiry: $useMembershipExpiry
      extraTalk: $extraTalk
      machineMenuList: $machineMenuList
      isBigNotice: $isBigNotice
    ) {
      machine {
        id
        username
        contact
        email
        address
        extraAddress
        addressType
        sido
        sigungu
        bname
        name
        logo
        status
        color
        notice
        intro
        createdAt
        images {
          id
          image
          orientation
        }
        talkContact
        talkPayment
        talkStock
        talkConsult
        talkCustomPayment
        useShopBill
        useAppTalk
        isAppPushSchedule
        isAppPushNotice
        isAppPushMembership
        appPushScheduleTime
        strappPushMembership1Day
        strappPushMembership2Cnt
        strappPushMembership3Day
        strappPushMembership3Cnt
        useSchedule
        useReportTalk
        useMembershipExpiry
        extraTalk {
          id
          seqOrder
          talkPayment
          talkStock
          talkConsult
          talkCustomPayment
          useReportTalk
        }
        machineMenuList {
          id
          machineId
          buyMembership
          seqBuyMembership
          txtBuyMembership
          callCunsult
          seqCallCunsult
          txtCallCunsult
          showGuideMovie
          seqShowGuideMovie
          txtShowGuideMovie
          entranceCheck
          seqEntranceCheck
          txtEntranceCheck
          buyCurItem
          seqBuyCurItem
          txtBuyCurItem
          buyorderItem
          seqBuyorderItem
          txtBuyorderItem
        }
        isBigNotice
      }
      error
    }
  }
`;

const DELETE = gql`
  mutation deleteMachine($machine: String!) {
    deleteMachine(machine: $machine)
  }
`;

const MachineEdit = () => {
  const history = useHistory();
  const location = useLocation();

  const password = useInput("");
  const name = useInput("");
  const contact = useInput("");
  const email = useInput("");
  const extraAddress = useInput("");
  const installmentPrice = useInput("");
  const notice = useInput("");
  const intro = useInput("");
  const talkContact = useInput("");

  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [uploadLogo, setUploadLogo] = useState(null);
  const [showLogo, setShowLogo] = useState(false);
  const [color, setColor] = useState("");
  const [showColor, setShowColor] = useState(false);
  const [address, setAddress] = useState({
    address: "",
    addressType: "",
    sido: "",
    sigungu: "",
    bname: "",
  });
  const [showAddress, setShowAddress] = useState(false);
  const [showDaum, setShowDaum] = useState(false);
  const [showTalk, setShowTalk] = useState(false);
  const [talks, setTalks] = useState([]);
  const [extraTalks, setExtraTalks] = useState([]);
  const [extraTalkTels, setExtraTalkTels] = useState();
  const [useShopBill, setUseShopBill] = useState(false);
  const [showUseShopBill, setShowUseShopBill] = useState(false);
  const [appTalk, setAppTalk] = useState(false);
  const [showAppTalk, setShowAppTalk] = useState(false);
  const [appTalkToggle, setAppTalkToggle] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [isBigNotice, setIsBigNotice] = useState(false);

  const scheduleTime = useInput("");
  const membership1Day = useInput("");
  const membership2Cnt = useInput("");
  const membership3Day = useInput("");
  const membership3Cnt = useInput("");

  const [menu, setMenu] = useState([]);

  const [showModal, setShowModal] = useState("");

  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [orientation, setOrientation] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [useMembershipExpiry, setUseMembershipExpiry] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [editMachineM] = useMutation(EDIT);
  const [deleteMachineM] = useMutation(DELETE);

  const handleUseMembershipExpiry = (useMembershipExpiry) => {
    if (useMembershipExpiry && (membership1Day.value <= 0 || membership2Cnt.value <= 0 || membership3Day.value <= 0 || membership3Cnt.value <= 0)) {
      alert("알림톡을 키려면 회원권 알림 내용을 모두 작성해주세요.");
    } else {
      setUseMembershipExpiry(useMembershipExpiry);
    }
  };

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const handleLogo = (logo) => {
    setLogo(logo);
    setUploadLogo(null);
    setShowLogo(false);
  };

  const handleColor = (color) => {
    setColor(color.hex);
    setShowColor(false);
  };

  const handleContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      contact.onChange(e);
    }
  };

  const handleSetTalkContact = (curTalkContact, curTalks, isShowModal) => {
    talkContact.setValue(curTalkContact);
    setTalks([...curTalks]);
    if (isShowModal) handleCloseModal();
  };

  const handleSaveExtraTalks = (curExtraTalks, isShowModal) => {
    setExtraTalks([...curExtraTalks]);
    console.log(curExtraTalks);

    let extraTalkTel = [];
    for (let i = 0; i < curExtraTalks.length; i++) {
      extraTalkTel.push(curExtraTalks[i].tel || "");
    }
    setExtraTalkTels(JSON.stringify(extraTalkTel).replaceAll("[", "").replaceAll("]", "").replaceAll('"', "").replaceAll(",", ", "));

    if (isShowModal) handleCloseModal();
  };

  const handleInstallmentPrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      installmentPrice.setValue(value);
    }
  };

  const handleAddress = (data) => {
    setAddress({
      address: data.address,
      addressType: data.addressType,
      sido: data.sido,
      sigungu: data.sigungu,
      bname: data.bname,
    });
    setShowDaum(false);
  };

  const handleTalkContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      talkContact.onChange(e);
    }
  };

  const handleAppTalkToggle = (toggle) => {
    const findIndex = appTalkToggle.findIndex((t) => t === toggle);
    if (findIndex > -1) {
      let newToggles = [...appTalkToggle];
      newToggles.splice(findIndex, 1);
      setAppTalkToggle(newToggles);
    } else {
      if (toggle === "schedule" && scheduleTime.value <= 0) {
        alert("알림을 키려면 예약한 수업 몇분 전 알림인지 작성해주세요.");
        return;
      }
      if (toggle === "membership" && (membership1Day.value <= 0 || membership2Cnt.value <= 0 || membership3Day.value <= 0 || membership3Cnt.value <= 0)) {
        alert("회원권 알림을 키려면 회원권 알림 내용을 모두 작성해주세요.");
        return;
      }
      setAppTalkToggle([...appTalkToggle, toggle]);
    }
  };

  const handleUseShopBill = (useShopBill) => {
    setUseShopBill(useShopBill);
    setShowUseShopBill(false);
  };

  const handleAppTalk = (appTalk) => {
    setAppTalk(appTalk);
    setShowAppTalk(false);
  };

  const handleScheduleTime = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      scheduleTime.setValue(value);
      if (value <= 0) {
        const findIndex = appTalkToggle.findIndex((t) => t === "schedule");
        if (findIndex > -1) {
          let newToggles = [...appTalkToggle];
          newToggles.splice(findIndex, 1);
          setAppTalkToggle(newToggles);
        }
      }
    }
  };

  const handleBigNotice = () => {
    setIsBigNotice(!isBigNotice);
  };

  const handleSaveMenuList = (modalMenuList) => {
    let isChkCnt = 0;
    for (let i = 0; i < modalMenuList.length; i++) {
      if (modalMenuList[i].ischked) isChkCnt++;
    }
    if (isChkCnt === 0) {
      alert("표시할 메뉴를 1개 이상 선택해주세요.");
      return false;
    }

    handleCloseModal();
    let tmpMenuList = {};
    tmpMenuList.machineId = data.getMachine.id;
    for (let i = 0; i < modalMenuList.length; i++) {
      if (modalMenuList[i].menuName === "회원권 구매") {
        tmpMenuList.buyMembership = modalMenuList[i].ischked;
        tmpMenuList.seqBuyMembership = modalMenuList[i].seq;
        tmpMenuList.txtBuyMembership = modalMenuList[i].menuText;
      } else if (modalMenuList[i].menuName === "상담 신청") {
        tmpMenuList.callCunsult = modalMenuList[i].ischked;
        tmpMenuList.seqCallCunsult = modalMenuList[i].seq;
        tmpMenuList.txtCallCunsult = modalMenuList[i].menuText;
      } else if (modalMenuList[i].menuName === "운동 가이드 영상 보기") {
        tmpMenuList.showGuideMovie = modalMenuList[i].ischked;
        tmpMenuList.seqShowGuideMovie = modalMenuList[i].seq;
        tmpMenuList.txtShowGuideMovie = modalMenuList[i].menuText;
      } else if (modalMenuList[i].menuName === "출석체크") {
        tmpMenuList.entranceCheck = modalMenuList[i].ischked;
        tmpMenuList.seqEntranceCheck = modalMenuList[i].seq;
        tmpMenuList.txtEntranceCheck = modalMenuList[i].menuText;
      } else if (modalMenuList[i].menuName === "현장 판매 상품 구매") {
        tmpMenuList.buyCurItem = modalMenuList[i].ischked;
        tmpMenuList.seqBuyCurItem = modalMenuList[i].seq;
        tmpMenuList.txtBuyCurItem = modalMenuList[i].menuText;
      } else if (modalMenuList[i].menuName === "주문 판매 상품 구매") {
        tmpMenuList.buyorderItem = modalMenuList[i].ischked;
        tmpMenuList.seqBuyorderItem = modalMenuList[i].seq;
        tmpMenuList.txtBuyorderItem = modalMenuList[i].menuText;
      }
    }
    setMenuList(tmpMenuList);
  };

  const handleMembership1Day = (e) => {
    //const { value } = e.target.value;
    let value = e.target.value;
    let rpValue = value
      .replace(/[^0-9,]/g, "")
      .replace(/,,$/, ",")
      .replace(/^,/, "");
    if (
      rpValue[rpValue.length - 1] == "," &&
      rpValue.match(/,/g).filter(function (item) {
        return item !== "";
      }).length == 5
    ) {
      rpValue = rpValue.replace(/,$/, "");
    }

    if (e.type === "blur") {
      rpValue = rpValue.replace(/,$/, "").replace(/^,/, "");
    }
    membership1Day.setValue(rpValue);
    if (numberReg.test(rpValue)) {
      if (rpValue <= 0) {
        const findIndex = appTalkToggle.findIndex((t) => t === "membership");
        if (findIndex > -1) {
          let newToggles = [...appTalkToggle];
          newToggles.splice(findIndex, 1);
          setAppTalkToggle(newToggles);
        }
      }
    }
  };

  const handleMembership2Cnt = (e) => {
    //const { value } = values;
    let value = e.target.value;
    let rpValue = value
      .replace(/[^0-9,]/g, "")
      .replace(/,,$/, ",")
      .replace(/^,/, "");
    if (
      rpValue[rpValue.length - 1] == "," &&
      rpValue.match(/,/g).filter(function (item) {
        return item !== "";
      }).length == 5
    ) {
      rpValue = rpValue.replace(/,$/, "");
    }

    if (e.type === "blur") {
      rpValue = rpValue.replace(/,$/, "").replace(/^,/, "");
    }
    membership2Cnt.setValue(rpValue);

    membership2Cnt.setValue(rpValue);
    if (numberReg.test(rpValue)) {
      if (rpValue <= 0) {
        const findIndex = appTalkToggle.findIndex((t) => t === "membership");
        if (findIndex > -1) {
          let newToggles = [...appTalkToggle];
          newToggles.splice(findIndex, 1);
          setAppTalkToggle(newToggles);
        }
      }
    }
  };

  const handleMembership3Day = (e) => {
    //const { value } = values;
    let value = e.target.value;
    let rpValue = value
      .replace(/[^0-9,]/g, "")
      .replace(/,,$/, ",")
      .replace(/^,/, "");
    if (
      rpValue[rpValue.length - 1] == "," &&
      rpValue.match(/,/g).filter(function (item) {
        return item !== "";
      }).length == 5
    ) {
      rpValue = rpValue.replace(/,$/, "");
    }

    if (e.type === "blur") {
      rpValue = rpValue.replace(/,$/, "").replace(/^,/, "");
    }
    membership3Day.setValue(rpValue);
    if (numberReg.test(rpValue)) {
      if (rpValue <= 0) {
        const findIndex = appTalkToggle.findIndex((t) => t === "membership");
        if (findIndex > -1) {
          let newToggles = [...appTalkToggle];
          newToggles.splice(findIndex, 1);
          setAppTalkToggle(newToggles);
        }
      }
    }
  };

  const handleMembership3Cnt = (e) => {
    //const { value } = values;
    let value = e.target.value;
    let rpValue = value
      .replace(/[^0-9,]/g, "")
      .replace(/,,$/, ",")
      .replace(/^,/, "");
    if (
      rpValue[rpValue.length - 1] == "," &&
      rpValue.match(/,/g).filter(function (item) {
        return item !== "";
      }).length == 5
    ) {
      rpValue = rpValue.replace(/,$/, "");
    }

    if (e.type === "blur") {
      rpValue = rpValue.replace(/,$/, "").replace(/^,/, "");
    }
    if (value[value.length - 2] === "," && value[value.length - 1] === ",") {
      value.splice(value.length - 1, 1);
    }
    membership3Cnt.setValue(rpValue);
    if (numberReg.test(rpValue)) {
      if (rpValue <= 0) {
        const findIndex = appTalkToggle.findIndex((t) => t === "membership");
        if (findIndex > -1) {
          let newToggles = [...appTalkToggle];
          newToggles.splice(findIndex, 1);
          setAppTalkToggle(newToggles);
        }
      }
    }
  };

  const addLogo = async (e) => {
    if (!loadingImage) {
      const files = e.target.files;

      let error = false;
      if (files[0].type === "image/jpg" || files[0].type === "image/jpeg" || files[0].type === "image/png") {
      } else {
        error = true;
      }
      if (error) {
        alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
      } else {
        setLoadingImage(true);
        let file = files[0];
        setLogo(file.name);
        setUploadLogo(file);
        setLoadingImage(false);
        setShowLogo(false);
      }
    }
  };

  const addImage = async (e) => {
    if (!loadingImage) {
      const files = e.target.files;

      if (images.length + files.length > 5) {
        alert("최대 5장의 사진을 선택하실 수 있습니다.");
      } else {
        var _URL = window.URL || window.webkitURL;
        let error = false;
        for (var i = 0; i < files.length; i++) {
          if (files[i].type === "image/jpg" || files[i].type === "image/jpeg" || files[i].type === "image/png") {
          } else {
            error = true;
          }
        }
        if (error) {
          alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
        } else {
          setLoadingImage(true);
          let newImages = [...images];
          let newUploadImages = [...uploadImages];
          let newOrientation = [...orientation];
          for (var i = 0; i < files.length; i++) {
            let file = files[i];
            newUploadImages[i + images.length] = file;
            await EXIF.getData(file, async function () {
              let curOrientation = await EXIF.getTag(this, "Orientation");
              newOrientation.push(curOrientation);
            });
            var img = new Image();
            var reader = new FileReader();
            reader.onloadend = () => {
              newImages[i + images.length] = {
                image: reader.result,
              };
            };
            img.src = await _URL.createObjectURL(file);
            await reader.readAsDataURL(file);
            while (true) {
              if (newImages[i + images.length]) {
                if (newImages[i + images.length].image === reader.result) {
                  break;
                }
              }
              await sleep(500);
            }
          }
          setImages(newImages);
          setUploadImages(newUploadImages);
          setOrientation(newOrientation);
          setLoadingImage(false);
        }
      }
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    const newUploadImages = [...uploadImages];
    const newOrientation = [...orientation];
    newImages.splice(index, 1);
    newUploadImages.splice(index, 1);
    newOrientation.splice(index, 1);
    setImages(newImages);
    setUploadImages(newUploadImages);
    setOrientation(newOrientation);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getMachine) {
        if (!talkContact.value) {
          if (talks.findIndex((t) => t === "useReportTalk") > -1) {
            setLoading(false);
            return alert("일일리포트 알림을 킬려면 기기 알림 수신 연락처를 작성해주세요.");
          }
        }
        if (name.value && status && contact.value && email.value && address.address && extraAddress.value) {
          if (emailReg.test(email.value)) {
            setLoading(true);
            try {
              let saveLogo = logo;
              if (uploadLogo) {
                const logoData = new FormData();
                const resizedImage = await resizeImage(uploadLogo);
                logoData.append("logo", resizedImage, uploadLogo.name);
                const { data } = await axios.post(`${FETCH_URL}/api/upload`, logoData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                if (data && data.location) {
                  saveLogo = data.location;
                }
              }
              let saveImages = [];
              let postImages = [];
              if (uploadImages && uploadImages.length > 0) {
                let index = 0;
                while (index < uploadImages.length) {
                  if (!uploadImages[index].id) {
                    postImages.push(uploadImages[index]);
                  }
                  index += 1;
                }
                if (postImages.length > 0) {
                  const formData = new FormData();
                  let reIndex = 0;
                  while (reIndex < postImages.length) {
                    if (!postImages[reIndex].id) {
                      const resizedImage = await resizeImage(postImages[reIndex]);
                      formData.append("introimage[]", resizedImage, postImages[reIndex].name);
                    }
                    reIndex += 1;
                  }
                  const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  if (data && data.locations) {
                    let locationIndex = 0;
                    let loopIndex = 0;
                    while (loopIndex < uploadImages.length) {
                      if (uploadImages[loopIndex].id) {
                        saveImages.push(uploadImages[loopIndex].image);
                      } else {
                        saveImages.push(data.locations[locationIndex]);
                        locationIndex += 1;
                      }
                      loopIndex += 1;
                    }
                  }
                } else {
                  let loopIndex = 0;
                  while (loopIndex < uploadImages.length) {
                    saveImages.push(uploadImages[loopIndex].image);
                    loopIndex += 1;
                  }
                }
              }
              let tmpImg = {};
              let submitImage = [];
              for (let i = 0; i < saveImages.length; i++) {
                tmpImg.image = saveImages[i];
                if (orientation[i] === undefined) orientation[i] = 1;
                tmpImg.orientation = orientation[i];
                submitImage.push(tmpImg);
                tmpImg = {};
              }
              if (saveImages.length == uploadImages.length) {
                const { data: result } = await editMachineM({
                  variables: {
                    username: data.getMachine.username,
                    password: password.value,
                    name: name.value,
                    status,
                    logo: saveLogo,
                    color,
                    contact: contact.value,
                    email: email.value,
                    address: address.address,
                    addressType: address.addressType,
                    sido: address.sido,
                    sigungu: address.sigungu,
                    bname: address.bname,
                    extraAddress: extraAddress.value,
                    installmentPrice: Number(installmentPrice.value),
                    notice: notice.value,
                    intro: intro.value,
                    images: JSON.stringify(submitImage),
                    talkContact: talkContact.value,
                    talkPayment: talks.findIndex((t) => t === "payment") > -1,
                    talkStock: talks.findIndex((t) => t === "stock") > -1,
                    talkConsult: talks.findIndex((t) => t === "consult") > -1,
                    talkCustomPayment: talks.findIndex((t) => t === "custom_payment") > -1,
                    useReportTalk: talks.findIndex((t) => t === "useReportTalk") > -1,
                    useShopBill,
                    useAppTalk: appTalk,
                    isAppPushSchedule: appTalkToggle.findIndex((t) => t === "schedule") > -1,
                    isAppPushNotice: appTalkToggle.findIndex((t) => t === "notice") > -1,
                    isAppPushMembership: appTalkToggle.findIndex((t) => t === "membership") > -1 || useMembershipExpiry,
                    appPushScheduleTime: Number(scheduleTime.value),
                    strappPushMembership1Day: membership1Day.value,
                    strappPushMembership2Cnt: membership2Cnt.value,
                    strappPushMembership3Day: membership3Day.value,
                    strappPushMembership3Cnt: membership3Cnt.value,
                    useMembershipExpiry,
                    extraTalk: JSON.stringify(extraTalks),
                    machineMenuList: JSON.stringify(menuList),
                    isBigNotice: isBigNotice,
                  },
                });
                if (result && result.editMachine) {
                  if (result.editMachine.error) {
                    setLoading(false);
                    alert(result.editMachine.error);
                  } else {
                    setLoading(false);
                    alert("기기 정보를 수정하였습니다.");
                  }
                } else {
                  setLoading(false);
                  alert("오류가 발생하였습니다.");
                }
              } else {
                setLoading(false);
                alert("오류가 발생하였습니다.");
              }
            } catch {
              setLoading(false);
              alert("오류가 발생하였습니다.");
            }
          } else {
            alert("올바른 형식의 이메일을 입력해주세요.");
          }
        } else {
          alert("위의 정보를 입력해주세요.");
        }
      }
    }
  };

  const deleteMachine = async () => {
    if (!loading) {
      if (data && data.getMachine) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data: result } = await deleteMachineM({
            variables: {
              machine: data.getMachine.username,
            },
          });
          setLoading(false);
          if (result && result.deleteMachine) {
            history.goBack();
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newImages = reorder(images, result.source.index, result.destination.index);
    const newUploadImages = reorder(uploadImages, result.source.index, result.destination.index);
    const newOrientation = reorder(orientation, result.source.index, result.destination.index);

    setImages(newImages);
    setUploadImages(newUploadImages);
    setOrientation(newOrientation);
  };

  const enterMenuList = (machine) => {
    const list = machine.machineMenuList;
    let tmpMenuList = {};

    tmpMenuList.buyMembership = true;
    tmpMenuList.seqBuyMembership = 1;

    tmpMenuList.showGuideMovie = true;
    tmpMenuList.seqShowGuideMovie = 2;

    tmpMenuList.callCunsult = true;
    tmpMenuList.seqCallCunsult = 3;

    tmpMenuList.entranceCheck = true;
    tmpMenuList.seqEntranceCheck = 4;

    tmpMenuList.buyCurItem = true;
    tmpMenuList.seqBuyCurItem = 5;

    tmpMenuList.buyorderItem = true;
    tmpMenuList.seqBuyorderItem = 6;
    if (list && list.length > 0) {
      tmpMenuList.buyMembership = list[0].buyMembership === false ? false : true;
      tmpMenuList.seqBuyMembership = list[0].seqBuyMembership === undefined ? 1 : list[0].seqBuyMembership;
      tmpMenuList.txtBuyMembership = list[0].txtBuyMembership;
      tmpMenuList.showGuideMovie = list[0].showGuideMovie === false ? false : true;
      tmpMenuList.seqShowGuideMovie = list[0].seqShowGuideMovie === undefined ? 2 : list[0].seqShowGuideMovie;
      tmpMenuList.txtShowGuideMovie = list[0].txtShowGuideMovie;
      tmpMenuList.callCunsult = list[0].callCunsult === false ? false : true;
      tmpMenuList.seqCallCunsult = list[0].seqCallCunsult === undefined ? 3 : list[0].seqCallCunsult;
      tmpMenuList.txtCallCunsult = list[0].txtCallCunsult;
      tmpMenuList.entranceCheck = list[0].entranceCheck === false ? false : true;
      tmpMenuList.seqEntranceCheck = list[0].seqEntranceCheck === undefined ? 4 : list[0].seqEntranceCheck;
      tmpMenuList.txtEntranceCheck = list[0].txtEntranceCheck;
      tmpMenuList.buyCurItem = list[0].buyCurItem === false ? false : true;
      tmpMenuList.seqBuyCurItem = list[0].seqBuyCurItem === undefined ? 5 : list[0].seqBuyCurItem;
      tmpMenuList.txtBuyCurItem = list[0].txtBuyCurItem;
      tmpMenuList.buyorderItem = list[0].buyorderItem === false ? false : true;
      tmpMenuList.seqBuyorderItem = list[0].seqBuyorderItem === undefined ? 6 : list[0].seqBuyorderItem;
      tmpMenuList.txtBuyorderItem = list[0].txtBuyorderItem;
    }
    return tmpMenuList;
  };

  const initialize = () => {
    if (data) {
      if (data.getMachine) {
        name.setValue(data.getMachine.name);
        contact.setValue(data.getMachine.contact);
        email.setValue(data.getMachine.email);
        extraAddress.setValue(data.getMachine.extraAddress);
        installmentPrice.setValue(String(data.getMachine.installmentPrice));
        notice.setValue(data.getMachine.notice);
        intro.setValue(data.getMachine.intro);
        talkContact.setValue(data.getMachine.talkContact ? data.getMachine.talkContact : "");
        setStatus(data.getMachine.status);
        setShowStatus(false);
        setLogo(data.getMachine.logo);
        setShowLogo(false);
        setColor(data.getMachine.color);
        setShowColor(false);
        setAddress({
          address: data.getMachine.address,
          addressType: data.getMachine.addressType,
          sido: data.getMachine.sido,
          sigungu: data.getMachine.sigungu,
          bname: data.getMachine.bname,
        });
        setUseShopBill(data.getMachine.useShopBill);
        setShowUseShopBill(false);
        setImages(data.getMachine.images);
        setOrientation([]);
        let tmpOri = [];
        for (let i = 0; i < data.getMachine.images.length; i++) {
          tmpOri.push(data.getMachine.images[i].orientation);
        }
        setOrientation(tmpOri);
        setUploadImages(data.getMachine.images);
        let talks = [];
        if (data.getMachine.talkPayment) {
          talks.push("payment");
        }
        if (data.getMachine.talkStock) {
          talks.push("stock");
        }
        if (data.getMachine.talkConsult) {
          talks.push("consult");
        }
        if (data.getMachine.talkCustomPayment) {
          talks.push("custom_payment");
        }
        if (data.getMachine.useReportTalk) {
          talks.push("useReportTalk");
        }
        setTalks(talks);
        let extraTalks = [];
        let tmpExtraTalks = {};
        if (data.getMachine.extraTalk) {
          for (let i = 0; i < data.getMachine.extraTalk.length; i++) {
            tmpExtraTalks.talkPayment = data.getMachine.extraTalk[i].talkPayment || false;
            tmpExtraTalks.talkStock = data.getMachine.extraTalk[i].talkStock || false;
            tmpExtraTalks.talkConsult = data.getMachine.extraTalk[i].talkConsult || false;
            tmpExtraTalks.talkCustomPayment = data.getMachine.extraTalk[i].talkCustomPayment || false;
            tmpExtraTalks.useReportTalk = data.getMachine.extraTalk[i].useReportTalk || false;
            tmpExtraTalks.tel = data.getMachine.extraTalk[i].tel || "";
            extraTalks.push(tmpExtraTalks);
            tmpExtraTalks = {};
          }
        }
        handleSaveExtraTalks(extraTalks, false);
        setAppTalk(data.getMachine.useAppTalk);
        setShowAppTalk(false);
        let appTalkArr = [];
        if (data.getMachine.isAppPushSchedule) {
          appTalkArr.push("schedule");
        }
        if (data.getMachine.isAppPushNotice) {
          appTalkArr.push("notice");
        }
        if (data.getMachine.isAppPushMembership || data.getMachine.useMembershipExpiry) {
          appTalkArr.push("membership");
        }
        setAppTalkToggle(appTalkArr);
        scheduleTime.setValue(String(data.getMachine.appPushScheduleTime));
        membership1Day.setValue(String(data.getMachine.strappPushMembership1Day));
        membership2Cnt.setValue(String(data.getMachine.strappPushMembership2Cnt));
        membership3Day.setValue(String(data.getMachine.strappPushMembership3Day));
        membership3Cnt.setValue(String(data.getMachine.strappPushMembership3Cnt));
        setUseMembershipExpiry(data.getMachine.useMembershipExpiry);
        setMenuList(enterMenuList(data.getMachine));
        setIsBigNotice(data.getMachine.isBigNotice);
      } else {
        alert("존재하지 않는 기기입니다.");
        history.goBack();
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    setShowDaum(false);
  }, [showAddress]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal("");
    document.body.style.overflow = "auto";
  };

  const handleOpenModal = (comp) => {
    setShowModal(comp);
    document.body.style.overflow = "hidden";
  };

  console.log("machineEdit");

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                기기관리
                {`${data && data.getMachine ? ` (${data.getMachine.name})` : ""}`}
              </p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div style={{ width: 1250 }}>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 계정*</p>
                  </div>
                  <input type={"text"} value={data && data.getMachine ? `${data.getMachine.username} (변경불가)` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 계정 비밀번호</p>
                  </div>
                  <input type={"password"} name={"password"} value={password.value} onChange={password.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"********"} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기명 (센터명)*</p>
                  </div>
                  <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"사용하실 기기명을 입력해주세요."} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showStatus ? 0 : 10,
                      borderBottomRightRadius: showStatus ? 0 : 10,
                    }}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상태*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${status ? styles.black : styles.grayA2}`}>
                        {status === "inuse" && "사용중"}
                        {status === "notuse" && "미사용"}
                        {status === "check" && "점검중"}
                        {status === "" && "기기 상태를 선택해주세요."}
                      </p>
                      <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showStatus && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("inuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>사용중</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("notuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>미사용</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("check")}>
                            <p className={`${styles.font16} ${styles.black}`}>점검중</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showLogo ? 0 : 10,
                      borderBottomRightRadius: showLogo ? 0 : 10,
                    }}
                    onClick={() => setShowLogo(!showLogo)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 로고</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.textAbbr}`}>
                      <p className={`${styles.font16} ${logo ? styles.black : styles.grayA2} ${styles.flex1} ${styles.textAbbr}`}>
                        {logo === "" ? "사용하실 기기 로고를 선택해주세요." : logo === "default" ? "벤브라더스 로고" : logo.startsWith("https://venbrothers.s3.ap-northeast-2.amazonaws.com/") ? logo.replace("https://venbrothers.s3.ap-northeast-2.amazonaws.com/", "") : logo}
                      </p>
                      <img src={showLogo ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showLogo && (
                    <div
                      className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                      style={{
                        width: 615,
                        opacity: loadingImage ? 0.4 : 1,
                      }}
                    >
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleLogo("default")}>
                            <p className={`${styles.font16} ${styles.black}`}>벤브라더스 로고</p>
                          </div>
                          <label htmlFor="logo">
                            <div className={`${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}>
                              <p className={`${styles.font16} ${styles.black}`}>로고 업로드</p>
                              <p className={`${styles.font13} ${styles.grayA2} ${styles.mt5}`}>*배경이 없는 투명한 이미지를 업로드해주세요.</p>
                            </div>
                          </label>
                          <input id={"logo"} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addLogo} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 색상</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1}`}>
                        <p className={`${styles.font16} ${color ? styles.black : styles.grayA2}`}>{color === "" ? "사용하실 기기 색상을 선택해주세요." : color === "#FF7701" ? "기본 색상" : color}</p>
                        <div
                          className={`${styles.ml10}`}
                          style={{
                            width: 50,
                            height: 20,
                            backgroundColor: color,
                          }}
                        />
                      </div>
                      <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowColor(!showColor)}>
                        변경
                      </p>
                    </div>
                  </div>
                  {showColor && (
                    <div
                      className={`${styles.center}`}
                      style={{
                        width: 615,
                        position: "absolute",
                      }}
                    >
                      <SketchPicker color={color} onChange={handleColor} />
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 연락처*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                    <input type={"text"} name={"contact"} value={contact.value} onChange={handleContact} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"사용하실 기기 연락처를 입력해주세요."} />
                    {contact.value !== "" && <p className={`${styles.font16} ${styles.black}`}>(관리자 호출시 표시되는 번호)</p>}
                  </div>
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showAddress ? 0 : 10,
                      borderBottomRightRadius: showAddress ? 0 : 10,
                    }}
                    onClick={() => setShowAddress(!showAddress)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 주소지*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <input type={"text"} value={address.address ? `${address.address} ${extraAddress.value}` : ""} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"사용하실 기기 주소지를 입력해주세요."} readOnly={true} />
                      {showAddress ? (
                        <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                          완료
                        </p>
                      ) : (
                        <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                          변경
                        </p>
                      )}
                    </div>
                  </div>
                  {showAddress && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        {showDaum ? (
                          <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                            <DaumPostcode onComplete={handleAddress} />
                          </div>
                        ) : (
                          <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>도 / 시</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sido}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>시 / 군 / 구</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sigungu}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>동 / 면 / 읍</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.bname}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.borderBottomGrayA2} ${styles.flex1} ${styles.mb60}`}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상세주소</p>
                              <input type={"text"} name={"extraAddress"} value={extraAddress.value} onChange={extraAddress.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"상세주소를 입력해주세요."} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 이메일 주소*</p>
                  </div>
                  <input type={"email"} name={"email"} value={email.value} onChange={email.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"사용하실 기기 이메일 주소를 입력해주세요."} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>할부선택 금액</p>
                  </div>
                  <NumberFormat value={installmentPrice.value} thousandSeparator={true} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} type={"tel"} displayType={"input"} onValueChange={handleInstallmentPrice} placeholder={"할부선택이 가능한 최소 금액을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 215 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 알림 수신 설정(무료)</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                    <input type={"tel"} name={"talkContact"} value={talkContact.value} readOnly={true} onChange={handleTalkContact} className={`${styles.inputWhite} ${styles.flex1}`} />
                    <p className={`${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => handleOpenModal("AlarmModalFree")}>
                      설정
                    </p>
                  </div>
                  <Modal isOpen={showModal === "AlarmModalFree"} onRequestClose={handleCloseModal} style={alramNumber}>
                    <AlarmModalFree talkContact={talkContact.value} talks={talks} handleSetTalkContact={handleSetTalkContact} closeModal={handleCloseModal} />
                  </Modal>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 215 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 알림 수신 설정(유료)</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                    <input type={"tel"} name={"talkContact"} value={extraTalkTels || ""} readOnly={true} onChange={handleTalkContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.textAbbr}`} />
                    <p className={`${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => handleOpenModal("AlarmModalPay")}>
                      설정
                    </p>
                  </div>
                  <Modal isOpen={showModal === "AlarmModalPay"} onRequestClose={handleCloseModal} style={alramNumber}>
                    <AlarmModalPay extraTalks={extraTalks} closeModal={handleCloseModal} handleSaveExtraTalks={handleSaveExtraTalks} />
                  </Modal>
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showUseShopBill ? 0 : 10,
                      borderBottomRightRadius: showUseShopBill ? 0 : 10,
                    }}
                    onClick={() => setShowUseShopBill(!showUseShopBill)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>가맹점용 영수증</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>{useShopBill ? "출력" : "미출력"}</p>
                      <img src={showUseShopBill ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"출력 여부 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showUseShopBill && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseShopBill(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>출력</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseShopBill(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>미출력</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={data && data.getMachine && data.getMachine.useSchedule ? `${styles.visible}` : `${styles.none}`}>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showAppTalk ? 0 : 10,
                      borderBottomRightRadius: showAppTalk ? 0 : 10,
                    }}
                    onClick={() => setShowAppTalk(!showAppTalk)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 215 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>스케쥴앱 설치 안내 알림톡</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>{appTalk ? "고객에게 발송" : "고객에게 발송하지 않음"}</p>
                      <img src={showAppTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"스케줄앱 알림 발송 여부 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showAppTalk && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 215,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppTalk(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>고객에게 발송</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppTalk(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>고객에게 발송하지 않음</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 메뉴 관리</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                    <input type={"tel"} name={"talkContact"} value={menu.length > 0 ? `${talks.length}개의 메뉴 표시` : ""} readOnly={false} onChange={handleTalkContact} className={`${styles.inputWhite} ${styles.flex1}`} />
                    <p className={`${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => handleOpenModal("MenuControlModal")}>
                      설정
                    </p>
                  </div>
                  <Modal isOpen={showModal === "MenuControlModal"} onRequestClose={handleCloseModal} style={alramNumber}>
                    <MenuControlModal menuList={menuList} closeModal={handleCloseModal} handleSaveMenuList={handleSaveMenuList} />
                  </Modal>
                </div>
              </div>
              <div
                className={`${data && data.getMachine && data.getMachine.useSchedule ? styles.visible : styles.none} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.mt20}`}
                style={{
                  width: 1250,
                  paddingBottom: "45px",
                  paddingTop: "15px",
                }}
              >
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>스케쥴앱 사용자에게 푸시 알림 (스케쥴앱 사용자에게 푸시 알림을 통해 {appTalkToggle.length}개 알림 발송)</p>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentAround} ${styles.fontR}`} style={{ marginTop: "27px" }}>
                  <div className={`${styles.alignItemsStretch}`}>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`}>
                      <div className={`${styles.row}`}>
                        <p>예약한 스케줄 시작하기</p>
                        <NumberFormat value={scheduleTime.value} thousandSeparator={false} style={{ width: "20%" }} className={`${styles.textCenter} ${styles.inputWhiteUnderline}`} type={"tel"} displayType={"input"} onValueChange={handleScheduleTime} placeholder={"00"} />
                        <p>분 전 알림</p>
                      </div>
                      <div>
                        <Switch onChange={() => handleAppTalkToggle("schedule")} checked={appTalkToggle.findIndex((t) => t === "schedule") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p>공지사항 알림</p>
                      </div>
                      <div>
                        <Switch onChange={() => handleAppTalkToggle("notice")} checked={appTalkToggle.findIndex((t) => t === "notice") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.alignItemsStretch}`} style={{ width: "550px" }}>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`}>
                      <div className={`${styles.row}`}>
                        <p>회원권 만료되기</p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership1Day.value}
                          onChange={handleMembership1Day}
                          onBlur={handleMembership1Day}
                        />
                        {/**
                        <NumberFormat
                          value={membership1Day.value}
                          thousandSeparator={false}
                          style={{ width: 70 }}
                          className={`${styles.textCenter} ${styles.inputWhiteUnderline}`}
                          type={"tel"}
                          displayType={"input"}
                          onValueChange={handleMembership1Day}
                          placeholder={"00"}
                        /> */}
                        <p>일 전 알림 (기간제 회원권)</p>
                      </div>
                      <div>
                        <Switch onChange={() => handleAppTalkToggle("membership")} checked={appTalkToggle.findIndex((t) => t === "membership") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                      </div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership2Cnt.value}
                          onChange={handleMembership2Cnt}
                          onBlur={handleMembership2Cnt}
                        />
                        {/*<NumberFormat
                          value={membership2Cnt.value}
                          thousandSeparator={false}
                          style={{ width: 70 }}
                          className={`${styles.textCenter} ${styles.inputWhiteUnderline}`}
                          type={"tel"}
                          displayType={"input"}
                          onValueChange={handleMembership2Cnt}
                          placeholder={"00"}
                        />*/}
                        <p>회 전 알림 (횟수제 회원권)</p>
                      </div>
                      <div style={{ width: "43px" }}></div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership3Day.value}
                          onChange={handleMembership3Day}
                          onBlur={handleMembership3Day}
                        />
                        {/**<NumberFormat
                          value={membership3Day.value}
                          thousandSeparator={false}
                          style={{ width: 70 }}
                          className={`${styles.textCenter} ${styles.inputWhiteUnderline}`}
                          type={"tel"}
                          displayType={"input"}
                          onValueChange={handleMembership3Day}
                          placeholder={"00"}
                        /> */}
                        <p>일 전과</p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership3Cnt.value}
                          onChange={handleMembership3Cnt}
                          onBlur={handleMembership3Cnt}
                        />
                        {/**<NumberFormat
                          value={membership3Cnt.value}
                          thousandSeparator={false}
                          style={{ width: 70 }}
                          className={`${styles.textCenter} ${styles.inputWhiteUnderline}`}
                          type={"tel"}
                          displayType={"input"}
                          onValueChange={handleMembership3Cnt}
                          placeholder={"00"}
                        />*/}
                        <p>회 전 알림 (기간+횟수제 회원권)</p>
                      </div>
                      <div style={{ width: "43px" }}></div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <p>카카오톡 알림톡으로 회원에게 만료 알림 전송 (유료)</p>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr20} ${styles.cursorPointer}`} onClick={() => handleUseMembershipExpiry(!useMembershipExpiry)}>
                          <div
                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}
                            style={{
                              marginLeft: "10px",
                              marginTop: "3px",
                            }}
                          >
                            {useMembershipExpiry && (
                              <div
                                className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                style={{
                                  width: 14,
                                  height: 14,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${data && data.getMachine && !data.getMachine.useSchedule ? styles.visible : styles.none} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.mt20}`}
                style={{
                  width: 1250,
                  paddingBottom: "45px",
                  paddingTop: "15px",
                }}
              >
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원권 만료 알림톡</p>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentAround} ${styles.fontR}`} style={{ marginTop: "27px" }}>
                  <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ width: "550px" }}>
                    <div className={`${styles.row}`}>
                      <div className={`${styles.row}`}>
                        <p>회원권 만료되기</p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership1Day.value}
                          onChange={handleMembership1Day}
                          onBlur={handleMembership1Day}
                        />
                        <p>일 전 알림 (기간제 회원권)</p>
                      </div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership2Cnt.value}
                          onChange={handleMembership2Cnt}
                          onBlur={handleMembership2Cnt}
                        />
                        <p>회 전 알림 (횟수제 회원권)</p>
                      </div>
                      <div style={{ width: "43px" }}></div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership3Day.value}
                          onChange={handleMembership3Day}
                          onBlur={handleMembership3Day}
                        />
                        <p>일 전과</p>
                        <input
                          style={{
                            width: 70,
                            border: "none",
                            borderBottom: "1px solid #a2a2a2",
                            textAlign: "center",
                            fontSize: "16px",
                          }}
                          placeholder={"00"}
                          value={membership3Cnt.value}
                          onChange={handleMembership3Cnt}
                          onBlur={handleMembership3Cnt}
                        />
                        <p>회 전 알림 (기간+횟수제 회원권)</p>
                      </div>
                      <div style={{ width: "43px" }}></div>
                    </div>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`} style={{ marginTop: "15px" }}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          회원권 만료되기
                        </p>
                        <p>카카오톡 알림톡으로 회원에게 만료 알림 전송 (유료)</p>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr20} ${styles.cursorPointer}`} onClick={() => handleUseMembershipExpiry(!useMembershipExpiry)}>
                          <div
                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}
                            style={{
                              marginLeft: "10px",
                              marginTop: "3px",
                            }}
                          >
                            {useMembershipExpiry && (
                              <div
                                className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                style={{
                                  width: 14,
                                  height: 14,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.alignItemsStretch}`}>
                    <div className={`${styles.row} ${styles.justifyContentBetween}`}>
                      <div className={`${styles.row}`}>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          예약한 스케줄 시작하기
                        </p>
                        <div
                          style={{
                            width: "20%",
                          }}
                        ></div>
                        <p
                          style={{
                            color: "transparent",
                          }}
                        >
                          분 전 알림
                        </p>
                      </div>
                      <div style={{ width: "43" }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.px20} ${styles.justifyContentBetween}`}>
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 공지사항 (최대 300자)</p>
                  <div className={`${styles.row} ${styles.cursorPointer}`} onClick={() => handleBigNotice(isBigNotice)}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>공지사항을 큰 글씨로 표시</p>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr20} `}>
                      <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`} style={{ marginLeft: "10px", marginTop: "3px" }}>
                        {isBigNotice && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
                      </div>
                    </div>
                  </div>
                </div>
                <textarea name={"notice"} value={notice.value || ""} onChange={notice.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"기기 공지사항을 입력해주세요."} maxLength={300} />
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 시설 소개 (최대 200자)</p>
                <textarea name={"intro"} value={intro.value || ""} onChange={intro.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"기기 시설 소개 내용을 입력해주세요."} maxLength={200} />
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 시설 소개 이미지 등록 (최대 5장)</p>
                  <label htmlFor="image">
                    <div
                      className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                      style={{
                        opacity: loadingImage ? 0.4 : 1,
                      }}
                    >
                      <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>이미지 추가하기</p>
                    </div>
                  </label>
                  <input id={"image"} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addImage} multiple={true} maxLength={5} />
                </div>
                {images && images.length > 0 ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided) => (
                        <div ref={provided.innerRef} className={`${styles.row} ${styles.mx0} ${styles.px40} ${styles.mt30}`} {...provided.droppableProps}>
                          {images.map((image, index) => (
                            <Draggable key={`${index}-${image.image}`} draggableId={`${index}-${image.image}`} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${index === 0 ? "" : styles.ml20}`}
                                  style={{
                                    width: 205.6,
                                    height: 150,
                                    backgroundColor: "#F4F4F4",
                                  }}
                                >
                                  <IntroImage image={image.image} index={index} orientation={image.orientation} removeImage={removeImage} horizontal={true} />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className={`${styles.row} ${styles.mx0} ${styles.px50} ${styles.mt30}`}>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                  </div>
                )}
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                {/*<div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={deleteMachine}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>삭제</p>
                </div>*/}
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>저장</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineEdit;
