import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";

const DETAIL = gql`
  query getContract($machine: String!, $contractId: Int!) {
    getContract(machine: $machine, contractId: $contractId) {
      id
      machine {
        id
        username
        name
      }
      terms {
        id
        name
        file
        createdAt
      }
      payment {
        id
        approvalNo
      }
      membership {
        id
        product {
          id
          name
        }
      }
      userName
      userContact
      checkTerm
      agreeTerm
      sign
      extra
      createdAt
    }
  }
`;

const EDIT = gql`
  mutation editContract($machine: String!, $contractId: Int!, $extra: String) {
    editContract(machine: $machine, contractId: $contractId, extra: $extra) {
      contract {
        id
        machine {
          id
          username
          name
        }
        terms {
          id
          name
          file
          createdAt
        }
        payment {
          id
          approvalNo
        }
        membership {
          id
          product {
            id
            name
          }
        }
        userName
        userContact
        checkTerm
        agreeTerm
        sign
        extra
        createdAt
      }
      error
    }
  }
`;

const ContractDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const extra = useInput("");

  const [contractDate, setContractDate] = useState(null);
  const [termsDate, setTermsDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.contract.machine.username,
      contractId: location.state.contract.id,
    },
    onError: () => {
      alert("계약이 존재하지 않습니다.");
      history.goBack();
    },
  });

  const [editContractM] = useMutation(EDIT);

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      try {
        const { data } = await editContractM({
          variables: {
            machine: location.state.contract.machine.username,
            contractId: location.state.contract.id,
            extra: extra.value,
          },
        });
        setLoading(false);
        if (data && data.editContract.contract) {
          alert("저장하였습니다.");
        } else if (data && data.editContract.error) {
          alert(data.editContract.error);
        } else {
          alert("오류가 발생하였습니다.");
        }
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    }
  };

  const initialize = () => {
    if (data && data.getContract) {
      setContractDate(new Date(Number(data.getContract.createdAt)));
      setTermsDate(new Date(Number(data.getContract.terms.createdAt)));
      extra.setValue(data.getContract.extra ? data.getContract.extra : "");
    }
  };

  useEffect(() => {
    if (data) {
      initialize();
    }
  }, [data]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>계약 상세 정보</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기명</p>
              </div>
              <input type={"text"} name={"machine"} value={data && data.getContract ? data.getContract.machine.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>계약일자</p>
              </div>
              <input
                type={"text"}
                name={"contractDate"}
                value={
                  contractDate
                    ? `${contractDate.getFullYear()}-${contractDate.getMonth() + 1 < 10 ? "0" + String(contractDate.getMonth() + 1) : contractDate.getMonth() + 1}-${contractDate.getDate() < 10 ? "0" + String(contractDate.getDate()) : contractDate.getDate()} ${
                        contractDate.getHours() < 10 ? "0" + contractDate.getHours() : contractDate.getHours()
                      }:${contractDate.getMinutes() < 10 ? "0" + contractDate.getMinutes() : contractDate.getMinutes()}:${contractDate.getSeconds() < 10 ? "0" + contractDate.getSeconds() : contractDate.getSeconds()}`
                    : ""
                }
                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                readOnly={true}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>약관명</p>
              </div>
              {data && data.getContract ? (
                <a target={"_blank"} href={data.getContract.terms.file} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1}`}>
                  <input type={"text"} name={"contractDate"} value={data && data.getContract ? data.getContract.terms.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
                </a>
              ) : null}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>약관 업로드</p>
              </div>
              <input
                type={"text"}
                name={"termDate"}
                value={
                  termsDate
                    ? `${termsDate.getFullYear()}-${termsDate.getMonth() + 1 < 10 ? "0" + String(termsDate.getMonth() + 1) : termsDate.getMonth() + 1}-${termsDate.getDate() < 10 ? "0" + String(termsDate.getDate()) : termsDate.getDate()} ${
                        termsDate.getHours() < 10 ? "0" + termsDate.getHours() : termsDate.getHours()
                      }:${termsDate.getMinutes() < 10 ? "0" + termsDate.getMinutes() : termsDate.getMinutes()}:${termsDate.getSeconds() < 10 ? "0" + termsDate.getSeconds() : termsDate.getSeconds()}`
                    : ""
                }
                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                readOnly={true}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>계약자명</p>
              </div>
              <input type={"text"} name={"userName"} value={data && data.getContract ? data.getContract.userName : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>계약자 연락처</p>
              </div>
              <input type={"text"} name={"userContact"} value={data && data.getContract ? data.getContract.userContact : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>중요 약관 동의</p>
              </div>
              <input type={"text"} name={"checkTerm"} value={data && data.getContract ? (data.getContract.checkTerm ? "예" : "아니요") : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>약관 동의</p>
              </div>
              <input type={"text"} name={"agreeTerm"} value={data && data.getContract ? (data.getContract.agreeTerm ? "예" : "아니요") : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제 승인번호</p>
              </div>
              <input type={"text"} name={"approvalNo"} value={data && data.getContract ? data.getContract.payment.approvalNo : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권</p>
              </div>
              <input type={"text"} name={"membership"} value={data && data.getContract ? data.getContract.membership.product.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>{data && data.getContract ? <img src={data.getContract.sign} className={`${styles.widthFull}`} /> : null}</div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>비고</p>
              <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 120 }} placeholder={"비고"} maxLength={5000} />
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} `} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContractDetail;
