const resizeImage = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxSize = 1000; // 원하는 최대 크기 설정
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxSize) {
            height = Math.round((height *= maxSize / width));
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width = Math.round((width *= maxSize / height));
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/webp",
          0.9
        );
      };
    };
    reader.readAsDataURL(file);
  });
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function datediff(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

function addCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function removeNonNumeric(num) {
  return num.toString().replace(/[^0-9]/g, "");
}

function allowNegativeNumeric(num) {
  return num.toString().replace(/[^0-9-]/g, "");
}

function changeToFormattedNumber(num) {
  return addCommas(removeNonNumeric(num));
}

function changeToNegativeNumber(num) {
  return addCommas(allowNegativeNumeric(num));
}

function setDateFormat(dateStr, isMonth) {
  let date = new Date(dateStr.substring(0, 4), dateStr.substring(4, 6) - 1, isMonth ? 1 : dateStr.substring(6, 8));

  if (isMonth) {
    return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}`;
  }
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`;
}

function setDateFormat_yyyyMM(date, replaceChar = "-") {
  return `${date.getFullYear()}${replaceChar}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}`;
}

function setDateFormat_yyyyMMdd(date, replaceChar = "-") {
  return `${date.getFullYear()}${replaceChar}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${replaceChar}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`;
}

export function setDateFormat_yyyyMMddHHmmss_dot(date, replaceChar = "-") {
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + date.getSeconds()).slice(-2);
  let timeString = hours + ":" + minutes + ":" + seconds;
  console.log("timeString::", timeString);
  return `${date.getFullYear()}${replaceChar}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${replaceChar}${date.getDate() < 10 ? "0" + String(date.getDate()) + " · " + timeString : date.getDate() + " · " + timeString}`;
}

function setDateFormatKor_yyyyMMdd(date) {
  return `${date.getFullYear()}년 ${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}월 ${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}일`;
}

function setDateFormatKor_day(date, suffix = false) {
  const days = ["일", "월", "화", "수", "목", "금", "토"];
  return days[date.getDay()] + (suffix ? "요일" : "");
}

function setDateFormatKor_aahhmm(date) {
  let hour = date.getHours();
  let min = date.getMinutes();

  let aa = "오전";
  if (hour >= 12) {
    aa = "오후";
    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${aa} ${hour}시${min > 0 ? " " + min + "분" : ""}`;
}

function getAmPm(time) {
  let times = time.split(":");
  if (times.length < 2) {
    return "";
  }

  let hour = Number(times[0]);

  return hour >= 12 && hour !== 24 ? "PM" : "AM";
}

function getHour(time) {
  let times = time.split(":");
  if (times.length < 2) {
    return "";
  }

  let hour = Number(times[0]);
  if (hour > 12) {
    hour -= 12;
  }

  return hour;
}

function getHourHH(time) {
  let times = time.split(":");
  if (times.length < 2) {
    return "";
  }

  return Number(times[0]);
}

function getMinute(time) {
  let times = time.split(":");
  if (times.length < 2) {
    return "";
  }

  let minute = Number(times[1]);

  return minute;
}

export {
  numberWithCommas,
  sleep,
  datediff,
  changeToFormattedNumber,
  changeToNegativeNumber,
  removeNonNumeric,
  allowNegativeNumeric,
  setDateFormat,
  setDateFormat_yyyyMM,
  setDateFormat_yyyyMMdd,
  setDateFormatKor_yyyyMMdd,
  setDateFormatKor_day,
  setDateFormatKor_aahhmm,
  getAmPm,
  getHour,
  getHourHH,
  getMinute,
  resizeImage,
};
