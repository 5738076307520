import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import xlsx from "xlsx";
import axios from "axios";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { bill } from "../../../common/modal";
import Member from "../../../components/Member/PC/Member";
import { numberReg } from "../../../common/regex";
import { FETCH_URL } from "../../../config";
import cache from "../../../apollo/cache";
import ScheduleMember from "../../../components/Schedule/PC/ScheduleMember";

const Memberships = gql`
  query getScheduleMemberships($machine: String!, $order: String!, $skip: Int!, $scheduleId: Int!, $status: String, $q: String) {
    getScheduleMemberships(machine: $machine, order: $order, skip: $skip, scheduleId: $scheduleId, status: $status, q: $q) {
      scheduleMemberships {
        id
        name
        mobile
        viewMembershipValids {
          id
          product {
            id
            name
            membershipType
          }
          start
          end
          validMembershipCount
          membershipCount
        }
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      username
    }
  }
`;

const RESERVATION = gql`
  mutation addScheduleReservation($machine: String!, $memberId: Int!, $scheduleId: Int!, $status: Int!, $over: Boolean!, $limit: Boolean!) {
    addScheduleReservation(machine: $machine, memberId: $memberId, scheduleId: $scheduleId, status: $status, over: $over, limit: $limit) {
      success
      error
      over
      limit
    }
  }
`;

const ScheduleMembershipList = () => {
  const history = useHistory();
  const location = useLocation();

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const [showOverModal, setShowOverModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMember, setModalMember] = useState(null);
  const [status, setStatus] = useState(location.state.status ?? 1);
  const [propOver, setPropOver] = useState(false);

  const [addReservationM] = useMutation(RESERVATION);

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const {
    data,
    loading: loadingMember,
    refetch,
  } = useQuery(Memberships, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
      scheduleId: location.state.scheduleId ?? -1,
      status: null,
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/schedule/membership/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleShowModal = (show, member) => {
    setModalMember(member);
    setShowOverModal(show ?? false);
  };

  const handleShowLimitModal = (show, over, member) => {
    setModalMember(member);
    setPropOver(over);
    setShowLimitModal(show ?? false);
  };

  const handleOrder = (order) => {
    if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else {
      applyOrder("name_asc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/schedule/membership/list/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    history.push({
      pathname: `/schedule/membership/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${q.value}`,
    });
  };

  useEffect(() => {
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
      } else {
        history.replace({
          pathname: `/schedule/membership/list/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  const reservation = async (member, over, limit = false) => {
    if (!loading) {
      if (member) {
        setLoading(true);

        setPropOver(over);

        const { data } = await addReservationM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            memberId: member.id,
            scheduleId: location.state.scheduleId ?? -1,
            status: status,
            over: over,
            limit: limit,
          },
        });
        setLoading(false);
        const result = data.addScheduleReservation;
        if (result.success) {
          alert(status === 2 ? "대기자 추가되었습니다" : "예약자 추가되었습니다.");
          refetch();
          setShowOverModal(false);
          setShowLimitModal(false);
        } else if (!result.success && result.over) {
          handleShowModal(true, member);
        } else if (!result.success && result.limit) {
          handleShowModal(false, member);
          handleShowLimitModal(true, over, member);
        } else {
          alert(result.error ?? "오류가 발생하였습니다.");
        }
      }
    }
  };

  const waitReservation = async () => {
    setStatus(2);
  };

  useEffect(() => {
    console.log("data### " + JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    console.log("machine### " + JSON.stringify(machine));
  }, [machine]);

  useEffect(async () => {
    if (status === 2) {
      await reservation(modalMember, false);
    }
  }, [status]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                {location.state.status === 2 ? "대기자 추가" : "예약자 추가"}
                {`${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}
              </p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <form action={""} onSubmit={submit}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`} style={{ width: 480 }}>
                    <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon25} ${styles.cursorPointer}`} onClick={submit} />
                    <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"회원명, 회원권명으로 검색"} />
                  </div>
                </form>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img
                    src={require("../../../assets/images/icon_back.png").default}
                    alt={"이전"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 45 * 0.2,
                      height: 79 * 0.2,
                    }}
                  />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원 연락처</p>
              </div>
              <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권명</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 기간</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 남은 횟수</p>
              </div>
              <div className={`${styles.flex1}`}></div>
            </div>
            <div style={{ minHeight: 600 }}>
              {loadingMember ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getScheduleMemberships && data.getScheduleMemberships.scheduleMemberships.length > 0 ? (
                data.getScheduleMemberships.scheduleMemberships.map((member) => {
                  return <ScheduleMember key={member.id} member={member} machine={machine && machine.getMachine ? machine.getMachine : {}} isWait={location.state.status === 2} addReservation={() => reservation(member, false)} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>예약 가능한 회원이 없습니다.</p>
                </div>
              )}
              {data?.getScheduleMemberships?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={require("../../../assets/images/icon_back.png").default}
                        alt={"이전"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    nextLabel={
                      <img
                        src={require("../../../assets/images/icon_front.png").default}
                        alt={"다음"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={data?.getScheduleMemberships?.total ? parseInt((data?.getScheduleMemberships?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingMember ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {loadingMember && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showOverModal} onRequestClose={() => handleShowModal(false, modalMember)} style={bill}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>예약</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>정원이 가득 찼습니다. 초과 예약을 할까요? 아니면 예약 대기를 할까요?</div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => reservation(modalMember, true)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>초과 예약</p>
            </div>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => waitReservation()}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>예약 대기</p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={showLimitModal} onRequestClose={() => handleShowModal(false, modalMember)} style={bill}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>예약</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>
            이 예약을 진행하실 경우, 설정하신 예약 제한 조건을 초과하게 됩니다.
            <br />
            예약 제한 조건을 초과하여 예약하시겠습니까?
          </div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => setShowLimitModal(false)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>취소</p>
            </div>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => reservation(modalMember, propOver, true)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>예약</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleMembershipList;
