import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Locker = ({
    locker
}) => {
    const history = useHistory();

    const end = locker.end ? new Date(Number(locker.end)) : null;
    let endStr = '';
    if (end) {
        endStr = `${end.getFullYear()}/${
            end.getMonth() + 1 < 10
                ? '0' + String(end.getMonth() + 1)
                : end.getMonth() + 1
        }/${
            end.getDate() < 10
                ? '0' + String(end.getDate())
                : end.getDate()
        }`
    }

    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`} onClick={() => history.push({
            pathname: `/locker/detail/`,
            state: {
                machine: locker.lockerArea.machine.username,
                lockerId: locker.id
            }
        })}>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    <span>{endStr}</span>
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.px10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {locker.member?.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {locker.number}
                </p>
            </div>
        </div>
    )
}

export default Locker;
