import React from 'react';

import {
    Appointments,
    AppointmentTooltip,
    DayView, GroupingPanel, Resources,
    Scheduler,
    WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import {GroupingState, IntegratedGrouping, ViewState} from "@devexpress/dx-react-scheduler";
import ScheduleMainItem from "./ScheduleMainItem";
import ScheduleMainDetailItem from "./ScheduleMainDetailItem";

import styles from '../../../styles/styles.module.scss';

const SchedulerMain = ({
                           resource,
                           scheduleData,
                           date,
                           viewDateType,
                           appointmentMeta,
                           onAppointmentMetaChange,
                           toggleTooltipVisibility,
                           tooltipVisibility,
                           toScheduleList,
                           toReservationList
}) => {

    return (
        <div className={`${styles.mt20}`} style={{height: 2500}}>

            <Scheduler
                data={scheduleData}>
                <ViewState
                    defaultCurrentDate={date}
                    currentDate={date}
                />

                <GroupingState
                    grouping={[
                        {
                            resourceName: 'category',
                        }
                    ]}
                />

                {viewDateType === 'week' ? (
                    <WeekView
                        startDayHour={0}
                        endDayHour={24}
                    />
                ) : (
                    <DayView
                        startDayHour={0}
                        endDayHour={24}
                    />
                )}
                <Appointments
                    appointmentComponent={({data, children, style, ...restProps}) => {
                        return (
                            <ScheduleMainItem
                                data={data}
                                children={children}
                                style={style}
                                restProps={restProps}
                                onAppointmentMetaChange={onAppointmentMetaChange}
                                toggleTooltipVisibility={toggleTooltipVisibility}
                            />
                        )
                    }}
                />

                <AppointmentTooltip
                    contentComponent={({appointmentData}) => {
                        return (
                            <ScheduleMainDetailItem
                                appointmentData={appointmentData}
                                toScheduleList={toScheduleList}
                                toReservationList={toReservationList}
                            />
                        )
                    }}
                    visible={tooltipVisibility}
                    onVisibilityChange={toggleTooltipVisibility}
                    appointmentMeta={appointmentMeta}
                    onAppointmentMetaChange={onAppointmentMetaChange}
                />

                <Resources
                    data={resource}
                    mainResourceName="category"
                />

                <IntegratedGrouping />

                <GroupingPanel />

            </Scheduler>

        </div>
    );
};

export default SchedulerMain;
