import React, { useState, useEffect, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';
import MachineSaleBar from '../../../components/Graph/MachineSaleBar';
import MachineSalePie from '../../../components/Graph/MachineSalePie';
import MachineDayWeekSaleBar from "../../../components/Graph/MachineDayWeekSaleBar";

const DATA = gql`
    query getMachineSaleStatics($machine: String!, $period: String!) {
        getMachineSaleStatics(
            type: "order"
            machine: $machine
            period: $period
        ) {
            success
            total
            count
            amount
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const OrderProductsPaymentStatics = () => {
    const history = useHistory();
    const location = useLocation();

    const timestamp = useRef(new Date().getTime());

    const [period, setPeriod] = useState('day');
    const [data, setData] = useState(null);

    const { refetch } = useQuery(DATA, {
        skip: true,
    });

    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const getData = async () => {
        setData(null);
        const now = new Date().getTime();
        timestamp.current = now;
        const { data } = await refetch({
            machine: location.state.machine ? location.state.machine : '',
            period,
        });
        if (timestamp.current === now) {
            if (
                data.getMachineSaleStatics &&
                data.getMachineSaleStatics.success
            ) {
                setData(data.getMachineSaleStatics);
            } else {
                setData(null);
            }
        }
    };

    useEffect(() => {
        getData();
    }, [period]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.pt50} ${styles.pb30} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`판매 통계${
                                    machine && machine.getMachine
                                        ? ` (${machine.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div
                                    className={`${
                                        period === 'day'
                                            ? styles.bgOrange
                                            : styles.bgBlack
                                    } ${styles.borderRadiusRound} ${
                                        styles.px20
                                    } ${styles.py7} ${styles.cursorPointer}`}
                                    onClick={() => setPeriod('day')}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        일간
                                    </p>
                                </div>
                                <div
                                    className={`${
                                        period === 'week'
                                            ? styles.bgOrange
                                            : styles.bgBlack
                                    } ${styles.borderRadiusRound} ${
                                        styles.px20
                                    } ${styles.py7} ${styles.cursorPointer} ${
                                        styles.ml10
                                    }`}
                                    onClick={() => setPeriod('week')}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        주간
                                    </p>
                                </div>
                                <div
                                    className={`${
                                        period === 'month'
                                            ? styles.bgOrange
                                            : styles.bgBlack
                                    } ${styles.borderRadiusRound} ${
                                        styles.px20
                                    } ${styles.py7} ${styles.cursorPointer} ${
                                        styles.ml10
                                    }`}
                                    onClick={() => setPeriod('month')}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        월간
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`}
                                    onClick={history.goBack}>
                                    <img
                                        src={
                                            require('../../../assets/images/icon_back.png')
                                                .default
                                        }
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.justifyContentBetween} ${styles.mt20}`}>
                        <div
                            className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow}`}>
                            <p
                                className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                                {`${
                                    period === 'day'
                                        ? '오늘'
                                        : period === 'week'
                                        ? '이번주'
                                        : '이번달'
                                } 총 판매 수익`}
                            </p>
                            {data ? (
                                <p
                                    className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>
                                    {numberWithCommas(data.total)}원
                                </p>
                            ) : (
                                <Skeleton
                                    height={30}
                                    width={180}
                                    style={{ marginTop: 5 }}
                                />
                            )}
                        </div>
                        <div
                            className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow} ${styles.mx20}`}>
                            <p
                                className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                                {`${
                                    period === 'day'
                                        ? '오늘'
                                        : period === 'week'
                                        ? '이번주'
                                        : '이번달'
                                } 최대 매출 상품`}
                            </p>
                            {data ? (
                                <p
                                    className={`${styles.fontB} ${
                                        styles.font24
                                    } ${styles.black} ${styles.textCenter} ${
                                        styles.mt5
                                    } ${data.amount ? '' : styles.hidden}`}>
                                    {data.amount ? data.amount : '없음'}
                                </p>
                            ) : (
                                <Skeleton
                                    height={30}
                                    width={180}
                                    style={{ marginTop: 5 }}
                                />
                            )}
                        </div>
                        <div
                            className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow}`}>
                            <p
                                className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                                {`${
                                    period === 'day'
                                        ? '오늘'
                                        : period === 'week'
                                        ? '이번주'
                                        : '이번달'
                                } 판매 최대 수량 상품`}
                            </p>
                            {data ? (
                                <p
                                    className={`${styles.fontB} ${
                                        styles.font24
                                    } ${styles.black} ${styles.textCenter} ${
                                        styles.mt5
                                    } ${data.count ? '' : styles.hidden}`}>
                                    {data.count ? data.count : '없음'}
                                </p>
                            ) : (
                                <Skeleton
                                    height={30}
                                    width={180}
                                    style={{ marginTop: 5 }}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.justifyContentBetween} ${styles.mt20}`}>
                        <div
                            style={{ width: 'calc((100% - 40px)*2/3 + 20px)' }}>
                            {
                                period === 'week' ? (
                                    <div className={`${styles.mb20}`}>
                                        <MachineDayWeekSaleBar
                                            machine={location.state.machine}
                                            type={'order'}
                                            height={150}
                                        />
                                    </div>
                                ) : null
                            }
                            <MachineSaleBar
                                period={period}
                                machine={location.state.machine}
                                type={'order'}
                                height={ period === 'week' ? 150 : 500 }
                            />
                        </div>
                        <div style={{ width: 'calc((100% - 40px)/3)' }}>
                            <MachineSalePie
                                period={period}
                                machine={location.state.machine}
                                type={'order'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderProductsPaymentStatics;
