import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { billSm } from "../../../common/modal";
import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import CustomPayment from "../../../components/Custom/PC/CustomPayment";
import { numberWithCommas } from "../../../common/utility";

import xlsx from "xlsx";
import Modal from "react-modal";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

var progressCount = 0;
const PAYMENT = gql`
  query getCustomPayments($machine: String!, $order: String!, $skip: Int!, $q: String) {
    getCustomPayments(type: "custom", machine: $machine, order: $order, skip: $skip, q: $q) {
      payments {
        id
        totalAmount
        installment
        status
        issuerInfo
        approvalDate
        approvalTime
        businessName
        businessAddress
        businessOwnerName
        businessPhoneNo
        businessNo
        cardNo
        surtax
        acquireInfo
        merchantNo
        approvalNo
        cancelDate
        cancelTime
        type
        userName
        userContact
        productName
        extra
        machine {
          id
          usePgBill
        }
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const CustomPaymentStatus = () => {
  const history = useHistory();
  const location = useLocation();
  const [showExcel, setShowExcel] = useState(false);

  const codeToNamePaymentStatus = (code) => {
    if (code === "paid") return "결제완료";
    if (code === "cancelled") return "결제취소";
    if (code === "refund") return "환불";
    return "";
  };

  const toDateTime = (date, time) => {
    return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 6)} - ${time.slice(0, 2)}:${time.slice(2, 4)}:${time.slice(4, 6)}`;
  };

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const {
    data,
    loading: loadingPayment,
    refetch,
  } = useQuery(PAYMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/custom/payment/status/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleOrder = (order) => {
    if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_dsc");
      }
    } else if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "contact") {
      if (queryString.parse(location.search).order === "contact_asc") {
        applyOrder("contact_dsc");
      } else if (queryString.parse(location.search).order === "contact_dsc") {
        applyOrder("contact_asc");
      } else {
        applyOrder("contact_asc");
      }
    } else if (order === "product") {
      if (queryString.parse(location.search).order === "product_asc") {
        applyOrder("product_dsc");
      } else if (queryString.parse(location.search).order === "product_dsc") {
        applyOrder("product_asc");
      } else {
        applyOrder("product_asc");
      }
    } else if (order === "amount") {
      if (queryString.parse(location.search).order === "amount_asc") {
        applyOrder("amount_dsc");
      } else if (queryString.parse(location.search).order === "amount_dsc") {
        applyOrder("amount_asc");
      } else {
        applyOrder("amount_asc");
      }
    } else if (order === "method") {
      if (queryString.parse(location.search).order === "method_asc") {
        applyOrder("method_dsc");
      } else if (queryString.parse(location.search).order === "method_dsc") {
        applyOrder("method_asc");
      } else {
        applyOrder("method_asc");
      }
    } else if (order === "status") {
      if (queryString.parse(location.search).order === "status_asc") {
        applyOrder("status_dsc");
      } else if (queryString.parse(location.search).order === "status_dsc") {
        applyOrder("status_asc");
      } else {
        applyOrder("status_asc");
      }
    } else {
      applyOrder("date_dsc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/custom/payment/status/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    history.push({
      pathname: `/custom/payment/status/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${q.value}`,
    });
  };

  const exportExcelFull = async (type) => {
    //if (!loading && machine && machine.getMachine.name) {
    if (true) {
      //setLoading(true);
      try {
        const { data: result } = await refetch({
          skip: 0,
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
        console.log("result", result);

        if (result && result.getCustomPayments.payments.length > 0) {
          console.log("result.total", result.getCustomPayments.total);
          var total = result.getCustomPayments.total;
          let data = [];
          for (var i = 0; i < total; ) {
            const { data: result } = await refetch({
              skip: i,
              machine: location.state.machine,
              order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
              q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
            });

            if (result && result.getCustomPayments.payments.length > 0) {
              for (const payment of result.getCustomPayments.payments) {
                data.push({
                  "결제 일시": toDateTime(payment.approvalDate, payment.approvalTime),
                  구매자명: payment.userName,
                  "구매자 연락처": payment.userContact,
                  상품명: payment.productName,
                  "결제 금액": Number(payment.totalAmount),
                  "결제 수단": `${payment.issuerInfo}(${payment.installment === 0 ? "일시불" : `${payment.installment}개월`})`,
                  "결제 상태": codeToNamePaymentStatus(payment.status),
                  비고: payment.extra,
                  "구매 형태": payment.membershipType ? payment.membershipType.name : "",
                });
              }
            }
            i += 10;
            progressCount = (i * 100) / total;
          }

          const ws = xlsx.utils.json_to_sheet(data);
          const wb = xlsx.utils.book_new();
          var wscols = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
          ws["!cols"] = wscols;
          xlsx.utils.book_append_sheet(wb, ws, "결제현황");
          xlsx.writeFile(wb, `Ven-Brothers_자유결제현황_${machine.getMachine.name}.xlsx`);
        }
      } catch (e) {
        console.log("eeee", e);
        alert("오류가 발생하였습니다.");
      } finally {
        //setLoading(false);
      }

      const { data: result } = await refetch({
        skip: 0,
        machine: location.state.machine,
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      });

      setShowExcel(false);
    }
  };

  useEffect(() => {
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
      } else {
        history.replace({
          pathname: `/custom/payment/status/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`결제 현황${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <form action={""} onSubmit={submit}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`} style={{ width: 480 }}>
                    <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon25} ${styles.cursorPointer}`} onClick={submit} />
                    <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"결제 금액, 구매자명, 구매자 연락처, 상품명 및 결제 수단 검색"} />
                  </div>
                </form>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml40}`}
                  onClick={() => {
                    progressCount = 0;
                    setShowExcel(true);
                  }}
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>결제 현황 내보내기</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제일시</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>구매자명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("contact")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>구매자 연락처</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "contact_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "contact_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("product")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상품명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "product_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "product_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("amount")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제금액</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "amount_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "amount_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("method")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제수단</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "method_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "method_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("status")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제상태</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "status_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "status_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>비고</p>
              </div>
              <div className={`${styles.flex1}`}></div>
            </div>
            <div style={{ minHeight: 600 }}>
              {loadingPayment ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getCustomPayments && data.getCustomPayments.payments.length > 0 ? (
                data.getCustomPayments.payments.map((payment) => {
                  return <CustomPayment key={payment.id} payment={payment} machine={location.state.machine ? location.state.machine : ""} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>결제 내역이 없습니다.</p>
                </div>
              )}
              {data?.getCustomPayments?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={data?.getCustomPayments?.total ? parseInt((data?.getCustomPayments?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingPayment ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {loadingPayment && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showExcel} onRequestClose={() => setShowExcel(false)} onAfterOpen={() => exportExcelFull()} style={billSm}>
        <div className={`${styles.center} ${styles.py20} ${styles.px20} ${styles.widthFull}`}>
          <p>
            <CircularProgressbar value={progressCount} text={`${Math.min(100, parseInt(progressCount))}%`} />{" "}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CustomPaymentStatus;
