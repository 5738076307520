import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import NumberFormat from "react-number-format";

const PROFILE = gql`
    {
        getProfileAdmin {
            id
            username
            name
            superuser
            mobile
        }
    }
`;

const ADD_POINT_PAY = gql`
    mutation addPointPay($charge: Int!) {
        addPointPay(charge:$charge) {
            success
            error
            id
            url
        }
    }
`;

const POINT = gql`
    {
        getUserPointAdmin {
            id
            point
            balance
        }
    }
`;

const PointUserAdd = () => {
    const history = useHistory();

    const charge = useInput('');
    const [addPointPay] = useMutation(ADD_POINT_PAY);

    const { data: point_data } = useQuery(POINT, {
        fetchPolicy: 'cache-and-network',
    });

    const [loading, setLoading] = useState(false);

    const { data, loading:loadingProfile } = useQuery(PROFILE, {
        fetchPolicy: 'cache-and-network',
    });


    const openInicis = (id, url) => {
        window.open(url + "/point/getInicisForm?id=" + id + "&type=pc", "ini", 'top=10, left=10, width=900, height=700, status=no, menubar=no, toolbar=no, resizable=no');
    }

    const newPayment = async () => {

        if(loading) {
            return;
        }

        if(isNaN(parseInt(charge.value)) || parseInt(charge.value) % 10000 > 0) {
            alert("만원 단위로 충전가능합니다");
            return;
        }

        setLoading(true);
        const { data: result } = await addPointPay({
            variables: {
                charge: parseInt(charge.value)
            }
        });
        if (result && result.addPointPay) {
            if (result.addPointPay.error) {
                setLoading(false);
                alert(result.addPointPay.error);
            } else {
                openInicis(result.addPointPay.id, result.addPointPay.url);
            }
        } else {
            setLoading(false);
            alert('오류가 발생하였습니다.');
        }
    };

    const handleCharge = values => {
        const {value} = values;
        charge.setValue(value);
    };

    useEffect(() => {
        if (!data) {
            alert('잘못된 접근입니다');
            history.goBack();
        } else {
        }
    }, []);

    window.onmessage = function(e) {
        window.location.reload();
    }

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                알림톡 포인트 충전
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.mt40} ${styles.center}`}>
                        <div style={{ width: 1250 }}>
                                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            남은 포인트
                                        </p>
                                    </div>
                                    <NumberFormat
                                        value={point_data && point_data.getUserPointAdmin ? point_data.getUserPointAdmin.balance : 0}
                                        thousandSeparator={true}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        type={'tel'}
                                        displayType={'input'}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                충전할 액수*
                                            </p>
                                        </div>

                                        <NumberFormat
                                            value={charge.value}
                                            thousandSeparator={true}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            type={'tel'}
                                            displayType={'input'}
                                            onValueChange={handleCharge}
                                            placeholder={
                                                '충전할 액수를 입력해주세요.'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb40}`} style={{ width: 1250, marginTop:'30%' }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py17} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    type={"button"}
                                    onClick={() => newPayment()}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        충전하기
                                    </p>
                                </button>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            {/*<div dangerouslySetInnerHTML={{ __html: inicis }}/>*/}
            {/*<ReactInicis payData={payData} isPurchase={isPurchase} isTest />*/}
        </div>
    );
};

export default PointUserAdd;
