import React, { useState, useEffect, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import Calendar from 'react-calendar';
import { ResponsivePie } from '@nivo/pie';

import styles from '../../styles/styles.module.scss';
import { colors, colors2, colors3 } from '../../styles/colors';
import {setDateFormat} from "../../common/utility";

const DATA = gql`
    query getMachineMembershipSaleGraphPie($type: String!, $machine: String!, $period: String!, $date: String!){
        getMachineMembershipSaleGraphPie(type: $type, machine: $machine, period: $period date: $date){
            success
            data{
                id
                title
                data{
                    id
                    label
                    value
                }
            }
            dates
        }
    }
`

const MachineMembershipSalePie = ({
    period,
    machine,
    type
}) => {
    const timestamp = useRef(new Date().getTime())

    const [date, setDate] = useState(new Date())
    const [showDate, setShowDate] = useState(false)
    const [data, setData] = useState(null)
    const [dates, setDates] = useState(null)

    const { refetch } = useQuery(DATA, {
        skip: true
    })

    const getData = async() => {
        setData(null);
        setDates(null);
        const now = new Date().getTime()
        timestamp.current = now
        const { data } = await refetch({
            type,
            machine,
            period,
            date: `${String(date.getFullYear()).slice(2,4)}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`
        });
        if(timestamp.current === now){
            if(data.getMachineMembershipSaleGraphPie && data.getMachineMembershipSaleGraphPie.success){
                setData(data.getMachineMembershipSaleGraphPie.data);
                setDates(data.getMachineMembershipSaleGraphPie.dates);
            }
            else{
                setData(null);
                setDates(null);
            }
        }
    }

    useEffect(() => {
        setShowDate(false)
        getData()
    }, [date, period])

    return (
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.flex1}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                    상품별 판매 매출
                </p>
                <div className={`${styles.ml50}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowDate(!showDate)}>
                        <p className={`${styles.fontB} ${styles.font18} ${styles.black}`}>
                            {dates ? `${setDateFormat(dates[0], period === "month")}${dates[1] && period === "week" ? ('~'+setDateFormat(dates[1])) : ''}` : ''}
                        </p>
                        <img src={showDate ? require("../../assets/images/icon_dropdown_up.png").default : require("../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                    </div>
                </div>
            </div>
            {showDate && (
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentEnd}`}>
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.px20} ${styles.py20} ${styles.overflowHidden}`} style={{ width: 400 }}>
                        <Calendar
                        calendarType={"US"}
                        value={date}
                        maxDate={new Date()}
                        minDate={new Date(2021,0,1)}
                        minDetail={(period === "day") ? "month" : (period === "week") ? "month" : "year"}
                        maxDetail={(period === "day") ? "month" : (period === "week") ? "month" : "year"}
                        nextLabel={<span><img src={require('../../assets/images/icon_dropdown_right.png').default} alt={"다음"} className={`${styles.icon15}`} /></span>}
                        next2Label={null}
                        prevLabel={<span><img src={require('../../assets/images/icon_dropdown_left.png').default} alt={"이전"} className={`${styles.icon15}`} /></span>}
                        prev2Label={null}
                        navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                        onChange={setDate}
                        />
                    </div>
                </div>
            )}
            <div className={`${styles.mt50}`}>
                {data ? (
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                        {data.map(d => {
                            return(
                                <div key={d.id} className={`${styles.flex1}`} style={{ height: 350 }}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                                        {d.title}
                                    </p>
                                    <ResponsivePie
                                    data={d.data}
                                    margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
                                    valueFormat={" >-,.2d"}
                                    innerRadius={0.5}
                                    activeOuterRadiusOffset={8}
                                    colors={(d.data.length === 2) ? colors2 : (d.data.length === 3) ? colors3 : colors}
                                    borderWidth={1}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <Skeleton height={350} />
                )}
            </div>
        </div>
    )
}

export default MachineMembershipSalePie;
