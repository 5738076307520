import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { emailReg, numberReg } from '../../../common/regex';
import AlarmInputTel from './AlarmInputTel';

const AlarmModalPay = ({ closeModal, extraTalks, handleSaveExtraTalks }) => {
    const location = useLocation();
    const talkContact = useInput('');

    const [extraUserList, setExtraUserList] = useState(extraTalks);
    console.log(extraUserList);
    if (extraUserList.length < 1) {
        setExtraUserList([...extraUserList, {}]);
    }

    const refDropDown = useRef();

    const handleAddInputTel = () => {
        setExtraUserList([...extraUserList, {}]);
    };

    const handleDelInputTel = index => {
        extraUserList.splice(index, 1);
        setExtraUserList([...extraUserList]);
    };

    const handleTalkContact = (e, index) => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value.replaceAll('-', ''))) {
            extraUserList[index].tel = value;
            setExtraUserList([...extraUserList]);
        }
    };

    const handleSetExtraTalks = (newTalks, index) => {
        if (newTalks.findIndex(t => t === 'payment') > -1)
            extraUserList[index].talkPayment = true;
        else extraUserList[index].talkPayment = false;
        if (newTalks.findIndex(t => t === 'stock') > -1)
            extraUserList[index].talkStock = true;
        else extraUserList[index].talkStock = false;
        if (newTalks.findIndex(t => t === 'consult') > -1)
            extraUserList[index].talkConsult = true;
        else extraUserList[index].talkConsult = false;
        if (newTalks.findIndex(t => t === 'custom_payment') > -1)
            extraUserList[index].talkCustomPayment = true;
        else extraUserList[index].talkCustomPayment = false;
        if (newTalks.findIndex(t => t === 'useReportTalk') > -1)
            extraUserList[index].useReportTalk = true;
        else extraUserList[index].useReportTalk = false;
    };

    return (
        <div className={`${styles.bgWhite}`}>
            <div className={`${styles.px70} ${styles.py30}`}>
                <div
                    className={`${styles.row} ${styles.justifyContentBetween} ${styles.flex}`}>
                    <div
                        className={`${styles.mb100} ${styles.fontB} ${styles.font20} ${styles.black}`}>
                        <p>기기 알림 수신 설정(유료)</p>
                    </div>
                    <div>
                        <img
                            src={
                                require('../../../assets/images/icon_close.png')
                                    .default
                            }
                            alt={'이전'}
                            className={`${styles.cursorPointer}`}
                            style={{
                                width: 45 * 0.6,
                                height: 45 * 0.6,
                            }}
                            onClick={closeModal}
                        />
                    </div>
                </div>
                <div className={`${styles.center}`}>
                    <div className={`${styles.right}`} style={{ width: 680 }}>
                        <p className={`${styles.grayA2}`}>
                            *추가된 연락처에 대한 알림은 10원/건 비용이
                            발생합니다
                        </p>
                    </div>
                    <div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.mt10} ${styles.borderRadius10} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                            style={{ width: 680 }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderBottomGrayA2} ${styles.overflowHidden}`}
                                style={{ width: 680 }}>
                                <div
                                    className={`${styles.px20} ${styles.py15}`}>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        추가된 연락처
                                    </p>
                                </div>
                                <div
                                    className={`${styles.px20} ${styles.py15} ${styles.fontB} ${styles.cursorPointer}`}
                                    onClick={handleAddInputTel}>
                                    +새로운 연락처 추가
                                </div>
                            </div>
                            <div
                                className={`${styles.overflowScroll} ${styles.overflowXHidden} ${styles.thinScrollBar}`}
                                style={{ maxHeight: 170 }}
                                ref={refDropDown}>
                                {extraUserList.map((item, index) => (
                                    <AlarmInputTel
                                        key={index}
                                        index={index}
                                        item={item}
                                        handleDelInputTel={handleDelInputTel}
                                        handleTalkContact={handleTalkContact}
                                        handleSetExtraTalks={
                                            handleSetExtraTalks
                                        }
                                        curScroll={refDropDown}></AlarmInputTel>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${styles.footer} ${styles.center} ${styles.row}`}>
                        <div
                            className={`${styles.mb80} ${styles.justifyContentBetween}`}>
                            <button
                                onClick={closeModal}
                                className={`${styles.px100} ${styles.py20} ${styles.mx10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                                취소
                            </button>
                            <button
                                onClick={e =>
                                    handleSaveExtraTalks(extraUserList, true)
                                }
                                className={`${styles.px100} ${styles.py20} ${styles.mx10} ${styles.fontB} ${styles.bgBlack} ${styles.white} ${styles.borderRadiusRound}`}>
                                저장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlarmModalPay;
