import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const ConsultPt = ({
    pt
}) => {
    const history = useHistory()

    const date = new Date(Number(pt.createdAt))
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`} onClick={() => history.push({
            pathname: `/consult/product/edit/`,
            state: {
                consultId: pt.id
            }
        })}>
            <div className={`${styles.flex2}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? '0' + String(date.getDate()) : date.getDate()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {pt.machine.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {pt.name}
                </p>
            </div>
            <div className={`${styles.flex2}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {pt.contact}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${(pt.status === "done") ? "" : styles.underline}`}>
                    {(pt.status === "ready") && "미처리"}
                    {(pt.status === "progress") && "처리중"}
                    {(pt.status === "done") && "처리완료"}
                </p>
            </div>
        </div>
    )
}

export default ConsultPt;