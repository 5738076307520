import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg } from '../../../common/regex';

const PROFILE = gql`
    {
        getProfileAdmin {
            id
            username
            name
            mobile
            status
            superuser
        }
    }
`;

const CHECK_MOBILE = gql`
    query checkMobile($mobile: String!) {
        checkMobile(mobile: $mobile)
    }
`;

const CHECK_USERNAME = gql`
    query checkUsername($username: String!) {
        checkUsername(username: $username)
    }
`;

const CHANGE = gql`
    mutation changeProfile($username: String!, $mobile: String!) {
        changeProfile(username: $username, mobile: $mobile) {
            success
            result {
                id
                username
                mobile
            }
        }
    }
`;

const CHANGE_PASSWORd = gql`
    mutation changePassword($password: String!) {
        changePassword(password: $password)
    }
`;

const CHANGE_STATUS = gql`
    mutation changeStatus($id: Int!, $status: String!) {
        changeStatus(id: $id, status: $status) {
            success
            result {
                id
                status
            }
        }
    }
`;

const ProfileEdit = () => {
    const history = useHistory();

    const username = useInput('');
    const mobile = useInput('');
    const password = useInput('');

    const [loading, setLoading] = useState(false);

    const { data } = useQuery(PROFILE, {
        fetchPolicy: 'cache-and-network',
    });
    const { refetch: refetchMobile } = useQuery(CHECK_MOBILE, {
        skip: true,
        fetchPolicy: 'no-cache',
    });
    const { refetch: refetchUsername } = useQuery(CHECK_USERNAME, {
        skip: true,
        fetchPolicy: 'no-cache',
    });

    const [changeProfileM] = useMutation(CHANGE);
    const [changePasswordM] = useMutation(CHANGE_PASSWORd);
    const [changeStatusM] = useMutation(CHANGE_STATUS);

    const handleMobile = e => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value)) {
            mobile.onChange(e);
        }
    };

    const changePassword = async () => {
        if (!loading) {
            if (password.value) {
                setLoading(true);
                const { data } = await changePasswordM({
                    variables: {
                        password: password.value,
                    },
                });
                setLoading(false);
                if (data && data.changePassword) {
                    alert('변경되었습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } else {
                alert('변경할 비밀번호를 입력해주세요.');
            }
        }
    };

    const resquestWithdrawal = async () => {
        if (!loading && data && data.getProfileAdmin) {
            if (data.getProfileAdmin.status === 'request_withdrawal') {
                const confirm = window.confirm('탈퇴신청을 철회하시겠습니까?');
                if (confirm) {
                    setLoading(true);
                    const { data: result } = await changeStatusM({
                        variables: {
                            id: data.getProfileAdmin.id,
                            status: 'inuse',
                        },
                    });
                    setLoading(false);
                    if (result && result.changeStatus.success) {
                        alert('탈퇴신청이 철회되었습니다.');
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                }
            } else {
                const confirm = window.confirm(
                    '탈퇴신청 하시겠습니까?\n탈퇴신청 후 관리자가 승낙해여야만 탈퇴처리됩니다.',
                );
                if (confirm) {
                    setLoading(true);
                    const { data: result } = await changeStatusM({
                        variables: {
                            id: data.getProfileAdmin.id,
                            status: 'request_withdrawal',
                        },
                    });
                    setLoading(false);
                    if (result && result.changeStatus.success) {
                        alert('탈퇴신청 되었습니다.');
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                }
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading && data && data.getProfileAdmin) {
            if (username.value && mobile.value) {
                if (
                    username.value === data.getProfileAdmin.username &&
                    mobile.value === data.getProfileAdmin.mobile
                ) {
                    alert('변경사항이 없습니다.');
                } else {
                    setLoading(true);
                    let mobileChecked = false;
                    if (mobile.value === data.getProfileAdmin.mobile) {
                        mobileChecked = true;
                    } else {
                        const { data: check } = await refetchMobile({
                            mobile: mobile.value,
                        });
                        if (check && check.checkMobile) {
                            mobileChecked = true;
                        } else {
                            mobileChecked = false;
                        }
                    }
                    let usernameChecked = false;
                    if (username.value === data.getProfileAdmin.username) {
                        usernameChecked = true;
                    } else {
                        const { data: check } = await refetchUsername({
                            username: username.value,
                        });
                        if (check && check.checkUsername) {
                            usernameChecked = true;
                        } else {
                            usernameChecked = false;
                        }
                    }
                    if (mobileChecked) {
                        if (usernameChecked) {
                            const { data: result } = await changeProfileM({
                                variables: {
                                    username: username.value,
                                    mobile: mobile.value,
                                },
                            });
                            setLoading(false);
                            if (result && result.changeProfile.success) {
                                alert('저장되었습니다.');
                            } else {
                                alert('오류가 발생하였습니다.');
                            }
                        } else {
                            setLoading(false);
                            alert('아이디가 중복됩니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('휴대폰 번호가 중복됩니다.');
                    }
                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    useEffect(() => {
        if (data && data.getProfileAdmin) {
            username.setValue(data.getProfileAdmin.username);
            mobile.setValue(data.getProfileAdmin.mobile);
        }
    }, [data]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                계정관리
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            이름
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        value={
                                            data && data.getProfileAdmin
                                                ? `${data.getProfileAdmin.name} (변경불가)`
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        readOnly={true}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            휴대폰 번호
                                        </p>
                                    </div>
                                    <input
                                        type={'tel'}
                                        name={'mobile'}
                                        value={mobile.value}
                                        onChange={handleMobile}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={"'-' 제외"}
                                        maxLength={11}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            이메일(아이디)
                                        </p>
                                    </div>
                                    <input
                                        type={'email'}
                                        name={'username'}
                                        value={username.value}
                                        onChange={username.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            비밀번호
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                        <input
                                            type={'password'}
                                            name={'password'}
                                            value={password.value}
                                            onChange={password.onChange}
                                            className={`${styles.inputWhite} ${styles.flex1}`}
                                            placeholder={'********'}
                                        />
                                        <p
                                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`}
                                            onClick={changePassword}>
                                            변경
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb70}`}
                                style={{ width: 1250, marginTop: 500 }}>
                                {data &&
                                data.getProfileAdmin &&
                                !data.getProfileAdmin.superuser ? (
                                    <div
                                        className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer}`}
                                        style={{
                                            opacity: loading ? 0.4 : 1,
                                            width: 270,
                                        }}
                                        onClick={resquestWithdrawal}>
                                        <p
                                            className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                            {data &&
                                            data.getProfileAdmin &&
                                            data.getProfileAdmin.status ===
                                                'request_withdrawal'
                                                ? '탈퇴철회'
                                                : '계정탈퇴'}
                                        </p>
                                    </div>
                                ) : null}
                                <div
                                    className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{
                                        opacity: loading ? 0.4 : 1,
                                        width: 270,
                                    }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{
                                        opacity: loading ? 0.4 : 1,
                                        width: 270,
                                    }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileEdit;
