import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg } from '../../../common/regex';
import IntroImage from '../../../components/Home/IntroImage';
import { FETCH_URL } from '../../../config';

const CATEGORY = gql`
    {
        getVideoCategory {
            id
            name
            hasDetailCategory
        }
    }
`;

const DETAIL_CATEGORY = gql`
    {
        getVideoDetailCategoryAll {
            id
            categoryId
            name
        }
    }
`;

const DETAIL = gql`
    query getVideoDetail($id: Int!) {
        getVideoDetail(id: $id) {
            id
            name
            video
            category {
                id
                name
                hasDetailCategory
            }
            detailCategory {
                id
                name
                categoryId
            }
            duration
            status
            qrCode
            image
        }
    }
`;

const EDIT = gql`
    mutation editVideo(
        $id: Int!
        $videoId: String!
        $name: String!
        $categoryId: Int!
        $detailCategoryId: Int
        $duration: Int!
        $status: String!
        $qr: String!
        $image: String!
    ) {
        editVideo(
            id: $id
            videoId: $videoId
            name: $name
            categoryId: $categoryId
            detailCategoryId: $detailCategoryId
            duration: $duration
            status: $status
            qr: $qr
            image: $image
        ) {
            success
            error
            result {
                id
                name
                category {
                    id
                    name
                }
                detailCategory {
                    id
                    name
                }
                status
                machinesCount
            }
        }
    }
`;

const DELETE = gql`
    mutation deleteVideo($id: Int!) {
        deleteVideo(id: $id)
    }
`;

const VideoEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const videoId = useInput('');
    const duration = useInput('');
    const name = useInput('');

    const imageRef = useRef();

    const [category, setCategory] = useState({
        id: -1,
        name: '',
        hasDetailCategory: false,
    });
    const [showCategory, setShowCategory] = useState(false);
    const [detailCategory, setDetailCategory] = useState({
        id: -1,
        categoryId: -1,
        name: '',
    });
    const [showDetailCategory, setShowDetailCategory] = useState(false);
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const [qR, setQR] = useState('');
    const [uploadQR, setUploadQR] = useState(null);
    const [image, setImage] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);

    const { data } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: location.state.video ? location.state.video.id : -1,
        },
    });
    const { data: categorys } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: detailCategorys } = useQuery(DETAIL_CATEGORY, {
        fetchPolicy: 'cache-and-network',
    });

    const [editVideoM] = useMutation(EDIT);
    const [deleteVideoM] = useMutation(DELETE);

    const handleDuration = values => {
        const { value } = values;
        if (numberReg.test(value)) {
            duration.setValue(value);
        }
    };

    const handleCategory = category => {
        setCategory(category);
        setShowCategory(false);
        setDetailCategory({
            id: -1,
            categoryId: -1,
            name: '',
        });
    };

    const handleDetailCategory = detailCategory => {
        setDetailCategory(detailCategory);
        setShowDetailCategory(false);
    };

    const handleStatus = status => {
        setStatus(status);
        setShowStatus(false);
    };

    const addQR = async e => {
        if (!loadingImage) {
            const files = e.target.files;

            let error = false;
            if (
                files[0].type === 'image/jpg' ||
                files[0].type === 'image/jpeg' ||
                files[0].type === 'image/png'
            ) {
            } else {
                error = true;
            }
            if (error) {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            } else {
                setLoadingImage(true);
                let file = files[0];
                setQR(file.name);
                setUploadQR(file);
                setLoadingImage(false);
            }
        }
    };

    const addImage = async e => {
        if (!loadingImage) {
            const file = e.target.files[0];
            if (
                file &&
                (file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png')
            ) {
                var _URL = window.URL || window.webkitURL;
                setLoadingImage(true);
                setUploadImage(file);
                var img = new Image();
                var reader = new FileReader();
                reader.onloadend = () => {
                    setImage(reader.result);
                };
                img.src = await _URL.createObjectURL(file);
                await reader.readAsDataURL(file);
                setLoadingImage(false);
            } else {
                alert('jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.');
            }
        }
    };

    const removeImage = () => {
        setImage(null);
        setUploadImage(null);
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading && location.state.video) {
            if (
                !(
                    videoId.value &&
                    name.value &&
                    category.id > -1 &&
                    duration.value &&
                    status &&
                    qR &&
                    image
                )
            ) {
                alert('위 정보를 입력해주세요.');
                return;
            }
            if (category.hasDetailCategory) {
                if (!(detailCategory.id > -1)) {
                    alert('세부 카테고리를 선택해주세요.');
                    return;
                }
            }
            setLoading(true);
            try {
                let saveQR = qR;
                if (uploadQR) {
                    const qrData = new FormData();
                    qrData.append('productimage', uploadQR, uploadQR.name);
                    const { data: dataQR } = await axios.post(
                        `${FETCH_URL}/api/upload`,
                        qrData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );
                    if (dataQR && dataQR.location) {
                        saveQR = dataQR.location;
                    }
                }
                let saveImage = image;
                if (uploadImage) {
                    const imageData = new FormData();
                    imageData.append(
                        'productimage',
                        uploadImage,
                        uploadImage.name,
                    );
                    const { data: dataImage } = await axios.post(
                        `${FETCH_URL}/api/upload`,
                        imageData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );
                    if (dataImage && dataImage.location) {
                        saveImage = dataImage.location;
                    }
                }
                if (!(saveQR && saveImage)) {
                    alert('오류가 발생하였습니다.');
                    return;
                }
                const { data } = await editVideoM({
                    variables: {
                        id: location.state.video.id,
                        videoId: videoId.value,
                        name: name.value,
                        categoryId: category.id,
                        detailCategoryId:
                            detailCategory.id > -1 ? detailCategory.id : null,
                        duration: Number(duration.value),
                        status,
                        qr: saveQR,
                        image: saveImage,
                    },
                });
                setLoading(false);
                if (data && data.editVideo.success) {
                    alert('수정하였습니다.');
                } else if (data && data.editVideo.error) {
                    alert(data.editVideo.error);
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        }
    };

    const removeVideo = async () => {
        if (!loading && location.state.video) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await deleteVideoM({
                        variables: {
                            id: location.state.video.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.deleteVideo) {
                        history.goBack();
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const initialize = () => {
        if (data && data.getVideoDetail) {
            videoId.setValue(data.getVideoDetail.video);
            duration.setValue(`${data.getVideoDetail.duration}`);
            name.setValue(data.getVideoDetail.name);
            setCategory(
                data.getVideoDetail.category
                    ? data.getVideoDetail.category
                    : {
                          id: -1,
                          name: '',
                          hasDetailCategory: false,
                      },
            );
            setShowCategory(false);
            setDetailCategory(
                data.getVideoDetail.detailCategory
                    ? data.getVideoDetail.detailCategory
                    : {
                          id: -1,
                          categoryId: -1,
                          name: '',
                      },
            );
            setShowDetailCategory(false);
            setStatus(data.getVideoDetail.status);
            setShowStatus(false);
            setQR(data.getVideoDetail.qrCode);
            setUploadQR(null);
            setImage(data.getVideoDetail.image);
            setUploadImage(null);
            setLoading(false);
            setLoadingImage(false);
            if (imageRef.current) {
                imageRef.current.value = '';
            }
        }
    };

    useEffect(() => {
        initialize();
    }, [data]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        영상 수정
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p
                                className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    영상 ID*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'videoId'}
                                value={videoId.value}
                                onChange={videoId.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                placeholder={'영상 ID를 입력해주세요.'}
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    영상명*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'name'}
                                value={name.value}
                                onChange={name.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                placeholder={'영상명을 입력해주세요.'}
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    영상 시간*
                                </p>
                            </div>
                            <NumberFormat
                                value={duration.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleDuration}
                                placeholder={'영상 시간을 입력해주세요. (초)'}
                            />
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showCategory
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showCategory
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowCategory(!showCategory)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        카테고리*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            category && category.id > -1
                                                ? category.name
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={'카테고리를 선택해주세요.'}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showCategory
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                      .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                      .default
                                        }
                                        alt={'카테고리 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showCategory && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll}`}
                                    style={{
                                        left: 0,
                                        right: 0,
                                        maxHeight: 200,
                                        overflowY: 'scroll',
                                    }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            {categorys &&
                                            categorys.getVideoCategory
                                                ? categorys.getVideoCategory.map(
                                                      c => {
                                                          return (
                                                              <div
                                                                  key={c.id}
                                                                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                  <p
                                                                      className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                      onClick={() =>
                                                                          handleCategory(
                                                                              c,
                                                                          )
                                                                      }>
                                                                      {c.name}
                                                                  </p>
                                                              </div>
                                                          );
                                                      },
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showDetailCategory
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showDetailCategory
                                        ? 0
                                        : 10,
                                }}
                                onClick={() =>
                                    setShowDetailCategory(!showDetailCategory)
                                }>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        세부 카테고리
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            detailCategory &&
                                            detailCategory.id > -1
                                                ? detailCategory.name
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={
                                            '세부 카테고리를 선택해주세요.'
                                        }
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showDetailCategory
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                      .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                      .default
                                        }
                                        alt={'세부 카테고리 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showDetailCategory && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll}`}
                                    style={{
                                        left: 0,
                                        right: 0,
                                        maxHeight: 200,
                                        overflowY: 'scroll',
                                    }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            {detailCategorys &&
                                            detailCategorys.getVideoDetailCategoryAll
                                                ? detailCategorys.getVideoDetailCategoryAll.map(
                                                      c => {
                                                          if (
                                                              category.id ===
                                                              c.categoryId
                                                          ) {
                                                              return (
                                                                  <div
                                                                      key={c.id}
                                                                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                      <p
                                                                          className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                          onClick={() =>
                                                                              handleDetailCategory(
                                                                                  c,
                                                                              )
                                                                          }>
                                                                          {
                                                                              c.name
                                                                          }
                                                                      </p>
                                                                  </div>
                                                              );
                                                          }
                                                      },
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showStatus ? 0 : 10,
                                    borderBottomRightRadius: showStatus
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowStatus(!showStatus)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        상태*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            status === 'inuse'
                                                ? '전체송출'
                                                : status === 'selective'
                                                ? '선택송출'
                                                : status === 'stop'
                                                ? '송출중지'
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={'상태를 선택해주세요.'}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showStatus
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                      .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                      .default
                                        }
                                        alt={'상태 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showStatus && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleStatus('inuse')
                                                }>
                                                <p
                                                    className={`${styles.font14} ${styles.black}`}>
                                                    전체송출
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleStatus('selective')
                                                }>
                                                <p
                                                    className={`${styles.font14} ${styles.black}`}>
                                                    선택송출
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleStatus('stop')
                                                }>
                                                <p
                                                    className={`${styles.font14} ${styles.black}`}>
                                                    송출중지
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        QR 코드*
                                    </p>
                                </div>
                                <label
                                    htmlFor="qr"
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.textAbbr}`}>
                                    <p
                                        className={`${styles.font14} ${
                                            qR ? styles.black : styles.grayA2
                                        } ${styles.flex1} ${styles.px15}`}>
                                        {qR === ''
                                            ? 'QR 코드를 선택해주세요.'
                                            : qR.startsWith(
                                                  'https://venbrothers.s3.ap-northeast-2.amazonaws.com/',
                                              )
                                            ? qR.replace(
                                                  'https://venbrothers.s3.ap-northeast-2.amazonaws.com/',
                                                  '',
                                              )
                                            : qR}
                                    </p>
                                    <input
                                        id={'qr'}
                                        className={`${styles.none}`}
                                        type={'file'}
                                        accept={'.jpg,.jpeg,.png'}
                                        onChange={addQR}
                                    />
                                </label>
                            </div>
                        </div>
                        <div
                            className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                이미지*
                            </p>
                            <div className={`${styles.center}`}>
                                <label htmlFor="image">
                                    <div
                                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt10}`}
                                        style={{
                                            opacity: loadingImage ? 0.4 : 1,
                                        }}>
                                        <p
                                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                            이미지 추가하기
                                        </p>
                                    </div>
                                </label>
                                <input
                                    id={'image'}
                                    ref={imageRef}
                                    className={`${styles.none}`}
                                    type={'file'}
                                    accept={'.jpg,.jpeg,.png'}
                                    onChange={addImage}
                                />
                            </div>
                            {image ? (
                                <div
                                    className={`${styles.px25} ${styles.mt25}`}>
                                    <IntroImage
                                        image={image}
                                        index={0}
                                        removeImage={removeImage}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={`${styles.px25} ${styles.mt25}`}>
                                    <label htmlFor="image">
                                        <div
                                            className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer}`}
                                            style={{ height: 150 }}>
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_add.png')
                                                        .default
                                                }
                                                alt={'add'}
                                                className={`${styles.icon20}`}
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className={`${styles.mt45}`}>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    저장
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={removeVideo}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    삭제
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default VideoEdit;
