const codes = [
  {
    code: "4865",
    name: "IDENTIFY_SUCCESS_FINGERPRINT",
    codename: "1:N 인증 성공(지문)",
  },
  {
    code: "3352",
    name: "REC_FINISHED_FAIL",
    codename: "녹화 종료 실패",
  },
  {
    code: "3354",
    name: "REC_FINISHED_FAIL_NO_RETRY",
    codename: "녹화 종료 실패 - 마지막 재시도",
  },
  {
    code: "3462",
    name: "SNP_SHOT_FINISHED_FAIL",
    codename: "스냅샷 종료 실패",
  },
  {
    code: "3464",
    name: "SNP_SHOT_FINISHED_FAIL_NO_RETRY",
    codename: "스냅샷 종료 실패 - 마지막 재시도",
  },
  {
    code: "4094",
    name: "ABNORMAL_DEVICE",
    codename: "펌웨어 업그레이드 필요 (장치 데이터베이스의 손상으로 로그 업로드 실패)",
  },
  {
    code: "4095",
    name: "DEVICE_DISCONNECT",
    codename: "장치연결해제 감지",
  },
  {
    code: "4097",
    name: "VERIFY_SUCCESS_ID_PIN",
    codename: "1:1 인증 성공(ID + PIN)",
  },
  {
    code: "4098",
    name: "VERIFY_SUCCESS_ID_FINGERPRINT",
    codename: "1:1 인증 성공(ID + 지문)",
  },
  {
    code: "4099",
    name: "VERIFY_SUCCESS_ID_FINGERPRINT_PIN",
    codename: "1:1 인증 성공(ID + 지문 + PIN)",
  },
  {
    code: "4100",
    name: "VERIFY_SUCCESS_ID_FACE",
    codename: "1:1 인증 성공(ID + 얼굴)",
  },
  {
    code: "4101",
    name: "VERIFY_SUCCESS_ID_FACE_PIN",
    codename: "1:1 인증 성공(ID + 얼굴 + PIN)",
  },
  {
    code: "4102",
    name: "VERIFY_SUCCESS_CARD",
    codename: "1:1 인증 성공(카드)",
  },
  {
    code: "4103",
    name: "VERIFY_SUCCESS_CARD_PIN",
    codename: "1:1 인증 성공(카드 + PIN)",
  },
  {
    code: "4104",
    name: "VERIFY_SUCCESS_CARD_FINGERPRINT",
    codename: "1:1 인증 성공(카드 + 지문)",
  },
  {
    code: "4105",
    name: "VERIFY_SUCCESS_CARD_FINGERPRINT_PIN",
    codename: "1:1 인증 성공(카드 + 지문 + PIN)",
  },
  {
    code: "4106",
    name: "VERIFY_SUCCESS_CARD_FACE",
    codename: "1:1 인증 성공(카드 + 얼굴)",
  },
  {
    code: "4107",
    name: "VERIFY_SUCCESS_CARD_FACE_PIN",
    codename: "1:1 인증 성공(카드 + 얼굴 + PIN)",
  },
  {
    code: "4112",
    name: "VERIFY_SUCCESS_CARD_FACE_FINGER",
    codename: "1:1 인증 성공(카드 + 얼굴 + 지문)",
  },
  {
    code: "4113",
    name: "VERIFY_SUCCESS_CARD_FINGER_FACE",
    codename: "1:1 인증 성공(카드 + 지문 + 얼굴)",
  },
  {
    code: "4114",
    name: "VERIFY_SUCCESS_ID_FACE_FINGER",
    codename: "1:1 인증 성공(ID + 얼굴 + 지문)",
  },
  {
    code: "4115",
    name: "VERIFY_SUCCESS_ID_FINGER_FACE",
    codename: "1:1 인증 성공(ID + 지문 + 얼굴)",
  },
  {
    code: "4118",
    name: "VERIFY_SUCCESS_MOBLIE_CARD",
    codename: "1:1 인증 성공(모바일카드)",
  },
  {
    code: "4119",
    name: "VERIFY_SUCCESS_MOBILE_CARD_PIN",
    codename: "1:1 인증 성공(모바일카드 + PIN)",
  },
  {
    code: "4120",
    name: "VERIFY_SUCCESS_MOBILE_CARD_FINGER",
    codename: "1:1 인증 성공(모바일카드 + 지문)",
  },
  {
    code: "4121",
    name: "VERIFY_SUCCESS_MOBILE_CARD_FINGER_PIN",
    codename: "1:1 인증 성공(모바일카드 + 지문 + PIN)",
  },
  {
    code: "4122",
    name: "VERIFY_SUCCESS_MOBILE_CARD_FACE",
    codename: "1:1 인증 성공(모바일카드 + 얼굴)",
  },
  {
    code: "4123",
    name: "VERIFY_SUCCESS_MOBiLE_CARD_FACE_PIN",
    codename: "1:1 인증 성공(모바일카드 + 얼굴 + PIN)",
  },
  {
    code: "4128",
    name: "VERIFY_SUCCESS_MOBILE_CARD_FACE_FINGER",
    codename: "1:1 인증 성공(모바일카드 + 얼굴 + 지문)",
  },
  {
    code: "4129",
    name: "VERIFY_SUCCESS_MOBILE_CARD_FINGER_FACE",
    codename: "1:1 인증 성공(모바일카드 + 지문 + 얼굴)",
  },
  {
    code: "4133",
    name: "VERIFY_SUCCESS_QR",
    codename: "1:1 인증 성공(QR/Barcode)",
  },
  {
    code: "4134",
    name: "VERIFY_SUCCESS_QR_PIN",
    codename: "1:1 인증 성공(QR/Barcode + PIN)",
  },
  {
    code: "4135",
    name: "VERIFY_SUCCESS_QR_FINGERPRINT",
    codename: "1:1 인증 성공(QR/Barcode + 지문)",
  },
  {
    code: "4136",
    name: "VERIFY_SUCCESS_QR_FINGERPRINT_PIN",
    codename: "1:1 인증 성공(QR/Barcode + 지문 + PIN)",
  },
  {
    code: "4137",
    name: "VERIFY_SUCCESS_QR_FACE",
    codename: "1:1 인증 성공(QR/Barcode + 얼굴)",
  },
  {
    code: "4138",
    name: "VERIFY_SUCCESS_QR_FACE_PIN",
    codename: "1:1 인증 성공(QR/Barcode + 얼굴 + PIN)",
  },
  {
    code: "4139",
    name: "VERIFY_SUCCESS_QR_FACE_FINGERPRINT",
    codename: "1:1 인증 성공(QR/Barcode + 얼굴 + 지문)",
  },
  {
    code: "4140",
    name: "VERIFY_SUCCESS_QR_FINGERPRINT_FACE",
    codename: "1:1 인증 성공(QR/Barcode + 지문 + 얼굴)",
  },
  {
    code: "4353",
    name: "VERIFY_FAIL_ID",
    codename: "1:1 인증 실패(ID)",
  },
  {
    code: "4354",
    name: "VERIFY_FAIL_CARD",
    codename: "1:1 인증 실패(카드)",
  },
  {
    code: "4355",
    name: "VERIFY_FAIL_PIN",
    codename: "1:1 인증 실패(PIN)",
  },
  {
    code: "4356",
    name: "VERIFY_FAIL_FINGERPRINT",
    codename: "1:1 인증 실패(지문)",
  },
  {
    code: "4357",
    name: "VERIFY_FAIL_FACE",
    codename: "1:1 인증 실패(얼굴)",
  },
  {
    code: "4360",
    name: "VERIFY_FAIL_MOBILE_CARD",
    codename: "1:1 인증 실패(모바일카드)",
  },
  {
    code: "4361",
    name: "VERIFY_FAIL_INVALID_QR",
    codename: "1:1 인증 실패(숫자가 아닌 데이터)",
  },
  {
    code: "4362",
    name: "VERIFY_FAIL_NON_PRINTABLE_QR",
    codename: "1:1 인증 실패(지원하지 않는 문자열)",
  },
  {
    code: "4363",
    name: "VERIFY_FAIL_TOO_LONG_QR",
    codename: "1:1 인증 실패(QR 최대 크기 초과)",
  },
  {
    code: "4364",
    name: "VERIFY_FAIL_CREDENTIAL_QR",
    codename: "1:1 인증 실패(QR/Barcode)",
  },
  {
    code: "4610",
    name: "VERIFY_DURESS_ID_FINGERPRINT",
    codename: "1:1 협박 지문 인증 성공(ID + 지문)",
  },
  {
    code: "4611",
    name: "VERIFY_DURESS_ID_FINGERPRINT_PIN",
    codename: "1:1 협박 지문 인증 성공(ID + 지문 + PIN)",
  },
  {
    code: "4616",
    name: "VERIFY_DURESS_CARD_FINGERPRINT",
    codename: "1:1 협박 지문 인증 성공(카드 + 지문)",
  },
  {
    code: "4617",
    name: "VERIFY_DURESS_CARD_FINGERPRINT_PIN",
    codename: "1:1 협박 지문 인증 성공(카드 + 지문 + PIN)",
  },
  {
    code: "4624",
    name: "VERIFY_DURESS_CARD_FACE_FINGER",
    codename: "1:1 협박 지문 인증 성공(카드 + 얼굴 + 지문)",
  },
  {
    code: "4625",
    name: "VERIFY_DURESS_CARD_FINGER_FACE",
    codename: "1:1 협박 지문 인증 성공(카드 + 지문 + 얼굴)",
  },
  {
    code: "4626",
    name: "VERIFY_DURESS_ID_FACE_FINGER",
    codename: "1:1 협박 지문 인증 성공(ID + 얼굴 + 지문)",
  },
  {
    code: "4627",
    name: "VERIFY_DURESS_ID_FINGER_FACE",
    codename: "1:1 협박 지문 인증 성공(ID + 지문 + 얼굴)",
  },
  {
    code: "4632",
    name: "VERIFY_DURESS_MOBILE_CARD_FINGER",
    codename: "1:1 협박 지문 인증 성공(모바일카드 + 지문)",
  },
  {
    code: "4633",
    name: "VERIFY_DURESS_MOBiLE_CARD_FINGER_PIN",
    codename: "1:1 협박 지문 인증 성공(모바일카드 + 지문 + PIN)",
  },
  {
    code: "4640",
    name: "VERIFY_DURESS_MOBILE_CARD_FACE_FINGER",
    codename: "1:1 협박 지문 인증 성공(모바일카드 + 얼굴 + 지문)",
  },
  {
    code: "4641",
    name: "VERIFY_DURESS_MOBILE_CARD_FINGER_FACE",
    codename: "1:1 협박 지문 인증 성공(모바일카드 + 지문 + 얼굴)",
  },
  {
    code: "4647",
    name: "VERIFY_DURESS_QR_FINGERPRINT",
    codename: "1:1 협박 지문 인증 성공(QR/Barcode + 지문)",
  },
  {
    code: "4648",
    name: "VERIFY_DURESS_QR_FINGERPRINT_PIN",
    codename: "1:1 협박 지문 인증 성공(QR/Barcode + 지문 + PIN)",
  },
  {
    code: "4651",
    name: "VERIFY_DURESS_QR_FACE_FINGERPRINT",
    codename: "1:1 협박 지문 인증 성공(QR/Barcode + 얼굴 + 지문)",
  },
  {
    code: "4652",
    name: "VERIFY_DURESS_QR_FINGERPRINT_FACE",
    codename: "1:1 협박 지문 인증 성공(QR/Barcode + 지문 + 얼굴)",
  },

  {
    code: "4866",
    name: "IDENTIFY_SUCCESS_FINGERPRINT_PIN",
    codename: "1:N 인증 성공(지문 + PIN)",
  },
  {
    code: "4867",
    name: "IDENTIFY_SUCCESS_FACE",
    codename: "1:N 인증 성공(얼굴)",
  },
  {
    code: "4868",
    name: "IDENTIFY_SUCCESS_FACE_PIN",
    codename: "1:N 인증 성공(얼굴 + PIN)",
  },
  {
    code: "4869",
    name: "IDENTIFY_SUCCESS_FACE_FINGER",
    codename: "1:N 인증 성공(얼굴 + 지문)",
  },
  {
    code: "4870",
    name: "IDENTIFY_SUCCESS_FACE_FINGER_PIN",
    codename: "1:N 인증 성공(얼굴 + 지문 + PIN)",
  },
  {
    code: "4871",
    name: "IDENTIFY_SUCCESS_FINGER_FACE",
    codename: "1:N 인증 성공(지문 + 얼굴)",
  },
  {
    code: "4872",
    name: "IDENTIFY_SUCCESS_FINGER_FACE_PIN",
    codename: "1:N 인증 성공(지문 + 얼굴 + PIN)",
  },
  {
    code: "5123",
    name: "IDENTIFY_FAIL_PIN",
    codename: "1:N 인증 실패(PIN)",
  },
  {
    code: "5124",
    name: "IDENTIFY_FAIL_FINGERPRINT",
    codename: "1:N 인증 실패(지문)",
  },
  {
    code: "5125",
    name: "IDENTIFY_FAIL_FACE",
    codename: "1:N 인증 실패(얼굴)",
  },
  {
    code: "5377",
    name: "IDENTIFY_DURESS_FINGERPRINT",
    codename: "1:N 협박 지문 인증 성공(지문)",
  },
  {
    code: "5378",
    name: "IDENTIFY_DURESS_FINGERPRINT_PIN",
    codename: "1:N 협박 지문 인증 성공(지문 + PIN)",
  },
  {
    code: "5381",
    name: "IDENTIFY_DURESS_FACE_FINGER",
    codename: "1:N 협박 지문 인증 성공(얼굴 + 지문)",
  },
  {
    code: "5382",
    name: "IDENTIFY_DURESS_FACE_FINGER_PIN",
    codename: "1:N 협박 지문 인증 성공(얼굴 + 지문 + PIN)",
  },
  {
    code: "5383",
    name: "IDENTIFY_DURESS_FINGER_FACE",
    codename: "1:N 협박 지문 인증 성공(지문 + 얼굴)",
  },
  {
    code: "5384",
    name: "IDENTIFY_DURESS_FINGER_FACE_PIN",
    codename: "1:N 협박 지문 인증 성공(지문 + 얼굴 + PIN)",
  },
  {
    code: "5889",
    name: "DUAL_AUTH_FAIL_TIMEOUT",
    codename: "이중 인증 실패(시간 초과)",
  },
  {
    code: "5890",
    name: "DUAL_AUTH_FAIL_ACCESS_GROUP",
    codename: "이중 인증 실패(잘못된 출입 그룹)",
  },
  {
    code: "6145",
    name: "AUTH_FAILED_INVALID_AUTH_MODE",
    codename: "인증 실패(잘못된 인증 모드)",
  },
  {
    code: "6146",
    name: "AUTH_FAILED_INVALID_CREDENTIAL",
    codename: "인증 실패(잘못된 크리덴셜)",
  },
  {
    code: "6147",
    name: "AUTH_FAILED_TIMEOUT",
    codename: "인증 실패(시간 초과)",
  },
  {
    code: "6148",
    name: "AUTH_FAILED_MATCHING_REFUSAL",
    codename: "인증 실패(서버 매칭 꺼짐)",
  },
  {
    code: "6401",
    name: "ACCESS_DENIED_ACCESS_GROUP",
    codename: "출입 거부(잘못된 출입 그룹)",
  },
  {
    code: "6402",
    name: "ACCESS_DENIED_DISABLED",
    codename: "출입 거부(비활성 사용자)",
  },
  {
    code: "6403",
    name: "ACCESS_DENIED_EXPIRED",
    codename: "출입 거부(유효 기간 범위 밖)",
  },
  {
    code: "6404",
    name: "ACCESS_DENIED_ON_BLACKLIST",
    codename: "출입 거부(블랙리스트)",
  },
  {
    code: "6405",
    name: "ACCESS_DENIED_APB",
    codename: "출입 거부(하드 안티패스백)",
  },
  {
    code: "6406",
    name: "ACCESS_DENIED_TIMED_APB",
    codename: "출입 거부(시간 지정 안티패스백)",
  },
  {
    code: "6407",
    name: "ACCESS_DENIED_FORCED_LOCK_SCHEDULE",
    codename: "출입 거부(스케줄 잠금)",
  },
  {
    code: "6408",
    name: "ACCESS_EXCUSED_SOFT_APB",
    codename: "출입 거부(소프트 안티패스백)",
  },
  {
    code: "6409",
    name: "ACCESS_EXCUSED_SOFT_TIMED_APB",
    codename: "출입 거부(소프트 시간 지정 안티패스백)",
  },
  {
    code: "6410",
    name: "ACCESS_DEVICE_FAILED_TO_FACE_DETECT",
    codename: "출입 거부(얼굴 검출 실패)",
  },
  {
    code: "6411",
    name: "ACCESS_DENIED_FAILED_TO_CAMERA_CAPTURE",
    codename: "출입 거부(촬영 실패)",
  },
  {
    code: "6412",
    name: "FAKE_FINGERPRINT_DETECTED",
    codename: "위조 지문 검출",
  },
  {
    code: "6414",
    name: "ACCESS_DENIED_INTRUSION_ALARM",
    codename: "경비 중 인증 실패",
  },
  {
    code: "6415",
    name: "ACCESS_DENIED_INTERLOCK_ALARM",
    codename: "출입 거부(인터락)",
  },
  {
    code: "6418",
    name: "ACCESS_DENIED_ANTI_TAILGATING_DEVICE",
    codename: "출입 거부(안티테일게이팅)",
  },
  {
    code: "6419",
    name: "ACCESS_DENIED_HIGH_TEMPERATURE",
    codename: "출입 거부(기준 온도 초과)",
  },
  {
    code: "6420",
    name: "ACCESS_DENIED_NONE_TEMPERATURE",
    codename: "출입 거부(온도 측정 오류)",
  },
  {
    code: "6421",
    name: "ACCESS_DENIED_UNMASK_DETECT",
    codename: "출입 거부(마스크 감지되지 않음)",
  },
  {
    code: "6422",
    name: "BS2_EVENT_ZONE_OCCP_ACCESS_DENIED_VIOLATION",
    codename: "출입 거부(재실 인원 제한 위반)",
  },
  {
    code: "6912",
    name: "BYPASS_SUCCESS_NO_VIOLATION",
    codename: "출입 성공(확인 전용)",
  },
  {
    code: "6913",
    name: "BYPASS_SUCCESS_THERMAL_ONLY",
    codename: "출입 성공(확인 전용 소프트 온도 위반)",
  },
  {
    code: "6914",
    name: "BYPASS_SUCCESS_MASK_ONLY",
    codename: "출입 성공(확인 전용 소프트 마스크 위반)",
  },
  {
    code: "6915",
    name: "BYPASS_SUCCESS_MASK_AND_THERMAL",
    codename: "출입 성공(확인 전용 소프트 온도 및 마스크 위반)",
  },
  {
    code: "7168",
    name: "BYPASS_FAIL_HIGH_TEMPERATURE",
    codename: "출입 거부(확인 전용 기준 온도 초과)",
  },
  {
    code: "7169",
    name: "BYPASS_FAIL_NONE_TEMPERATURE",
    codename: "출입 거부(확인 전용 온도 측정 오류)",
  },
  {
    code: "7170",
    name: "BYPASS_FAIL_UNMASK_DETECT",
    codename: "출입 거부(확인 전용 마스크 감지되지 않음)",
  },
  {
    code: "7424",
    name: "ABNORMAL_FEVER_DETECT_HIGH_TEMPERATURE",
    codename: "이상 온도 감지(기준 온도 초과)",
  },
  {
    code: "7425",
    name: "ABNORMAL_FEVER_DETECT_NONE_TEMPERATURE",
    codename: "이상 온도 감지(온도 측정 오류)",
  },
  {
    code: "7680",
    name: "UNMASKED_FACE_DETECT",
    codename: "마스크 감지되지 않음",
  },
  {
    code: "8192",
    name: "ENROLL_SUCCESS",
    codename: "사용자 등록 성공",
  },
  {
    code: "8448",
    name: "ENROLL_FAIL",
    codename: "사용자 등록 실패",
  },
  {
    code: "8449",
    name: "ENROLL_FAIL(VISUAL FACE)",
    codename: "사용자 등록 실패(비주얼 페이스 추출 실패)",
  },
  {
    code: "8450",
    name: "ENROLL_FAIL_MISMATCHED_FORMAT",
    codename: "사용자 등록 실패(지문 템플릿 형식 불일치)",
  },
  {
    code: "8451",
    name: "ENROLL_FAIL_FULL_CREDENTIAL",
    codename: "사용자 등록 실패(최대 크리덴셜 수 초과)",
  },
  {
    code: "8457",
    name: "ENROLL_FAIL_USER_DATA_ERROR",
    codename: "사용자 등록 실패(사용자 정보 오류)",
  },
  {
    code: "8704",
    name: "UPDATE_SUCCESS",
    codename: "사용자 업데이트 성공",
  },
  {
    code: "8960",
    name: "UPDATE_FAIL",
    codename: "사용자 업데이트 실패",
  },
  {
    code: "8961",
    name: "UPDATE_FAIL(VISUAL FACE)",
    codename: "사용자 업데이트 실패(비주얼 페이스 추출 실패)",
  },
  {
    code: "8962",
    name: "UPDATE_FAIL_MISMATCHED_FORMAT",
    codename: "사용자 업데이트 실패(지문 템플릿 형식 불일치)",
  },
  {
    code: "8969",
    name: "UPDATE_FAIL_USER_DATA_ERROR",
    codename: "사용자 업데이트 실패(사용자 정보 오류)",
  },
  {
    code: "9216",
    name: "DELETE_SUCCESS",
    codename: "사용자 삭제 성공",
  },
  {
    code: "9472",
    name: "DELETE_FAIL",
    codename: "사용자 삭제 실패",
  },
  {
    code: "9728",
    name: "DELETE_ALL_SUCCESS",
    codename: "사용자 모두 삭제 성공",
  },
  {
    code: "10242",
    name: "DUPLICATE_CARD",
    codename: "중복된 카드",
  },
  {
    code: "10244",
    name: "DUPLICATE_FINGERPRINT",
    codename: "중복된 지문",
  },
  {
    code: "10245",
    name: "DUPLICATE_FACE",
    codename: "중복된 얼굴",
  },
  {
    code: "10496",
    name: "PARTIAL_UPDATE_SUCCESS",
    codename: "사용자 부분 업데이트 성공",
  },
  {
    code: "10752",
    name: "PARTIAL_UPDATE_FAIL",
    codename: "사용자 부분 업데이트 실패",
  },
  {
    code: "10753",
    name: "PARTIAL_UPDATE_FAIL_INVALID_FACE",
    codename: "사용자 부분 업데이트 실패(비주얼 페이스 추출 실패)",
  },
  {
    code: "10754",
    name: "PARTIAL_UPDATE_FAIL_MISMATCHED_FORMAT",
    codename: "사용자 부분 업데이트 실패(지문 템플릿 형식 불일치)",
  },
  {
    code: "10755",
    name: "PARTIAL_UPDATE_FAIL_FULL_CREDENTIAL",
    codename: "사용자 부분 업데이트 실패(최대 크리덴셜 수 초과)",
  },
  {
    code: "10756",
    name: "PARTIAL_UPDATE_FAIL_INVALID_USER",
    codename: "사용자 부분 업데이트 실패(사용자 없음)",
  },
  {
    code: "10761",
    name: "PARTIAL_UPDATE_FAIL_USER_DATA_ERROR",
    codename: "사용자 부분 업데이트 실패(사용자 정보 오류)",
  },
  {
    code: "12288",
    name: "SYSTEM_RESET",
    codename: "장치 재시작",
  },
  {
    code: "12368",
    name: "BS2_EVENT_DEVICE_SYSTEM_ERROR_OPENGL",
    codename: "장치 재시작(시스템 재시작)",
  },
  {
    code: "12544",
    name: "SYSTEM_STARTED",
    codename: "장치 시작",
  },
  {
    code: "12800",
    name: "TIME_SET",
    codename: "장치 시간 설정",
  },
  {
    code: "12801",
    name: "TIME_SET_TIME_ZONE",
    codename: "타임존 설정",
  },
  {
    code: "12802",
    name: "DST_APPLIED",
    codename: "일광 절약 시간 적용",
  },
  {
    code: "13056",
    name: "LINK_CONNECTED",
    codename: "네트워크 연결 성공",
  },
  {
    code: "13312",
    name: "LINK_DISCONNECTED",
    codename: "네트워크 연결 해제",
  },
  {
    code: "13568",
    name: "DHCP_SUCCESS",
    codename: "DHCP 연결 성공",
  },
  {
    code: "13824",
    name: "ADMIN_MENU",
    codename: "관리자 메뉴 진입",
  },
  {
    code: "14080",
    name: "UI_LOCKED",
    codename: "장치 잠금",
  },
  {
    code: "14336",
    name: "UI_UNLOCKED",
    codename: "장치 잠금 해제",
  },
  {
    code: "14592",
    name: "COMM_LOCKED",
    codename: "BioStar 2 접속 잠금",
  },
  {
    code: "14848",
    name: "COMM_UNLOCKED",
    codename: "BioStar 2 접속 잠금 해제",
  },
  {
    code: "15104",
    name: "TCP_CONNECTED",
    codename: "BioStar 2 연결",
  },
  {
    code: "15120",
    name: "RTSP_CONNECTED",
    codename: "RTSP 연결 성공",
  },
  {
    code: "15360",
    name: "TCP_DISCONNECTED",
    codename: "BioStar 2 연결 해제",
  },
  {
    code: "15376",
    name: "RTSP_DISCONNECTED",
    codename: "RTSP 연결 해제",
  },
  {
    code: "15616",
    name: "RS485_CONNECTED",
    codename: "RS-486 연결 성공",
  },
  {
    code: "15872",
    name: "RS485_DISCONNECTED",
    codename: "RS-486 연결 해제",
  },
  {
    code: "16128",
    name: "INPUT_DETECTED",
    codename: "입력 감지(켜짐)",
  },
  {
    code: "16384",
    name: "TAMPER_ON",
    codename: "탬퍼 켜짐",
  },
  {
    code: "16640",
    name: "TAMPER_OFF",
    codename: "탬퍼 꺼짐",
  },
  {
    code: "16896",
    name: "EVENT_LOG_CLEARED",
    codename: "이벤트 로그 삭제",
  },
  {
    code: "17152",
    name: "FIRMWARE_UPGRADED",
    codename: "펌웨어 업그레이드 성공",
  },
  {
    code: "17408",
    name: "RESOURCE_UPGRADED",
    codename: "리소스 업그레이드 성공",
  },
  {
    code: "17664",
    name: "CONFIG_RESET",
    codename: "장치 재설정",
  },
  {
    code: "17665",
    name: "DATABASE_RESET",
    codename: "데이터베이스 리셋",
  },
  {
    code: "17666",
    name: "FACTORY_RESET",
    codename: "공장 초기화",
  },
  {
    code: "17920",
    name: "SUPERVISED_INPUT_SHORT",
    codename: "Supervised Input(단락 감지)",
  },
  {
    code: "18176",
    name: "SUPERVISED_INPUT_OPEN",
    codename: "Supervised Input(단선 감지)",
  },
  {
    code: "18432",
    name: "AC_FAIL",
    codename: "AC Power 실패",
  },
  {
    code: "18688",
    name: "AC_SUCCESS",
    codename: "AC Power 성공",
  },
  {
    code: "18944",
    name: "BS2_EVENT_EXIT_BUTTON",
    codename: "퇴실 버튼에 의한 출입문 개방 요청",
  },
  {
    code: "18945",
    name: "BS2_EVENT_SIMULATED_EXIT_BUTTON",
    codename: "퇴실 버튼에 의한 출입문 개방 요청(릴레이 동작 안 함)",
  },
  {
    code: "19200",
    name: "BS2_EVENT_OPERATOR_OPEN",
    codename: "관리자에 의한 출입문 개방 요청",
  },
  {
    code: "19456",
    name: "DOOR_UNLOCKED_BY_VOIP",
    codename: "IP 인터폰 문 열기 버튼에 의한 출입문 개방 요청",
  },
  {
    code: "19712",
    name: "BS2_EVENT_LICENSE_ENABLE_SUCESS",
    codename: "라이선스 활성화 성공",
  },
  {
    code: "19713",
    name: "BS2_EVENT_LICENSE_ENABLE_FAIL",
    codename: "라이선스 활성화 실패",
  },
  {
    code: "19714",
    name: "BS2_EVENT_LICENSE_DISABLE_SUCESS",
    codename: "라이선스 비활성화 성공",
  },
  {
    code: "19715",
    name: "BS2_EVENT_LICENSE_DISABLE_FAIL",
    codename: "라이선스 비활성화 실패",
  },
  {
    code: "19716",
    name: "BS2_EVENT_LICENSE_EXPIRED",
    codename: "라이선스 만료",
  },
  {
    code: "19969",
    name: "UZ_BATTERY_LV_NORMAL",
    codename: "배터리 잔량 부족",
  },
  {
    code: "19970",
    name: "UZ_BATTERY_LV_LOW",
    codename: "배터리 잔량 매우 부족",
  },
  {
    code: "19971",
    name: "UZ_BATTERY_LV_LOWEST",
    codename: "배터리 잔량 없음",
  },
  {
    code: "20480",
    name: "UNLOCKED",
    codename: "출입문 개방",
  },
  {
    code: "20736",
    name: "LOCKED",
    codename: "출입문 잠금",
  },
  {
    code: "20992",
    name: "OPEN",
    codename: "출입문 열림",
  },
  {
    code: "21248",
    name: "CLOSE",
    codename: "출입문 닫힘",
  },
  {
    code: "21504",
    name: "FORCED_OPEN",
    codename: "출입문 강제 열림",
  },
  {
    code: "21760",
    name: "HELD_OPEN",
    codename: "출입문 장시간 열림",
  },
  {
    code: "22016",
    name: "FORCED_OPEN_ALARM",
    codename: "출입문 강제 열림 알람",
  },
  {
    code: "22272",
    name: "FORCED_OPEN_ALARM_CLEAR",
    codename: "출입문 강제 열림 알람 해제",
  },
  {
    code: "22528",
    name: "HELD_OPEN_ALARM",
    codename: "출입문 장시간 열림 알람",
  },
  {
    code: "22784",
    name: "HELD_OPEN_ALARM_CLEAR",
    codename: "출입문 장시간 열림 알람 해제",
  },
  {
    code: "23040",
    name: "DOOR_APB_ALARM",
    codename: "출입문 안티패스백 알람",
  },
  {
    code: "23296",
    name: "DOOR_APB_ALARM_CLEAR",
    codename: "출입문 안티패스백 알람 해제",
  },
  {
    code: "23553",
    name: "RELEASE_DOOR_BY_SCHEDULE",
    codename: "스케줄 개방/잠금 해제 요청(출입문)",
  },
  {
    code: "23554",
    name: "RELEASE_DOOR_BY_EMERGENCY",
    codename: "화재 경보 개방 해제 요청",
  },
  {
    code: "23556",
    name: "RELEASE_DOOR_BY_OPERATOR",
    codename: "관리자 개방/잠금 해제 요청",
  },
  {
    code: "23809",
    name: "LOCK_DOOR_BY_SCHEDULE",
    codename: "스케줄 잠금 요청",
  },
  {
    code: "23810",
    name: "LOCK_DOOR_BY_EMERGENCY",
    codename: "화재 경보 잠금 요청",
  },
  {
    code: "23812",
    name: "LOCK_DOOR_BY_OPERATOR",
    codename: "관리자 수동 잠금 요청",
  },
  {
    code: "24065",
    name: "UNLOCK_DOOR_BY_SCHEDULE",
    codename: "스케줄 개방 요청(출입문)",
  },
  {
    code: "24066",
    name: "UNLOCK_DOOR_BY_EMERGENCY",
    codename: "화재 경보 개방 요청",
  },
  {
    code: "24068",
    name: "UNLOCK_DOOR_BY_OPERATOR",
    codename: "관리자 수동 개방 요청",
  },
  {
    code: "24576",
    name: "APB_VIOLATION",
    codename: "안티패스백 위반 감지",
  },
  {
    code: "24577",
    name: "APB_VIOLATION_HARD",
    codename: "하드 안티패스백 위반 감지",
  },
  {
    code: "24578",
    name: "APB_VIOLATION_SOFT",
    codename: "소프트 안티패스백 위반 감지",
  },
  {
    code: "24832",
    name: "APB_ALARM",
    codename: "안티패스백 구역 알람 감지",
  },
  {
    code: "25088",
    name: "APB_ALARM_CLEAR",
    codename: "안티패스백 구역 알람 해제",
  },
  {
    code: "25344",
    name: "TIMED_APB_VIOLATION",
    codename: "시간 지정 안티패스백 위반 감지",
  },
  {
    code: "25600",
    name: "TIMED_APB_ALARM",
    codename: "시간 지정 안티패스백 구역 알람 감지",
  },
  {
    code: "25856",
    name: "TIMED_APB_ALARM_CLEAR",
    codename: "시간 지정 안지패스백 구역 알람 해제",
  },
  {
    code: "26112",
    name: "FIRE_ALARM_INPUT",
    codename: "화재 경보 입력 감지",
  },
  {
    code: "26368",
    name: "FIRE_ALARM",
    codename: "화재 경보 구역 알람 감지",
  },
  {
    code: "26624",
    name: "FIRE_ALARM_CLEAR",
    codename: "화재 경보 구역 알람 해제",
  },
  {
    code: "26880",
    name: "FORCED_LOCK_VIOLATION",
    codename: "스케줄 잠금 위반 감지",
  },
  {
    code: "27136",
    name: "FORCED_LOCK_START",
    codename: "스케줄 잠금 구역 시작",
  },
  {
    code: "27392",
    name: "FORCED_LOCK_END",
    codename: "스케줄 잠금 구역 끝",
  },
  {
    code: "27648",
    name: "FORCED_UNLOCK_START",
    codename: "스케줄 개방 구역 시작(출입문)",
  },
  {
    code: "27904",
    name: "FORCED_UNLOCK_END",
    codename: "스케줄 개방 구역 끝(출입문)",
  },
  {
    code: "28160",
    name: "FORCED_LOCK_ALARM",
    codename: "스케줄 잠금 구역 알람 감지",
  },
  {
    code: "28416",
    name: "FORCED_LOCK_ALARM_CLEAR",
    codename: "스케줄 잠금 구역 알람 해제",
  },
  {
    code: "28417",
    name: "BS2_EVENT_ZONE_OCCP_FULL_DETECTED",
    codename: "재실 인원 정원 도달 감지",
  },
  {
    code: "28418",
    name: "BS2_EVENT_ZONE_OCCP_AVAILABILITY_RECOVERED",
    codename: "재실 인원 제한 구역 입실 가능 상태 복구",
  },
  {
    code: "28419",
    name: "BS2_EVENT_ZONE_OCCP_EXIT_OCCURRED_COUNT_ZERO",
    codename: "공실 중 퇴실 입력 감지",
  },
  {
    code: "28420",
    name: "BS2_EVENT_ZONE_OCCP_ALMOST_FULL_STEP1",
    codename: "재실 인원 사전 경보 1 감지",
  },
  {
    code: "28421",
    name: "BS2_EVENT_ZONE_OCCP_ALMOST_FULL_STEP2",
    codename: "재실 인원 사전 경보 2 감지",
  },
  {
    code: "28672",
    name: "ELEVATOR_ACTIVATED",
    codename: "층 활성",
  },
  {
    code: "28928",
    name: "ELEVATOR_DEACTIVATED",
    codename: "층 해제",
  },
  {
    code: "29185",
    name: "RELEASE_ELEVATOR_BY_SCHEDULE",
    codename: "스케줄 개방/잠금 해제 요청(엘리베이터)",
  },
  {
    code: "29186",
    name: "RELEASE_ELEVATOR_BY_EMERGENCY",
    codename: "비상상태 개방/잠금 해제",
  },
  {
    code: "29188",
    name: "RELEASE_ELEVATOR_BY_OPERATOR",
    codename: "관리자 개방/잠금 해제",
  },
  {
    code: "29192",
    name: "RELEASE_ELEVATOR_ALARM",
    codename: "층 해제(알람)",
  },
  {
    code: "29441",
    name: "ACTIVATE_ELEVATOR_BY_SCHEDULE",
    codename: "스케줄 개방 요청(엘리베이터)",
  },
  {
    code: "29442",
    name: "ACTIVATE_ELEVATOR_BY_EMERGENCY",
    codename: "비상상태 수동 개방",
  },
  {
    code: "29444",
    name: "ACTIVATE_ELEVATOR_BY_OPERATOR",
    codename: "관리자 수동 개방",
  },
  {
    code: "29448",
    name: "ACTIVATE_ELEVATOR_ALARM",
    codename: "층 활성(알람)",
  },
  {
    code: "29697",
    name: "DEACTIVATE_ELEVATOR_BY_SCHEDULE",
    codename: "스케줄 수동 잠금",
  },
  {
    code: "29698",
    name: "DEACTIVATE_ELEVATOR_BY_EMERGENCY",
    codename: "비상상태 수동 장금",
  },
  {
    code: "29700",
    name: "DEACTIVATE_ELEVATOR_BY_OPERATOR",
    codename: "관리자 수동 잠금",
  },
  {
    code: "29952",
    name: "ELEVATOR_ALARM_INPUT",
    codename: "엘리베이터 알람 입력 감지",
  },
  {
    code: "30208",
    name: "ELEVATOR_ALARM",
    codename: "엘리베이터 알람",
  },
  {
    code: "30464",
    name: "ELEVATOR_ALARM_CLEAR",
    codename: "엘리베이터 알람 해제",
  },
  {
    code: "30720",
    name: "ALL_FLOOR_ACTIVATED",
    codename: "모든 층의 릴레이 활성화",
  },
  {
    code: "30976",
    name: "ALL_FLOOR_DEACTIVATED",
    codename: "모든 층의 릴레이 비활성화",
  },
  {
    code: "36864",
    name: "ZONE_INTRUSION_ALARM_VIOLATION",
    codename: "경비 중 인증 제한",
  },
  {
    code: "37120",
    name: "ZONE_INTRUSION_ALARM_ARM_GRANTED",
    codename: "경비 진입 인증 성공",
  },
  {
    code: "37376",
    name: "ZONE_INTRUSION_ALARM_ARM_SUCCESS",
    codename: "경비 진입",
  },
  {
    code: "37632",
    name: "ZONE_INTRUSION_ALARM_ARM_FAIL",
    codename: "경비 진입 실패",
  },
  {
    code: "37888",
    name: "ZONE_INTRUSION_ALARM_DISARM_ARM_GRANTED",
    codename: "해제 인증 성공",
  },
  {
    code: "38144",
    name: "ZONE_INTRUSION_ALARM_DISARM_ARM_SUCCESS",
    codename: "경비 해제",
  },
  {
    code: "38912",
    name: "ZONE_INTRUSION_ALARM",
    codename: "침입 감지",
  },
  {
    code: "39168",
    name: "ZONE_INTRUSION_ALARM_CLEAR",
    codename: "침입 경보 상태 해제",
  },
  {
    code: "39424",
    name: "ZONE_INTRUSION_ALARM_ARM_DENIED",
    codename: "경비 진입 인증 실패",
  },
  {
    code: "39680",
    name: "ZONE_INTRUSION_ALARM_DISARM_DENIED",
    codename: "경비 해제 인증 실패",
  },
  {
    code: "40961",
    name: "ZONE_INTERLOCK_VIOLATION_DOOR_OPEN",
    codename: "인터락 출입문 열림 거부",
  },
  {
    code: "40962",
    name: "ZONE_INTERLOCK_VIOLATION_INPUT_DETECT",
    codename: "체류자로 인한 인터락 출입문 열림 거부",
  },
  {
    code: "41216",
    name: "ZONE_INTERLOCK_ALARM",
    codename: "인터락 구역 알람",
  },
  {
    code: "41472",
    name: "ZONE_INTERLOCK_ALARM_DOOR_OPEN_DENIED",
    codename: "인터락 출입문 열림 거부 알람",
  },
  {
    code: "41728",
    name: "ZONE_INTERLOCK_ALARM_INDOOR_DENIED",
    codename: "체류자로 인한 인터락 출입문 열림 거부 알람",
  },
  {
    code: "41984",
    name: "ZONE_INTERLOCK_ALARM_CLEAR",
    codename: "인터락 구역 알람 해제",
  },
  {
    code: "42753",
    name: "BS2_EVENT_ZONE_OCCP_ACCESS_DENIED",
    codename: "재실 인원 제한 위반(정원 초과)",
  },
  {
    code: "42754",
    name: "BS2_EVENT_ZONE_OCCP_ACCESS_DENIED_NET_FAILURE",
    codename: "재실 인원 제한 위반(네트워크 오류)",
  },
  {
    code: "45056",
    name: "ZONE_MUSTER_VIOLATION",
    codename: "소집 구역 체류 시간 초과",
  },
  {
    code: "45312",
    name: "ZONE_MUSTER_ALARM",
    codename: "소집 구역 알람 감지",
  },
  {
    code: "45568",
    name: "ZONE_MUSTER_ALARM_CLEAR",
    codename: "소집 구역 알람 해제",
  },
  {
    code: "47104",
    name: "ZONE_LIFT_UNLOCK_START",
    codename: "스케줄 개방 구역 시작(엘리베이터)",
  },
  {
    code: "47360",
    name: "ZONE_LIFT_UNLOCK_END",
    codename: "스케줄 개방 구역 끝(엘리베이터)",
  },
  {
    code: "49152",
    name: "DEVICE_USER_SYNC_TO_SERVER_FAIL",
    codename: "서버 반영 실패",
  },
  {
    code: "49920",
    name: "RELAY_ACTIVATED",
    codename: "릴레이 켜짐",
  },
  {
    code: "50176",
    name: "RELAY_DEACTIVATE",
    codename: "릴레이 꺼짐",
  },
  {
    code: "50177",
    name: "BACKUP_STARTED",
    codename: "시스템 백업 시작",
  },
  {
    code: "50179",
    name: "BACKUP_COMPLETE",
    codename: "시스템 백업 완료",
  },
  {
    code: "50181",
    name: "BACKUP_FAILED",
    codename: "시스템 백업 실패",
  },
  {
    code: "53248",
    name: "TOM_FACE_ENROLL_SUCCESS",
    codename: "ToM 비주얼 페이스 등록 성공",
  },
  {
    code: "53505",
    name: "TOM_FACE_ENROLL_FAIL_SCAN_CANCELED",
    codename: "ToM 비주얼 페이스 등록 실패(등록 취소)",
  },
  {
    code: "53506",
    name: "TOM_FACE_ENROLL_FAIL_SCAN_TIMEOUT",
    codename: "ToM 비주얼 페이스 등록 실패(등록 대기 시간 초과)",
  },
  {
    code: "53507",
    name: "TOM_FACE_ENROLL_FAIL_WARP_ERROR",
    codename: "ToM 비주얼 페이스 등록 실패(템플릿 추출 실패)",
  },
  {
    code: "53508",
    name: "TOM_FACE_ENROLL_FAIL_WRITE_ERROR",
    codename: "ToM 비주얼 페이스 등록 실패(통신 오류)",
  },
  {
    code: "53513",
    name: "TOM_FACE_ENROLL_FAIL_INTERNAL_ERROR",
    codename: "ToM 비주얼 페이스 등록 실패(지정되지 않은 오류)",
  },
  {
    code: "53760",
    name: "TOM_FINGER_ENROLL_SUCCESS",
    codename: "ToM 지문 등록 성공",
  },
  {
    code: "54017",
    name: "TOM_FINGER_ENROLL_FAIL_SCAN_CANCELED",
    codename: "ToM 지문 등록 실패(등록 취소)",
  },
  {
    code: "54018",
    name: "TOM_FINGER_ENROLL_FAIL_SCAN_TIMEOUT",
    codename: "ToM 지문 등록 실패(등록 대기 시간 초과)",
  },
  {
    code: "54019",
    name: "TOM_FINGER_ENROLL_FAIL_WARP_ERROR",
    codename: "ToM 지문 등록 실패(템플릿 추출 실패)",
  },
  {
    code: "54020",
    name: "TOM_FINGER_ENROLL_FAIL_WRITE_ERROR",
    codename: "ToM 지문 등록 실패(통신 오류)",
  },
  {
    code: "54025",
    name: "TOM_FINGER_ENROLL_FAIL_INTERNAL_ERROR",
    codename: "ToM 지문 등록 실패(지정되지 않은 오류)",
  },
];

export default codes;
