import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import Calendar from "react-calendar";
import DaumPostcode from "react-daum-postcode";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import axios from "axios";
import { FETCH_URL } from "../../../config";
import AddEntrance from "../../../components/Member/AddEntrance";

const CREATE = gql`
  mutation addMember(
    $memberId: Int
    $machine: String!
    $name: String!
    $mobile: String!
    $birth: String
    $gender: String
    $membershipId: Int
    $membershipType: Int
    $start: String
    $end: String
    $membershipCount: Int
    $cancelCount: Int
    $ignoreCancel: Boolean
    $address: String
    $extraAddress: String
    $addressType: String
    $sido: String
    $sigungu: String
    $bname: String
    $purpose: String
    $extra: String
    $profileImage: String
  ) {
    addMember(
      memberId: $memberId
      machine: $machine
      name: $name
      mobile: $mobile
      birth: $birth
      gender: $gender
      membershipId: $membershipId
      membershipType: $membershipType
      start: $start
      end: $end
      membershipCount: $membershipCount
      cancelCount: $cancelCount
      ignoreCancel: $ignoreCancel
      address: $address
      extraAddress: $extraAddress
      addressType: $addressType
      sido: $sido
      sigungu: $sigungu
      bname: $bname
      purpose: $purpose
      extra: $extra
      profileImage: $profileImage
    ) {
      member {
        id
        name
        mobile
        createdAt
        defaultMembership {
          id
          product {
            id
            name
          }
          start
          end
          status
        }
      }
      error
    }
  }
`;

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      duration
      membershipType
      membership
      cancel
      ignoreCancel
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
      entranceMachine
      deviceId
    }
  }
`;

const MemberAdd = () => {
  const titleWidth = 120;

  const history = useHistory();
  const location = useLocation();

  const profileImageRef = useRef();

  const name = useInput("");
  const mobile = useInput("");
  const birth = useInput("");
  const extraAddress = useInput("");
  const purpose = useInput("");
  const extra = useInput("");

  const [profileImage, setProfileImage] = useState(null);
  const [previewProfileImage, setPreviewProfileImage] = useState("");
  const [gender, setGender] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [membership, setMembership] = useState({});
  const [showMembership, setShowMembership] = useState(false);
  const [start, setStart] = useState(null);
  const [showStart, setShowStart] = useState(false);
  const [end, setEnd] = useState(null);
  const [showEnd, setShowEnd] = useState(false);
  const [membershipCount, setMembershipCount] = useState("");
  const [cancelCount, setCancelCount] = useState("");
  const [address, setAddress] = useState({
    address: "",
    addressType: "",
    sido: "",
    sigungu: "",
    bname: "",
  });
  const [showAddress, setShowAddress] = useState(false);
  const [showDaum, setShowDaum] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEntrance, setIsEntrance] = useState(false);
  const [showAddEntranceModal, setShowAddEntranceModal] = useState(false);
  const [member, setMember] = useState(null);

  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      machineId: location.state.machineId ? location.state.machineId : null,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [addMemberM] = useMutation(CREATE);

  const handleMobile = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      mobile.setValue(value);
    }
  };

  const handleBirth = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      birth.setValue(value);
    }
  };

  const handleGender = (value) => {
    setGender(value);
    setShowGender(false);
  };

  const handleMembership = (membership) => {
    setMembership(membership);
    setShowMembership(false);

    if (membership.membershipType === 2) {
      setStart(new Date());
      let end = new Date();
      end.setFullYear(end.getFullYear() + 10);
      setEnd(end);
    } else {
      setStart(null);
      setEnd(null);
    }

    if (membership.membershipType === 2 || membership.membershipType === 3) {
      setMembershipCount(membership.membership);
      setCancelCount(membership.cancel);
    }
  };

  const handleStart = (start) => {
    setStart(start);
    if (membership.duration) {
      setEnd(new Date(start.getTime() + 1000 * 60 * 60 * 24 * (membership.duration - 1)));
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setEnd(end);
    setShowEnd(false);
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setMembershipCount(value);
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCancelCount(value);
    }
  };

  const handleAddress = (data) => {
    setAddress({
      address: data.address,
      addressType: data.addressType,
      sido: data.sido,
      sigungu: data.sigungu,
      bname: data.bname,
    });
    setShowDaum(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (name.value && mobile.value) {
        if (mobile.value.length !== 8) {
          alert("전화번호 11자리를 입력해주세요");
          return;
        }

        if (isEntrance) {
          if (!membership.id) {
            alert("회원권을 선택해주세요.");
            return;
          }
        }

        if (membership) {
          if (membership.membershipType === 1 || membership.membershipType === 3) {
            if (!start) {
              alert("회원권 시작일을 입력해주세요.");
              return;
            }

            if (!end) {
              alert("회원권 종료일을 입력해주세요.");
              return;
            }
          }

          if (membership.membershipType === 2 || membership.membershipType === 3) {
            if (!membershipCount) {
              alert("횟수를 입력해주세요.");
              return;
            }

            if (!membership.ignoreCancel && !cancelCount) {
              alert("취소 가능 횟수를 입력해주세요.");
              return;
            }
          }
        }
        if (isEntrance) {
          setMember({
            name: name.value,
            mobile: mobile.value,
          });
          console.log(member);
          console.log(member);
          console.log(member);
        } else {
          await addMemberMachine(null);
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  const addMemberMachine = async (memberId) => {
    setLoading(true);

    try {
      let uploadResult = await uploadProfileImage();
      if (uploadResult.result === "ok") {
        const { data: result } = await addMemberM({
          variables: {
            memberId,
            machine: location.state.machine ? location.state.machine : "",
            name: name.value,
            mobile: mobile.value,
            birth: birth.value,
            gender,
            membershipId: membership.id,
            membershipType: membership.membershipType,
            start: start ? `${start.getTime()}` : null,
            end: end ? `${end.getTime()}` : null,
            membershipCount: membershipCount ? Number(membershipCount) : null,
            cancelCount: !membership.ignoreCancel && cancelCount ? Number(cancelCount) : null,
            ignoreCancel: membership.ignoreCancel,
            address: address.address,
            extraAddress: extraAddress.value,
            addressType: address.addressType,
            sido: address.sido,
            sigungu: address.sigungu,
            bname: address.bname,
            purpose: purpose.value,
            extra: extra.value,
            profileImage: uploadResult.profileImage,
          },
        });
        if (result && result.addMember) {
          if (result.addMember.error) {
            setLoading(false);
            alert(result.addMember.error);
          } else {
            setLoading(false);
            alert("회원을 등록하였습니다.");
            initialize();
          }
        } else {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      } else {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    } catch {
      setLoading(false);
      alert("오류가 발생하였습니다.");
    }
  };

  const uploadProfileImage = async () => {
    if (!profileImage) {
      return {
        result: "ok",
        profileImage: "",
      };
    }

    const formData = new FormData();
    const resizedImage = await resizeImage(profileImage);
    formData.append("excel", resizedImage, profileImage.name);
    const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data && data.location) {
      return {
        result: "ok",
        profileImage: data.location,
      };
    } else {
      return {
        result: "fail",
        profileImage: "",
      };
    }
  };

  const delProfileImageFile = () => {
    profileImageRef.current.value = null;
    setProfileImage(null);
  };

  const initialize = () => {
    name.setValue("");
    mobile.setValue("");
    birth.setValue("");
    extraAddress.setValue("");
    purpose.setValue("");
    extra.setValue("");
    setGender("");
    setShowGender(false);
    setMembership({});
    setShowMembership(false);
    setStart(null);
    setShowStart(false);
    setEnd(null);
    setMembershipCount("");
    setCancelCount("");
    setShowEnd(false);
    setAddress({
      address: "",
      addressType: "",
      sido: "",
      sigungu: "",
      bname: "",
    });
    setShowAddress(false);
    setShowDaum(false);
    setLoading(false);
    delProfileImageFile();
  };

  useEffect(() => {
    setShowDaum(false);
  }, [showAddress]);

  useEffect(() => {
    if (member) {
      setShowAddEntranceModal(true);
    }
  }, [member]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (profileImage) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setPreviewProfileImage(reader.result);
      };
      reader.readAsDataURL(profileImage);
    } else {
      setPreviewProfileImage("");
    }
  }, [profileImage]);

  useEffect(() => {
    let _isEntrance = false;
    if (machine && machine.getMachine) {
      _isEntrance = machine.getMachine.entranceSystem !== "none";
    }

    setIsEntrance(_isEntrance);
  }, [machine]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`회원 등록${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div>
              <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.mt10}`}>
              <div
                className={`${styles.flex2} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.textCenter} ${styles.center}`}
                style={{
                  height: 250,
                }}
              >
                {profileImage ? (
                  <img
                    style={{
                      height: 250,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={previewProfileImage}
                  />
                ) : (
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>사진</p>
                )}
                <input ref={profileImageRef} id={"profile_image"} className={`${styles.none}`} type={"file"} accept={"image/*"} onChange={(e) => setProfileImage(e.target.files[0])} />
              </div>
              <div className={`${styles.px15} ${styles.flex1}`} style={{ display: "table" }}>
                <div style={{ display: "table-cell", flex: 1, verticalAlign: "middle" }}>
                  <label className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter} ${styles.mb20}`} htmlFor={"profile_image"} style={{ display: "block" }}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>변경</p>
                  </label>
                  <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter}`} onClick={delProfileImageFile}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원명*</p>
              </div>
              <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"회원명을 입력해주세요."} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원 연락처*</p>
              </div>
              <NumberFormat value={mobile.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} type={"tel"} displayType={"input"} onValueChange={handleMobile} placeholder={"회원 연락처를 입력해주세요."} format={"010-####-####"} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>생년월일</p>
              </div>
              <NumberFormat value={birth.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} type={"tel"} displayType={"input"} onValueChange={handleBirth} placeholder={"회원의 생년월일을 입력해주세요.(YYMMDD)"} format={"######"} />
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showGender ? 0 : 10,
                  borderBottomRightRadius: showGender ? 0 : 10,
                }}
                onClick={() => setShowGender(!showGender)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>성별</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input type={"text"} value={gender === "male" || gender === "female" ? (gender === "male" ? "남성" : "여성") : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"회원의 성별을 선택해주세요."} readOnly={true} />
                  <img src={showGender ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"성별 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showGender && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: titleWidth }}></div>
                    <div className={`${styles.py10} ${styles.flex1}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("male")}>
                        <p className={`${styles.font14} ${styles.black}`}>남성</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("female")}>
                        <p className={`${styles.font14} ${styles.black}`}>여성</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>운동 목적</p>
              </div>
              <input type={"text"} name={"purpose"} value={purpose.value} onChange={purpose.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"회원의 운동 목적을 입력해주세요."} />
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showMembership ? 0 : 10,
                  borderBottomRightRadius: showMembership ? 0 : 10,
                }}
                onClick={() => setShowMembership(!showMembership)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권{isEntrance ? "*" : ""}</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input type={"text"} value={membership && membership.id ? membership.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"회원이 사용중인 회원권을 선택해주세요."} readOnly={true} />
                  <img src={showMembership ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showMembership && (
                <div
                  className={`${styles.bgWhite} ${styles.dropdown} ${styles.hideScroll}`}
                  style={{
                    left: 0,
                    right: 0,
                    maxHeight: 300,
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: titleWidth }}></div>
                    <div className={`${styles.py10}`}>
                      {data?.getMemberships?.map((item) => {
                        if (!(item.membershipType === 2 && isEntrance)) {
                          return (
                            <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                              <p className={`${styles.font14} ${styles.black}`}>{item.name}</p>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative", display: membership.membershipType === 1 || membership.membershipType === 3 ? "block" : "none" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showStart ? 0 : 10,
                  borderBottomRightRadius: showStart ? 0 : 10,
                }}
                onClick={() => setShowStart(!showStart)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권 시작일{isEntrance ? "*" : ""}</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input
                    type={"text"}
                    value={start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                    style={{ minWidth: 20 }}
                    placeholder={"회원권 시작일을 선택해주세요."}
                    readOnly={true}
                  />
                  <img src={showStart ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 시작일 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showStart && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                    <Calendar
                      calendarType={"US"}
                      value={start}
                      maxDate={end ? end : null}
                      minDetail={"month"}
                      nextLabel={
                        <span>
                          <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                        </span>
                      }
                      next2Label={null}
                      prevLabel={
                        <span>
                          <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                        </span>
                      }
                      prev2Label={null}
                      navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>{label}</p>}
                      tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                      onChange={handleStart}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative", display: membership.membershipType === 1 || membership.membershipType === 3 ? "block" : "none" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showEnd ? 0 : 10,
                  borderBottomRightRadius: showEnd ? 0 : 10,
                }}
                onClick={() => setShowEnd(!showEnd)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권 종료일{isEntrance ? "*" : ""}</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input
                    type={"text"}
                    value={end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                    style={{ minWidth: 20 }}
                    placeholder={"회원권 종료일을 선택해주세요."}
                    readOnly={true}
                  />
                  <img src={showEnd ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 종료일 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showEnd && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                    <Calendar
                      calendarType={"US"}
                      value={end}
                      minDate={start ? start : null}
                      minDetail={"month"}
                      nextLabel={
                        <span>
                          <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                        </span>
                      }
                      next2Label={null}
                      prevLabel={
                        <span>
                          <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                        </span>
                      }
                      prev2Label={null}
                      navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>{label}</p>}
                      tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                      onChange={handleEnd}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} style={{ display: membership.membershipType === 2 || membership.membershipType === 3 ? "flex" : "none" }}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>횟수*</p>
              </div>
              <NumberFormat
                value={membershipCount}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                type={"tel"}
                displayType={"input"}
                onValueChange={handleMembershipCount}
                placeholder={"횟수를 입력해주세요. (숫자만 입력)"}
                style={{
                  width: "100%",
                  minWidth: 20,
                }}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} style={{ display: membership.membershipType === 2 || membership.membershipType === 3 ? "flex" : "none" }}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>취소 가능 횟수*</p>
              </div>
              {membership.ignoreCancel ? (
                <input type={"text"} name={"ignoreCancel"} value={"무제한"} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
              ) : (
                <NumberFormat
                  value={cancelCount}
                  thousandSeparator={true}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                  type={"tel"}
                  displayType={"input"}
                  onValueChange={handleCancelCount}
                  placeholder={"취소 가능 횟수를 입력해주세요. (숫자만 입력)"}
                  style={{
                    width: "100%",
                    minWidth: 20,
                  }}
                />
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showAddress ? 0 : 10,
                  borderBottomRightRadius: showAddress ? 0 : 10,
                }}
                onClick={() => setShowAddress(!showAddress)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: titleWidth }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원 주소</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input type={"text"} value={address.address ? `${address.address} ${extraAddress.value}` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15} `} style={{ minWidth: 20 }} placeholder={"회원의 주소지를 입력해주세요."} readOnly={true} />
                  {showAddress ? (
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.underline} ${styles.px15} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                      완료
                    </p>
                  ) : (
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.underline} ${styles.px15} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                      변경
                    </p>
                  )}
                </div>
              </div>
              {showAddress && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: titleWidth }}></div>
                    {showDaum ? (
                      <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                        <DaumPostcode onComplete={handleAddress} />
                      </div>
                    ) : (
                      <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>도 / 시</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.sido}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>시 / 군 / 구</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.sigungu}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>동 / 면 / 읍</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.bname}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.borderBottomGrayA2}`}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상세주소</p>
                          <input type={"text"} name={"extraAddress"} value={extraAddress.value} onChange={extraAddress.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15} ${styles.textRight}`} style={{ minWidth: 50 }} placeholder={"상세주소를 입력해주세요."} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>비고</p>
              <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 120 }} placeholder={"비고"} maxLength={5000} />
            </div>
            <div className={`${styles.mt10}`} style={{ display: "none" }}>
              <div className={`${styles.row} ${styles.mx0}`}>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black}`}>*경고:</p>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.flex1} ${styles.ml10}`}>
                  벤브라더스 무인 출입 시스템(Suprema)을 이용하는 경우, 현재 보고 계신 관리자 웹이 아닌, 키오스크 관리자 기능을 통해서 회원을 등록하십시오!
                  <br />
                  현재 보고 계신 관리자 웹에서 회원을 등록할 경우, 무인 출입 시스템과 연동되지 않습니다!
                </p>
              </div>
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>{isEntrance ? "다음" : "완료"}</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
        <AddEntrance
          machine={machine && machine.getMachine ? machine.getMachine : null}
          showModal={showAddEntranceModal}
          cancel={() => setShowAddEntranceModal(false)}
          addMemberType={"add"}
          member={member}
          start={start}
          end={end}
          membershipId={membership.id}
          setLoading={setLoading}
          addMember={addMemberMachine}
        />
      </div>
    </div>
  );
};

export default MemberAdd;
