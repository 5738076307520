import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const SiteProductDiscount = ({ product, removeDiscount, machine }) => {
    const history = useHistory();

    const start = new Date(Number(product.discountStart));
    const end = new Date(Number(product.discountEnd));

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex2} ${styles.center}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius5} ${styles.btnShadow} ${styles.bgImgContain}`}
                    style={{
                        backgroundImage: `url(${product.image})`,
                        width: 120,
                        height: 120,
                    }}></div>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.slot ? numberWithCommas(product.slot) : ''}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.isDiscount && product.discountPrice > 0
                        ? `${numberWithCommas(product.discountPrice)}원 할인`
                        : '미적용'}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                {product.isDiscount && product.discountPrice > 0 ? (
                    <div>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            style={{ textDecorationLine: 'line-through' }}>
                            {numberWithCommas(product.price)}원
                        </p>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>
                            {numberWithCommas(
                                product.price - product.discountPrice,
                            )}
                            원
                        </p>
                    </div>
                ) : (
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {numberWithCommas(product.price)}원
                    </p>
                )}
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.isDiscount && product.discountStart
                        ? `${start.getFullYear()}-${
                              start.getMonth() + 1 < 10
                                  ? '0' + String(start.getMonth() + 1)
                                  : start.getMonth() + 1
                          }-${
                              start.getDate() < 10
                                  ? '0' + String(start.getDate())
                                  : start.getDate()
                          }`
                        : ''}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.isDiscount && product.discountEnd
                        ? `${end.getFullYear()}-${
                              end.getMonth() + 1 < 10
                                  ? '0' + String(end.getMonth() + 1)
                                  : end.getMonth() + 1
                          }-${
                              end.getDate() < 10
                                  ? '0' + String(end.getDate())
                                  : end.getDate()
                          }`
                        : ''}
                </p>
            </div>

            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    onClick={removeDiscount}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        해제
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                    onClick={() =>
                        history.push({
                            pathname: `/site/product/discount/edit/`,
                            state: {
                                machine: machine.username,
                                product,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        관리
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SiteProductDiscount;
