export const PROFILE_ROUTE = ["/profile/edit/"];

export const DEVICE_ROUTE = ["/", "/device/add", "/device/find/", "/device/edit/", "/device/payment/statics/"];

export const SITE_ROUTE = [
  "/site/",
  "/site/product/list/",
  "/site/product/ordering/",
  "/site/category/ordering/",
  "/site/product/add/",
  "/site/product/edit/",
  "/site/product/discount/",
  "/site/product/discount/add/",
  "/site/product/discount/edit/",
  "/site/product/payment/status/",
  "/site/product/payment/statics/",
];

export const ORDER_ROUTE = [
  "/order/",
  "/order/product/list/",
  "/order/product/ordering/",
  "/order/category/ordering/",
  "/order/product/add/",
  "/order/product/edit/",
  "/order/product/discount/",
  "/order/product/discount/add/",
  "/order/product/discount/edit/",
  "/order/product/notice/",
  "/order/product/payment/status/",
  "/order/product/payment/detail/",
  "/order/product/payment/statics/",
];

export const MEMBERSHIP_ROUTE = [
  "/membership/",
  "/membership/product/list/",
  "/membership/product/ordering/",
  "/membership/category/ordering/",
  "/membership/product/add/",
  "/membership/product/edit/",
  "/membership/product/discount/",
  "/membership/product/discount/add/",
  "/membership/product/discount/edit/",
  "/membership/product/payment/status/",
  "/membership/product/payment/detail/",
  "/membership/product/payment/statics/",
];

export const LOCKER_ROUTE = [
  "/locker/",
  "/locker/list/",
  "/locker/add/",
  "/locker/edit/",
  "/locker/detail/",
  "/locker/product/list/",
  "/locker/product/add/",
  "/locker/product/edit/",
  "/locker/product/discount/",
  "/locker/product/discount/add/",
  "/locker/product/discount/edit/",
  "/locker/product/payment/status/",
];

export const CONSULT_ROUTE = ["/consult/", "/consult/product/list/", "/consult/product/edit/", "/consult/form/edit/", "/consult/category/edit/"];

export const CUSTOM_ROUTE = ["/custom/", "/custom/payment/status/", "/custom/payment/statics/", "/custom/payment/detail/"];

export const CASH_ROUTE = ["/cash/", "/cash/payment/status/", "/cash/payment/statics/", "/cash/payment/detail/", "/cash/payment/add/"];

export const CHECKIN_ROUTE = ["/checkin/", "/checkin/status/", "/checkin/statics/"];

export const USERS_ROUTE = ["/", "/user/edit/"];

export const ADVERTISE_ROUTE = ["/advertise/", "/advertise/add/", "/advertise/edit/"];

export const VIDEO_ROUTE = ["/video/", "/video/add/", "/video/edit/", "/video/category/edit/"];

export const MEMBER_ROUTE = ["/member/", "/member/list/", "/member/add/", "/member/edit/", "/member/statics/"];

export const ADMIN_DEVICE_ROUTE = ["/device/", "/device/add", "/device/find/", "/device/edit/", "/device/detail/", "/device/video/", "/member/list/", "/member/add/", "/member/edit/", "/entrance/edit/"];

export const CONTRACT_ROUTE = ["/contract/", "/contract/list/", "/contract/detail/", "/contract/terms/list/", "/contract/terms/add/", "/contract/terms/detail/"];

export const SCHEDULE_ROUTE = [
  "/schedule/",
  "/schedule/main/",
  "/schedule/add/",
  "/schedule/list/",
  "/schedule/membership/list/",
  "/teacher/main/",
  "/teacher/add/",
  "/teacher/edit/",
  "/category/main/",
  "/category/add/",
  "/category/edit/",
  "/holiday/main/",
  "/holiday/add/",
  "/holiday/edit/",
  "/category/edit/",
  "/schedule/reservation/list/",
  "/schedule/reservation/limit/",
  "/schedule/reservation/today_list/",
  "/schedule/reservation/search_list/",
];

export const NOTICE_ROUTE = ["/notice/", "/notice/main/", "/notice/add/", "/notice/edit/"];

export const MEMBER_RESERVATION_ROUTE = ["/member_reservation/marketing/"];

export const PROFIT_INFO_ROUTE = ["/profit_info/"];

export const POINT_ROUTE = ["/point/user/main/", "/point/user/add/", "/point/user/payment/"];

export const ENTRANCE_ROUTE = ["/entrance/", "/entrance/door/list/", "/entrance/door/log/"];
