import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg, emailReg } from '../../../common/regex';

const FIND = gql`
    mutation findPassword($username: String!, $mobile: String!) {
        findPassword(username: $username, mobile: $mobile)
    }
`;

const FindPassword = () => {
    const history = useHistory();

    const username = useInput('');
    const mobile = useInput('');

    const [loading, setLoading] = useState(false);

    const [findPasswordM] = useMutation(FIND, {
        fetchPolicy: 'no-cache',
    });

    const handleMobile = e => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value)) {
            mobile.onChange(e);
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (username.value && mobile.value) {
                if (emailReg.test(username.value)) {
                    setLoading(true);
                    const { data } = await findPasswordM({
                        variables: {
                            username: username.value,
                            mobile: mobile.value,
                        },
                    });
                    setLoading(false);
                    if (data && data.findPassword) {
                        history.push({
                            pathname: '/find/password/success/',
                            state: {
                                username: data.findPassword,
                            },
                        });
                    } else {
                        alert('일치하는 계정이 없습니다.');
                    }
                } else {
                    alert('올바른 형식의 이메일을 입력해주세요.');
                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
            <div
                className={`${styles.container} ${styles.alignItemsEnd} ${styles.justifyContentCenter} ${styles.overflowHidden}`}>
                <img
                    src={require('../../../assets/images/brothers.png').default}
                    alt={'VEN Brothers'}
                    style={{
                        maxWidth: 830,
                        position: 'absolute',
                        bottom: -190,
                        left: 180,
                    }}
                />
                <img
                    src={require('../../../assets/images/logo_lg.png').default}
                    alt={'VEN Brothers'}
                    style={{
                        maxWidth: 400,
                        position: 'absolute',
                        top: 80,
                        left: 95,
                    }}
                />
                <div
                    className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px30} ${styles.mr100} ${styles.pAuthBox} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font34} ${styles.black} ${styles.textCenter}`}>
                        비밀번호 찾기
                    </p>
                    <p
                        className={`${styles.fontM} ${styles.font18} ${styles.black} ${styles.textCenter} ${styles.mt10}`}>
                        계정이 있으신가요?{' '}
                        <span
                            className={`${styles.cursorPointer} ${styles.underline}`}
                            onClick={loading ? null : () => history.push('/')}>
                            로그인
                        </span>
                    </p>
                    <form
                        action={''}
                        className={`${styles.mt70}`}
                        onSubmit={submit}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px20} ${styles.py5} ${styles.containerInput}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                이메일
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 45 }}
                            />
                            <input
                                type={'email'}
                                value={username.value}
                                onChange={username.onChange}
                                placeholder={
                                    '가입하신 이메일 주소를 입력해주세요.'
                                }
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px20} ${styles.py5} ${styles.containerInput} ${styles.mt20}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                휴대폰 번호
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 45 }}
                            />
                            <input
                                type={'tel'}
                                value={mobile.value}
                                onChange={handleMobile}
                                placeholder={
                                    '가입하신 휴대폰 번호를 입력해주세요.'
                                }
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>
                        <button
                            type={'submit'}
                            className={`${styles.mt100} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull}`}
                            style={{ opacity: loading ? 0.4 : 1 }}>
                            <p
                                className={`${styles.fontB} ${styles.font18} ${styles.grayEf} ${styles.textCenter}`}>
                                계속
                            </p>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FindPassword;
