import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
            orderProductNotice
	        orderProductDeliveryNotice
        }
    }
`

const EDIT = gql`
    mutation editNotice($machine: String!, $orderProductNotice: String!, $orderProductDeliveryNotice: String!){
        editNotice(machine: $machine, orderProductNotice: $orderProductNotice, orderProductDeliveryNotice: $orderProductDeliveryNotice){
            id
            orderProductNotice
	        orderProductDeliveryNotice
        }
    }
`

const OrderProductNotice = () => {
    const history = useHistory()
    const location = useLocation()

    const productNotice = useInput("")
    const deliveryNotice = useInput("")

    const [loading, setLoading] = useState(false)

    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [editNoticeM] = useMutation(EDIT)

    const submit = async(e) => {
        e.preventDefault()
        if(!loading){
            setLoading(true)
            try{
                const { data:result } = await editNoticeM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        orderProductNotice: productNotice.value,
                        orderProductDeliveryNotice: deliveryNotice.value
                    }
                })
                setLoading(false)
                if(result && result.editNotice){
                    alert("안내 문구를 수정하였습니다.")
                }
                else{
                    alert("오류가 발생하였습니다.")
                }
            }
            catch{
                setLoading(false)
                alert("오류가 발생하였습니다.")
            }
        }
    }

    const initialize = () => {
        if(machine && machine.getMachine){
            productNotice.setValue(machine.getMachine.orderProductNotice)
            deliveryNotice.setValue(machine.getMachine.orderProductDeliveryNotice)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(machine && machine.getMachine){
            initialize()
        }
    }, [machine])

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`안내 문구 관리${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    주문 판매 상품 구매 시 주의사항 문구 (최대 150자)
                                </p>
                                <textarea 
                                name={"productNotice"}
                                value={productNotice.value}
                                onChange={productNotice.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} 
                                style={{ width: 1208, height: 120 }}
                                placeholder={"문구를 입력해주세요."}
                                maxLength={150}
                                />
                            </div>
                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    주문 판매 상품 수령 방법 안내 문구 (최대 150자)
                                </p>
                                <textarea 
                                name={"deliveryNotice"}
                                value={deliveryNotice.value}
                                onChange={deliveryNotice.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} 
                                style={{ width: 1208, height: 120 }}
                                placeholder={"문구를 입력해주세요."}
                                maxLength={150}
                                />
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb70}`} style={{ width: 1250, marginTop: 300 }}>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                                    <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderProductNotice;