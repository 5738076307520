import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { TOKEN_KEY } from "../../../common/constants";

import styles from "../../../styles/styles.module.scss";
import {
  ADMIN_DEVICE_ROUTE,
  ADVERTISE_ROUTE,
  CASH_ROUTE,
  CHECKIN_ROUTE,
  CONSULT_ROUTE,
  CONTRACT_ROUTE,
  CUSTOM_ROUTE,
  DEVICE_ROUTE,
  LOCKER_ROUTE,
  MEMBERSHIP_ROUTE,
  MEMBER_ROUTE,
  ORDER_ROUTE,
  SITE_ROUTE,
  USERS_ROUTE,
  VIDEO_ROUTE,
  SCHEDULE_ROUTE,
  NOTICE_ROUTE,
  MEMBER_RESERVATION_ROUTE,
  ENTRANCE_ROUTE,
} from "../../../common/urls";
import { useAuthContext } from "../../../context/Auth";
import { numberWithCommas } from "../../../common/utility";

const PROFILE = gql`
  {
    getProfileAdmin {
      id
      username
      name
      status
      superuser
    }
  }
`;

const POINT = gql`
  {
    getUserPointAdmin {
      id
      point
      balance
    }
  }
`;

const Navigation = () => {
  const location = useLocation();
  const history = useHistory();

  const AuthContext = useAuthContext();

  const { data, refetch: getUserData } = useQuery(PROFILE, {
    fetchPolicy: "cache-and-network",
  });

  const { data: point_data } = useQuery(POINT, {
    fetchPolicy: "cache-and-network",
  });

  const [signOutTxt, setSignOutTxt] = useState("");

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    let adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      setSignOutTxt("관리접속 해제");
    } else {
      setSignOutTxt("로그아웃");
    }
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    if (data && data.getProfileAdmin) {
      let isAdmin = localStorage.getItem("viaItem");
      if (data.getProfileAdmin.status === "expire" && !isAdmin) {
        localStorage.removeItem(TOKEN_KEY);
        AuthContext.setIsLoggedIn(false);
      }
    }
  }, [data]);

  const handleAuthChk = () => {
    getUserData();
  };

  const logout = () => {
    let adminToken = localStorage.getItem("adminToken");

    if (adminToken) {
      localStorage.removeItem("adminToken");
      AuthContext.login(adminToken, true);
      window.location.href = "/?page=1";
    } else {
      localStorage.removeItem("viaItem", false);
      AuthContext.logout();
    }
  };

  return (
    <div
      className={`${styles.bgWhite}`}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        minWidth: 375,
      }}
    >
      <div className={`${styles.row} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40}`} style={{ height: 85 }}>
        <img src={require("../../../assets/images/icon_menu.png").default} alt={"MENU"} style={{ width: 40 * 0.5, height: 34 * 0.5 }} className={`${styles.cursorPointer}`} onClick={() => setShowMenu(!showMenu)} />
        <img src={require("../../../assets/images/logo_orange.png").default} alt={"VEN Brothers"} className={`${styles.cursorPointer}`} style={{ width: 125 }} onClick={() => history.push("/?page=1")} />
        <img src={require("../../../assets/images/icon_back.png").default} alt={"BACK"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} onClick={history.goBack} />
      </div>
      <div className={`${styles.menu} ${showMenu ? styles.show : null} ${styles.bgWhite}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div className={`${styles.row} ${styles.justifyContentEnd} ${styles.px40} ${styles.py40}`}>
            <img src={require("../../../assets/images/icon_close_menu.png").default} alt={"CLOSE"} className={`${styles.cursorPointer} ${styles.icon20}`} onClick={() => setShowMenu(!showMenu)} />
          </div>
          <div
            className={`${styles.justifyContentBetween} ${styles.py40}`}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              flexGrow: 1,
            }}
          >
            <div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsEnd} ${styles.px50} ${styles.cursorPointer}`} onClick={() => history.push("/profile/edit/")}>
                <p className={`${styles.fontB} ${styles.font24} ${styles.black}`}>{data?.getProfileAdmin?.name} 님</p>
                <img src={require("../../../assets/images/icon_profile.png").default} alt={data?.getProfileAdmin?.name} className={`${styles.icon25} ${styles.ml20} ${styles.mb4}`} />
              </div>
              {data && data.getProfileAdmin && !data.getProfileAdmin.superuser && (
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsEnd} ${styles.mt10} ${styles.px50} ${styles.justifyContentBetween}`}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>{point_data && point_data.getUserPointAdmin && point_data.getUserPointAdmin.balance ? numberWithCommas(point_data.getUserPointAdmin.balance) : 0} P</p>
                  <p onClick={() => history.push(`/point/user/main?page=1&order=date_dsc`)} className={`${styles.font15} ${styles.black} ${styles.cursorPointer}`} style={{ textDecorationLine: "underline", letterSpacing: -0.72 }}>
                    포인트 조회/충전
                  </p>
                </div>
              )}
              {data && data.getProfileAdmin ? (
                data.getProfileAdmin.superuser ? (
                  <div className={`${styles.mt60}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push(`/?page=1&order=date_dsc&q=`)}>
                      <img src={USERS_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_users_orange.png").default : require("../../../assets/images/icon_users.png").default} alt={"관리자 계정 관리"} className={`${styles.icon20}`} />
                      <p className={`${USERS_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${USERS_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>관리자 계정 관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push(`/advertise/?page=1&order=name_asc&&q=`)}>
                      <img src={ADVERTISE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_advertise_orange.png").default : require("../../../assets/images/icon_advertise.png").default} alt={"광고 관리"} className={`${styles.icon20}`} />
                      <p className={`${ADVERTISE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${ADVERTISE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>광고 관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push(`/video/?page=1&order=name_asc&&q=`)}>
                      <img src={VIDEO_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_advertise_orange.png").default : require("../../../assets/images/icon_advertise.png").default} alt={"영상 관리"} className={`${styles.icon20}`} />
                      <p className={`${VIDEO_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${VIDEO_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>영상 관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push(`/device/?page=1`)}>
                      <img src={ADMIN_DEVICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_device_orange.png").default : require("../../../assets/images/icon_device.png").default} alt={"기기관리"} className={`${styles.icon20}`} />
                      <p className={`${ADMIN_DEVICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${ADMIN_DEVICE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>기기관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push("/member_reservation/marketing/")}>
                      <img src={MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_member_orange.png").default : require("../../../assets/images/icon_member.png").default} alt={"회원 관리"} className={`${styles.icon20}`} />
                      <p className={`${MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>회원 관리</p>
                    </div>
                    {/* <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push(`/video/?page=1&e=${`${date.getFullYear()}`}`)}>
                                            <img src={VIDEO_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_video_orange.png").default : require("../../../assets/images/icon_video.png").default} alt={"영상 관리"} className={`${styles.icon20}`} />
                                            <p className={`${VIDEO_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${VIDEO_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>
                                                영상 관리
                                            </p>
                                        </div> */}
                  </div>
                ) : (
                  <div className={`${styles.mt60}`}>
                    <div className={`${styles.px25} ${styles.mt35} ${styles.mb10}`}>
                      <p className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.ml20}`}>키오스크</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/?page=1`);
                      }}
                    >
                      <img src={DEVICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_device_orange.png").default : require("../../../assets/images/icon_device.png").default} alt={"기기관리"} className={`${styles.icon20}`} />
                      <p className={`${DEVICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${DEVICE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>기기관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/site/?page=1`);
                      }}
                    >
                      <img src={SITE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_site_orange.png").default : require("../../../assets/images/icon_site.png").default} alt={"현장 판매 상품 관리"} className={`${styles.icon20}`} />
                      <p className={`${SITE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${SITE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>현장 판매 상품 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/order/?page=1&tpage=1&order=date_dsc`);
                      }}
                    >
                      <img src={ORDER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_order_orange.png").default : require("../../../assets/images/icon_order.png").default} alt={"주문 판매 상품 관리"} className={`${styles.icon20}`} />
                      <p className={`${ORDER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${ORDER_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>주문 판매 상품 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/membership/?page=1`);
                      }}
                    >
                      <img src={MEMBERSHIP_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_membership_orange.png").default : require("../../../assets/images/icon_membership.png").default} alt={"회원권 관리"} className={`${styles.icon20}`} />
                      <p className={`${MEMBERSHIP_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${MEMBERSHIP_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>회원권 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/locker/?page=1&tpage=1&order=date_dsc`);
                      }}
                    >
                      <img src={LOCKER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_membership_orange.png").default : require("../../../assets/images/icon_membership.png").default} alt={"락커 관리"} className={`${styles.icon20}`} />
                      <p className={`${LOCKER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${LOCKER_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>락커 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/consult/?page=1&tpage=1&order=date_dsc");
                      }}
                    >
                      <img src={CONSULT_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_pt_orange.png").default : require("../../../assets/images/icon_pt.png").default} alt={"상담 관리"} className={`${styles.icon20}`} />
                      <p className={`${CONSULT_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${CONSULT_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>상담 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/custom/?page=1");
                      }}
                    >
                      <img src={CUSTOM_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_custom_payment_orange.png").default : require("../../../assets/images/icon_custom_payment.png").default} alt={"자유 결제 관리"} className={`${styles.icon20}`} />
                      <p className={`${CUSTOM_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${CUSTOM_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>자유 결제 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/cash/?page=1");
                      }}
                    >
                      <img src={CASH_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_cash_receipt_orange.png").default : require("../../../assets/images/icon_cash_receipt.png").default} alt={"현금 결제 관리"} className={`${styles.icon20}`} />
                      <p className={`${CASH_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${CASH_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>현금 결제 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/checkin/?page=1");
                      }}
                    >
                      <img src={CHECKIN_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_checkin_orange.png").default : require("../../../assets/images/icon_checkin.png").default} alt={"출석 관리"} className={`${styles.icon20}`} />
                      <p className={`${CHECKIN_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${CHECKIN_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>출석 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/member/?page=1&tpage=1&order=end_asc");
                      }}
                    >
                      <img src={MEMBER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_member_orange.png").default : require("../../../assets/images/icon_member.png").default} alt={"회원 관리"} className={`${styles.icon20}`} />
                      <p className={`${MEMBER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${MEMBER_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>회원 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/contract/?page=1&tpage=1&order=date_dsc");
                      }}
                    >
                      <img src={CONTRACT_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_terms_orange.png").default : require("../../../assets/images/icon_terms.png").default} alt={"약관 관리"} className={`${styles.icon20}`} />
                      <p className={`${CONTRACT_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${CONTRACT_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>약관 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/entrance/?page=1`);
                      }}
                    >
                      <img src={ENTRANCE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_entrance_orange.png").default : require("../../../assets/images/icon_entrance.png").default} alt={"출입 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${ENTRANCE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${ENTRANCE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>출입 관리</p>
                    </div>
                    <div className={`${styles.px25} ${styles.mt35} ${styles.mb10}`}>
                      <p className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.ml20}`}>스케쥴앱</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push("/schedule/?page=1")}>
                      <img src={SCHEDULE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_schedule_orange.png").default : require("../../../assets/images/icon_schedule.png").default} alt={"스케쥴 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${SCHEDULE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${SCHEDULE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>스케쥴 관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.py20} ${styles.cursorPointer}`} onClick={() => history.push("/notice/?page=1")}>
                      <img src={NOTICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_notice_orange.png").default : require("../../../assets/images/icon_notice.png").default} alt={"공지사항 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${NOTICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font18} ${NOTICE_ROUTE.includes(location.pathname) ? styles.orange : styles.black} ${styles.ml20}`}>공지사항 관리</p>
                    </div>
                  </div>
                )
              ) : null}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr50} ${styles.pl30} ${styles.mt30} ${styles.cursorPointer} ${data && data.getProfileAdmin.superuser ? styles.none : styles.show}`}>
              <p className={`${styles.fontM} ${styles.font18} ${styles.black} ${styles.ml20}`}>
                <a style={{ textDecoration: "underline", color: "black" }} target={"_blank"} href="https://app.catchsecu.com/document/P/e025b657ce8369f">
                  개인정보 처리방침
                </a>
              </p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px50} ${styles.mt30} ${styles.cursorPointer}`} onClick={logout}>
              <img src={require("../../../assets/images/icon_logout.png").default} alt={"로그아웃"} className={`${styles.icon20}`} />
              <p className={`${styles.fontM} ${styles.font18} ${styles.black} ${styles.ml20}`}>{signOutTxt}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
