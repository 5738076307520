import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';
import { bill } from '../../../common/modal';
import Bill from '../../Global/Bill';

const CashPayment = ({ payment, machine }) => {
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.borderBottomGrayF4}`}>
            <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.cursorPointer}`}
                style={{ flex: 8 }}
                onClick={() =>
                    history.push({
                        pathname: '/cash/payment/detail/',
                        state: {
                            payment,
                            machine,
                        },
                    })
                }>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {`${payment.approvalDate.slice(
                            0,
                            2,
                        )}/${payment.approvalDate.slice(
                            2,
                            4,
                        )}/${payment.approvalDate.slice(
                            4,
                            6,
                        )} - ${payment.approvalTime.slice(
                            0,
                            2,
                        )}:${payment.approvalTime.slice(
                            2,
                            4,
                        )}:${payment.approvalTime.slice(4, 6)}`}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${
                            payment.userName ? styles.black : styles.grayA2
                        } ${styles.textCenter}`}>
                        {payment.userName ? payment.userName : '입력'}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${
                            payment.userContact ? styles.black : styles.grayA2
                        } ${styles.textCenter}`}>
                        {payment.userContact ? payment.userContact : '입력'}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${
                            payment.productName ? styles.black : styles.grayA2
                        } ${styles.textCenter}`}>
                        {payment.productName ? payment.productName : '입력'}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                        style={
                            payment.status === 'refund'
                                ? { textDecorationLine: 'line-through' }
                                : null
                        }>
                        {numberWithCommas(payment.totalAmount)}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {`${payment.issuerInfo ? payment.issuerInfo : ''}(${
                            payment.installment === 0
                                ? '일시불'
                                : `${payment.installment}개월`
                        })`}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline}`}>
                        {payment.status === 'paid' && '결제완료'}
                        {payment.status === 'cancelled' && '결제취소'}
                        {payment.status === 'refund' && '환불'}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${
                            payment.extra ? styles.black : styles.grayA2
                        } ${styles.textCenter}`}>
                        {payment.extra ? payment.extra : '입력'}
                    </p>
                </div>
            </div>
            <div className={`${styles.flex1}`}>
                {payment.approvalNo ? (
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                        onClick={() => setShowModal(!showModal)}>
                        영수증
                    </p>
                ) : null}
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={bill}>
                <Bill payment={payment} />
            </Modal>
        </div>
    );
};

export default CashPayment;
