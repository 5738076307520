import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";

const LOCKERLIST = gql`
  query getLockerList($lockerAreaIdStr: String!) {
    getLockerList(lockerAreaIdStr: $lockerAreaIdStr) {
      lockerArea {
        name
      }
      lockerTotal
      lockerAvailableTotal
    }
  }
`;

const LockerProduct = ({ product, remove, machine }) => {
  const history = useHistory();

  const { data: lockerList } = useQuery(LOCKERLIST, {
    fetchPolicy: "cache-and-network",
    variables: {
      lockerAreaIdStr: product.lockerAreaIdStr,
    },
  });

  useEffect(() => {
    console.log(JSON.stringify(lockerList));
  }, [lockerList]);

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex1} ${styles.pr10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>{product.name}</p>
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: `/locker/product/edit/`,
              state: {
                machine: machine.username,
                product,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
        </div>
        <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml20}`} onClick={remove} />
      </div>
    </div>
  );
};

export default LockerProduct;
