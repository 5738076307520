import React from 'react';

import styles from '../../../styles/styles.module.scss';

const SiteOrdering = ({
    product,
}) => {
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {product.name}
                </p>
            </div>
        </div>
    )
}

export default SiteOrdering;