import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import styles from '../../../styles/styles.module.scss';
import useWindowDimensions from '../../../hooks/useWindowSize';

const ContractTermDetail = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const history = useHistory()
    const location = useLocation()

    const terms = location.state.terms

    const { width } = useWindowDimensions()

    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);

    const onLoadSuccess = ({ numPages }) => {
        setTotal(numPages);
    }

    const handlePage = (page) => {
        if((page > 0) && (total >= page)){
            setPage(page)
        }
    }

    useEffect(() => {
        if(!location.state.terms){
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {terms.name}
                    </p>
                    <div className={`${styles.mt40} ${styles.px20} ${styles.center}`}>
                        <Document
                        file={terms.file}
                        onLoadSuccess={onLoadSuccess}
                        externalLinkTarget={"_blank"}
                        >
                            <Page
                            pageNumber={page}
                            width={(width > 375) ? width - 70 : 305}
                            />
                        </Document>
                        {total ? (
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my10} `}>
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer} ${styles.mx20} ${(page <= 1) ? styles.hidden : ""}`} style={{ width: 45*0.2, height: 79*0.2 }} onClick={() => handlePage(page - 1)} />
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    Page {page} of {total}
                                </p>
                                <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer} ${styles.mx20} ${(page >= total) ? styles.hidden : ""}`} style={{ width: 45*0.2, height: 79*0.2 }} onClick={() => handlePage(page + 1)} />
                            </div>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractTermDetail;
