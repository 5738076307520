import React from 'react';

import styles from '../../../styles/styles.module.scss';

import {setDateFormat_yyyyMMdd, setDateFormatKor_aahhmm} from "../../../common/utility";

const ScheduleMainDetailItem = ({appointmentData, toScheduleList, toReservationList}) => (
    <div className={`${styles.px18} ${styles.pb12} ${styles.textCenter}`}>
        <p className={`${styles.font18} ${styles.fontB} ${styles.black} ${styles.mb16}`}>
            {appointmentData.scheduleName}
        </p>
        <p className={`${styles.font16} ${styles.fontM} ${styles.black} ${styles.mb10}`}>
            {appointmentData.teacherName}
        </p>
        <p className={`${styles.font14} ${styles.fontM} ${styles.black} ${styles.mb16}`}>
            {`${setDateFormat_yyyyMMdd(appointmentData.startDate, "/")} `}
            {`${setDateFormatKor_aahhmm(appointmentData.startDate)} ~ `}
            {`${setDateFormatKor_aahhmm(appointmentData.endDate)}`}
        </p>
        <p className={`${styles.font14} ${styles.fontM} ${styles.black} ${styles.mb35}`}>
            {`${appointmentData.reservationCnt ? appointmentData.reservationCnt : 0}/${appointmentData.limit ? appointmentData.limit : 0}명`}
        </p>
        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer}`}
             onClick={() => {
                 toScheduleList(appointmentData.id);
             }}>
            <p
               className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>
                스케쥴 관리
            </p>
        </div>
        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.my12} ${styles.px20} ${styles.py7} ${styles.cursorPointer}`}
             onClick={() => {
                 toReservationList(appointmentData.id);
             }}>
            <p
               className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>
                예약 관리
            </p>
        </div>
    </div>
);

export default ScheduleMainDetailItem;
