import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { SketchPicker } from "react-color";
import DaumPostcode from "react-daum-postcode";
import NumberFormat from "react-number-format";
import Switch from "react-switch";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { sleep } from "../../../common/utility";
import IntroImage from "../../../components/Home/IntroImage";
import { emailReg, numberReg } from "../../../common/regex";
import { FETCH_URL } from "../../../config";

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      contact
      email
      address
      extraAddress
      addressType
      sido
      sigungu
      bname
      name
      logo
      status
      businessNo
      catId
      extraBusinessNo
      extraCatId
      color
      entranceSystem
      entranceIp
      entranceCode
      entranceMachine
      notice
      intro
      installmentPrice
      installmentPeriod
      createdAt
      images {
        id
        image
      }
      talkContact
      talkPayment
      talkStock
      talkConsult
      talkCustomPayment
      usePgBill
      receiveBill
      useReviewTalk
      appType
      useSchedule
    }
  }
`;

const EDIT = gql`
  mutation editMachineAdmin(
    $username: String!
    $password: String
    $name: String!
    $status: String!
    $businessNo: String
    $catId: String
    $extraBusinessNo: String
    $extraCatId: String
    $logo: String
    $color: String
    #        $entranceSystem: String!
    #        $entranceIp: String
    #        $entranceCode: String
    #        $entranceMachine: String
    $contact: String!
    $email: String!
    $address: String!
    $addressType: String!
    $sido: String!
    $sigungu: String!
    $bname: String!
    $extraAddress: String!
    $installmentPrice: Int!
    $installmentPeriod: String
    $notice: String
    $intro: String
    $images: String!
    $talkContact: String
    $talkPayment: Boolean!
    $talkStock: Boolean!
    $talkConsult: Boolean!
    $talkCustomPayment: Boolean!
    $usePgBill: Boolean!
    $receiveBill: String!
    $useReviewTalk: Boolean!
    $appType: String!
    $useSchedule: Boolean
  ) {
    editMachineAdmin(
      username: $username
      password: $password
      name: $name
      status: $status
      businessNo: $businessNo
      catId: $catId
      extraBusinessNo: $extraBusinessNo
      extraCatId: $extraCatId
      logo: $logo
      color: $color
      #            entranceSystem: $entranceSystem
      #            entranceIp: $entranceIp
      #            entranceCode: $entranceCode
      #            entranceMachine: $entranceMachine
      contact: $contact
      email: $email
      address: $address
      addressType: $addressType
      sido: $sido
      sigungu: $sigungu
      bname: $bname
      extraAddress: $extraAddress
      installmentPrice: $installmentPrice
      installmentPeriod: $installmentPeriod
      notice: $notice
      intro: $intro
      images: $images
      talkContact: $talkContact
      talkPayment: $talkPayment
      talkStock: $talkStock
      talkConsult: $talkConsult
      talkCustomPayment: $talkCustomPayment
      usePgBill: $usePgBill
      receiveBill: $receiveBill
      useReviewTalk: $useReviewTalk
      appType: $appType
      useSchedule: $useSchedule
    ) {
      machine {
        id
        username
        contact
        email
        address
        extraAddress
        addressType
        sido
        sigungu
        bname
        name
        logo
        status
        businessNo
        catId
        extraBusinessNo
        extraCatId
        color
        entranceSystem
        entranceIp
        entranceCode
        entranceMachine
        installmentPrice
        installmentPeriod
        notice
        intro
        createdAt
        images {
          id
          image
        }
        talkContact
        talkPayment
        talkStock
        talkConsult
        talkCustomPayment
        usePgBill
        receiveBill
        useReviewTalk
        appType
        useSchedule
      }
      error
    }
  }
`;

const DELETE = gql`
  mutation deleteMachine($machine: String!) {
    deleteMachine(machine: $machine)
  }
`;

const ENTRANCE_MACHINES = [
  {
    name: "미사용",
    value: "none",
    systems: [
      {
        name: "미사용",
        value: "none",
      },
    ],
  },
  {
    name: "N2",
    value: "N2",
    systems: [
      {
        name: "지문 인증",
        value: "F",
      },
      {
        name: "카드 인증",
        value: "C",
      },
    ],
  },
  {
    name: "F2",
    value: "F2",
    systems: [
      {
        name: "카드 인증",
        value: "C",
      },
      {
        name: "얼굴 인증",
        value: "M",
      },
    ],
  },
];

const DeviceDetail = () => {
  const history = useHistory();
  const location = useLocation();

  const password = useInput("");
  const name = useInput("");
  const contact = useInput("");
  const email = useInput("");
  const extraAddress = useInput("");
  const installmentPrice = useInput("");
  const installmentPeriod = useInput("");
  const notice = useInput("");
  const intro = useInput("");
  const businessNo = useInput("");
  const catId = useInput("");
  const extraBusinessNo = useInput("");
  const extraCatId = useInput("");
  const entranceIp = useInput("");
  const entranceCode = useInput("");
  const talkContact = useInput("");

  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [uploadLogo, setUploadLogo] = useState(null);
  const [showLogo, setShowLogo] = useState(false);
  const [color, setColor] = useState("");
  const [showColor, setShowColor] = useState(false);
  const [entranceSystem, setEntranceSystem] = useState("none");
  const [showEntranceSystem, setShowEntranceSystem] = useState(false);
  const [entranceMachine, setEntranceMachine] = useState("none");
  const [showEntranceMachine, setShowEntranceMachine] = useState(false);
  const [address, setAddress] = useState({
    address: "",
    addressType: "",
    sido: "",
    sigungu: "",
    bname: "",
  });
  const [showAddress, setShowAddress] = useState(false);
  const [showDaum, setShowDaum] = useState(false);
  const [showTalk, setShowTalk] = useState(false);
  const [talks, setTalks] = useState([]);
  const [usePgBill, setUsePgBill] = useState(false);
  const [showUsePgBill, setShowUsePgBill] = useState(false);
  const [receiveBill, setReceiveBill] = useState("email");
  const [showReceiveBill, setShowReceiveBill] = useState(false);
  const [useReviewTalk, setUseReviewTalk] = useState(false);
  const [showUseReviewTalk, setShowUseReviewTalk] = useState(false);
  const [appType, setAppType] = useState(null);
  const [showAppType, setShowAppType] = useState(false);
  const [useSchedule, setUseSchedule] = useState(false);
  const [showUseSchedule, setShowUseSchedule] = useState(false);

  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [editMachineAdminM] = useMutation(EDIT);
  const [deleteMachineM] = useMutation(DELETE);

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const handleBusinessNo = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      businessNo.onChange(e);
    }
  };

  const handleExtraBusinessNo = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      extraBusinessNo.onChange(e);
    }
  };

  const handleEntranceSystem = (entranceSystem) => {
    setEntranceSystem(entranceSystem);
    setShowEntranceSystem(false);
  };

  const handleEntranceMachine = (entranceMachine) => {
    setEntranceMachine(entranceMachine);
    setShowEntranceMachine(false);
  };

  const handleCatId = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      catId.onChange(e);
    }
  };

  const handleExtraCatId = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      extraCatId.onChange(e);
    }
  };

  const handleLogo = (logo) => {
    setLogo(logo);
    setUploadLogo(null);
    setShowLogo(false);
  };

  const handleColor = (color) => {
    setColor(color.hex);
    setShowColor(false);
  };

  const handleContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      contact.onChange(e);
    }
  };

  const handleInstallmentPrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      installmentPrice.setValue(value);
    }
  };

  const handleInstallmentPeriod = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      installmentPeriod.onChange(e);
    }
  };

  const handleAddress = (data) => {
    setAddress({
      address: data.address,
      addressType: data.addressType,
      sido: data.sido,
      sigungu: data.sigungu,
      bname: data.bname,
    });
    setShowDaum(false);
  };

  const handleTalkContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      talkContact.onChange(e);
    }
  };

  const handleTalks = (talk) => {
    const findIndex = talks.findIndex((t) => t === talk);
    if (findIndex > -1) {
      let newTalks = [...talks];
      newTalks.splice(findIndex, 1);
      setTalks(newTalks);
    } else {
      setTalks([...talks, talk]);
    }
  };

  const handleUsePgBill = (usePgBill) => {
    setUsePgBill(usePgBill);
    setShowUsePgBill(false);
  };

  const handleReceiveBill = (receiveBill) => {
    setReceiveBill(receiveBill);
    setShowReceiveBill(false);
  };

  const handleUseReviewTalk = (userReviewTalk) => {
    setUseReviewTalk(userReviewTalk);
    setShowUseReviewTalk(false);
  };

  const handleUseSchedule = (userSchedule) => {
    setUseSchedule(userSchedule);
    setShowUseSchedule(false);
  };

  const handleAppType = (appType) => {
    setAppType(appType);
    setShowAppType(false);
  };

  const addLogo = async (e) => {
    if (!loadingImage) {
      const files = e.target.files;

      let error = false;
      if (files[0].type === "image/jpg" || files[0].type === "image/jpeg" || files[0].type === "image/png") {
      } else {
        error = true;
      }
      if (error) {
        alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
      } else {
        setLoadingImage(true);
        let file = files[0];
        setLogo(file.name);
        setUploadLogo(file);
        setLoadingImage(false);
        setShowLogo(false);
      }
    }
  };

  const addImage = async (e) => {
    if (!loadingImage) {
      const files = e.target.files;

      if (images.length + files.length > 5) {
        alert("최대 5장의 사진을 선택하실 수 있습니다.");
      } else {
        var _URL = window.URL || window.webkitURL;
        let error = false;
        for (var i = 0; i < files.length; i++) {
          if (files[i].type === "image/jpg" || files[i].type === "image/jpeg" || files[i].type === "image/png") {
          } else {
            error = true;
          }
        }
        if (error) {
          alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
        } else {
          setLoadingImage(true);
          let newImages = [...images];
          let newUploadImages = [...uploadImages];
          for (var i = 0; i < files.length; i++) {
            let file = files[i];
            newUploadImages[i + images.length] = file;
            var img = new Image();
            var reader = new FileReader();
            reader.onloadend = () => {
              newImages[i + images.length] = {
                image: reader.result,
              };
            };
            img.src = await _URL.createObjectURL(file);
            await reader.readAsDataURL(file);
            while (true) {
              if (newImages[i + images.length]) {
                if (newImages[i + images.length].image === reader.result) {
                  break;
                }
              }
              await sleep(500);
            }
          }
          setImages(newImages);
          setUploadImages(newUploadImages);
          setLoadingImage(false);
        }
      }
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    const newUploadImages = [...uploadImages];
    newImages.splice(index, 1);
    newUploadImages.splice(index, 1);
    setImages(newImages);
    setUploadImages(newUploadImages);
  };

  console.log("data.getMachine:::", data);

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getMachine) {
        if (name.value && status && contact.value && email.value && address.address && extraAddress.value) {
          if (emailReg.test(email.value)) {
            if (businessNo.value && businessNo.value.length !== 10) {
              alert("올바른 사업자번호를 입력해주세요.");
              return;
            }
            if (extraBusinessNo.value && extraBusinessNo.value.length !== 10) {
              alert("올바른 사업자번호를 입력해주세요.");
              return;
            }
            // if (
            //     entranceMachine !== 'none' &&
            //     !(
            //         entranceIp.value &&
            //         entranceCode.value &&
            //         entranceSystem !== 'none'
            //     )
            // ) {
            //     alert('출입관리 정보를 입력해주세요.');
            //     return;
            // }
            // const machineIndex = ENTRANCE_MACHINES.findIndex(
            //     m => m.value === entranceMachine,
            // );
            // if (machineIndex < 0) {
            //     alert('사용할 수 없는 인증 기기입니다.');
            //     return;
            // }
            // if (
            //     ENTRANCE_MACHINES[machineIndex].systems.findIndex(
            //         s => s.value === entranceSystem,
            //     ) < 0
            // ) {
            //     alert(
            //         '선택한 인증 기기에서 사용할 수 없는 인증수단입니다.',
            //     );
            //     return;
            // }

            if (!appType) {
              alert("어플 타입을 선택해주세요.");
              return;
            }

            setLoading(true);
            try {
              let saveLogo = logo;
              if (uploadLogo) {
                const logoData = new FormData();
                const resizedImage = await resizeImage(uploadLogo);
                logoData.append("logo", resizedImage, uploadLogo.name);
                const { data } = await axios.post(`${FETCH_URL}/api/upload`, logoData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                if (data && data.location) {
                  saveLogo = data.location;
                }
              }
              let saveImages = [];
              let postImages = [];
              if (uploadImages && uploadImages.length > 0) {
                let index = 0;
                while (index < uploadImages.length) {
                  if (!uploadImages[index].id) {
                    postImages.push(uploadImages[index]);
                  }
                  index += 1;
                }
                if (postImages.length > 0) {
                  const formData = new FormData();
                  let reIndex = 0;
                  while (reIndex < postImages.length) {
                    if (!postImages[reIndex].id) {
                      const resizedImage = await resizeImage(postImages[reIndex]);
                      formData.append("introimage[]", resizedImage, postImages[reIndex].name);
                    }
                    reIndex += 1;
                  }
                  const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  if (data && data.locations) {
                    let locationIndex = 0;
                    let loopIndex = 0;
                    while (loopIndex < uploadImages.length) {
                      if (uploadImages[loopIndex].id) {
                        saveImages.push(uploadImages[loopIndex].image);
                      } else {
                        saveImages.push(data.locations[locationIndex]);
                        locationIndex += 1;
                      }
                      loopIndex += 1;
                    }
                  }
                } else {
                  let loopIndex = 0;
                  while (loopIndex < uploadImages.length) {
                    saveImages.push(uploadImages[loopIndex].image);
                    loopIndex += 1;
                  }
                }
              }

              console.log("useSchedule::::", useSchedule);
              if (saveImages.length === uploadImages.length) {
                const { data: result } = await editMachineAdminM({
                  variables: {
                    username: data.getMachine.username,
                    password: password.value,
                    name: name.value,
                    status,
                    businessNo: businessNo.value,
                    catId: catId.value,
                    extraBusinessNo: extraBusinessNo.value,
                    extraCatId: extraCatId.value,
                    logo: saveLogo,
                    color,
                    // entranceSystem,
                    // entranceIp: entranceIp.value,
                    // entranceCode: entranceCode.value,
                    // entranceMachine,
                    contact: contact.value,
                    email: email.value,
                    address: address.address,
                    addressType: address.addressType,
                    sido: address.sido,
                    sigungu: address.sigungu,
                    bname: address.bname,
                    extraAddress: extraAddress.value,
                    installmentPrice: Number(installmentPrice.value),
                    installmentPeriod: installmentPeriod.value,
                    notice: notice.value,
                    intro: intro.value,
                    images: JSON.stringify(saveImages),
                    talkContact: talkContact.value,
                    talkPayment: talks.findIndex((t) => t === "payment") > -1,
                    talkStock: talks.findIndex((t) => t === "stock") > -1,
                    talkConsult: talks.findIndex((t) => t === "consult") > -1,
                    talkCustomPayment: talks.findIndex((t) => t === "custom_payment") > -1,
                    usePgBill,
                    receiveBill,
                    useReviewTalk,
                    useSchedule,
                    appType,
                  },
                });
                if (result && result.editMachineAdmin) {
                  if (result.editMachineAdmin.error) {
                    setLoading(false);
                    alert(result.editMachineAdmin.error);
                  } else {
                    setLoading(false);
                    alert("기기 정보를 수정하였습니다.");
                  }
                } else {
                  setLoading(false);
                  alert("오류가 발생하였습니다.");
                }
              } else {
                setLoading(false);
                alert("오류가 발생하였습니다.");
              }
            } catch {
              setLoading(false);
              alert("오류가 발생하였습니다.");
            }
          } else {
            alert("올바른 형식의 이메일을 입력해주세요.");
          }
        } else {
          alert("위의 정보를 입력해주세요.");
        }
      }
    }
  };

  const deleteMachine = async () => {
    if (!loading) {
      if (data && data.getMachine) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data: result } = await deleteMachineM({
            variables: {
              machine: data.getMachine.username,
            },
          });
          setLoading(false);
          if (result && result.deleteMachine) {
            history.goBack();
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newImages = reorder(images, result.source.index, result.destination.index);
    const newUploadImages = reorder(uploadImages, result.source.index, result.destination.index);

    setImages(newImages);
    setUploadImages(newUploadImages);
  };

  const initialize = () => {
    if (data) {
      if (data.getMachine) {
        name.setValue(data.getMachine.name);
        contact.setValue(data.getMachine.contact);
        email.setValue(data.getMachine.email);
        extraAddress.setValue(data.getMachine.extraAddress);
        installmentPrice.setValue(String(data.getMachine.installmentPrice));
        installmentPeriod.setValue(String(data.getMachine.installmentPeriod));
        notice.setValue(data.getMachine.notice);
        intro.setValue(data.getMachine.intro);
        businessNo.setValue(data.getMachine.businessNo ? data.getMachine.businessNo : "");
        catId.setValue(data.getMachine.catId ? data.getMachine.catId : "");
        extraBusinessNo.setValue(data.getMachine.extraBusinessNo ? data.getMachine.extraBusinessNo : "");
        extraCatId.setValue(data.getMachine.extraCatId ? data.getMachine.extraCatId : "");
        entranceIp.setValue(data.getMachine.entranceIp ? data.getMachine.entranceIp : "");
        entranceCode.setValue(data.getMachine.entranceCode ? data.getMachine.entranceCode : "");
        talkContact.setValue(data.getMachine.talkContact ? data.getMachine.talkContact : "");
        setStatus(data.getMachine.status);
        setShowStatus(false);
        setLogo(data.getMachine.logo);
        setShowLogo(false);
        setColor(data.getMachine.color);
        setShowColor(false);
        setEntranceSystem(data.getMachine.entranceSystem);
        setShowEntranceSystem(false);
        setEntranceMachine(data.getMachine.entranceMachine ? data.getMachine.entranceMachine : "none");
        setShowEntranceMachine(false);
        setAddress({
          address: data.getMachine.address,
          addressType: data.getMachine.addressType,
          sido: data.getMachine.sido,
          sigungu: data.getMachine.sigungu,
          bname: data.getMachine.bname,
        });
        setUsePgBill(data.getMachine.usePgBill);
        setShowUsePgBill(false);
        setReceiveBill(data.getMachine.receiveBill);
        setShowReceiveBill(false);
        setUseReviewTalk(data.getMachine.useReviewTalk);
        setShowUseReviewTalk(false);
        setUseSchedule(data.getMachine.useSchedule);
        setShowUseSchedule(false);
        setAppType(data.getMachine.appType);
        setShowAppType(false);
        setImages(data.getMachine.images);
        setUploadImages(data.getMachine.images);
        let talks = [];
        if (data.getMachine.talkPayment) {
          talks.push("payment");
        }
        if (data.getMachine.talkStock) {
          talks.push("stock");
        }
        if (data.getMachine.talkConsult) {
          talks.push("consult");
        }
        if (data.getMachine.talkCustomPayment) {
          talks.push("custom_payment");
        }
        setTalks(talks);
      } else {
        alert("존재하지 않는 기기입니다.");
        history.goBack();
      }
    }
  };

  const checkEntranceSystem = () => {
    const machineIndex = ENTRANCE_MACHINES.findIndex((m) => m.value === entranceMachine);
    if (machineIndex > -1) {
      if (ENTRANCE_MACHINES[machineIndex].systems.findIndex((s) => s.value === entranceSystem) < 0) {
        setEntranceSystem(ENTRANCE_MACHINES[machineIndex].systems[0].value);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, [data]);

  useEffect(() => {
    checkEntranceSystem();
  }, [entranceMachine]);

  useEffect(() => {
    setShowDaum(false);
  }, [showAddress]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                기기관리
                {`${data && data.getMachine ? ` (${data.getMachine.name})` : ""}`}
              </p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div style={{ width: 1250 }}>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 계정*</p>
                  </div>
                  <input type={"text"} value={data && data.getMachine ? `${data.getMachine.username} (변경불가)` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 계정 비밀번호</p>
                  </div>
                  <input type={"password"} name={"password"} value={password.value} onChange={password.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"********"} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기명*</p>
                  </div>
                  <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"사용하실 기기명을 입력해주세요."} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showStatus ? 0 : 10,
                      borderBottomRightRadius: showStatus ? 0 : 10,
                    }}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상태*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${status ? styles.black : styles.grayA2}`}>
                        {status === "inuse" && "사용중"}
                        {status === "notuse" && "미사용"}
                        {status === "check" && "점검중"}
                        {status === "" && "기기 상태를 선택해주세요."}
                      </p>
                      <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showStatus && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("inuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>사용중</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("notuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>미사용</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("check")}>
                            <p className={`${styles.font16} ${styles.black}`}>점검중</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>사업자번호</p>
                  </div>
                  <input type={"tel"} name={"businessNo"} value={businessNo.value} onChange={handleBusinessNo} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"기기의 사업자번호를 입력해주세요."} maxLength={10} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>단말기번호</p>
                  </div>
                  <input type={"tel"} name={"catId"} value={catId.value} onChange={handleCatId} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"기기의 단말기번호를 입력해주세요."} maxLength={10} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>추가 사업자번호</p>
                  </div>
                  <input type={"tel"} name={"extraBusinessNo"} value={extraBusinessNo.value} onChange={handleExtraBusinessNo} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"기기의 추가 사업자번호를 입력해주세요."} maxLength={10} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>추가 단말기번호</p>
                  </div>
                  <input type={"tel"} name={"extraCatId"} value={extraCatId.value} onChange={handleExtraCatId} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"기기의 추가 단말기번호를 입력해주세요."} maxLength={10} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showLogo ? 0 : 10,
                      borderBottomRightRadius: showLogo ? 0 : 10,
                    }}
                    onClick={() => setShowLogo(!showLogo)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 로고</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${logo ? styles.black : styles.grayA2} ${styles.flex1}`}>
                        {logo === "" ? "사용하실 기기 로고를 선택해주세요." : logo === "default" ? "브라더스 로고" : logo.startsWith("https://venbrothers.s3.ap-northeast-2.amazonaws.com/") ? logo.replace("https://venbrothers.s3.ap-northeast-2.amazonaws.com/", "") : logo}
                      </p>
                      <img src={showLogo ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showLogo && (
                    <div
                      className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                      style={{
                        width: 615,
                        opacity: loadingImage ? 0.4 : 1,
                      }}
                    >
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleLogo("default")}>
                            <p className={`${styles.font16} ${styles.black}`}>브라더스 로고</p>
                          </div>
                          <label htmlFor="logo">
                            <div className={`${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("notuse")}>
                              <p className={`${styles.font16} ${styles.black}`}>로고 업로드</p>
                              <p className={`${styles.font13} ${styles.grayA2} ${styles.mt5}`}>*배경이 없는 투명한 이미지를 업로드해주세요.</p>
                            </div>
                          </label>
                          <input id={"logo"} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addLogo} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 색상</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1}`}>
                        <p className={`${styles.font16} ${color ? styles.black : styles.grayA2}`}>{color === "" ? "사용하실 기기 색상을 선택해주세요." : color === "#FF7701" ? "기본 색상" : color}</p>
                        <div
                          className={`${styles.ml10}`}
                          style={{
                            width: 50,
                            height: 20,
                            backgroundColor: color,
                          }}
                        />
                      </div>
                      <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowColor(!showColor)}>
                        변경
                      </p>
                    </div>
                  </div>
                  {showColor && (
                    <div
                      className={`${styles.center}`}
                      style={{
                        width: 615,
                        position: "absolute",
                      }}
                    >
                      <SketchPicker color={color} onChange={handleColor} />
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 연락처*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
                    <input type={"text"} name={"contact"} value={contact.value} onChange={handleContact} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"사용하실 기기 연락처를 입력해주세요."} />
                    {contact.value !== "" && <p className={`${styles.font16} ${styles.black}`}>(관리자 호출시 표시되는 번호)</p>}
                  </div>
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showAddress ? 0 : 10,
                      borderBottomRightRadius: showAddress ? 0 : 10,
                    }}
                    onClick={() => setShowAddress(!showAddress)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 주소지*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <input type={"text"} value={address.address ? `${address.address} ${extraAddress.value}` : ""} className={`${styles.inputWhite} ${styles.flex1}`} placeholder={"사용하실 기기 주소지를 입력해주세요."} readOnly={true} />
                      {showAddress ? (
                        <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                          완료
                        </p>
                      ) : (
                        <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                          변경
                        </p>
                      )}
                    </div>
                  </div>
                  {showAddress && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        {showDaum ? (
                          <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                            <DaumPostcode onComplete={handleAddress} />
                          </div>
                        ) : (
                          <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>도 / 시</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sido}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>시 / 군 / 구</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.sigungu}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2} ${styles.flex1}`} onClick={() => setShowDaum(!showDaum)}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.flex1}`}>동 / 면 / 읍</p>
                              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>{address.bname}</p>
                                <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                              </div>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.borderBottomGrayA2} ${styles.flex1} ${styles.mb60}`}>
                              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상세주소</p>
                              <input type={"text"} name={"extraAddress"} value={extraAddress.value} onChange={extraAddress.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"상세주소를 입력해주세요."} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 이메일 주소*</p>
                  </div>
                  <input type={"email"} name={"email"} value={email.value} onChange={email.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"사용하실 기기 이메일 주소를 입력해주세요."} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>할부선택 금액</p>
                  </div>
                  <NumberFormat value={installmentPrice.value} thousandSeparator={true} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} type={"tel"} displayType={"input"} onValueChange={handleInstallmentPrice} placeholder={"할부선택이 가능한 최소 금액을 입력해주세요."} />
                </div>
              </div>
              {/*<div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius:
                                                showEntranceSystem ? 0 : 10,
                                            borderBottomRightRadius:
                                                showEntranceSystem ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowEntranceSystem(
                                                !showEntranceSystem,
                                            )
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                출입관리 형태*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    entranceSystem
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {entranceSystem ? (
                                                    <>
                                                        {entranceSystem ===
                                                            'none' && '미사용'}
                                                        {entranceSystem ===
                                                            'C' && '카드 인증'}
                                                        {entranceSystem ===
                                                            'F' && '지문 인증'}
                                                        {entranceSystem ===
                                                            'M' && '얼굴 인증'}
                                                    </>
                                                ) : (
                                                    '출입관리 형태를 선택해주세요.'
                                                )}
                                            </p>
                                            <img
                                                src={
                                                    showEntranceSystem
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'출입관리 형태 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEntranceSystem && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    {ENTRANCE_MACHINES[
                                                        ENTRANCE_MACHINES.findIndex(
                                                            m =>
                                                                m.value ===
                                                                entranceMachine,
                                                        )
                                                    ].systems.map(s => {
                                                        return (
                                                            <div
                                                                key={s.value}
                                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                onClick={() =>
                                                                    handleEntranceSystem(
                                                                        s.value,
                                                                    )
                                                                }>
                                                                <p
                                                                    className={`${styles.font16} ${styles.black}`}>
                                                                    {s.name}
                                                                </p>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius:
                                                showEntranceMachine ? 0 : 10,
                                            borderBottomRightRadius:
                                                showEntranceMachine ? 0 : 10,
                                        }}
                                        onClick={() =>
                                            setShowEntranceMachine(
                                                !showEntranceMachine,
                                            )
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                인증 기기
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    entranceMachine
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {entranceMachine ? (
                                                    <>
                                                        {entranceMachine ===
                                                        'none'
                                                            ? '미사용'
                                                            : entranceMachine}
                                                    </>
                                                ) : (
                                                    '인증 기기를 선택해주세요.'
                                                )}
                                            </p>
                                            <img
                                                src={
                                                    showEntranceMachine
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'인증 기기 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEntranceMachine && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'none',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            미사용
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'N2',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            N2
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleEntranceMachine(
                                                                'F2',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            F2
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            출입관리 IP
                                        </p>
                                    </div>
                                    <input
                                        type={'tel'}
                                        name={'entranceIp'}
                                        value={entranceIp.value}
                                        onChange={entranceIp.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '출입관리 IP를 입력해주세요.'
                                        }
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            출입구역 코드
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'entranceCode'}
                                        value={entranceCode.value}
                                        onChange={entranceCode.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '출입구역 코드를 입력해주세요.'
                                        }
                                    />
                                </div>
                            </div>*/}
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>알림 수신 연락처</p>
                  </div>
                  <input type={"tel"} name={"talkContact"} value={talkContact.value} onChange={handleTalkContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"알림톡을 수신할 연락처를 입력해주세요."} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showTalk ? 0 : 10,
                      borderBottomRightRadius: showTalk ? 0 : 10,
                    }}
                    onClick={() => setShowTalk(!showTalk)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>수신 알림</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${talks.length > 0 ? styles.black : styles.grayA2} ${styles.flex1}`}>{talks.length > 0 ? `${talks.length}개의 알림` : "수신할 알림을 선택해주세요."}</p>
                      <img src={showTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"알림 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showTalk && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                            <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>주문판매/회원권판매 결제 알림</p>
                            <Switch onChange={() => handleTalks("payment")} checked={talks.findIndex((t) => t === "payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                            <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>재고 부족 알림</p>
                            <Switch onChange={() => handleTalks("stock")} checked={talks.findIndex((t) => t === "stock") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                            <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>상담 신청 알림</p>
                            <Switch onChange={() => handleTalks("consult")} checked={talks.findIndex((t) => t === "consult") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                            <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>자유/현금 결제 알림</p>
                            <Switch onChange={() => handleTalks("custom_payment")} checked={talks.findIndex((t) => t === "custom_payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showUsePgBill ? 0 : 10,
                      borderBottomRightRadius: showUsePgBill ? 0 : 10,
                    }}
                    onClick={() => setShowUsePgBill(!showUsePgBill)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>PG 영수증</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>{usePgBill ? "사용" : "미사용"}</p>
                      <img src={showUsePgBill ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"사용 여부 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showUsePgBill && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUsePgBill(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>사용</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUsePgBill(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>미사용</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showReceiveBill ? 0 : 10,
                      borderBottomRightRadius: showReceiveBill ? 0 : 10,
                    }}
                    onClick={() => setShowReceiveBill(!showReceiveBill)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>영수증 수신</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>
                        {receiveBill === "email" && "이메일"}
                        {receiveBill === "talk" && "알림톡"}
                      </p>
                      <img src={showReceiveBill ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"수신 방법 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showReceiveBill && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleReceiveBill("email")}>
                            <p className={`${styles.font16} ${styles.black}`}>이메일</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleReceiveBill("talk")}>
                            <p className={`${styles.font16} ${styles.black}`}>알림톡</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showUseReviewTalk ? 0 : 10,
                      borderBottomRightRadius: showUseReviewTalk ? 0 : 10,
                    }}
                    onClick={() => setShowUseReviewTalk(!showUseReviewTalk)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>태닝나우 리뷰 알림톡</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>{useReviewTalk ? "사용" : "미사용"}</p>
                      <img src={showUseReviewTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"사용 여부 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showUseReviewTalk && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseReviewTalk(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>사용</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseReviewTalk(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>미사용</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showAppType ? 0 : 10,
                      borderBottomRightRadius: showAppType ? 0 : 10,
                    }}
                    onClick={() => setShowAppType(!showAppType)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>어플 타입*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1} ${!appType && styles.grayA2}`}>{appType ? appType : "어플 타입을 선택해주세요."}</p>
                      <img src={showUseReviewTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"어플 타입 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showAppType && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppType("VK")}>
                            <p className={`${styles.font16} ${styles.black}`}>VK</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppType("VS")}>
                            <p className={`${styles.font16} ${styles.black}`}>VS</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppType("SZ")}>
                            <p className={`${styles.font16} ${styles.black}`}>SZ</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleAppType("SE")}>
                            <p className={`${styles.font16} ${styles.black}`}>SE</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.overflowHidden} ${styles.mt20}`} style={{ width: 1250 }}>
                <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  style={{
                    width: 615,
                    borderBottomLeftRadius: showUseReviewTalk ? 0 : 10,
                    borderBottomRightRadius: showUseReviewTalk ? 0 : 10,
                  }}
                >
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>최대 할부 기간</p>
                  </div>
                  <input type={"tel"} name={"installmentPeriod"} value={installmentPeriod.value} onChange={handleInstallmentPeriod} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"최대 할부 기간을 입력해주세요"} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showUseSchedule ? 0 : 10,
                      borderBottomRightRadius: showUseSchedule ? 0 : 10,
                    }}
                    onClick={() => setShowUseSchedule(!showUseSchedule)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>스케쥴 관리</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>{useSchedule ? "사용" : "미사용"}</p>
                      <img src={showUseSchedule ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"사용 여부 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showUseSchedule && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseSchedule(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>사용</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleUseSchedule(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>미사용</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 공지사항 (최대 300자)</p>
                <textarea name={"notice"} value={notice.value} onChange={notice.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"기기 공지사항을 입력해주세요."} maxLength={300} />
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 시설 소개 (최대 200자)</p>
                <textarea name={"intro"} value={intro.value} onChange={intro.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"기기 시설 소개 내용을 입력해주세요."} maxLength={200} />
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>기기 시설 소개 이미지 등록 (최대 5장)</p>
                  <label htmlFor="image">
                    <div
                      className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                      style={{
                        opacity: loadingImage ? 0.4 : 1,
                      }}
                    >
                      <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>이미지 추가하기</p>
                    </div>
                  </label>
                  <input id={"image"} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addImage} multiple={true} maxLength={5} />
                </div>
                {images && images.length > 0 ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided) => (
                        <div ref={provided.innerRef} className={`${styles.row} ${styles.mx0} ${styles.px40} ${styles.mt30}`} {...provided.droppableProps}>
                          {images.map((image, index) => (
                            <Draggable key={`${index}-${image.image}`} draggableId={`${index}-${image.image}`} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`${index === 0 ? "" : styles.ml20}`}
                                  style={{
                                    width: 205.6,
                                    height: 150,
                                  }}
                                >
                                  <IntroImage image={image.image} index={index} removeImage={removeImage} horizontal={true} />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className={`${styles.row} ${styles.mx0} ${styles.px50} ${styles.mt30}`}>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                    <label htmlFor="image">
                      <div
                        className={`${styles.bgGrayF4} ${styles.center} ${styles.cursorPointer} ${styles.ml20}`}
                        style={{
                          width: 205.6,
                          height: 150,
                        }}
                      >
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                  </div>
                )}
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={deleteMachine}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>삭제</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>저장</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetail;
