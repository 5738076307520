import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Terms = ({
    terms,
    editDefaultTerms,
    loading
}) => {
    const history = useHistory()
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`} style={{ opacity: loading ? 0.4 : 1 }}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.bgWhite} ${styles.cursorPointer} ${styles.flex3}`} onClick={() => history.push({
                pathname: `/contract/terms/detail/`,
                state: {
                    terms
                }
            })}>
                <div className={`${styles.flex3}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                        {terms.name}
                    </p>
                </div>
            </div>
            <div className={`${styles.flex1} ${styles.px10} ${styles.center}`}>
                <div className={`${styles.icon20} ${styles.borderRadius5} ${styles.borderGrayA2} ${styles.center} ${styles.cursorPointer}`} onClick={editDefaultTerms}>
                    {terms.default && (
                        <div className={`${styles.bgOrange}`} style={{ width: 14, height: 14, borderRadius: 3 }} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Terms;