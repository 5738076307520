import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import Device from '../../../components/Device/Mobile/Device';

const DEVICES = gql`
    query getDevices($order: String!, $skip: Int!, $q: String) {
        getDevices(order: $order, skip: $skip, q: $q) {
            machines {
                id
                username
                user {
                    id
                    username
                    name
                }
                name
                createdAt
                status
                productTotal
                advertiseTotal
                sido
                sigungu
                bname
            }
            total
        }
    }
`;

const DeviceList = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput(
        queryString.parse(location.search).q
            ? queryString.parse(location.search).q
            : '',
    );

    const { data, loading, refetch } = useQuery(DEVICES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            skip: 0,
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'date_dsc',
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/device/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&q=${q.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/device/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    useEffect(() => {
        if (
            queryString.parse(location.search).page &&
            !isNaN(Number(queryString.parse(location.search).page))
        ) {
            window.scrollTo(0, 0);
            refetch({
                skip:
                    10 * (Number(queryString.parse(location.search).page) - 1),
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc',
                q: queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : '',
            });
            q.setValue(queryString.parse(location.search).q);
        } else {
            history.replace({
                pathname: `/device/?page=1&order=${
                    queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'date_dsc'
                }&q=${
                    queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                }`,
                state: location.state,
            });
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        기기 관리
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt45} ${styles.px40}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img
                                src={
                                    require('../../../assets/images/icon_search.png')
                                        .default
                                }
                                alt={'search'}
                                className={`${styles.icon20} ${styles.cursorPointer}`}
                                onClick={submit}
                            />
                            <input
                                type={'text'}
                                name={'q'}
                                value={q.value}
                                onChange={q.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                placeholder={'기기명 및 사용자명 검색'}
                            />
                        </div>
                    </form>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1} ${styles.pr10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                기기명
                            </p>
                        </div>
                        <div />
                    </div>
                    <div>
                        {loading ? (
                            <Skeleton height={50} count={3} />
                        ) : data &&
                          data.getDevices &&
                          data.getDevices.machines.length > 0 ? (
                            data.getDevices.machines.map(device => {
                                return (
                                    <Device
                                        key={device.id}
                                        device={device}
                                        location={location} />
                                );
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    기기가 없습니다.
                                </p>
                            </div>
                        )}
                        {data?.getDevices?.total > 10 ? (
                            <div
                                className={`${styles.mt35}`}
                                style={{ position: 'relative' }}>
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_back.png')
                                                    .default
                                            }
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_front.png')
                                                    .default
                                            }
                                            alt={'다음'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    breakLabel={
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    }
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={
                                        data?.getDevices?.total
                                            ? parseInt(
                                                  (data?.getDevices?.total -
                                                      1) /
                                                      10,
                                              ) + 1
                                            : 1
                                    }
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loading ? null : handlePage}
                                    initialPage={
                                        queryString.parse(location.search).page
                                            ? Number(
                                                  queryString.parse(
                                                      location.search,
                                                  ).page,
                                              ) - 1
                                            : 0
                                    }
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.4)',
                                        }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceList;
