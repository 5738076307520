import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import NumberFormat from "react-number-format";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import IntroImage from "../../../components/Home/IntroImage";
import { FETCH_URL } from "../../../config";
import cache from "../../../apollo/cache";
import { numberWithCommas } from "../../../common/utility";

const EDIT = gql`
  mutation editProduct($machine: String!, $productId: Int!, $name: String!, $price: Int!, $stock: Int, $ignoreStock: Boolean!, $categoryId: Int!, $slot: Int, $description: String!, $image: String!, $isDisplay: Boolean) {
    editProduct(machine: $machine, productId: $productId, name: $name, price: $price, stock: $stock, ignoreStock: $ignoreStock, categoryId: $categoryId, slot: $slot, description: $description, image: $image, isDisplay: $isDisplay) {
      product {
        id
        category {
          id
          name
        }
        image
        name
        price
        stock
        ignoreStock
        slot
        description
        discountPrice
        discountPercent
        isDisplay
      }
      error
    }
  }
`;

const DELETE = gql`
  mutation deleteProduct($machine: String!, $productId: Int!) {
    deleteProduct(machine: $machine, productId: $productId)
  }
`;

const CATEGORY = gql`
  query getCategoryAdmin($machine: String!) {
    getCategoryAdmin(type: "site", machine: $machine) {
      id
      name
      productCount
    }
  }
`;

const CATEGORY_DELETE = gql`
  mutation deleteCategory($categoryId: Int!) {
    deleteCategory(categoryId: $categoryId)
  }
`;

const CATEGORY_ADD = gql`
  mutation addCategory($machine: String!, $name: String!) {
    addCategory(machine: $machine, type: "site", name: $name) {
      id
      name
      productCount
    }
  }
`;

const CHECK = gql`
  query checkProduct($machine: String!, $slot: Int!, $productId: Int) {
    checkProduct(machine: $machine, slot: $slot, productId: $productId)
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const PRODUCT = gql`
  query getProduct($id: Int!, $machine: String!) {
    getProduct(id: $id, machine: $machine) {
      id
      category {
        id
        name
      }
      image
      name
      price
      stock
      ignoreStock
      slot
      description
      discountPrice
      discountPercent
      isDisplay
    }
  }
`;

const SiteProductEdit = () => {
  const history = useHistory();
  const location = useLocation();

  const imageRef = useRef();

  const name = useInput("");
  const price = useInput("");
  const stock = useInput("");
  const description = useInput("");
  const categoryName = useInput("");

  const [category, setCategory] = useState({});
  const [discountPrice, setDiscountPrice] = useState("");
  const [showCategory, setShowCategory] = useState(false);
  const [showCategoryAdd, setShowCategoryAdd] = useState(false);
  const [ignoreStock, setIgnoreStock] = useState(false);
  const [slot, setSlot] = useState(null);
  const [showSlot, setShowSlot] = useState(false);
  const [nowStock, setNowStock] = useState("");
  const [showStock, setShowStock] = useState(false);
  const [image, setImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showIsDisplay, setShowIsDisplay] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);

  const { refetch } = useQuery(CHECK, {
    fetchPolicy: "no-cache",
    skip: true,
  });
  const { data, refetch: refetchCategory } = useQuery(CATEGORY, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: product } = useQuery(PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: location.state.product.id ? location.state.product.id : -1,
      machine: location.state.machine ? location.state.machine : "",
    },
    onError: () => {
      alert("상품이 존재하지 않습니다.");
      history.goBack();
    },
  });

  const [editProductM] = useMutation(EDIT);
  const [deleteProductM] = useMutation(DELETE);
  const [deleteCategoryM] = useMutation(CATEGORY_DELETE);
  const [addCategoryM] = useMutation(CATEGORY_ADD);

  const handlePrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      price.setValue(value);
    }
  };

  const handleStock = (values) => {
    const { value } = values;
    stock.setValue(value);
  };

  const handleCategory = (category) => {
    setCategory(category);
    setShowCategory(false);
  };

  const handleSlot = (slot) => {
    setSlot(slot);
    setShowSlot(false);
    categoryName.setValue("");
  };

  const handleIsDisplay = (isDisplay) => {
    setIsDisplay(isDisplay);
    setShowIsDisplay(false);
  };

  const addCategory = async () => {
    if (!loading) {
      if (categoryName.value) {
        setLoading(true);
        const { data } = await addCategoryM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            name: categoryName.value,
          },
        });
        setLoading(false);
        if (data && data.addCategory) {
          refetchCategory();
          setCategory(data.addCategory);
          setShowCategory(false);
        } else {
          alert("오류가 발생하였습니다.");
        }
      } else {
        alert("추가할 분류명을 입력해주세요.");
      }
    }
  };

  const addImage = async (e) => {
    if (!loadingImage) {
      const file = e.target.files[0];
      if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
        var _URL = window.URL || window.webkitURL;
        setLoadingImage(true);
        setUploadImage(file);
        var img = new Image();
        var reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        img.src = await _URL.createObjectURL(file);
        await reader.readAsDataURL(file);
        setLoadingImage(false);
      } else {
        alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
      }
    }
  };

  const removeImage = () => {
    setImage(null);
    setUploadImage(null);
  };

  const removeCategory = async (selected) => {
    if (!loading) {
      if (selected && selected.id) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          if (selected.productCount === 0) {
            setLoading(true);
            const { data } = await deleteCategoryM({
              variables: {
                categoryId: selected.id,
              },
            });
            setLoading(false);
            if (data && data.deleteCategory) {
              if (category.id === selected.id) {
                setCategory({});
              }
              cache.evict({
                id: `Category:${selected.id}`,
              });
            } else {
              alert("해당 카테고리에 현재 판매중인 상품이 존재합니다.");
            }
          } else {
            alert("해당 카테고리에 현재 판매중인 상품이 존재합니다.");
          }
        }
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (product && product.getProduct) {
        if (name.value && Number(price.value) >= 0 && category && slot && description.value && image) {
          let checkStock = true;
          if (!ignoreStock) {
            if (stock.value === "-") {
              checkStock = false;
            }
          }
          if (!checkStock) {
            alert("올바른 상품 수량을 입력해주세요.");
            return;
          }
          setLoading(true);
          const { data: check } = await refetch({
            machine: location.state.machine ? location.state.machine : "",
            slot,
            productId: product.getProduct.id,
          });
          if (check && check.checkProduct) {
            if (check.checkProduct === "success") {
              try {
                let imageUrl = image;
                if (!(image === product.getProduct.image)) {
                  if (uploadImage) {
                    const formData = new FormData();
                    const resizedImage = await resizeImage(uploadImage);
                    formData.append("productimage", resizedImage, uploadImage.name);
                    const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    });
                    if (data && data.location) {
                      imageUrl = data.location;
                    } else {
                      setLoading(false);
                      alert("오류가 발생하였습니다.");
                      return;
                    }
                  } else {
                    setLoading(false);
                    alert("위의 정보를 입력해주세요.");
                    return;
                  }
                }
                if (category.id < 0) {
                  const { data: create } = await addCategoryM({
                    variables: {
                      machine: location.state.machine ? location.state.machine : "",
                      name: category.name,
                    },
                  });
                  if (create && create.addCategory) {
                    const { data: result } = await editProductM({
                      variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        productId: product.getProduct.id,
                        name: name.value,
                        price: Number(price.value),
                        stock: stock.value ? Number(nowStock) + Number(stock.value) : Number(nowStock),
                        ignoreStock,
                        categoryId: create.addCategory.id,
                        slot,
                        description: description.value,
                        image: imageUrl,
                        isDisplay,
                      },
                    });
                    if (result && result.editProduct) {
                      if (result.editProduct.error) {
                        setLoading(false);
                        alert(result.editProduct.error);
                      } else {
                        setLoading(false);
                        alert("상품을 수정하였습니다.");
                      }
                    } else {
                      setLoading(false);
                      alert("오류가 발생하였습니다.");
                    }
                  } else {
                    setLoading(false);
                    alert("오류가 발생하였습니다.");
                  }
                } else {
                  const { data: result } = await editProductM({
                    variables: {
                      machine: location.state.machine ? location.state.machine : "",
                      productId: product.getProduct.id,
                      name: name.value,
                      price: Number(price.value),
                      stock: stock.value ? Number(nowStock) + Number(stock.value) : Number(nowStock),
                      ignoreStock,
                      categoryId: category.id,
                      slot,
                      description: description.value,
                      image: imageUrl,
                      isDisplay,
                    },
                  });
                  if (result && result.editProduct) {
                    if (result.editProduct.error) {
                      setLoading(false);
                      alert(result.editProduct.error);
                    } else {
                      setLoading(false);
                      alert("상품을 수정하였습니다.");
                    }
                  } else {
                    setLoading(false);
                    alert("오류가 발생하였습니다.");
                  }
                }
              } catch {
                setLoading(false);
                alert("오류가 발생하였습니다.");
              }
            } else {
              setLoading(false);
              alert(check.checkProduct);
            }
          } else {
            setLoading(false);
            alert("입력하신 기기 계정에 일치하는 기기가 존재하지 않습니다.");
          }
        } else {
          alert("위의 정보를 입력해주세요.");
        }
      }
    }
  };

  const remove = async () => {
    if (!loading) {
      if (product && product.getProduct) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data } = await deleteProductM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              productId: product.getProduct.id,
            },
          });
          setLoading(false);
          if (data && data.deleteProduct) {
            alert("상품을 삭제하였습니다.");
            cache.evict({
              id: `Product:${product.getProduct.id}`,
            });
            history.goBack();
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const initialize = async () => {
    if (product && product.getProduct) {
      name.setValue(product.getProduct.name);
      price.setValue(product.getProduct.price);
      stock.setValue("");
      description.setValue(product.getProduct.description);
      categoryName.setValue("");
      setCategory(product.getProduct.category);
      setShowCategory(false);
      setDiscountPrice(product.getProduct.discountPrice);
      setSlot(product.getProduct.slot);
      setNowStock(product.getProduct.stock);
      setShowStock(false);
      setIgnoreStock(product.getProduct.ignoreStock);
      setShowSlot(false);
      setLoading(false);
      setImage(product.getProduct.image);
      setUploadImage(null);
      setLoadingImage(false);
      setIsDisplay(product.getProduct.isDisplay);
      if (imageRef.current) {
        imageRef.current.value = "";
      }
    }
  };

  useEffect(() => {
    setShowCategoryAdd(false);
    categoryName.setValue("");
  }, [showCategory]);

  useEffect(() => {
    if (product && product.getProduct) {
      initialize();
    }
  }, [product]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`상품 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          {product && product.getProduct ? (
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
              <div>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
              </div>
              <div className={`${styles.mt10}`} style={{ position: "relative" }}>
                <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  style={{
                    borderBottomLeftRadius: showIsDisplay ? 0 : 10,
                    borderBottomRightRadius: showIsDisplay ? 0 : 10,
                  }}
                >
                  <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>전시 상태*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.cursorPointer}`} onClick={() => setShowIsDisplay(!showIsDisplay)}>
                    <input type={"text"} value={isDisplay ? "전시 중" : "전시 중단"} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} readOnly={true} />
                    <img src={showIsDisplay ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                </div>
                {showIsDisplay && (
                  <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                      <div className={`${styles.py10} ${styles.flex1}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                          <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleIsDisplay(true)}>
                            전시 중
                          </p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`} style={{ display: "flex" }}>
                          <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleIsDisplay(false)}>
                            전시 중단
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품명*</p>
                </div>
                <input type={"text"} name={"name"} value={name.value || ""} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"상품명을 입력해주세요."} />
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품 가격 (원)*</p>
                </div>
                <NumberFormat value={price.value || ""} thousandSeparator={true} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} type={"tel"} displayType={"input"} onValueChange={handlePrice} placeholder={"상품 가격을 입력해주세요. (숫자만 입력)"} />
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>할인 상태</p>
                </div>
                <input type={"text"} name={"status"} value={discountPrice ? `${numberWithCommas(Number(discountPrice))}원 할인 (할인 적용 관리에서 변경 가능)` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>할인 가격 (원)</p>
                </div>
                <input
                  type={"text"}
                  name={"discountPrice"}
                  value={discountPrice ? `${numberWithCommas((Number(price.value) - Number(discountPrice)).toFixed(2))} (할인 적용 관리에서 변경 가능)` : `${numberWithCommas(Number(price.value))} (할인 적용 관리에서 변경 가능)`}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                  readOnly={true}
                />
              </div>
              <div className={`${styles.mt10}`} style={{ position: "relative" }}>
                <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  style={{
                    borderBottomLeftRadius: showStock ? 0 : 10,
                    borderBottomRightRadius: showStock ? 0 : 10,
                  }}
                >
                  <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품 수량*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`} onClick={() => setShowStock(!showStock)}>
                      {ignoreStock ? (
                        <p className={`${styles.font14} ${styles.black} ${styles.px15}`}>무제한</p>
                      ) : (
                        <NumberFormat
                          value={showStock ? `현재+추가:${Number(nowStock) + Number(stock.value)}` : Number(nowStock) + Number(stock.value)}
                          thousandSeparator={true}
                          className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                          displayType={"text"}
                          placeholder={"추가할 수량을 입력해주세요."}
                        />
                      )}
                      <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.underline} ${styles.cursorPointer}`}>{showStock ? "완료" : "변경"}</p>
                    </div>
                    <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.mx15} ${styles.cursorPointer}`} onClick={() => setIgnoreStock(!ignoreStock)}>
                      {ignoreStock && (
                        <div
                          className={`${styles.bgOrange} ${styles.borderRadius5}`}
                          style={{
                            width: 14,
                            height: 14,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {showStock && (
                  <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                      <div className={`${styles.py10} ${styles.flex1}`}>
                        <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.px15} ${styles.mt20}`}>{`현재: ${nowStock}`}</p>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}>
                          <p className={`${styles.font14} ${styles.black} ${styles.mr10}`}>추가:</p>
                          <NumberFormat
                            value={stock.value}
                            thousandSeparator={true}
                            className={`${styles.inputWhiteUnderline} ${styles.flex1} ${styles.py5} ${styles.mt3}`}
                            type={"tel"}
                            displayType={"input"}
                            onValueChange={handleStock}
                            placeholder={"추가할 수량을 입력해주세요. (‘-’ 입력 가능)"}
                            style={{ minWidth: 20 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.mt10}`} style={{ position: "relative" }}>
                <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  style={{
                    borderBottomLeftRadius: showCategory ? 0 : 10,
                    borderBottomRightRadius: showCategory ? 0 : 10,
                  }}
                  onClick={() => setShowCategory(!showCategory)}
                >
                  <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품 분류*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                    <input type={"text"} value={category && category.id ? category.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"상품 분류를 선택해주세요."} readOnly={true} />
                    <img src={showCategory ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"상품 분류 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                </div>
                {showCategory && (
                  <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                      {showCategoryAdd ? (
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}>
                            <p className={`${styles.font14} ${styles.black} ${styles.mr10}`}>분류명</p>
                            <input
                              type={"text"}
                              name={"categoryName"}
                              value={categoryName.value}
                              onChange={categoryName.onChange}
                              className={`${styles.inputWhiteUnderline} ${styles.flex1} ${styles.py5} ${styles.mt3}`}
                              style={{
                                minWidth: 20,
                              }}
                              placeholder={"분류명을 입력해주세요."}
                            />
                            <p
                              className={`${styles.font14} ${styles.black} ${styles.ml10} ${styles.cursorPointer}`}
                              style={{
                                textDecorationLine: "underline",
                                opacity: loading ? 0.4 : 1,
                              }}
                              onClick={addCategory}
                            >
                              완료
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => setShowCategoryAdd(true)}>
                            <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon15} ${styles.cursorPointer} ${styles.mr10} ${styles.mt3}`} />
                            <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`}>새로운 분류 추가</p>
                          </div>
                          {data && data.getCategoryAdmin
                            ? data.getCategoryAdmin.map((c) => {
                                return (
                                  <div key={c.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                    <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleCategory(c)}>
                                      {c.name}
                                    </p>
                                    <img src={require("../../../assets/images/icon_close.png").default} alt={"delete"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml20} ${styles.mt3}`} onClick={() => removeCategory(c)} />
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.mt10}`} style={{ position: "relative" }}>
                <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  style={{
                    borderBottomLeftRadius: showSlot ? 0 : 10,
                    borderBottomRightRadius: showSlot ? 0 : 10,
                  }}
                  onClick={() => setShowSlot(!showSlot)}
                >
                  <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                    <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>슬롯 번호*</p>
                  </div>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                    <input type={"text"} value={slot || ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"상품이 보관되는 슬롯의 번호를 선택해주세요."} readOnly={true} />
                    <img src={showSlot ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"슬롯 번호 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                </div>
                {showSlot && (
                  <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                      <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                      <div className={`${styles.py10}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(1)}>
                          <p className={`${styles.font14} ${styles.black}`}>1</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(2)}>
                          <p className={`${styles.font14} ${styles.black}`}>2</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(3)}>
                          <p className={`${styles.font14} ${styles.black}`}>3</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(4)}>
                          <p className={`${styles.font14} ${styles.black}`}>4</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(5)}>
                          <p className={`${styles.font14} ${styles.black}`}>5</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(6)}>
                          <p className={`${styles.font14} ${styles.black}`}>6</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(7)}>
                          <p className={`${styles.font14} ${styles.black}`}>7</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(8)}>
                          <p className={`${styles.font14} ${styles.black}`}>8</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(9)}>
                          <p className={`${styles.font14} ${styles.black}`}>9</p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleSlot(10)}>
                          <p className={`${styles.font14} ${styles.black}`}>10</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품 설명 (최대 150자)*</p>
                <textarea name={"description"} value={description.value} onChange={description.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 120 }} placeholder={"상품 설명을 입력해주세요."} maxLength={150} />
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품 이미지 등록*</p>
                <div className={`${styles.center}`}>
                  <label htmlFor="image">
                    <div
                      className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt10}`}
                      style={{
                        opacity: loadingImage ? 0.4 : 1,
                      }}
                    >
                      <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>이미지 추가하기</p>
                    </div>
                  </label>
                  <input id={"image"} ref={imageRef} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addImage} />
                </div>
                {image ? (
                  <div className={`${styles.px25} ${styles.mt25}`}>
                    <IntroImage image={image} index={0} removeImage={removeImage} />
                  </div>
                ) : (
                  <div className={`${styles.px25} ${styles.mt25}`}>
                    <label htmlFor="image">
                      <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer}`} style={{ height: 150 }}>
                        <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                      </div>
                    </label>
                  </div>
                )}
              </div>
              <div className={`${styles.mt45}`}>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
                </button>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={remove}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
                </div>
              </div>
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SiteProductEdit;
