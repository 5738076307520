import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { bill, alramNumber, modalAlert, modalCalendar } from "../../../common/modal";
import Modal from "react-modal";
import Calendar from "react-calendar/dist/umd/Calendar";

import styles from "../../../styles/styles.module.scss";
import EventSearch from "../../../components/EventLog/PC/EventSearch";
import codes from "../../../components/EventLog/Codes";

// GraphQL query to get all users
const GETALLUSERS = gql`
  query getAllUsers($searchText: String, $entranceIp: String) {
    getAllUsers(searchText: $searchText, entranceIp: $entranceIp) {
      user_id
      name
    }
  }
`;

const GET_DOORS = gql`
  query getAllDoor($searchText: String, $entranceIp: String) {
    getAllDoor(searchText: $searchText, entranceIp: $entranceIp) {
      id
      name
    }
  }
`;

// GraphQL query to get event logs
const EVENTSEARCH = gql`
  query getEventLog($q: [String], $start: String, $end: String, $column: String, $entranceIp: String, $deviceId: String, $limit: Int, $offset: String, $skip: Int, $finalCondition: JSON) {
    getEventLog(q: $q, start: $start, end: $end, column: $column, entranceIp: $entranceIp, deviceId: $deviceId, limit: $limit, offset: $offset, skip: $skip, finalCondition: $finalCondition) {
      id
      user_group_id
      user_group_name
      server_datetime
      datetime
      device_id
      device_name
      event_type_id
      user_id
      user_name
      door {
        id
        name
      }
    }
  }
`;

const useModal = (initialItems = [], itemIdKey, storageKey) => {
  // 초기 상태 로드: 로컬 스토리지에서 저장된 항목이 있으면 로드하고, 없으면 빈 배열로 설정
  const [selectedItems, setSelectedItems] = useState(() => {
    const savedItems = localStorage.getItem(storageKey);
    return savedItems ? JSON.parse(savedItems) : [];
  });
  const [remainingItems, setRemainingItems] = useState(initialItems);
  const [originalItems, setOriginalItems] = useState(initialItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);

  // 모달 창 열기/닫기 토글 함수
  const toggleModal = () => setShowModal((prev) => !prev);

  // 항목 클릭 처리 함수: 항목을 추가하거나 제거
  const handleItemClick = (item, type) => {
    setSelectedItems((prevSelected) => (type === "add" ? [...prevSelected, item] : prevSelected.filter((i) => i[itemIdKey] !== item[itemIdKey])));

    setRemainingItems((prevRemaining) => (type === "add" ? prevRemaining.filter((i) => i[itemIdKey] !== item[itemIdKey]) : [...prevRemaining, item].sort((a, b) => originalItems.findIndex((o) => o[itemIdKey] === a[itemIdKey]) - originalItems.findIndex((o) => o[itemIdKey] === b[itemIdKey]))));
  };

  // 완료 처리 함수: onComplete 콜백을 호출하고 모달을 닫음
  const handleComplete = (onComplete) => {
    onComplete(selectedItems.length > 0 ? selectedItems : originalItems);
    toggleModal();
  };

  // 선택된 항목이나 원래 항목이 변경될 때마다 남은 항목 업데이트 및 로컬 스토리지에 저장
  useEffect(() => {
    setRemainingItems(originalItems.filter((oi) => !selectedItems.some((si) => si[itemIdKey] === oi[itemIdKey])));
    if (storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(selectedItems));
    }
  }, [selectedItems, originalItems, itemIdKey, storageKey]);

  // 컴포넌트 언마운트 시 로컬 스토리지에서 값 제거
  useEffect(() => {
    return () => {
      localStorage.removeItem(storageKey);
    };
  }, [storageKey]);

  return {
    selectedItems,
    remainingItems,
    originalItems,
    setRemainingItems,
    setOriginalItems,
    searchTerm,
    setSearchTerm,
    showModal,
    toggleModal,
    handleItemClick,
    handleComplete,
    setSelectedItems,
  };
};

// URL의 쿼리 파라미터에서 초기 값을 가져옴
const getInitialQuery = (key, defaultValue, location) => {
  return queryString.parse(location.search)[key] || defaultValue;
};

// 페이지가 유효한지 검사
const isValidPage = (page) => page && !isNaN(Number(page));

// 날짜를 'YYYY-MM-DD' 형식으로 포맷
const formatDate = (date) => (date ? date.toISOString() : "");

// 오늘의 시작과 끝 시간을 반환
const getTodayStartEnd = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
  return { start, end };
};

const formatDateKST = (date) => {
  const options = {
    timeZone: "Asia/Seoul",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const dateTimeFormat = new Intl.DateTimeFormat("ko-KR", options);
  const parts = dateTimeFormat.formatToParts(date);

  const year = parts.find((part) => part.type === "year").value;
  const month = parts.find((part) => part.type === "month").value;
  const day = parts.find((part) => part.type === "day").value;
  const hour = parts.find((part) => part.type === "hour").value;
  const minute = parts.find((part) => part.type === "minute").value;

  return `${year}-${month}-${day} ${hour}:${minute}`;
};

// const formatDateKST = (date) => {
//   return date
//     .toLocaleString("ko-KR", {
//       timeZone: "Asia/Seoul",
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "2-digit",
//       minute: "2-digit",
//       hour12: false,
//     })
//     .replace("T", " ");
// };

// 초기 조건 설정
let _finalCondition = {
  user_id: { column: "user_id", operator: 2, values: [] },
  event_type_id: { column: "event_type_id", operator: 2, values: [] },
  door_id: { column: "door_id", operator: 2, values: [] },
};

// 조건 업데이트 함수
const updateFinalCondition = (column, values) => {
  _finalCondition = {
    ..._finalCondition,
    [column]: { column, operator: 2, values },
  };
};

const SearchEvent = () => {
  const history = useHistory();
  const location = useLocation();

  const [start, setStart] = useState(getTodayStartEnd().start);
  const [end, setEnd] = useState(getTodayStartEnd().end);
  const [showOptions, setShowOptions] = useState(false);
  const [showCustom, setShowCustom] = useState(false);
  const [selectedRange, setSelectedRange] = useState("1day");
  const [column, setColumn] = useState("");
  const [machineId, setMachineId] = useState(null);
  const [machineUser, setMachineUser] = useState(null);
  const [machineIp, setMachineIp] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState("0");
  const [currentPage, setCurrentPage] = useState(0);
  const [machineOptions, setMachineOptions] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("");
  const [showDeviceModal, setShowDeviceModal] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingName, setEditingName] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // 필터 저장
  const saveFilter = (filterName) => {
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    const newFilter = {
      name: filterName,
      condition: _finalCondition,
      selectedUsers,
      selectedDoors,
      selectedLogs,
    };
    savedFilters.push(newFilter);
    localStorage.setItem("savedFilters", JSON.stringify(savedFilters));
    alert("필터가 성공적으로 저장되었습니다!");
    setSavedFilters(loadFilters()); // 저장된 필터 업데이트
  };

  // 필터 불러오기
  const loadFilters = () => {
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    return savedFilters;
  };

  // 필터 적용
  const applyFilter = (filter) => {
    _finalCondition = filter.condition;
    setSelectedUsers(filter.selectedUsers);
    setSelectedDoors(filter.selectedDoors);
    setSelectedLogs(filter.selectedLogs);

    const startStr = formatDate(start);
    const endStr = formatDate(end);
    const newState = location.state ? { ...location.state } : null;

    history.push({
      pathname: location.pathname,
      state: newState,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        start: startStr,
        end: endStr,
      }),
    });
    setDropdownOpen(false); // 필터 선택 후 드롭다운 닫기
    refetch();
    alert("필터가 성공적으로 적용되었습니다!");
  };

  // 필터 삭제
  const deleteFilter = (filterName) => {
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters")) || [];
    const updatedFilters = savedFilters.filter((filter) => filter.name !== filterName);
    localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
    setSavedFilters(updatedFilters); // 상태 업데이트
    alert("필터가 성공적으로 삭제되었습니다!");
  };

  // 필터 이름 생성
  const generateFilterName = () => {
    let baseName = "새 필터";
    let newName = baseName;
    let counter = 1;

    while (savedFilters.some((filter) => filter.name === newName)) {
      newName = `${baseName}${counter}`;
      counter++;
    }

    return newName;
  };

  // 필터 저장 핸들러
  const handleSaveFilter = () => {
    const nameToSave = filterName.trim() !== "" ? filterName : generateFilterName();
    saveFilter(nameToSave);
    setFilterName("");
  };

  // 필터 편집 핸들러
  const handleEditFilter = (index) => {
    setEditingIndex(index);
    setEditingName(savedFilters[index].name);
  };

  // 필터 이름 업데이트 핸들러
  const handleUpdateFilterName = () => {
    const updatedFilters = [...savedFilters];
    updatedFilters[editingIndex].name = editingName.trim() !== "" ? editingName : generateFilterName();
    localStorage.setItem("savedFilters", JSON.stringify(updatedFilters));
    setSavedFilters(updatedFilters);
    setEditingIndex(-1);
    setEditingName("");
  };

  // 코드명 목록 생성
  const codenames = codes.map((item) => ({ code: item.code, codename: item.codename }));

  // 코드명 가져오기
  const getCodename = (eventTypeId) => {
    const codeItem = codenames.find((item) => item.code === eventTypeId);
    return codeItem ? codeItem.codename : eventTypeId;
  };

  // useModal 훅 사용
  const {
    selectedItems: selectedDoors,
    setSelectedItems: setSelectedDoors,
    remainingItems: remainingDoors,
    setRemainingItems: setRemainingDoors,
    setOriginalItems: setOriginalDoors,
    searchTerm: doorSearchTerm,
    setSearchTerm: setDoorSearchTerm,
    showModal: showDoorModal,
    toggleModal: toggleDoorModal,
    handleItemClick: handleDoorClick,
    handleComplete: handleDoorComplete,
  } = useModal([], "door_id", "selectedDoors");

  const {
    selectedItems: selectedUsers,
    setSelectedItems: setSelectedUsers,
    remainingItems: remainingUsers,
    setRemainingItems: setRemainingUsers,
    setOriginalItems: setOriginalUsers,
    searchTerm: userSearchTerm,
    setSearchTerm: setUserSearchTerm,
    showModal: showUserModal,
    toggleModal: toggleUserModal,
    handleItemClick: handleUserClick,
    handleComplete: handleUserComplete,
  } = useModal([], "user_id", "selectedUsers");

  const {
    selectedItems: selectedLogs,
    setSelectedItems: setSelectedLogs,
    remainingItems: remainingLogs,
    searchTerm: logSearchTerm,
    setSearchTerm: setLogSearchTerm,
    showModal: showLogModal,
    toggleModal: toggleLogModal,
    handleItemClick: handleLogClick,
    handleComplete: handleLogComplete,
    setRemainingItems: setRemainingLogs,
  } = useModal(codenames, "code", "selectedLogs");

  // 전체 사용자 쿼리
  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: refetchUsers,
  } = useQuery(GETALLUSERS, {
    variables: {
      searchText: userSearchTerm,
      entranceIp: machineIp, // Add machineIp as entranceIp
    },
  });

  // 도어 쿼리
  const {
    data: doorData,
    loading: doorLoading,
    error: doorError,
    refetch: refetchDoors,
  } = useQuery(GET_DOORS, {
    variables: {
      searchText: doorSearchTerm,
      entranceIp: machineIp, // Add machineIp as entranceIp
    },
  });

  const {
    data,
    loading: loadingEvent,
    error: eventError,
    refetch,
  } = useQuery(EVENTSEARCH, {
    fetchPolicy: "cache-and-network",
    skip: !machineId,
    variables: {
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q.split(",") : [],
      start: queryString.parse(location.search).start || formatDate(start),
      end: queryString.parse(location.search).end || formatDate(end),
      column: queryString.parse(location.search).column || column,
      entranceIp: machineIp, // Add machineIp as entranceIp
      deviceId: selectedMachine || machineId,
      limit,
      offset,
      finalCondition: _finalCondition,
    },
    onCompleted: (data) => {
      if (data.getEventLog.length < limit) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setTotalPages((prev) => prev + Math.ceil(data.getEventLog.length / limit));
      }
    },
  });

  // 로그 검색 핸들러
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setLogSearchTerm(value);
    const filteredLogs = value.trim() ? codenames.filter((code) => code.codename.toLowerCase().includes(value.toLowerCase())) : codenames;
    const updatedRemainingLogs = filteredLogs.filter((code) => !selectedLogs.some((selected) => selected.code === code.code));
    setRemainingLogs(updatedRemainingLogs);
  };

  // 페이지당 항목 수 변경 핸들러
  const handleLimitClick = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setOffset("0");
    setCurrentPage(0);
    refetch();
  };

  // 다음 페이지로 이동 핸들러
  const handleNextPage = () => {
    if (hasMore && currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        const newOffset = (nextPage * limit).toString();
        setOffset(newOffset);
        refetch();
        return nextPage;
      });
      window.scrollTo(0, 0);
    } else {
      alert("마지막 페이지입니다.");
    }
  };

  // 이전 페이지로 이동 핸들러
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => {
      const prev = Math.max(prevPage - 1, 0);
      const newOffset = (prev * limit).toString();
      setOffset(newOffset);
      refetch();
      return prev;
    });
    window.scrollTo(0, 0);
  };

  // 특정 페이지로 이동 핸들러
  const handleGoToPage = (data) => {
    const pageNumber = data.selected;
    if (pageNumber !== currentPage) {
      const newOffset = (pageNumber * limit).toString();
      setCurrentPage(pageNumber);
      setOffset(newOffset);
      refetch();
    }
  };

  // 사용자 지정 범위 선택 핸들러
  const handleCustomRange = () => {
    setSelectedRange("custom");
    setShowCustom(true);
    setShowOptions(false);
  };

  // 범위 변경 핸들러
  const handleRangeChange = (range) => {
    const now = new Date();
    setSelectedRange(range);

    const getStartDate = (unit, value) => {
      const date = new Date();
      switch (unit) {
        case "days":
          date.setDate(now.getDate() - value);
          date.setHours(0, 0, 0, 0);
          break;
        case "months":
          date.setMonth(now.getMonth() - value);
          date.setHours(0, 0, 0, 0);
          break;
        default:
          break;
      }
      return date;
    };

    const ranges = {
      "1day": { start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      "3days": { start: getStartDate("days", 2), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      "1week": { start: getStartDate("days", 6), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      "1month": { start: getStartDate("months", 1), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      "3months": { start: getStartDate("months", 3), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      "6months": { start: getStartDate("months", 6), end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999) },
      custom: { start, end },
    };

    setStart(ranges[range].start);
    setEnd(ranges[range].end);

    if (range === "custom") {
      setShowCustom(true);
    } else {
      setShowCustom(false);
    }
    setShowOptions(false);

    const startStr = formatDate(ranges[range].start);
    const endStr = formatDate(ranges[range].end);
    const newState = location.state ? { ...location.state } : null;

    history.push({
      pathname: location.pathname,
      state: newState,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        start: startStr,
        end: endStr,
      }),
    });
  };

  // 범위 레이블 가져오기
  const getRangeLabel = (range) =>
    ({
      "1day": "1일",
      "3days": "3일",
      "1week": "1주",
      "1month": "1달",
      "3months": "3달",
      "6months": "6달",
      custom: "사용자 지정",
    }[range] || "");

  // 날짜 선택 핸들러
  const handleDateSelection = (date, type) => {
    if (type === "start") {
      setStart(date);
    } else {
      date.setDate(date.getDate() + 1);
      date.setSeconds(date.getSeconds() - 1);
      setEnd(date);
    }
  };

  // 옵션 토글 핸들러
  const toggleOptions = () => setShowOptions(!showOptions);

  // 폼 제출 핸들러
  const submit = (e) => {
    e.preventDefault();
    handleComplete();
  };

  // 장치 아이디 드롭다운 핸들러
  const handleDropdownDeviceId = (value) => {
    if (value === "전체") {
      setSelectedMachine("");
    } else {
      setSelectedMachine(value);
    }
  };

  // 장치 모달 토글 핸들러
  const toggleDeviceModal = () => {
    setShowDeviceModal(!showDeviceModal);
  };

  // 완료 핸들러
  const handleComplete = (items, itemKey) => {
    const itemIds = items.map((item) => item[itemKey]);
    const startStr = formatDate(start);
    const endStr = formatDate(end);
    const page = getInitialQuery("page", 1, location);

    updateFinalCondition(column, itemIds);

    const newState = location.state ? { ...location.state } : null;

    history.push({
      pathname: `/entrance/door/log/`,
      state: newState,
      search: queryString.stringify({
        page,
        q: itemIds.length > 0 ? itemIds.join(",") : "",
        start: startStr,
        end: endStr,
        column,
        deviceId: selectedMachine || "",
        limit,
        offset,
      }),
    });
    refetch();
  };

  const handleCompleteUsers = () => handleComplete(selectedUsers, "user_id");
  const handleCompleteLogs = () => handleComplete(selectedLogs, "code");
  const handleCompleteDoors = () => handleComplete(selectedDoors, "id");

  const handleDoorsButtonClick = () => {
    setColumn("door_id");
    toggleDoorModal();
  };

  const handleUserButtonClick = () => {
    setColumn("user_id");
    toggleUserModal();
  };

  const handleLogButtonClick = () => {
    setColumn("event_type_id");
    toggleLogModal();
  };

  useEffect(() => {
    // 컴포넌트가 언마운트될 때 _finalCondition 초기화
    return () => {
      _finalCondition = {
        user_id: { column: "user_id", operator: 2, values: [] },
        event_type_id: { column: "event_type_id", operator: 2, values: [] },
        door_id: { column: "door_id", operator: 2, values: [] },
      };
    };
  }, []);

  useEffect(() => {
    const { start, end } = getTodayStartEnd();
    setStart(start);
    setEnd(end);
  }, []);

  useEffect(() => {
    const startStr = formatDate(start);
    const endStr = formatDate(end);
    const currentPage = queryString.parse(location.search).page || 1;

    console.log("useEffect - Start Date:", startStr);
    console.log("useEffect - End Date:", endStr);

    if (isValidPage(currentPage)) {
      refetch({
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q.split(",") : [],
        start: startStr,
        end: endStr,
        column: queryString.parse(location.search).column || column,
        deviceId: selectedMachine || machineId,
        limit,
        offset,
        finalCondition: _finalCondition,
        entranceIp: machineIp,
      });
    } else {
      history.replace({
        pathname: `/entrance/door/log/`,
        state: location.state,
        search: queryString.stringify({
          page: 1,
          q: queryString.parse(location.search).q || "",
          start: startStr,
          end: endStr,
          column: queryString.parse(location.search).column || column,
          limit,
          offset,
          deviceId: selectedMachine || machineId,
          entranceIp: machineIp,
        }),
      });
    }
  }, [start, end, column, machineId, refetch, limit, offset, location.search, selectedMachine, currentPage, savedFilters]);

  useEffect(() => {
    console.log("Selected deviceId state before searchEvents:", selectedMachine);
  }, [selectedMachine]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.column) {
      setColumn(params.column);
    }
  }, [location.search]);

  useEffect(() => {
    if (userData && userData.getAllUsers) {
      setRemainingUsers(userData.getAllUsers);
      setOriginalUsers(userData.getAllUsers);
    }
  }, [userData]);

  useEffect(() => {
    if (doorData && doorData.getAllDoor) {
      setRemainingDoors(doorData.getAllDoor);
      setOriginalDoors(doorData.getAllDoor);
    }
  }, [doorData]);

  useEffect(() => {
    refetchUsers({ searchText: userSearchTerm });
  }, [userSearchTerm, refetchUsers]);

  useEffect(() => {
    refetchDoors({ searchText: doorSearchTerm });
  }, [doorSearchTerm, refetchDoors]);

  useEffect(() => {
    console.log("limit updated:", limit);
    console.log("offset updated:", offset);
    refetch();
  }, [limit, offset, refetch]);

  // useEffect(() => {
  //   console.log("useEffect triggered with location.state:", location.state);
  //   if (location.state) {
  //     if (location.state.deviceId) {
  //       setMachineId(location.state.deviceId);
  //       setMachineOptions(location.state.deviceId.split(","));
  //     }
  //     if (location.state.name) {
  //       setMachineUser(location.state.name);
  //     }
  //     if (location.state.ip) {
  //       // 포트 번호를 제거
  //       const ipWithoutPort = location.state.ip.split(":")[0];
  //       setMachineIp(ipWithoutPort);
  //       console.log("Server IP without port:", ipWithoutPort);
  //     }
  //   }
  // }, [location.state]);

  useEffect(() => {
    console.log("useEffect triggered with location.state:", location.state);
    if (location.state) {
      if (location.state.deviceId) {
        setMachineId(location.state.deviceId);
        setMachineOptions(location.state.deviceId.split(","));
      }
      if (location.state.name) {
        setMachineUser(location.state.name);
      }
      if (location.state.ip) {
        setMachineIp(location.state.ip);
        console.log("Server IP with port:", location.state.ip);
      }
    }
  }, [location.state]);

  useEffect(() => {
    setSavedFilters(loadFilters());
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`출입 기록 관리${machineUser ? ` (${machineUser})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.ml10}`}>
                <div className={`${styles.mt20}`} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", padding: "10px" }}>
                    <button
                      onClick={handlePrevPage}
                      disabled={currentPage === 0}
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "8px 12px",
                        cursor: currentPage === 0 ? "not-allowed" : "pointer",
                        opacity: currentPage === 0 ? 0.5 : 1,
                        transition: "background-color 0.3s",
                      }}
                    >
                      {"◀︎"}
                    </button>
                    <button
                      onClick={handleNextPage}
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "8px 12px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                      }}
                    >
                      {"▶︎"}
                    </button>
                    <select
                      value={limit}
                      onChange={handleLimitClick}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "8px 12px",
                        cursor: "pointer",
                        transition: "border-color 0.3s",
                      }}
                    >
                      <option value={25}>25행</option>
                      <option value={50}>50행</option>
                      <option value={100}>100행</option>
                      <option value={200}>200행</option>
                    </select>
                  </div>
                  <div style={{ fontSize: "12px", marginTop: "5px", color: "#000" }}>최근 페이지: {currentPage + 1}</div>
                </div>

                <form onSubmit={submit} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.ml10}`}>
                  <div className={`${styles.flex1}`} style={{ position: "relative" }}>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.flex1}`}
                      style={{
                        borderBottomLeftRadius: showOptions ? 0 : 10,
                        borderBottomRightRadius: showOptions ? 0 : 10,
                      }}
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      <div className={`${styles.borderRightGrayA2} ${styles.px15} ${styles.py15}`} style={{ width: 350, height: "45px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`} style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                          ({formatDateKST(start).replace(", ", " ")} ~ {formatDateKST(end).replace(", ", " ")}) {getRangeLabel(selectedRange) === "사용자 지정" ? "\n사용자 지정" : getRangeLabel(selectedRange)}
                        </p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10}`}>
                        <p className={`${styles.font14} ${selectedRange ? styles.black : styles.grayA2}`}>{selectedRange ? <span>{getRangeLabel(selectedRange)}</span> : "기간을 선택해 주세요"}</p>
                        <img src={showOptions ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"기간 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                      </div>
                    </div>
                    {showOptions && (
                      <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.widthFull}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                          <div className={`${styles.borderRightGrayA2} ${styles.px20}`} style={{ width: 350 }}></div>
                          <div className={`${styles.py10} ${styles.flex1}`} style={{ width: 200 }}>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("1day")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>1일</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("3days")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>3일</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("1week")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>1주</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("1month")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>1달</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("3months")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>3달</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleRangeChange("6months")}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>6달</p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`} onClick={handleCustomRange}>
                              <p className={`${styles.fontM} ${styles.font13} ${styles.black}`}>사용자 지정</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal isOpen={showCustom} onRequestClose={() => setShowCustom(false)} style={modalCalendar}>
                      <div style={{ position: "relative" }}>
                        <div style={{ position: "relative", top: "100%", left: 0, width: "100%", zIndex: 10 }}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.cursorPointer}`} onClick={() => setShowCustom(false)}>
                            <div></div>
                            <img
                              src={require("../../../assets/images/icon_close.png").default}
                              alt={"이전"}
                              className={`${styles.cursorPointer}`}
                              style={{
                                width: 30,
                                height: 30,
                              }}
                            />
                          </div>
                          <div className={`${styles.bgWhite} ${styles.px20} ${styles.mt20}`} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Calendar
                              calendarType={"US"}
                              value={start}
                              maxDate={new Date()}
                              minDate={new Date(2021, 0, 1)}
                              onChange={(date) => handleDateSelection(date, "start")}
                              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                              nextLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt="Next" className={`${styles.icon15}`} />
                                </span>
                              }
                              prevLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt="Previous" className={`${styles.icon15}`} />
                                </span>
                              }
                              style={{ width: "48%" }}
                            />
                            <span className={`${styles.px15}`} style={{ alignSelf: "center" }}>
                              ~
                            </span>
                            <Calendar
                              calendarType={"US"}
                              value={end}
                              maxDate={new Date()}
                              minDate={start} // End date must be after the start date
                              onChange={(date) => handleDateSelection(date, "end")}
                              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                              nextLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt="Next" className={`${styles.icon15}`} />
                                </span>
                              }
                              prevLabel={
                                <span>
                                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt="Previous" className={`${styles.icon15}`} />
                                </span>
                              }
                              style={{ width: "48%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </form>

                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.px10}`}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <input
                  type="text"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder="필터 이름"
                  style={{
                    width: "180px",
                    padding: "8px",
                    borderRadius: "12px",
                    border: "1px solid #ddd",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                    fontSize: "12px",
                    outline: "none",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                    marginRight: "10px",
                    color: "#333",
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#000";
                    e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#ddd";
                    e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
                  }}
                />
                <button onClick={handleSaveFilter} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer}`}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
                </button>
              </div>

              <div style={{ marginTop: "10px", position: "relative" }}>
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  style={{
                    width: "200px",
                    padding: "8px",
                    borderRadius: "12px",
                    border: "1px solid #ddd",
                    backgroundColor: "#f5f5f5",
                    cursor: "pointer",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  저장된 필터 보기
                </button>
                {dropdownOpen && (
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                      position: "absolute",
                      top: "40px",
                      left: 0,
                      width: "200px",
                      border: "1px solid #ddd",
                      borderRadius: "12px",
                      backgroundColor: "#fff",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      maxHeight: "200px",
                      overflowY: "auto",
                    }}
                  >
                    {savedFilters.map((filter, index) => (
                      <li key={index} style={{ display: "flex", alignItems: "center", padding: "8px", justifyContent: "space-between" }}>
                        {editingIndex === index ? (
                          <>
                            <input
                              type="text"
                              value={editingName}
                              onChange={(e) => setEditingName(e.target.value)}
                              placeholder="필터 이름"
                              style={{
                                width: "120px",
                                padding: "8px",
                                borderRadius: "12px",
                                border: "1px solid #ddd",
                                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                fontSize: "12px",
                                outline: "none",
                                transition: "border-color 0.3s, box-shadow 0.3s",
                                marginRight: "10px",
                                color: "#333",
                              }}
                              onFocus={(e) => {
                                e.target.style.borderColor = "#000";
                                e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
                              }}
                              onBlur={(e) => {
                                e.target.style.borderColor = "#ddd";
                                e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
                              }}
                            />
                            <button onClick={handleUpdateFilterName} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px10} ${styles.py3} ${styles.center} ${styles.cursorPointer}`} style={{ marginRight: "5px" }}>
                              <p className={`${styles.fontB} ${styles.font12} ${styles.white}`}>저장</p>
                            </button>
                          </>
                        ) : (
                          <>
                            <span
                              onClick={() => applyFilter(filter)}
                              style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                color: "black",
                                cursor: "pointer",
                                marginRight: "10px",
                                flexGrow: 1,
                              }}
                            >
                              {filter.name}
                            </span>
                            <button onClick={() => handleEditFilter(index)} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px10} ${styles.py3} ${styles.center} ${styles.cursorPointer}`} style={{ marginRight: "5px" }}>
                              <p className={`${styles.font12} ${styles.white}`}>수정</p>
                            </button>
                            <img
                              src={require("../../../assets/images/icon_trash.png").default}
                              alt={"삭제"}
                              style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => deleteFilter(filter.name)}
                            />
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>날짜</p>
              </div>
              <div className={`${styles.flex1}`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: "10px", fontSize: "14px", fontWeight: "bold", color: "black" }}>출입문</span>
                  <button onClick={handleDoorsButtonClick} className={`${styles.btnSelectUser} ${styles.bgGrayF4}`}>
                    <img src={require("../../../assets/images/icon_sort_down.png").default} alt="출입문" />
                  </button>
                </div>
              </div>
              <div className={`${styles.flex1}`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: "10px", fontSize: "14px", fontWeight: "bold", color: "black" }}>장치ID</span>
                  <button onClick={toggleDeviceModal} className={`${styles.btnSelectUser} ${styles.bgGrayF4}`}>
                    <img src={require("../../../assets/images/icon_sort_down.png").default} alt="장치ID" />
                  </button>
                </div>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>장치</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>사용자 그룹</p>
              </div>
              <div className={`${styles.flex1}`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: "10px", fontSize: "14px", fontWeight: "bold", color: "black" }}>사용자</span>
                  <button onClick={handleUserButtonClick} className={`${styles.btnSelectUser} ${styles.bgGrayF4}`}>
                    <img src={require("../../../assets/images/icon_sort_down.png").default} alt="사용자" />
                  </button>
                </div>
              </div>
              <div className={`${styles.flex2}`} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: "10px", fontSize: "14px", fontWeight: "bold", color: "black" }}>이벤트 로그</span>
                  <button onClick={handleLogButtonClick} className={`${styles.btnSelectUser} ${styles.bgGrayF4}`}>
                    <img src={require("../../../assets/images/icon_sort_down.png").default} alt="이벤트 로그" />
                  </button>
                </div>
              </div>
            </div>
            {loadingEvent ? (
              <Skeleton height={50} count={3} />
            ) : data && data.getEventLog ? (
              data.getEventLog.length > 0 ? (
                data.getEventLog.map((event) => <EventSearch key={event.id} data={event} codename={getCodename(event.event_type_id)} />)
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>검색 내역이 없습니다.</p>
                </div>
              )
            ) : (
              <div className={`${styles.center}`} style={{ height: 600 }}>
                <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>데이터를 불러올 수 없습니다.</p>
              </div>
            )}
            <div className={`${styles.mt20}`} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", padding: "10px" }}>
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 0}
                  style={{
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px 12px",
                    cursor: currentPage === 0 ? "not-allowed" : "pointer",
                    opacity: currentPage === 0 ? 0.5 : 1,
                    transition: "background-color 0.3s",
                  }}
                >
                  {"◀︎"}
                </button>
                <button
                  onClick={handleNextPage}
                  style={{
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "8px 12px",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                >
                  {"▶︎"}
                </button>
              </div>
              <div style={{ fontSize: "12px", marginTop: "5px", color: "#000" }}>최근 페이지: {currentPage + 1}</div>
            </div>
            {/**
            {data?.getEventLog?.length > limit - 1 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getEventLog?.length ? parseInt(400 / (data?.getEventLog?.length - 1)) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingEvent ? null : handleGoToPage}
                  initialPage={currentPage}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingEvent && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
             */}
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDeviceModal}
        onRequestClose={toggleDeviceModal}
        contentLabel="Select Device"
        style={{
          content: {
            width: "400px",
            margin: "0 auto",
            overflow: "hidden",
            maxHeight: "50vh",
            borderRadius: "15px",
            padding: "20px", // 패딩 추가
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            top: "10%", // 중앙으로 이동
          },
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 100,
          },
        }}
      >
        <div style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}>
          <h3 style={{ marginBottom: "20px", fontWeight: "bold", color: "#333" }}>장치ID</h3>
          <ul style={{ maxHeight: "40vh", overflowY: "auto", marginBottom: "20px", padding: "0" }}>
            <li
              onClick={() => {
                handleDropdownDeviceId("전체");
                toggleDeviceModal();
              }}
              style={{
                cursor: "pointer",
                padding: "10px",
                borderBottom: "1px solid #eee",
                transition: "background-color 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#f5f5f5";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "white";
              }}
            >
              전체( {machineOptions.join(", ")} )
            </li>
            {machineOptions.map((deviceId, index) => (
              <li
                key={index}
                onClick={() => {
                  handleDropdownDeviceId(deviceId);
                  toggleDeviceModal();
                }}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderBottom: "1px solid #eee",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#f5f5f5";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
              >
                {deviceId}
              </li>
            ))}
          </ul>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <button onClick={toggleDeviceModal} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>Close</p>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showDoorModal}
        onRequestClose={toggleDoorModal}
        contentLabel="Select Door"
        //shouldCloseOnOverlayClick={false}
        style={{
          content: {
            width: "400px",
            margin: "0 auto",
            overflow: "hidden",
            maxHeight: "80vh",
            borderRadius: "15px",
            padding: "20px",
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            top: "10%", // 중앙으로 이동
          },
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 100,
          },
        }}
      >
        <div style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <h3 style={{ marginBottom: "20px", fontWeight: "bold", color: "#333" }}>출입문 검색</h3>
            <button
              onClick={toggleDoorModal}
              style={{
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: "#000",
                color: "white",
                fontSize: "12px",
                cursor: "pointer",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#333";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#000";
              }}
            >
              X
            </button>
          </div>
          <input
            type="text"
            placeholder="Search doors..."
            value={doorSearchTerm}
            onChange={(e) => setDoorSearchTerm(e.target.value)}
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              outline: "none",
              transition: "border-color 0.3s, box-shadow 0.3s",
            }}
            onFocus={(e) => {
              e.target.style.borderColor = "#000";
              e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#ddd";
              e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
            }}
          />
          {/* {doorLoading ? (
            <p>Loading...</p>
          ) : doorError ? (
            <p>Error: {doorError.message}</p>
          ) : doorData && doorData.getAllDoor ? (
            <> */}
          {doorLoading ? (
            <p>Loading...</p>
          ) : doorData && doorData.getAllDoor ? (
            <>
              <ul style={{ maxHeight: "10vh", overflowY: "auto", marginBottom: "20px", padding: "0" }}>
                {selectedDoors.map((door) => (
                  <li key={door.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderBottom: "1px solid #eee" }}>
                    <span style={{ flex: 1, color: "#333" }}>{door.name}</span>
                    <button
                      onClick={() => handleDoorClick(door, "remove")}
                      style={{
                        padding: "5px 10px",
                        borderRadius: "8px",
                        border: "none",
                        backgroundColor: "#f44336",
                        color: "white",
                        fontSize: "12px",
                        cursor: "pointer",
                        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#d32f2f";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor = "#f44336";
                      }}
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <button onClick={() => handleDoorComplete(handleCompleteDoors)} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer} `}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
                </button>
              </div>
              <h3 style={{ marginBottom: "10px", fontWeight: "bold", color: "#333" }}>전체 문</h3>
            </>
          ) : (
            <p>No doors found.</p>
          )}
        </div>
        {doorData && doorData.getAllDoor && (
          <ul style={{ overflowY: "auto", maxHeight: "40vh", borderTop: "1px solid #ccc", width: "100%", padding: "0" }}>
            {remainingDoors.length > 0 ? (
              remainingDoors.map((door) => (
                <li
                  key={door.id}
                  onClick={() => handleDoorClick(door, "add")}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    borderBottom: "1px solid #eee",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#f5f5f5";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "white";
                  }}
                >
                  {door.name}
                </li>
              ))
            ) : (
              <p>No matching doors found.</p>
            )}
          </ul>
        )}
      </Modal>

      <Modal
        isOpen={showUserModal}
        onRequestClose={toggleUserModal}
        contentLabel="Select User"
        //shouldCloseOnOverlayClick={false}
        style={{
          content: {
            width: "400px",
            margin: "0 auto",
            overflow: "hidden",
            maxHeight: "80vh",
            borderRadius: "15px",
            padding: "20px",
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            top: "10%", // 중앙으로 이동
          },
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 100,
          },
        }}
      >
        <div style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <h3 style={{ fontWeight: "bold", color: "#333" }}>사용자 검색</h3>
            <button
              onClick={toggleUserModal}
              style={{
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: "#000",
                color: "white",
                fontSize: "12px",
                cursor: "pointer",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#333";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#000";
              }}
            >
              X
            </button>
          </div>
          <input
            type="text"
            placeholder="Search users..."
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              outline: "none",
              transition: "border-color 0.3s, box-shadow 0.3s",
            }}
            onFocus={(e) => {
              e.target.style.borderColor = "#000";
              e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#ddd";
              e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
            }}
          />
          {userLoading ? (
            <p>Loading...</p>
          ) : userError ? (
            <p>Error: {userError.message}</p>
          ) : userData && userData.getAllUsers ? (
            <>
              <ul style={{ maxHeight: "10vh", overflowY: "auto", marginBottom: "20px", padding: "0" }}>
                {selectedUsers.map((user) => (
                  <li key={user.user_id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderBottom: "1px solid #eee" }}>
                    <span style={{ flex: 1, color: "#333" }}>
                      {user.user_id}({user.name})
                    </span>
                    <button
                      onClick={() => handleUserClick(user, "remove")}
                      style={{
                        padding: "5px 10px",
                        borderRadius: "8px",
                        border: "none",
                        backgroundColor: "#f44336",
                        color: "white",
                        fontSize: "12px",
                        cursor: "pointer",
                        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                        transition: "background-color 0.3s",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#d32f2f";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor = "#f44336";
                      }}
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <button onClick={() => handleUserComplete(handleCompleteUsers)} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer}`}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
                </button>
              </div>
              <h3 style={{ marginBottom: "10px", fontWeight: "bold", color: "#333" }}>전체 사용자</h3>
            </>
          ) : (
            <p>No users found.</p>
          )}
        </div>
        {userData && userData.getAllUsers && (
          <ul style={{ overflowY: "auto", maxHeight: "40vh", borderTop: "1px solid #ccc", width: "100%", padding: "0" }}>
            {remainingUsers.length > 0 ? (
              remainingUsers.map((user) => (
                <li
                  key={user.user_id}
                  onClick={() => handleUserClick(user, "add")}
                  style={{
                    cursor: "pointer",
                    padding: "10px",
                    borderBottom: "1px solid #eee",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#f5f5f5";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "white";
                  }}
                >
                  {user.user_id}({user.name})
                </li>
              ))
            ) : (
              <p>No matching users found.</p>
            )}
          </ul>
        )}
      </Modal>

      <Modal
        isOpen={showLogModal}
        onRequestClose={toggleLogModal}
        contentLabel="Event Log Modal"
        //shouldCloseOnOverlayClick={false}
        style={{
          content: {
            width: "400px",
            margin: "0 auto",
            overflow: "hidden",
            maxHeight: "80vh",
            borderRadius: "15px",
            padding: "20px",
            border: "none",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            top: "10%", // 중앙으로 이동
          },
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 100,
          },
        }}
      >
        <div style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
            <h3 style={{ fontWeight: "bold", color: "#333" }}>이벤트로그 검색</h3>
            <button
              onClick={toggleLogModal}
              style={{
                padding: "6px 12px",
                borderRadius: "4px",
                border: "none",
                backgroundColor: "#000",
                color: "white",
                fontSize: "12px",
                cursor: "pointer",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#333";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#000";
              }}
            >
              X
            </button>
          </div>
          <input
            type="text"
            placeholder="Search logs..."
            value={logSearchTerm}
            onChange={handleSearchChange}
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "20px",
              borderRadius: "10px",
              border: "1px solid #ddd",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              outline: "none",
              transition: "border-color 0.3s, box-shadow 0.3s",
            }}
            onFocus={(e) => {
              e.target.style.borderColor = "#000";
              e.target.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.2)";
            }}
            onBlur={(e) => {
              e.target.style.borderColor = "#ddd";
              e.target.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.1)";
            }}
          />

          <ul style={{ maxHeight: "10vh", overflowY: "auto", marginBottom: "20px", padding: "0" }}>
            {selectedLogs.map((log) => (
              <li key={log.code} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", borderBottom: "1px solid #eee" }}>
                <span style={{ flex: 1, color: "#333" }}>{log.codename}</span>
                <button
                  onClick={() => handleLogClick(log, "remove")}
                  style={{
                    padding: "5px 10px",
                    borderRadius: "8px",
                    border: "none",
                    backgroundColor: "#f44336",
                    color: "white",
                    fontSize: "12px",
                    cursor: "pointer",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#d32f2f";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#f44336";
                  }}
                >
                  X
                </button>
              </li>
            ))}
          </ul>
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <button onClick={() => handleLogComplete(handleCompleteLogs)} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.cursorPointer}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
            </button>
          </div>
          <h3 style={{ marginBottom: "10px", fontWeight: "bold", color: "#333" }}>전체 로그</h3>
        </div>
        {remainingLogs.length > 0 && (
          <ul style={{ overflowY: "auto", maxHeight: "40vh", borderTop: "1px solid #ccc", width: "100%", padding: "0" }}>
            {remainingLogs.map((log) => (
              <li
                key={log.code}
                onClick={() => handleLogClick(log, "add")}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  borderBottom: "1px solid #eee",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => {
                  e.target.style.backgroundColor = "#f5f5f5";
                }}
                onMouseOut={(e) => {
                  e.target.style.backgroundColor = "white";
                }}
              >
                {log.codename}
              </li>
            ))}
          </ul>
        )}
      </Modal>
    </div>
  );
};

export default SearchEvent;
