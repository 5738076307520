import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import {
    numberWithCommas,
    setDateFormat_yyyyMMdd,
    setDateFormatKor_aahhmm,
    setDateFormatKor_day
} from "../../../common/utility";

const ScheduleListItem = ({ schedule, deleteSchedule, toScheduleEdit, isShowEditBtns}) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {schedule.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {schedule.teacher.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${setDateFormat_yyyyMMdd(new Date(schedule.date), "/")} ${setDateFormatKor_day(new Date(schedule.date), true)}`}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${setDateFormatKor_aahhmm(new Date(schedule.date + " " + schedule.startTime))} ~ ${setDateFormatKor_aahhmm(new Date(schedule.date + " " + schedule.endTime))}`}
                </p>
            </div>
            {isShowEditBtns && (
                <div
                    className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                    <div
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.mr12} ${styles.cursorPointer}`}
                        onClick={() => toScheduleEdit(schedule.id)}
                    >
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            해당 스케쥴만 수정
                        </p>
                    </div>
                    <div
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                        onClick={() => deleteSchedule(schedule.id)}
                    >
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            해당 스케쥴만 삭제
                        </p>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ScheduleListItem;
