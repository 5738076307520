import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { SketchPicker } from 'react-color';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const ADD_TEACHER = gql`
    mutation addTeacher($machine: String!, $name: String!, $color: String!) {
        addTeacher(machine: $machine, name:$name, color:$color) {
            success
            error
        }
    }
`;

const TeacherAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const name = useInput('');
    const [color, setColor] = useState('');
    const [showColor, setShowColor] = useState(false);
    const [addTeacher] = useMutation(ADD_TEACHER);

    const [loading, setLoading] = useState(false);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const handleColor = color => {
        setColor(color.hex);
        setShowColor(false);
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);
            if (data && data.getMachine) {
                if (name.value&&color) {
                    if (name.value.length<=10) {
                        console.log('name : ',name.value);
                        console.log('color : ',color);
                        const { data: result } = await addTeacher({
                            variables: {
                                machine: location.state.machine ? location.state.machine : '',
                                name: name.value,
                                color: color
                            }
                        });
                        if (result && result.addTeacher) {
                            if (result.addTeacher.error) {
                                setLoading(false);
                                alert(result.addTeacher.error);
                            } else {
                                setLoading(false);
                                alert('강사 정보를 저장하였습니다.');
                                initialize();
                            }
                        } else {
                            setLoading(false);
                            alert('오류가 발생하였습니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('강사명을 10글자 이내로 작성해주세요.');
                    }
                } else {
                    setLoading(false);
                    alert('필수 값을 모두 입력해주세요.');
                }
            }
        }
    }

    const initialize = () => {
        name.setValue('');
        setColor('');
        setLoading(false);
    };

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                강사 추가
                                {`${
                                    data && data.getMachine
                                        ? ` (${data.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div style={{ width: 1250 }}>
                                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            강사명*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        value={name.value}
                                        onChange={name.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '강사명을 입력해주세요.'
                                        }
                                        maxLength={10}
                                    />
                                </div>
                                <div>
                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                스케쥴 표시 색상*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1}`}>
                                                <p
                                                    className={`${
                                                        styles.font16
                                                    } ${
                                                        color
                                                            ? styles.black
                                                            : styles.grayA2
                                                    }`}>
                                                    {color === '' ? '스케쥴에 표시될 강사의 색상을 선택해주세요.' : color === '#FF7701' ? '기본 색상' : color}
                                                </p>
                                                <div
                                                    className={`${styles.ml10}`}
                                                    style={{
                                                        width: 50,
                                                        height: 20,
                                                        backgroundColor: color,
                                                        display: color===''?'none':''
                                                    }}
                                                />
                                            </div>
                                            <p
                                                className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.underline} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    setShowColor(!showColor)
                                                }>
                                                변경
                                            </p>
                                        </div>
                                    </div>
                                    {showColor && (
                                        <div
                                            className={`${styles.center}`}
                                            style={{
                                                width: 615,
                                                position: 'absolute',
                                            }}>
                                            <SketchPicker
                                                color={color}
                                                onChange={handleColor}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb40}`} style={{ width: 1250, marginTop:'30%' }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py17} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        완료
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherAdd;
