import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Calendar from "react-calendar";
import NumberFormat from "react-number-format";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";
import { bill, membership as membershipModal } from "../../../common/modal";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import MembershipHolding from "./MembershipHolding";

const ActiveMembershipItem = ({ membership: defaultMembership, index, isEntrance, selected, memberships, setDefaultMembershipId, removeMembership, editMembership, createMembership, changeDefaultMembership, confirmEditing }) => {
  const price = useInput("");
  const date = useInput("");

  const [membership, setMembership] = useState({
    ...defaultMembership,
    start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
    end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
    membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
    cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
  });
  const [showMembership, setShowMembership] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [calcMembershipCount, setCalcMembershipCount] = useState(Number(membership.membershipCount) - Number(membership.totalDeduct));
  const [calcCancelCount, setCalcCancelCount] = useState(Number(membership.cancelCount) - Number(membership.totalCancel));
  const [editing, setEditing] = useState(membership.id === -1);
  const [showHolding, setShowHolding] = useState(false);
  const [addType, setAddType] = useState(membership.payment ? membership.payment.type : "added_by_admin");
  const [dDay, setDDay] = useState("");

  const handleMembership = (product) => {
    console.log("membership.isUsed ::::: ", membership.isUsed);
    if (membership.isUsed) {
      alert("사용중인 회원권은 회원권 변경이 불가능합니다.");
      return;
    }

    // membership.start = new Date();
    // if(product.membershipType === 2) {
    //     let end = new Date();
    //     end.setFullYear(end.getFullYear() + 10);
    //     membership.end = end;
    // } else {
    //     membership.start = new Date();
    //     membership.end = null;
    // }

    if (product.membershipType !== 1 && membership.id === -1) {
      setCalcMembershipCount(product.membership);
      setCalcCancelCount(product.cancel);
      membership.membershipCount = product.membership;
      membership.cancelCount = product.cancel;
    }

    setMembership({
      ...membership,
      product,
    });
    setShowMembership(false);
  };

  const handleStart = (start) => {
    if (membership.id === -1) {
      if (membership.product.duration) {
        setMembership({
          ...membership,
          start,
          end: new Date(start.getTime() + 1000 * 60 * 60 * 24 * (membership.product.duration - 1)),
        });
      } else {
        setMembership({
          ...membership,
          start,
        });
      }
    } else {
      setMembership({
        ...membership,
        start,
      });
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setMembership({
      ...membership,
      end,
    });
    setShowEnd(false);
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcMembershipCount(value);
      setMembership({
        ...membership,
        membershipCount: Number(value) + (membership.totalDeduct ? Number(membership.totalDeduct) : ""),
      });
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcCancelCount(value);
      setMembership({
        ...membership,
        cancelCount: Number(value) + (membership.totalCancel ? Number(membership.totalCancel) : 0),
      });
    }
  };

  const handlePrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      price.setValue(value);
      setMembership({ ...membership, price: value });
    }
  };

  const handleDate = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      date.setValue(value);
      setMembership({ ...membership, date: value });
    }
  };

  const edit = async () => {
    //debugger;
    let tmpPrice = price.value !== "" ? Number(price.value) : null;
    let approvalDate = date.value !== "" ? date.value.substring(0, 6) : null;
    let approvalTime = date.value !== "" ? `${date.value.substring(6, 10)}00` : null;

    if ((tmpPrice === null && date.value !== "") || (tmpPrice !== null && date.value === "")) {
      alert("결제금액과 결제일시는 같이 입력되어야 합니다.");
      return;
    }
    if (approvalTime && approvalTime.length !== 6 && approvalDate && approvalDate.length !== 6) {
      alert("올바른 결제일시를 입력해주세요.");
      return;
    }

    setEditing(false);
    const result = await editMembership({
      ...membership,
      payment: {
        id: -1,
        totalAmount: tmpPrice,
        approvalDate: approvalDate,
        approvalTime: approvalTime,
      },
      isDefault: selected,
    });
    setEditing(!result);
  };

  const create = async () => {
    if ((price.value !== "" && date.value === "") || (price.value === "" && date.value !== "")) {
      alert("결제금액과 결제일시는 같이 입력되어야 합니다.");
      return;
    }

    if (membership.id === -1) {
      if (membership.product.id === -1) {
        alert("회원권명을 선택해주세요.");
        return;
      }

      if (membership.product.membershipType !== 2) {
        if (!membership.start) {
          alert("회원권 시작일을 선택해주세요.");
          return;
        }
      }

      if (membership.product.membershipType === 2 && membership.product.membershipType === 3) {
        // if(!membership.membershipCount) {
        //     alert('회원권 횟수를 선택해주세요.');
        //     return;
        // }
        if (!membership.cancelCount && !membership.product.ignoreCancel) {
          alert("회원권 취소 가능 횟수를 선택해주세요.");
          return;
        }
      }

      let approvalDate = date.value ? date.value.substring(0, 6) : "";
      let approvalTime = date.value ? `${date.value.substring(6, 10)}00` : "";

      if (date.value && date.value.length !== 10) {
        alert("올바른 결제일시를 입력해주세요.");
        return;
      }

      createMembership({
        ...membership,
        payment: {
          id: -1,
          totalAmount: Number(price.value),
          approvalDate: approvalDate,
          approvalTime: approvalTime,
        },
        isDefault: selected,
      });
    }
  };

  useEffect(() => {
    price.setValue(membership.payment ? membership.payment.totalAmount : "");
    date.setValue(membership.payment ? membership.payment.approvalDate + membership.payment.approvalTime : "");
  }, [membership.payment]);

  useEffect(() => {
    setMembership({
      ...defaultMembership,
      start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
      end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
      membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
      cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
    });
  }, [editing, defaultMembership]);

  useEffect(() => {
    const now = new Date();
    const tmpNow = new Date(now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate());
    let tmpDDay = membership.end - tmpNow;
    console.log(membership.end);
    console.log(now);
    setDDay(Math.floor(tmpDDay / (1000 * 60 * 60 * 24)));
  }, [membership.end]);

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.py15} ${styles.px15} ${index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf}`}>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={editing ? () => setShowMembership(!showMembership) : null}>
        <p className={`${styles.fontR} ${styles.font14} ${membership.product.id === -1 ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>{membership.product.id === -1 ? "선택해주세요." : membership.product.name}</p>
        {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}>
        <div style={{ display: membership.product.membershipType === 1 || membership.product.membershipType === 3 || membership.product.membershipType === 4 ? "flex" : "none" }} onClick={editing ? () => setShowStart(!showStart) : null}>
          <p className={`${styles.fontR} ${styles.font14} ${membership.id === -1 && !membership.start ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.id === -1
              ? membership.start
                ? `${membership.start.getFullYear()}/${membership.start.getMonth() + 1 < 10 ? "0" + String(membership.start.getMonth() + 1) : membership.start.getMonth() + 1}/${membership.start.getDate() < 10 ? "0" + String(membership.start.getDate()) : membership.start.getDate()}`
                : "선택해주세요."
              : membership.start
              ? `${membership.start.getFullYear()}/${membership.start.getMonth() + 1 < 10 ? "0" + String(membership.start.getMonth() + 1) : membership.start.getMonth() + 1}/${membership.start.getDate() < 10 ? "0" + String(membership.start.getDate()) : membership.start.getDate()}`
              : ""}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}>
        <div className={`${styles.flex1}`} style={{ display: membership.product.membershipType === 1 || membership.product.membershipType === 3 || membership.product.membershipType === 4 ? "flex" : "none" }} onClick={editing ? () => setShowEnd(!showEnd) : null}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.flex1} ${membership.id === -1 && !membership.end ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.id === -1
              ? membership.end
                ? `${membership.end.getFullYear()}/${membership.end.getMonth() + 1 < 10 ? "0" + String(membership.end.getMonth() + 1) : membership.end.getMonth() + 1}/${membership.end.getDate() < 10 ? "0" + String(membership.end.getDate()) : membership.end.getDate()}`
                : "선택해주세요."
              : membership.end
              ? `${membership.end.getFullYear()}/${membership.end.getMonth() + 1 < 10 ? "0" + String(membership.end.getMonth() + 1) : membership.end.getMonth() + 1}/${membership.end.getDate() < 10 ? "0" + String(membership.end.getDate()) : membership.end.getDate()}`
              : ""}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
      </div>
      <div className={`${styles.flex1}`}>
        <NumberFormat
          value={calcMembershipCount}
          thousandSeparator={true}
          className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
          type={"tel"}
          displayType={"input"}
          onValueChange={handleMembershipCount}
          placeholder={editing ? "횟수를 입력해주세요. (숫자만 입력)" : ""}
          style={{
            width: "100%",
            minWidth: 20,
            display: membership.product.membershipType === 2 || membership.product.membershipType === 3 ? "block" : "none",
          }}
          readOnly={!editing}
        />
      </div>

      <div className={`${styles.flex1}`}>
        {membership.product.ignoreCancel ? (
          <div className={`${styles.flex1}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.flex1} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>무제한</p>
          </div>
        ) : (
          <NumberFormat
            value={calcCancelCount}
            thousandSeparator={true}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handleCancelCount}
            placeholder={editing ? "취소 가능 횟수를 입력해주세요. (숫자만 입력)" : ""}
            style={{
              width: "100%",
              minWidth: 20,
              display: membership.product.membershipType === 2 || membership.product.membershipType === 3 ? "block" : "none",
            }}
            readOnly={!editing}
          />
        )}
      </div>

      <p className={`${styles.fontR} ${styles.font14} ${membership.id === -1 ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
        {membership.id === -1 ? (
          "자동입력"
        ) : (
          <>
            {membership.status === "inactive" && "비활성"}
            {membership.status === "active" && "유효"}
            {membership.status === "active" && <br />}
            {membership.status === "active" && "D-" + dDay}
            {membership.status === "ready" && "사용대기"}
            {membership.status === "expired" && "만료"}
            {membership.status === "holding" && "홀딩중"}
          </>
        )}
      </p>
      {membership.id === -1 ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={price.value}
            thousandSeparator={true}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handlePrice}
            placeholder={"결제 금액을 입력해주세요. (숫자만 입력)"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : editing && addType === "added_by_admin" ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={price.value}
            thousandSeparator={true}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handlePrice}
            placeholder={"결제 금액을 입력해주세요. (숫자만 입력)"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : (
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>{membership.payment ? numberWithCommas(membership.payment.totalAmount) : ""}</p>
      )}
      {membership.id === -1 ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={date.value}
            thousandSeparator={false}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handleDate}
            placeholder={"YYMMDD HH:MM"}
            format={"###### ##:##"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : editing && addType === "added_by_admin" ? (
        <div className={`${styles.flex1}`}>
          <NumberFormat
            value={date.value}
            thousandSeparator={false}
            className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handleDate}
            placeholder={"YYMMDD HH:MM"}
            format={"###### ##:##"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
            readOnly={!editing}
          />
        </div>
      ) : (
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
          {membership.payment && membership.payment.approvalDate && membership.payment.approvalTime ? `${membership.payment.approvalDate.slice(0, 2)}/${membership.payment.approvalDate.slice(2, 4)}/${membership.payment.approvalDate.slice(4)}` : ""}
          <br />
          {membership.payment && membership.payment.approvalDate && membership.payment.approvalTime ? `${membership.payment.approvalTime.slice(0, 2)}:${membership.payment.approvalTime.slice(2, 4)}:${membership.payment.approvalTime.slice(4)}` : ""}
        </p>
      )}
      {membership.id === -1 ? (
        <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
          <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`} onClick={setDefaultMembershipId}>
            {selected && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
          </div>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={setDefaultMembershipId}>
            대표
          </p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={create}>
            저장
          </p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.hidden} ${styles.ml10}`}>홀딩</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.hidden} ${styles.ml10}`}>삭제</p>
        </div>
      ) : editing ? (
        <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
          <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`} onClick={changeDefaultMembership}>
            {selected && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
          </div>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={changeDefaultMembership}>
            대표
          </p>
          <p
            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
            onClick={() => {
              edit();
              confirmEditing(false);
            }}
          >
            저장
          </p>
          <p
            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
            onClick={() => {
              setEditing(false);
              confirmEditing(false);
            }}
          >
            취소
          </p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.hidden} ${styles.ml10}`}>삭제</p>
        </div>
      ) : (
        <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
          <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`} onClick={changeDefaultMembership}>
            {selected && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
          </div>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={changeDefaultMembership}>
            대표
          </p>
          <p
            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}
            onClick={() => {
              setEditing(true);
              confirmEditing(true);
            }}
          >
            변경
          </p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setShowHolding(true)}>
            홀딩
          </p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`} onClick={removeMembership}>
            삭제
          </p>
        </div>
      )}
      <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={bill}>
        <div
          className={`${styles.bgWhite} ${styles.hideScroll}`}
          style={{
            maxHeight: 300,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {memberships
            ? memberships.map((item) => {
                if (!(item.membershipType === 2 && isEntrance)) {
                  return (
                    <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                      <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                    </div>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
          <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
        </div>
      </Modal>
      <Modal isOpen={showStart} onRequestClose={() => setShowStart(false)} style={bill}>
        <Calendar
          calendarType={"US"}
          value={membership.start}
          maxDate={membership.end ? membership.end : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleStart}
        />
      </Modal>
      <Modal isOpen={showEnd} onRequestClose={() => setShowEnd(false)} style={bill}>
        <Calendar
          calendarType={"US"}
          value={membership.end}
          minDate={membership.start ? membership.start : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleEnd}
        />
      </Modal>
      <Modal isOpen={showHolding} onRequestClose={() => setShowHolding(false)} style={membershipModal}>
        <MembershipHolding membership={membership} close={() => setShowHolding(false)} />
      </Modal>
    </div>
  );
};

export default ActiveMembershipItem;
