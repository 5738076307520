import React, { useEffect, useState, useRef } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg } from '../../../common/regex';
import IntroImage from '../../../components/Home/IntroImage';
import { FETCH_URL } from '../../../config';
import cache from '../../../apollo/cache';
import Calendar from "react-calendar";
import ScheduleTime from "../../../components/Schedule/Mobile/ScheduleTime";
import {getAmPm, getHour, getHourHH, getMinute} from "../../../common/utility";
import ScheduleDays from "../../../components/Schedule/PC/ScheduleDays";

const SAVE = gql`
    mutation saveSchedule(
        $machine: String
        $scheduleId: Int
        $editMode: String
        $name: String
        $teacherId: Int
        $categoryId: Int
        $limit: Int
        $minusCount: Int
        $productIds: String
        $availableTime: Int
        $cancelTime:Int
        $date: String
        $startTime: String
        $endTime: String
        $loopType: Int
        $startDate: String
        $endDate: String
        $isMon: Boolean
        $isTue: Boolean
        $isWed: Boolean
        $isThu: Boolean
        $isFir: Boolean
        $isSat: Boolean
        $isSun: Boolean
        $loopStartTime: String
        $loopEndTime: String
        $intervalTime: Int
        $runningTime: Int
    ) {
        saveSchedule(
            machine: $machine
            scheduleId: $scheduleId
            editMode: $editMode
            name: $name
            teacherId: $teacherId
            categoryId: $categoryId
            limit: $limit
            minusCount: $minusCount
            productIds: $productIds
            availableTime: $availableTime
            cancelTime: $cancelTime
            date: $date
            startTime: $startTime
            endTime: $endTime
            loopType: $loopType
            startDate: $startDate
            endDate: $endDate
            isMon: $isMon
            isTue: $isTue
            isWed: $isWed
            isThu: $isThu
            isFir: $isFir
            isSat: $isSat
            isSun: $isSun
            loopStartTime: $loopStartTime
            loopEndTime: $loopEndTime
            intervalTime: $intervalTime
            runningTime: $runningTime
        ) {
            error
            schedule {
                id
            }
        }
    }
`;

const PRODUCT = gql`
    query getProducts($machine: String!, $order: String!) {
        getProducts(type: "membership", machine: $machine, order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
            }
            total
        }
    }
`;

const TEACHER = gql`
    query getTeacherAdmin($machine: String!) {
        getTeacherAdmin(machine: $machine) {
            id
            name
        }
    }
`;

const CATEGORY = gql`
    query getCategoryAdmin($machine: String!) {
        getCategoryAdmin(type: "schedule", machine: $machine) {
            id
            name
            productCount
        }
    }
`;

const CATEGORY_DELETE = gql`
    mutation deleteCategory($categoryId: Int!) {
        deleteCategory(categoryId: $categoryId)
    }
`;

const CATEGORY_ADD = gql`
    mutation addCategory($machine: String!, $name: String!) {
        addCategory(machine: $machine, type: "schedule", name: $name) {
            id
            name
            productCount
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const DETAIL_SCHEDULE = gql`
    query getSchedule($scheduleId: Int) {
        getSchedule(scheduleId: $scheduleId) {
            schedule {
                id
                name
                limit
                minusCount
                availableTime
                cancelTime
                date
                startTime
                endTime
                scheduleProducts {
                    id
                    productId
                    product {
                        name
                    }
                }
                teacher {
                    id
                    name
                }
                category {
                    id
                    name
                }
                scheduleGroup {
                    id
                    isMon
                    isTue
                    isWed
                    isThu
                    isFir
                    isSat
                    isSun
                    startTime
                    endTime
                    startDate
                    endDate
                    type
                    intervalTime
                    runningTime
                }
            }
            error
        }
    }
`;

const EDIT_SCHEDULE_CHECKED = gql`
    mutation editScheduleChecked($scheduleGroupId: Int!, $scheduleId: Int!, $type: String!) {
        editScheduleChecked(scheduleGroupId: $scheduleGroupId, scheduleId: $scheduleId, type: $type) {
            success
            error
        }
    }
`;

const ScheduleAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const name = useInput('');
    const limit = useInput('');
    const minusCount = useInput('');
    const availableHour = useInput('');
    const availableMin = useInput('');
    const cancelHour = useInput('');
    const cancelMin = useInput('');
    const startTimeHour = useInput('');
    const startTimeMin = useInput('');
    const endTimeHour = useInput('');
    const endTimeMin = useInput('');
    const categoryName = useInput('');
    const loopStartTimeHour = useInput('');
    const loopStartTimeMin = useInput('');
    const loopEndTimeHour = useInput('');
    const loopEndTimeMin = useInput('');
    const loopTimeHour = useInput('');
    const loopTimeMin = useInput('');
    const loopTermTimeHour = useInput('');
    const loopTermTimeMin = useInput('');

    const [scheduleId, setScheduleId] = useState(null);
    const [editMode, setEditMode] = useState(location.state.editMode ? location.state.editMode : null);
    const [teacher, setTeacher] = useState(null);
    const [showTeacher, setShowTeacher] = useState(false);
    const [category, setCategory] = useState(null);
    const [showCategory, setShowCategory] = useState(false);
    const [showCategoryAdd, setShowCategoryAdd] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const [showMemberships, setShowMemberships] = useState(false);
    const [membershipNames, setMembershipNames] = useState('');
    const [loopType, setLoopType] = useState(null);
    const [showLoopType, setShowLoopType] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [showDate, setShowDate] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [showStartDate, setShowStartDate] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [showEndDate, setShowEndDate] = useState(false);
    const [startTimeAmPm, setStartTimeAmPm] = useState('AM');
    const [endTimeAmPm, setEndTimeAmPm] = useState('AM');
    const [loopStartTimeAmPm, setLoopStartTimeAmPm] = useState('AM');
    const [loopEndTimeAmPm, setLoopEndTimeAmPm] = useState('AM');
    const [selectDays, setSelectDays] = useState([false, false, false, false, false, false, false]);
    const [selectDaysString, setSelectDaysString] = useState(null);
    const [showSelectDays, setShowSelectDays] = useState(false);


    const {
        data : products,
        loading: loadingProduct,
        refetch: productsRefetch,
    } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: 'name_asc',
        },
    });
    const {data: teachers} = useQuery(TEACHER, {
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const {data, refetch: refetchCategory} = useQuery(CATEGORY, {
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const {data: machine} = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });
    const {data: schedule, refetch: refetchSchedule} = useQuery(DETAIL_SCHEDULE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            scheduleId: location.state.scheduleId ? location.state.scheduleId : -1,
        },
    });

    const [saveScheduleM] = useMutation(SAVE);
    const [deleteCategoryM] = useMutation(CATEGORY_DELETE);
    const [addCategoryM] = useMutation(CATEGORY_ADD);
    const [editScheduleCheckedM] = useMutation(EDIT_SCHEDULE_CHECKED);

    const [detailLoading, setDetailLoading] = useState(location.state.scheduleId ? true : false);

    const handleTeacher = teacher => {
        setTeacher(teacher);
        setShowTeacher(false);
    };

    const handleCategory = category => {
        setCategory(category);
        setShowCategory(false);
    };

    const handleLimit = values => {
        const {value} = values;
        limit.setValue(value);
    };

    const handleMinusCount = values => {
        const {value} = values;
        minusCount.setValue(value);
    };

    const handleAvailableHour = values => {
        const {value} = values;
        availableHour.setValue(value);
    };

    const handleAvailableMin = values => {
        const {value} = values;
        availableMin.setValue(value);
    };

    const handleCancelHour = values => {
        const {value} = values;
        cancelHour.setValue(value);
    };

    const handleCancelMin = values => {
        const {value} = values;
        cancelMin.setValue(value);
    };

    const handleMemberships = membership => {
        let _memberships = [...memberships];

        let idx = _memberships.indexOf(membership);
        if (_memberships[idx].checked) {
            _memberships[idx].checked = false;
        } else {
            _memberships[idx] = {..._memberships[idx], checked: true};
        }

        setMemberships(_memberships);
    };

    const handleLoopType = loopType => {
        setLoopType(loopType);
        setShowLoopType(false);
    };

    const handleDate = date => {
        // 0시로 맞춰주기 위해
        let _date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        setDate(_date);
        setShowDate(false);
    };

    const handleStartDate = startDate => {
        // 0시로 맞춰주기 위해
        let _startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        setStartDate(_startDate);
        setShowStartDate(false);
    };

    const handleEndDate = endDate => {
        // 0시로 맞춰주기 위해
        let _endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        setEndDate(_endDate);
        setShowEndDate(false);
    };

    const handleStartTimeAmPm = startTimeAmPm => {
        setStartTimeAmPm(startTimeAmPm);
    };

    const handleStartTimHour = values => {
        const {value} = values;
        startTimeHour.setValue(value);
    };

    const handleStartTimMin = values => {
        const {value} = values;
        startTimeMin.setValue(value);
    };

    const handleEndTimeAmPm = endTimeAmPm => {
        setEndTimeAmPm(endTimeAmPm);
    };

    const handleEndTimHour = values => {
        const {value} = values;
        endTimeHour.setValue(value);
    };

    const handleEndTimMin = values => {
        const {value} = values;
        endTimeMin.setValue(value);
    };

    const handleSelectDays = index => {
        let _selectDays = [...selectDays];

        _selectDays[index] = !_selectDays[index];

        setSelectDays(_selectDays);
    };

    const handleLoopStartTimeAmPm = loopStartTimeAmPm => {
        setLoopStartTimeAmPm(loopStartTimeAmPm);
    };

    const handleLoopStartTimHour = values => {
        const {value} = values;
        loopStartTimeHour.setValue(value);
    };

    const handleLoopStartTimMin = values => {
        const {value} = values;
        loopStartTimeMin.setValue(value);
    };

    const handleLoopEndTimeAmPm = loopEndTimeAmPm => {
        setLoopEndTimeAmPm(loopEndTimeAmPm);
    };

    const handleLoopEndTimHour = values => {
        const {value} = values;
        loopEndTimeHour.setValue(value);
    };

    const handleLoopEndTimMin = values => {
        const {value} = values;
        loopEndTimeMin.setValue(value);
    };

    const handleLoopTimeHour = values => {
        const {value} = values;
        loopTimeHour.setValue(value);
    };

    const handleLoopTimeMin = values => {
        const {value} = values;
        loopTimeMin.setValue(value);
    };

    const handleLoopTermTimeHour = values => {
        const {value} = values;
        loopTermTimeHour.setValue(value);
    };

    const handleLoopTermTimeMin = values => {
        const {value} = values;
        loopTermTimeMin.setValue(value);
    };

    const addCategory = async () => {
        if (!loading) {
            if (categoryName.value) {
                setLoading(true);
                const {data} = await addCategoryM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        name: categoryName.value,
                    },
                });
                setLoading(false);
                if (data && data.addCategory) {
                    refetchCategory();
                    setCategory(data.addCategory);
                    setShowCategory(false);
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } else {
                alert('추가할 분류명을 입력해주세요.');
            }
        }
    };

    const removeCategory = async selected => {
        if (!loading) {
            if (selected && selected.id) {
                const confirm = window.confirm('정말 삭제하시겠습니까?');
                if (confirm) {
                    if (selected.productCount === 0) {
                        setLoading(true);
                        const {data} = await deleteCategoryM({
                            variables: {
                                categoryId: selected.id,
                            },
                        });
                        setLoading(false);
                        if (data && data.deleteCategory) {
                            if (category.id === selected.id) {
                                setCategory({});
                            }
                            cache.evict({
                                id: `Category:${selected.id}`,
                            });
                        } else {
                            alert(
                                '해당 카테고리에 현재 스케쥴이 존재합니다.',
                            );
                        }
                    } else {
                        alert(
                            '해당 카테고리에 현재 스케쥴이 존재합니다.',
                        );
                    }
                }
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            let membershipIds = [];

            for (var i = 0; i < memberships.length; i++) {
                if (memberships[i].checked) {
                    membershipIds.push(memberships[i].id);
                }
            }

            if (
                name.value && // 스케쥴명
                teacher && // 강사
                category && // 카테고리
                limit.value && // 수강 인원
                minusCount.value && // 차감 횟수
                membershipIds.length > 0 && // 예약 가능 회원권
                (availableHour.value || availableMin.value || (availableHour.value === 0 && availableMin.value === 0)) && // 예약 가능 시간
                (cancelHour.value || cancelMin.value || (cancelHour.value === 0 && cancelMin.value === 0)) && // 예약 취소 가능 시간
                (
                    (loopType && editMode !== 'one' && // 추가, 관련 스케쥴 수정시
                        (
                            (
                                loopType === 1 && // 반복 안 함
                                loopType1DataCheck()
                            ) ||
                            (
                                loopType === 2 && // 일 단위 반복
                                loopType2DataCheck()
                            ) ||
                            (
                                loopType === 3 && // 시간 단위 반복
                                loopType3DataCheck()
                            ) ||
                            (
                                loopType === 4 && // 일 + 시간 단위 반복
                                loopType2DataCheck() &&
                                loopType3DataCheck()
                            )
                        )
                    ) || (
                        editMode === 'one' && // 한개의 스케쥴만 수정시
                        loopType1DataCheck()
                    )
                )
            ) {
                if (Number(minusCount.value) < 0) {
                    alert('차감 횟수를 다시 확인해주세요.');
                    return;
                }
                if (Number(availableMin.value) > 59) {
                    alert('예약 가능 시간을 다시 확인해주세요.');
                    return;
                }
                if (Number(cancelMin.value) > 59) {
                    alert('예약 취소 가능 시간을 다시 확인해주세요.');
                    return;
                }
                if (loopType === 1 || loopType === 2) {
                    if (Number(startTimeHour.value) > 12 || Number(startTimeMin.value) > 59) {
                        alert('스케쥴 시작 시간을 다시 확인해주세요.');
                        return;
                    }
                    if (Number(endTimeHour.value) > 12 || Number(endTimeMin.value) > 59) {
                        alert('스케쥴 종료 시간을 다시 확인해주세요.');
                        return;
                    }

                    let checkStartTimeMin = startTimeMin.value % 10 !== 0;
                    let checkEndTimeMin = endTimeMin.value % 10 !== 0;
                    if (checkStartTimeMin || checkEndTimeMin) {
                        alert((checkStartTimeMin ? '시작 시간' : '종료 시간') + ' (분) 은 10분 단위로 입력해주세요.');
                        return;
                    }
                } else if (loopType === 3 || loopType === 4) {
                    if (date.length < 1 && loopType === 3) {
                        alert('위의 정보를 입력해주세요.');
                        return;
                    }
                    if (Number(loopStartTimeHour.value) > 12 || Number(loopStartTimeMin.value) > 59) {
                        alert('스케쥴 반복 시작 시간을 다시 확인해주세요.');
                        return;
                    }
                    if (Number(loopEndTimeHour.value) > 12 || Number(loopEndTimeMin.value) > 59) {
                        alert('스케쥴 반복 종료 시간을 다시 확인해주세요.');
                        return;
                    }
                    if (Number(loopTimeHour.value) > 12 || Number(loopTimeMin.value) > 59) {
                        alert('스케쥴 진행 시간을 다시 확인해주세요.');
                        return;
                    }
                    if (Number(loopTermTimeHour.value) > 12 || Number(loopTermTimeMin.value) > 59) {
                        alert('스케쥴 사이 간격을 다시 확인해주세요.');
                        return;
                    }

                    let checkLoopStartTimeMin = loopStartTimeMin.value % 10 !== 0;
                    let checkLoopEndTimeMin = loopEndTimeMin.value % 10 !== 0;
                    let checkLoopTimeMin = loopTimeMin.value % 10 !== 0;
                    let checkLoopTermTimeMin = loopTermTimeMin.value % 10 !== 0;
                    if (checkLoopStartTimeMin || checkLoopEndTimeMin || checkLoopTimeMin || checkLoopTermTimeMin) {
                        alert((checkLoopStartTimeMin ? '반복 시작 시간' : (checkLoopEndTimeMin ? '반복 종료 시간' : (checkLoopTimeMin ? '진행 시간' : '사이 간격'))) + ' (분) 은 10분 단위로 입력해주세요.');
                        return;
                    }
                }

                if (confirmEditAlert()) {
                    setLoading(true);

                    let scheduleGroupId = -1;
                    let scheduleId = -1;
                    if (schedule && schedule.getSchedule && schedule.getSchedule.schedule) {
                        if (editMode === 'all') {
                            scheduleGroupId = schedule.getSchedule.schedule.scheduleGroup.id;
                            scheduleId = -1;
                        } else {
                            scheduleGroupId = -1;
                            scheduleId = schedule.getSchedule.schedule.id;
                        }
                    }

                    if (category.id < 0) {
                        const {data: create} = await addCategoryM(
                            {
                                variables: {
                                    machine: location.state.machine
                                        ? location.state.machine
                                        : '',
                                    name: category.name,
                                },
                            },
                        );
                        if (create && create.addCategory) {
                            if (editMode) {
                                await editScheduleChecked(scheduleGroupId, scheduleId, create.addCategory.id, membershipIds);
                            } else {
                                await saveSchedule(create.addCategory.id, membershipIds);
                            }
                        } else {
                            setLoading(false);
                            alert('오류가 발생하였습니다.');
                        }
                    } else {
                        if (editMode) {
                            await editScheduleChecked(scheduleGroupId, scheduleId, category.id, membershipIds);
                        } else {
                            await saveSchedule(category.id, membershipIds);
                        }
                    }

                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    const confirmEditAlert = () => {
        if (editMode === 'all') {
            return window.confirm('지난 스케쥴을 제외한 관련된 모든 스케쥴을 수정하시겠습니까?');
        }

        return true;
    }

    const loopType1DataCheck = () => {
        return (
            date && // 스케쥴 날짜
            startTimeAmPm && // 스케쥴 시작 시간 AM/PM
            (startTimeHour.value || startTimeMin.value || (startTimeHour.value === 0 && startTimeMin.value === 0)) && // 스케쥴 시작 시간
            endTimeAmPm && // 스케쥴 종료 시간 AM/PM
            (endTimeHour.value ||endTimeMin.value || (endTimeHour.value === 0 && endTimeMin.value === 0)) // 스케쥴 종료 시간
        )
    };

    const loopType2DataCheck = () => {
        return (
            startDate && // 반복 시작일
            endDate && // 반복 종료일
            selectDaysString // 반복 요일
        )
    }

    const loopType3DataCheck = () => {
        return (
            loopStartTimeAmPm && // 반복 시간 체크
            (loopStartTimeHour.value || loopStartTimeMin.value || (loopStartTimeHour.value === 0 && loopStartTimeMin.value === 0)) &&
            loopEndTimeAmPm &&
            (loopEndTimeHour.value || loopEndTimeMin.value || (loopEndTimeHour.value === 0 && loopEndTimeMin.value === 0)) &&
            (loopTimeHour.value || loopTimeMin.value) &&
            (loopTermTimeHour.value || loopTermTimeMin.value || (loopTermTimeHour.value === 0 && loopTermTimeMin.value === 0))
        )
    }

    const editScheduleChecked = async (scheduleGroupId, scheduleId, categoryId, membershipIds) => {
        const { data } = await editScheduleCheckedM({
            variables: {
                scheduleGroupId,
                scheduleId,
                type: 'EDIT'
            },
        });

        if (data && data.editScheduleChecked) {
            if (data.editScheduleChecked.success) {
                let confirm = true;

                if (data.editScheduleChecked.error) {
                    confirm = window.confirm(data.editScheduleChecked.error);
                }

                if (confirm) {
                    await saveSchedule(categoryId, membershipIds);
                } else {
                    setLoading(false);
                }
            } else {
                alert(data.editScheduleChecked.error);

                setLoading(false);
            }
        } else {
            alert("오류가 발생하였습니다.");

            setLoading(false);
        }
    }

    const saveSchedule = async (categoryId, membershipIds) => {

        let availableTime = hourMinConvertMin(availableHour.value, availableMin.value);
        let cancelTime = hourMinConvertMin(cancelHour.value, cancelMin.value);
        let startTime = (loopType !== 1 && loopType !== 2 && !editMode) ? null : hourMinConvertTimeString(startTimeHour.value, startTimeMin.value, startTimeAmPm);
        let endTime = (loopType !== 1 && loopType !== 2 && !editMode) ? null : hourMinConvertTimeString(endTimeHour.value, endTimeMin.value, endTimeAmPm, true);
        let loopStartTime = (loopType === 1 || loopType === 2) ? null : hourMinConvertTimeString(loopStartTimeHour.value, loopStartTimeMin.value, loopStartTimeAmPm);
        let loopEndTime = (loopType === 1 || loopType === 2) ? null : hourMinConvertTimeString(loopEndTimeHour.value, loopEndTimeMin.value, loopEndTimeAmPm, true);
        let intervalTime = (loopType === 1 || loopType === 2) ? null : hourMinConvertMin(loopTermTimeHour.value, loopTermTimeMin.value);
        let runningTime = (loopType === 1 || loopType === 2) ? null : hourMinConvertMin(loopTimeHour.value, loopTimeMin.value);

        const {data: result} = await saveScheduleM({
            variables: {
                scheduleId: scheduleId,
                machine: location.state
                    .machine
                    ? location.state.machine
                    : '',
                name: name.value,
                teacherId: teacher.id,
                categoryId,
                limit: limit.value
                    ? Number(limit.value)
                    : null,
                minusCount: minusCount.value
                    ? Number(minusCount.value)
                    : null,
                productIds: membershipIds.toString(),
                availableTime,
                cancelTime,
                date,
                startTime,
                endTime,
                loopType,
                startDate,
                endDate,
                isSun: selectDays[0],
                isMon: selectDays[1],
                isTue: selectDays[2],
                isWed: selectDays[3],
                isThu: selectDays[4],
                isFir: selectDays[5],
                isSat: selectDays[6],
                loopStartTime,
                loopEndTime,
                intervalTime,
                runningTime,
                editMode,
            },
        });
        if (result && result.saveSchedule) {
            if (result.saveSchedule.error) {
                setLoading(false);
                alert(result.saveSchedule.error);
            } else {
                setLoading(false);
                if (editMode) {
                    alert('스케쥴을 수정하였습니다.');
                    if (editMode === 'all') {
                        // 아이디를 새로 따기 때문에 뒤로가기 할 때 /schedule/list 도 변경되어야함
                        localStorage.setItem('scheduleId', result.saveSchedule.schedule.id);

                        // 전체 스케쥴 수정일 경우엔, 페이지 이동 처리
                        location.state = {
                            machine: location.state.machine,
                            scheduleId: result.saveSchedule.schedule.id,
                            editMode: editMode
                        }
                        history.replace({
                            pathname: `/schedule/add/`,
                            state: location.state,
                        });
                    } else {
                        refetchSchedule();
                    }
                } else {
                    alert('스케쥴을 추가하였습니다.');
                    initialize();
                }
            }
        } else {
            setLoading(false);
            alert('오류가 발생하였습니다.');
        }
    }

    const hourMinConvertMin = (hour, min) => {
        let _hour = hour ? Number(hour) : 0;
        let _min = min ? Number(min) : 0;

        return (_hour * 60) + _min;
    }

    const hourMinConvertTimeString = (hour, min, amPm, isEndTime) => {
        let _hour = hour ? Number(hour) : 0;
        let _min = min ? Number(min) : 0;

        if (amPm === 'PM' && _hour !== 12) {
            _hour += 12;
        } else if (amPm === 'AM' && _hour === 12) {
            _hour = 0;
        }

        if (isEndTime && _hour === 0) {
            _hour = 24;
        }

        return `${_hour > 9 ? _hour : '0'+_hour}:${_min > 9 ? _min : '0'+_min}`;
    }

    const initialize = () => {
        name.setValue('');
        limit.setValue('');
        minusCount.setValue('');
        availableHour.setValue('');
        availableMin.setValue('');
        cancelHour.setValue('');
        cancelMin.setValue('');
        startTimeHour.setValue('');
        startTimeMin.setValue('');
        endTimeHour.setValue('');
        endTimeMin.setValue('');
        categoryName.setValue('');
        loopStartTimeHour.setValue('');
        loopStartTimeMin.setValue('');
        loopEndTimeHour.setValue('');
        loopEndTimeMin.setValue('');
        loopTimeHour.setValue('');
        loopTimeMin.setValue('');
        loopTermTimeHour.setValue('');
        loopTermTimeMin.setValue('');

        setCategory(null);
        setShowCategory(false);
        setTeacher(null);
        setShowTeacher(false);
        setShowCategoryAdd(false);
        setMemberships(products && products.getProducts ? products.getProducts.products : []);
        setShowMemberships(false);
        setLoopType(null);
        setShowLoopType(false);
        setLoading(false);
        setDate(null);
        setShowDate(false);
        setStartDate(null);
        setShowStartDate(false);
        setEndDate(null);
        setShowEndDate(false);
        setStartTimeAmPm('AM');
        setEndTimeAmPm('AM');
        setLoopStartTimeAmPm('AM');
        setLoopEndTimeAmPm('AM');
        setSelectDays([false, false, false, false, false, false, false]);
        setSelectDaysString(null);
        setShowSelectDays(false);
    };

    useEffect(() => {
        setShowCategoryAdd(false);
        categoryName.setValue('');
    }, [showCategory]);

    useEffect(() => {
        const days = ["일", "월", "화", "수", "목", "금", "토"];
        let _selectDaysString = '';
        for (var i = 0; i < selectDays.length; i++) {
            if (selectDays[i]) {
                if (_selectDaysString.length > 0) {
                    _selectDaysString += ", ";
                }

                _selectDaysString += days[i];
            }
        }
        setSelectDaysString(_selectDaysString);
    }, [selectDays]);

    useEffect(() => {
        if (products && products.getProducts) {
            setMemberships(products.getProducts.products);
        }
    }, [products]);

    useEffect(() => {
        if (loopType && !detailLoading) {
            setDate('');
            setStartTimeAmPm('AM');
            startTimeHour.setValue('');
            startTimeMin.setValue('');
            setEndTimeAmPm('AM');
            endTimeHour.setValue('');
            endTimeMin.setValue('');
            setStartDate(null);
            setEndDate(null);
            setSelectDaysString(null);
            setSelectDays([false, false, false, false, false, false, false]);
            setLoopStartTimeAmPm('AM');
            loopStartTimeHour.setValue('');
            loopStartTimeMin.setValue('');
            setLoopEndTimeAmPm('AM');
            loopEndTimeHour.setValue('');
            loopEndTimeMin.setValue('');
            loopTimeHour.setValue('');
            loopTimeMin.setValue('');
            loopTermTimeHour.setValue('');
            loopTermTimeMin.setValue('');

            setShowDate(false);
            setShowStartDate(false);
            setShowEndDate(false);
            setShowSelectDays(false);
        }
    }, [loopType]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }

        if (location.state.scheduleId) {
            setScheduleId(location.state.scheduleId);
            setEditMode(location.state.editMode);
            setDetailLoading(true);

            refetchSchedule();
        }
    }, []);

    useEffect(() => {
        let checkMembershipNames = '';
        if (memberships) {
            for (var i = 0; i < memberships.length; i++) {
                if (memberships[i].checked) {
                    if (checkMembershipNames.length > 0) {
                        checkMembershipNames += ', ';
                    }
                    checkMembershipNames += memberships[i].name;
                }
            }
        }

        setMembershipNames(checkMembershipNames);
    }, [memberships]);

    useEffect(() => {
        // if (schedule && schedule.getSchedule && schedule.getSchedule.schedule) {
        //     initialize();
        //
        //     let s = schedule.getSchedule.schedule;
        //
        //     let startTimeHourValue = getHour(s.startTime);
        //     let startTimeMinValue = getMinute(s.startTime);
        //     let endTimeHourValue = getHour(s.endTime);
        //     let endTimeMinValue = getMinute(s.endTime);
        //
        //     name.setValue(s.name);
        //     limit.setValue(s.limit);
        //     minusCount.setValue(s.minusCount);
        //     availableHour.setValue(parseInt(s.availableTime / 60));
        //     availableMin.setValue(parseInt(s.availableTime % 60));
        //     cancelHour.setValue(parseInt(s.cancelTime / 60));
        //     cancelMin.setValue(parseInt(s.cancelTime % 60));
        //     startTimeHour.setValue(startTimeHourValue);
        //     startTimeMin.setValue(startTimeMinValue);
        //     endTimeHour.setValue(endTimeHourValue);
        //     endTimeMin.setValue(endTimeMinValue);
        //
        //     setDate(new Date(s.date));
        //     setCategory(s.category);
        //     setTeacher(s.teacher);
        //     setStartTimeAmPm(getHourHH(s.startTime) >= 12 ? 'PM' : 'AM');
        //     setEndTimeAmPm(getHourHH(s.endTime) >= 12 ? 'PM' : 'AM');
        //
        //     let _memberships = [...products.getProducts.products];
        //
        //     for (var i = 0; i < _memberships.length; i++) {
        //         for (var j = 0; j < s.scheduleProducts.length; j++) {
        //             if (s.scheduleProducts[j].productId === _memberships[i].id) {
        //                 _memberships[i] = {..._memberships[i], checked: true};
        //                 break;
        //             }
        //         }
        //     }
        //
        //     setMemberships(_memberships);
        //
        //     if (location.state.editMode === 'all') {
        //         let sg = s.scheduleGroup;
        //         setLoopStartTimeAmPm(getAmPm(sg.startTime));
        //         loopStartTimeHour.setValue(getHour(sg.startTime));
        //         loopStartTimeMin.setValue(getMinute(sg.startTime));
        //         setLoopEndTimeAmPm(getAmPm(sg.endTime));
        //         loopEndTimeHour.setValue(getHour(sg.endTime));
        //         loopEndTimeMin.setValue(getMinute(sg.endTime));
        //         loopTimeHour.setValue(parseInt(sg.runningTime / 60));
        //         loopTimeMin.setValue(parseInt(sg.runningTime % 60));
        //         loopTermTimeHour.setValue(parseInt(sg.intervalTime / 60));
        //         loopTermTimeMin.setValue(parseInt(sg.intervalTime % 60));
        //
        //         setStartDate(new Date(sg.startDate));
        //         setEndDate(new Date(sg.endDate));
        //         setSelectDays([sg.isSun, sg.isMon, sg.isTue, sg.isWed, sg.isThu, sg.isFir, sg.isSat]);
        //
        //         setLoopType(sg.type);
        //     }
        // }

        syncScheduleData();
        // loopType 을 바꿔버리면 바로 초기화돼서 timeout 추가
        setTimeout(() => {
            setDetailLoading(false);
        }, 1000);
    }, [schedule]);

    const syncScheduleData = () => {

        if (schedule && schedule.getSchedule && schedule.getSchedule.schedule) {
            if (products && products.getProducts) {
                initialize();

                let s = schedule.getSchedule.schedule;

                let startTimeHourValue = getHour(s.startTime);
                let startTimeMinValue = getMinute(s.startTime);
                let endTimeHourValue = getHour(s.endTime);
                let endTimeMinValue = getMinute(s.endTime);

                name.setValue(s.name);
                limit.setValue(s.limit);
                minusCount.setValue(s.minusCount);
                availableHour.setValue(parseInt(s.availableTime / 60) || 0);
                availableMin.setValue(parseInt(s.availableTime % 60) || 0);
                cancelHour.setValue(parseInt(s.cancelTime / 60) || 0);
                cancelMin.setValue(parseInt(s.cancelTime % 60) || 0);
                startTimeHour.setValue(startTimeHourValue);
                startTimeMin.setValue(startTimeMinValue);
                endTimeHour.setValue(endTimeHourValue);
                endTimeMin.setValue(endTimeMinValue);

                console.log('getHourHH(s.startTime: ', getHourHH(s.startTime), 's.startTime: ', s.startTime, 's.id', s.id);

                setDate(new Date(s.date));
                setCategory(s.category);
                setTeacher(s.teacher);
                setStartTimeAmPm(getHourHH(s.startTime) >= 12 ? 'PM' : 'AM');
                setEndTimeAmPm(getHourHH(s.endTime) >= 12 ? 'PM' : 'AM');

                let _memberships = [...products.getProducts.products];

                for (var i = 0; i < _memberships.length; i++) {
                    for (var j = 0; j < s.scheduleProducts.length; j++) {
                        if (s.scheduleProducts[j].productId === _memberships[i].id) {
                            _memberships[i] = {..._memberships[i], checked: true};
                            break;
                        }
                    }
                }

                setMemberships(_memberships);
                console.log('editMode: ', editMode, s.date);

                if (location.state.editMode === 'all') {
                    let sg = s.scheduleGroup;
                    console.log('sg.runningTime: ', sg.runningTime);
                    console.log('sg.intervalTime: ', sg.intervalTime);

                    setLoopStartTimeAmPm(getAmPm(sg.startTime));
                    loopStartTimeHour.setValue(getHour(sg.startTime));
                    loopStartTimeMin.setValue(getMinute(sg.startTime));
                    setLoopEndTimeAmPm(getAmPm(sg.endTime));
                    loopEndTimeHour.setValue(getHour(sg.endTime));
                    loopEndTimeMin.setValue(getMinute(sg.endTime));
                    loopTimeHour.setValue(parseInt(sg.runningTime / 60) || 0);
                    loopTimeMin.setValue(parseInt(sg.runningTime % 60) || 0);
                    loopTermTimeHour.setValue(parseInt(sg.intervalTime / 60) || 0);
                    loopTermTimeMin.setValue(parseInt(sg.intervalTime % 60) || 0);
                    console.log('sg.runningTime Hour: ', parseInt(sg.runningTime / 60) || 0);
                    console.log('sg.runningTime Min: ', parseInt(sg.runningTime % 60) || 0);
                    console.log('sg.intervalTime Hour: ', parseInt(sg.intervalTime / 60) || 0);
                    console.log('sg.intervalTime Min: ', parseInt(sg.intervalTime % 60) || 0);

                    setStartDate(new Date(sg.startDate));
                    setEndDate(new Date(sg.endDate));
                    setSelectDays([sg.isSun, sg.isMon, sg.isTue, sg.isWed, sg.isThu, sg.isFir, sg.isSat]);

                    setLoopType(sg.type);
                }
            } else {
                setTimeout(() => {
                    syncScheduleData();
                }, 500);
            }
        }
    }

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`스케쥴 ${scheduleId ? '수정' : '추가'}${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p
                                className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    스케쥴명*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'name'}
                                value={name.value}
                                onChange={name.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                placeholder={'스케쥴명을 입력해주세요.'}
                            />
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showTeacher
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showTeacher
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowTeacher(!showTeacher)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        강사 선택*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={teacher && teacher.id ? teacher.name : ''}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={'강사를 선택해주세요.'}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showTeacher
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                    .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                    .default
                                        }
                                        alt={'강사 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showTeacher && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div
                                            className={`${styles.py10} ${styles.flex1}`}>
                                            {teachers && teachers.getTeacherAdmin
                                                ? teachers.getTeacherAdmin.map(
                                                    c => {
                                                        return (
                                                            <div
                                                                key={c.id}
                                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                <p
                                                                    className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                    onClick={() =>
                                                                        handleTeacher(
                                                                            c,
                                                                        )
                                                                    }>
                                                                    {
                                                                        c.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    },
                                                )
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showCategory
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showCategory
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowCategory(!showCategory)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        카테고리 선택*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            category && category.id
                                                ? category.name
                                                : ''
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={
                                            '카테고리를 선택해주세요.'
                                        }
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showCategory
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                    .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                    .default
                                        }
                                        alt={'카테고리 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showCategory && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        {showCategoryAdd ? (
                                            <div
                                                className={`${styles.py10} ${styles.flex1}`}>
                                                <div
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}>
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.mr10}`}>
                                                        카테고리명
                                                    </p>
                                                    <input
                                                        type={'text'}
                                                        name={'categoryName'}
                                                        value={
                                                            categoryName.value
                                                        }
                                                        onChange={
                                                            categoryName.onChange
                                                        }
                                                        className={`${styles.inputWhiteUnderline} ${styles.flex1} ${styles.py5} ${styles.mt3}`}
                                                        style={{ minWidth: 20 }}
                                                        placeholder={
                                                            '카테고리명을 입력해주세요.'
                                                        }
                                                    />
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.ml10} ${styles.cursorPointer}`}
                                                        style={{
                                                            textDecorationLine:
                                                                'underline',
                                                            opacity: loading
                                                                ? 0.4
                                                                : 1,
                                                        }}
                                                        onClick={addCategory}>
                                                        완료
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={`${styles.py10} ${styles.flex1}`}>
                                                <div
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                    onClick={() =>
                                                        setShowCategoryAdd(true)
                                                    }>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_add.png')
                                                                .default
                                                        }
                                                        alt={'add'}
                                                        className={`${styles.icon15} ${styles.cursorPointer} ${styles.mr10} ${styles.mt3}`}
                                                    />
                                                    <p
                                                        className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`}>
                                                        새로운 카테고리 추가
                                                    </p>
                                                </div>
                                                {data && data.getCategoryAdmin
                                                    ? data.getCategoryAdmin.map(
                                                        c => {
                                                            return (
                                                                <div
                                                                    key={c.id}
                                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                    <p
                                                                        className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                        onClick={() =>
                                                                            handleCategory(
                                                                                c,
                                                                            )
                                                                        }>
                                                                        {
                                                                            c.name
                                                                        }
                                                                    </p>
                                                                    <img
                                                                        src={
                                                                            require('../../../assets/images/icon_close.png')
                                                                                .default
                                                                        }
                                                                        alt={
                                                                            'delete'
                                                                        }
                                                                        className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml20} ${styles.mt3}`}
                                                                        onClick={() =>
                                                                            removeCategory(
                                                                                c,
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            );
                                                        },
                                                    )
                                                    : null}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    수강 인원*
                                </p>
                            </div>
                            <NumberFormat
                                value={limit.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleLimit}
                                placeholder={
                                    '수강 인원을 입력해주세요.'
                                }
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    차감 횟수*
                                </p>
                            </div>
                            <NumberFormat
                                value={minusCount.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleMinusCount}
                                placeholder={
                                    '차감 횟수를 입력해주세요.'
                                }
                            />
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showMemberships
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showMemberships
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowMemberships(!showMemberships)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 127 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        예약 가능 회원권*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        placeholder={'예약에 사용 가능한 회원권을 모두 선택해주세요.'}
                                        value={membershipNames}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showMemberships
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                    .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                    .default
                                        }
                                        alt={'회원권 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showMemberships && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 127 }}></div>
                                        <div
                                            className={`${styles.py10} ${styles.flex1}`}
                                            style={{ maxHeight: 200, overflow: "auto" }}>
                                            {memberships.map(
                                                    c => {
                                                        return (
                                                            <div
                                                                key={c.id}
                                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                                <div
                                                                    onClick={() =>
                                                                        handleMemberships(
                                                                            c,
                                                                        )
                                                                    }
                                                                    className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20} ${styles.cursorPointer}`}>
                                                                    {c.checked && (
                                                                        <div
                                                                            className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                                            style={{
                                                                                width: 14,
                                                                                height: 14,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>

                                                                <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                                   onClick={() =>
                                                                       handleMemberships(
                                                                           c,
                                                                       )
                                                                   }>
                                                                    {
                                                                        c.name
                                                                    }
                                                                </p>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 127 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    예약 가능 시간*
                                </p>
                            </div>
                            <NumberFormat
                                value={availableHour.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.pl15} ${styles.mr3}`}
                                style={{width: 45}}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleAvailableHour}
                                placeholder={
                                    '시간'
                                }
                            />
                            <span className={`${styles.font14} ${styles.black}`} style={{alignSelf: 'center'}}>시간</span>
                            <NumberFormat
                                value={availableMin.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.px5} ${styles.mr3}`}
                                style={{width: 30}}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleAvailableMin}
                                placeholder={
                                    '분'
                                }
                            />
                            <span className={`${styles.font14} ${styles.black}`}  style={{alignSelf: 'center'}}>분 전까지 가능</span>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 127 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    예약취소가능시간*
                                </p>
                            </div>
                            <NumberFormat
                                value={cancelHour.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.pl15} ${styles.mr3}`}
                                style={{width: 45}}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleCancelHour}
                                placeholder={
                                    '시간'
                                }
                            />
                            <span className={`${styles.font14} ${styles.black}`} style={{alignSelf: 'center'}}>시간</span>
                            <NumberFormat
                                value={cancelMin.value}
                                thousandSeparator={true}
                                className={`${styles.inputWhite} ${styles.px5} ${styles.mr3}`}
                                style={{width: 30}}
                                type={'tel'}
                                displayType={'input'}
                                onValueChange={handleCancelMin}
                                placeholder={
                                    '분'
                                }
                            />
                            <span className={`${styles.font14} ${styles.black}`}  style={{alignSelf: 'center'}}>분 전까지 가능</span>
                        </div>

                        {(editMode !== 'one') && (
                            <div
                                className={`${styles.mt10}`}
                                style={{ position: 'relative' }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                    style={{
                                        borderBottomLeftRadius: showLoopType
                                            ? 0
                                            : 10,
                                        borderBottomRightRadius: showLoopType
                                            ? 0
                                            : 10,
                                    }}
                                    onClick={() => setShowLoopType(!showLoopType)}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 110 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            반복 타입*
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                        <input
                                            type={'text'}
                                            value={loopType ? (
                                                loopType === 1 ?
                                                    '반복 사용 안 함'
                                                    : loopType === 2 ?
                                                        '일 단위 반복 사용'
                                                        : loopType === 3 ?
                                                            '시간 단위 반복 사용' : '일 + 시간 단위 반복 사용'
                                            ) : ''}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                            style={{ minWidth: 20 }}
                                            placeholder={'반복 타입을 선택해주세요.'}
                                            readOnly={true}
                                        />
                                        <img
                                            src={
                                                showLoopType
                                                    ? require('../../../assets/images/icon_dropdown_up.png')
                                                        .default
                                                    : require('../../../assets/images/icon_dropdown_down.png')
                                                        .default
                                            }
                                            alt={'반복 타입 선택'}
                                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                        />
                                    </div>
                                </div>
                                {showLoopType && (
                                    <div
                                        className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                        style={{ left: 0, right: 0 }}>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                            <div
                                                className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                                style={{ width: 110 }}></div>
                                            <div
                                                className={`${styles.py10} ${styles.flex1}`}>
                                                <div
                                                    key={'loopType1'}
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                        onClick={() => handleLoopType(1)}>
                                                        반복 사용 안 함
                                                    </p>
                                                </div>
                                                <div
                                                    key={'loopType2'}
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                        onClick={() => handleLoopType(2)}>
                                                        일 단위 반복 사용
                                                    </p>
                                                </div>
                                                <div
                                                    key={'loopType3'}
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                        onClick={() => handleLoopType(3)}>
                                                        시간 단위 반복 사용
                                                    </p>
                                                </div>
                                                <div
                                                    key={'loopType4'}
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                                    <p
                                                        className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`}
                                                        onClick={() => handleLoopType(4)}>
                                                        일 + 시간 단위 반복 사용
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {(loopType === 1 || loopType === 3) && (
                            <div
                                className={`${styles.mt10}`}
                                style={{ position: 'relative' }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                    style={{
                                        borderBottomLeftRadius: showDate ? 0 : 10,
                                        borderBottomRightRadius: showDate ? 0 : 10,
                                    }}
                                    onClick={() => setShowDate(!showDate)}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 110 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 날짜*
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                        <input
                                            type={'text'}
                                            value={
                                                date
                                                    ? `${date.getFullYear()}-${
                                                        date.getMonth() + 1 < 10
                                                            ? '0' +
                                                            String(
                                                                date.getMonth() +
                                                                1,
                                                            )
                                                            : date.getMonth() + 1
                                                    }-${
                                                        date.getDate() < 10
                                                            ? '0' +
                                                            String(
                                                                date.getDate(),
                                                            )
                                                            : date.getDate()
                                                    }`
                                                    : ''
                                            }
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                            style={{ minWidth: 20 }}
                                            placeholder={
                                                '스케쥴 날짜를 선택해주세요.'
                                            }
                                            readOnly={true}
                                        />
                                        <img
                                            src={
                                                showDate
                                                    ? require('../../../assets/images/icon_dropdown_up.png')
                                                        .default
                                                    : require('../../../assets/images/icon_dropdown_down.png')
                                                        .default
                                            }
                                            alt={'스케쥴 날짜0 선택'}
                                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                        />
                                    </div>
                                </div>
                                {showDate && (
                                    <div
                                        className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                        style={{ left: 0, right: 0 }}>
                                        <div
                                            className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                                            <Calendar
                                                calendarType={'US'}
                                                value={date}
                                                minDetail={'month'}
                                                minDate={new Date()}
                                                nextLabel={
                                                    <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                .default
                                                        }
                                                        alt={'다음'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                }
                                                next2Label={null}
                                                prevLabel={
                                                    <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                .default
                                                        }
                                                        alt={'이전'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                }
                                                prev2Label={null}
                                                navigationLabel={({ label }) => (
                                                    <p
                                                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>
                                                        {label}
                                                    </p>
                                                )}
                                                tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                                                onChange={handleDate}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {(loopType === 1 || loopType === 2 || editMode === 'one') && (
                            <div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 시작 시간*
                                        </p>
                                    </div>
                                    <ScheduleTime
                                        timeHour={startTimeHour}
                                        timeMin={startTimeMin}
                                        timeAmPm={startTimeAmPm}
                                        handleTimeAmPm={handleStartTimeAmPm}
                                        handleTimeHour={handleStartTimHour}
                                        handleTimeMin={handleStartTimMin}
                                        infoText={'분 부터 시작'} />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 종료 시간*
                                        </p>
                                    </div>
                                    <ScheduleTime
                                        timeHour={endTimeHour}
                                        timeMin={endTimeMin}
                                        timeAmPm={endTimeAmPm}
                                        handleTimeAmPm={handleEndTimeAmPm}
                                        handleTimeHour={handleEndTimHour}
                                        handleTimeMin={handleEndTimMin}
                                        infoText={'분 에 종료'} />
                                </div>
                            </div>
                        )}

                        {(loopType === 2 || loopType === 4) && (
                            <div>
                                <div
                                    className={`${styles.mt10}`}
                                    style={{ position: 'relative' }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            borderBottomLeftRadius: showStartDate ? 0 : 10,
                                            borderBottomRightRadius: showStartDate ? 0 : 10,
                                        }}
                                        onClick={() => setShowStartDate(!showStartDate)}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                            style={{ width: 110 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                스케쥴 시작일*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                            <input
                                                type={'text'}
                                                value={
                                                    startDate
                                                        ? `${startDate.getFullYear()}-${
                                                            startDate.getMonth() + 1 < 10
                                                                ? '0' +
                                                                String(
                                                                    startDate.getMonth() +
                                                                    1,
                                                                )
                                                                : startDate.getMonth() + 1
                                                        }-${
                                                            startDate.getDate() < 10
                                                                ? '0' +
                                                                String(
                                                                    startDate.getDate(),
                                                                )
                                                                : startDate.getDate()
                                                        }`
                                                        : ''
                                                }
                                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                                style={{ minWidth: 20 }}
                                                placeholder={
                                                    '스케쥴 반복 시작일을 선택해주세요.'
                                                }
                                                readOnly={true}
                                            />
                                            <img
                                                src={
                                                    showDate
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                            .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                            .default
                                                }
                                                alt={'스케쥴 반복 시작일 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showStartDate && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ left: 0, right: 0 }}>
                                            <div
                                                className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                                                <Calendar
                                                    calendarType={'US'}
                                                    value={startDate}
                                                    minDetail={'month'}
                                                    minDate={new Date()}
                                                    nextLabel={
                                                        <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                .default
                                                        }
                                                        alt={'다음'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                    }
                                                    next2Label={null}
                                                    prevLabel={
                                                        <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                .default
                                                        }
                                                        alt={'이전'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                    }
                                                    prev2Label={null}
                                                    navigationLabel={({ label }) => (
                                                        <p
                                                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>
                                                            {label}
                                                        </p>
                                                    )}
                                                    tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                                                    onChange={handleStartDate}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${styles.mt10}`}
                                    style={{ position: 'relative' }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            borderBottomLeftRadius: showEndDate ? 0 : 10,
                                            borderBottomRightRadius: showEndDate ? 0 : 10,
                                        }}
                                        onClick={() => setShowEndDate(!showEndDate)}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                            style={{ width: 110 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                스케쥴 종료일*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                            <input
                                                type={'text'}
                                                value={
                                                    endDate
                                                        ? `${endDate.getFullYear()}-${
                                                            endDate.getMonth() + 1 < 10
                                                                ? '0' +
                                                                String(
                                                                    endDate.getMonth() +
                                                                    1,
                                                                )
                                                                : endDate.getMonth() + 1
                                                        }-${
                                                            endDate.getDate() < 10
                                                                ? '0' +
                                                                String(
                                                                    endDate.getDate(),
                                                                )
                                                                : endDate.getDate()
                                                        }`
                                                        : ''
                                                }
                                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                                style={{ minWidth: 20 }}
                                                placeholder={
                                                    '스케쥴 반복 종료일을 선택해주세요.'
                                                }
                                                readOnly={true}
                                            />
                                            <img
                                                src={
                                                    showDate
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                            .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                            .default
                                                }
                                                alt={'스케쥴 반복 종료일 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEndDate && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ left: 0, right: 0 }}>
                                            <div
                                                className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                                                <Calendar
                                                    calendarType={'US'}
                                                    value={endDate}
                                                    minDetail={'month'}
                                                    minDate={startDate ? startDate : new Date()}
                                                    nextLabel={
                                                        <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                .default
                                                        }
                                                        alt={'다음'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                    }
                                                    next2Label={null}
                                                    prevLabel={
                                                        <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                .default
                                                        }
                                                        alt={'이전'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                                    }
                                                    prev2Label={null}
                                                    navigationLabel={({ label }) => (
                                                        <p
                                                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>
                                                            {label}
                                                        </p>
                                                    )}
                                                    tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                                                    onChange={handleEndDate}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${styles.mt10}`}
                                    style={{ position: 'relative' }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            borderBottomLeftRadius: showSelectDays ? 0 : 10,
                                            borderBottomRightRadius: showSelectDays ? 0 : 10,
                                        }}
                                        onClick={() => setShowSelectDays(!showSelectDays)}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                            style={{ width: 120 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                                스케쥴 추가 요일*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                            <input
                                                type={'text'}
                                                value={selectDaysString}
                                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                                style={{ minWidth: 20 }}
                                                placeholder={
                                                    '스케쥴 기간 내에 활성화 할 요일을 모두 선택해주세요.'
                                                }
                                                readOnly={true}
                                            />
                                            <img
                                                src={
                                                    showDate
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                            .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                            .default
                                                }
                                                alt={'스케쥴 추가 요일 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showSelectDays && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ left: 0, right: 0 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.overflowHidden} ${styles.cursorPointer}`}>
                                                <div className={`${styles.borderRightGrayA2} `} style={{ width: 120 }}></div>
                                                <div className={`${styles.flex1} ${styles.px10} ${styles.py10}`}>
                                                    {selectDays.map((item, idx) => {
                                                        return (
                                                            <ScheduleDays
                                                                selected={item}
                                                                idx={idx}
                                                                handleSelectDays={handleSelectDays} />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {(loopType === 3 || loopType === 4) && (
                            <div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 반복 시작*
                                        </p>
                                    </div>
                                    <ScheduleTime
                                        timeHour={loopStartTimeHour}
                                        timeMin={loopStartTimeMin}
                                        timeAmPm={loopStartTimeAmPm}
                                        handleTimeAmPm={handleLoopStartTimeAmPm}
                                        handleTimeHour={handleLoopStartTimHour}
                                        handleTimeMin={handleLoopStartTimMin}
                                        infoText={'분 부터 시작'} />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 반복 종료*
                                        </p>
                                    </div>
                                    <ScheduleTime
                                        timeHour={loopEndTimeHour}
                                        timeMin={loopEndTimeMin}
                                        timeAmPm={loopEndTimeAmPm}
                                        handleTimeAmPm={handleLoopEndTimeAmPm}
                                        handleTimeHour={handleLoopEndTimHour}
                                        handleTimeMin={handleLoopEndTimMin}
                                        infoText={'분 에 종료'} />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 진행 시간*
                                        </p>
                                    </div>
                                    <NumberFormat
                                        value={loopTimeHour.value}
                                        thousandSeparator={true}
                                        className={`${styles.inputWhite} ${styles.pl15} ${styles.mr3}`}
                                        style={{width: 45}}
                                        type={'tel'}
                                        displayType={'input'}
                                        onValueChange={handleLoopTimeHour}
                                        placeholder={'시간'}
                                    />
                                    <span className={`${styles.font14} ${styles.black}`} style={{alignSelf: 'center'}}>시간</span>
                                    <NumberFormat
                                        value={loopTimeMin.value}
                                        thousandSeparator={true}
                                        className={`${styles.inputWhite} ${styles.px5} ${styles.mr3}`}
                                        style={{width: 30}}
                                        type={'tel'}
                                        displayType={'input'}
                                        onValueChange={handleLoopTimeMin}
                                        placeholder={'분'}
                                    />
                                    <span className={`${styles.font14} ${styles.black}`}  style={{alignSelf: 'center'}}>분 진행</span>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                        style={{ width: 120 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                            스케쥴 사이 간격*
                                        </p>
                                    </div>
                                    <NumberFormat
                                        value={loopTermTimeHour.value}
                                        thousandSeparator={true}
                                        className={`${styles.inputWhite} ${styles.pl15} ${styles.mr3}`}
                                        style={{width: 45}}
                                        type={'tel'}
                                        displayType={'input'}
                                        onValueChange={handleLoopTermTimeHour}
                                        placeholder={'시간'}
                                    />
                                    <span className={`${styles.font14} ${styles.black}`} style={{alignSelf: 'center'}}>시간</span>
                                    <NumberFormat
                                        value={loopTermTimeMin.value}
                                        thousandSeparator={true}
                                        className={`${styles.inputWhite} ${styles.px5} ${styles.mr3}`}
                                        style={{width: 30}}
                                        type={'tel'}
                                        displayType={'input'}
                                        onValueChange={handleLoopTermTimeMin}
                                        placeholder={'분'}
                                    />
                                    <span className={`${styles.font14} ${styles.black}`}  style={{alignSelf: 'center'}}>분 간격</span>
                                </div>
                            </div>
                        )}

                        <div className={`${styles.mt45}`}>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    완료
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ScheduleAdd;
