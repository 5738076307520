import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import OrderProductDiscount from '../../../components/Order/Mobile/OrderProductDiscount';

const PRODUCT = gql`
    query getProducts($machine: String!, $order: String!) {
        getProducts(type: "order", machine: $machine, order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
                discountStart
                discountEnd
            }
            total
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            username
            name
        }
    }
`;

const REMOVE_ALL = gql`
    mutation removeAllDiscount($machine: String!, $order: String!) {
        removeAllDiscount(machine: $machine, type: "order", order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
                discountStart
                discountEnd
            }
            total
        }
    }
`;

const REMOVE = gql`
    mutation removeDiscount($productId: Int!) {
        removeDiscount(productId: $productId) {
            id
            category {
                id
                name
            }
            image
            name
            price
            stock
            ignoreStock
            description
            isDiscount
            discountPrice
            discountPercent
            discountStart
            discountEnd
        }
    }
`;

const OrderProductsDiscount = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const {
        data,
        loading: loadingProduct,
        refetch,
    } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [removeAllDiscountM] = useMutation(REMOVE_ALL);
    const [removeDiscountM] = useMutation(REMOVE);

    const removeAllDiscount = async () => {
        if (!loading) {
            const confirm = window.confirm('정말 해제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await removeAllDiscountM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        order: queryString.parse(location.search).order
                            ? queryString.parse(location.search).order
                            : 'name_asc',
                    },
                });
                setLoading(false);
                if (data && data.removeAllDiscount) {
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const removeDiscount = async product => {
        if (!loading) {
            if (product && product.isDiscount && product.discountPrice > 0) {
                const confirm = window.confirm('정말 해제하시겠습니까?');
                if (confirm) {
                    setLoading(true);
                    const { data } = await removeDiscountM({
                        variables: {
                            productId: product.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.removeDiscount) {
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (location.state.machine) {
            window.scrollTo(0, 0);
            refetch({
                machine: location.state.machine,
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc',
            });
        } else {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, [queryString.parse(location.search).order]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`할인 적용 관리${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            style={{ opacity: loading ? 0.4 : 1 }}
                            onClick={removeAllDiscount}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                모든 할인 해제
                            </p>
                        </div>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
                            onClick={() =>
                                history.push({
                                    pathname: `/order/product/discount/add/`,
                                    state: {
                                        machine: location.state.machine
                                            ? location.state.machine
                                            : '',
                                    },
                                })
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                할인 추가하기
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1} ${styles.pr10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                상품명
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingProduct ? (
                            <Skeleton height={50} count={3} />
                        ) : data &&
                          data.getProducts &&
                          data.getProducts.products.length > 0 ? (
                            data.getProducts.products.map(product => {
                                return (
                                    <OrderProductDiscount
                                        key={product.id}
                                        product={product}
                                        removeDiscount={() =>
                                            removeDiscount(product)
                                        }
                                        machine={
                                            machine && machine.getMachine
                                                ? machine.getMachine
                                                : {}
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    아직 등록된 상품이 없습니다.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderProductsDiscount;
