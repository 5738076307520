import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const Terms = ({ terms, editDefaultTerms, loading }) => {
    const history = useHistory();

    const date = new Date(Number(terms.createdAt));
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.borderBottomGrayF4}`}
            style={{ opacity: loading ? 0.4 : 1 }}>
            <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.cursorPointer} ${styles.flex5}`}
                onClick={() =>
                    history.push({
                        pathname: `/contract/terms/detail/`,
                        state: {
                            terms,
                        },
                    })
                }>
                <div className={`${styles.flex2}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {`${date.getFullYear()}-${
                            date.getMonth() + 1 < 10
                                ? '0' + String(date.getMonth() + 1)
                                : date.getMonth() + 1
                        }-${
                            date.getDate() < 10
                                ? '0' + String(date.getDate())
                                : date.getDate()
                        } ${
                            date.getHours() < 10
                                ? '0' + date.getHours()
                                : date.getHours()
                        }:${
                            date.getMinutes() < 10
                                ? '0' + date.getMinutes()
                                : date.getMinutes()
                        }:${
                            date.getSeconds() < 10
                                ? '0' + date.getSeconds()
                                : date.getSeconds()
                        }`}
                    </p>
                </div>
                <div className={`${styles.flex2}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {terms.name}
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {numberWithCommas(terms.contractTotal)}
                    </p>
                </div>
            </div>
            <div className={`${styles.flex1} ${styles.center}`}>
                <div
                    className={`${styles.icon20} ${styles.borderRadius5} ${styles.borderGrayA2} ${styles.center} ${styles.cursorPointer}`}
                    onClick={editDefaultTerms}>
                    {terms.default && (
                        <div
                            className={`${styles.bgOrange}`}
                            style={{ width: 14, height: 14, borderRadius: 3 }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Terms;
