import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const ReservationListItem = ({
    reservation,
    changeStatus,
    isWait
}) => {
    const [showStatus, setShowStatus] = useState(false);

    return (
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {reservation.member.name}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    010-{reservation.member.mobile.substring(0,4)}-{reservation.member.mobile.substring(4,8)}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {reservation.member.gender==='male'?'남':'여'}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {reservation.product.name}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>

                {reservation.start&&reservation.end?
                    (<p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {new Date((parseInt(reservation.start)+32400000)).toISOString().split('T')[0].replace('-', '/').replace('-', '/')} ~ {new Date((parseInt(reservation.end)+32400000)).toISOString().split('T')[0].replace('-', '/').replace('-', '/')}
                    </p>)
                :null}

            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {reservation.product.membershipType === 1 ? '-' : (Number(reservation.membershipCount) - Number(reservation.totalDeduct)) }
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.mx0} ${styles.justifyContentCenter} ${styles.row} ${styles.alignItemsCenter}`}>
                <div className={`${styles.flex1} ${styles.px15}`} style={{position: 'relative'}}>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.cursorPointer}`}
                        onClick={() => setShowStatus(!showStatus)}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                            { isWait ? '대기' : (reservation.scheduleReservations[0].status === 3 ? '결석' : (reservation.scheduleReservations[0].isAttendance ? '출석' : '예약')) }
                        </p>
                        <img
                            src={
                                showStatus
                                    ? require('../../../assets/images/icon_dropdown_up.png')
                                        .default
                                    : require('../../../assets/images/icon_dropdown_down.png')
                                        .default
                            }
                            alt={'상태 선택'}
                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                        />
                    </div>
                    {showStatus && (
                        <div
                            className={`${styles.bgWhite} ${styles.flex1} ${styles.dropdown} ${styles.overflowHidden} ${styles.py10}`}
                            style={{left:0,right:0,top:40,border: '1px solid #f4f4f4', borderTop: '0px'}}>
                            { isWait ? (
                                <div
                                    className={`${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>

                                            <div
                                                className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                style={{
                                                    width: 14,
                                                    height: 14,
                                                }}
                                            />
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            대기
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 1);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black}`}>
                                            예약
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 5);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            대기 취소
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 1);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>

                                            {
                                                reservation.scheduleReservations[0].status === 1 && !reservation.scheduleReservations[0].isAttendance ?
                                                    <div
                                                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                        style={{
                                                            width: 14,
                                                            height: 14,
                                                        }}
                                                    /> : null
                                            }
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black}`}>
                                            예약
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 4);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                            {
                                                reservation.scheduleReservations[0].status === 1 && reservation.scheduleReservations[0].isAttendance ?
                                                    <div
                                                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                        style={{
                                                            width: 14,
                                                            height: 14,
                                                        }}
                                                    /> : null
                                            }
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            출석
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 3);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                            {
                                                reservation.scheduleReservations[0].status === 3 ?
                                                    <div
                                                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                        style={{
                                                            width: 14,
                                                            height: 14,
                                                        }}
                                                    /> : null
                                            }
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            결석
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 5);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            예약 취소
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                        onClick={() => {
                                            changeStatus(reservation, 2);
                                        }}>
                                        <div
                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                        </div>
                                        <p
                                            className={`${styles.font16} ${styles.black} ${styles.cursorPointer}`}>
                                            대기
                                        </p>
                                    </div>
                                </div>
                            ) }
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReservationListItem;
