import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const CONNECT = gql`
    mutation connectAdvertise($machineId: Int!, $ids: String!) {
        connectAdvertise(machineId: $machineId, ids: $ids)
    }
`;

const DISCONNECT = gql`
    mutation disconnectAdvertise($machineId: Int!, $id: Int!) {
        disconnectAdvertise(machineId: $machineId, id: $id)
    }
`;

const ACTIVE = gql`
    query getActiveAdvertise($id: Int!) {
        getActiveAdvertise(id: $id) {
            id
            name
        }
    }
`;

const INACTIVE = gql`
    query getInactiveAdvertise($id: Int!) {
        getInactiveAdvertise(id: $id) {
            id
            name
        }
    }
`;

const DeviceEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const device = location.state.device;

    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);

    const { data: active, refetch: refetchActive } = useQuery(ACTIVE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: device.id,
        },
    });
    const { data: inactive, refetch: refetchInactive } = useQuery(INACTIVE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: device.id,
        },
    });

    const [connectAdvertiseM] = useMutation(CONNECT);
    const [disconnectAdvertiseM] = useMutation(DISCONNECT);

    const handleSelected = advertise => {
        const findIndex = selected.findIndex(item => item === advertise.id);
        if (findIndex > -1) {
            let newSelected = [...selected];
            newSelected.splice(findIndex, 1);
            setSelected(newSelected);
        } else {
            setSelected([...selected, advertise.id]);
        }
    };

    const submit = async () => {
        if (!loading && location.state.device) {
            if (selected.length > 0) {
                setLoading(true);
                const { data } = await connectAdvertiseM({
                    variables: {
                        machineId: device.id,
                        ids: JSON.stringify(selected),
                    },
                });
                setLoading(false);
                if (data && data.connectAdvertise) {
                    alert('저장하였습니다.');
                    refetchActive();
                    refetchInactive();
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const remove = async advertise => {
        if (!loading && location.state.device) {
            const confirm = window.confirm(
                '기기에서 해당 광고를 송출 중단하시겠습니까?',
            );
            if (confirm) {
                setLoading(true);
                const { data } = await disconnectAdvertiseM({
                    variables: {
                        machineId: device.id,
                        id: advertise.id,
                    },
                });
                setLoading(false);
                if (data && data.disconnectAdvertise) {
                    alert('저장하였습니다.');
                    refetchActive();
                    refetchInactive();
                } else {
                    alert('송출 중단할 수 없는 광고입니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (!location.state.device) {
            alert('잘못된 접근입니다.');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                기기 광고 관리
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.mt40} ${styles.center}`}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 1210 / 3 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            지역
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                        <p
                                            className={`${styles.font16} ${styles.black}`}>
                                            {device?.sido}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 1210 / 3 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            시 / 군
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                        <p
                                            className={`${styles.font16} ${styles.black}`}>
                                            {device?.sigungu}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 1210 / 3 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            동 / 면 / 읍
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                        <p
                                            className={`${styles.font16} ${styles.black}`}>
                                            {device.bname}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        송출 가능한 광고
                                    </p>
                                    <div
                                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                        style={{
                                            width: 615,
                                            height: 500,
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                        }}>
                                        {inactive?.getInactiveAdvertise.map(
                                            advertise => {
                                                const isSelected =
                                                    selected.findIndex(
                                                        item =>
                                                            item ===
                                                            advertise.id,
                                                    ) > -1;
                                                return (
                                                    <div
                                                        key={advertise.id}
                                                        className={`${
                                                            styles.px20
                                                        } ${styles.py20} ${
                                                            styles.cursorPointer
                                                        } ${
                                                            styles.borderBottomGrayA2
                                                        } ${
                                                            isSelected
                                                                ? styles.bgGrayEf
                                                                : styles.bgWhite
                                                        }`}
                                                        onClick={() =>
                                                            handleSelected(
                                                                advertise,
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                                            {advertise.name}
                                                        </p>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <p
                                        className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        현재 송출중인 광고
                                    </p>
                                    <div
                                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.hideScroll} ${styles.mt10}`}
                                        style={{
                                            width: 615,
                                            height: 500,
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                        }}>
                                        {active?.getActiveAdvertise.map(
                                            advertise => {
                                                return (
                                                    <div
                                                        key={advertise.id}
                                                        className={`${styles.px20} ${styles.py20} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`}
                                                        onClick={() =>
                                                            remove(advertise)
                                                        }>
                                                        <p
                                                            className={`${styles.fontM} ${styles.font18} ${styles.black}`}>
                                                            {advertise.name}
                                                        </p>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`}
                                    style={{
                                        opacity: loading ? 0.4 : 1,
                                        width: 270,
                                    }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{
                                        opacity: loading ? 0.4 : 1,
                                        width: 270,
                                    }}
                                    onClick={submit}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceEdit;
