import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import queryString from "query-string";

const PROFILE = gql`
    {
        getProfileAdmin {
            id
            username
            name
            superuser
            mobile
        }
    }
`;

const ADD_POINT = gql`
    mutation addPoint($machine: String!, $point: Int!, $charge: String!) {
        addPoint(machine: $machine, point:$point, charge:$charge) {
            success
            error
        }
    }
`;

const PointUserAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const [addPoint] = useMutation(ADD_POINT);

    const [loading, setLoading] = useState(false);

    const { data, loading:loadingProfile } = useQuery(PROFILE, {
        fetchPolicy: 'cache-and-network',
    });

    const payment = async e => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);

            const { data: result } = await addPoint({
                variables: {

                }
            });
            if (result && result.addPoint) {
                if (result.addPoint.error) {
                    setLoading(false);
                    alert(result.addPoint.error);
                } else {
                    setLoading(false);
                    alert('포인트를 저장하였습니다.');
                    initialize();
                }
            } else {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        } else {
            setLoading(false);
            alert('필수 값을 모두 입력해주세요.');
        }

    }

    const initialize = () => {

        setLoading(false);
    };

    useEffect(() => {
        console.log("ABCDE ::::: ", queryString.parse(location.search));
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`} style={{ display: "none" }}>

        </div>
    );
};

export default PointUserAdd;
