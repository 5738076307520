import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import LockerArea from "../../../components/Locker/PC/LockerArea";
import cache from "../../../apollo/cache";

const LOCKER = gql`
  query getLockerAreas($machine: String!) {
    getLockerAreas(machine: $machine) {
      id
      name
      sizeX
      sizeY
      isDisplay
      lockers {
        id
        positionX
        positionY
        status
        number
      }
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      name
    }
  }
`;

const DELETE = gql`
  mutation deleteLockerArea($machine: String!, $lockerId: Int!) {
    deleteLockerArea(machine: $machine, lockerId: $lockerId) {
      success
      error
    }
  }
`;

const LockerList = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: loadingLocker,
    refetch,
  } = useQuery(LOCKER, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [deleteLockerAreaM] = useMutation(DELETE);

  const remove = async (locker) => {
    if (!loading) {
      const confirm = window.confirm("정말 삭제하시겠습니까?");
      if (confirm) {
        setLoading(true);
        const { data } = await deleteLockerAreaM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            lockerId: locker.id,
          },
        });
        setLoading(false);
        if (data && data.deleteLockerArea.success) {
          cache.evict({
            id: `LockerArea:${locker.id}`,
          });
          refetch();
          alert("락커를 삭제하였습니다.");
        } else if (data && data.deleteLockerArea.error) {
          alert(data.deleteLockerArea.error);
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`락커 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer}`}
                  onClick={() =>
                    history.push({
                      pathname: `/locker/add/`,
                      state: {
                        machine: location.state.machine ? location.state.machine : "",
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>락커 구역 추가</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.mt35}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
                <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgGrayD8}`} />
                <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>사용중</p>
                <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgPink} ${styles.ml20}`} />
                <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>만료</p>
                <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgSky} ${styles.ml20}`} />
                <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>사용가능</p>
              </div>
              {loadingLocker ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getLockerAreas && data.getLockerAreas.length > 0 ? (
                <div className={`${styles.center}`}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`} style={{ width: 1250 }}>
                    {data.getLockerAreas.map((lockerArea) => {
                      return <LockerArea key={lockerArea.id} lockerArea={lockerArea} remove={() => remove(lockerArea)} machine={machine && machine.getMachine ? machine.getMachine : {}} loading={loading} />;
                    })}
                  </div>
                </div>
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>아직 등록된 락커가 없습니다.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerList;
