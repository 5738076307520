import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import NumberFormat from "react-number-format";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import { removeNonNumeric } from "../../../common/utility";

const ReservationLimitCntItem = ({
  index,
  count,
  reservationLimitCnt,
  categories,
  syncCategory,
  removeItem,
}) => {
  const [limit, setLimit] = useState(reservationLimitCnt);
  const [showCategory, setShowCategory] = useState(false);

  const scheduleLimit = useInput(limit.limit);

  const handleCategory = (category) => {
    setLimit({
      ...limit,
      id: category.id,
      name: category.name,
    });
    //reservationLimit = limit;
    setShowCategory(false);
  };

  const handleScheduleLimit = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      scheduleLimit.setValue(value);
      setLimit({
        ...limit,
        limit: value,
      });
    }
  };

  const handleExceptPrev = (exceptPrev) => {
    setLimit({
      ...limit,
      exceptPrev,
    });
  };

  useEffect(() => {
    syncCategory(limit, index);
  }, [limit]);

  return (
    <div
      className={`${styles.mx0} ${
        count === index + 1 ? "" : styles.borderBottomGrayA2
      } ${styles.font14}`}
      style={{ display: "flex" }}
    >
      <div
        className={`${styles.px10} ${styles.py10} ${styles.textCenter}`}
        style={{ margin: "auto", width: 30 }}
      >
        조건{index + 1}
      </div>
      <div
        className={`${styles.row} ${styles.alignItemsCenter} ${styles.px5} ${styles.borderLeftGrayA2} ${styles.borderRightGrayA2}`}
        style={{
          marginRight: 0,
          marginLeft: 0,
          paddingBottom: 10,
          justifyContent: "center",
        }}
      >
        <p className={`${styles.fontM} ${styles.px10} ${styles.py5}`}>하루의</p>
        <div>
          <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.overflowHidden} ${styles.cursorPointer}`}
          >
            <div
              className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10}`}
              onClick={() => setShowCategory(!showCategory)}
            >
              <p className={`${styles.font14} ${styles.black} ${styles.flex1}`}>
                {limit.id >= 0 ? limit.name + " 스케쥴" : "조건을 선택해주세요"}
              </p>
              <img
                src={
                  showCategory
                    ? require("../../../assets/images/icon_dropdown_up.png")
                        .default
                    : require("../../../assets/images/icon_dropdown_down.png")
                        .default
                }
                alt={"출력 여부 선택"}
                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
              />
            </div>
          </div>
          {showCategory && (
            <div
              className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.mt5}`}
            >
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
              >
                <div className={`${styles.py5} ${styles.flex1}`}>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`}
                    onClick={() =>
                      handleCategory({
                        id: count + 99999,
                        name: "전체",
                      })
                    }
                  >
                    <p className={`${styles.font14} ${styles.black}`}>
                      전체 스케쥴
                    </p>
                  </div>
                  {categories.map((category, index) => {
                    if (!category.cntselected) {
                      return (
                        <div
                          key={category.id}
                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px10} ${styles.py5} ${styles.cursorPointer}`}
                          onClick={() => handleCategory(category)}
                        >
                          <p className={`${styles.font14} ${styles.black}`}>
                            {category.name + " 스케쥴"}
                          </p>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        <p className={`${styles.fontM} ${styles.px5} ${styles.py5}`}>에서</p>
        <NumberFormat
          value={scheduleLimit.value}
          thousandSeparator={false}
          style={{ width: 60 }}
          className={`${styles.textCenter} ${styles.inputWhiteUnderline}`}
          type={"tel"}
          displayType={"input"}
          onValueChange={handleScheduleLimit}
          placeholder={"숫자입력"}
        />
        <p className={`${styles.fontM} ${styles.py5}`}>회까지만 예약 가능</p>
        <div
          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr10} ${styles.px10} ${styles.cursorPointer}`}
          style={{ flexWrap: "nowrap" }}
          onClick={() => handleExceptPrev(!limit.exceptPrev)}
        >
          <div
            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}
            style={{ aspectRatio: "1" }}
          >
            {limit.exceptPrev && (
              <div
                className={`${styles.bgOrange} ${styles.borderRadius5}`}
                style={{
                  width: 14,
                  height: 14,
                }}
              />
            )}
          </div>
          <p className={`${styles.font14} ${styles.black} ${styles.ml5}`}>
            지나간 예약은 조건 계산 시 제외하기
          </p>
        </div>
      </div>
      <p
        className={`${styles.fontB} ${styles.px10} ${styles.py10} ${styles.cursorPointer}`}
        style={{ alignSelf: "right", margin: "auto" }}
        onClick={() => removeItem(limit.id)}
      >
        X
      </p>
    </div>
  );
};

export default ReservationLimitCntItem;
