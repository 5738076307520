import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Modal from 'react-modal';
import Calendar from 'react-calendar';

import styles from '../../../styles/styles.module.scss';
import { bill } from '../../../common/modal';
import useInput from '../../../hooks/useInput';
import MembershipHoldingItem from './MembershipHoldingItem';

const HOLDING = gql`
    query getHoldings($membershipId: Int!) {
        getHoldings(membershipId: $membershipId) {
            id
            start
            end
            reason
            status
        }
    }
`;

const ADD = gql`
    mutation addHolding(
        $membershipId: Int!
        $start: String!
        $end: String!
        $reason: String
    ) {
        addHolding(
            membershipId: $membershipId
            start: $start
            end: $end
            reason: $reason
        ) {
            member {
                id
                defaultMembership {
                    id
                    start
                    end
                    status
                }
                memberships {
                    id
                    start
                    end
                    status
                }
            }
            error
        }
    }
`;

const REMOVE = gql`
    mutation removeHolding($holdingId: Int!) {
        removeHolding(holdingId: $holdingId) {
            member {
                id
                defaultMembership {
                    id
                    start
                    end
                    status
                }
                memberships {
                    id
                    start
                    end
                    status
                }
            }
            error
        }
    }
`;

const EDIT = gql`
    mutation editHolding(
        $holdingId: Int!
        $start: String!
        $end: String!
        $reason: String
    ) {
        editHolding(
            holdingId: $holdingId
            start: $start
            end: $end
            reason: $reason
        ) {
            member {
                id
                defaultMembership {
                    id
                    start
                    end
                    status
                }
                memberships {
                    id
                    start
                    end
                    status
                }
            }
            error
        }
    }
`;

const MembershipHolding = ({ membership, close }) => {
    const reason = useInput('');

    const [loading, setLoading] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [start, setStart] = useState(null);
    const [showStart, setShowStart] = useState(false);
    const [end, setEnd] = useState(null);
    const [showEnd, setShowEnd] = useState(false);

    const { data: holding, refetch } = useQuery(HOLDING, {
        fetchPolicy: 'cache-and-network',
        variables: {
            membershipId: membership.id,
        },
    });

    const [addHoldingM] = useMutation(ADD);
    const [removeHoldingM] = useMutation(REMOVE);
    const [editHoldingM] = useMutation(EDIT);

    const handleStart = start => {
        setStart(start);
        setShowStart(false);
    };

    const handleEnd = end => {
        setEnd(end);
        setShowEnd(false);
    };

    const add = async () => {
        if (!loading) {
            if (start && end) {
                if (start > end) {
                    alert('종료일이 시작일보다 빠릅니다.');
                }
                setLoading(true);
                try {
                    const { data } = await addHoldingM({
                        variables: {
                            membershipId: membership.id,
                            start: `${start.getTime()}`,
                            end: `${end.getTime()}`,
                            reason: reason.value,
                        },
                    });
                    if (data && data.addHolding.member) {
                        await refetch();
                        setShowAdd(false);
                        setLoading(false);
                        alert('추가하였습니다.');
                    } else if (data && data.addHolding.error) {
                        setLoading(false);
                        alert(data.addHolding.error);
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            } else {
                alert('시작일과 종료일을 선택해주세요.');
            }
        }
    };

    const remove = async holdingId => {
        if (!loading && holdingId) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await removeHoldingM({
                        variables: {
                            holdingId,
                        },
                    });
                    if (data && data.removeHolding.member) {
                        await refetch();
                        setLoading(false);
                        alert('삭제하였습니다.');
                    } else if (data && data.removeHolding.error) {
                        setLoading(false);
                        alert(data.removeHolding.error);
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const edit = async holding => {
        if (!loading) {
            if (holding.start && holding.end) {
                if (holding.start > holding.end) {
                    alert('종료일이 시작일보다 빠릅니다.');
                }
                setLoading(true);
                try {
                    const { data } = await editHoldingM({
                        variables: {
                            holdingId: holding.id,
                            start: `${holding.start.getTime()}`,
                            end: `${holding.end.getTime()}`,
                            reason: holding.reason,
                        },
                    });
                    if (data && data.editHolding.member) {
                        await refetch();
                        setLoading(false);
                        alert('변경하였습니다.');
                    } else if (data && data.editHolding.error) {
                        setLoading(false);
                        alert(data.editHolding.error);
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            } else {
                alert('시작일과 종료일을 선택해주세요.');
            }
        }
    };

    useEffect(() => {
        setStart(null);
        setEnd(null);
        reason.setValue('');
    }, [showAdd]);

    return (
        <div className={`${styles.bgWhite}`}>
            <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.py15} ${styles.px15}`}>
                <p
                    className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.hidden}`}>
                    홀딩 추가
                </p>
                <p
                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                    홀딩 관리
                </p>
                <p
                    className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`}
                    onClick={() => setShowAdd(true)}>
                    홀딩 추가
                </p>
            </div>
            <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py10} ${styles.px15} ${styles.bgGrayF4}`}>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        회차
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        시작일
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        종료일
                    </p>
                </div>
                <div className={`${styles.flex1}`}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        상태
                    </p>
                </div>
                <div className={`${styles.flex2}`}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        사유
                    </p>
                </div>
                <div className={`${styles.flex1}`}></div>
            </div>
            <div
                className={`${styles.bgWhite} ${styles.hideScroll}`}
                style={{ overflowY: 'scroll', maxHeight: 400 }}>
                {holding && holding.getHoldings
                    ? holding.getHoldings.map((holding, index) => {
                          return (
                              <MembershipHoldingItem
                                  key={holding.id}
                                  holding={holding}
                                  index={index}
                                  remove={() => remove(holding.id)}
                                  edit={edit}
                              />
                          );
                      })
                    : null}
            </div>
            {showAdd && (
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px15} ${styles.bgWhite}`}>
                    <div className={`${styles.flex1}`}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                            {holding && holding.getHoldings
                                ? holding.getHoldings.length + 1
                                : ''}
                        </p>
                    </div>
                    <div className={`${styles.flex1}`}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${
                                start ? styles.black : styles.grayA2
                            } ${styles.textCenter} ${styles.cursorPointer}`}
                            onClick={() => setShowStart(true)}>
                            {start
                                ? `${start.getFullYear()}/${
                                      start.getMonth() + 1 < 10
                                          ? '0' + String(start.getMonth() + 1)
                                          : start.getMonth() + 1
                                  }/${
                                      start.getDate() < 10
                                          ? '0' + String(start.getDate())
                                          : start.getDate()
                                  }`
                                : '선택해주세요.'}
                        </p>
                    </div>
                    <div className={`${styles.flex1}`}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${
                                end ? styles.black : styles.grayA2
                            } ${styles.textCenter} ${styles.cursorPointer}`}
                            onClick={() => setShowEnd(true)}>
                            {end
                                ? `${end.getFullYear()}/${
                                      end.getMonth() + 1 < 10
                                          ? '0' + String(end.getMonth() + 1)
                                          : end.getMonth() + 1
                                  }/${
                                      end.getDate() < 10
                                          ? '0' + String(end.getDate())
                                          : end.getDate()
                                  }`
                                : '선택해주세요.'}
                        </p>
                    </div>
                    <div className={`${styles.flex1}`}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                            자동입력
                        </p>
                    </div>
                    <div className={`${styles.flex2}`}>
                        <input
                            type={'text'}
                            name={'reason'}
                            value={reason.value}
                            onChange={reason.onChange}
                            className={`${styles.inputTransparent} ${styles.flex1}`}
                            placeholder={'입력해주세요. (선택사항)'}
                            style={{
                                width: '100%',
                                minWidth: 20,
                            }}
                        />
                    </div>
                    <div className={`${styles.flex1}`}>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                            onClick={add}>
                            추가
                        </p>
                    </div>
                </div>
            )}
            <div
                className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`}
                style={{ opacity: loading ? 0.4 : 1 }}
                onClick={close}>
                <p
                    className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>
                    확인
                </p>
            </div>
            <Modal
                isOpen={showStart}
                onRequestClose={() => setShowStart(false)}
                style={bill}>
                <Calendar
                    calendarType={'US'}
                    value={start}
                    maxDate={end ? end : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleStart}
                />
            </Modal>
            <Modal
                isOpen={showEnd}
                onRequestClose={() => setShowEnd(false)}
                style={bill}>
                <Calendar
                    calendarType={'US'}
                    value={end}
                    minDate={start ? start : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleEnd}
                />
            </Modal>
        </div>
    );
};

export default MembershipHolding;
