import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import cache from '../../../apollo/cache';
import useInput from '../../../hooks/useInput';
import Video from '../../../components/Video/Mobile/Video';

const VIDEO = gql`
    query getVideos($order: String!, $skip: Int!, $q: String) {
        getVideos(order: $order, skip: $skip, q: $q) {
            videos {
                id
                name
            }
            total
        }
    }
`;

const DELETE = gql`
    mutation deleteVideo($id: Int!) {
        deleteVideo(id: $id)
    }
`;

const VideoHome = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput('');

    const [loading, setLoading] = useState(false);

    const {
        data,
        loading: loadingVideo,
        refetch,
    } = useQuery(VIDEO, {
        fetchPolicy: 'cache-and-network',
        variables: {
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
            skip: 0,
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });

    const [deleteVideoM] = useMutation(DELETE);

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/video/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${q.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/video/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const remove = async video => {
        if (!loading && video) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await deleteVideoM({
                        variables: {
                            id: video.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.deleteVideo) {
                        cache.evict({
                            id: `Video:${video.id}`,
                        });
                        alert('삭제하였습니다.');
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (
            queryString.parse(location.search).page &&
            !isNaN(Number(queryString.parse(location.search).page))
        ) {
            window.scrollTo(0, 0);
            refetch({
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc',
                skip:
                    10 * (Number(queryString.parse(location.search).page) - 1),
                q: queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : '',
            });
            q.setValue(queryString.parse(location.search).q);
        } else {
            history.replace({
                pathname: `/video/?page=1&order=${
                    queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'name_asc'
                }&q=${
                    queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                }`,
                state: location.state,
            });
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        영상 관리
                    </p>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() =>
                                history.push('/video/category/edit/')
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                카테고리 관리
                            </p>
                        </div>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
                            onClick={() => history.push('/video/add/')}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                새 영상 등록
                            </p>
                        </div>
                    </div>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt15} ${styles.px40}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img
                                src={
                                    require('../../../assets/images/icon_search.png')
                                        .default
                                }
                                alt={'search'}
                                className={`${styles.icon20} ${styles.cursorPointer}`}
                                onClick={submit}
                            />
                            <input
                                type={'text'}
                                name={'q'}
                                value={q.value}
                                onChange={q.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                placeholder={
                                    '영상명, 카테고리명, 세부 카테고리명 검색'
                                }
                            />
                        </div>
                    </form>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                영상명
                            </p>
                        </div>
                        <div className={`${styles.flex2}`}></div>
                    </div>
                    <div>
                        {loadingVideo ? (
                            <Skeleton height={50} count={3} />
                        ) : data &&
                          data.getVideos &&
                          data.getVideos.videos.length > 0 ? (
                            data.getVideos.videos.map(video => {
                                return (
                                    <Video
                                        key={video.id}
                                        video={video}
                                        remove={() => remove(video)}
                                        loading={loading}
                                    />
                                );
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    영상이 없습니다.
                                </p>
                            </div>
                        )}
                        {data?.getVideos?.total > 10 ? (
                            <div
                                className={`${styles.mt35}`}
                                style={{ position: 'relative' }}>
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_back.png')
                                                    .default
                                            }
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_front.png')
                                                    .default
                                            }
                                            alt={'다음'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    breakLabel={
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    }
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={
                                        data?.getVideos?.total
                                            ? parseInt(
                                                  (data?.getVideos?.total - 1) /
                                                      10,
                                              ) + 1
                                            : 1
                                    }
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={
                                        loadingVideo ? null : handlePage
                                    }
                                    initialPage={
                                        queryString.parse(location.search).page
                                            ? Number(
                                                  queryString.parse(
                                                      location.search,
                                                  ).page,
                                              ) - 1
                                            : 0
                                    }
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingVideo && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.4)',
                                        }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoHome;
