import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import Terms from '../../../components/Contract/PC/Terms';
import useInput from '../../../hooks/useInput';

const TERMS = gql`
    query getTerms($machine: String!, $skip: Int!, $order: String!, $search: String){
        getTerms(machine: $machine, skip: $skip, order: $order, search: $search){
            terms{
                id
                name
                file
                default
                contractTotal
                createdAt
            }
            total
        }
    }
`

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
        }
    }
`

const EDIT = gql`
    mutation editDefaultTerms($machine: String!, $termId: Int!){
        editDefaultTerms(machine: $machine, termId: $termId){
            terms{
                id
                name
                default
                contractTotal
                createdAt
            }
            error
        }
    }
`

const ContractTermList = () => {
    const history = useHistory()
    const location = useLocation()

    const search = useInput("")

    const [loading, setLoading] = useState(false)

    const { data, loading:loadingTerms, refetch } = useQuery(TERMS, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0,
            machine: location.state.machine ? location.state.machine : "",
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            search: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        }
    })
    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [editDefaultTermsM] = useMutation(EDIT)

    const submit = (e) => {
        e.preventDefault()
        history.push({
            pathname: `/contract/terms/list/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${search.value}`
        })
    }

    const handlePage = (page) => {
        history.push({
            pathname: `/contract/terms/list/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
        })
    }

    const handleOrder = (order) => {
        if(order === "date"){
            if(queryString.parse(location.search).order === "date_asc"){
                applyOrder("date_dsc")
            }
            else if(queryString.parse(location.search).order === "date_dsc"){
                applyOrder("date_asc")
            }
            else{
                applyOrder("date_dsc")
            }
        }
        else if(order === "name"){
            if(queryString.parse(location.search).order === "name_asc"){
                applyOrder("name_dsc")
            }
            else if(queryString.parse(location.search).order === "name_dsc"){
                applyOrder("name_asc")
            }
            else{
                applyOrder("name_asc")
            }
        }
        else{
            applyOrder("date_dsc")
        }
    }

    const applyOrder = (order) => {
        history.replace({
            pathname: "/contract/terms/list/",
            state: location.state,
            search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
        })
    }

    const editDefaultTerms = async(terms) => {
        if(!loading && terms && !terms.default){
            const confirm = window.confirm("해당 약관을 기본으로 변경하시겠습니까?")
            if(confirm){
                setLoading(true)
                const { data } = await editDefaultTermsM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        termId: terms.id
                    }
                })
                setLoading(false)
                if(data && data.editDefaultTerms.error){
                    alert(data.editDefaultTerms.error)
                }
                else{
                    alert("변경하였습니다.")
                }
            }
        }
    }

    useEffect(() => {
        if(location.state.machine){
            if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
                window.scrollTo(0,0)
                refetch({
                    skip: 10*(Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                    search: queryString.parse(location.search).q ? queryString.parse(location.search).q : ""
                })
            }
            else{
                history.replace({
                    pathname: `/contract/terms/list/`,
                    state: location.state,
                    search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
                })
            }
        }
        else{
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`계약 양식 관리${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <form action={""} onSubmit={submit}>
                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`} style={{ width: 480 }}>
                                        <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon25} ${styles.cursorPointer}`} onClick={submit} />
                                        <input 
                                        type={"text"} 
                                        name={"search"} 
                                        value={search.value} 
                                        onChange={search.onChange} 
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} 
                                        placeholder={"약관명 검색"}
                                        />
                                    </div>
                                </form>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => history.push({
                                    pathname: `/contract/terms/add/`,
                                    state: {
                                        machine: location.state.machine ? location.state.machine : ""
                                    }
                                })}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        양식 추가
                                    </p>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록일시
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "date_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "date_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    약관명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    사인된 계약수
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 사용 여부
                                </p>
                            </div>
                        </div>
                        <div>
                            {loadingTerms ? (
                                <Skeleton height={50} count={3}/>
                            ) : (
                                (data && data.getTerms && (data.getTerms.terms.length > 0)) ? (
                                    data.getTerms.terms.map(terms => {
                                        return(
                                            <Terms 
                                            key={terms.id} 
                                            terms={terms} 
                                            editDefaultTerms={() => editDefaultTerms(terms)}
                                            loading={loading}
                                            />
                                        )
                                    })
                                ) : (
                                    <div className={`${styles.center}`} style={{ height: 600 }}>
                                        <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                            아직 등록된 양식이 없습니다.
                                        </p>
                                    </div>
                                )
                            )}
                            {(data?.getTerms?.total > 5) ? (
                                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                    <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(data?.getTerms?.total) ? parseInt((data?.getTerms?.total - 1)/10) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingTerms ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                    />
                                    {loadingTerms && (
                                        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractTermList;