import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import MachineCustomSaleBar from '../../../components/Graph/MachineCustomSaleBar';
import MachineDayWeekSaleBar from "../../../components/Graph/MachineDayWeekSaleBar";


const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
        }
    }
`

const CashPaymentStatics = () => {
    const history = useHistory()
    const location = useLocation()

    const [period, setPeriod] = useState("day")

    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.pt50} ${styles.pb30} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`결제 통계${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div className={`${(period === "day") ? styles.bgOrange : styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer}`} onClick={() => setPeriod("day")}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        일간
                                    </p>
                                </div>
                                <div className={`${(period === "week") ? styles.bgOrange : styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setPeriod("week")}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        주간
                                    </p>
                                </div>
                                <div className={`${(period === "month") ? styles.bgOrange : styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setPeriod("month")}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        월간
                                    </p>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.mt20}`}>
                        {
                            period === 'week' ? (
                                <div className={`${styles.mb20}`}>
                                    <MachineDayWeekSaleBar
                                        machine={location.state.machine}
                                        type={'cash'}
                                    />
                                </div>
                            ) : null
                        }
                        <div className={`${styles.widthFull}`}>
                            <MachineCustomSaleBar
                            period={period}
                            machine={location.state.machine}
                            type={"cash"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashPaymentStatics;
