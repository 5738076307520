import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import {useHistory, useLocation} from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import TodayReservationMember from "../../../components/Schedule/PC/TodayReservationMember";

const RESERVATIONS = gql`
    query getTodayListScheduleReservation(
        $machine: String!
        $order: String!
        $skip: Int!
        $date: String
        $viewDateType: String
        $listType: Int
    ) {
        getTodayListScheduleReservation(
            machine: $machine
            order: $order
            skip: $skip
            date: $date
            viewDateType: $viewDateType
            listType: $listType
        ) {
            scheduleReservations {
                id
                status
                scheduleid
                schedulename
                scheduledate
                schedulestart
                scheduleendtime
                membername
                mobile
                gender
            }
            total
            displayDate
            error
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
            username
        }
    }
`;

const RESERVATION = gql`
    mutation addScheduleReservation(
        $machine: String!
        $memberId: Int!
        $scheduleId: Int!
        $status: Int!
        $over: Boolean!
    ) {
        addScheduleReservation(machine: $machine, memberId: $memberId, scheduleId: $scheduleId, status: $status, over: $over) {
            success
            error
            over
        }
    }
`;

const ScheduleMembershipList = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput(
        queryString.parse(location.search).q
            ? queryString.parse(location.search).q
            : '',
    );

    const [showOverModal, setShowOverModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalMember, setModalMember] = useState(null);
    const [status, setStatus] = useState(location.state.status ?? 1);

    const [addReservationM] = useMutation(RESERVATION);

    const {
        data,
        loading: loadingMember,
        refetch,
    } = useQuery(RESERVATIONS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
            skip: 0,
            date: location.state.date ? location.state.date : '',
            viewDateType: location.state.viewDateType ? location.state.viewDateType : '',
            listType: location.state.listType ? location.state.listType : -1
        },
    });
    console.log('location.state.machine',location.state.machine);
    console.log("location.state.date",location.state.date);
    console.log("location.state.viewDateType",location.state.viewDateType);
    console.log("location.state.listType",location.state.listType);
    console.log("data::::::", data);

    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });
    const handlePage = page => {
        history.push({
            pathname: `/schedule/reservation/today_list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&page=${page.selected + 1}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const handleShowModal = (show, member) => {
        setModalMember(member);
        setShowOverModal(show ?? false);
    };

    const handleOrder = order => {
        console.log('queryString.parse(location.search).order ??' , queryString.parse(location.search).order);

        if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'date') {
            if (queryString.parse(location.search).order === 'date_asc') {
                applyOrder('date_dsc');
            } else if (queryString.parse(location.search).order === 'date_dsc') {
                applyOrder('date_asc');
            } else {
                applyOrder('date_asc');
            }
        } else if (order === 'user_name') {
            if (queryString.parse(location.search).order === 'user_name_asc') {
                applyOrder('user_name_dsc');
            } else if (queryString.parse(location.search).order === 'user_name_dsc') {
                applyOrder('user_name_asc');
            } else {
                applyOrder('user_name_asc');
            }
        } else if (order === 'gender') {
            if (queryString.parse(location.search).order === 'gender_asc') {
                applyOrder('gender_dsc');
            } else if (queryString.parse(location.search).order === 'gender_dsc') {
                applyOrder('gender_asc');
            } else {
                applyOrder('gender_asc');
            }
        } else {
            applyOrder('name_asc');
        }
    };

    const applyOrder = order => {
        history.replace({
            pathname: '/schedule/reservation/today_list/',
            state: location.state,
            search: `?order=${order}&page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/schedule/reservation/today_list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&q=${q.value}`,
        });
    };

    useEffect(() => {
        if (location.state.machine) {
            if (
                queryString.parse(location.search).page &&
                !isNaN(Number(queryString.parse(location.search).page))
            ) {
                window.scrollTo(0, 0);
                refetch({
                    skip: (Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'name_asc',
                    q: queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                });
            } else {
                history.replace({
                    pathname: `/schedule/reservation/today_list/`,
                    state: location.state,
                    search: `?order=${
                        queryString.parse(location.search).order
                            ? queryString.parse(location.search).order
                            : 'name_asc'
                    }&page=1&q=${
                        queryString.parse(location.search).q
                            ? queryString.parse(location.search).q
                            : ''
                    }`,
                });
            }
        } else {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                { location.state.listType === 1 && '오늘의 예약인원' }
                                { location.state.listType === 2 && '오늘의 출석인원' }
                                { location.state.listType === 3 && '오늘의 결석인원' }
                                {`${
                                    machine && machine.getMachine
                                        ? ` (${machine.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`}
                                    onClick={history.goBack}>
                                    <img
                                        src={
                                            require('../../../assets/images/icon_back.png')
                                                .default
                                        }
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('name')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    예약 수업명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                    .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                    .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => {handleOrder('date')}}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    예약한 수업의 시간
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'date_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                    .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'date_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                    .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}
                                onClick={() => {handleOrder('user_name')}}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'user_name_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                    .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'user_name_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                    .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                 // onClick={() => {handleOrder('phone_number')}}
                            >
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    연락처
                                </p>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => {handleOrder('gender')}}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    성별
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'gender_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                    .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'gender_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                    .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                    .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ minHeight: 600 }}>
                            {loadingMember ? (
                                <Skeleton height={50} count={3} />
                            ) : data && data.getTodayListScheduleReservation &&
                            data.getTodayListScheduleReservation.scheduleReservations.length > 0 ? (
                                data.getTodayListScheduleReservation.scheduleReservations.map(scheduleReservations => {
                                    return (
                                        <TodayReservationMember
                                            key={scheduleReservations.id}
                                            scheduleReservations={scheduleReservations}
                                            machine={
                                                machine && machine.getMachine
                                                    ? machine.getMachine
                                                    : {}
                                            }
                                        />
                                    );
                                })
                            ) : (
                                <div
                                    className={`${styles.center}`}
                                    style={{ height: 600 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        회원 목록이 없습니다.
                                    </p>
                                </div>
                            )}
                            {data?.getTodayListScheduleReservation?.total > 10 ? (
                                <div
                                    className={`${styles.mt60} ${styles.mb10}`}
                                    style={{ position: 'relative' }}>
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_back.png')
                                                        .default
                                                }
                                                alt={'이전'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_front.png')
                                                        .default
                                                }
                                                alt={'다음'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        breakLabel={
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                &#183;&#183;&#183;
                                            </p>
                                        }
                                        breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                        pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                        activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                        previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                        nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                        disabledClassName={`${styles.icon30} ${styles.center}`}
                                        pageCount={
                                            data?.getTodayListScheduleReservation?.total
                                                ? parseInt(
                                                (data?.getTodayListScheduleReservation?.total -
                                                    1) /
                                                10,
                                            ) + 1
                                                : 1
                                        }
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={4}
                                        onPageChange={
                                            loadingMember ? null : handlePage
                                        }
                                        initialPage={
                                            queryString.parse(location.search)
                                                .page
                                                ? Number(
                                                queryString.parse(
                                                    location.search,
                                                ).page,
                                            ) - 1
                                                : 0
                                        }
                                        containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                    />
                                    {loadingMember && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.4)',
                                            }}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScheduleMembershipList;
