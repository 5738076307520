import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const User = ({ user, remove, removing, conversion }) => {
  const history = useHistory();

  const date = new Date(Number(user.createdAt));

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}</p>
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{user.name}</p>
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {user.status === "inuse" && "사용중"}
          {user.status === "expire" && "만료"}
          {user.status === "request_withdrawal" && "탈퇴 요청"}
          {user.status === "withdrawal" && "탈퇴"}
        </p>
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{numberWithCommas(user.machines)}</p>
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{numberWithCommas(user.nmachines)}</p>
      </div>
      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{user.isAdvertiseAgree ? "동의" : "미동의"}</p>
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`} onClick={conversion}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리접속</p>
        </div>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.ml30} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: "/user/edit/",
              state: {
                user,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>수정</p>
        </div>
        <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.ml30} ${styles.cursorPointer}`} style={{ opacity: removing ? 0.4 : 1 }} onClick={remove} />
      </div>
    </div>
  );
};

export default User;
