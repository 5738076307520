import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";

const CHANGE_USER = gql`
  mutation changeUser($id: Int!, $name: String!, $username: String!, $status: String!) {
    changeUser(id: $id, name: $name, username: $username, status: $status) {
      success
      result {
        id
        name
        username
        status
      }
    }
  }
`;

const CHECK_USERNAME = gql`
  query checkUsername($username: String!) {
    checkUsername(username: $username)
  }
`;

const UserEdit = () => {
  const history = useHistory();
  const location = useLocation();

  const date = new Date(Number(location.state.user ? location.state.user.createdAt : new Date().getTime()));

  const name = useInput(location.state.user.name);
  const username = useInput(location.state.user.username);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(location.state.user ? location.state.user.status : "");
  const [showStatus, setShowStatus] = useState(false);

  const { refetch: refetchUsername } = useQuery(CHECK_USERNAME, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const [changeUserM] = useMutation(CHANGE_USER);

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading && location.state.user) {
      if (status && name.value && username.value) {
        if (location.state.user.status === status && location.state.user.name !== name.value && location.state.user.username !== username.value) {
          alert("변경사항이 없습니다.");
        } else {
          setLoading(true);
          let usernameChecked = false;
          if (location.state.user.username === username.value) {
            usernameChecked = true;
          } else {
            const { data: check } = await refetchUsername({
              username: username.value,
            });
            if (check && check.checkUsername) {
              usernameChecked = true;
            } else {
              usernameChecked = false;
            }
          }
          if (usernameChecked) {
            const { data: result } = await changeUserM({
              variables: {
                id: location.state.user.id,
                name: name.value,
                username: username.value,
                status,
              },
            });
            setLoading(false);
            if (result && result.changeUser.success) {
              alert("변경되었습니다.");
              history.replace({
                pathname: location.pathname,
                state: {
                  user: {
                    ...location.state.user,
                    name: result.changeUser.result.name,
                    username: result.changeUser.result.username,
                    status: result.changeUser.result.status,
                  },
                },
              });
            } else {
              alert("오류가 발생하였습니다.");
            }
          } else {
            setLoading(false);
            alert("아이디가 중복됩니다.");
          }
        }
      } else {
        alert("위 정보를 입력해주세요.");
      }
    }
  };

  useEffect(() => {
    if (!location.state.user) {
      alert("잘못된 접근입니다.");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>관리자 계정 관리</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>이름</p>
                  </div>
                  <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>가입일자</p>
                  </div>
                  <input type={"text"} value={`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>이메일(아이디)</p>
                  </div>
                  <input type={"email"} name={"username"} value={username.value} onChange={username.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{ width: 615, borderBottomLeftRadius: showStatus ? 0 : 10, borderBottomRightRadius: showStatus ? 0 : 10 }}
                    onClick={() => setShowStatus(!showStatus)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>계정 상태</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${status ? styles.black : styles.grayA2}`}>
                        {status === "inuse" && "사용중"}
                        {status === "expire" && "만료"}
                        {status === "request_withdrawal" && "탈퇴 요청"}
                        {status === "withdrawal" && "탈퇴"}
                      </p>
                      <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"계정 상태"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showStatus && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div className={`${styles.borderRightGrayA2} ${styles.px20}`} style={{ width: 190 }}></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("inuse")}>
                            <p className={`${styles.font16} ${styles.black}`}>사용중</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("expire")}>
                            <p className={`${styles.font16} ${styles.black}`}>만료</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("request_withdrawal")}>
                            <p className={`${styles.font16} ${styles.black}`}>탈퇴 요청</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("withdrawal")}>
                            <p className={`${styles.font16} ${styles.black}`}>탈퇴</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb70}`} style={{ width: 1250, marginTop: 500 }}>
                <div className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`} style={{ opacity: loading ? 0.4 : 1, width: 270 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.center} ${styles.borderRadiusRound} ${styles.py15} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1, width: 270 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>저장</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEdit;
