import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const SignUpClear = () => {
    const history = useHistory()

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
            <div className={`${styles.container} ${styles.alignItemsEnd} ${styles.justifyContentCenter} ${styles.overflowHidden}`}>
                <img src={require("../../../assets/images/brothers.png").default} alt={"VEN Brothers"} style={{ maxWidth: 830, position: "absolute", bottom: -190, left: 180 }} />
                <img src={require("../../../assets/images/logo_lg.png").default} alt={"VEN Brothers"} style={{ maxWidth: 400, position: "absolute", top: 80, left: 95 }} />
                <div className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px30} ${styles.mr100} ${styles.pAuthBox} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font34} ${styles.black} ${styles.textCenter}`}>
                        계정 만들기 완료
                    </p>
                    <p className={`${styles.fontM} ${styles.font18} ${styles.black} ${styles.textCenter} ${styles.mt40}`}>
                        벤브라더스 가입을 환영합니다. <br/>로그인 페이지로 이동하여 벤브라더스에 접속하세요!
                    </p>
                    <div className={`${styles.mt70} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer} ${styles.containerInput}`} onClick={() => history.push("/")}>
                        <p className={`${styles.fontB} ${styles.font18} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpClear;