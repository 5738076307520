import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg } from '../../../common/regex';
import Location from '../../../components/Advertise/PC/Location';
import Machine from '../../../components/Advertise/PC/Machine';

const DETAIL = gql`
    query getAdvertise($id: Int!) {
        getAdvertise(id: $id) {
            id
            name
            description
            ownerName
            ownerContact
            createdAt
            status
            videoId
            start
            end
            locations {
                id
                sido
                sigungu
                bname
            }
            machinesCount
            machines {
                id
                name
            }
        }
    }
`;

const SIDO = gql`
    {
        getSido {
            sido
        }
    }
`;

const SIGUNGU = gql`
    query getSigungu($sido: String!) {
        getSigungu(sido: $sido) {
            sigungu
        }
    }
`;

const BNAME = gql`
    query getBname($sido: String!, $sigungu: String!) {
        getBname(sido: $sido, sigungu: $sigungu) {
            bname
        }
    }
`;

const EDIT = gql`
    mutation editAdvertise(
        $id: Int!
        $videoId: String!
        $name: String!
        $ownerName: String!
        $ownerContact: String!
        $start: String!
        $status: String!
        $end: String
        $description: String
        $locations: String
    ) {
        editAdvertise(
            id: $id
            videoId: $videoId
            name: $name
            ownerName: $ownerName
            ownerContact: $ownerContact
            start: $start
            status: $status
            end: $end
            description: $description
            locations: $locations
        ) {
            success
            error
            result {
                id
                name
                description
                ownerName
                ownerContact
                createdAt
                status
                videoId
                start
                end
                locations {
                    id
                    sido
                    sigungu
                    bname
                }
                machinesCount
            }
        }
    }
`;

const DISCONNECT = gql`
    mutation disconnectAdvertise($machineId: Int!, $id: Int!) {
        disconnectAdvertise(machineId: $machineId, id: $id)
    }
`;

const DELETE = gql`
    mutation deleteAdvertise($id: Int!) {
        deleteAdvertise(id: $id)
    }
`;

const AdvertiseEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const videoId = useInput('');
    const name = useInput('');
    const ownerName = useInput('');
    const ownerContact = useInput('');
    const description = useInput('');

    const [start, setStart] = useState(null);
    const [showStart, setShowStart] = useState(false);
    const [end, setEnd] = useState(null);
    const [showEnd, setShowEnd] = useState(false);
    const [status, setStatus] = useState('');
    const [showStatus, setShowStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sido, setSido] = useState('');
    const [showSido, setShowSido] = useState(false);
    const [sigungu, setSigungu] = useState('');
    const [showSigungu, setShowSigungu] = useState(false);
    const [bname, setBname] = useState('');
    const [showBname, setShowBname] = useState(false);
    const [locations, setLocations] = useState([]);

    const { data, refetch } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: location.state.advertise.id,
        },
    });
    const { data: sidoList } = useQuery(SIDO, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: sigunguList } = useQuery(SIGUNGU, {
        fetchPolicy: 'cache-and-network',
        variables: {
            sido,
        },
    });
    const { data: bnameList } = useQuery(BNAME, {
        fetchPolicy: 'cache-and-network',
        variables: {
            sido,
            sigungu,
        },
    });

    const [editAdvertiseM] = useMutation(EDIT);
    const [deleteAdvertiseM] = useMutation(DELETE);
    const [disconnectAdvertiseM] = useMutation(DISCONNECT);

    const handleContact = e => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value)) {
            ownerContact.onChange(e);
        }
    };

    const handleStart = start => {
        setStart(start);
        setShowStart(false);
    };

    const handleEnd = end => {
        setEnd(end);
        setShowEnd(false);
    };

    const handleStatus = status => {
        setStatus(status);
        setShowStatus(false);
    };

    const handleShowSigungu = showSigungu => {
        if (showSigungu) {
            if (sido) {
                if (sido !== '전체') {
                    setShowSigungu(showSigungu);
                }
            } else {
                alert('송출 지역을 먼저 선택해주세요.');
            }
        } else {
            setShowSigungu(showSigungu);
        }
    };

    const handleShowBname = showBname => {
        if (showBname) {
            if (sido) {
                if (sido !== '전체') {
                    if (sigungu) {
                        setShowBname(showBname);
                    } else {
                        alert('송출 지역과 시/군을 먼저 선택해주세요.');
                    }
                }
            } else {
                alert('송출 지역과 시/군을 먼저 선택해주세요.');
            }
        } else {
            setShowBname(showBname);
        }
    };

    const handleSido = sido => {
        setSido(sido);
        setSigungu('');
        setBname('');
        setShowSido(false);
    };

    const handleSigungu = sigungu => {
        setSigungu(sigungu);
        setBname('');
        setShowSigungu(false);
    };

    const handleBname = bname => {
        setBname(bname);
        setShowBname(false);
    };

    const addLocation = () => {
        const findIndex = locations.findIndex(
            location =>
                location.sido === sido &&
                location.sigungu === sigungu &&
                location.bname === bname,
        );
        if (findIndex < 0) {
            setLocations([
                ...locations,
                {
                    id: -new Date().getTime(),
                    sido,
                    sigungu,
                    bname,
                },
            ]);
        }
    };

    const removeLocation = index => {
        let newLocations = [...locations];
        newLocations.splice(index, 1);
        setLocations(newLocations);
    };

    const removeMachine = async machine => {
        if (!loading && data && data.getAdvertise) {
            const confirm = window.confirm(
                '해당 기기에서 본 광고 송출을 중단하시겠습니까?',
            );
            if (confirm) {
                setLoading(true);
                const { data: result } = await disconnectAdvertiseM({
                    variables: {
                        machineId: machine.id,
                        id: data.getAdvertise.id,
                    },
                });
                setLoading(false);
                if (result && result.disconnectAdvertise) {
                    await refetch();
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading && data && data.getAdvertise) {
            if (
                !(
                    videoId.value &&
                    name.value &&
                    ownerName.value &&
                    ownerContact.value &&
                    start &&
                    status
                )
            ) {
                alert('위 정보를 입력해주세요.');
                return;
            }
            setLoading(true);
            try {
                const { data } = await editAdvertiseM({
                    variables: {
                        id: location.state.advertise.id,
                        videoId: videoId.value,
                        name: name.value,
                        ownerName: ownerName.value,
                        ownerContact: ownerContact.value,
                        start: `${start.getTime()}`,
                        status,
                        end: end ? `${end.getTime()}` : null,
                        description: description.value,
                        locations: JSON.stringify(locations),
                    },
                });
                setLoading(false);
                if (data && data.editAdvertise.success) {
                    alert('저장되었습니다.');
                    refetch();
                } else if (data && data.editAdvertise.error) {
                    alert(data.editAdvertise.error);
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
        }
    };

    const remove = async () => {
        if (!loading && location.state.advertise) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await deleteAdvertiseM({
                        variables: {
                            id: location.state.advertise.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.deleteAdvertise) {
                        history.goBack();
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const initialize = () => {
        if (data && data.getAdvertise) {
            videoId.setValue(data.getAdvertise.videoId);
            name.setValue(data.getAdvertise.name);
            ownerName.setValue(data.getAdvertise.ownerName);
            ownerContact.setValue(data.getAdvertise.ownerContact);
            description.setValue(data.getAdvertise.description);
            setStart(new Date(Number(data.getAdvertise.start)));
            setShowStart(false);
            setEnd(
                data.getAdvertise.end &&
                    new Date(Number(data.getAdvertise.end)).getFullYear() < 3000
                    ? new Date(Number(data.getAdvertise.end))
                    : null,
            );
            setShowEnd(false);
            setStatus(data.getAdvertise.status);
            setShowStatus(false);
            setSido('');
            setShowSido(false);
            setSigungu('');
            setShowSigungu(false);
            setBname('');
            setShowBname(false);
            setLocations(data.getAdvertise.locations);
        }
    };

    useEffect(() => {
        if (data && data.getAdvertise) {
            initialize();
        }
    }, [data]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                수정
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <form
                            action={''}
                            onSubmit={submit}
                            className={`${styles.mt40} ${styles.center}`}>
                            <div style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                    *표시는 필수 입력사항입니다.
                                </p>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 1250 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            광고 ID*
                                        </p>
                                    </div>
                                    <input
                                        type={'videoId'}
                                        name={'videoId'}
                                        value={videoId.value}
                                        onChange={videoId.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={'광고 ID를 선택해주세요.'}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            광고명*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        value={name.value}
                                        onChange={name.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={'광고명을 입력해주세요.'}
                                        maxLength={20}
                                    />
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            광고주명*
                                        </p>
                                    </div>
                                    <input
                                        type={'text'}
                                        name={'ownerName'}
                                        value={ownerName.value}
                                        onChange={ownerName.onChange}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={'광고주명을 입력해주세요.'}
                                        maxLength={20}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                    style={{ width: 615 }}>
                                    <div
                                        className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                        style={{ width: 190 }}>
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            광고주 연락처*
                                        </p>
                                    </div>
                                    <input
                                        type={'tel'}
                                        name={'ownerContact'}
                                        value={ownerContact.value}
                                        onChange={handleContact}
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                        placeholder={
                                            '광고주 연락처를 입력해주세요.'
                                        }
                                        maxLength={11}
                                    />
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius: showStart
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showStart
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() =>
                                            setShowStart(!showStart)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                광고 송출 시작일*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    start
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {start
                                                    ? `${start.getFullYear()}-${
                                                          start.getMonth() + 1 <
                                                          10
                                                              ? '0' +
                                                                String(
                                                                    start.getMonth() +
                                                                        1,
                                                                )
                                                              : start.getMonth() +
                                                                1
                                                      }-${
                                                          start.getDate() < 10
                                                              ? '0' +
                                                                String(
                                                                    start.getDate(),
                                                                )
                                                              : start.getDate()
                                                      }`
                                                    : '광고 송출 시작일을 선택해주세요.'}
                                            </p>
                                            <img
                                                src={
                                                    showStart
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'회원권 분류 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showStart && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10} ${styles.flex1}`}>
                                                    <Calendar
                                                        calendarType={'US'}
                                                        value={start}
                                                        minDetail={'month'}
                                                        maxDate={end}
                                                        nextLabel={
                                                            <span>
                                                                <img
                                                                    src={
                                                                        require('../../../assets/images/icon_dropdown_right.png')
                                                                            .default
                                                                    }
                                                                    alt={'다음'}
                                                                    className={`${styles.icon15}`}
                                                                />
                                                            </span>
                                                        }
                                                        next2Label={null}
                                                        prevLabel={
                                                            <span>
                                                                <img
                                                                    src={
                                                                        require('../../../assets/images/icon_dropdown_left.png')
                                                                            .default
                                                                    }
                                                                    alt={'이전'}
                                                                    className={`${styles.icon15}`}
                                                                />
                                                            </span>
                                                        }
                                                        prev2Label={null}
                                                        navigationLabel={({
                                                            label,
                                                        }) => (
                                                            <p
                                                                className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                                                                {label}
                                                            </p>
                                                        )}
                                                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                                        onChange={handleStart}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius: showStatus
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showStatus
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() =>
                                            setShowStatus(!showStatus)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                상태*
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    status
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {status === 'ready' &&
                                                    '송출대기'}
                                                {status === 'inuse' && '송출중'}
                                                {status === 'stop' &&
                                                    '송출중지'}
                                                {status === 'expired' &&
                                                    '계약만료'}
                                                {status === '' &&
                                                    '상태를 선택해주세요.'}
                                            </p>
                                            <img
                                                src={
                                                    showStatus
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'날짜 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showStatus && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10}`}>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus(
                                                                'ready',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            송출대기
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus(
                                                                'inuse',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            송출중
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus('stop')
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            송출중지
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleStatus(
                                                                'expired',
                                                            )
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            계약만료
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 615,
                                            borderBottomLeftRadius: showEnd
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showEnd
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() => setShowEnd(!showEnd)}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                광고 송출 종료일
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    end
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {end
                                                    ? `${end.getFullYear()}-${
                                                          end.getMonth() + 1 <
                                                          10
                                                              ? '0' +
                                                                String(
                                                                    end.getMonth() +
                                                                        1,
                                                                )
                                                              : end.getMonth() +
                                                                1
                                                      }-${
                                                          end.getDate() < 10
                                                              ? '0' +
                                                                String(
                                                                    end.getDate(),
                                                                )
                                                              : end.getDate()
                                                      }`
                                                    : '광고 송출 종료일을 선택해주세요.'}
                                            </p>
                                            <img
                                                src={
                                                    showEnd
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'회원권 분류 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showEnd && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 615 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10} ${styles.flex1}`}>
                                                    <Calendar
                                                        calendarType={'US'}
                                                        value={end}
                                                        minDetail={'month'}
                                                        minDate={start}
                                                        nextLabel={
                                                            <span>
                                                                <img
                                                                    src={
                                                                        require('../../../assets/images/icon_dropdown_right.png')
                                                                            .default
                                                                    }
                                                                    alt={'다음'}
                                                                    className={`${styles.icon15}`}
                                                                />
                                                            </span>
                                                        }
                                                        next2Label={null}
                                                        prevLabel={
                                                            <span>
                                                                <img
                                                                    src={
                                                                        require('../../../assets/images/icon_dropdown_left.png')
                                                                            .default
                                                                    }
                                                                    alt={'이전'}
                                                                    className={`${styles.icon15}`}
                                                                />
                                                            </span>
                                                        }
                                                        prev2Label={null}
                                                        navigationLabel={({
                                                            label,
                                                        }) => (
                                                            <p
                                                                className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                                                                {label}
                                                            </p>
                                                        )}
                                                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                                        onChange={handleEnd}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    광고 설명 (최대 50자)
                                </p>
                                <textarea
                                    name={'description'}
                                    value={description.value}
                                    onChange={description.onChange}
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`}
                                    style={{ width: 1208, height: 120 }}
                                    placeholder={'상품 설명을 입력해주세요.'}
                                    maxLength={50}
                                />
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 1075 / 3,
                                            borderBottomLeftRadius: showSido
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showSido
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() => setShowSido(!showSido)}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                광고 송출 지역
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    sido
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {sido ? sido : '지역'}
                                            </p>
                                            <img
                                                src={
                                                    showSido
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'날짜 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showSido && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 1075 / 3 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 1075 / 3 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10} ${styles.flex1}`}
                                                    style={{
                                                        maxHeight: 200,
                                                        overflowY: 'scroll',
                                                    }}>
                                                    <div
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() =>
                                                            handleSido('전체')
                                                        }>
                                                        <p
                                                            className={`${styles.font16} ${styles.black}`}>
                                                            전체
                                                        </p>
                                                    </div>
                                                    {sidoList &&
                                                    sidoList.getSido
                                                        ? sidoList.getSido.map(
                                                              s => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              s.sido
                                                                          }
                                                                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                          onClick={() =>
                                                                              handleSido(
                                                                                  s.sido,
                                                                              )
                                                                          }>
                                                                          <p
                                                                              className={`${styles.font16} ${styles.black}`}>
                                                                              {
                                                                                  s.sido
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 1075 / 3,
                                            borderBottomLeftRadius: showSigungu
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showSigungu
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() =>
                                            handleShowSigungu(!showSigungu)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                광고 송출 시 / 군
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    sigungu
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {sigungu ? sigungu : '시 / 군'}
                                            </p>
                                            <img
                                                src={
                                                    showSigungu
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'날짜 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showSigungu && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 1075 / 3 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 1075 / 3 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10} ${styles.flex1}`}
                                                    style={{
                                                        maxHeight: 200,
                                                        overflowY: 'scroll',
                                                    }}>
                                                    {sigunguList &&
                                                    sigunguList.getSigungu
                                                        ? sigunguList.getSigungu.map(
                                                              s => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              s.sigungu
                                                                          }
                                                                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                          onClick={() =>
                                                                              handleSigungu(
                                                                                  s.sigungu,
                                                                              )
                                                                          }>
                                                                          <p
                                                                              className={`${styles.font16} ${styles.black}`}>
                                                                              {
                                                                                  s.sigungu
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                        style={{
                                            width: 1075 / 3,
                                            borderBottomLeftRadius: showBname
                                                ? 0
                                                : 10,
                                            borderBottomRightRadius: showBname
                                                ? 0
                                                : 10,
                                        }}
                                        onClick={() =>
                                            handleShowBname(!showBname)
                                        }>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                광고 송출 동 / 면 / 읍
                                            </p>
                                        </div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                            <p
                                                className={`${styles.font16} ${
                                                    bname
                                                        ? styles.black
                                                        : styles.grayA2
                                                }`}>
                                                {bname ? bname : '동 / 면 / 읍'}
                                            </p>
                                            <img
                                                src={
                                                    showBname
                                                        ? require('../../../assets/images/icon_dropdown_up.png')
                                                              .default
                                                        : require('../../../assets/images/icon_dropdown_down.png')
                                                              .default
                                                }
                                                alt={'날짜 선택'}
                                                className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                            />
                                        </div>
                                    </div>
                                    {showBname && (
                                        <div
                                            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                            style={{ width: 1075 / 3 }}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                style={{ width: 1075 / 3 }}>
                                                <div
                                                    className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                    style={{
                                                        width: 190,
                                                    }}></div>
                                                <div
                                                    className={`${styles.py10} ${styles.flex1}`}
                                                    style={{
                                                        maxHeight: 200,
                                                        overflowY: 'scroll',
                                                    }}>
                                                    {bnameList &&
                                                    bnameList.getBname
                                                        ? bnameList.getBname.map(
                                                              s => {
                                                                  return (
                                                                      <div
                                                                          key={
                                                                              s.bname
                                                                          }
                                                                          className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                                          onClick={() =>
                                                                              handleBname(
                                                                                  s.bname,
                                                                              )
                                                                          }>
                                                                          <p
                                                                              className={`${styles.font16} ${styles.black}`}>
                                                                              {
                                                                                  s.bname
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  );
                                                              },
                                                          )
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{ width: 115 }}
                                    onClick={addLocation}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        추가
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    현재 송출 지역
                                </p>
                                <div
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.py15} ${styles.row} ${styles.mx0} ${styles.flexWrap}`}
                                    style={{
                                        width: 1208,
                                        height: 120,
                                        overflowY: 'scroll',
                                    }}>
                                    {locations.map((location, index) => {
                                        return (
                                            <Location
                                                key={index}
                                                location={location}
                                                last={
                                                    index ===
                                                    locations.length - 1
                                                }
                                                remove={() =>
                                                    removeLocation(index)
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div
                                className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`}
                                style={{ width: 1250 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    추가 송출 기기
                                </p>
                                <div
                                    className={`${styles.inputWhite} ${styles.flex1} ${styles.py15} ${styles.row} ${styles.mx0} ${styles.flexWrap}`}
                                    style={{
                                        width: 1208,
                                        height: 120,
                                        overflowY: 'scroll',
                                    }}>
                                    {data?.getAdvertise?.machines.map(
                                        (machine, index) => {
                                            return (
                                                <Machine
                                                    key={machine.id}
                                                    machine={machine}
                                                    last={
                                                        index ===
                                                        data?.getAdvertise
                                                            ?.machines.length -
                                                            1
                                                    }
                                                    remove={() =>
                                                        removeMachine(machine)
                                                    }
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`}
                                style={{ width: 1250 }}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={remove}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        삭제
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mx10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={history.goBack}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                                <button
                                    type={'submit'}
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p
                                        className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertiseEdit;
