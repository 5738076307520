import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from "../../../apollo/cache";
import Calendar from "react-calendar";

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const DETAIL_HOLIDAY = gql`
    query detailScheduleHoliday($id: Int!) {
        detailScheduleHoliday(id: $id) {
            name
            start
            end
        }
    }
`;

const EDIT_HOLIDAY = gql`
    mutation editScheduleHoliday($id: Int!, $name: String!, $start: String!, $end: String!) {
        editScheduleHoliday(id: $id, name:$name, start: $start, end: $end) {
            success
            error
        }
    }
`;

const DELETE_HOLIDAY = gql`
    mutation deleteScheduleHoliday($id: Int!) {
        deleteScheduleHoliday(id: $id) {
            success
            error
        }
    }
`;

const HolidayEdit = () => {
    const history = useHistory();
    const location = useLocation();

    const name = useInput('');
    const [editHoliday] = useMutation(EDIT_HOLIDAY);

    const [loading, setLoading] = useState(false);

    const [start, setStart] = useState(null);
    const [showStart, setShowStart] = useState(false);
    const [end, setEnd] = useState(null);
    const [showEnd, setShowEnd] = useState(false);

    const [deleteScheduleHolidayM] = useMutation(DELETE_HOLIDAY);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const { data: holidayData, loading:loadingHoliday, refetch:holidayRefetch } = useQuery(DETAIL_HOLIDAY, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: location.state.holiday ? location.state.holiday.id : -1
        }
    });

    const handleStart = start => {
        setStart(start);
        setShowStart(false);
    };

    const handleEnd = end => {
        setEnd(end);
        setShowEnd(false);
    };

    const deleteHoliday = async () => {
        if (!loading) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await deleteScheduleHolidayM({
                    variables: {
                        id: location.state.holiday.id
                    }
                });
                setLoading(false);
                console.log(data)
                if (data && data.deleteScheduleHoliday.success) {
                    cache.evict({
                        id: `Holiday:${location.state.holiday.id}`,
                    });
                    alert('휴일을 삭제하였습니다.');
                    history.goBack();
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);
            if (data && data.getMachine) {
                if (name.value && start && end) {
                    console.log('name : ',name.value);
                    const { data: result } = await editHoliday({
                        variables: {
                            id: location.state.holiday ? location.state.holiday.id : -1,
                            name: name.value,
                            start: start ? `${start.getTime()}` : null,
                            end: end ? `${end.getTime()}` : null,
                        }
                    });
                    if (result && result.editScheduleHoliday) {
                        if (result.editScheduleHoliday.error) {
                            setLoading(false);
                            alert(result.editScheduleHoliday.error);
                        } else {
                            setLoading(false);
                            alert('휴일 정보를 수정하였습니다.');
                            holidayRefetch();
                        }
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } else {
                    setLoading(false);
                    alert('필수 값을 모두 입력해주세요.');
                }
            }
        }
    }

    useEffect(() => {
        if (holidayData) {
            console.log(holidayData.detailScheduleHoliday);
            name.setValue(holidayData.detailScheduleHoliday.name);
            setStart(holidayData.detailScheduleHoliday.start ? new Date(holidayData.detailScheduleHoliday.start) : null);
            setEnd(holidayData.detailScheduleHoliday.end ? new Date(holidayData.detailScheduleHoliday.end) : null);
        }
    }, [holidayData]);

    useEffect(() => {
        if (!location.state.machine&&!location.state.holiday) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        휴일 관리
                        {`${
                            data && data.getMachine
                                ? ` (${data.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>

                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 115 }}>
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    휴일명*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'name'}
                                value={name.value}
                                onChange={name.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                placeholder={
                                    '휴일명을 입력해주세요.'
                                }
                            />
                        </div>

                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showStart ? 0 : 10,
                                    borderBottomRightRadius: showStart ? 0 : 10,
                                }}
                                onClick={() => setShowStart(!showStart)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 115 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        휴일 시작일*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            start
                                                ? `${start.getFullYear()}-${
                                                    start.getMonth() + 1 < 10
                                                        ? '0' +
                                                        String(
                                                            start.getMonth() +
                                                            1,
                                                        )
                                                        : start.getMonth() + 1
                                                }-${
                                                    start.getDate() < 10
                                                        ? '0' +
                                                        String(
                                                            start.getDate(),
                                                        )
                                                        : start.getDate()
                                                }`
                                                : '휴일 시작일을 선택해주세요.'
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}
                                        ${
                                            start
                                                ? styles.black
                                                : styles.grayA2
                                        }`}
                                        style={{ minWidth: 20 }}
                                        placeholder={''}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showStart
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                    .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                    .default
                                        }
                                        alt={'날짜 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showStart && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                                        <Calendar
                                            calendarType={'US'}
                                            value={start}
                                            minDetail={'month'}
                                            maxDate={end}
                                            nextLabel={
                                                <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                .default
                                                        }
                                                        alt={'다음'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                            }
                                            next2Label={null}
                                            prevLabel={
                                                <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                .default
                                                        }
                                                        alt={'이전'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                            }
                                            prev2Label={null}
                                            navigationLabel={({ label }) => (
                                                <p
                                                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>
                                                    {label}
                                                </p>
                                            )}
                                            tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                                            onChange={handleStart}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showEnd ? 0 : 10,
                                    borderBottomRightRadius: showEnd ? 0 : 10,
                                }}
                                onClick={() => setShowEnd(!showEnd)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 115 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        휴일 종료일*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            end
                                                ? `${end.getFullYear()}-${
                                                    end.getMonth() + 1 < 10
                                                        ? '0' +
                                                        String(
                                                            end.getMonth() +
                                                            1,
                                                        )
                                                        : end.getMonth() + 1
                                                }-${
                                                    end.getDate() < 10
                                                        ? '0' +
                                                        String(
                                                            end.getDate(),
                                                        )
                                                        : end.getDate()
                                                }`
                                                : '휴일 종료일을 선택해주세요.'
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}
                                        ${
                                            start
                                                ? styles.black
                                                : styles.grayA2
                                        }`}
                                        style={{ minWidth: 20 }}
                                        placeholder={''}
                                        readOnly={true}
                                    />
                                    <img
                                        src={
                                            showEnd
                                                ? require('../../../assets/images/icon_dropdown_up.png')
                                                    .default
                                                : require('../../../assets/images/icon_dropdown_down.png')
                                                    .default
                                        }
                                        alt={'날짜 선택'}
                                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                    />
                                </div>
                            </div>
                            {showEnd && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                                        <Calendar
                                            calendarType={'US'}
                                            value={end}
                                            minDetail={'month'}
                                            minDate={start}
                                            nextLabel={
                                                <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                .default
                                                        }
                                                        alt={'다음'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                            }
                                            next2Label={null}
                                            prevLabel={
                                                <span>
                                                    <img
                                                        src={
                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                .default
                                                        }
                                                        alt={'이전'}
                                                        className={`${styles.icon15}`}
                                                    />
                                                </span>
                                            }
                                            prev2Label={null}
                                            navigationLabel={({ label }) => (
                                                <p
                                                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>
                                                    {label}
                                                </p>
                                            )}
                                            tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                                            onChange={handleEnd}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:250}}>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    저장
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={deleteHoliday}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    삭제
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default HolidayEdit;
