import React from 'react';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const MembershipOrdering = ({ product }) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex2} ${styles.center}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius5} ${styles.btnShadow} ${styles.bgImgContain}`}
                    style={{
                        backgroundImage: `url(${product.image})`,
                        width: 120,
                        height: 120,
                    }}></div>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.name}
                </p>
            </div>
            <div className={`${styles.flex3}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.description}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.category.name
                        ? product.category.name
                        : product.lockerArea?.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                {product.isDiscount && product.discountPrice > 0 ? (
                    <div>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            style={{ textDecorationLine: 'line-through' }}>
                            {numberWithCommas(product.price)}원
                        </p>
                        <p
                            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>
                            {numberWithCommas(
                                product.price - product.discountPrice,
                            )}
                            원
                        </p>
                    </div>
                ) : (
                    <p
                        className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {numberWithCommas(product.price)}원
                    </p>
                )}
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.ignoreStock
                        ? '무제한'
                        : numberWithCommas(product.stock)}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {product.isDiscount && product.discountPrice > 0
                        ? `${numberWithCommas(product.discountPrice)}원 할인`
                        : '미적용'}
                </p>
            </div>
        </div>
    );
};

export default MembershipOrdering;
