import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import Device from '../../../components/Device/PC/Device';

const DEVICES = gql`
    query getDevices($order: String!, $skip: Int!, $q: String) {
        getDevices(order: $order, skip: $skip, q: $q) {
            machines {
                id
                username
                user {
                    id
                    username
                    name
                }
                name
                createdAt
                status
                productTotal
                advertiseTotal
                sido
                sigungu
                bname
                usePgBill
            }
            total
        }
    }
`;

const DeviceList = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput(
        queryString.parse(location.search).q
            ? queryString.parse(location.search).q
            : '',
    );

    const { data, loading, refetch } = useQuery(DEVICES, {
        fetchPolicy: 'cache-and-network',
        variables: {
            skip: 0,
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'date_dsc',
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/device/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&q=${q.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/device/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc'
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const applyOrder = order => {
        history.replace({
            pathname: `/device/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${order}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const handleOrder = order => {
        if (order === 'date') {
            if (queryString.parse(location.search).order === 'date_asc') {
                applyOrder('date_dsc');
            } else if (
                queryString.parse(location.search).order === 'date_dsc'
            ) {
                applyOrder('date_asc');
            } else {
                applyOrder('date_dsc');
            }
        } else if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'status') {
            if (queryString.parse(location.search).order === 'status_asc') {
                applyOrder('status_dsc');
            } else if (
                queryString.parse(location.search).order === 'status_dsc'
            ) {
                applyOrder('status_asc');
            } else {
                applyOrder('status_asc');
            }
        } else if (order === 'user') {
            if (queryString.parse(location.search).order === 'user_asc') {
                applyOrder('user_dsc');
            } else if (
                queryString.parse(location.search).order === 'user_dsc'
            ) {
                applyOrder('user_asc');
            } else {
                applyOrder('user_asc');
            }
        } else {
            applyOrder('date_dsc');
        }
    };

    useEffect(() => {
        if (
            queryString.parse(location.search).page &&
            !isNaN(Number(queryString.parse(location.search).page))
        ) {
            window.scrollTo(0, 0);
            refetch({
                skip:
                    10 * (Number(queryString.parse(location.search).page) - 1),
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'date_dsc',
                q: queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : '',
            });
            q.setValue(queryString.parse(location.search).q);
        } else {
            history.replace({
                pathname: `/device/?page=1&order=${
                    queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'date_dsc'
                }&q=${
                    queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                }`,
                state: location.state,
            });
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                기기 관리
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <form action={''} onSubmit={submit}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}
                                        style={{ width: 480 }}>
                                        <img
                                            src={
                                                require('../../../assets/images/icon_search.png')
                                                    .default
                                            }
                                            alt={'search'}
                                            className={`${styles.icon25} ${styles.cursorPointer}`}
                                            onClick={submit}
                                        />
                                        <input
                                            type={'text'}
                                            name={'q'}
                                            value={q.value}
                                            onChange={q.onChange}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            placeholder={
                                                '기기명 및 사용자명 검색'
                                            }
                                        />
                                    </div>
                                </form>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`}
                                    onClick={history.goBack}>
                                    <img
                                        src={
                                            require('../../../assets/images/icon_back.png')
                                                .default
                                        }
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('date')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록일자
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'date_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'date_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('name')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('status')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기 상태
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'status_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'status_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('user')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    사용자명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'user_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'user_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 상품수
                                </p>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    송출중인 광고수
                                </p>
                            </div>
                            <div
                                className={`${styles.flex3} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    관리
                                </p>
                            </div>
                        </div>
                        <div style={{ minHeight: 600 }}>
                            {loading ? (
                                <Skeleton height={50} count={3} />
                            ) : data &&
                              data.getDevices &&
                              data.getDevices.machines.length > 0 ? (
                                data.getDevices.machines.map(device => {
                                    return (
                                        <Device
                                            key={device.id}
                                            device={device}
                                            location={location}
                                        />
                                    );
                                })
                            ) : (
                                <div
                                    className={`${styles.center}`}
                                    style={{ height: 600 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        기기가 없습니다.
                                    </p>
                                </div>
                            )}
                            {data?.getDevices?.total > 10 ? (
                                <div
                                    className={`${styles.mt60} ${styles.mb10}`}
                                    style={{ position: 'relative' }}>
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_back.png')
                                                        .default
                                                }
                                                alt={'이전'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_front.png')
                                                        .default
                                                }
                                                alt={'다음'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        breakLabel={
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                &#183;&#183;&#183;
                                            </p>
                                        }
                                        breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                        pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                        activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                        previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                        nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                        disabledClassName={`${styles.icon30} ${styles.center}`}
                                        pageCount={
                                            data?.getDevices?.total
                                                ? parseInt(
                                                      (data?.getDevices?.total -
                                                          1) /
                                                          10,
                                                  ) + 1
                                                : 1
                                        }
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={4}
                                        onPageChange={
                                            loading ? null : handlePage
                                        }
                                        initialPage={
                                            queryString.parse(location.search)
                                                .page
                                                ? Number(
                                                      queryString.parse(
                                                          location.search,
                                                      ).page,
                                                  ) - 1
                                                : 0
                                        }
                                        containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                    />
                                    {loading && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.4)',
                                            }}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceList;
