import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import NumberFormat from 'react-number-format';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { FETCH_URL } from '../../../config';

const CREATE = gql`
    mutation createTerms(
        $machine: String!
        $name: String!
        $isDefault: Boolean!
        $file: String!
    ) {
        createTerms(
            machine: $machine
            name: $name
            isDefault: $isDefault
            file: $file
        ) {
            terms {
                id
                name
                default
                contractTotal
                createdAt
            }
            error
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const ContractTermAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const name = useInput('');

    const [isDefault, setIsDefault] = useState(true);
    const [showDefault, setShowDefault] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [loadingFile, setLoadingFile] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [createTermsM] = useMutation(CREATE);

    const handleIsDefault = isDefault => {
        setIsDefault(isDefault);
        setShowDefault(false);
    };

    const addFile = async e => {
        if (!loadingFile) {
            const files = e.target.files;

            let error = false;
            if (files[0].type === 'application/pdf') {
            } else {
                error = true;
            }
            if (error) {
                alert('pdf 파일만 사용하실 수 있습니다.');
            } else {
                setLoadingFile(true);
                let file = files[0];
                setFileName(file.name);
                setFile(file);
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (name.value && file) {
                setLoading(true);
                try {
                    const formData = new FormData();
                    formData.append('term', file, file.name);
                    const { data } = await axios.post(
                        `${FETCH_URL}/api/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        },
                    );
                    if (data && data.location) {
                        const { data: result } = await createTermsM({
                            variables: {
                                machine: location.state.machine
                                    ? location.state.machine
                                    : '',
                                name: name.value,
                                isDefault,
                                file: data.location,
                            },
                        });
                        setLoading(false);
                        if (result && result.createTerms) {
                            if (result.createTerms.error) {
                                alert(result.createTerms.error);
                            } else {
                                alert('양식을 추가하였습니다.');
                                initialize();
                            }
                        } else {
                            alert('오류가 발생하였습니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    const initialize = () => {
        name.setValue('');
        setIsDefault(true);
        setShowDefault(false);
        setFile(null);
        setFileName('');
        setLoadingFile(false);
        setLoading(false);
    };

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`계약 양식 추가${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p
                                className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div
                                className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                style={{ width: 110 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    양식명*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'name'}
                                value={name.value}
                                onChange={name.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                                placeholder={'양식명을 입력해주세요.'}
                            />
                        </div>
                        <div
                            className={`${styles.mt10}`}
                            style={{ position: 'relative' }}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                style={{
                                    borderBottomLeftRadius: showDefault
                                        ? 0
                                        : 10,
                                    borderBottomRightRadius: showDefault
                                        ? 0
                                        : 10,
                                }}
                                onClick={() => setShowDefault(!showDefault)}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        기본 사용*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                                    <input
                                        type={'text'}
                                        value={
                                            isDefault
                                                ? '기본으로 사용'
                                                : '기본으로 사용하지 않음'
                                        }
                                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                                        style={{ minWidth: 20 }}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            {showDefault && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                    style={{ left: 0, right: 0 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.pl15}`}
                                            style={{ width: 110 }}></div>
                                        <div className={`${styles.py10}`}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleIsDefault(true)
                                                }>
                                                <p
                                                    className={`${styles.font14} ${styles.black}`}>
                                                    기본으로 사용
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleIsDefault(false)
                                                }>
                                                <p
                                                    className={`${styles.font14} ${styles.black}`}>
                                                    기본으로 사용하지 않음
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <label htmlFor={'file'}>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                                <div
                                    className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                                    style={{ width: 110 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                        계약 양식*
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15}`}>
                                    <p
                                        className={`${styles.font14} ${
                                            fileName
                                                ? styles.black
                                                : styles.grayA2
                                        }`}>
                                        {fileName
                                            ? fileName
                                            : '계약 양식을 업로드해주세요.(pdf만 사용가능)'}
                                    </p>
                                </div>
                            </div>
                        </label>
                        <input
                            id={'file'}
                            className={`${styles.none}`}
                            type={'file'}
                            accept={'.pdf'}
                            onChange={addFile}
                        />
                        <div className={`${styles.mt45}`}>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    완료
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContractTermAdd;
