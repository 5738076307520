import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import ReservationLimitCntItem from "../../../components/Schedule/Mobile/ReservationLimitCntItem";
import ReservationLimitDateItem from "../../../components/Schedule/Mobile/ReservationLimitDateItem";
import { removeNonNumeric } from "../../../common/utility";

const CATEGORY = gql`
  query getCategoryAdmin($machine: String!) {
    getCategoryAdmin(type: "schedule", machine: $machine) {
      id
      name
    }
  }
`;

const LIMIT = gql`
  query getScheduleLimit($machine: String!) {
    getScheduleLimit(machine: $machine) {
      id
      name
      type
      limit
      exceptPrev
      dateLimit
      isSetDateLimit
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const ADD = gql`
  mutation addScheduleLimit($machine: String!, $scheduleLimit: [ScheduleLimit]!) {
    addScheduleLimit(machine: $machine, scheduleLimit: $scheduleLimit) {
      success
      error
    }
  }
`;

const ReservationLimit = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [limitList, setLimitList] = useState([]);
  const [limitCategories, setLimitCategories] = useState([]);

  const [addLimitM] = useMutation(ADD);

  const { data: categories, refetch: refetchCategory } = useQuery(CATEGORY, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
    fetchPolicy: "network-only",
    //nextFetchPolicy: "cache-first",
  });

  const { data } = useQuery(LIMIT, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
    fetchPolicy: "network-only",
    //nextFetchPolicy: "cache-first",
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "network-only",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handleNewLimit = (type) => {
    let index = limitList.findIndex((v) => v.id === -1);
    if (index > -1) {
      alert("스케쥴을 선택해주세요");
    } else {
      setLimitList([
        ...limitList,
        {
          id: -1,
          name: "",
          type: type,
          isSetDateLimit: type === "date" ? true : false,
        },
      ]);
    }
  };

  const submit = async (e) => {
    e.preventDefault();

    if (!loading) {
      let isDateLimitAll = false;
      let isCntLimitAll = false;
      for (let i = 0; i < limitList.length; i++) {
        if (limitList[i].isSetDateLimit === false && limitList[i].name === "전체") {
          isDateLimitAll = true;
        }
      }
      for (let i = 0; i < limitList.length; i++) {
        if (limitList[i].isSetDateLimit === false && limitList[i].name !== "전체" && isDateLimitAll === true) {
          alert("횟수제한 전체조건 설정시 개별조건 설정은 불가합니다.");
          return false;
        }
      }

      for (let i = 0; i < limitList.length; i++) {
        if (limitList[i].isSetDateLimit === true && limitList[i].name === "전체") {
          isCntLimitAll = true;
        }
      }
      for (let i = 0; i < limitList.length; i++) {
        if (limitList[i].isSetDateLimit === true && limitList[i].name !== "전체" && isCntLimitAll === true) {
          alert("기간제한 전체조건 설정시 개별조건 설정은 불가합니다.");
          return false;
        }
      }

      //기본횟수 0으로 둘때 제한(0으로 저장하면 리스트에 뜨지 않기 때문에 애당초 제한)
      //기간제한은 제한두지않고 +1해서 저장
      for (let i = 0; i < limitList.length; i++) {
        if (limitList[i].isSetDateLimit == false && limitList[i].limit === 0) {
          alert(limitList[i].name + " 스케쥴의 횟수제한을 0회로 설정하는것은 불가합니다.");
          return false;
        } else if (limitList[i].isSetDateLimit == true) {
          limitList[i].dateLimit += 1;
        }
      }
      setLoading(true);
      try {
        const { data: result } = await addLimitM({
          variables: {
            machine: location.state.machine,
            scheduleLimit: removeTypename(limitList),
          },
        });
        if (result.addScheduleLimit && result.addScheduleLimit.success) {
          alert("저장되었습니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      } catch {
        alert("오류가 발생하였습니다.");
      } finally {
        setLoading(false);
      }
    }
  };

  const remove = (categoryId) => {
    if (categoryId === -1 || (categoryId >= 0 && window.confirm("조건을 삭제하시겠습니까?"))) {
      let index = limitList.findIndex((v) => v.id === categoryId);
      if (index !== -1) {
        limitList.splice(index, 1);
        setLimitList([...limitList]);

        let dupLimitCategory = JSON.parse(JSON.stringify(limitCategories));
        let idx = dupLimitCategory.findIndex((v) => v.id === categoryId);
        if (idx > -1) {
          dupLimitCategory[idx].selected = false;
        }

        setLimitCategories([...dupLimitCategory]);
      }
    }
  };

  const removeTypename = (value) => {
    if (value === null || value === undefined) {
      return value;
    } else if (Array.isArray(value)) {
      return value.map((v) => removeTypename(v));
    } else if (typeof value === "object") {
      const newObj = {};
      Object.entries(value).forEach(([key, v]) => {
        console.log("key ::::: ", key);
        if (key !== "__typename") {
          newObj[key] = removeTypename(v);
        }
      });
      return newObj;
    } else {
      return value;
    }
  };

  const syncCategory = async (category, index) => {
    if (category && index > -1) {
      let dupLimitList = JSON.parse(JSON.stringify(limitList));

      dupLimitList[index].id = category.id;
      dupLimitList[index].name = category.name;
      dupLimitList[index].limit = category.limit ? parseInt(category.limit) : 0;
      dupLimitList[index].exceptPrev = category.exceptPrev ?? false;
      dupLimitList[index].dateLimit = category.dateLimit ? parseInt(category.dateLimit - 1) : 0;
      dupLimitList[index].isSetDateLimit = category.isSetDateLimit ?? false;

      setLimitList(dupLimitList);

      console.log("category :::::: ", category);

      let dupLimitCategories = await JSON.parse(JSON.stringify(categories.getCategoryAdmin.map((item) => Object.assign({}, item, { cntselected: false, dateselected: false }))));

      console.log("dupLimitCategories ::::: ", dupLimitCategories);

      for (const limit of limitList) {
        let idx = dupLimitCategories.findIndex((v) => v.id === limit.id);
        if (idx >= 0) {
          if (limit.isSetDateLimit == false) {
            dupLimitCategories[idx]["cntselected"] = idx !== -1;
          } else if (limit.isSetDateLimit == true) {
            dupLimitCategories[idx]["dateselected"] = idx !== -1;
          }
        }
      }

      setLimitCategories(dupLimitCategories);
    }
  };

  const initialize = () => {};

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (data) {
      setLimitList(data.getScheduleLimit);
    }
  }, [data]);

  useEffect(() => {
    if (categories) {
      setLimitCategories(categories.getCategoryAdmin);
      let dupLimitCategories = categories.getCategoryAdmin.map((item) => Object.assign({}, item, { selected: false }));
      setLimitCategories([...dupLimitCategories]);
    }
  }, [categories]);

  // useEffect(() => {
  //     limitList.forEach(limit => {
  //         let index = limitCategories.findIndex(v => v.id === limit.id);
  //         if(index !== -1) {
  //             limitCategories.splice(index, 1);
  //             setLimitCategories(
  //                 [...limitCategories]
  //             );
  //         }
  //     });
  // }, [limitCategories, limitList]);

  function checkAllCategory(element) {
    if (element.name === "전체") {
      if (element.isSetDateLimit === false) {
        return false;
      } else {
        return true;
      }
    }
  }
  function checkAllSelected(element) {
    if (!element.selected) {
      return true;
    }
  }

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile} ${styles.minHeightFull}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`예약 내역 관리 ${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          </div>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
            <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.textLeft}`} style={{ width: "100%" }}>
              예약 제한 조건
            </p>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.pt15} ${styles.mt20}`} style={{ width: "100%" }}>
              <div className={`${styles.mx0} ${styles.px20} ${styles.pb15} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.borderBottomGrayA2}`}>
                <p className={`${styles.fontM} ${styles.font15} ${styles.black}`}>조건 추가</p>
                {limitCategories && (limitCategories.some(checkAllSelected) || limitList.length === 0) ? (
                  <div className={`${styles.justifyContentBetween} ${styles.row} ${styles.px5} ${styles.pt5}`}>
                    <p
                      className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.py4}`}
                      onClick={() => {
                        handleNewLimit("count");
                      }}
                    >
                      + 횟수 제한 조건 추가
                    </p>
                    <p
                      className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.py4}`}
                      onClick={() => {
                        handleNewLimit("date");
                      }}
                    >
                      + 기간 제한 조건 추가
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {limitList && limitList.length > 0 ? (
                <div>
                  {limitList.map((limit, index) => {
                    if (limit.isSetDateLimit === true) {
                      return <ReservationLimitDateItem key={limit.id} index={index} count={limitList.length} reservationLimitDate={limit} categories={limitCategories} syncCategory={syncCategory} removeItem={() => remove(limit.id)} />;
                    } else {
                      return <ReservationLimitCntItem key={limit.id} index={index} count={limitList.length} reservationLimitCnt={limit} categories={limitCategories} syncCategory={syncCategory} removeItem={() => remove(limit.id)} />;
                    }
                  })}
                </div>
              ) : (
                <p className={`${styles.fontB} ${styles.font16} ${styles.textCenter} ${styles.black} ${styles.my10}`}>제한 없이 예약 가능</p>
              )}
            </div>

            <div className={`${styles.mt65}`} style={{ width: "100%" }}>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
              </div>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>완료</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReservationLimit;
