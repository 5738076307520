import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Calendar from "react-calendar";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const DATA = gql`
  query getTotalSaleStatics($date: String!) {
    getTotalSaleStatics(date: $date) {
      total
      site
      order
      membership
      custom
      cash
      locker
    }
  }
`;

const TotalSaleStatics = () => {
  const location = useLocation();

  const timestamp = useRef(new Date().getTime());

  const [date, setDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState(null);

  const { refetch } = useQuery(DATA, {
    skip: true,
  });

  const getData = async () => {
    setData(null);
    const now = new Date().getTime();
    timestamp.current = now;
    const { data } = await refetch({
      date: `${String(date.getFullYear()).slice(2, 4)}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`,
    });
    if (timestamp.current === now) {
      if (data) {
        setData(data.getTotalSaleStatics);
      } else {
        setData(null);
      }
    }
  };

  useEffect(() => {
    setShowDate(false);
    getData();
  }, [date]);

  useEffect(() => {
    setShowDate(false);
  }, [queryString.parse(location.search).page]);

  return (
    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.ml10} ${styles.flex4}`}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
        <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>전체 기기 통합 매출</p>
        <div className={`${styles.ml50}`}>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowDate(!showDate)}>
            <p className={`${styles.fontB} ${styles.font18} ${styles.black}`}>{`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`}</p>
            <img src={showDate ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
          </div>
        </div>
      </div>
      {showDate && (
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentEnd}`}>
          <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.px20} ${styles.py20} ${styles.overflowHidden}`} style={{ width: 400 }}>
            <Calendar
              calendarType={"US"}
              value={date}
              maxDate={new Date()}
              minDate={new Date(2021, 0, 1)}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={setDate}
            />
          </div>
        </div>
      )}
      <div className={`${styles.mt50}`}>
        {data ? (
          <div>
            <div
              className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py10}`}
              style={{
                borderTop: "1px solid #a2a2a2",
                borderBottom: "1px solid #a2a2a2",
              }}
            >
              <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>통합 매출</p>
              <p className={`${styles.fontM} ${styles.font24} ${styles.black}`}>{numberWithCommas(data.total)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5} ${styles.mt30}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>현장 판매 상품 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.site)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>주문 판매 상품 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.order)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>회원권 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.membership)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.locker)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>자유 결제 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.custom)}</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px40} ${styles.py5}`}>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>현금 결제 매출</p>
              <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>{numberWithCommas(data.cash)}</p>
            </div>
          </div>
        ) : (
          <div>
            <Skeleton height={80} />
            <Skeleton height={80} style={{ marginTop: 60 }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalSaleStatics;
