import React from "react";

import styles from "../../../styles/styles.module.scss";

const Term = () => {
    return (
        <div>
            <div>
                <p className={`${styles.fontM} ${styles.font24} ${styles.black} ${styles.textCenter}`}>
                    이용약관
                </p>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 1조 (목적)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        본 ‘워킹카우’ 서비스 이용 약관 (이하 “본 약관”이라 합니다.)은 이용자가 ㈜ 워킹카우에서 제공하는 워킹카우 사이트 및 사이트 관련 각종 서비스 (이하 “서비스”라 합니다.)를 이용함에 있어 워킹카우와 이용자의 권리 의무 및 책임 사항을 규정함을 목적으로 합니다
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 2조 (정의)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        본 약관의 주요 용어는 아래와 같이 정의합니다<br/><br/>
                        ① "사이트”는 ㈜워킹카우의 사이트를 말합니다.<br/>
                        ② “서비스”란 워킹카우 사이트 및 사이트 관련 각종 서비스를 말합니다<br/>
                        ③ “이용자”란 사이트에 접속하여 이 약관에 따라 워킹카우가 제공하는 서비스를 받는 “회원”과 “비회원”을 말합니다<br/>
                        ④ “회원”은 “워킹카우”에 개인 정보를 제공하여 회원 등록을 한 자로서 회원 전용 “서비스”를 이용할 수 있는 자를 말합니다.<br/>
                        ⑤ “비회원”이라 함은 회원에 가입하지 않고 “서비스”를 이용하는 자를 말합니다<br/><br/>
                        이 약관에서 정하지 아니한 내용과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률 약관의 규제 등에 관한 법률 공정거래위원 회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 3조 (약관의 게시와 변경)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 이 약관의 내용과 상호 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호, 이메일 주소, 사업자등록번호, 통신판매업신고번호, 개인정보보호책임자 등을 이용자가 쉽게 알 수 있도록 “사이트”내에 게시합니다. 다만 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/><br/>
                        2. “워킹카우”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래 기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/><br/>
                        3. “워킹카우”는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 홈페이지의 초기화면에 그 적용일 7일 이전부터 적용일자 전일까지 공지합니다. 다만 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “워킹카우”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.<br/><br/>
                        4. “워킹카우”가 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 따로 공지 또는 고지하였음에도 회원이 명시적으로 거부의사를 표시하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다. 또한 회원이 개정약관의 적용에 동의하지 않는 경우 “워킹카우”는 개정약관의 내용을 적용할 수 없으며 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만 기존약관을 적용할 수 없는 특별한 사정이 있는 경우에는 “워킹카우”는 이용계약을 해지할 수 있습니다.<br/><br/>
                        5. 이 약관에서 정하지 아니한 내용과 이 약관의 해석에 관하여 전자상거래 등에서의 소비자보호에 관한 법률 약관의 규제 등에 관한 법률 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 4조 (서비스의 제공 및 변경)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 이용자에게 아래와 같은 서비스를 제공합니다.<br/>
                        ① 광고, 이벤트 행사<br/>
                        ② 다양한 정보의 제공<br/>
                        ③ 광고, 이벤트 행사 등 상품과 관련한 다양한 판촉 행위(뉴스레터, DM 포함)<br/>
                        ④ 기타 이용자에게 유용한 부가 서비스<br/><br/>
                        2. “워킹카우”는 경품이 품절 등으로 더 이상 제공할 수 없는 경우에는 제공할 경품의 내용을 변경할 수 있습니다. 이 경우에는 변경된 경품의 내용 및 제공일자를 명시하여 현재의 상품의 내용을 게시한 곳에 즉시 공지합니다.<br/><br/>
                        3. “워킹카우”는 이용자에게 제공하기로 명시한 서비스의 내용을 상품 품절 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능 한 주소로 즉시 통지합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 5조 (서비스의 중단)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 컴퓨터 등 전기통신설비의 보수점검 교체 및 고장 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/><br/>
                        2. 제1항에 의한 서비스 중단의 경우에는 “워킹카우”는 제8조에 정한 방법으로 이용자에게 통지합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 6조 회원 가입
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “이용자”는 “워킹카우”가 정한 가입 양식에 따라 회원 정보를 기입한 후 이 약관에 동의한다는 의사 표시를 함으로써 회원 가입을 신청합니다.<br/><br/>
                        2. “워킹카우”는 전항과 같이 회원으로 가입할 것을 신청한 자가 다음 각 호에 해당하지 않는 한 신청한 자를 회원으로 등록합니다.<br/>
                        ① 등록 내용에 허위 기재누락 오기가 있는 경우<br/>
                        ② 기타 회원으로 등록하는 것이 “워킹카우”의 기술상 현저히 지장이 있다고 판단되는 경우<br/><br/>
                        3. 아동(만14세 미만인 자)은 회원가입이 불가능 합니다.<br/><br/>
                        4. 회원 가입 계약의 성립시기는 “워킹카우”의 승낙이 가입 신청자에게 도달한 시점으로 합니다.<br/><br/>
                        5. “회원”은 “워킹카우”에 등록한 회원정보에 변경이 있는 경우 상당한 기간 내에 “사이트”에서 정하는 방법에 따라 해당변경사항을 “워킹카우”에게 통지하거나 수정하여야 합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 7조 (회원의 탈퇴 및 회원자격 상실)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “회원”은 “워킹카우”에 언제든지 자신의 회원 등록을 말소해 줄 것(회원 탈퇴)을 요청할 수 있으며 “워킹카우”는 위 요청을 받은 즉시 해당 이용자의 회원 등록 말소를 위한 절차를 밟습니다. 단, 절차가 진행되는 동안에는 기존 일정에 맞춰 예정된 서비스를 제공받을 수 있습니다.<br/><br/>
                        2. “워킹카우”는 회원자격의 정지 등의 방법을 통해 회원자격을 제한할 수 있으며 자격 상실의 경우에는 회원등록을 말소합니다. 말소의 경우 회원에게 이를 통지하고, 회원등록 말소 전에 30 일 이상의 기간을 정하여 소명할 기회를 부여합니다. 아래와 같은 각각의 경우 회원자격의 말소 대상이 되며 이에 해당하지 않더라도 “워킹카우”의 경영상 판단에 의해 사업의 운영이나 다른 회원의 구매행위에 장애를 발생시키는 회원에 대해서는 동일하게 30일 이상의 기간을 정하여 소명 기회를 부여한 다음 말소가 가능합니다.<br/>
                        ① 가입 신청 시에 허위 내용을 등록한 경우<br/>
                        ② 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자 거래 질서를 위협하는 경우<br/>
                        ③ 서비스를 이용하여 법령과 본 약관이 금지하거나 공서 양속에 반하는 행위를 하는 경우<br/>
                        ④ 회원자격의 제한 또는 정지의 제재를 3회 이상 받은 회원<br/>
                        ⑤ 회원 자격의 제한 또는 정지를 받고 그 제한이 해제된 다음 30일 이내에 다시 제한을 받은 경우<br/>
                        ⑥ 거래 제한과 별개로 해당 행위에 대해 시정을 요구하였으나 14 일 이내에 그 사유가 시정되지 아니하는 경우<br/>
                        ⑦ 회원의 소재불명 등으로 인해 소명 기회의 제공이 어려운 경우는 “워킹카우”는 합리적인 판단으로 회원등록을 말소 가능<br/>
                        ⑧ 이벤트 응모를 악의적으로 반복 참여하거나 개인정보를 변경하여 중복 참여하는 등 “워킹카우”의 영업에 지장을 초래하는 경우<br/><br/>
                        3. “워킹카우”는 전자상거래 등에서의 소비자보호에 관한 법률 시행령 제6조에 의거 회원등록이 말소된 회원이라 하더라도 계약 또는 청약 철회에 관한 기록은 5년 대금 결제 및 재화 등의 공급에 관한 기록은 5년 그리고 소비자의 불만 또는 분쟁처리에 관한 기록은 3년간 보존하게 됩니다. 그러나 이러한 기록의 보존이 회원 자격의 유지 및 혜택 제공이 지속되는 것을 의미하지는 않습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 8조 (이용자에 대한 통지)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 특정 이용자 또는 “회원”에 대한 통지를 하는 경우 회원이 “워킹카우”에 미리 약정하여 지정한 전자 우편 주소 또는 문자 메시지로 할 수 있습니다.<br/><br/>
                        2. “워킹카우”는 불특정 다수 회원에 대한 통지의 경우 1주일 이상 “서비스” 사이트 내에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 9조 (이벤트 신청)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        이용자는 “사이트”에서 다음 또는 이와 유사한 방법에 의하여 이벤트를 신청하며 “워킹카우”는 이용자가 이벤트 신청을 함에 있어 서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.<br/><br/>
                        1. 이벤트 항목 등의 검색 및 선택<br/>
                        2. 받는 사람의 성명 주소 전화번호 이메일 주소(또는 이동전화번호) 등의 입력<br/>
                        3. 약관내용, 청약철회권이 제한되는 서비스, 배송료 등의 비용부담과 관련한 내용에 대한 확인<br/>
                        4. 이 약관에 동의하고 위3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br/>
                        5. 이벤트 신청 및 이에 관한 확인 또는 “워킹카우”의 확인에 대한 동의
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 10조 (이벤트 신청의 성립 및 이벤트의 당첨)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 제9조 이벤트 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.<br/>
                        다만, 미성년자(만19세 미만의 자)가 이벤트를 신청하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 신청을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
                        ① 신청 내용에 허위 기재누락 오기가 있는 경우<br/>
                        ② 이벤트를 신청하였으나 워킹카우 키오스크 설치 시설 회원 여부가 확인되지 않을 경우<br/>
                        ③ 이벤트 일정이 지난 후 신청한 경우<br/>
                        ④ 기타 신청을 승낙하는 것이 “워킹카우” 기술 상 현저히 지장이 있다고 판단하는 경우<br/><br/>
                        2. “워킹카우”의 승낙이 제12조 제1항의 수신확인 통지형태로 이용자에게 도달한 시점에 이벤트 신청이 성립한 것으로 보며, 제1항 각 호에 해당하는 경우 별도 통보하지 않습니다.<br/><br/>
                        3. “워킹카우”는 제1항에 따라 이벤트 신청이 성립한 이용자 중 이벤트에 당첨된 이용자에게 이벤트 종료 후 4주 내에 명시한 혜택을 제공합니다. (당사의 재고 및 사정에 따라 일정이 변경될 수 있음) 이벤트 대상으로 확인되어 혜택을 제공하였으나 이용자의 부재 혹은 이용자의 정보 오입력으로 경품 등이 반송된 경우, 이벤트 당첨이 취소될 수 있습니다.<br/><br/>
                        4. 소득세법 및 지방세법에 따라 제세공과금을 부담하여야 하는 상품(가액 5만원을 초과하는 경품)에 당첨된 이용자는 당사가 제세공과금 납부 및 처리를 하기 위하여 당첨자의 주민등록번호 및 주소가 필요하며 신분증(주민등록증, 운전면허증, 여권 등)을 요청합니다. 당첨자가 해당 정보 제공을 거부하는 경우 부득이하게 당첨이 취소될 수 있습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 11조 (“워킹카우”의 의무)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 법령과 본 약관이 금지하거나 공서 양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고 안정적으로 “서비스”를 제공하기 위해서 최선을 다하여야 합니다.<br/><br/>
                        2. “워킹카우”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 아래와 같은 보안 시스템을 갖추어야 합니다.<br/>
                        ① 암호 알고리즘 등의 이용을 통하여 개인 정보를 안전하게 네트워크 상에서 전송할 수 있는 보안 조치<br/>
                        ② 침입 차단 시스템 등 접근 통제 장치의 설치 운영<br/>
                        ③ 기타 안정성 확보를 위하여 필요한 기술적 조치<br/><br/>
                        3. “워킹카우”는 이용자가 서비스를 이용함에 있어 “워킹카우”의 고의 또는 중대한 과실로 인하여 입은 손해를 배상할 책임을 부담합니다. 단, 기술적인 보완 조치 및 필요한 조치를 했음에도 불구하고 발생한 해킹 등 기본적인 네트워크 상의 위험성에 의해 발생하는 예기치 못한 사고나 천재지변으로 인한 개인 정보의 훼손 및 서비스의 이상 및 “이용자”가 작성한 게시물에 의한 분쟁에 관해서는 책임이 없습니다.<br/><br/>
                        4. “워킹카우”는 사전에 “워킹카우”의 정보 수신에 동의한 “이용자”에 한해 정보성, 광고성, 전자우편, 문자, 메시지, 우편물 및 각종정보를 보낼 수 있습니다.<br/><br/>
                        5. “워킹카우”은 “이용자”의 편의를 위해 아래 경로를 통해 “이용자”의 “서비스” 이용과 관련한 모든 불편 사항 및 문의 사항을 처리합니다.<br/><br/>
                        &#183;담당자 : 이승민<br/>
                        &#183;소속 : 워킹카우<br/>
                        &#183;이메일 : min@working-cow.com<br/>
                        &#183;서비스 전화 문의 : 02-6084-3500<br/>
                        &#183;월~금 오전 9시 - 오후 5시(주말 및 공휴일 휴무)<br/>
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 12조 (“이용자”의 ID 및 비밀번호에 대한 의무)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”가 관계법령 개인정보처리방침에 의해서 그 책임을 지는 경우를 제외하고 자신의 ID와 비밀번호에 관한 관리 책임은 각 “이용자”에게 있습니다.<br/><br/>
                        2. “이용자”는 자신의 ID 및 비밀번호를 제3자가 이용하게 해서는 안됩니다.<br/><br/>
                        3. “이용자”는 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “워킹카우”에 통보하고 “워킹카우”의 안내가 있는 경우에는 그에 따라야 합니다.<br/><br/>
                        4. “이용자”가 이용자의 의무 조항을 어길 경우 “워킹카우”는 해당 이용자의 계정 및 서비스 이용에 제한을 가할 수 있습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 13조 (이용자의 의무)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        “이용자”는 다음 각 호의 행위를 하여서는 안됩니다.<br/><br/>
                        ① 회원 가입을 포함한 각종 신청 또는 변경 시 허위 내용을 등록하는 행위<br/>
                        ② 타인의 정보 도용<br/>
                        ③ “워킹카우”가 게시한 정보의 변경<br/>
                        ④ “워킹카우”가 정한 정보 이외의 정보 (컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
                        ⑤ “워킹카우” 및 기타 제 3자의 저작권 등 지적 재산권에 대한 침해<br/>
                        ⑥ “워킹카우” 및 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
                        ⑦ 외설 또는 폭력적인 메시지 화상, 음성, 기타 공서 양속에 반하는 정보를 “서비스”에 공개 또는 게시하는 행위
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 14조 (“워킹카우”와 “피연결 서비스”간의 관계)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. 제3자가 제공하는 사이트나 컨텐츠가 “서비스”를 통해 안내되었거나 “서비스”와 Hyperlink 방식 등으로 연결된 경우, 이러한 사이트나 컨텐츠를 “피연결 서비스”라 합니다.<br/><br/>
                        2. “워킹카우”는 “이용자”가 “피연결 서비스”가 독자적으로 제공하는 사이트나 컨텐츠 및 각종 재화와 용역을 이용하는 데 있어, 그 이용과 그로 인해 발생하는 사항에 대한 책임을 지지 않습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 15조 (저작권의 귀속 및 이용제한)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “워킹카우”에 귀속합니다.<br/><br/>
                        2. “이용자”는 “워킹카우”를 이용함으로써 얻은 정보 중 “워킹카우”에 지적 재산권이 귀속된 정보를 “워킹카우”의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.<br/><br/>
                        3. “워킹카우”는 “이용자”가 올린 컨텐츠의 전체 또는 일부분을 변경 편집 포스트 거절 또는 삭제할 권한을 가지고 있습니다. 또한 “이용자” 창작 컨텐츠를 공개하거나 컨텐츠 전송과 관련된 사용환경을 제3자에게 제공할 수 있습니다.<br/><br/>
                        4. “워킹카우”는 “이용자”가 올린 컨텐츠에 포함된 댓글, 정보, 디자인, 아이디어 및 웹사이트에 접속하고 있는 동안 만든 모든 컨텐츠 “워킹카우”에 보낸 의견에 포함되어 있는 정보를 이용할 수 있습니다. 이를 바탕으로 제품 개발, 제조, 마케팅, 웹사이트, 서비스 또는 기타 제품이나 서비스의 창작, 변경, 개발 등 어떠한 목적으로든 사전의 공지, 보상이나 연락 없이 사용할 수 있습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 16조 (광고게재 등)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 “이용자”에게 최상의 서비스를 원활하게 제공하기 위한 재정기반을 마련하기 위하여 상업용 광고를 화면에 게재하거나 광고 메시지 수신에 동의한 이용자에게 이메일 또는 DM(서신) 등을 이용하여 광고 메시지를 보낼 수 있습니다. 단, 수신거절의 의사를 명백히 표시한 이용자에 대해서는 더 이상 이메일 또는 DM(서신) 등을 발송하지 않습니다.<br/><br/>
                        2. “워킹카우”는 본 서비스를 통한 광고주의 판촉활동에 회원이 직접 참여함으로써 발생하는 손해에 대하여는 아무런 책임을 부담하지 않습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 17조 (분쟁 해결)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”는 “이용자”가 “서비스”와 관련하여 제기하는 정당한 의견이나 불만을 반영하고 그 피해 및 보상 처리를 고객 상담 센터를 통해 진행합니다.<br/><br/>
                        2. “워킹카우”는 “서비스”와 관련하여 “이용자”로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 “이용자”에게 그 사유와 처리 일정을 즉시 통보해드립니다.<br/><br/>
                        3. “워킹카우”와 “이용자”간에 발생한 “서비스” 이용과 관련한 “이용자”의 피해 구제 신청이 있는 경우에는 공정 거래 위원회 또는 시, 도지사가 의뢰하는 분쟁 조정 기관의 조정에 따를 수 있습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 18조 (재판 관할)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        1. “워킹카우”와 “이용자”간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 “워킹카우”의 주소에 의하고 주소가 없는 경우에는 거소를 관할하는 지방 법원의 전속 관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사 소송법 상의 관할 법원에 제기합니다.<br/><br/>
                        2. “워킹카우”와 “이용자”간에 제기된 서비스 관련 소송에는 대한민국 법을 적용합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        제 19조 (긴급 조치)
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        회원이 위법하거나 부정한 목적을 위해 서비스를 이용한다고 “워킹카우”가 판단하는 때에는 이벤트 당첨 등의 임의 취소가 가능하고 관련 재화의 수취나 배송을 거절할 수 있으며 회원의 지정된 주소로 전자우편, 주소, 배송지, 주소, 문자 메시지, 통지 혹은 경고한 다음 회원자격을 제한하거나 영구 정지시킬 수 있습니다. “워킹카우”가 이 해당 긴급 조치를 취하는 경우 이는 이용자가 제공한 정보(회원정보, 배송지, 주소, 아이피 주소 등) 뿐만 아니라 “워킹카우”가 고유한 방법을 이용하여 필요하다고 판단한 경우 (이벤트 신청 패턴, 아이디 생성 패턴 등)에도 가능합니다.<br/><br/>
                        ① 동일인이 여러 개의 아이디를 생성하거나 이를 이용하여 특정 이벤트 반복 참여를 시도하는 경우<br/>
                        ② 회원이 제공한 개인정보나 가입신청 내역에 허위내용이 발견된 경우 : 예를 들어 가입한 주소나 배송지 주소가 동일한 여러 개의 다른 아이디가 생성된 경우<br/>
                        ③ 당첨이 확정된 후 합리적인 일정 기간 내에 추가 정보 제공이 이루어지지 않는 경우<br/>
                        ④ “워킹카우” 운영을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
                        ⑤ 회원이 위법하거나 부정한 목적으로 본 서비스를 사용하였다고 “워킹카우”가 객관적 자료에 의거 합리적으로 판단했을 경우<br/>
                        ⑥ 회원이 제출한 주소 또는 연락처의 변경통지를 하지 않는 등 회원의 귀책사유로 인해 회원이 소재불명 되어 “워킹카우” 회원에게 통지 연락을 할 수 없다고 판단되는 경우<br/>
                        ⑦ 오프라인 이벤트 당첨 후 이벤트 현장에 참여하지 않는 행위를 반복하는 경우
                    </p>
                </div>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt20}`}>
                    이 약관은 2021년 01월 01일부터 최초 시행되었습니다.
                </p>
            </div>
            <div className={`${styles.mt30}`}>
                <p className={`${styles.fontM} ${styles.font24} ${styles.black} ${styles.textCenter}`}>
                    개인정보처리방침
                </p>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                    ㈜워킹카우는 고객의 개인정보보호를 소중하게 생각하고 고객의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있습니다.<br/>
                    워킹카우는 「개인정보 보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」을 비롯한 모든 개인정보보호 관련 법률규정을 준수하고 있습니다.
                </p>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        1. 수집하는 개인정보의 항목 및 수집방법
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        개인정보 수집에 대한 동의<br/>
                        ㈜워킹카우는 개인정보 수집이용 동의에 대해 「동의합니다」를 클릭할 수 있는 절차를 마련하고 있으며, 「동의합니다」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. “㈜워킹카우“가 “회원” 이용자의 “서비스” 이용과 관련하여 수집하는 개인정보의 범위는 아래와 같습니다.<br/><br/>
                        &#183;성명, 이메일 주소, 휴대폰 번호, 성별, 주소, 생년월일, 본인확인값(CI) 서비스 이용 또는 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br/>
                        &#183;서비스이용기록, 접속로그, 쿠키, 접속IP정보, 결제기록, 이용정지기록<br/><br/>
                        개인 정보의 수집 방법은 아래와 같습니다.<br/><br/>
                        ① 홈페이지, 서면양식, 전화, 팩스를 통한 문의하기<br/>
                        ② 제휴사로부터의 제공<br/>
                        ③ 이벤트 응모, 서비스 이용 기록 수집 툴을 통한 수집<br/>
                        ④ 본인인증을 통한 회원정보 제공
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        2. 개인정보의 수집 및 이용목적
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 본조 제1항의 수집 정보를 다음과 같은 목적을 위하여 사용합니다.<br/><br/>
                        ① 서비스 이용에 따른 본인식별, 실명확인, 가입의사 확인, 연령제한 서비스 이용<br/>
                        ② 고지사항 전달, 불만처리 의사소통 경로 확보, 물품배송 시 정확한 배송지 정보 확보, 서비스 AS 제공을 위한 정보확인<br/>
                        ③ ㈜워킹카우가 주관하는 행사, 프로모션, 서베이의 응모, 접수, 등록을 포함한 전반적인 운영 관리 및 이와 관련된 경품 제품 소식지 및 각종 홍보물 등의 배송<br/>
                        ④ ㈜워킹카우가 제공하는 각종 소식 및 고지 사항 전달, 본인 의사 확인<br/>
                        ⑤ ㈜워킹카우의 마케팅 활동에 필요한 인구 통계학적 분석
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        3. 비회원 고객 개인정보수집
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 비회원 고객 또한 상품의 문의를 하실 수 있습니다. 비회원 고객이 상품 문의 및 이와 관련한 서비스 등을 위하여 필요할 경우에만 다음과 같이 최소한의 개인정보만을 수집 이용하고 있습니다. 입력하신 정보는 수집 시 안내해 드린 목적 외에는 다른 어떠한 용도로도 사용되지 않습니다.<br/><br/>
                        &#183;성명, 회사(상호)명, 주소, 전화번호, 이메일
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        4. 개인정보의 목적 외 사용 및 제3자에 대한 제공 및 공유
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 회원의 개인정보를 ‘개인정보의 수집항목 및 수집방법’, ‘개인정보의 이용’에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업/기관에 제공하지 않습니다.<br/>
                        다만, 다음은 예외로 합니다.<br/><br/>
                        1) 회원이 사전에 동의한 경우<br/>
                        ㈜워킹카우는 회원의 개인정보를 제3자에게 제공하기 이전에 다음의 모든 사항을 회원에게 알리고 동의를 받습니다.<br/><br/>
                        (1) 개인정보를 제공받는 자<br/>
                        (2) 개인정보를 제공받는 자의 개인정보 이용 목적<br/>
                        (3) 제공하는 개인정보의 항목<br/>
                        (4) 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간<br/><br/>
                        2) 관계법률의 규정에 의거하거나, 수사목적으로 관계법률에서 정한 절차와 방법에 따라 수사기관이 개인정보 제공을 요구하는 경우<br/><br/>
                        3) 영업의 양수 등<br/>
                        영업의 양수 등에 관한 사유가 발생하여 회원의 개인정보 이전이 필요한 경우, ㈜워킹카우는 정보통신망 이용 촉진 및 정 보보호에 관한 법률 등 관계법률에서 규정한 절차와 방법에 따라 개인정보 이전에 관한 사실 등을 사전에 고지하며, 회원에게는 개인정보 이전에 관한 동의 철회권을 부여합니다.<br/><br/>
                        수탁자 : Amazon Web Services, Inc<br/>
                        위탁 업무 내용 : 데이터 보관<br/>
                        제공하는 정보 : 서비스 제공 과정에서 수집한 모든 개인정보<br/>
                        * Amazon Web Service는 이용자 정보에 접근할 수 없으며, 서비스 제공을 위한 클라우드 컴퓨팅 환경만 제공
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        5. 개인정보 처리 업무의 위탁
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우 는 서비스 향상을 위해 고객의 개인정보를 외부에 위탁할 수 있으며, 관계법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 규정하고 있습니다.<br/>
                        현재, ㈜워킹카우의 개인정보처리 수탁자와 그 업무의 내용은 다음과 같습니다.<br/>
                        특정 이벤트 및 서비스 형태에 따라 (예시, 타 브랜드와 함께 이벤트 진행 후 해당 브랜드에서 경품 및 혜택을 제공할 경우) 전술된 개인정보처리 수탁자 외에 협업 브랜드에 회원의 개인정보, 연락처 등이 제공될 수 있습니다. 이 경우에는 제4조에서 정한 바와 같이 개인정보 제3자 제공에 관한 사항을 이용자에게 고지하고 동의를 받도록 하겠습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        6. 개인정보의 보유 및 이용기간
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우가 회원님의 개인정보를 수집하는 경우 그 보유기간은 회원가입 하신 후 해지 (탈퇴신청, 직권탈퇴, 포함)시까지입니다. 또한 해지 시 ㈜워킹카우는 회원님의 개인정보를 즉시 파기하며 개인정보가 제3자에게 제공된 경우에는 제3자 에게도 파기하도록 지시합니다.<br/>
                        또한 ㈜워킹카우가 고객에게 경품을 지급하기 위하여 수집한 정보는 해당 경품 또는 서비스를 인도한 후 6개월까지 보유 및 이용하고, 설문조사, 이벤트, 상품문의 등의 일시적인 목적을 위하여 수집한 정보는 해당 설문조사, 이벤트, 상품문의 등이 종료된 후 6개월까지 보유 및 이용합니다.<br/>
                        단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.<br/><br/>
                        ① 보존 항목 : 고객 정보, 서비스 이용기록, 접속 IP 정보<br/>
                        ② 보존 근거 : 고객님의 탈퇴기록 및 수사기관 협조 등에 이용하기 위하여 보존함<br/>
                        ③ 보존 기간 : 3개월<br/><br/>
                        관계법령의 규정에 의하여 보존할 필요가 있는 경우 ㈜워킹카우는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다.<br/><br/>
                        ① 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
                        ② 이용자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br/>
                        ③ 방문에 관한 기록 : 3개월 통신비밀보호법<br/>
                        ④ ㈜워킹카우는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제29조 및 시행령 제16조에 따라 휴면회원의 경우 다음과 같이 처리합니다.<br/>
                        - 미이용 기준 : 당사 서비스에 대한 로그인 일자기준<br/>
                        - 유효기간 : 1년<br/>
                        - 휴면회원 처리에 대한 안내 : 30일 이전 메일을 통한 안내<br/>
                        - 휴면회원에 대한 개인정보 처리 : 분리 보관조치<br/>
                        - 분리 보관 정보 : 회원정보 등<br/>
                        - 분리 보관 정보에 대한 보존 기간 : 5년후 비식별화 조치(개인을 특정할 수 있는 식별정보는 삭제)<br/>
                        - 계정활성화 : 고객님의 요청에 의거 계정활성화 조치 실시
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        7. 개인정보의 파기 절차 및 방법
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 또는 그로부터 개인 정보를 제공받은 제3자는 개인 정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 아래와 같은 방법과 절차로 당해 개인 정보를 지체 없이 파기 및 삭제합니다.<br/><br/>
                        회원가입정보의 경우 : 회원탈퇴 하거나 회원에서 제명된 때<br/><br/>
                        경품 지급 정보의 경우 : 물품 또는 서비스 인도 후 6개월이 된 때<br/><br/>
                        설문조사, 이벤트, 상품문의 등 일시적 목적을 위하여 수집한 경우: 설문조사, 이벤트, 상품문의 등이 종료된 후 6개월이 된 때<br/><br/>
                        ①파기절차<br/>
                        “이용자”가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 (종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인 정보는 법률에 의한 경우가 아니고서는 보유 되어지는 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>
                        ②파기방법<br/>
                        전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        8. 개인정보의 자동 수집 장치의 설치운영 및 그 거부에 관한 사항
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        상기 개인 정보 외에 “서비스”는 쿠키(Cookie)와 픽셀 태그(Pixel Tag)를 통해 접속자를 인식하며 아래의 목적으로 쿠키를 사용합니다. (쿠키는 웹 사이트 또는 웹 사이트의 서비스 제공자가 이용자의 컴퓨터내의 웹 브라우저로 전송하는 작은 텍스트 파 일을 말하며 이 파일들은 웹 사이트 또는 서비스 제공자의 시스템이 이용자의 웹 브라우저를 인식하고 특정 정보를 저장하고 기억하도록 합니다. 픽셀 태그는 “이용자”에게 전자우편을 보냈을 때 정형화된 포맷으로 전달하며 “이용자”가 언제 읽었는지 알려주는 작은 그래픽 이미지입니다.)<br/><br/>
                        ① “이용자”의 서비스 방문 시 이용한 컨텐츠를 기억하여 “이용자”에게 향상된 “서비스” 환경 제공<br/>
                        ② “서비스”의 방문 traffic의 분석 및 “서비스”의 이용 행태 파악<br/>
                        ③ 필요에 따라 제3자와의 계약을 통해 “서비스” 방문 traffic 분석에 활용<br/><br/>
                        “이용자”는 본인이 이용하는 컴퓨터의 웹 브라우저에서 쿠키 수집 시 경고 창이 뜨도록 하거나 쿠키 기능을 중지할 수 있습니다. 자세한 사항은 웹 브라우저의 도움말이나 Help 기능을 참조하십시오. 만약 쿠키를 중지시킬 경우 이 기능을 통해 이용 가능했던 “서비스” 내의 여러 컨텐츠나 기능을 이용할 수 없으며 “서비스”가 제대로 구동하지 않을 수 있습니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        9. 이용자 및 법정대리인의 권리와 그 행사방법
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        “이용자” 또는 만 14세 미만 아동의 법정대리인은 언제든지 ㈜워킹카우가 보유한 자신 혹은 당해 만 14세 미만 아동의 개인 정보에 대해 열람 및 오류 정정 및 동의 철회를 요구할 수 있으며 ㈜워킹카우는 이에 대해 필요한 조치를 취할 의무를 집니다.<br/>
                        ㈜워킹카우가 개인정보의 수집을 위해 “이용자”의 동의를 받아야 하는 경우에는 개인 정보 관리 책임자의 신원 (소속, 성명 및 전화번호, 기타 연락처), 정보의 수집 목적 및 이용 목적 제3자에 대한 정보 제공 관련 사항(제공 받은 자, 제공 목적 및 제공할 정보의 내용)등 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등의 요구하는 사항을 명시하거나 고지해야 하며 “이용자”는 언제든지 이 동의를 철회할 수 있습니다.<br/>
                        이와 관련한 각종 문의 및 요청은 서비스 담당자 (이승민 min@working-cow.com)에게 메일을 보내 변경 및 열람을 신청 하셔야 합니다.<br/>
                        회원 정보는 로그인 후 회원 정보 페이지 상에서 “이용자”가 직접 자신 혹은 당해 만 14세 미만 아동의 개인 정보를 변경 또는 열람할 수 있습니다.<br/>
                        “이용자”의 법정 대리인이 “이용자”의 개인 정보의 열람 및 변경을 원할 경우 서비스 담당자(이승민 min@working-cow.com)에게 메일로 문의하시고 서비스 담당자의 지시에 따라 법정 대리인임을 증명할 자료 및 증표를 제시 하셔야 합니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        10. 만14세 미만 아동의 개인정보보호
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        아동이 법정 연령 미만인 경우 본 사이트에 가입할 수 없습니다. ㈜워킹카우는 이벤트에 참여하는 아동의 경우 법정 대리인의 동의를 구하겠습니다.<br/>
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        11. 광고성 정보 전송의 제한
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 회원의 명시적인 수신거부 의사에 반하여 영리목적의 광고성 정보를 전송하지 않습니다.<br/>
                        회원이 뉴스레터 등 전자우편 전송에 대한 동의를 한 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이 회원이 쉽게 알아볼 수 있도록 조치합니다.<br/><br/>
                        ① 전자우편의 제목란: (뉴스레터) 혹은 (광고)라는 문구를 제목에 표시하고 전자우편 본문란의 주요 내용을 표시합니다.<br/>
                        ② 전자우편의 본문란: 수신거부의 의사표시를 할 수 있는 전송자의 명칭, 전자우편주소, 전화번호 및 주소를 명시하며 수신 거부의 의사를 쉽게 표시할 수 있는 방법 및 회원이 동의를 한 시기 및 내용을 명시합니다.<br/>
                        ③ 팩스 휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해 영리목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에 (광고)라는 문구를 표기하고 전송내용 중에 전송자의 연락처를 명시하도록 조치합니다.<br/>
                        ④ 회원은 영리목적의 광고성 정보를 전송 받은 경우 언제든지 이에 대해 수신거부의 의사표시를 할 수 있고 ㈜워킹카우는 즉각 전송중단의 조치를 취한 후 이를 회원에게 알립니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        12. 도용된 개인정보에 대한 조치
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        이용자가 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때에는 지체 없이 해당 아이디에 대한 서비스 이용정지 또는 회원탈퇴 등 필요한 조치를 취합니다.<br/>
                        자신의 개인정보 도용을 인지한 이용자가 해당 아이디에 대해 서비스 이용정지 또는 회원탈퇴를 요구하는 경우에는 즉시 조치를 취합니다.<br/>
                        ※ 이때 개인정보가 도용됨을 주장하는 이용자의 본인 확인방법으로는 전자정부에서 시행하는 주민등록증 진위확인 서비스를 이용합니다.<br/><br/>
                        기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터 대검찰청 인터넷범죄수사센터 경찰청 사이버테러대응 센터 등으로 문의하실 수 있습니다.<br/><br/>
                        ① 개인정보침해신고센터<br/>
                        - 전화 : 118<br/>
                        - URL : https://privacy.kisa.or.kr/<br/><br/>
                        ② 개인정보 분쟁조정위원회<br/>
                        - 전화 : 1833 - 6972<br/>
                        - URL : https://www.kopico.go.kr/<br/><br/>
                        ③ 대검찰청 사이버안전국<br/>
                        - 전화 : 국번없이 1301<br/>
                        - URL : http://www.spo.go.kr<br/><br/>
                        ④ 경찰청 사이버안전국<br/>
                        - 전화 : 국번없이 182<br/>
                        - URL : http://cyberbureau.police.go.kr/
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        13. 개인정보 보호를 위한 기술적 관리적 보호대책
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적관리적 보호대책을 강구하고 있습니다.<br/><br/>
                        ① 내부관리계획 수립∙시행<br/>
                        ㈜워킹카우는 개인정보의 분실, 도난, 유출, 위조, 변조 또는 훼손되지 아니하도록 내부 의사결정 절차를 통하여 내부 관리계획을 수립, 시행하고 있습니다.<br/><br/>
                        ②개인정보의 암호화<br/>
                        이용자의 비밀번호는 일방향 암호화하여 저장 및 관리되고 있으며, 개인정보의 확인 및 변경은 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 비밀번호는 이용자의 생일 전화번호 등 타인이 추측하기 쉬운 숫자 등을 이용하지 않도록 비밀번호 생성규칙을 수립하여 적용하고 있습니다. 개인정보는 안전한 암호 알고리즘으로 암호화되어 저장 및 관리되고 있습니다.<br/><br/>
                        ③해킹 등에 대비한 대책<br/>
                        ㈜워킹카우는 해킹 등 회사 정보통신망 침입에 의해 이용자의 개인정보가 유출되는 것을 방지하기 위해 개인정보는 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br/><br/>
                        ④개인정보 취급자의 최소화 및 교육<br/>
                        ㈜워킹카우는 회사의 개인정보 취급자를 최소한으로 제한하며 개인정보 취급자에 대한 교육 등 관리적 조치를 통해 개인 정보보호의 중요성을 인식시키고 있습니다.<br/><br/>
                        ⑤접근통제<br/>
                        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여 변경 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/><br/>
                        ⑥물리적 안전조치<br/>
                        ㈜ 워킹카우는 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 있으며 이에 대한 출입 통제 절차를 수립∙운영하고 있습니다.<br/><br/>
                        ㈜워킹카우는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. ㈜워킹카우는 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와 비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.<br/>
                        그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 변조, 훼손이 유발될 경우 ㈜워킹카우는 즉각 회원께 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        14. 워킹카우 개인정보 보호 책임자
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        ㈜워킹카우의 개인 정보는 다음의 담당자가 책임을 맡고 있습니다.<br/><br/>
                        담당자 성명 : 이승민<br/>
                        부서명 : 총괄팀<br/>
                        전화번호 : 02-6084-3500<br/>
                        E-Mail : min@working-cow.com
                    </p>
                </div>
                <div className={`${styles.mt20}`}>
                    <p className={`${styles.fontM} ${styles.font20} ${styles.black}`}>
                        15. 개인정보 처리방침 변경
                    </p>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt10}`}>
                        현 개인정보 처리방침은 2021년 1월 1일부터 적용됩니다. <br/>
                        내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전(중요한 사항이 변경되는 경우는 최소 30일전)부터 웹사이트의 공지사항을 통하여 고지할 것입니다.<br/>
                        만일, 개인정보의 수집 및 활용 제3 자 제공 등이 변경되어 동의가 필요한 경우에는 별도 동의 절차를 마련하여 진행할 것입니다.<br/>
                        또한 개인정보보호정책에 버전번호 및 개정일자 등을 부여하여 개정여부를 쉽게 알 수 있도록 하고 있습니다.
                    </p>
                </div>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt20}`}>
                    &#183;개인정보처리방침 버전번호 : v.1.0
                </p>
            </div>
        </div>
    )
}

export default Term;