import React from 'react';

import styles from '../../../styles/styles.module.scss';
import NumberFormat from "react-number-format";

const ScheduleTime = ({timeHour, timeMin, timeAmPm, handleTimeAmPm, handleTimeHour, handleTimeMin, infoText}) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20}`}>
            <div
                className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
                onClick={() => handleTimeAmPm('AM')}>
                {timeAmPm === 'AM' && (
                    <div
                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                )}
            </div>
            <span className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.cursorPointer} ${styles.mr5}`}
                  onClick={() => handleTimeAmPm('AM')}>오전</span>
            <div
                className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
                onClick={() => handleTimeAmPm('PM')}>
                {timeAmPm === 'PM' && (
                    <div
                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                )}
            </div>
            <span
                className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.cursorPointer}`}
                onClick={() => handleTimeAmPm('PM')}>오후</span>

            <NumberFormat
                value={timeHour.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.py10} ${styles.mr8} ${styles.textRight}`}
                style={{width: 40}}
                type={'tel'}
                displayType={'input'}
                onValueChange={handleTimeHour}
                placeholder={
                    '시'
                }
            />
            <span className={`${styles.fontM} ${styles.font16} ${styles.black}`}>시</span>
            <NumberFormat
                value={timeMin.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.ml10} ${styles.py10} ${styles.mr8} ${styles.textRight}`}
                style={{width: 40}}
                type={'tel'}
                displayType={'input'}
                onValueChange={handleTimeMin}
                placeholder={
                    '분'
                }
            />
            <span className={`${styles.fontM} ${styles.font15} ${styles.black}`}>{infoText}</span>
        </div>
    );
};

export default ScheduleTime;
