import React from "react";
import { gql, useQuery } from "@apollo/client";

import styles from "../../styles/styles.module.scss";
import Bill from "../../components/Global/Bill";

const BILL = gql`
  query getBill($billNo: String!) {
    getBill(billNo: $billNo) {
      id
      productName
      userName
      userContact
      totalAmount
      status
      issuerInfo
      installment
      approvalDate
      approvalTime
      startDate
      businessName
      businessAddress
      businessOwnerName
      businessPhoneNo
      businessNo
      cardNo
      surtax
      acquireInfo
      merchantNo
      approvalNo
      cancelDate
      cancelTime
      type
      lockerNumber
      lockerAreaName
      machine {
        id
        usePgBill
      }
    }
  }
`;

const CustomerBill = () => {
  const { data } = useQuery(BILL, {
    fetchPolicy: "network-only",
    variables: {
      billNo: window.location.pathname.split("/")[2],
    },
  });

  return (
    <div className={`${styles.bgGrayEf} ${styles.minHeightFull} ${styles.center}`} style={{ minWidth: 320, justifyContent: "flex-start" }}>
      <div style={{ maxWidth: 600 }}>{data && data.getBill ? <Bill payment={data.getBill} showPrint={false} /> : null}</div>
    </div>
  );
};

export default CustomerBill;
