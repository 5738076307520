import React, { useState } from "react";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { useAuthContext } from "../../../context/Auth";
import cache from "../../../apollo/cache";
import Modal from "react-modal";
import { modalAlertMb } from "../../../common/modal";

import ExpireModal from "../Auth/ExpireModal";

const LOGIN = gql`
  query loginAdmin($username: String!, $password: String!) {
    loginAdmin(username: $username, password: $password) {
      success
      token
      error
      user {
        id
        username
        superuser
      }
    }
  }
`;

const Login = () => {
  const history = useHistory();

  const username = useInput("");
  const password = useInput("");

  const AuthContext = useAuthContext();

  const [save, setSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { refetch } = useQuery(LOGIN, {
    skip: true,
    fetchPolicy: "no-cache",
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("viaItem");
    if (!loading) {
      if (username.value && password.value) {
        setLoading(true);
        await cache.reset();
        const { data } = await refetch({
          username: username.value,
          password: password.value,
        });
        setLoading(false);
        if (data && data.loginAdmin) {
          if (data.loginAdmin.success) {
            if (data.loginAdmin.user.superuser) {
              localStorage.setItem("viaItem", true);
            }
            AuthContext.login(data.loginAdmin.token, save);
          } else if (data.loginAdmin.error) {
            if (data.loginAdmin.error !== "현재 이용료 미결제 등의 사유로 계정이 만료 상태입니다.\n관리자에게 문의해주시길 바랍니다.") {
              alert(data.loginAdmin.error);
            } else {
              setShowModal(true);
            }
          } else {
            alert("아이디와 비밀번호를 확인해주세요.");
          }
        } else {
          alert("아이디와 비밀번호를 확인해주세요.");
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
      <Modal isOpen={showModal} onRequestClose={handleCloseModal} style={modalAlertMb}>
        <ExpireModal closeModal={handleCloseModal} />
      </Modal>
      <div className={`${styles.container} ${styles.overflowHidden} ${styles.py70}`} style={{ maxWidth: 600 }}>
        <div className={`${styles.textCenter}`}>
          <img src={require("../../../assets/images/logo_lg.png").default} alt={"VEN Brothers"} style={{ width: 200 }} />
        </div>
        <div className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px15} ${styles.pAuthBox} ${styles.mx15} ${styles.mt40} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font25} ${styles.black} ${styles.textCenter}`}>로그인</p>
          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt10}`}>
            계정이 없으신가요?{" "}
            <span className={`${styles.cursorPointer} ${styles.underline}`} onClick={loading ? null : () => history.push("/signup/")}>
              계정 만들기
            </span>
          </p>
          <form action={""} className={`${styles.mt50}`} onSubmit={submit}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`} style={{ width: 100 }}>
                이메일
              </p>
              <div className={`${styles.bgGrayEf}`} style={{ width: 1, height: 35 }} />
              <input type={"email"} value={username.value} onChange={username.onChange} placeholder={"가입하신 이메일 주소를 입력해주세요."} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`} style={{ width: 100 }}>
                비밀번호
              </p>
              <div className={`${styles.bgGrayEf}`} style={{ width: 1, height: 35 }} />
              <input type={"password"} value={password.value} onChange={password.onChange} placeholder={"비밀번호를 입력해주세요."} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`} />
            </div>
            <div className={`${styles.row} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt30}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.underline}`} onClick={loading ? null : () => history.push("/find/email/")}>
                이메일 찾기
              </p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.underline} ${styles.ml20}`} onClick={loading ? null : () => history.push("/find/password/")}>
                비밀번호 찾기
              </p>
            </div>
            <div className={`${styles.mt90} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.flex1}`}>
              <Switch onChange={setSave} checked={save} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
              <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.ml10}`}>로그인 상태 유지</p>
            </div>
            <button type={"submit"} className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull}`} style={{ opacity: loading ? 0.4 : 1 }}>
              <p className={`${styles.fontB} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>계속</p>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
