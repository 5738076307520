import React from "react";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";

import Login from "../../screens/PC/Auth/Login";
import SignUp from "../../screens/PC/Auth/SignUp";
import SignUpClear from "../../screens/PC/Auth/SignUpClear";
import FindEmail from "../../screens/PC/Auth/FindEmail";
import FindEmailClear from "../../screens/PC/Auth/FindEmailClear";
import FindPassword from "../../screens/PC/Auth/FindPassword";
import FindPasswordClear from "../../screens/PC/Auth/FindPasswordClear";
import AgreeWebView from "../../screens/PC/Agree/AgreeWebView";

import ProfitInfo from "../../screens/PC/ProfitInfo/ProfitInfo";
import ReportResult from "../../screens/PC/ProfitInfo/ReportResult";

const LoggedOutRoute = () => (
  <Router>
    <Switch>
      <Route exact path={"/"} component={Login} />
      <Route exact path={"/signup/"} component={SignUp} />
      <Route exact path={"/signup/success/"} component={SignUpClear} />
      <Route exact path={"/find/email/"} component={FindEmail} />
      <Route exact path={"/find/email/success/"} component={FindEmailClear} />
      <Route exact path={"/find/password/"} component={FindPassword} />
      <Route exact path={"/find/password/success/"} component={FindPasswordClear} />
      <Route exact path={"/report/"} component={ProfitInfo} />
      <Route exact path={"/reportresult/"} component={ReportResult} />
      <Route exact path={"/agree/"} render={(props) => <AgreeWebView {...props} />} />
      <Redirect exact to={"/"} />
    </Switch>
  </Router>
);

export default LoggedOutRoute;
