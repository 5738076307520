import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import styles from '../../../styles/styles.module.scss';

const ContractTermDetail = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const history = useHistory()
    const location = useLocation()

    const terms = location.state.terms;

    const [total, setTotal] = useState(null);
    const [page, setPage] = useState(1);

    const onLoadSuccess = ({ numPages }) => {
        setTotal(numPages);
    }

    const handlePage = (page) => {
        if((page > 0) && (total >= page)){
            setPage(page)
        }
    }

    useEffect(() => {
        if(!location.state.terms){
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {terms.name}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.mt40} ${styles.center}`}>
                            <Document
                            file={terms.file}
                            onLoadSuccess={onLoadSuccess}
                            externalLinkTarget={"_blank"}
                            >
                                <Page
                                pageNumber={page}
                                />
                            </Document>
                            {total ? (
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.my10}`}>
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer} ${styles.mx20} ${(page <= 1) ? styles.hidden : ""}`} style={{ width: 45*0.2, height: 79*0.2 }} onClick={() => handlePage(page - 1)} />
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter}`}>
                                        Page {page} of {total}
                                    </p>
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer} ${styles.mx20} ${(page >= total) ? styles.hidden : ""}`} style={{ width: 45*0.2, height: 79*0.2 }} onClick={() => handlePage(page + 1)} />
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractTermDetail;
