import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import ActiveMembershipItem from "./ActiveMembershipItem";

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      duration
      membershipType
      membership
      cancel
      ignoreCancel
    }
  }
`;

const ActiveMemberships = ({ machine, memberships, defaultMembershipId: id, isEntrance, removeMembership, editMembership, addMembership, createMembership, changeDefaultMembership, machineId, setFirstActiveMembership, confirmEditing }) => {
  const [defaultMembershipId, setDefaultMembershipId] = useState(id);

  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine,
      machineId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setDefaultMembershipId(id);
  }, [id]);

  return (
    <div className={`${styles.bgWhite}`}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py10} ${styles.px15} ${styles.borderBottomGrayF4}`}>
        <div className={`${styles.flex2}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권명</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>시작일</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>종료일</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>횟수</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>취소 가능 횟수</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제 금액</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>결제 일시</p>
        </div>
        <div className={`${styles.flex2}`}></div>
      </div>
      <div className={`${styles.bgWhite}`}>
        {memberships.map((membership, index) => {
          if (membership.status === "active" || membership.status === "ready" || membership.status === "holding") {
            setFirstActiveMembership(membership);

            return (
              <ActiveMembershipItem
                key={membership.id}
                membership={membership}
                index={index}
                isEntrance={isEntrance}
                memberships={data?.getMemberships}
                selected={membership.id === defaultMembershipId}
                setDefaultMembershipId={() => setDefaultMembershipId(membership.id)}
                removeMembership={() => removeMembership(membership)}
                editMembership={editMembership}
                confirmEditing={confirmEditing}
                changeDefaultMembership={() => changeDefaultMembership(membership.id)}
              />
            );
          }
        })}
        {addMembership && (
          <ActiveMembershipItem
            membership={{
              id: -1,
              product: {
                id: -1,
                name: "",
              },
              payment: {
                id: -1,
                totalAmount: "",
                approvalDate: "",
                approvalTime: "",
              },
              start: null,
              end: null,
              status: "",
            }}
            index={0}
            isEntrance={isEntrance}
            memberships={data?.getMemberships}
            selected={-1 === defaultMembershipId}
            setDefaultMembershipId={() => setDefaultMembershipId(-1)}
            createMembership={createMembership}
          />
        )}
      </div>
    </div>
  );
};

export default ActiveMemberships;
