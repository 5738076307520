import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import {numberWithCommas} from "../../../common/utility";

const ScheduleMachine = ({
    machine, setShowModal
}) => {
    const history = useHistory()

    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {machine.name}
                </p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
                     onClick={() => {
                         if(machine.useSchedule) {
                             history.push({
                                 pathname: `/schedule/main/`,
                                 state: {
                                     machine: machine.username
                                 },
                                 search: '?order=date_dsc&page=1&q='
                             });
                         } else {
                             setShowModal(true);
                         }
                     }}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        스케쥴 관리
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ScheduleMachine;
