import React, { useEffect, useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

import styles from "../../../styles/styles.module.scss";

import "../../../styles/scheduler_css.css";
import reportHeader from "../../../assets/images/report_header.png";
import reportCheck from "../../../assets/images/report_check.png";
import reportCheckBlue from "../../../assets/images/report_checkblue.png";
import reportSearch from "../../../assets/images/report_search.png";
import dropUp from "../../../assets/images/report_dropdup.png";
import dropDown from "../../../assets/images/report_dropdown.png";
import add from "../../../assets/images/report_add.png";

const siData = ["서울특별시", "부산광역시", "인천광역시", "대구광역시", "광주광역시", "대전광역시", "울산광역시", "경기도", "강원도", "충청북도", "충청남도", "경상북도", "경상남도", "전라북도", "전라남도", "제주특별자치도"];

const guJson = {
  data: [
    { 서울특별시: ["종로구", "중구", "용산구", "성동구", "광진구", "동대문구", "중랑구", "성북구", "강북구", "도봉구", "노원구", "은평구", "서대문구", "마포구", "양천구", "강서구", "구로구", "금천구", "영등포구", "동작구", "관악구", "서초구", "강남구", "송파구", "강동구"] },
    { 부산광역시: ["중구", "서구", "동구", "영도구", "부산진구", "동래구", "남구", "북구", "강서구", "해운대구", "사하구", "금정구", "연제구", "수영구", "사상구", "기장군"] },
    { 인천광역시: ["중구", "동구", "남구", "연수구", "남동구", "부평구", "계양구", "서구", "강화군", "옹진군"] },
    { 대구광역시: ["중구", "동구", "서구", "남구", "북구", "수성구", "달서구", "달성군"] },
    { 광주광역시: ["동구", "서구", "남구", "북구", "광산구"] },
    { 대전광역시: ["동구", "중구", "서구", "유성구", "대덕구"] },
    { 울산광역시: ["중구", "남구", "동구", "북구", "울주군"] },
    { 세종특별자치시: ["세종특별자치시"] },
    {
      경기도: [
        "가평군",
        "고양시",
        "과천시",
        "광명시",
        "광주시",
        "구리시",
        "군포시",
        "김포시",
        "남양주시",
        "동두천시",
        "부천시",
        "성남시",
        "수원시",
        "시흥시",
        "안산시",
        "안성시",
        "안양시",
        "양주시",
        "양평군",
        "여주시",
        "연천군",
        "오산시",
        "용인시",
        "의왕시",
        "의정부시",
        "이천시",
        "파주시",
        "평택시",
        "포천시",
        "하남시",
        "화성시",
      ],
    },
    { 강원도: ["원주시", "춘천시", "강릉시", "동해시", "속초시", "삼척시", "홍천군", "태백시", "철원군", "횡성군", "평창군", "영월군", "정선군", "인제군", "고성군", "양양군", "화천군", "양구군"] },
    { 충청북도: ["청주시", "충주시", "제천시", "보은군", "옥천군", "영동군", "증평군", "진천군", "괴산군", "음성군", "단양군"] },
    { 충청남도: ["천안시", "공주시", "보령시", "아산시", "서산시", "논산시", "계룡시", "당진시", "금산군", "부여군", "서천군", "청양군", "홍성군", "예산군", "태안군"] },
    { 경상북도: ["포항시", "경주시", "김천시", "안동시", "구미시", "영주시", "영천시", "상주시", "문경시", "경산시", "군위군", "의성군", "청송군", "영양군", "영덕군", "청도군", "고령군", "성주군", "칠곡군", "예천군", "봉화군", "울진군", "울릉군"] },
    { 경상남도: ["창원시", "김해시", "진주시", "양산시", "거제시", "통영시", "사천시", "밀양시", "함안군", "거창군", "창녕군", "고성군", "하동군", "합천군", "남해군", "함양군", "산청군", "의령군"] },
    { 전라북도: ["전주시", "익산시", "군산시", "정읍시", "완주군", "김제시", "남원시", "고창군", "부안군", "임실군", "순창군", "진안군", "장수군", "무주군"] },
    { 전라남도: ["여수시", "순천시", "목포시", "광양시", "나주시", "무안군", "해남군", "고흥군", "화순군", "영암군", "영광군", "완도군", "담양군", "장성군", "보성군", "신안군", "장흥군", "강진군", "함평군", "진도군", "곡성군", "구례군"] },
    { 제주특별자치도: ["제주시", "서귀포시"] },
  ],
};

const ProfitInfo = () => {
  //num1 state
  const [si, setSi] = useState("");
  const [gu, setGu] = useState("");
  const [guData, setGuData] = useState([]);
  const [isSiDropdown, setIsSiDropown] = useState(false);
  const [isGuDropdown, setIsGuDropown] = useState(false);

  //num2 state
  const [upjongstr, setUpjongstr] = useState("");
  const [upjong, setUpjong] = useState("");

  //num4 state
  const [inputProfit, setInputProfit] = useState([{ date: "", profit: "", isDropDown: false }]);
  const [dropDate, setDropDate] = useState([]);
  const [num4chk, setNum4chk] = useState(false);

  const addComma = (str) => {
    return parseInt(str).toLocaleString("ko-KR");
  };

  const getInitDate = async () => {
    let inputDate = [];
    let tmpDate = "";
    for (let i = 0; i < 12; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() + 1 - i);
      //2024-00으로 나오는경우가 있어 아래와 같이 처리
      let tmpYear = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear();
      let tmpMonth00 = date.getMonth() + 1 > 10 ? date.getMonth() : "0" + date.getMonth();
      let tmpMonth = tmpMonth00 === "00" ? "12" : tmpMonth00;

      let tmpDate = tmpYear + "-" + tmpMonth;
      inputDate.push(tmpDate);
    }

    setDropDate([...inputDate]);
    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[0].date = inputDate[0];
    console.log(tmpInputProfit);
    setInputProfit([...tmpInputProfit]);
  };

  const init = async () => {
    await getInitDate();
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let test = false;

    for (let i = 0; i < inputProfit.length; i++) {
      if (inputProfit[i].date !== "" && inputProfit[i].profit !== "") {
        continue;
      } else {
        test = true;
        break;
      }
    }
    setNum4chk(!test);
  }, [inputProfit]);

  const handleUpjongStr = () => {};

  const onChangeProfit = (e, index) => {
    let tmpStr = e.target.value;
    let regex = /[^0-9]/g;
    let result = tmpStr.replaceAll(regex, ""); // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경

    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[index].profit = result;
    setInputProfit([...tmpInputProfit]);

    console.log(tmpInputProfit);
  };

  const handleDateAdd = () => {
    let tmpInputProfit = [...inputProfit];
    let index = inputProfit.length;
    if (index < 12) {
      let tmpArr = { date: dropDate[index], profit: "", isDropDown: false };

      tmpInputProfit.push(tmpArr);
      setInputProfit([...tmpInputProfit]);
    }
  };

  const handleSiDropdown = () => {
    let tmpIsSiDropdown = isSiDropdown;

    setIsSiDropown(!tmpIsSiDropdown);
  };

  const handleGuDropdown = () => {
    if (si !== "") {
      let tmpIsGuDropdown = isGuDropdown;

      setIsGuDropown(!tmpIsGuDropdown);
    }
  };

  const handleDropDownDate = (e, index) => {
    let tmpInputProfit = [...inputProfit];
    tmpInputProfit[index].isDropDown = !tmpInputProfit[index].isDropDown;

    setInputProfit([...tmpInputProfit]);
  };

  const handleSelectSi = (item) => {
    setSi(item);
    setGu("");
    setIsGuDropown(true);
    for (let i = 0; i < guJson.data.length; i++) {
      if (guJson.data[i][item]) {
        setGuData([...guJson.data[i][item]]);
        break;
      }
    }
  };

  const handleSelectGu = (item) => {
    setGu(item);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#111111", alignItems: "center", height: "100%" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <div className={`${styles.font14} ${styles.fontB}`} style={{ position: "absolute", width: "300px", top: "50%", left: "50%", transform: `translate(-50%, -50%)`, zIndex: 10 }}>
          <label style={{ color: "white" }}>지금 내 매출을 입력하고</label>
          <label style={{ color: "#32bbff" }}>&nbsp;빅데이터 기반</label>
          <label style={{ color: "white" }}>
            으로 예측된
            <br /> 향후 예상 매출을 확인해보세요!
          </label>
        </div>
        <img src={reportHeader} style={{ backgroundColor: "#ffffff", opacity: "0.5", height: "20vh", width: "100%", backgroundSize: "cover" }} />
      </div>
      <div className={`${styles.py30}`} style={{ minWidth: "300px", width: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div className={`${styles.py0} ${styles.widthFull}`} style={{ backgroundColor: "#111111" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <label className={`${styles.fontB} ${styles.font18}`} style={{ color: "white" }}>{`분석하실 `}</label>
            <label className={`${styles.fontB} ${styles.font18}`} style={{ color: "#32bbff" }}>
              &nbsp; 데이터
            </label>
            <label className={`${styles.fontB} ${styles.font18}`} style={{ color: "white" }}>{`를 입력해주세요.`}</label>
          </div>
          <div className={`${styles.pt30} ${styles.widthFull}`}>
            <div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font14}`} style={{ color: "white" }}>
                  1. 운영중인 업장의 지역을 선택해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font14}`} style={{ color: `${gu !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${gu !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${gu !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${gu !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`} style={{ position: "relative" }} onClick={handleSiDropdown}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${si !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                    {`${si !== "" ? si : "광역시도를 선택해 주세요."}`}
                    <img className={`${styles.mx10}`} src={isSiDropdown === true ? dropUp : dropDown} width={20} height={13} />
                  </label>
                  {isSiDropdown && (
                    <div className={`${styles.hideScroll} ${styles.pb5}`} style={{ zIndex: 10, position: "absolute", top: "40px", width: "100%", height: "150px", overflowY: "scroll", backgroundColor: "#252525" }}>
                      {siData &&
                        siData.map((item, index) => (
                          <div key={index} className={`${styles.reportHoverItem} ${styles.py3}`} style={{ paddingLeft: "15px" }} onClick={() => handleSelectSi(item, index)}>
                            <div style={{ color: "white" }}>{item}</div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull} ${styles.ml10}`} style={{ position: "relative" }} onClick={handleGuDropdown}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${gu !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {`${gu !== "" ? gu : "시군구 지역을 선택해 주세요."}`}
                    <img className={`${styles.mx10}`} src={isGuDropdown === true ? dropUp : dropDown} width={20} height={13} />
                  </label>
                  {isGuDropdown && (
                    <div className={`${styles.hideScroll} ${styles.pb5}`} style={{ zIndex: 10, position: "absolute", top: "40px", width: "100%", height: "150px", overflowY: "scroll", backgroundColor: "#252525" }}>
                      {guData &&
                        guData.map((item, index) => (
                          <div key={index} className={`${styles.reportHoverItem} ${styles.py3}`} style={{ paddingLeft: "15px" }} onClick={() => handleSelectGu(item)}>
                            <div style={{ color: "white" }}>{item}</div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font14}`} style={{ color: "white" }}>
                  2. 운영중인 업장의 업종을 선택해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font14}`} style={{ color: `${gu !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${gu !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${gu !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${gu !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjongstr !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      className={`${styles.font12} ${styles.mt5} ${styles.widthFull}`}
                      style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${upjongstr !== "" ? "white" : "#767676"}` }}
                      placeholder={"업종을 검색해 보세요."}
                      value={upjongstr}
                      onChange={() => handleUpjongStr(upjongstr)}
                    ></input>
                    <img className={`${styles.mx10}`} src={reportSearch} width={20} height={20} />
                  </label>
                </div>
                <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull} ${styles.ml10}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjong !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>{`${upjong !== "" ? upjong : "업종을 선택해 주세요."}`}</label>
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  3. 운영중인 업장의 이름을 입력해 주세요.
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${gu !== "" ? "#32bbff" : "#767676"}` }}>
                    {`${gu !== "" ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${gu !== "" ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${gu !== "" ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              <div className={`${styles.mt15} ${styles.widthFull}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}>
                  <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjongstr !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <input
                      className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                      style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${upjongstr !== "" ? "white" : "#767676"}` }}
                      placeholder={"업장명을 입력해 주세요."}
                      onChange={() => handleUpjongStr(upjongstr)}
                    ></input>
                  </label>
                </div>
              </div>
            </div>
            <div className={`${styles.mt60}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`}>
                <label className={`${styles.font18}`} style={{ color: "white" }}>
                  4. 월 매출을 입력해 주세요. (최대 12개월까지 입력 가능)
                </label>
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.pr5}`}>
                  <label className={`${styles.font18}`} style={{ color: `${num4chk === true ? "#32bbff" : "#767676"}` }}>
                    {`${num4chk === true ? "입력완료" : "입력필요"}`} &nbsp;
                  </label>
                  <div style={{ border: `solid ${num4chk === true ? "#32bbff" : "#767676"}`, borderRadius: "50px", width: "25px", height: "25px", marginTop: "2px" }}>
                    <img src={`${num4chk === true ? reportCheckBlue : reportCheck}`} width={16} height={16} style={{ padding: "0px 0px 0px 2px" }} />
                  </div>
                </div>
              </div>
              {inputProfit.length > 0 &&
                inputProfit.map((item, index) => (
                  <div key={index} className={`${styles.mt15}`} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div
                      className={`${styles.flex4} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.widthFull}`}
                      style={{ position: "relative" }}
                      onClick={(e) => {
                        handleDropDownDate(e, index);
                      }}
                    >
                      <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${item.date !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                        {`${item.date !== "" ? item.date : "날짜를 선택해 주세요."}`}
                        <img className={`${styles.mx10}`} src={item.isDropDown ? dropUp : dropDown} width={20} height={13} />
                      </label>
                      {item.isDropDown && (
                        <div className={`${styles.hideScroll} ${styles.pb5}`} style={{ zIndex: 10, position: "absolute", top: "40px", width: "100%", height: "150px", overflowY: "scroll", backgroundColor: "#252525" }}>
                          {dropDate &&
                            dropDate.map((dropItem, dropIndex) => (
                              <div key={dropIndex} className={`${styles.reportHoverItem} ${styles.py3}`} style={{ paddingLeft: "15px" }}>
                                <div style={{ color: "white" }}>{dropItem}</div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>

                    <div className={`${styles.flex6} ${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.ml10}`}>
                      <label style={{ width: "100%", paddingLeft: "15px", backgroundColor: "#252525", color: `${upjongstr !== "" ? "white" : "#767676"}`, height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <input
                          className={`${styles.font16} ${styles.mt5} ${styles.widthFull}`}
                          style={{ height: "25px", backgroundColor: "#252525", border: "none", color: `${item.profit !== "" ? "white" : "#767676"}`, textAlign: "right" }}
                          placeholder={"매출을 입력해 주세요. (숫자만 입력가능)"}
                          value={addComma(item.profit) === "NaN" ? "" : addComma(item.profit)}
                          onChange={(e) => onChangeProfit(e, index)}
                        ></input>
                        <label className={`${styles.mr10}`} style={{ color: "white" }}>
                          원
                        </label>
                      </label>
                    </div>
                  </div>
                ))}

              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentCenter} ${styles.mt30}`}>
                <div style={{ border: "solid 2px #767676", borderRadius: "50px", width: "36px", height: "36px", padding: "6px 0px 0px 6px", cursor: "pointer" }} onClick={handleDateAdd}>
                  <img src={add} width={20} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.mb100} ${styles.mt30}`} style={{ borderTop: "solid 2px #32bbff", backgroundColor: "#252525", width: "80%", display: "flex", justifyContent: "center" }}>
        <div className={`${styles.row} ${styles.mx0} ${styles.font20} ${styles.py15}`} style={{ width: "80%", justifyContent: "center" }}>
          <label style={{ color: "white", fontWeight: "bold" }}>데이터&nbsp;</label>
          <label style={{ color: "white" }}>분석하기</label>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", borderTop: "solid 1px #252525", height: "250px", width: "100%" }}>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font18}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
        <div className={`${styles.pl60} ${styles.pt50} ${styles.font15}`} style={{ color: "white", width: "40%" }}>
          test
        </div>
      </div>
    </div>
  );
};

export default ProfitInfo;
