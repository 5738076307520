import React from 'react';

import styles from '../../../styles/styles.module.scss';

const CheckIn = ({
    checkIn
}) => {
    const date = new Date(Number(checkIn.date))
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex2}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {`${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}/${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}-${date.getHours() < 10 ? "0" + String(date.getHours()) : date.getHours()}:${date.getMinutes() < 10 ? "0" + String(date.getMinutes()) : date.getMinutes()}`}
                </p>
            </div>
            <div className={`${styles.flex2} ${styles.px10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {checkIn.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.underline}`}>
                    {checkIn.temperature}
                </p>
            </div>
        </div>
    )
}

export default CheckIn;