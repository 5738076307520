import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const SignUpClear = () => {
    const history = useHistory()

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
            <div className={`${styles.container} ${styles.overflowHidden} ${styles.py70}`} style={{ maxWidth: 600 }}>
                <div className={`${styles.textCenter}`}>
                    <img src={require("../../../assets/images/logo_lg.png").default} alt={"VEN Brothers"} style={{ width: 200 }} />
                </div>
                <div className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px15} ${styles.pAuthBox} ${styles.mx15} ${styles.mt40} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font25} ${styles.black} ${styles.textCenter}`}>
                        계정 만들기 완료
                    </p>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.mt75}`}>
                        벤브라더스 가입을 환영합니다. <br/>로그인 페이지로 이동하여 <br/>벤브라더스에 접속하세요!
                    </p>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer} ${styles.containerInput}`} style={{ marginTop: 215 }} onClick={() => history.push("/")}>
                        <p className={`${styles.fontB} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpClear;