import React, { useState } from "react";

const Modal = require("react-modal");
const { billSm } = require("../../common/modal");
const styles = require("../../../src/styles/styles.module.scss");
export const AUTH_TYPE_CARD = "C"; // 카드 인증
export const AUTH_TYPE_FNGR = "F"; // 지문 인증
export const AUTH_TYPE_MASK = "M"; // 얼굴 인증
export const AUTH_TYPE_DRCT = "D"; // 직접 인증(추후)
export const AUTH_TYPE_NONE = "N"; // 직접 인증 해제(추후)
export const AUTH_STATUS_DRCT = "Direct";

const SelectEntranceType = ({ machine, showModal, setAuthTypeSubmit, cancel, authStatus }) => {
  const [authType, setAuthType] = useState("");

  console.log(machine);

  return (
    <div style={{ minHeight: 230 }}>
      <div style={{ display: "flex", flexFlow: "column", gap: 10, marginTop: 10 }}>
        <div style={{ width: "100%", display: "flex", gap: 10 }}>
          <div
            className={`${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.borderRadius10} 
                            ${authType === AUTH_TYPE_FNGR || authType === AUTH_TYPE_MASK ? styles.borderOrange : styles.borderGrayA2} ${styles.bgWhite} ${styles.btnShadow}`}
            style={{ borderWidth: "2px" }}
            onClick={machine.entranceMachine === "N2" ? () => setAuthType(AUTH_TYPE_FNGR) : () => setAuthType(AUTH_TYPE_MASK)}
          >
            <img
              src={machine.entranceMachine === "N2" ? require("../../assets/images/icon_auth_finger.png").default : require("../../assets/images/icon_auth_face.png").default}
              style={{
                width: 60,
                height: (534 * 60) / 400,
              }}
            />
            <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_FNGR || authType === AUTH_TYPE_MASK ? styles.orange : styles.black} ${styles.mt10}`}>{machine.entranceMachine === "N2" ? "지문" : "얼굴"}</p>
          </div>
          <div
            className={`${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.borderRadius10} 
                            ${authType === AUTH_TYPE_CARD ? styles.borderOrange : styles.borderGrayA2} ${styles.bgWhite} ${styles.btnShadow}`}
            style={{ borderWidth: "2px" }}
            onClick={() => setAuthType(AUTH_TYPE_CARD)}
          >
            <img
              src={require("../../assets/images/icon_auth_card.png").default}
              style={{
                width: 60,
                height: (534 * 60) / 400,
              }}
            />
            <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_CARD ? styles.orange : styles.black} ${styles.mt10}`}>카드</p>
          </div>
          {machine?.deviceId?.length > 0 ? (
            <div
              className={`${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.borderRadius10}
                            ${authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? styles.borderOrange : styles.borderGrayA2} ${styles.bgWhite} ${styles.btnShadow}`}
              style={{ borderWidth: "2px" }}
              onClick={() => {
                authStatus == AUTH_STATUS_DRCT ? setAuthType(AUTH_TYPE_NONE) : setAuthType(AUTH_TYPE_DRCT);
              }}
            >
              <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? styles.orange : styles.black} ${styles.height}`}>추후 회원이</p>
              <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? styles.orange : styles.black} ${styles.mt10}`}>키오스크에서</p>
              <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? styles.orange : styles.black} ${styles.mt10}`}>직접 등록</p>
              <p className={`${styles.fontB} ${styles.font14} ${authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? styles.orange : styles.black} ${styles.mt10}`}>{authStatus == AUTH_STATUS_DRCT ? "해제" : "설정"}</p>
            </div>
          ) : null}
        </div>
        <div style={{ textAlign: "right" }}>{machine?.deviceId?.length > 0 ? <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.mr15}`}>{authStatus == AUTH_STATUS_DRCT ? "현재 권한 O" : "현재 권한 X"}</p> : null}</div>
        <div className={`${styles.mt10}`} style={{ width: "100%", display: "flex", gap: 10 }}>
          <div className={`${styles.py10} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.btnShadow}`} onClick={cancel}>
            <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>취소</p>
          </div>
          <div
            className={`${styles.py10} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.btnShadow}`}
            style={{ borderWidth: "2px" }}
            onClick={() => {
              if (authType !== AUTH_TYPE_CARD && authType !== AUTH_TYPE_FNGR && authType !== AUTH_TYPE_MASK && authType !== AUTH_TYPE_DRCT && authType !== AUTH_TYPE_NONE) {
                alert("인증수단을 선택해주세요.");
                return;
              }
              setAuthTypeSubmit(authType);
            }}
          >
            <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>다음</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectEntranceType;
