import React, {useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import {useHistory, useLocation} from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

import '../../../styles/scheduler_css.css';
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import MarketingList from "../../../components/MemberReservation/PC/MarketingList";
import xlsx from "xlsx";

const GET_MARKETINGS = gql`
    query getMarketings($order: String!, $skip: Int!) {
        getMarketings(order: $order, skip: $skip) {
            memberReservations {
                id
                birth
                gender
                isMarketingAgree
                createdAt
                members {
                    id
                    name
                    birth
                    gender
                    mobile
                    machine {
                        name
                    }
                }
            }
            total
        }
    }
`;

const EXCEL_RESERVATION_MEMBERS = gql`
    query getExcelReservationMembers {
        getExcelReservationMembers {
            memberReservations {
                id
                birth
                gender
                isMarketingAgree
                createdAt
                members {
                    id
                    name
                    birth
                    gender
                    mobile
                    machine {
                        name
                    }
                }
            }
        }
    }
`;

const MarketingHome = () => {
    const history = useHistory();
    const location = useLocation();

    const {data, loading: loadingData, refetch: memberRefetch} = useQuery(GET_MARKETINGS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            skip: 0
        }
    });

    const handleOrder = (order) => {
        if(order === "date"){
            if(queryString.parse(location.search).order === "name_asc"){
                applyOrder("name_dsc")
            }
            else if(queryString.parse(location.search).order === "name_dsc"){
                applyOrder("name_asc")
            }
            else{
                applyOrder("name_dsc")
            }
        }
    };

    const applyOrder = (order) => {
        history.replace({
            pathname: `/member_reservation/marketing/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&order=${order}`
        });
    };

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            memberRefetch({
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_dsc",
                skip: 10*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order]);

    const handlePage = (page) => {
        history.replace({
            pathname: `/member_reservation/marketing/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_dsc"}`
        })
    }

    const { data:excel_data, refetch:excelFetch } = useQuery(EXCEL_RESERVATION_MEMBERS, {
        fetchPolicy: "cache-and-network"
    })
    const [downloading, setDownloading] = useState(false);
    const exportExcel = async () => {
        if (!downloading && data) {
            setDownloading(true);
            try {
                const { data: result} = await excelFetch();
                if (result && result.getExcelReservationMembers.memberReservations.length > 0) {
                    let data = [];
                    for (const memberReservation of result.getExcelReservationMembers.memberReservations) {
                        let memberName = memberReservation && memberReservation.members && memberReservation.members.length > 0 ? memberReservation.members[0].name : '';
                        let memberMobile = memberReservation && memberReservation.members && memberReservation.members.length > 0 ? memberReservation.members[0].mobile : '';
                        let members = memberReservation && memberReservation.members && memberReservation.members.length > 0 ? memberReservation.members : [];
                        let memberMachine = [];
                        for (const member of members) {
                            memberMachine.push(member.machine.name);
                        }
                        data.push({
                            '회원명': memberName,
                            '회원 연락처': memberMobile,
                            '성별': memberReservation.gender==='male'?'남성':(memberReservation.gender==='female'?'여성':''),
                            '생년월일': memberReservation.birth,
                            '센터': memberMachine.join(', '),
                        });
                    }
                    const ws = xlsx.utils.json_to_sheet(data);
                    const wb = xlsx.utils.book_new();
                    let wscols = [
                        { wpx: 100 },
                        { wpx: 100 },
                        { wpx: 100 },
                        { wpx: 100 },
                        { wpx: 400 },
                    ];
                    ws['!cols'] = wscols;
                    xlsx.utils.book_append_sheet(wb, ws, '마케팅수신 동의자 회원 목록');
                    xlsx.writeFile(wb, `Ven-Brothers_마케팅수신동의자_회원명단.xlsx`,);
                } else {
                    alert('내보낼 명단이 없습니다.')
                }
            } catch {
                alert('오류가 발생하였습니다.');
            } finally {
                setDownloading(false);
            }
        }
    };

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`회원 관리`}
                            </p>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                onClick={exportExcel}>
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                    마케팅 수신 동의자 내보내기
                                </p>
                            </div>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원명
                                </p>
                                {/*<div className={`${styles.ml10} ${styles.center}`}>*/}
                                {/*    <img src={(queryString.parse(location.search).order === "name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />*/}
                                {/*    <img src={(queryString.parse(location.search).order === "name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />*/}
                                {/*</div>*/}
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    회원 연락처
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    성별
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    생년월일
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    센터
                                </p>
                            </div>
                        </div>
                        {loadingData || !data ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            data?.getMarketings?.memberReservations?.map(memberReservation => {
                                return (
                                    <MarketingList key={memberReservation.id} memberReservation={memberReservation}/>
                                )
                            })
                        )}
                        {(data?.getMarketings?.total > 10) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{position: "relative"}}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(data?.getMarketings?.total) ? parseInt((data?.getMarketings?.total - 1) / 10) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingData ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {(loadingData) && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "rgba(255, 255, 255, 0.4)"
                                    }}/>
                                )}
                            </div>
                        ) : null}
                    </div>


                </div>
            </div>
        </div>
    );
}

export default MarketingHome;
