import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import OrderMachine from '../../../components/Order/PC/OrderMachine';
import OrderHomePayment from '../../../components/Order/PC/OrderHomePayment';

const MACHINE = gql`
    query getMachines($skip: Int!){
        getMachines(skip: $skip){
            machines{
                id
                username
                name
                status
                createdAt
                orderProductTotal
                orderProductStock{
                    _sum{
                        stock
                    }
                }
                orderProductDiscountTotal
            }
            total
        }
    }
`

const PAYMENT = gql`
    query getOrderPayments($order: String!, $skip: Int!){
        getOrderPayments(order: $order, skip: $skip){
            payments{
                id
                productName
                machine{
                    id
                    username
                    name
                }
                userName
                userContact
                totalAmount
                status
                receiveStatus
                issuerInfo
                installment
                approvalDate
                approvalTime
            }
            total
        }
    }
`

const OrderHome = () => {
    const history = useHistory()
    const location = useLocation()

    const { data, loading:loadingMachine, refetch } = useQuery(MACHINE, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0
        }
    })
    const { data:payments, loading:loadingPayment, refetch:refetchPayment } = useQuery(PAYMENT, {
        fetchPolicy: "cache-and-network",
        variables: {
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            skip: 0
        }
    })

    const handlePage = (page) => {
        history.push(`/order/?page=${page.selected + 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
    }

    const handleTPage = (page) => {
        history.push(`/order/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
    }

    const handleOrder = (order) => {
        if(order === "date"){
            if(queryString.parse(location.search).order === "date_asc"){
                applyOrder("date_dsc")
            }
            else if(queryString.parse(location.search).order === "date_dsc"){
                applyOrder("date_asc")
            }
            else{
                applyOrder("date_dsc")
            }
        }
        else if(order === "name"){
            if(queryString.parse(location.search).order === "name_asc"){
                applyOrder("name_dsc")
            }
            else if(queryString.parse(location.search).order === "name_dsc"){
                applyOrder("name_asc")
            }
            else{
                applyOrder("name_asc")
            }
        }
        else if(order === "customer_name"){
            if(queryString.parse(location.search).order === "customer_name_asc"){
                applyOrder("customer_name_dsc")
            }
            else if(queryString.parse(location.search).order === "customer_name_dsc"){
                applyOrder("customer_name_asc")
            }
            else{
                applyOrder("customer_name_asc")
            }
        }
        else if(order === "customer_contact"){
            if(queryString.parse(location.search).order === "customer_contact_asc"){
                applyOrder("customer_contact_dsc")
            }
            else if(queryString.parse(location.search).order === "customer_contact_dsc"){
                applyOrder("customer_contact_asc")
            }
            else{
                applyOrder("customer_contact_asc")
            }
        }
        else if(order === "product"){
            if(queryString.parse(location.search).order === "product_asc"){
                applyOrder("product_dsc")
            }
            else if(queryString.parse(location.search).order === "product_dsc"){
                applyOrder("product_asc")
            }
            else{
                applyOrder("product_asc")
            }
        }
        else if(order === "amount"){
            if(queryString.parse(location.search).order === "amount_asc"){
                applyOrder("amount_dsc")
            }
            else if(queryString.parse(location.search).order === "amount_dsc"){
                applyOrder("amount_asc")
            }
            else{
                applyOrder("amount_asc")
            }
        }
        else if(order === "payment"){
            if(queryString.parse(location.search).order === "payment_asc"){
                applyOrder("payment_dsc")
            }
            else if(queryString.parse(location.search).order === "payment_dsc"){
                applyOrder("payment_asc")
            }
            else{
                applyOrder("payment_asc")
            }
        }
        else{
            applyOrder("date_dsc")
        }
    }

    const applyOrder = (order) => {
        history.replace({
            pathname: `/order/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${order}`
        })
    }

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            refetch({
                skip: 5*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page])

    useEffect(() => {
        if(queryString.parse(location.search).tpage && !isNaN(Number(queryString.parse(location.search).tpage))){
            window.scrollTo(0,0)
            refetchPayment({
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                skip: 5*(Number(queryString.parse(location.search).tpage) - 1)
            })
        }
    }, [queryString.parse(location.search).tpage, queryString.parse(location.search).order])

    useEffect(() => {
        if(queryString.parse(location.search).page && queryString.parse(location.search).tpage && queryString.parse(location.search).order){
            
        }
        else{
            history.replace(`/order/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                주문 판매 상품 관리
                            </p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기계정
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태	
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 상품수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 전체 상품 수량
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    할인 적용 상품
                                </p>
                            </div>
                            <div className={`${styles.flex2}`}>
                                
                            </div>
                        </div>
                        {loadingMachine ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            data?.getMachines?.machines?.map(machine => {
                                return(
                                    <OrderMachine key={machine.id} machine={machine} />
                                )
                            })
                        )}
                        {(data?.getMachines?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(data?.getMachines?.total) ? parseInt((data?.getMachines?.total - 1)/5) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingMachine ? null : handlePage}
                                initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingMachine && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                미처리 주문 목록 바로보기
                            </p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    판매일시
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "date_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "date_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("customer_name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    구매자명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "customer_name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "customer_name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("customer_contact")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    구매자 연락처
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "customer_contact_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "customer_contact_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("product")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상품명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "product_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "product_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("amount")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    결제 금액
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "amount_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "amount_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("payment")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    결제 수단
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "payment_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "payment_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    결제상태
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    수령 상태
                                </p>
                            </div>
                        </div>
                        {loadingPayment ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            payments?.getOrderPayments?.payments?.map(payment => {
                                return(
                                    <OrderHomePayment key={payment.id} payment={payment} />
                                )
                            })
                        )}
                        {(payments?.getOrderPayments?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(payments?.getOrderPayments?.total) ? parseInt((payments?.getOrderPayments?.total - 1)/5) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingPayment ? null : handleTPage}
                                initialPage={queryString.parse(location.search).tpage ? Number(queryString.parse(location.search).tpage) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingPayment && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderHome;