import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styles from '../../../styles/styles.module.scss';
import ProductCategory from '../../../components/Global/PC/ProductCategory';

const CATEGORY = gql`
    query getCategoryAdmin($machine: String!, $orderName: Boolean) {
        getCategoryAdmin(
            type: "site"
            machine: $machine
            orderName: $orderName
        ) {
            id
            name
            productCount
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
            username
        }
    }
`;

const EDIT = gql`
    mutation editCategoryIndex($machine: String!, $result: String!) {
        editCategoryIndex(machine: $machine, type: "site", result: $result) {
            id
            name
            productCount
        }
    }
`;

const SiteCategoryOrdering = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [categorys, setCategorys] = useState([]);

    const {
        data,
        loading: loadingCategory,
        refetch,
    } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            orderName: false,
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [editCategoryIndexM] = useMutation(EDIT);

    const orderName = async () => {
        if (!loading) {
            const { data } = await refetch({
                machine: location.state.machine,
                orderName: true,
            });
            if (data && data.getCategoryAdmin) {
                setCategorys(data.getCategoryAdmin);
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const newCategorys = reorder(
            categorys,
            result.source.index,
            result.destination.index,
        );

        setCategorys(newCategorys);
    };

    const saveOrder = async () => {
        if (!loading && categorys.length > 0) {
            setLoading(true);
            let result = [];
            for (var i = 0; i < categorys.length; i++) {
                result.push({
                    id: categorys[i].id,
                    index: i,
                });
            }
            try {
                const { data } = await editCategoryIndexM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        result: JSON.stringify(result),
                    },
                });
                setLoading(false);
                if (data && data.editCategoryIndex) {
                    setCategorys(data.editCategoryIndex);
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data && data.getCategoryAdmin) {
            setCategorys(data.getCategoryAdmin);
        }
    }, [data]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`카테고리 노출 순서 변경${
                                    machine && machine.getMachine
                                        ? ` (${machine.getMachine.name})`
                                        : ''
                                }`}
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={orderName}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        가나다순 정렬
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                    style={{ opacity: loading ? 0.4 : 1 }}
                                    onClick={saveOrder}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        저장
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`}
                                    onClick={history.goBack}>
                                    <img
                                        src={
                                            require('../../../assets/images/icon_back.png')
                                                .default
                                        }
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                        뒤로가기
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p
                            className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight} ${styles.mt45}`}>
                            *카테고리를 드래그하여 노출 순서를 지정해주세요.
                        </p>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt10}`}>
                            <div className={`${styles.flex1}`}></div>
                            <div className={`${styles.flex2}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    카테고리명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상품 수
                                </p>
                            </div>
                            <div className={`${styles.flex10}`}></div>
                        </div>
                        <div style={{ minHeight: 600 }}>
                            {loadingCategory ? (
                                <Skeleton height={50} count={3} />
                            ) : categorys.length > 0 ? (
                                <>
                                    <ProductCategory
                                        category={{
                                            id: -1,
                                            name: '전체',
                                        }}
                                        extra={
                                            "* '전체' 카테고리는 삭제하거나 이동할 수 없습니다."
                                        }
                                    />
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable
                                            droppableId="droppable"
                                            direction="vertical">
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    className={`${styles.overflowHidden}`}>
                                                    {categorys.map(
                                                        (category, index) => (
                                                            <Draggable
                                                                key={`${category.id}`}
                                                                draggableId={`${category.id}`}
                                                                index={index}>
                                                                {provided => (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}>
                                                                        <ProductCategory
                                                                            key={
                                                                                category.id
                                                                            }
                                                                            category={
                                                                                category
                                                                            }
                                                                        />
                                                                        {
                                                                            provided.placeholder
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                            ) : (
                                <div
                                    className={`${styles.center}`}
                                    style={{ height: 600 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        아직 등록된 카테고리가 없습니다.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SiteCategoryOrdering;
