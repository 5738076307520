import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Device = ({ device, location }) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p
                    className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                    {device.name}
                </p>
            </div>
            <div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/video/',
                            state: {
                                machine: device.username,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        영상
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt5}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/edit/',
                            state: {
                                device,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        광고
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt5}`}
                    onClick={() =>
                        history.push({
                            pathname: '/device/detail/',
                            state: {
                                machine: device.username,
                            },
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        기기
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt5}`}
                    onClick={() =>{
                        location.state = {
                            machine: device.username,
                            machineId: device.id,
                        }

                        history.push({
                            pathname: '/member/list/',
                            state: location.state,
                            search: `?order=name_asc&page=1&q=`,
                        })
                    }}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        회원
                    </p>
                </div>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mt5}`}
                    onClick={() =>{
                        history.push({
                            pathname: '/entrance/edit/',
                            state: {
                                machine: device.username,
                            }
                        })
                    }}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        출입
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Device;
