import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import TableDragSelect from "react-table-drag-select";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";

const CREATE = gql`
  mutation createLockerArea($machine: String!, $name: String!, $lockers: String!, $isDisplay: Boolean) {
    createLockerArea(machine: $machine, name: $name, lockers: $lockers, isDisplay: $isDisplay) {
      lockerArea {
        id
        name
        isDisplay
      }
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
    }
  }
`;

const LockerAdd = () => {
  const EMPTY_LOCKER = [
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
  ];

  const history = useHistory();
  const location = useLocation();

  const name = useInput("");

  const [step, setStep] = useState(1);
  const [locker, setLocker] = useState([...EMPTY_LOCKER]);
  const [lockerNumbers, setLockerNumbers] = useState([]);
  const [arrange, setArrange] = useState("horizontal");
  const [loading, setLoading] = useState(false);
  const [showIsDisplay, setShowIsDisplay] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [createLockerAreaM] = useMutation(CREATE);

  const handleIsDisplay = (isDisplay) => {
    setIsDisplay(isDisplay);
    setShowIsDisplay(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (step === 1) {
        let check = false;
        for (const row of locker) {
          for (const col of row) {
            if (col) {
              check = true;
            }
          }
        }
        if (!check) {
          alert("사용하실 락커를 선택해주세요.");
          return;
        }
        arrangeLockerNumber(arrange);
        setStep(2);
      } else if (step === 2) {
        if (!name.value) {
          alert("락커 구역을 입력해주세요.");
          return;
        }
        setLoading(true);
        let check = true;
        for (const row of lockerNumbers) {
          for (const col of row) {
            if (col.enabled && !col.number) {
              check = false;
            }
            const findIndex = lockerNumbers.findIndex((r) => {
              for (const c of r) {
                if (col.enabled && c.enabled && !(c.x === col.x && c.y === col.y) && c.number === col.number) {
                  return true;
                }
              }
              return false;
            });
            if (findIndex > -1) {
              check = false;
            }
          }
        }
        if (!check) {
          setLoading(false);
          alert("중복되는 락커번호 또는 번호가 입력되지 않은 락커가 있습니다.");
          return;
        }
        try {
          const { data } = await createLockerAreaM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              name: name.value,
              lockers: JSON.stringify(lockerNumbers),
              isDisplay,
            },
          });
          setLoading(false);
          if (data && data.createLockerArea.lockerArea) {
            alert("추가하였습니다.");
            initialize();
          } else if (data && data.createLockerArea.error) {
            alert(data.createLockerArea.error);
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const arrangeLockerNumber = (arrange) => {
    if (arrange === "horizontal") {
      let newLockerNumbers = [];
      let count = 1;
      for (var y = 0; y < locker.length; y++) {
        let row = [];
        for (var x = 0; x < locker[y].length; x++) {
          if (locker[y][x]) {
            row.push({
              x,
              y,
              enabled: true,
              number: count,
            });
            count++;
          } else {
            row.push({
              x,
              y,
              enabled: false,
              number: null,
            });
          }
        }
        newLockerNumbers.push(row);
      }
      setLockerNumbers(newLockerNumbers);
    } else if (arrange === "vertical") {
      let newLockerNumbers = [...EMPTY_LOCKER];
      let count = 1;
      for (var x = 0; x < 15; x++) {
        for (var y = 0; y < locker.length; y++) {
          if (locker[y][x]) {
            newLockerNumbers[y][x] = {
              x,
              y,
              enabled: true,
              number: count,
            };
            count++;
          } else {
            newLockerNumbers[y][x] = {
              x,
              y,
              enabled: false,
              number: null,
            };
          }
        }
      }
      setLockerNumbers(newLockerNumbers);
    }
  };

  const onChangeNumber = (e, x, y) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      let newLockerNumbers = [...lockerNumbers];
      newLockerNumbers[y][x] = {
        ...newLockerNumbers[y][x],
        number: parseInt(value),
      };
      setLockerNumbers(newLockerNumbers);
    }
  };

  const initializeStep2 = () => {
    name.setValue("");
    setArrange("horizontal");
    arrangeLockerNumber("horizontal");
  };

  const initialize = () => {
    name.setValue("");
    setStep(1);
    setLocker([...EMPTY_LOCKER]);
    setLockerNumbers([]);
    setArrange("horizontal");
    setLoading(false);
  };

  useEffect(() => {
    arrangeLockerNumber(arrange);
  }, [arrange]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`락커 구역 추가${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div style={{ width: 1250 }}>
                <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.my10}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                    style={{ width: 1250, borderBottomLeftRadius: showIsDisplay ? 0 : 10, borderBottomRightRadius: showIsDisplay ? 0 : 10 }}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>전시 상태*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`} onClick={() => setShowIsDisplay(!showIsDisplay)}>
                      <p className={`${styles.font16} ${styles.black}`}>{isDisplay ? "전시 중" : "전시 중단"}</p>
                      <img src={showIsDisplay ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 분류 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showIsDisplay && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 1250 }}>
                        <div className={`${styles.borderRightGrayA2} ${styles.px20}`} style={{ width: 190 }}></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleIsDisplay(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>전시 중</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleIsDisplay(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>전시 중단</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 구역*</p>
                  </div>
                  <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"락커 구역을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                {step === 1 && (
                  <div>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 배치 등록 (락커의 구조에 맞게 클릭 및 드래그하여 선택해주세요.)</p>
                    <div className={`${styles.py30} ${styles.center}`}>
                      <TableDragSelect value={locker} onChange={(cells) => setLocker(cells)}>
                        {locker.map((row, index) => {
                          return (
                            <tr key={index}>
                              {row.map((_, i) => {
                                return <td key={`${index}-${i}`} />;
                              })}
                            </tr>
                          );
                        })}
                      </TableDragSelect>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 번호 등록 (직접 번호를 수정할 수 있습니다.)</p>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                        <p className={`${styles.fontB} ${styles.font13} ${arrange === "horizontal" ? styles.orange : styles.grayC4} ${styles.cursorPointer}`} onClick={() => setArrange("horizontal")}>
                          번호 가로정렬
                        </p>
                        <p className={`${styles.fontB} ${styles.font13} ${arrange === "vertical" ? styles.orange : styles.grayC4} ${styles.ml10} ${styles.cursorPointer}`} onClick={() => setArrange("vertical")}>
                          번호 세로정렬
                        </p>
                        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.center} ${styles.ml10} ${styles.cursorPointer}`} onClick={initializeStep2}>
                          <p className={`${styles.fontB} ${styles.font13} ${styles.white}`}>초기화</p>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles.py30} ${styles.center}`}>
                      <table>
                        <tbody>
                          {lockerNumbers.map((row, y) => {
                            return (
                              <tr key={y}>
                                {row.map((col, x) => {
                                  return (
                                    <td
                                      key={`${y}-${x}`}
                                      style={{
                                        width: 1206 / 15 - 2,
                                        height: 1206 / 15 - 2,
                                        borderRadius: 5,
                                        cursor: "pointer",
                                        backgroundColor: col.enabled ? "#ff7700" : "#D8D8D8",
                                      }}
                                    >
                                      <input
                                        type={"tel"}
                                        name={`${y}-${x}`}
                                        value={col.number}
                                        onChange={(e) => onChangeNumber(e, x, y)}
                                        className={`${styles.inputTransparent} ${styles.textCenter} ${styles.fontB} ${styles.white} ${styles.widthFull}`}
                                        style={{
                                          fontSize: 33,
                                          padding: 0,
                                        }}
                                        readOnly={!col.enabled}
                                      />
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>{step === 1 ? "다음" : "완료"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerAdd;
