import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import DaumPostcode from "react-daum-postcode";
import Modal from "react-modal";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import cache from "../../../apollo/cache";
import { numberReg } from "../../../common/regex";
import { bill, billSm } from "../../../common/modal";
import MembershipHistory from "../../../components/Member/Mobile/MembershipHistory";
import ActiveMemberships from "../../../components/Member/Mobile/ActiveMemberships";
import { numberWithCommas } from "../../../common/utility";
import axios from "axios";
import { FETCH_URL } from "../../../config";
import Calendar from "react-calendar";
import AddEntrance from "../../../components/Member/AddEntrance";
import EXIF from "exif-js";

const EDIT = gql`
  mutation editMember(
    $machine: String!
    $memberId: Int!
    $name: String!
    $mobile: String!
    $birth: String
    $gender: String
    $address: String
    $extraAddress: String
    $addressType: String
    $sido: String
    $sigungu: String
    $bname: String
    $purpose: String
    $extra: String
    $profileImage: String
    $profileOrientation: Int
  ) {
    editMember(
      machine: $machine
      memberId: $memberId
      name: $name
      mobile: $mobile
      birth: $birth
      gender: $gender
      address: $address
      extraAddress: $extraAddress
      addressType: $addressType
      sido: $sido
      sigungu: $sigungu
      bname: $bname
      purpose: $purpose
      extra: $extra
      profileImage: $profileImage
      profileOrientation: $profileOrientation
    ) {
      member {
        id
        name
        mobile
        birth
        gender
        address
        extraAddress
        sido
        sigungu
        bname
        addressType
        purpose
        extra
        profileImage
        profileOrientation
        createdAt
        defaultMembership {
          id
          product {
            id
            name
            membershipType
          }
          payment {
            id
            totalAmount
            approvalDate
            approvalTime
          }
          start
          end
          status
          totalDeduct
          totalCancel
          isUsed
        }
        defaultMembershipId
        memberships {
          id
          product {
            id
            name
            membershipType
          }
          payment {
            id
            totalAmount
            approvalDate
            approvalTime
          }
          start
          end
          status
          totalDeduct
          totalCancel
          isUsed
        }
        lockers {
          id
          number
          lockerArea {
            id
            name
          }
          payment {
            id
            totalAmount
            approvalDate
            approvalTime
          }
          start
          end
          status
        }
      }
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
      entranceMachine
      deviceId
    }
  }
`;

const MEMBER = gql`
  query getMember($id: Int!, $machine: String!) {
    getMember(id: $id, machine: $machine) {
      id
      name
      mobile
      birth
      gender
      address
      extraAddress
      sido
      sigungu
      bname
      addressType
      purpose
      extra
      profileImage
      profileOrientation
      createdAt
      defaultMembership {
        id
        product {
          id
          name
          membershipType
          ignoreCancel
        }
        payment {
          id
          totalAmount
          approvalDate
          approvalTime
          type
        }
        start
        end
        membershipCount
        cancelCount
        status
        totalDeduct
        totalCancel
        isUsed
      }
      defaultMembershipId
      memberships {
        id
        product {
          id
          name
          membershipType
          ignoreCancel
        }
        payment {
          id
          totalAmount
          approvalDate
          approvalTime
          type
        }
        start
        end
        membershipCount
        cancelCount
        status
        totalDeduct
        totalCancel
        isUsed
        lastreservationend
      }
      lockers {
        id
        number
        lockerArea {
          id
          name
        }
        payment {
          id
          totalAmount
          approvalDate
          approvalTime
          type
        }
        start
        end
        status
      }
    }
  }
`;

const DELETE = gql`
  mutation deleteMember($machine: String!, $memberId: Int!, $machineId: Int) {
    deleteMember(machine: $machine, memberId: $memberId, machineId: $machineId)
  }
`;

const REMOVE_MEMBERSHIP = gql`
  mutation removeMembership($machine: String!, $membershipId: Int!) {
    removeMembership(machine: $machine, membershipId: $membershipId)
  }
`;

const EDIT_MEMBERSHIP = gql`
  mutation editMembership($machine: String!, $membershipId: Int!, $productId: Int!, $start: String, $end: String, $membershipCount: Int, $cancelCount: Int, $totalAmount: Int, $approvalDate: String, $approvalTime: String, $userId: Int!) {
    editMembership(machine: $machine, membershipId: $membershipId, productId: $productId, start: $start, end: $end, membershipCount: $membershipCount, cancelCount: $cancelCount, totalAmount: $totalAmount, approvalDate: $approvalDate, approvalTime: $approvalTime, userId: $userId) {
      success
      error
    }
  }
`;

const ADD_MEMBERSHIP = gql`
  mutation addMembership($machine: String!, $userId: Int!, $productId: Int!, $start: String, $end: String, $membershipCount: Int, $cancelCount: Int, $totalAmount: Int, $approvalDate: String, $approvalTime: String, $isDefault: Boolean!, $machineId: Int) {
    addMembership(machine: $machine, userId: $userId, productId: $productId, start: $start, end: $end, membershipCount: $membershipCount, cancelCount: $cancelCount, totalAmount: $totalAmount, approvalDate: $approvalDate, approvalTime: $approvalTime, isDefault: $isDefault, machineId: $machineId) {
      success
      error
    }
  }
`;

const CHANGE_DEFAULT = gql`
  mutation changeDefaultMembership($userId: Int!, $membershipId: Int!) {
    changeDefaultMembership(userId: $userId, membershipId: $membershipId) {
      member {
        id
        name
        mobile
        birth
        gender
        address
        extraAddress
        sido
        sigungu
        bname
        addressType
        purpose
        extra
        createdAt
        defaultMembership {
          id
          product {
            id
            name
            membershipType
          }
          start
          end
          status
        }
        defaultMembershipId
        memberships {
          id
          product {
            id
            name
            membershipType
          }
          payment {
            id
            totalAmount
            approvalDate
            approvalTime
          }
          start
          end
          membershipCount
          cancelCount
          status
          totalDeduct
          totalCancel
        }
      }
      error
    }
  }
`;

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      duration
      membershipType
      membership
      cancel
      ignoreCancel
    }
  }
`;

const MemberEdit = () => {
  const history = useHistory();
  const location = useLocation();

  const profileImageRef = useRef();

  const name = useInput("");
  const mobile = useInput("");
  const birth = useInput("");
  const extraAddress = useInput("");
  const purpose = useInput("");
  const extra = useInput("");

  const [showAddEntranceModal, setShowAddEntranceModal] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [profileOrientation, setProfileOrientation] = useState(1);
  const [imgModify, setImgModify] = useState(false);
  const [rotate, setRotate] = useState("");
  const [previewProfileImage, setPreviewProfileImage] = useState("");
  const [gender, setGender] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [address, setAddress] = useState({
    address: "",
    addressType: "",
    sido: "",
    sigungu: "",
    bname: "",
    extraAddress: "", // 엑셀 일괄 업로드시 중복으로 들어가는 데이터때문에 사용
  });
  const [showAddress, setShowAddress] = useState(false);
  const [showDaum, setShowDaum] = useState(false);
  const [showMembershipHistory, setShowMembershipHistory] = useState(false);
  const [addMembership, setAddMembership] = useState(false);
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState();

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: resMember, refetch } = useQuery(MEMBER, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: location.state.member.id ? location.state.member.id : -1,
      machine: location.state.machine ? location.state.machine : "",
    },
    onError: () => {
      alert("회원이 존재하지 않습니다.");
      history.goBack();
    },
  });

  const [showMembership, setShowMembership] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [showMembershipCount, setShowMembershipCount] = useState(false);

  const [membership, setMembership] = useState({
    id: -1,
    product: {
      id: -1,
      name: "",
    },
    payment: {
      id: -1,
      totalAmount: "",
      approvalDate: "",
      approvalTime: "",
    },
    start: null,
    end: null,
    status: "",
  });
  const [editProduct, setEditProduct] = useState({
    id: -1,
    name: "",
  });
  const [editStart, setEditStart] = useState(null);
  const [editEnd, setEditEnd] = useState(null);
  // const [editMembershipCount, setEditMembershipCount] = useState(0);
  // const [editCancelCount, setEditCancelCount] = useState(0);
  const price = useInput("");
  const date = useInput("");
  const editMembershipCount = useInput("");
  const editCancelCount = useInput("");

  const [calcMembershipCount, setCalcMembershipCount] = useState(0);
  const [calcCancelCount, setCalcCancelCount] = useState(0);

  const [editMemberM] = useMutation(EDIT);
  const [deleteMemberM] = useMutation(DELETE);
  const [removeMembershipM] = useMutation(REMOVE_MEMBERSHIP);
  const [editMembershipM] = useMutation(EDIT_MEMBERSHIP);
  const [addMembershipM] = useMutation(ADD_MEMBERSHIP);
  const [changeDefaultMembershipM] = useMutation(CHANGE_DEFAULT);

  const handleMobile = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      mobile.setValue(value);
    }
  };

  const handleBirth = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      birth.setValue(value);
    }
  };

  const handleGender = (value) => {
    setGender(value);
    setShowGender(false);
  };

  const handleAddress = (data) => {
    setAddress({
      address: data.address,
      addressType: data.addressType,
      sido: data.sido,
      sigungu: data.sigungu,
      bname: data.bname,
      extraAddress: "",
    });
    setShowDaum(false);
  };

  const handleAddMembership = (bool) => {
    if (bool) {
      setEditProduct({
        id: -1,
        name: "",
      });
      editMembershipCount.setValue(0);
      editCancelCount.setValue(0);
      date.setValue(null);
      price.setValue(null);
    }
    setAddMembership(bool);
  };

  const handleMembership = (product) => {
    console.log("product.duration M ::::: ", product.duration);
    if (product.membershipType === 2 && product.membershipType === 3) {
      setCalcMembershipCount(product.membership);
      setCalcCancelCount(product.cancel);
      membership.membershipCount = product.membership;
      membership.cancelCount = product.cancel;
    }

    // setMembership({
    //     ...membership,
    //     product,
    // });

    if (editStart && product.duration) {
      setEditEnd(new Date(editStart.getTime() + 1000 * 60 * 60 * 24 * (product.duration - 1)));
    }

    setEditProduct(product);
    editMembershipCount.setValue(product.membership ?? "");
    editCancelCount.setValue(product.cancel ?? "");
    setShowMembership(false);
  };

  const handleStart = (start) => {
    console.log("start :::::: ", start);
    if (editProduct.duration) {
      setEditStart(start);
      setEditEnd(new Date(start.getTime() + 1000 * 60 * 60 * 24 * (editProduct.duration - 1)));
    } else {
      setEditStart(start);
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setEditEnd(end);
    setShowEnd(false);
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      console.log(value);
      editMembershipCount.setValue(value);
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      editCancelCount.setValue(value);
    }
  };

  const handlePrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      price.setValue(value);
    }
  };

  const handleDate = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      date.setValue(value);
    }
  };

  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine: location.state.machine,
      machineId: location.state.machineId ? location.state.machineId : null,
    },
    fetchPolicy: "cache-and-network",
  });

  const newMembership = async () => {
    if (editProduct.id === -1) {
      alert("회원권명을 선택해주세요.");
      return;
    }

    if (editProduct.membershipType !== 2) {
      if (!editStart) {
        alert("회원권 시작일을 선택해주세요.");
        return;
      }
    }

    // if (editProduct.membershipType !== 1) {
    //     if(!editMembershipCount) {
    //         alert('회원권 횟수를 선택해주세요.');
    //         return;
    //     }
    //     if(!editCancelCount) {
    //         alert('회원권 취소 가능 횟수를 선택해주세요.');
    //         return;
    //     }
    // }

    let approvalDate = date.value ? date.value.substring(0, 6) : "";
    let approvalTime = date.value ? `${date.value.substring(6, 10)}00` : "";

    if (date.value && date.value.length !== 10) {
      alert("올바른 결제일시를 입력해주세요.");
      return;
    }

    await createMembership({
      ...membership,
      product: editProduct,
      start: editStart,
      end: editEnd,
      payment: {
        id: -1,
        totalAmount: Number(price.value),
        approvalDate: approvalDate,
        approvalTime: approvalTime,
      },
      membershipCount: Number(editMembershipCount.value),
      cancelCount: Number(editCancelCount.value),
      isDefault: false,
    });
  };

  const removeMembership = async (membership) => {
    if (!loading && membership) {
      const confirm = window.confirm("정말 삭제하시겠습니까?");
      if (confirm) {
        setLoading(true);
        try {
          const { data } = await removeMembershipM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              membershipId: membership.id,
            },
          });
          if (data && data.removeMembership) {
            await refetch();
            setLoading(false);
            alert("삭제하였습니다.");
          } else {
            setLoading(false);
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const editMembership = async (membership) => {
    if (!loading && membership) {
      console.log("membership ::::: ", membership);
      console.log("membership.product ::::: ", membership.product);
      let tmpPrice = membership.price ? membership.price : membership.payment ? membership.payment.totalAmount : null;
      let tmpApprovalDate = membership.date ? membership.date.substring(0, 6) : membership.payment ? membership.payment.approvalDate : null;
      let tmpApprovalTime = membership.date ? membership.date.substring(6, 10) + "00" : membership.payment ? membership.payment.approvalTime : null;

      if (membership.product.membershipType !== 2) {
        if (!membership.start) {
          alert("시작일을 선택해주세요.");
          return;
        }
        if (!membership.end) {
          alert("종료일을 선택해주세요.");
          return;
        }
      }
      if (membership.product.membershipType === 2 || membership.product.membershipType === 3) {
        if (!membership.membershipCount) {
          membership.membershipCount = membership.product.membership;
        }
        if (!membership.cancelCount) {
          membership.cancelCount = membership.product.cancel;
        }
      }

      setLoading(true);

      console.log("membership.start ::::: ", membership.start);
      console.log("membership.end ::::: ", membership.end);

      try {
        const { data } = await editMembershipM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            membershipId: membership.id,
            productId: membership.product.id,
            start: membership.start ? `${membership.start.getTime()}` : null,
            end: membership.end ? `${membership.end.getTime()}` : null,
            membershipCount: membership.membershipCount ? Number(membership.membershipCount) : Number(membership.product.membership),
            cancelCount: membership.cancelCount ? Number(membership.cancelCount) : Number(membership.product.cancel),
            totalAmount: parseInt(tmpPrice),
            approvalDate: tmpApprovalDate,
            approvalTime: tmpApprovalTime,
            userId: member.getMember.id,
          },
        });
        if (data && data.editMembership.success) {
          await refetch();
          setLoading(false);
          alert("변경하였습니다.");
          return true;
        } else if (data && data.editMembership.error) {
          setLoading(false);
          alert(data.editMembership.error);
          return false;
        } else {
          setLoading(false);
          alert("오류가 발생하였습니다.");
          return false;
        }
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
        return false;
      }
    }
  };

  const createMembership = async (membership) => {
    let tempPrice = price.value === "" ? null : price.value;
    let tmpDate = date.value === "" ? null : date.value;

    if ((tempPrice === null && tmpDate !== null) || (tempPrice !== null && tmpDate === null)) {
      alert("결제금액과 결제일시는 같이 입력되어야 합니다.");
      return;
    }

    if (!loading && membership && member && member.getMember) {
      if (membership.product.id) {
        setLoading(true);
        try {
          const { data } = await addMembershipM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              machineId: location.state.machineId ? location.state.machineId : null,
              userId: member.getMember.id,
              productId: membership.product.id,
              start: membership.start ? `${membership.start.getTime()}` : null,
              end: membership.end ? `${membership.end.getTime()}` : null,
              totalAmount: membership.payment.totalAmount,
              membershipCount: membership.membershipCount ? Number(membership.membershipCount) : null,
              cancelCount: membership.cancelCount ? Number(membership.cancelCount) : null,
              approvalDate: membership.payment.approvalDate,
              approvalTime: membership.payment.approvalTime,
              isDefault: membership.isDefault,
            },
          });
          if (data && data.addMembership.success) {
            await refetch();
            setAddMembership(false);
            setLoading(false);
            alert("추가하였습니다.");
          } else if (data && data.addMembership.error) {
            setLoading(false);
            alert(data.addMembership.error);
          } else {
            setLoading(false);
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      } else {
        alert("회원권명을 선택해주세요.");
      }
    }
  };

  const changeDefaultMembership = async (membershipId) => {
    if (!loading && membershipId && member && member.getMember) {
      const confirm = window.confirm("대표 회원권을 변경하시겠습니까?");
      if (confirm) {
        setLoading(true);
        try {
          const { data } = await changeDefaultMembershipM({
            variables: {
              userId: member.getMember.id,
              membershipId,
            },
          });
          setLoading(false);
          if (data && data.changeDefaultMembership.member) {
            alert("변경하였습니다.");
          } else if (data && data.changeDefaultMembership.error) {
            alert(data.changeDefaultMembership.error);
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (member && member.getMember && name.value && mobile.value) {
        if (mobile.value.length !== 8) {
          alert("전화번호 11자리를 입력해주세요");
          return;
        }
        setLoading(true);

        try {
          let uploadResult = await uploadProfileImage();

          if (uploadResult.result === "ok") {
            const { data: result } = await editMemberM({
              variables: {
                machine: location.state.machine ? location.state.machine : "",
                memberId: location.state.member.id ? location.state.member.id : -1,
                name: name.value,
                mobile: mobile.value,
                birth: birth.value,
                gender,
                address: address.address,
                extraAddress: extraAddress.value,
                addressType: address.addressType,
                sido: address.sido,
                sigungu: address.sigungu,
                bname: address.bname,
                purpose: purpose.value,
                extra: extra.value,
                profileImage: uploadResult.profileImage,
                profileOrientation: profileOrientation,
              },
            });
            if (result && result.editMember) {
              if (result.editMember.error) {
                setLoading(false);
                alert(result.editMember.error);
              } else {
                setLoading(false);
                alert("회원을 수정하였습니다.");
                initialize(result.editMember.member);
              }
            } else {
              setLoading(false);
              alert("오류가 발생하였습니다.");
            }
          } else {
            setLoading(false);
            alert("오류가 발생하였습니다.");
          }
        } catch {
          setLoading(false);
          alert("오류가 발생하였습니다.");
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  const remove = async () => {
    if (!loading) {
      if (member && member.getMember) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data } = await deleteMemberM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              memberId: member.getMember.id,
              machineId: location.state.machineId ? location.state.machineId : null,
            },
          });
          setLoading(false);
          if (data && data.deleteMember) {
            alert("회원을 삭제하였습니다.");
            cache.evict({
              id: `Member:${member.getMember.id}`,
            });
            history.goBack();
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const initialize = (newMember) => {
    if (newMember) {
      let now = new Date();
      let today = now.getUTCFullYear();
      today += "-";
      today += now.getUTCMonth() + 1 < 10 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
      today += "-";
      today += now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
      for (let i = 0; i < member.getMember.memberships.length; i++) {
        let lastScheduleEnd = new Date(member.getMember.memberships[i].lastreservationend);
        if (member.getMember.memberships[i].lastreservationend && member.getMember.memberships[i].lastreservationend >= member.getMember.memberships[i].end) {
          member.getMember.memberships[i].end = lastScheduleEnd.getTime() + "";
        }
        if (member.getMember.memberships[i].lastreservationend && member.getMember.memberships[i].lastreservationend >= today) {
          member.getMember.memberships[i].status = "active";
        }
      }

      name.setValue(newMember.name);
      mobile.setValue(newMember.mobile);
      birth.setValue(newMember.birth ? newMember.birth : "");
      extraAddress.setValue(newMember.extraAddress ? newMember.extraAddress : "");
      purpose.setValue(newMember.purpose ? newMember.purpose : "");
      extra.setValue(newMember.extra ? newMember.extra : "");
      setGender(newMember.gender ? newMember.gender : "");
      setShowGender(false);
      setAddress({
        address: newMember.address ? newMember.address : "",
        addressType: newMember.addressType ? newMember.addressType : "",
        sido: newMember.sido ? newMember.sido : "",
        sigungu: newMember.sigungu ? newMember.sigungu : "",
        bname: newMember.bname ? newMember.bname : "",
        extraAddress: newMember.extraAddress ? newMember.extraAddress : "",
      });
      setPreviewProfileImage(newMember.profileImage ? newMember.profileImage : "");
      setImgModify(false);
      setRotate(imageSort(profileOrientation));
      setProfileOrientation(1);
      setProfileOrientation(newMember.profileOrientation ? newMember.profileOrientation : 1);
      setShowAddress(false);
      setShowDaum(false);
      setLoading(false);
    } else if (member && member.getMember) {
      let now = new Date();
      let today = now.getUTCFullYear();
      today += "-";
      today += now.getUTCMonth() + 1 < 10 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
      today += "-";
      today += now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
      for (let i = 0; i < member.getMember.memberships.length; i++) {
        let lastScheduleEnd = new Date(member.getMember.memberships[i].lastreservationend);
        if (member.getMember.memberships[i].lastreservationend && member.getMember.memberships[i].lastreservationend >= member.getMember.memberships[i].end) {
          member.getMember.memberships[i].end = lastScheduleEnd.getTime() + "";
        }
        if (member.getMember.memberships[i].lastreservationend && member.getMember.memberships[i].lastreservationend >= today) {
          member.getMember.memberships[i].status = "active";
        }
      }

      name.setValue(member.getMember.name);
      mobile.setValue(member.getMember.mobile);
      birth.setValue(member.getMember.birth ? member.getMember.birth : "");
      extraAddress.setValue(member.getMember.extraAddress ? member.getMember.extraAddress : "");
      purpose.setValue(member.getMember.purpose ? member.getMember.purpose : "");
      extra.setValue(member.getMember.extra ? member.getMember.extra : "");
      setGender(member.getMember.gender ? member.getMember.gender : "");
      setShowGender(false);
      setAddress({
        address: member.getMember.address ? member.getMember.address : "",
        addressType: member.getMember.addressType ? member.getMember.addressType : "",
        sido: member.getMember.sido ? member.getMember.sido : "",
        sigungu: member.getMember.sigungu ? member.getMember.sigungu : "",
        bname: member.getMember.bname ? member.getMember.bname : "",
        extraAddress: member.getMember.extraAddress ? member.getMember.extraAddress : "",
      });
      setPreviewProfileImage(member.getMember.profileImage ? member.getMember.profileImage : "");
      setProfileOrientation(member.getMember.profileOrientation ? member.getMember.profileOrientation : 1);
      setShowAddress(false);
      setShowDaum(false);
      setLoading(false);
    }

    console.log(member.getMember.memberships);
  };

  const uploadProfileImage = async () => {
    if (!profileImage) {
      return {
        result: "ok",
        profileImage: previewProfileImage,
      };
    }

    const formData = new FormData();
    const resizedImage = await resizeImage(profileImage);
    formData.append("excel", resizedImage, profileImage.name);
    const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (data && data.location) {
      return {
        result: "ok",
        profileImage: data.location,
      };
    } else {
      return {
        result: "fail",
        profileImage: "",
      };
    }
  };

  const delProfileImageFile = () => {
    profileImageRef.current.value = null;
    setProfileImage(null);
    setPreviewProfileImage("");
    setProfileOrientation(1);
  };

  //이미지 정방향 정렬
  const imageSort = (orientation) => {
    let rotate = "";

    if (!imgModify) {
      switch (orientation) {
        // @details 이미지 회전값이 0인경우 ( 정방향 )
        case 1:
          rotate = "rotate( 0deg )";
          break;

        // @details 이미지 회전값이 180 기운 경우
        case 3:
          rotate = "rotate( 180deg )";
          break;

        // @details 이미지 회전값이 270 기운 경우 ( 왼쪽으로 90 기운 경우 )
        case 6:
          rotate = "rotate( 90deg )";
          break;

        // @details 이미지 회전값이 90 기운 경우
        case 8:
          rotate = "rotate( 270deg )";
          break;
      }
    }

    return rotate;
  };

  useEffect(() => {
    let newRotate = imageSort(profileOrientation);
    setRotate(newRotate);
  }, [profileOrientation]);

  useEffect(() => {
    setShowDaum(false);
  }, [showAddress]);

  useEffect(() => {
    if (resMember && resMember.getMember) {
      setMember(JSON.parse(JSON.stringify(resMember)));
    }
  }, [resMember]);

  useEffect(() => {
    if (member && member.getMember) {
      initialize();
    }
  }, [member]);

  useEffect(async () => {
    if (loading) {
      if (profileImage) {
        await EXIF.getData(profileImage, async function () {
          setProfileOrientation(0);
          let curOrientation = await EXIF.getTag(this, "Orientation");
          console.log(profileOrientation);
          setProfileOrientation(curOrientation);
        });

        let reader = new FileReader();
        reader.onloadend = () => {
          setPreviewProfileImage(reader.result);
        };
        reader.readAsDataURL(profileImage);
      } else {
        setPreviewProfileImage("");
        setProfileOrientation(1);
      }

      setLoading(false);
    }
  }, [profileImage]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`회원 수정${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt40}`}>
            <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.mr10} ${styles.cursorPointer}`} onClick={() => setShowAddEntranceModal(true)}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>출입 인증 수단 변경</p>
            </div>
            {member && member.getMember.memberships.length > 0 && (
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px30} ${styles.py10} ${styles.cursorPointer}`} onClick={() => setShowMembershipHistory(true)}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>만료 회원권 내역</p>
                </div>
              </div>
            )}
          </div>
          <form action={""} onSubmit={submit} className={`${member && member.getMember.memberships.length > 0 ? styles.mt15 : styles.mt40} ${styles.px20}`}>
            <div>
              <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.mt10}`}>
              <div
                className={`${styles.flex2} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.textCenter} ${styles.center}`}
                style={{
                  height: 250,
                }}
              >
                {previewProfileImage.length > 0 ? (
                  <img
                    style={{
                      height: 250,
                      objectFit: "cover",
                      objectPosition: "center",
                      transform: rotate,
                    }}
                    src={previewProfileImage}
                  />
                ) : (
                  <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>사진</p>
                )}
                <input
                  ref={profileImageRef}
                  id={"profile_image"}
                  className={`${styles.none}`}
                  type={"file"}
                  accept={"image/*"}
                  onChange={(e) => {
                    setLoading(true);
                    setImgModify(true);
                    setProfileImage(e.target.files[0]);
                  }}
                />
              </div>
              <div className={`${styles.px15} ${styles.flex1}`} style={{ display: "table" }}>
                <div
                  style={{
                    display: "table-cell",
                    flex: 1,
                    verticalAlign: "middle",
                  }}
                >
                  <label className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter} ${styles.mb20}`} htmlFor={"profile_image"} style={{ display: "block" }}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>변경</p>
                  </label>
                  <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.textCenter}`} onClick={delProfileImageFile}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원명*</p>
              </div>
              <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"회원명을 입력해주세요."} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원 연락처*</p>
              </div>
              <NumberFormat value={mobile.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} type={"tel"} displayType={"input"} onValueChange={handleMobile} placeholder={"회원 연락처를 입력해주세요."} format={"010-####-####"} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>생년월일</p>
              </div>
              <NumberFormat value={birth.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} type={"tel"} displayType={"input"} onValueChange={handleBirth} placeholder={"회원의 생년월일을 입력해주세요.(YYMMDD)"} format={"######"} />
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showGender ? 0 : 10,
                  borderBottomRightRadius: showGender ? 0 : 10,
                }}
                onClick={() => setShowGender(!showGender)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>성별</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input type={"text"} value={gender === "male" || gender === "female" ? (gender === "male" ? "남성" : "여성") : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"회원의 성별을 선택해주세요."} readOnly={true} />
                  <img src={showGender ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"성별 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showGender && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    <div className={`${styles.py10} ${styles.flex1}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("male")}>
                        <p className={`${styles.font14} ${styles.black}`}>남성</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleGender("female")}>
                        <p className={`${styles.font14} ${styles.black}`}>여성</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showAddress ? 0 : 10,
                  borderBottomRightRadius: showAddress ? 0 : 10,
                }}
                onClick={() => setShowAddress(!showAddress)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원 주소</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input
                    type={"text"}
                    value={address.address ? `${address.address.replaceAll(address.extraAddress, "")} ${extraAddress.value}` : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15} `}
                    style={{ minWidth: 20 }}
                    placeholder={"회원의 주소지를 입력해주세요."}
                    readOnly={true}
                  />
                  {showAddress ? (
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.underline} ${styles.px15} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                      완료
                    </p>
                  ) : (
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.underline} ${styles.px15} ${styles.cursorPointer}`} onClick={() => setShowAddress(!showAddress)}>
                      변경
                    </p>
                  )}
                </div>
              </div>
              {showAddress && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    {showDaum ? (
                      <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                        <DaumPostcode onComplete={handleAddress} />
                      </div>
                    ) : (
                      <div className={`${styles.py10} ${styles.px15} ${styles.flex1}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>도 / 시</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.sido}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>시 / 군 / 구</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.sigungu}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.cursorPointer} ${styles.borderBottomGrayA2}`} onClick={() => setShowDaum(!showDaum)}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.mr15}`}>동 / 면 / 읍</p>
                          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1} ${styles.textAbbr}`}>{address.bname}</p>
                          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"주소 검색"} className={`${styles.icon15} ${styles.mt5} ${styles.ml20}`} />
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.py10} ${styles.borderBottomGrayA2}`}>
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상세주소</p>
                          <input type={"text"} name={"extraAddress"} value={extraAddress.value} onChange={extraAddress.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15} ${styles.textRight}`} style={{ minWidth: 50 }} placeholder={"상세주소를 입력해주세요."} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>운동 목적</p>
              </div>
              <input type={"text"} name={"purpose"} value={purpose.value} onChange={purpose.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"회원의 운동 목적을 입력해주세요."} />
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>비고</p>
              <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 120 }} placeholder={"비고"} maxLength={5000} />
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>유효 회원권 내역</p>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => handleAddMembership(true)}>
                  회원권 추가
                </p>
              </div>
              <ActiveMemberships
                machine={location.state.machine ? location.state.machine : ""}
                machineId={location.state.machineId ? location.state.machineId : null}
                isEntrance={machine && machine.getMachine ? machine.getMachine.entranceSystem !== "none" : false}
                memberships={member && member.getMember ? member.getMember.memberships : []}
                defaultMembershipId={member?.getMember.defaultMembershipId}
                removeMembership={removeMembership}
                editMembership={editMembership}
                addMembership={addMembership}
                createMembership={createMembership}
                changeDefaultMembership={changeDefaultMembership}
              />
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>락커</p>
              <div className={`${styles.bgWhite}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py10} ${styles.px15} ${styles.borderBottomGrayF4}`}>
                  <div className={`${styles.flex2}`}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용권명</p>
                  </div>
                  <div className={`${styles.flex2} ${styles.px5}`}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>시작일</p>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>종료일</p>
                  </div>
                  <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
                  </div>
                </div>
                <div
                  className={`${styles.bgWhite} ${styles.hideScroll}`}
                  style={{
                    overflowY: "scroll",
                    maxHeight: 400,
                  }}
                >
                  {member && member.getMember.lockers
                    ? member.getMember.lockers.map((locker, index) => {
                        const start = locker.start ? new Date(Number(locker.start)) : null;
                        const end = locker.end ? new Date(Number(locker.end)) : null;
                        return (
                          <div key={locker.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px15} ${styles.py15} ${index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf}`}>
                            <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex2}`}>{locker.lockerArea?.name}</p>
                            <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex2} ${styles.px5}`}>
                              {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : ""}
                              <br />
                              {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                            </p>
                            <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                              {locker.status === "inuse" && "사용중"}
                              {locker.status === "expired" && "만료"}
                              {locker.status === "available" && "사용가능"}
                            </p>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={remove}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal isOpen={showMembershipHistory} onRequestClose={() => setShowMembershipHistory(false)} style={billSm}>
        <MembershipHistory
          machine={location.state.machine ? location.state.machine : ""}
          machineId={location.state.machineId ? location.state.machineId : null}
          memberships={member && member.getMember ? member.getMember.memberships : []}
          close={() => setShowMembershipHistory(false)}
          removeMembership={removeMembership}
          editMembership={editMembership}
          loading={loading}
        />
      </Modal>

      <Modal isOpen={addMembership} onRequestClose={() => setAddMembership(false)} style={billSm}>
        <div style={{ textAlign: "right" }}>
          <img src={require("../../../assets/images/icon_close_menu.png").default} alt={"CLOSE"} className={`${styles.cursorPointer} ${styles.icon20}`} onClick={() => setAddMembership(false)} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowMembership(true)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권명</p>
          </div>
          <input type={"text"} value={editProduct.name} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"회원권을 선택해주세요."} readOnly={true} />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowStart([1, 3, 4].indexOf(editProduct.membershipType) > -1)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>시작일</p>
          </div>
          <input
            type={"text"}
            value={[1, 3, 4].indexOf(editProduct.membershipType) > -1 && editStart ? editStart.getFullYear() + "/" + (editStart.getMonth() + 1).toString().padStart(2, "0") + "/" + editStart.getDate().toString().padStart(2, "0") : ""}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
            style={{ minWidth: 20 }}
            placeholder={"시작일을 선택해주세요."}
            readOnly={true}
          />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"시작일 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowEnd([1, 3, 4].indexOf(editProduct.membershipType) > -1)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>종료일</p>
          </div>
          <input
            type={"text"}
            value={[1, 3, 4].indexOf(editProduct.membershipType) > -1 && editEnd ? editEnd.getFullYear() + "/" + (editEnd.getMonth() + 1).toString().padStart(2, "0") + "/" + editEnd.getDate().toString().padStart(2, "0") : ""}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
            style={{ minWidth: 20 }}
            placeholder={"종료일을 선택해주세요."}
            readOnly={true}
          />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"종료일 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        {(editProduct.membershipType === 2 || editProduct.membershipType === 3) && (
          <>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>횟수</p>
              </div>
              <NumberFormat
                value={editMembershipCount.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                type={"tel"}
                displayType={"input"}
                onValueChange={handleMembershipCount}
                placeholder={"횟수를 입력해주세요."}
                style={{
                  width: "100%",
                  minWidth: 20,
                }}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>취소 횟수</p>
              </div>
              {editProduct.ignoreCancel ? (
                <input
                  value={"무제한"}
                  readOnly={true}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                  placeholder={"취소 횟수를 입력해주세요."}
                  style={{
                    width: "100%",
                    minWidth: 20,
                  }}
                />
              ) : (
                <NumberFormat
                  value={editCancelCount.value}
                  thousandSeparator={true}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                  type={"tel"}
                  displayType={"input"}
                  onValueChange={handleCancelCount}
                  placeholder={"취소 횟수를 입력해주세요."}
                  style={{
                    width: "100%",
                    minWidth: 20,
                  }}
                />
              )}
            </div>
          </>
        )}
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제금액</p>
          </div>
          <NumberFormat
            value={price.value}
            thousandSeparator={true}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handlePrice}
            placeholder={"결제 금액을 입력해주세요. (숫자만 입력)"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
          />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제일시</p>
          </div>
          <NumberFormat
            value={date.value}
            thousandSeparator={true}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
            type={"tel"}
            displayType={"input"}
            onValueChange={handleDate}
            placeholder={"YYMMDD HH:MM"}
            format={"###### ##:##"}
            style={{
              width: "100%",
              minWidth: 20,
            }}
          />
        </div>
        <div className={`${styles.mt20}`}>
          <button type={"button"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} onClick={() => newMembership()}>
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
          </button>
          <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} onClick={() => setAddMembership(false)}>
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
          </div>
        </div>
      </Modal>

      <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={billSm}>
        <div
          className={`${styles.bgWhite} ${styles.hideScroll}`}
          style={{
            maxHeight: 300,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {data?.getMemberships
            ? data?.getMemberships.map((item) => {
                if (!(item.membershipType === 2 && machine && machine.getMachine ? machine.getMachine.entranceSystem !== "none" : false)) {
                  return (
                    <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                      <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                    </div>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
          <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
        </div>
      </Modal>

      <Modal isOpen={showStart} onRequestClose={() => setShowStart(false)} style={billSm}>
        <Calendar
          calendarType={"US"}
          value={editStart}
          maxDate={editEnd ? editEnd : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleStart}
        />
      </Modal>
      <Modal isOpen={showEnd} onRequestClose={() => setShowEnd(false)} style={billSm}>
        <Calendar
          calendarType={"US"}
          value={editEnd}
          minDate={editStart ? editStart : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleEnd}
        />
      </Modal>
      <AddEntrance machine={machine && machine.getMachine ? machine.getMachine : null} showModal={showAddEntranceModal} cancel={() => setShowAddEntranceModal(false)} addMemberType={"edit"} member={member && member.getMember ? member.getMember : null} setLoading={setLoading} />
    </div>
  );
};

export default MemberEdit;
