import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
            orderProductNotice
	        orderProductDeliveryNotice
        }
    }
`

const EDIT = gql`
    mutation editNotice($machine: String!, $orderProductNotice: String!, $orderProductDeliveryNotice: String!){
        editNotice(machine: $machine, orderProductNotice: $orderProductNotice, orderProductDeliveryNotice: $orderProductDeliveryNotice){
            id
            orderProductNotice
	        orderProductDeliveryNotice
        }
    }
`

const OrderProductNotice = () => {
    const history = useHistory()
    const location = useLocation()

    const productNotice = useInput("")
    const deliveryNotice = useInput("")

    const [loading, setLoading] = useState(false)

    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [editNoticeM] = useMutation(EDIT)

    const submit = async(e) => {
        e.preventDefault()
        if(!loading){
            setLoading(true)
            try{
                const { data:result } = await editNoticeM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        orderProductNotice: productNotice.value,
                        orderProductDeliveryNotice: deliveryNotice.value
                    }
                })
                setLoading(false)
                if(result && result.editNotice){
                    alert("안내 문구를 수정하였습니다.")
                }
                else{
                    alert("오류가 발생하였습니다.")
                }
            }
            catch{
                setLoading(false)
                alert("오류가 발생하였습니다.")
            }
        }
    }

    const initialize = () => {
        if(machine && machine.getMachine){
            productNotice.setValue(machine.getMachine.orderProductNotice)
            deliveryNotice.setValue(machine.getMachine.orderProductDeliveryNotice)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(machine && machine.getMachine){
            initialize()
        }
    }, [machine])

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다")
            history.goBack()
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} `}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`안내 문구 관리${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                    </p>
                    <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
                        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                주문 판매 상품 구매 시 주의사항 문구 (최대 150자)
                            </p>
                            <textarea 
                            name={"productNotice"}
                            value={productNotice.value}
                            onChange={productNotice.onChange}
                            className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                            style={{ height: 120 }}
                            placeholder={"문구를 입력해주세요."}
                            maxLength={50}
                            />
                        </div>
                        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                주문 판매 상품 수령 방법 안내 문구 (최대 150자)
                            </p>
                            <textarea 
                            name={"deliveryNotice"}
                            value={deliveryNotice.value}
                            onChange={deliveryNotice.onChange}
                            className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                            style={{ height: 120 }}
                            placeholder={"문구를 입력해주세요."}
                            maxLength={50}
                            />
                        </div>
                        <div className={`${styles.mt45}`}>
                            <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    저장
                                </p>
                            </button>
                            <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default OrderProductNotice;