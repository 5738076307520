import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Member = ({
    machine,
    member,
    showCheckbox,
    selectedMemberIds,
    setSelectedMemberIds
}) => {
    const history = useHistory()

    let checked = false;
    if (selectedMemberIds && showCheckbox) {
        for (let i = 0; i < selectedMemberIds.length; i++) {
            if (selectedMemberIds[i] === member.id) {
                checked = true;
                break;
            }
        }
    }
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`}>
            {showCheckbox && (
                <div
                    className={`${styles.mr20} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
                    onClick={() => {
                        let _selectedMemberId = [...selectedMemberIds]

                        if (checked) {
                            let idx = _selectedMemberId.indexOf(member.id)
                            if (idx > 0) {
                                _selectedMemberId.splice(idx, 1)
                            }
                        } else {
                            _selectedMemberId.push(member.id)
                        }

                        setSelectedMemberIds(_selectedMemberId)
                    }}>
                    {checked && <div
                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                        style={{ width: 14, height: 14 }}
                    />}
                </div>
            )}

            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => history.push({
                pathname: `/member/edit/`,
                state: {
                    machine: machine.username,
                    machineId: machine.id,
                    member
                }
            })}>
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                        {member.name}
                    </p>
                </div>
                <div className={`${styles.flex3}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline}`}>
                        {member.defaultMembership ? (
                            <span>
                            {(member.defaultMembership.status === "inactive") && "비활성"}
                                {(member.defaultMembership.status === "active") && "유효"}
                                {(member.defaultMembership.status === "ready") && "사용대기"}
                                {(member.defaultMembership.status === "expired") && "만료"}
                                {(member.defaultMembership.status === "holding") && "홀딩중"}
                        </span>
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Member;
