import React, { useEffect, useState, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

import styles from "../../../styles/styles.module.scss";

const tmpResult = {
  region_month: { "2022-11-01": 0.7805908302777331, "2022-12-01": 0.8097121469501094, "2023-01-01": 0.9997392729197838, "2023-02-01": 0.8062663052430015, "2023-03-01": 0.8095937726966229 },
  region_week: { "2022-11-01": 0.7805908302777331, "2022-12-01": 0.8097121469501094, "2023-01-01": 0.9997392729197838, "2023-02-01": 0.8062663052430015, "2023-03-01": 0.8095937726966229 },
  review_summary: "고기를 화덕에서 초벌한 후 솥뚜껑에서 구워 먹는 방식이며, 특별하지는 않지만 맛있음. 반찬은 셀프 서비스로 제공되며, 고기 자르기만 직원이 해주고 나머지는 자율적으로 익혀야 함. 매장은 깨끗하고 테이블이 널찍하여 편하게 식사할 수 있으며, 분위기는 무난하고 서비스도 좋음.",
  month_revenue: { "2022-11-01": 40000, "2022-12-01": 40000, "2023-01-01": 130000, "2023-02-01": 80000, "2023-03-01": 180000 },
  month_revenue_normal: { "2022-11-01": 0.0, "2022-12-01": 0.0, "2023-01-01": 0.6428571428571429, "2023-02-01": 0.2857142857142857, "2023-03-01": 1.0 },
  week_revenue: {
    "2022-11-06": 10000,
    "2022-11-13": 10000,
    "2022-11-20": 10000,
    "2022-11-27": 10000,
    "2022-12-04": 10000,
    "2022-12-11": 10000,
    "2022-12-18": 10000,
    "2022-12-25": 10000,
    "2023-01-01": 10000,
    "2023-01-08": 20000,
    "2023-01-15": 30000,
    "2023-01-22": 40000,
    "2023-01-29": 30000,
    "2023-02-05": 20000,
    "2023-02-12": 10000,
    "2023-02-19": 20000,
    "2023-02-26": 30000,
    "2023-03-04": 30000,
    "2023-03-11": 40000,
    "2023-03-18": 50000,
    "2023-03-25": 60000,
  },
  week_revenue_normal: {
    "2022-11-06": 0.0,
    "2022-11-13": 0.0,
    "2022-11-20": 0.0,
    "2022-11-27": 0.0,
    "2022-12-04": 0.0,
    "2022-12-11": 0.0,
    "2022-12-18": 0.0,
    "2022-12-25": 0.0,
    "2023-01-01": 0.0,
    "2023-01-08": 0.2,
    "2023-01-15": 0.4,
    "2023-01-22": 0.6,
    "2023-01-29": 0.4,
    "2023-02-05": 0.2,
    "2023-02-12": 0.0,
    "2023-02-19": 0.2,
    "2023-02-26": 0.4,
    "2023-03-04": 0.4,
    "2023-03-11": 0.6,
    "2023-03-18": 0.8,
    "2023-03-25": 1.0,
  },
  reviews: [
    "[ 잠실 고깃집 맛집 리뷰 ] English translation at the bottom\n\n1. 품질 & 맛\n고기를 화덕에 먼저 초벌하고 솥뚜껑 위에 구워먹는 시스템입니다. 엄청 특별한 맛은 아니지만, 맛있었습니다.\n\n2. 서비스 & 친절\n반찬은 셀프로 더 가져다먹을 수 있습니다. 고기 자르는 것까지만 직원이 해주고, 알아서 익혀 먹어야 합니다.\n\n3. 위생 & 분위기\n매장 위생은 전반적으로 깨끗했습니다.",
    "고기가 초벌돼 나오고 먹기 좋게 잘라주시기 때문에 편하게 먹을수 있습니다.\n고기도 맛있어요",
    "분위기도 괜찮고 맛도 무난한 고기집. 테이블이 상당히 커서 둘이서가면 되게 널널하고 4명이서 가도 충분한 넓이.\n굽느데 시간이 좀 걸리고 화덕에서 초벌 해주는데 맛이 막 뛰어나게 좋아진다라는 느낌이 들지는 않음. 평범했다.",
    "고기도 구워 주시고... 좀 지나서는 알아서 ㅎ\n일하시는 분들이 서비스도 좋으시고 전문가 같습니다.\n음식들도 맛있구요.\n좀 부산스럽기는 합니다.\n어쩌다 화장실 앞쪽에 앉았는데 나오는 분들과 아이컨택이 되는건 좀..\n화장실도 남여 입구가 하나라 좀..",
    "화덕이라 신기했고 낵아 먹어본 삼겹살중 탑3안에 들어감\n셀프바도 잘 되어있어서 눈치보지않고 갔다 먹을수있다.\n친절하고 분위기좋고 또 방문하고 싶다.",
  ],
  store_name: "예시 이름",
};

const ReportResult = () => {
  return <div></div>;
};

export default ReportResult;
