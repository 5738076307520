import React, {useEffect, useState} from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import LockerPayment from '../../../components/Locker/Mobile/LockerPayment';

const PAYMENT = gql`
    query getPayments($machine: String!, $order: String!, $skip: Int!, $target: String, $q: String){
        getPayments(type: "locker", machine: $machine, order: $order, skip: $skip, target: $target, q: $q){
            payments{
                id
                productName
                userName
                userContact
                totalAmount
                status
                issuerInfo
                installment
                approvalDate
                approvalTime
                startDate
                lockerNumber
            }
            total
        }
    }
`

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
        }
    }
`

const LockerProductsPaymentStatus = () => {
    const history = useHistory()
    const location = useLocation()

    const [showSearchTarget, setShowSearchTarget] = useState(false);
    const [searchTarget, setSearchTarget] = useState("total");

    const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "")

    const { data, loading:loadingPayment, refetch } = useQuery(PAYMENT, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0,
            machine: location.state.machine ? location.state.machine : "",
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            target: searchTarget,
            q: queryString.parse(location.search).q ? queryString.parse(location.search).q : ""
        }
    })

    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const handlePage = (page) => {
        history.push({
            pathname: `/locker/product/payment/status/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&target=${searchTarget}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
        })
    }

    const submit = async(e) => {
        e.preventDefault()
        history.push({
            pathname: `/locker/product/payment/status/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&target=${searchTarget}&q=${q.value}`
        })
    }

    const handleSearchTarget = status => {
        setSearchTarget(status);
        setShowSearchTarget(false);
    };

    useEffect(() => {
        q.value = "";
        console.log(q);
        history.push({
            pathname: `/locker/product/payment/status/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&target=${searchTarget}&q=`
        })
    },[searchTarget])

    useEffect(() => {
        if(location.state.machine){
            if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
                window.scrollTo(0,0)
                refetch({
                    skip: 10*(Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                    q: queryString.parse(location.search).q ? queryString.parse(location.search).q : ""
                })
                q.setValue(queryString.parse(location.search).q)
            }
            else{
                history.replace({
                    pathname: `/locker/product/payment/status/?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
                    state: location.state
                })
            }
        }
        else{
            alert("잘못된 접근입니다")
            history.goBack()
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`판매 현황${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                    </p>
                    <form action={""} onSubmit={submit} className={`${styles.mt45} ${styles.px40}`}>
                        <div className={`${styles.mb10}`}
                             style={{
                                 position: 'relative',
                             }}>
                            <div className={`${styles.row} ${styles.mx0}  ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.px20} ${styles.py10} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                 style={{
                                     position: 'relative',
                                     borderBottomLeftRadius: showSearchTarget ? 0 : 10,
                                     borderBottomRightRadius: showSearchTarget ? 0 : 10,
                                 }}
                                 onClick={() => {setShowSearchTarget(!showSearchTarget)}}>
                                <p className={`${styles.fontM} ${styles.font15} ${styles.black}`}>
                                    {searchTarget === 'total' &&
                                        '통합'}
                                    {searchTarget === 'buyer' &&
                                        '구매자'}
                                    {searchTarget === 'phoneNumber' &&
                                        '연락처'}
                                    {searchTarget === 'product' &&
                                        '이용권'}
                                    {searchTarget === 'amount' &&
                                        '금액'}
                                    {searchTarget === 'payment' &&
                                        '수단'}
                                    {searchTarget === 'lockerNumber' &&
                                        '락커번호'}
                                </p>
                                <img
                                    src={
                                        showSearchTarget ?
                                            require('../../../assets/images/icon_dropdown_up.png')
                                                .default
                                            : require('../../../assets/images/icon_dropdown_down.png')
                                                .default
                                    }
                                    alt={'selectSearchTarget'}
                                    className={`${styles.icon15} ${styles.cursorPointer} ${styles.mt5}`}
                                />
                            </div>
                            {showSearchTarget && (
                                <div
                                    className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden} ${styles.widthFull}`}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.flex1}`}>
                                        <div
                                            className={`${styles.py10} ${styles.flex1}`}>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget(
                                                        'total',
                                                    )
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    통합
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget('buyer')
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    구매자
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget(
                                                        'phoneNumber',
                                                    )
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    연락처
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget(
                                                        'product',
                                                    )
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    이용권
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget(
                                                        'amount',
                                                    )
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    금액
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget('payment')
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    수단
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                onClick={() =>
                                                    handleSearchTarget('lockerNumber')
                                                }>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    락커번호
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
                            <input
                            type={"text"}
                            name={"q"}
                            value={q.value}
                            onChange={q.onChange}
                            className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                            placeholder={"구매자, 연락처, 이용권, 금액, 수단, 락커번호 등 검색"}
                            />
                        </div>
                    </form>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                판매일시
                            </p>
                        </div>
                        <div className={`${styles.flex2} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                이용권명
                            </p>
                        </div>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                결제 금액
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingPayment ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            (data && data.getPayments && (data.getPayments.payments.length > 0)) ? (
                                data.getPayments.payments.map(payment => {
                                    return(
                                        <LockerPayment
                                        key={payment.id}
                                        payment={payment}
                                        machine={machine && machine.getMachine ? machine.getMachine.name : ''}
                                        />
                                    )
                                })
                            ) : (
                                <div className={`${styles.center}`} style={{ height: 600 }}>
                                    <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        판매 내역이 없습니다.
                                    </p>
                                </div>
                            )
                        )}
                        {(data?.getPayments?.total > 10) ? (
                            <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(data?.getPayments?.total) ? parseInt((data?.getPayments?.total - 1)/10) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingPayment ? null : handlePage}
                                // initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                forcePage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingPayment && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LockerProductsPaymentStatus;
