import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import MembershipProduct from "../../../components/Membership/Mobile/MembershipProduct";
import cache from "../../../apollo/cache";

const PRODUCT = gql`
  query getProducts($machine: String!, $order: String!) {
    getProducts(type: "membership", machine: $machine, order: $order) {
      products {
        id
        category {
          id
          name
        }
        image
        name
        price
        stock
        ignoreStock
        description
        isDiscount
        discountPrice
        discountPercent
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      username
    }
  }
`;

const DELETE = gql`
  mutation deleteProduct($machine: String!, $productId: Int!) {
    deleteProduct(machine: $machine, productId: $productId)
  }
`;

const MembershipProducts = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: loadingProduct,
    refetch,
  } = useQuery(PRODUCT, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
    },
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [deleteProductM] = useMutation(DELETE);

  const remove = async (product) => {
    if (!loading) {
      const confirm = window.confirm("정말 삭제하시겠습니까?");
      if (confirm) {
        setLoading(true);
        const { data } = await deleteProductM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            productId: product.id,
          },
        });
        setLoading(false);
        if (data && data.deleteProduct) {
          cache.evict({
            id: `Product:${product.id}`,
          });
          refetch();
          alert("회원권을 삭제하였습니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      }
    }
  };

  useEffect(() => {
    if (location.state.machine) {
      window.scrollTo(0, 0);
      refetch({
        machine: location.state.machine,
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
      });
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).order]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`회원권 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
            <div
              className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
              onClick={() =>
                history.push({
                  pathname: `/membership/product/discount/`,
                  state: {
                    machine: location.state.machine ? location.state.machine : "",
                  },
                  search: "?order=name_asc",
                })
              }
            >
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>할인 적용 관리</p>
            </div>
            <div
              className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
              onClick={() =>
                history.push({
                  pathname: `/membership/product/add/`,
                  state: {
                    machine: location.state.machine ? location.state.machine : "",
                  },
                })
              }
            >
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>회원권 추가하기</p>
            </div>
          </div>
          <div className={`${styles.center} ${styles.mt15}`}>
            <div
              className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
              onClick={() =>
                history.push({
                  pathname: `/membership/product/ordering/`,
                  state: {
                    machine: location.state.machine ? location.state.machine : "",
                  },
                })
              }
            >
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>노출 순서 변경</p>
            </div>
          </div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>회원권명</p>
            </div>
          </div>
          <div>
            {loadingProduct ? (
              <Skeleton height={50} count={3} />
            ) : data && data.getProducts && data.getProducts.products.length > 0 ? (
              data.getProducts.products.map((product) => {
                return <MembershipProduct key={product.id} product={product} remove={() => remove(product)} machine={machine && machine.getMachine ? machine.getMachine : {}} />;
              })
            ) : (
              <div className={`${styles.center}`} style={{ height: 600 }}>
                <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>아직 등록된 회원권이 없습니다.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipProducts;
