import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import cache from '../../../apollo/cache';
import useInput from '../../../hooks/useInput';
import Advertise from '../../../components/Advertise/Mobile/Advertise';

const ADVERTISE = gql`
    query getAdvertises($order: String!, $skip: Int!, $q: String) {
        getAdvertises(order: $order, skip: $skip, q: $q) {
            advertises {
                id
                name
                description
                ownerName
                ownerContact
                createdAt
                status
                machinesCount
            }
            total
        }
    }
`;

const DELETE = gql`
    mutation deleteAdvertise($id: Int!) {
        deleteAdvertise(id: $id)
    }
`;

const AdvertiseHome = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput('');

    const [loading, setLoading] = useState(false);

    const {
        data,
        loading: loadingAdvertise,
        refetch,
    } = useQuery(ADVERTISE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
            skip: 0,
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });

    const [deleteAdvertiseM] = useMutation(DELETE);

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/advertise/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${q.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/advertise/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const applyOrder = order => {
        history.replace({
            pathname: `/advertise/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${order}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const handleOrder = order => {
        if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'owner_name') {
            if (queryString.parse(location.search).order === 'owner_name_asc') {
                applyOrder('owner_name_dsc');
            } else if (
                queryString.parse(location.search).order === 'owner_name_dsc'
            ) {
                applyOrder('owner_name_asc');
            } else {
                applyOrder('owner_name_asc');
            }
        } else if (order === 'owner_contact') {
            if (
                queryString.parse(location.search).order === 'owner_contact_asc'
            ) {
                applyOrder('owner_contact_dsc');
            } else if (
                queryString.parse(location.search).order === 'owner_contact_dsc'
            ) {
                applyOrder('owner_contact_asc');
            } else {
                applyOrder('owner_contact_asc');
            }
        } else if (order === 'created') {
            if (queryString.parse(location.search).order === 'created_asc') {
                applyOrder('created_dsc');
            } else if (
                queryString.parse(location.search).order === 'created_dsc'
            ) {
                applyOrder('created_asc');
            } else {
                applyOrder('created_asc');
            }
        } else if (order === 'status') {
            if (queryString.parse(location.search).order === 'status_asc') {
                applyOrder('status_dsc');
            } else if (
                queryString.parse(location.search).order === 'status_dsc'
            ) {
                applyOrder('status_asc');
            } else {
                applyOrder('status_asc');
            }
        } else {
            applyOrder('name_asc');
        }
    };

    const remove = async advertise => {
        if (!loading && advertise) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await deleteAdvertiseM({
                        variables: {
                            id: advertise.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.deleteAdvertise) {
                        cache.evict({
                            id: `Advertise:${advertise.id}`,
                        });
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (
            queryString.parse(location.search).page &&
            !isNaN(Number(queryString.parse(location.search).page))
        ) {
            window.scrollTo(0, 0);
            refetch({
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc',
                skip:
                    10 * (Number(queryString.parse(location.search).page) - 1),
                q: queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : '',
            });
            q.setValue(queryString.parse(location.search).q);
        } else {
            history.replace({
                pathname: `/advertise/?page=1&order=${
                    queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'name_asc'
                }&q=${
                    queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                }`,
                state: location.state,
            });
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        광고 관리
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt45} ${styles.px40}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img
                                src={
                                    require('../../../assets/images/icon_search.png')
                                        .default
                                }
                                alt={'search'}
                                className={`${styles.icon20} ${styles.cursorPointer}`}
                                onClick={submit}
                            />
                            <input
                                type={'text'}
                                name={'q'}
                                value={q.value}
                                onChange={q.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                placeholder={
                                    '광고명, 광고주명, 광고주 연락처 검색'
                                }
                            />
                        </div>
                    </form>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                광고명
                            </p>
                        </div>
                        <div className={`${styles.flex1} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                광고주명
                            </p>
                        </div>
                        <div className={`${styles.flex2}`}></div>
                    </div>
                    <div>
                        {loadingAdvertise ? (
                            <Skeleton height={50} count={3} />
                        ) : data &&
                          data.getAdvertises &&
                          data.getAdvertises.advertises.length > 0 ? (
                            data.getAdvertises.advertises.map(advertise => {
                                return (
                                    <Advertise
                                        key={advertise.id}
                                        advertise={advertise}
                                        remove={() => remove(advertise)}
                                        loading={loading}
                                    />
                                );
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    광고가 없습니다.
                                </p>
                            </div>
                        )}
                        {data?.getAdvertises?.total > 10 ? (
                            <div
                                className={`${styles.mt35}`}
                                style={{ position: 'relative' }}>
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_back.png')
                                                    .default
                                            }
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_front.png')
                                                    .default
                                            }
                                            alt={'다음'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    breakLabel={
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    }
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={
                                        data?.getAdvertises?.total
                                            ? parseInt(
                                                  (data?.getAdvertises?.total -
                                                      1) /
                                                      10,
                                              ) + 1
                                            : 1
                                    }
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={
                                        loadingAdvertise ? null : handlePage
                                    }
                                    initialPage={
                                        queryString.parse(location.search).page
                                            ? Number(
                                                  queryString.parse(
                                                      location.search,
                                                  ).page,
                                              ) - 1
                                            : 0
                                    }
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingAdvertise && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.4)',
                                        }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertiseHome;
