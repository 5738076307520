import React, { createContext, useContext, useState } from 'react';
import { OPTION_SAVE_TOKEN_KEY, TOKEN_KEY } from '../common/constants';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const login = async (token, save) => {
        localStorage.setItem(TOKEN_KEY, token);
        if (save) {
            localStorage.setItem(OPTION_SAVE_TOKEN_KEY, 'true');
        } else {
            localStorage.setItem(OPTION_SAVE_TOKEN_KEY, 'false');
        }
        setIsLoggedIn(true);
        return true;
    };

    const logout = async () => {
        const confirm = window.confirm('로그아웃 하시겠습니까?');
        if (confirm) {
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(OPTION_SAVE_TOKEN_KEY);
            localStorage.removeItem("adminToken");
            setIsLoggedIn(false);
        }
        return true;
    };

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const context = useContext(AuthContext);

    return context;
};

export { AuthContext, AuthProvider, useAuthContext };
