import React, {useState, useEffect} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import {useHistory, useLocation} from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

import '../../../styles/scheduler_css.css';
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import cache from "../../../apollo/cache";
import CategoryList from "../../../components/Category/PC/CategoryList";

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const GET_CATEGORY = gql`
    query getScheduleCategory($machine: String!, $order: String!, $skip: Int!) {
        getScheduleCategory(machine: $machine, order: $order, skip: $skip) {
            category{
                id
                name
                createdAt
                scheduleCnt
                scheduleReservationCnt
                scheduleWaitingCnt
                scheduleReservationMemberCnt
                machine{
                    id
                    name
                    username
                }
            }
            total
        }
    }
`;

const DELETE_CATEGORY = gql`
    mutation deleteScheduleCategory($id: Int!) {
        deleteScheduleCategory(id: $id) {
            success
            error
        }
    }
`;

const CategoryMain = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [deleteCategoryM] = useMutation(DELETE_CATEGORY);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const {data: getCategoryList, loading:loadingCategory, refetch: categoryRefetch} = useQuery(GET_CATEGORY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            skip: 0
        }
    });

    const handleOrder = (order) => {
        if(order === "date"){
            if(queryString.parse(location.search).order === "date_asc"){
                applyOrder("date_dsc")
            }
            else if(queryString.parse(location.search).order === "date_dsc"){
                applyOrder("date_asc")
            }
            else{
                applyOrder("date_dsc")
            }
        } else if(order === "name"){
            if(queryString.parse(location.search).order === "name_asc"){
                applyOrder("name_dsc")
            }
            else if(queryString.parse(location.search).order === "name_dsc"){
                applyOrder("name_asc")
            }
            else{
                applyOrder("name_asc")
            }
        }
    };

    const applyOrder = (order) => {
        history.replace({
            pathname: `/category/main/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&order=${order}`
        });
    };

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            categoryRefetch({
                machine: location.state.machine ? location.state.machine : '',
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                skip: 10*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order]);

    const handlePage = (page) => {
        history.replace({
            pathname: `/category/main/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`
        })
    }

    const remove = async c => {
        console.log('delete category id : ', c.id);
        if (!loading) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await deleteCategoryM({
                    variables: {
                        id: c.id
                    }
                });
                setLoading(false);
                if (data && data.deleteScheduleCategory.success) {
                    cache.evict({
                        id: `Category:${c.id}`,
                    });
                    categoryRefetch();
                    alert('카테고리를 삭제하였습니다.');
                } else {
                    alert(data.deleteScheduleCategory.error);
                }
            }
        }
    };

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`카테고리 관리${data && data.getMachine ? ` (${data.getMachine.name})` : ''}`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                    style={{minWidth: 150}}
                                    onClick={() => history.push({
                                        pathname: `/category/add/`,
                                        state: {
                                            machine: location.state.machine
                                        },
                                    })}>
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>카테고리 추가</p>
                                </div>
                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                                    <img
                                        src={require('../../../assets/images/icon_back.png').default}
                                        alt={'이전'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 45 * 0.2,
                                            height: 79 * 0.2,
                                        }}
                                    />
                                    <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록일
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "date_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "date_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    카테고리 명
                                </p>
                                <div className={`${styles.ml10} ${styles.center}`}>
                                    <img src={(queryString.parse(location.search).order === "name_asc") ? require("../../../assets/images/icon_sort_up_orange.png").default : require('../../../assets/images/icon_sort_up.png').default} alt={'asc'} className={`${styles.cursorPointer}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                    <img src={(queryString.parse(location.search).order === "name_dsc") ? require("../../../assets/images/icon_sort_down_orange.png").default : require('../../../assets/images/icon_sort_down.png').default} alt={'dsc'} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18*0.5, height: 10*0.5 }} />
                                </div>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 등록된 스케쥴 수
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 예약된 강사수
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 예약된 회원수
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 대기중인 회원수
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>

                            </div>
                        </div>
                        {loadingCategory ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            getCategoryList?.getScheduleCategory?.category?.map(category => {
                                return (
                                    <CategoryList key={category.id} category={category} remove={() => remove(category)}/>
                                )
                            })
                        )}
                        {(getCategoryList?.getScheduleCategory?.total < 1) ? (
                            <div className={`${styles.row} ${styles.justifyContentCenter}`} style={{marginTop:30}}>등록된 카테고리가 없습니다.</div>
                        ):null}
                        {(getCategoryList?.getScheduleCategory?.total > 10) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{position: "relative"}}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(getCategoryList?.getScheduleCategory?.total) ? parseInt((getCategoryList?.getScheduleCategory?.total - 1) / 10) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingCategory ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {(loadingMachine || loadingCategory) && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "rgba(255, 255, 255, 0.4)"
                                    }}/>
                                )}
                            </div>
                        ) : null}
                    </div>


                </div>
            </div>
        </div>
    );
}

export default CategoryMain;
