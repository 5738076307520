import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import cache from "../../../apollo/cache";

const DETAIL = gql`
  query getPayment($machine: String!, $paymentId: Int!) {
    getPayment(machine: $machine, paymentId: $paymentId) {
      id
      productName
      userName
      userContact
      startDate
      status
      approvalDate
      approvalNo
      membershipType {
        id
        name
      }
    }
  }
`;

const EDIT = gql`
  mutation changeMembershipType($machine: String!, $paymentId: Int!, $typeId: Int!) {
    changeMembershipType(machine: $machine, paymentId: $paymentId, typeId: $typeId) {
      success
      result {
        id
        membershipType {
          id
          name
        }
      }
    }
  }
`;

const MACHINE = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const TYPE = gql`
  query getMembershipType($machine: String!) {
    getMembershipType(machine: $machine) {
      id
      name
    }
  }
`;

const TYPE_DELETE = gql`
  mutation deleteMembershipType($typeId: Int!) {
    deleteMembershipType(typeId: $typeId)
  }
`;

const TYPE_ADD = gql`
  mutation addMembershipType($machine: String!, $name: String!) {
    addMembershipType(machine: $machine, name: $name) {
      id
      name
    }
  }
`;

const EditMembershipType = () => {
  const history = useHistory();
  const location = useLocation();

  const typeName = useInput("");

  const [showMembershipType, setShowMembershipType] = useState(false);
  const [showMembershipTypeAdd, setShowMembershipTypeAdd] = useState(false);
  const [membershipType, setMembershipType] = useState({});
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine,
      paymentId: location.state.payment.id,
    },
  });
  const { data: machine } = useQuery(MACHINE, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { data: type, refetch } = useQuery(TYPE, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
    fetchPolicy: "cache-and-network",
  });

  const [changeMembershipTypeM] = useMutation(EDIT);
  const [deleteMembershipTypeM] = useMutation(TYPE_DELETE);
  const [addMembershipTypeM] = useMutation(TYPE_ADD);

  const handleMembershipType = (membershipType) => {
    setMembershipType(membershipType);
    setShowMembershipType(false);
  };

  const addMembershipType = async () => {
    if (!loading) {
      if (typeName.value) {
        setLoading(true);
        const { data } = await addMembershipTypeM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            name: typeName.value,
          },
        });
        setLoading(false);
        if (data && data.addMembershipType) {
          refetch();
          setMembershipType(data.addMembershipType);
          setShowMembershipType(false);
        } else {
          alert("오류가 발생하였습니다.");
        }
      } else {
        alert("추가할 구매형태를 입력해주세요.");
      }
    }
  };

  const removeMembershipType = async (membershipType) => {
    if (!loading) {
      if (membershipType && membershipType.id) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data } = await deleteMembershipTypeM({
            variables: {
              typeId: membershipType.id,
            },
          });
          setLoading(false);
          if (data && data.deleteMembershipType) {
            cache.evict({
              id: `MembershipType:${membershipType.id}`,
            });
          } else {
            alert("해당 구매형태에 해당하는 결제내역이 존재합니다.");
          }
        }
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getPayment) {
        if (membershipType && membershipType.id) {
          setLoading(true);
          try {
            const { data: result } = await changeMembershipTypeM({
              variables: {
                machine: location.state.machine,
                paymentId: data.getPayment.id,
                typeId: membershipType.id,
              },
            });
            if (result.changeMembershipType && result.changeMembershipType.success) {
              alert("회원권 판매 내역을 수정하였습니다.");
              history.goBack();
            } else {
              alert("오류가 발생하였습니다.");
            }
          } catch {
            alert("오류가 발생하였습니다.");
          } finally {
            setLoading(false);
          }
        } else {
          alert("구매형태를 선택해주세요.");
        }
      }
    }
  };

  // 영수증 출력
  const showBill = async (e) => {
    e.preventDefault();
    window.open("https://admin.ven-brothers.com/receipt/" + data.getPayment.approvalDate + data.getPayment.approvalNo);
  };

  useEffect(() => {
    setShowMembershipTypeAdd(false);
    typeName.setValue("");
  }, [showMembershipType]);

  useEffect(() => {
    if (data && data.getPayment) {
      setMembershipType(data.getPayment.membershipType ? data.getPayment.membershipType : {});
    }
  }, [data]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`구매형태 설정${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자명</p>
              </div>
              <input type={"text"} name={"userName"} value={data && data.getPayment ? data.getPayment.userName : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권명</p>
              </div>
              <input type={"text"} name={"product"} value={data && data.getPayment ? data.getPayment.productName : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매자 연락처</p>
              </div>
              <input type={"text"} name={"userContact"} value={data && data.getPayment ? data.getPayment.userContact : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권 시작일</p>
              </div>
              <input type={"text"} name={"startDate"} value={data && data.getPayment ? data.getPayment.startDate : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} readOnly={true} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제상태</p>
              </div>
              {data && data.getPayment ? (
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input
                    type={"text"}
                    value={data.getPayment.status === "paid" ? "결제완료" : data.getPayment.status === "cancelled" ? "결제취소" : data.getPayment.status === "refund" ? "환불" : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                    style={{ minWidth: 20 }}
                    readOnly={true}
                  />
                </div>
              ) : (
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15}`}></div>
              )}
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{ borderBottomLeftRadius: showMembershipType ? 0 : 10, borderBottomRightRadius: showMembershipType ? 0 : 10 }}
                onClick={() => setShowMembershipType(!showMembershipType)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>구매형태</p>
                </div>
                {data && data.getPayment ? (
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                    <input type={"text"} value={membershipType && membershipType.id ? membershipType.name : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} style={{ minWidth: 20 }} placeholder={"구매형태를 선택해주세요."} readOnly={true} />
                    <img src={showMembershipType ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"수령 상태 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                  </div>
                ) : (
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15}`}></div>
                )}
              </div>
              {showMembershipType && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    {showMembershipTypeAdd ? (
                      <div className={`${styles.py10} ${styles.flex1}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`}>
                          <p className={`${styles.font14} ${styles.black} ${styles.mr10}`}>추가할 구매형태</p>
                          <input type={"text"} name={"typeName"} value={typeName.value} onChange={typeName.onChange} className={`${styles.inputWhiteUnderline} ${styles.flex1} ${styles.py5} ${styles.mt3}`} style={{ minWidth: 20 }} placeholder={"추가할 구매형태를 입력해주세요."} />
                          <p className={`${styles.font14} ${styles.black} ${styles.ml10} ${styles.cursorPointer}`} style={{ textDecorationLine: "underline" }} onClick={addMembershipType}>
                            완료
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className={`${styles.py10} ${styles.flex1}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => setShowMembershipTypeAdd(true)}>
                          <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon15} ${styles.cursorPointer} ${styles.mr10} ${styles.mt3}`} />
                          <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.cursorPointer}`}>새로운 구매형태 추가</p>
                        </div>
                        {type && type.getMembershipType
                          ? type.getMembershipType.map((c) => {
                              return (
                                <div key={c.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                                  <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleMembershipType(c)}>
                                    {c.name}
                                  </p>
                                  <img src={require("../../../assets/images/icon_close.png").default} alt={"delete"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml20} ${styles.mt3}`} onClick={() => removeMembershipType(c)} />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.fontM} ${styles.font16} ${styles.bgWhite} ${styles.black} ${styles.py10}`} style={{ width: "100%", textAlign: "center" }} onClick={showBill}>
                영 수 증
              </div>
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditMembershipType;
