import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const ContractMachine = ({ machine, editUseSignTerms, loading }) => {
    const history = useHistory();

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.username}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.status === 'inuse' && '사용중'}
                    {machine.status === 'notuse' && '미사용'}
                    {machine.status === 'check' && '점검중'}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {machine.useSignTerms ? '사용중' : '미사용'}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(machine.contractTotal)}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={editUseSignTerms}>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        {machine.useSignTerms ? '사용중지' : '사용하기'}
                    </p>
                </div>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={() =>
                        history.push({
                            pathname: `/contract/list/`,
                            state: {
                                machine: machine.username,
                            },
                            search: '?order=date_dsc&page=1&q=',
                        })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        계약 관리
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContractMachine;
