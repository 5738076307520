import React from 'react';

import styles from '../../../styles/styles.module.scss';

const OrderHomePayment = ({
    payment
}) => {
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {`${payment.approvalDate.slice(2,4)}/${payment.approvalDate.slice(4,6)} - ${payment.approvalTime.slice(0,2)}:${payment.approvalTime.slice(2,4)}`}
                </p>
            </div>
            <div className={`${styles.flex2} ${styles.px10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {payment.productName}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.underline}`}>
                    {(payment.receiveStatus === "none") && "미처리"}
                    {(payment.receiveStatus === "prepare") && "상품준비중"}
                    {(payment.receiveStatus === "ready") && "상품준비완료"}
                    {(payment.receiveStatus === "delivering") && "배송중"}
                    {(payment.receiveStatus === "done") && "수령 완료"}
                </p>
            </div>
        </div>
    )
}

export default OrderHomePayment;