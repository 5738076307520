import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import MembershipProduct from "../../../components/Membership/Mobile/MembershipProduct";
import cache from "../../../apollo/cache";
import useInput from "../../../hooks/useInput";
import ScheduleMember from "../../../components/Schedule/Mobile/ScheduleMember";
import CheckIn from "../../../components/CheckIn/PC/CheckIn";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { bill, billSm } from "../../../common/modal";
import Calendar from "react-calendar";

const Memberships = gql`
  query getScheduleMemberships($machine: String!, $order: String!, $skip: Int!, $scheduleId: Int!, $status: String, $q: String) {
    getScheduleMemberships(machine: $machine, order: $order, skip: $skip, scheduleId: $scheduleId, status: $status, q: $q) {
      scheduleMemberships {
        id
        name
        mobile
        viewMembershipValids {
          id
          product {
            id
            name
          }
          start
          end
          validMembershipCount
          membershipCount
        }
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      username
    }
  }
`;

const RESERVATION = gql`
  mutation addScheduleReservation($machine: String!, $memberId: Int!, $scheduleId: Int!, $status: Int!, $over: Boolean!, $limit: Boolean!) {
    addScheduleReservation(machine: $machine, memberId: $memberId, scheduleId: $scheduleId, status: $status, over: $over, limit: $limit) {
      success
      error
      over
      limit
    }
  }
`;

const ScheduleMembershipList = () => {
  const history = useHistory();
  const location = useLocation();

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const [showOverModal, setShowOverModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMember, setModalMember] = useState(null);
  const [status, setStatus] = useState(location.state.status ?? 1);
  const [propOver, setPropOver] = useState(false);

  const [addReservationM] = useMutation(RESERVATION);

  const {
    data,
    loading: loadingMember,
    refetch,
  } = useQuery(Memberships, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      machineId: location.state.machineId ? location.state.machineId : -1,
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
      scheduleId: location.state.scheduleId ?? -1,
      status: null,
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/schedule/membership/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleShowModal = (show, member) => {
    setModalMember(member);
    setShowOverModal(show ?? false);
  };

  const handleShowLimitModal = (show, over, member) => {
    setModalMember(member);
    setPropOver(over);
    setShowLimitModal(show ?? false);
  };

  const handleOrder = (order) => {
    if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else {
      applyOrder("name_asc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/schedule/membership/list/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    history.push({
      pathname: `/schedule/membership/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${q.value}`,
    });
  };

  useEffect(() => {
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
      } else {
        history.replace({
          pathname: `/schedule/membership/list/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  const reservation = async (member, over, limit = false) => {
    if (!loading) {
      if (member) {
        setLoading(true);
        const { data } = await addReservationM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            memberId: member.id,
            scheduleId: location.state.scheduleId ?? -1,
            status: status,
            over: over,
            limit: limit,
          },
        });
        setLoading(false);
        const result = data.addScheduleReservation;
        if (result.success) {
          alert(status === 2 ? "대기자 추가되었습니다" : "예약자 추가되었습니다.");
          refetch();
          setShowOverModal(false);
          setShowLimitModal(false);
        } else if (!result.success && result.over) {
          handleShowModal(true, member);
        } else if (!result.success && result.limit) {
          handleShowModal(false, member);
          handleShowLimitModal(true, over, member);
        } else {
          alert(result.error ?? "오류가 발생하였습니다.");
        }
      }
    }
  };

  const waitReservation = async () => {
    setStatus(2);
  };

  useEffect(() => {
    console.log("data### " + data);
  }, [data]);

  useEffect(async () => {
    if (status === 2) {
      await reservation(modalMember, false);
    }
  }, [status]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
            {location.state.status === 2 ? "대기자 추가" : "예약자 추가"}
            {`${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}
          </p>
          <form action={""} onSubmit={submit} className={`${styles.mt15} ${styles.px40}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
              <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
              <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} placeholder={"회원명, 연락처 및 회원권으로 검색"} />
            </div>
          </form>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt10}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원명</p>
            </div>
            <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권명</p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}></p>
            </div>
          </div>
          <div style={{ minHeight: 600 }}>
            {loadingMember ? (
              <Skeleton height={50} count={3} />
            ) : data && data.getScheduleMemberships && data.getScheduleMemberships.scheduleMemberships.length > 0 ? (
              data.getScheduleMemberships.scheduleMemberships.map((member) => {
                return <ScheduleMember key={member.id} member={member} machine={machine && machine.getMachine ? machine.getMachine : {}} isWait={location.state.status === 2} addReservation={() => reservation(member, false)} />;
              })
            ) : (
              <div className={`${styles.center}`} style={{ height: 600 }}>
                <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>예약 가능한 회원이 없습니다.</p>
              </div>
            )}
            {data?.getScheduleMemberships?.total > 10 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={
                    <img
                      src={require("../../../assets/images/icon_back.png").default}
                      alt={"이전"}
                      className={`${styles.cursorPointer}`}
                      style={{
                        width: 45 * 0.2,
                        height: 79 * 0.2,
                      }}
                    />
                  }
                  nextLabel={
                    <img
                      src={require("../../../assets/images/icon_front.png").default}
                      alt={"다음"}
                      className={`${styles.cursorPointer}`}
                      style={{
                        width: 45 * 0.2,
                        height: 79 * 0.2,
                      }}
                    />
                  }
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getScheduleMemberships?.total ? parseInt((data?.getScheduleMemberships?.total - 1) / 10) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingMember ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingMember && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.4)",
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal isOpen={showOverModal} onRequestClose={() => handleShowModal(false, modalMember)} style={billSm}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>예약</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>정원이 가득 찼습니다. 초과 예약을 할까요? 아니면 예약 대기를 할까요?</div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => reservation(modalMember, true)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>초과 예약</p>
            </div>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => waitReservation()}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>예약 대기</p>
            </div>
          </div>
        </div>
      </Modal>

      {/* 횟수제한 초과시 모달 */}
      <Modal isOpen={showLimitModal} onRequestClose={() => handleShowModal(false, modalMember)} style={billSm}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>예약</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>이 예약을 진행하실 경우, 설정하신 1일 회원당 예약 제한 횟수를 초과하게 됩니다. 예약 제한 횟수를 초과하여 예약하시겠습니까?</div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => setShowLimitModal(false)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>취소</p>
            </div>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => reservation(modalMember, propOver, true)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>예약</p>
            </div>
          </div>
        </div>
      </Modal>

      {/* 기간제한 초과시 모달 */}
      <Modal isOpen={showDateModal} onRequestClose={() => handleShowModal(false, modalMember)} style={billSm}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>예약</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>이 예약을 진행하실 경우, 설정하신 예약 가능 기간을 초과하게 됩니다. 예약 가능 기간을 초과하여 예약하시겠습니까?</div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20}  ${styles.mr10} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => setShowLimitModal(false)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>취소</p>
            </div>
            <div className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => reservation(modalMember, propOver, true)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>예약</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleMembershipList;
