import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const ScheduleMember = ({
                    machine,
                    member,
                    isWait,
                    addReservation,
                }) => {

    const history = useHistory()

    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`} >
                <div className={`${styles.flex1}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                        {member.name}
                    </p>
                </div>
                <div className={`${styles.flex2} ${styles.showLimitMemberships} memberships`}>
                    {member.viewMembershipValids ? (member.viewMembershipValids.length > 0) && (
                        member.viewMembershipValids.map((membership, idx) => {
                            return(
                                // membership.status === 'active' ? (
                                    <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.textCenter}`}>
                                        {membership.product.name}
                                    </p>
                                // ) : null
                            )
                        })
                    ) : null}
                </div>
                <div
                    className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                    <div
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                        onClick={addReservation}>
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            { isWait ? '대기' : '예약' }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleMember;
