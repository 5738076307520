import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
            ptRequestForm
        }
    }
`

const FORM = gql`
    mutation editPtRequestForm($machine: String! $form: String!){
        editPtRequestForm(machine: $machine, form: $form){
            machine{
                id
                ptRequestForm
            }
            error
        }
    }
`

const ConsultFormEdit = () => {
    const history = useHistory()
    const location = useLocation()

    const [form, setForm] = useState([])
    const [loading, setLoading] = useState(false)

    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [editPtRequestFormM] = useMutation(FORM)

    const addForm = (type) => {
        if(type === "text"){
            setForm([...form, {
                id: `${new Date().getTime()}`,
                type: "text",
                title: "",
                optional: false
            }])
        }
        else if(type === "select"){
            setForm([...form, {
                id: `${new Date().getTime()}`,
                type: "select",
                title: "",
                optional: false,
                duplicable: false,
                items: []
            }])
        }
    }

    const deleteForm = (id) => {
        const findIndex = form.findIndex(item => item.id === id)
        if(findIndex > -1){
            let newForm = [...form]
            newForm.splice(findIndex, 1)
            setForm(newForm)
        }
    }

    const onChange = (e, f) => {
        const findIndex = form.findIndex(item => item.id === f.id)
        if(findIndex > -1){
            const { target : { value } } = e;
            let newForm = [...form]
            newForm[findIndex].title = value
            setForm(newForm)
        }
    }

    const handleOptional = (f) => {
        const findIndex = form.findIndex(item => item.id === f.id)
        if(findIndex > -1){
            let newForm = [...form]
            newForm[findIndex].optional = !newForm[findIndex].optional
            setForm(newForm)
        }
    }

    const handleDuplicable = (f) => {
        const findIndex = form.findIndex(item => item.id === f.id)
        if(findIndex > -1){
            let newForm = [...form]
            newForm[findIndex].duplicable = !newForm[findIndex].duplicable
            setForm(newForm)
        }
    }

    const addSelectItem = (f) => {
        const findIndex = form.findIndex(item => item.id === f.id)
        if(findIndex > -1){
            let newForm = [...form]
            newForm[findIndex].items = [...newForm[findIndex].items, {
                id: `${new Date().getTime()}`,
                title: ""
            }]
            setForm(newForm)
        }
    }

    const onChangeItem = (e, f, item) => {
        const findIndex = form.findIndex(item => item.id === f.id)
        if(findIndex > -1){
            let newForm = [...form]
            const itemIndex = newForm[findIndex].items.findIndex(i => i.id === item.id)
            if(itemIndex > -1){
                const { target : { value } } = e;
                newForm[findIndex].items[itemIndex].title = value
                setForm(newForm)
            }
        }
    }

    const deleteItem = (formId, itemId) => {
        const findIndex = form.findIndex(item => item.id === formId)
        if(findIndex > -1){
            let newForm = [...form]
            const itemIndex = newForm[findIndex].items.findIndex(i => i.id === itemId)
            newForm[findIndex].items.splice(itemIndex, 1)
            setForm(newForm)
        }
    }

    const submit = async(e) => {
        e.preventDefault()
        if(!loading){
            let check = true
            for(const item of form){
                if(item.type === "text"){
                    if(!item.title){
                        check = false
                        break
                    }
                }
                else{
                    if(!item.title){
                        check = false
                        break
                    }
                    if(item.items.length === 0){
                        check = false
                        break
                    }
                    for(const inner of item.items){
                        if(!inner.title){
                            check = false
                            break
                        }
                    }
                }
            }
            if(!check){
                alert("위 양식을 완성해주세요.")
                return;
            }
            setLoading(true)
            const { data } = await editPtRequestFormM({
                variables: {
                    machine: location.state.machine ? location.state.machine : "",
                    form: JSON.stringify(form)
                }
            })
            setLoading(false)
            if(data && data.editPtRequestForm.machine){
                alert("저장되었습니다.")
            }
            else if(data && data.editPtRequestForm.error){
                alert(data.editPtRequestForm.error)
            }
            else{
                alert("오류가 발생하였습니다.")
            }
        }
    }

    useEffect(() => {
        if(!location.state.machine){
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [])

    useEffect(() => {
        if(machine){
            setForm(machine.getMachine.ptRequestForm ? JSON.parse(machine.getMachine.ptRequestForm) : [])
        }
    }, [machine])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`상담 양식 관리${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                    </p>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => addForm("text")}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                텍스트형 추가
                            </p>
                        </div>
                        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`} onClick={() => addForm("select")}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                선택형 추가
                            </p>
                        </div>
                    </div>
                    {(form.length > 0) ? (
                        <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
                            {form.map((f, i) => {
                                return(
                                    <div key={f.id}>
                                        {(f.type === "text") && (
                                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${(i === 0) ? "" : styles.mt10}`}>
                                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                                                    <input
                                                    type={"text"}
                                                    name={f.id}
                                                    value={f.title}
                                                    onChange={(e) => onChange(e, f)}
                                                    className={`${styles.inputWhite} ${styles.flex1}`} 
                                                    placeholder={"질문명을 입력해주세요."}
                                                    />
                                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => handleOptional(f)}>
                                                            <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>
                                                                {!f.optional && (
                                                                    <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />
                                                                )}
                                                            </div>
                                                            <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
                                                                필수
                                                            </p>
                                                        </div>
                                                        <div className={`${styles.pl20}`}>
                                                            <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => deleteForm(f.id)}>
                                                                삭제
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <textarea 
                                                name={"description"}
                                                value={""}
                                                className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} 
                                                style={{ height: 120 }}
                                                placeholder={""}
                                                maxLength={50}
                                                readOnly={true}
                                                />
                                            </div>
                                        )}
                                        {(f.type === "select") && (
                                            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${(i === 0) ? "" : styles.mt10}`}>
                                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                                                    <input
                                                    type={"text"}
                                                    name={f.id}
                                                    value={f.title}
                                                    onChange={(e) => onChange(e, f)}
                                                    className={`${styles.inputWhite} ${styles.flex1}`} 
                                                    placeholder={"질문명을 입력해주세요."}
                                                    />
                                                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                                        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px10} ${styles.py3} ${styles.cursorPointer}`} onClick={() => addSelectItem(f)}>
                                                            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                                                항목 추가
                                                            </p>
                                                        </div>
                                                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml20}`} onClick={() => handleDuplicable(f)}>
                                                            <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>
                                                                {f.duplicable && (
                                                                    <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />
                                                                )}
                                                            </div>
                                                            <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
                                                                중복선택
                                                            </p>
                                                        </div>
                                                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml20}`} onClick={() => handleOptional(f)}>
                                                            <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>
                                                                {!f.optional && (
                                                                    <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />
                                                                )}
                                                            </div>
                                                            <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
                                                                필수
                                                            </p>
                                                        </div>
                                                        <div className={`${styles.pl20}`}>
                                                            <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => deleteForm(f.id)}>
                                                                삭제
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${styles.py10}`}>
                                                    {f.items.map((item, index) => {
                                                        return(
                                                            <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${(index === 0) ? "" : styles.mt10}`}>
                                                                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1}`}>
                                                                    <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.mr10}`} />
                                                                    <input
                                                                    type={"text"}
                                                                    name={item.id}
                                                                    value={item.title}
                                                                    onChange={(e) => onChangeItem(e, f, item)}
                                                                    className={`${styles.inputWhite} ${styles.flex1}`} 
                                                                    placeholder={"항목명을 입력해주세요."}
                                                                    />
                                                                </div>
                                                                <div className={`${styles.pl20}`}>
                                                                    <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.cursorPointer}`} onClick={() => deleteItem(f.id, item.id)}>
                                                                        삭제
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                            <div className={`${styles.mt45}`}>
                                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        저장
                                    </p>
                                </button>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                        취소
                                    </p>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className={`${styles.center}`} style={{ height: 500 }}>
                            <p className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                상담 양식을 만들어주세요.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ConsultFormEdit;