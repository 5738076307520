import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import styles from "../../../styles/styles.module.scss";
import ScheduleMachine from "../../../components/Schedule/Mobile/ScheduleMachine";
import { numberWithCommas, setDateFormat_yyyyMM, setDateFormatKor_aahhmm, setDateFormatKor_day, setDateFormatKor_yyyyMMdd } from "../../../common/utility";
import ScheduleListItem from "../../../components/Schedule/Mobile/ScheduleListItem";
import Calendar from "react-calendar";

const DATA = gql`
  query getListSchedule($skip: Int!, $machine: String!, $scheduleId: Int!, $date: String, $order: String) {
    getListSchedule(skip: $skip, machine: $machine, scheduleId: $scheduleId, date: $date, order: $order) {
      schedule {
        id
        name
        date
        startTime
        endTime
        scheduleGroupId
        scheduleGroup {
          id
          type
          delYn
        }
        teacher {
          name
        }
      }
      schedules {
        id
        name
        date
        startTime
        endTime
        scheduleGroupId
        teacher {
          name
        }
      }
      schedulesTotal
      error
    }
  }
`;

const DELETE = gql`
  mutation deleteSchedule($scheduleId: Int!) {
    deleteSchedule(scheduleId: $scheduleId) {
      success
      error
    }
  }
`;

const DELETE_GROUP = gql`
  mutation deleteScheduleGroup($scheduleGroupId: Int!) {
    deleteScheduleGroup(scheduleGroupId: $scheduleGroupId) {
      success
      error
    }
  }
`;

const EDIT_SCHEDULE_CHECKED = gql`
  mutation editScheduleChecked($scheduleGroupId: Int!, $scheduleId: Int!, $type: String!) {
    editScheduleChecked(scheduleGroupId: $scheduleGroupId, scheduleId: $scheduleId, type: $type) {
      success
      error
    }
  }
`;

const ScheduleList = () => {
  const history = useHistory();
  const location = useLocation();

  // const scheduleId = location.state.scheduleId;
  let scheduleId = localStorage.getItem("scheduleId");
  if (scheduleId === null) {
    scheduleId = location.state.scheduleId;
  } else {
    scheduleId = parseInt(scheduleId);
  }

  const [data, setData] = useState(null);
  const [date, setDate] = useState(null);
  const [showDate, setShowDate] = useState(false);

  const [dataRefetch, { loading: loadingData }] = useLazyQuery(DATA, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 10 * (Number(queryString.parse(location.search).page) - 1),
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      date: date !== null ? setDateFormat_yyyyMM(date) : "",
      scheduleId: scheduleId ? scheduleId : "",
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [deleteScheduleM] = useMutation(DELETE);
  const [deleteScheduleGroupM] = useMutation(DELETE_GROUP);
  const [editScheduleCheckedM] = useMutation(EDIT_SCHEDULE_CHECKED);

  const handlePage = (page) => {
    history.replace({
      pathname: `/schedule/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc"}&page=${page.selected + 1}`,
    });
  };

  const getData = async () => {
    setData(null);

    let dateMonth = null;
    if (date) {
      dateMonth = setDateFormat_yyyyMM(date);
    }

    const { data } = await dataRefetch({
      machine: location.state.machine ? location.state.machine : "",
      scheduleId,
      date: dateMonth,
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc",
      skip: 10 * (Number(queryString.parse(location.search).page) - 1),
    });
    if (data.getListSchedule && !data.getListSchedule.error) {
      console.log(data.getListSchedule);
      setData(data.getListSchedule);
    } else {
      setData(null);
    }
  };

  const deleteScheduleConfirm = async (id) => {
    const confirm = window.confirm("정말 삭제하시겠습니까?");

    if (confirm) {
      await editScheduleChecked(-1, id);
    }
  };

  const deleteSchedule = async (id) => {
    const { data } = await deleteScheduleM({
      variables: {
        scheduleId: id,
      },
    });

    if (data && data.deleteSchedule) {
      if (data.deleteSchedule.error) {
        alert(data.deleteSchedule.error);
      } else {
        alert("해당 스케쥴을 삭제했습니다.");

        if (scheduleId === id) {
          history.goBack();
        } else {
          getData();
        }
      }
    } else {
      alert("오류가 발생하였습니다.");
    }
  };

  const deleteScheduleGroupConfirm = async (scheduleGroupId) => {
    const confirm = window.confirm("지난 스케쥴을 제외한 관련된 모든 스케쥴을 삭제하시겠습니까?");

    if (confirm) {
      await editScheduleChecked(scheduleGroupId, -1);
    }
  };

  const deleteScheduleGroup = async (scheduleGroupId) => {
    const { data } = await deleteScheduleGroupM({
      variables: {
        scheduleGroupId,
      },
    });

    if (data && data.deleteScheduleGroup) {
      if (data.deleteScheduleGroup.error) {
        alert(data.deleteScheduleGroup.error);
      } else {
        alert("지난 스케쥴을 제외한 관련된 모든 스케쥴을 삭제했습니다.");

        history.goBack();
      }
    } else {
      alert("오류가 발생하였습니다.");
    }
  };

  const editScheduleChecked = async (scheduleGroupId, scheduleId) => {
    const { data } = await editScheduleCheckedM({
      variables: {
        scheduleGroupId,
        scheduleId,
        type: "DELETE",
      },
    });

    if (data && data.editScheduleChecked) {
      if (data.editScheduleChecked.success) {
        let confirm = true;

        if (data.editScheduleChecked.error) {
          confirm = window.confirm(data.editScheduleChecked.error);
        }

        if (confirm) {
          if (scheduleGroupId > 0) {
            await deleteScheduleGroup(scheduleGroupId);
          } else if (scheduleId > 0) {
            await deleteSchedule(scheduleId);
          }
        }
      } else {
        alert(data.editScheduleChecked.error);
      }
    } else {
      alert("오류가 발생하였습니다.");
    }
  };

  const toScheduleEdit = (scheduleId, editMode = "one") => {
    history.push({
      pathname: `/schedule/add/`,
      state: {
        machine: location.state.machine,
        scheduleId: scheduleId,
        editMode: editMode,
      },
    });
  };

  useEffect(() => {
    getData();
    setShowDate(false);
  }, [date]);

  useEffect(() => {
    console.log(location.state.scheduleId);
    if (!location.state.machine || !location.state.scheduleId) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
      window.scrollTo(0, 0);

      getData();
    }
  }, [queryString.parse(location.search).page]);

  useEffect(() => {
    if (queryString.parse(location.search).order) {
      window.scrollTo(0, 0);

      getData();
    }
  }, [queryString.parse(location.search).order]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile} ${styles.minHeightFull}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.py40} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font28} ${styles.black} ${styles.textCenter}`}>{data && data.schedule ? `${data.schedule.name}` : ""}</p>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.mt10} ${styles.justifyContentCenter}`}>
            <p className={`${styles.fontB} ${styles.font12} ${styles.black}`}>
              {data && data.schedule ? data.schedule.teacher.name : ""}
              {`\u00A0`}|{`\u00A0`}
            </p>
            <p className={`${styles.fontB} ${styles.font12} ${styles.black}`}>{data && data.schedule ? setDateFormatKor_yyyyMMdd(new Date(data.schedule.date)) + " " + setDateFormatKor_day(new Date(data.schedule.date), true) : ""}</p>
          </div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
            <p className={`${styles.fontB} ${styles.font12} ${styles.black}`}>{data && data.schedule ? setDateFormatKor_aahhmm(new Date(data.schedule.date + " " + data.schedule.startTime)) + " ~ " + setDateFormatKor_aahhmm(new Date(data.schedule.date + " " + data.schedule.endTime), true) : ""}</p>
          </div>
          {data && data.schedule && data.schedule.scheduleGroup.delYn === "N" && (
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt35} ${styles.mx35}`}>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.center} ${styles.cursorPointer}`} onClick={() => toScheduleEdit(scheduleId)}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>해당 스케쥴만 수정</p>
              </div>
              <div
                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.center} ${styles.cursorPointer} ${styles.ml15}`}
                onClick={() => {
                  if (data && data.schedule) {
                    deleteScheduleConfirm(data.schedule.id);
                  }
                }}
              >
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>해당 스케쥴만 삭제</p>
              </div>
            </div>
          )}
        </div>
        {data && data.schedule && data.schedule.scheduleGroup.type !== 1 && (
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.mt10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow} ${styles.minHeightFull}`}>
            <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>관련된 수업 목록</p>
            {data && data.schedule && data.schedule.scheduleGroup.delYn === "N" && (
              <div className={`${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt30} ${styles.px70}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.py10} ${styles.center} ${styles.cursorPointer}`} onClick={() => toScheduleEdit(scheduleId, "all")}>
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관련된 모든 스케쥴 수정</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.flex1} ${styles.mt14} ${styles.py10} ${styles.center} ${styles.cursorPointer}`}
                  onClick={() => {
                    if (data && data.schedule) {
                      deleteScheduleGroupConfirm(data.schedule.scheduleGroupId);
                    }
                  }}
                >
                  <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관련된 모든 스케쥴 삭제</p>
                </div>
              </div>
            )}
            <p className={`${styles.flex2} ${styles.mt30} ${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`} onClick={() => setShowDate(!showDate)}>
              {date ? setDateFormat_yyyyMM(date) : data && data.schedule ? setDateFormat_yyyyMM(new Date(data.schedule.date)) : ""}
              <img src={require("../../../assets/images/icon_dropdown_down.png").default} className={`${styles.icon15} ${styles.ml5}`} />
            </p>
            {showDate && (
              <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 45, right: 45 }}>
                <div className={`${styles.py10} ${styles.flex1} ${styles.bgWhite}`}>
                  <Calendar
                    calendarType={"US"}
                    value={date}
                    maxDetail={"year"}
                    nextLabel={
                      <span>
                        <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                      </span>
                    }
                    next2Label={null}
                    prevLabel={
                      <span>
                        <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                      </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.px60}`}>{label}</p>}
                    tileClassName={`${styles.fontR} ${styles.font14} ${styles.py10}`}
                    onChange={(selectDate) => {
                      setDate(selectDate);
                    }}
                  />
                </div>
              </div>
            )}
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.pr10}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>스케쥴명</p>
              </div>
            </div>
            {loadingData ? (
              <Skeleton height={50} count={3} />
            ) : (
              data?.schedules?.map((schedule) => {
                return <ScheduleListItem key={schedule.id} schedule={schedule} deleteSchedule={deleteScheduleConfirm} toScheduleEdit={toScheduleEdit} isShowEditBtns={data && data.schedule && data.schedule.scheduleGroup.delYn === "N"} />;
              })
            )}
            {data?.schedulesTotal > 10 ? (
              <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.schedulesTotal ? parseInt((data?.schedulesTotal - 1) / 10) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingData ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingData && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleList;
