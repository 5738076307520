import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import ConsultMachine from '../../../components/Consult/Mobile/ConsultMachine';
import ConsultPt from '../../../components/Consult/Mobile/ConsultPt';

const MACHINE = gql`
    query getMachines($skip: Int!){
        getMachines(skip: $skip){
            machines{
                id
                username
                name
                status
                createdAt
                requestTotal
                requestReadyTotal
            }
            total
        }
    }
`

const PT = gql`
    query getPtRequests($skip: Int!, $order: String!){
        getPtRequests(skip: $skip, order: $order, status: "ready"){
            ptRequests{
                id
                name
                contact
                category{
                    id
                    name
                }
                extra
                status
                machine{
                    id
                    username
                    name
                }
                createdAt
            }
            total
        }
    }
`

const ConsultHome = () => {
    const history = useHistory()
    const location = useLocation()

    const { data, loading:loadingMachine, refetch } = useQuery(MACHINE, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0
        }
    })

    const { data:pt, loading:loadingPt, refetch:refetchPt } = useQuery(PT, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0,
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"
        }
    })

    const handlePage = (page) => {
        history.push(`/consult/?page=${page.selected + 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
    }

    const handleTPage = (page) => {
        history.push(`/consult/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
    }

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            refetch({
                skip: 5*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page])

    useEffect(() => {
        if(queryString.parse(location.search).tpage && !isNaN(Number(queryString.parse(location.search).tpage))){
            window.scrollTo(0,0)
            refetchPt({
                skip: 5*(Number(queryString.parse(location.search).tpage) - 1),
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"
            })
        }
    }, [queryString.parse(location.search).tpage, queryString.parse(location.search).order])

    useEffect(() => {
        if(queryString.parse(location.search).page && queryString.parse(location.search).tpage && queryString.parse(location.search).order){
            
        }
        else{
            history.replace(`/consult/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`)
        }
    }, [])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        상담 관리
                    </p>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt45}`}>
                        <div className={`${styles.flex1} ${styles.pr10}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                기기명
                            </p>
                        </div>
                    </div>
                    {loadingMachine ? (
                        <Skeleton height={50} count={3}/>
                    ) : (
                        data?.getMachines?.machines?.map(machine => {
                            return(
                                <ConsultMachine key={machine.id} machine={machine} />
                            )
                        })
                    )}
                    {(data?.getMachines?.total > 5) ? (
                        <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                            <ReactPaginate
                            previousLabel={(
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                            )}
                            nextLabel={(
                                <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                            )}
                            breakLabel={(
                                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    &#183;&#183;&#183;
                                </p>
                            )}
                            breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            breakLinkClassName={`${styles.icon30} ${styles.center}`}
                            pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                            activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                            previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                            previousLinkClassName={`${styles.icon30} ${styles.center}`}
                            nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                            nextLinkClassName={`${styles.icon30} ${styles.center}`}
                            disabledClassName={`${styles.icon30} ${styles.center}`}
                            pageCount={(data?.getMachines?.total) ? parseInt((data?.getMachines?.total - 1)/5) + 1 : 1}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={loadingMachine ? null : handlePage}
                            initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                            containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                            />
                            {loadingMachine && (
                                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                            )}
                        </div>
                    ) : null}
                </div>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow} ${styles.mt20}`}>
                    <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.textCenter}`}>
                        미처리 상담 목록 바로보기
                    </p>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt45}`}>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                신청일시
                            </p>
                        </div>
                        <div className={`${styles.flex2} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                신청자명
                            </p>
                        </div>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                처리 여부
                            </p>
                        </div>
                    </div>
                    {loadingPt ? (
                        <Skeleton height={50} count={3}/>
                    ) : (
                        pt?.getPtRequests?.ptRequests?.map(pt => {
                            return(
                                <ConsultPt key={pt.id} pt={pt} />
                            )
                        })
                    )}
                    {(pt?.getPtRequests?.total > 5) ? (
                        <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                            <ReactPaginate
                            previousLabel={(
                                <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                            )}
                            nextLabel={(
                                <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                            )}
                            breakLabel={(
                                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                    &#183;&#183;&#183;
                                </p>
                            )}
                            breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            breakLinkClassName={`${styles.icon30} ${styles.center}`}
                            pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                            activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                            activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                            previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                            previousLinkClassName={`${styles.icon30} ${styles.center}`}
                            nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                            nextLinkClassName={`${styles.icon30} ${styles.center}`}
                            disabledClassName={`${styles.icon30} ${styles.center}`}
                            pageCount={(pt?.getPtRequests?.total) ? parseInt((pt?.getPtRequests?.total - 1)/5) + 1 : 1}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={4}
                            onPageChange={loadingPt ? null : handleTPage}
                            initialPage={queryString.parse(location.search).tpage ? Number(queryString.parse(location.search).tpage) - 1 : 0}
                            containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                            />
                            {loadingPt && (
                                <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default ConsultHome;