import SelectEntranceType from "./SelectEntranceType";
import { AUTH_TYPE_NONE, AUTH_TYPE_DRCT } from "./SelectEntranceType";
import { useQuery, gql, useMutation } from "@apollo/client";
import React, { useEffect, useState, useRef } from "react";
import styles from "../../styles/styles.module.scss";
const { billSm } = require("../../common/modal");
const Modal = require("react-modal");

const ADD_MEMBER_MACHINE = gql`
  mutation addMemberMachine($type: String!, $name: String!, $mobile: String!, $auth: String!, $startDate: String, $endDate: String, $membershipId: Int, $machineId: Int) {
    addMemberMachine(type: $type, name: $name, mobile: $mobile, auth: $auth, startDate: $startDate, endDate: $endDate, membershipId: $membershipId, isAdmin: true, machineId: $machineId) {
      success
      error
      memberId
    }
  }
`;

const MEMBER_AUTH = gql`
  query getMemberAuth($userName: String!, $userHpno: String!) {
    getMemberAuth(userName: $userName, userHpno: $userHpno) {
      id
      userName
      userHpno
      authStatus
      createdAt
      entrancesystem
    }
  }
`;

const AddEntrance = ({ machine, showModal, cancel, addMemberType, member, start, end, membershipId, setLoading, addMember }) => {
  const [authType, setAuthType] = useState("");
  const [step, setStep] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const [addMemberMachineM] = useMutation(ADD_MEMBER_MACHINE);

  const { data: dataMemberAuth } = useQuery(MEMBER_AUTH, {
    fetchPolicy: "network-only",
    variables: {
      userName: member?.name,
      userHpno: `010${member?.mobile}`, //contact.replace(/-/g, '')
    },
  });

  let authStatus = dataMemberAuth?.getMemberAuth?.authStatus;
  console.log("MEMBER_AUTH:" + member?.name + " " + member?.mobile + " " + authStatus);
  // useEffect(() => {
  //     setShowSelectEntranceType(showModal);
  // }, [showModal]);

  const addEntrance = async () => {
    try {
      console.log("addEntrance!!");
      setStep(1);
      setErrorMsg("");

      let startDate = null;
      let endDate = null;
      if (addMemberType === "add") {
        let s_year = start.getFullYear();
        let s_month = start.getMonth() + 1;
        let s_day = start.getDate();

        startDate = `${s_year}/${s_month < 10 ? "0" + s_month : s_month}/${s_day < 10 ? "0" + s_day : s_day}`;

        let e_year = end.getFullYear();
        let e_month = end.getMonth() + 1;
        let e_day = end.getDate();

        endDate = `${e_year}/${e_month < 10 ? "0" + e_month : e_month}/${e_day < 10 ? "0" + e_day : e_day}`;
      }

      const { data } = await addMemberMachineM({
        variables: {
          type: addMemberType,
          name: member.name,
          mobile: member.mobile,
          auth: authType,
          startDate: startDate,
          endDate: endDate,
          membershipId: membershipId ? membershipId : null,
          machineId: machine.id,
        },
      });
      setLoading(false);
      if (data && data.addMemberMachine.success) {
        initialize();
        if (addMemberType === "edit") {
          setStep(4);
        } else {
          cancel();
          await addMember(data.addMemberMachine.memberId);
        }
      } else if (data && data.addMemberMachine.error) {
        if (data.addMemberMachine.error === "9") {
          setErrorMsg("출입시스템에 이미 존재하는 회원입니다.");
        } else if (data.addMemberMachine.error === "인증이 되지 않았습니다.") {
          setErrorMsg("등록에 실패하였습니다.\n다시 시도하시겠습니까?");
        } else {
          setErrorMsg(data.addMemberMachine.error === "999" ? "이미 등록된 카드입니다.\n다시 등록 하시겠습니까?" : data.addMemberMachine.error);
        }
      } else {
        setStep(3);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      setStep(3);
    }
  };

  const initialize = () => {
    setAuthType("");
    setStep(0);
  };

  useEffect(() => {
    if (authType.length > 0) {
      addEntrance();
    }
  }, [authType]);
  useEffect(() => {
    if (errorMsg) {
      setStep(3);
    }
  }, [errorMsg]);

  return (
    <Modal
      isOpen={showModal}
      // onRequestClose={() => setShowModal(false)}
      style={billSm}
    >
      <div className={`${styles.py10}`}>
        {step === 0 && (
          <SelectEntranceType
            showModal={showModal}
            machine={machine}
            setAuthTypeSubmit={(authType) => {
              setAuthType(authType);
            }}
            cancel={cancel}
            authStatus={authStatus}
          />
        )}
        {step === 1 && (
          <div className={`${styles.textCenter} ${styles.flex1} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`} style={{ minHeight: 230, marginTop: 10, display: "flex" }}>
            <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>{authType === AUTH_TYPE_DRCT || authType === AUTH_TYPE_NONE ? "처리중입니다." : "인증기에 인증 수단을 스캔하십시오."}</p>
          </div>
        )}
        {step === 2 && (
          <div className={`${styles.textCenter} ${styles.flex1} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`} style={{ minHeight: 230, marginTop: 10, display: "flex" }}>
            <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>등록이 완료되었습니다.</p>
          </div>
        )}
        {step === 3 && (
          <div className={`${styles.textCenter} ${styles.flex1} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`} style={{ minHeight: 230, marginTop: 10, display: "flex", flexDirection: "column" }}>
            <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>
              {errorMsg.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < errorMsg.split("\n").length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.mt30}`} onClick={cancel}>
                <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>취 소</p>
              </div>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.mt30}`} onClick={addEntrance} style={{ marginLeft: 20 }}>
                <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>재시도</p>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className={`${styles.textCenter} ${styles.flex1} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`} style={{ minHeight: 230, marginTop: 10, display: "flex", flexDirection: "column" }}>
            <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>처리가 완료되었습니다</p>
            <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.mt30}`} onClick={cancel}>
              <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>확인</p>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddEntrance;
