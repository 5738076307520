export const colors2 = [
    "#FF7700",
    "#214033"
]

export const colors3 = [
    "#FF7700",
    "#883414",
    "#214033"
]

export const colors = [
    "#FFDD4D",
    "#FF7700",
    "#883414",
    "#FFBC4D",
    "#C3310F",
    "#214033",
    "#008B55",
    "#4ABE5C",
    "#8F5B00",
    "#C19800",
    "#272221",
    "#DECD00",
    "#7D9F0D",
    "#A6D310",
    "#CFDDA0"
]