import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from "../../../hooks/useInput";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import {billSm} from "../../../common/modal";
import * as PropTypes from "prop-types";
import TodayReservationMember from "../../../components/Schedule/Mobile/TodayReservationMember";
import Calendar from "react-calendar";


const RESERVATIONS = gql`
    query getSearchListScheduleReservation(
        $machine: String!
        $order: String!
        $skip: Int!
        $q: String
        $start: String
        $end: String
    ) {
        getSearchListScheduleReservation(
            machine: $machine
            order: $order
            skip: $skip
            q: $q
            start: $start
            end: $end
        ) {
            scheduleReservations {
                id
                status
                scheduleid
                schedulename
                scheduledate
                schedulestart
                scheduleendtime
                membername
                mobile
                gender
            }
            total
            error
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
            username
        }
    }
`;

const RESERVATION = gql`
    mutation addScheduleReservation(
        $machine: String!
        $memberId: Int!
        $scheduleId: Int!
        $status: Int!
        $over: Boolean!
        $limit: Boolean!
    ) {
        addScheduleReservation(machine: $machine, memberId: $memberId, scheduleId: $scheduleId, status: $status, over: $over, limit: $limit) {
            success
            error
            over
            limit
        }
    }
`;


const ScheduledSearchList = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput(
        queryString.parse(location.search).q
            ? queryString.parse(location.search).q
            : '',
    );

    let startDt = queryString.parse(location.search).start ? new Date(queryString.parse(location.search).start) : null;
    let endDt = queryString.parse(location.search).end ? new Date(queryString.parse(location.search).end) : null;

    const [showOverModal, setShowOverModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalMember, setModalMember] = useState(null);
    const [startDate, setStartDate] = useState(startDt);
    const [endDate, setEndDate] = useState(endDt);
    const [showStartDate, setShowStartData] = useState(false);
    const [showEndDate, setShowEndData] = useState(false);

    const [addReservationM] = useMutation(RESERVATION);

    const {
        data,
        loading: loadingMember,
        refetch,
    } = useQuery(RESERVATIONS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
            skip: queryString.parse(location.search).page
                ? parseInt(((queryString.parse(location.search).page - 1) / 10 ))
                : 0,
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : "",
            start: queryString.parse(location.search).start
                ? queryString.parse(location.search).start
                : "",
            end: queryString.parse(location.search).end
                ? queryString.parse(location.search).end
                : "",
        },
    });

    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });
    const handlePage = page => {

        let startStr = "";
        if (startDate) {
            startStr = startDate.toISOString().split("T")[0];
        }
        let endStr = "";
        if (endDate) {
            endStr = endDate.toISOString().split("T")[0];
        }
        console.log('page.selected' ,page.selected);

        history.push({
            pathname: `/schedule/reservation/search_list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&page=${page.selected + 1}&start=${startStr}&end=${endStr}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };


    const handleStart = start => {
        if(endDate && start > endDate){
            alert('시작일이 마지막일보다 클 수 없습니다.');
        }else{
            setStartDate(new Date(start.getTime() + 1000*60*60*9));
            setShowStartData(false);
        }
    };

    const handleEnd = end => {
        let endTime = end.getTime() + 1000*60*60*9;
        if(startDate && startDate > endTime){
            alert('마지막일이 시작일보다 작을 수 없습니다.');
        }else{
            setEndDate(new Date(end.getTime() + 1000*60*60*9));
            setShowEndData(false);
        }
    };

    const handleOrder = order => {
        if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'date') {
            if (queryString.parse(location.search).order === 'date_asc') {
                applyOrder('date_dsc');
            } else if (queryString.parse(location.search).order === 'date_dsc') {
                applyOrder('date_asc');
            } else {
                applyOrder('date_asc');
            }
        } else if (order === 'user_name') {
            if (queryString.parse(location.search).order === 'user_name_asc') {
                applyOrder('user_name_dsc');
            } else if (queryString.parse(location.search).order === 'user_name_dsc') {
                applyOrder('user_name_asc');
            } else {
                applyOrder('user_name_asc');
            }
        } else if (order === 'gender') {
            if (queryString.parse(location.search).order === 'gender_asc') {
                applyOrder('gender_dsc');
            } else if (queryString.parse(location.search).order === 'gender_dsc') {
                applyOrder('gender_asc');
            } else {
                applyOrder('gender_asc');
            }
        } else {
            applyOrder('name_asc');
        }
    };

    const applyOrder = order => {
        history.replace({
            pathname: '/schedule/reservation/search_list/',
            state: location.state,
            search: `?order=${order}&page=${
                queryString.parse(location.search).page
                    ? (queryString.parse(location.search).page -1) / 10 + 1
                    : 1
            }&start=${
                queryString.parse(location.search).start
            }&end=${
                queryString.parse(location.search).end
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const submit = async (e) => {
        e.preventDefault();
        searchCheckIn();
    };

    const searchCheckIn = async () => {
        let startStr = "";
        if (startDate) {
            startStr = startDate.toISOString().split("T")[0];
        }
        let endStr = "";
        if (endDate) {
            endStr = endDate.toISOString().split("T")[0];
        }

        console.log("startStr", startStr);
        console.log("endStr", endStr);

        history.push({
            pathname: `/schedule/reservation/search_list/`,
            state: location.state,
            search: `?order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : "name_asc"
            }&page=1&start=${startStr}&end=${endStr}&q=${
                q.value
                    ? q.value
                    : ''
            }`,
        });
    }

    useEffect(() => {
        let startStr = "";
        if (startDate) {
            startStr = startDate.toISOString().split("T")[0];
        }
        let endStr = "";
        if (endDate) {
            endStr = endDate.toISOString().split("T")[0];
        }
        if (location.state.machine) {
            if (
                queryString.parse(location.search).page &&
                !isNaN(Number(queryString.parse(location.search).page))
            ) {
                window.scrollTo(0, 0);
                refetch({
                    skip:
                        (Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'name_asc',
                    q: q.value
                        ? q.value
                        : ''
                });
            } else {
                history.replace({
                    pathname: `/schedule/reservation/search_list/`,
                    state: location.state,
                    search: `?order=${
                        queryString.parse(location.search).order
                            ? queryString.parse(location.search).order
                            : 'name_asc'
                    }&page=1&start=${startStr}&end=${endStr}&q=${
                        queryString.parse(location.search).q
                            ? queryString.parse(location.search).q
                            : ''
                    }`,
                });
            }
        } else {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        예약 검색
                        {`${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <form
                        action={""}
                        onSubmit={submit}
                        className={`${styles.mt45} ${styles.px40}`}
                    >
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.mb10} ${styles.justifyContentBetween}`}
                        >
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                onClick={() =>
                                    setShowStartData(!showStartDate)
                                }
                                style={{width: '45%', height: 42}}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                                    <p
                                        className={`${styles.inputWhite} ${styles.flex1} ${
                                            startDate
                                                ? styles.black
                                                : styles.grayA2
                                        }`}>
                                        {startDate
                                            ? `${startDate.getFullYear()}/${
                                                startDate.getMonth() + 1 <
                                                10
                                                    ? '0' +
                                                    String(
                                                        startDate.getMonth() +
                                                        1,
                                                    )
                                                    : startDate.getMonth() +
                                                    1
                                            }/${
                                                startDate.getDate() < 10
                                                    ? '0' +
                                                    String(
                                                        startDate.getDate(),
                                                    )
                                                    : startDate.getDate()
                                            }`
                                            : '2022-01-01'}
                                    </p>
                                    <div
                                        className={`${styles.py10}`}
                                        style={{width: 25, height: 42, textAlign: 'center'}}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            setStartDate(null);
                                        }}>
                                        <img
                                            src={require('../../../assets/images/icon_close.png').default}
                                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className={`${styles.px5} ${styles.py10}`}>~</p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                onClick={() =>
                                    setShowEndData(!showEndDate)
                                }
                                style={{width: '45%', height: 42}}>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                                    <p
                                        className={`${styles.inputWhite} ${styles.flex1} ${
                                            endDate
                                                ? styles.black
                                                : styles.grayA2
                                        }`}>
                                        {endDate
                                            ? `${endDate.getFullYear()}/${
                                                endDate.getMonth() + 1 <
                                                10
                                                    ? '0' +
                                                    String(
                                                        endDate.getMonth() +
                                                        1,
                                                    )
                                                    : endDate.getMonth() +
                                                    1
                                            }/${
                                                endDate.getDate() < 10
                                                    ? '0' +
                                                    String(
                                                        endDate.getDate(),
                                                    )
                                                    : endDate.getDate()
                                            }`
                                            : '2022-01-01'}
                                    </p>
                                    <div
                                        className={`${styles.py10}`}
                                        style={{width: 25, height: 42, textAlign: 'center'}}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            setEndDate(null);
                                        }}>
                                        <img
                                            src={require('../../../assets/images/icon_close.png').default}
                                            className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}
                        >
                            <img
                                src={require("../../../assets/images/icon_search.png").default}
                                alt={"search"}
                                className={`${styles.icon20} ${styles.cursorPointer}`}
                                onClick={submit}
                            />
                            <input
                                type={"text"}
                                name={"q"}
                                value={q.value}
                                onChange={q.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                                placeholder={"사용자명 및 연락처 검색"}
                            />
                        </div>
                    </form>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt10} ${styles.widthFull}`}
                    >
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer} ${styles.flex1}`}
                            onClick={() => handleOrder("name")}
                        >
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            >
                                수업명
                            </p>
                            <div
                                className={`${styles.ml10} ${styles.center}`}>
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'name_asc'
                                            ? require('../../../assets/images/icon_sort_up_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_up.png')
                                                .default
                                    }
                                    alt={'asc'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'name_dsc'
                                            ? require('../../../assets/images/icon_sort_down_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_down.png')
                                                .default
                                    }
                                    alt={'dsc'}
                                    className={`${styles.cursorPointer} ${styles.mt5}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer} ${styles.flex1}`}
                            onClick={() => {handleOrder('date')}}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            >
                                예약 시간
                            </p>
                            <div
                                className={`${styles.ml10} ${styles.center}`}>
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'date_asc'
                                            ? require('../../../assets/images/icon_sort_up_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_up.png')
                                                .default
                                    }
                                    alt={'asc'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'date_dsc'
                                            ? require('../../../assets/images/icon_sort_down_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_down.png')
                                                .default
                                    }
                                    alt={'dsc'}
                                    className={`${styles.cursorPointer} ${styles.mt5}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer} ${styles.flex1}`}
                            onClick={() => {handleOrder('user_name')}}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            >
                                회원명
                            </p>
                            <div
                                className={`${styles.ml10} ${styles.center}`}>
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'user_name_asc'
                                            ? require('../../../assets/images/icon_sort_up_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_up.png')
                                                .default
                                    }
                                    alt={'asc'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                                <img
                                    src={
                                        queryString.parse(location.search)
                                            .order === 'user_name_dsc'
                                            ? require('../../../assets/images/icon_sort_down_orange.png')
                                                .default
                                            : require('../../../assets/images/icon_sort_down.png')
                                                .default
                                    }
                                    alt={'dsc'}
                                    className={`${styles.cursorPointer} ${styles.mt5}`}
                                    style={{
                                        width: 18 * 0.5,
                                        height: 10 * 0.5,
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer} ${styles.flex1}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                            >
                                연락처
                            </p>
                        </div>
                        {/*<div*/}
                        {/*    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer} ${styles.width10}`}>*/}
                        {/*    <p*/}
                        {/*        className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>*/}
                        {/*        성별*/}
                        {/*    </p>*/}
                        {/*    <div*/}
                        {/*        className={`${styles.ml10} ${styles.center}`}>*/}
                        {/*        <img*/}
                        {/*            src={*/}
                        {/*                queryString.parse(location.search)*/}
                        {/*                    .order === 'user_name_asc'*/}
                        {/*                    ? require('../../../assets/images/icon_sort_up_orange.png')*/}
                        {/*                        .default*/}
                        {/*                    : require('../../../assets/images/icon_sort_up.png')*/}
                        {/*                        .default*/}
                        {/*            }*/}
                        {/*            alt={'asc'}*/}
                        {/*            className={`${styles.cursorPointer}`}*/}
                        {/*            style={{*/}
                        {/*                width: 18 * 0.5,*/}
                        {/*                height: 10 * 0.5,*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            src={*/}
                        {/*                queryString.parse(location.search)*/}
                        {/*                    .order === 'user_name_dsc'*/}
                        {/*                    ? require('../../../assets/images/icon_sort_down_orange.png')*/}
                        {/*                        .default*/}
                        {/*                    : require('../../../assets/images/icon_sort_down.png')*/}
                        {/*                        .default*/}
                        {/*            }*/}
                        {/*            alt={'dsc'}*/}
                        {/*            className={`${styles.cursorPointer} ${styles.mt5}`}*/}
                        {/*            style={{*/}
                        {/*                width: 18 * 0.5,*/}
                        {/*                height: 10 * 0.5,*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div style={{ minHeight: 600 }}>
                        {loadingMember ? (
                            <Skeleton height={50} count={3} />
                        ) : data && data.getSearchListScheduleReservation &&
                        data.getSearchListScheduleReservation.scheduleReservations.length > 0 ? (
                            data.getSearchListScheduleReservation.scheduleReservations.map(scheduleReservations => {
                                return (
                                    <TodayReservationMember
                                        key={scheduleReservations.id}
                                        scheduleReservations={scheduleReservations}
                                        machine={
                                            machine && machine.getMachine
                                                ? machine.getMachine
                                                : {}
                                        }
                                    />
                                );
                            })
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    회원 목록이 없습니다.
                                </p>
                            </div>
                        )}
                        {data?.getSearchListScheduleReservation?.total > 10 ? (
                            <div
                                className={`${styles.mt60} ${styles.mb10}`}
                                style={{ position: 'relative' }}>
                                <ReactPaginate
                                    previousLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_back.png')
                                                    .default
                                            }
                                            alt={'이전'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    nextLabel={
                                        <img
                                            src={
                                                require('../../../assets/images/icon_front.png')
                                                    .default
                                            }
                                            alt={'다음'}
                                            className={`${styles.cursorPointer}`}
                                            style={{
                                                width: 45 * 0.2,
                                                height: 79 * 0.2,
                                            }}
                                        />
                                    }
                                    breakLabel={
                                        <p
                                            className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    }
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={
                                        data?.getSearchListScheduleReservation?.total
                                            ? parseInt(
                                            (data?.getSearchListScheduleReservation?.total -
                                                1) /
                                            10,
                                        ) + 1
                                            : 1
                                    }
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={
                                        loadingMember ? null : handlePage
                                    }
                                    initialPage={
                                        queryString.parse(location.search)
                                            .page
                                            ? Number(
                                            queryString.parse(
                                                location.search,
                                            ).page,
                                        ) - 1
                                            : 0
                                    }
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingMember && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor:
                                                'rgba(255, 255, 255, 0.4)',
                                        }}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showStartDate}
                onRequestClose={() => setShowStartData(false)}
                style={billSm}>
                <Calendar
                    calendarType={'US'}
                    value={startDate}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleStart}
                />
            </Modal>
            <Modal
                isOpen={showEndDate}
                onRequestClose={() => setShowEndData(false)}
                style={billSm}>
                <Calendar
                    calendarType={'US'}
                    value={endDate}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleEnd}
                />
            </Modal>
        </div>
    );
};

export default ScheduledSearchList;
