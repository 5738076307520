import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import cache from '../../../apollo/cache';
import useInput from '../../../hooks/useInput';
import Video from '../../../components/Video/PC/Video';

const VIDEO = gql`
    query getVideos($order: String!, $skip: Int!, $q: String) {
        getVideos(order: $order, skip: $skip, q: $q) {
            videos {
                id
                name
                category {
                    id
                    name
                }
                detailCategory {
                    id
                    name
                }
                status
                machinesCount
            }
            total
        }
    }
`;

const DELETE = gql`
    mutation deleteVideo($id: Int!) {
        deleteVideo(id: $id)
    }
`;

const VideoHome = () => {
    const history = useHistory();
    const location = useLocation();

    const q = useInput('');

    const [loading, setLoading] = useState(false);

    const {
        data,
        loading: loadingVideo,
        refetch,
    } = useQuery(VIDEO, {
        fetchPolicy: 'cache-and-network',
        variables: {
            order: queryString.parse(location.search).order
                ? queryString.parse(location.search).order
                : 'name_asc',
            skip: 0,
            q: queryString.parse(location.search).q
                ? queryString.parse(location.search).q
                : '',
        },
    });

    const [deleteVideoM] = useMutation(DELETE);

    const submit = async e => {
        e.preventDefault();
        history.push({
            pathname: `/video/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${q.value}`,
        });
    };

    const handlePage = page => {
        history.push({
            pathname: `/video/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${
                queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc'
            }&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const applyOrder = order => {
        history.replace({
            pathname: `/video/`,
            state: location.state,
            search: `?page=${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }&order=${order}&q=${
                queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : ''
            }`,
        });
    };

    const handleOrder = order => {
        if (order === 'name') {
            if (queryString.parse(location.search).order === 'name_asc') {
                applyOrder('name_dsc');
            } else if (
                queryString.parse(location.search).order === 'name_dsc'
            ) {
                applyOrder('name_asc');
            } else {
                applyOrder('name_asc');
            }
        } else if (order === 'category') {
            if (queryString.parse(location.search).order === 'category_asc') {
                applyOrder('category_dsc');
            } else if (
                queryString.parse(location.search).order === 'category_dsc'
            ) {
                applyOrder('category_asc');
            } else {
                applyOrder('category_asc');
            }
        } else if (order === 'detail_category') {
            if (
                queryString.parse(location.search).order ===
                'detail_category_asc'
            ) {
                applyOrder('detail_category_dsc');
            } else if (
                queryString.parse(location.search).order ===
                'detail_category_dsc'
            ) {
                applyOrder('detail_category_asc');
            } else {
                applyOrder('detail_category_asc');
            }
        } else if (order === 'status') {
            if (queryString.parse(location.search).order === 'status_asc') {
                applyOrder('status_dsc');
            } else if (
                queryString.parse(location.search).order === 'status_dsc'
            ) {
                applyOrder('status_asc');
            } else {
                applyOrder('status_asc');
            }
        } else {
            applyOrder('name_asc');
        }
    };

    const remove = async video => {
        if (!loading && video) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                try {
                    const { data } = await deleteVideoM({
                        variables: {
                            id: video.id,
                        },
                    });
                    setLoading(false);
                    if (data && data.deleteVideo) {
                        cache.evict({
                            id: `Video:${video.id}`,
                        });
                        alert('삭제하였습니다.');
                    } else {
                        alert('오류가 발생하였습니다.');
                    }
                } catch {
                    setLoading(false);
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (
            queryString.parse(location.search).page &&
            !isNaN(Number(queryString.parse(location.search).page))
        ) {
            window.scrollTo(0, 0);
            refetch({
                order: queryString.parse(location.search).order
                    ? queryString.parse(location.search).order
                    : 'name_asc',
                skip:
                    10 * (Number(queryString.parse(location.search).page) - 1),
                q: queryString.parse(location.search).q
                    ? queryString.parse(location.search).q
                    : '',
            });
            q.setValue(queryString.parse(location.search).q);
        } else {
            history.replace({
                pathname: `/video/?page=1&order=${
                    queryString.parse(location.search).order
                        ? queryString.parse(location.search).order
                        : 'name_asc'
                }&q=${
                    queryString.parse(location.search).q
                        ? queryString.parse(location.search).q
                        : ''
                }`,
                state: location.state,
            });
        }
    }, [
        queryString.parse(location.search).page,
        queryString.parse(location.search).order,
        queryString.parse(location.search).q,
    ]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                운동 가이드 영상 관리
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                <form action={''} onSubmit={submit}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}
                                        style={{ width: 480 }}>
                                        <img
                                            src={
                                                require('../../../assets/images/icon_search.png')
                                                    .default
                                            }
                                            alt={'search'}
                                            className={`${styles.icon25} ${styles.cursorPointer}`}
                                            onClick={submit}
                                        />
                                        <input
                                            type={'text'}
                                            name={'q'}
                                            value={q.value}
                                            onChange={q.onChange}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            placeholder={
                                                '영상명, 카테고리명, 세부 카테고리명 검색'
                                            }
                                        />
                                    </div>
                                </form>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.ml35} ${styles.cursorPointer}`}
                                    onClick={() =>
                                        history.push('/video/category/edit/')
                                    }>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        카테고리 관리
                                    </p>
                                </div>
                                <div
                                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.ml10} ${styles.cursorPointer}`}
                                    onClick={() => history.push('/video/add/')}>
                                    <p
                                        className={`${styles.fontB} ${styles.font16} ${styles.white}`}>
                                        새 영상 등록
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('name')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    영상명
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'name_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('category')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    카테고리
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'category_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'category_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('detail_category')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    세부 카테고리
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'detail_category_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'detail_category_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                                onClick={() => handleOrder('status')}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태
                                </p>
                                <div
                                    className={`${styles.ml10} ${styles.center}`}>
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'status_asc'
                                                ? require('../../../assets/images/icon_sort_up_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_up.png')
                                                      .default
                                        }
                                        alt={'asc'}
                                        className={`${styles.cursorPointer}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                    <img
                                        src={
                                            queryString.parse(location.search)
                                                .order === 'status_dsc'
                                                ? require('../../../assets/images/icon_sort_down_orange.png')
                                                      .default
                                                : require('../../../assets/images/icon_sort_down.png')
                                                      .default
                                        }
                                        alt={'dsc'}
                                        className={`${styles.cursorPointer} ${styles.mt5}`}
                                        style={{
                                            width: 18 * 0.5,
                                            height: 10 * 0.5,
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    송출중인 기기수
                                </p>
                            </div>
                            <div
                                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}></div>
                        </div>
                        <div style={{ minHeight: 600 }}>
                            {loadingVideo ? (
                                <Skeleton height={50} count={3} />
                            ) : data &&
                              data.getVideos &&
                              data.getVideos.videos.length > 0 ? (
                                data.getVideos.videos.map(video => {
                                    return (
                                        <Video
                                            key={video.id}
                                            video={video}
                                            remove={() => remove(video)}
                                            loading={loading}
                                        />
                                    );
                                })
                            ) : (
                                <div
                                    className={`${styles.center}`}
                                    style={{ height: 600 }}>
                                    <p
                                        className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        영상이 없습니다.
                                    </p>
                                </div>
                            )}
                            {data?.getVideos?.total > 10 ? (
                                <div
                                    className={`${styles.mt60} ${styles.mb10}`}
                                    style={{ position: 'relative' }}>
                                    <ReactPaginate
                                        previousLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_back.png')
                                                        .default
                                                }
                                                alt={'이전'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        nextLabel={
                                            <img
                                                src={
                                                    require('../../../assets/images/icon_front.png')
                                                        .default
                                                }
                                                alt={'다음'}
                                                className={`${styles.cursorPointer}`}
                                                style={{
                                                    width: 45 * 0.2,
                                                    height: 79 * 0.2,
                                                }}
                                            />
                                        }
                                        breakLabel={
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                &#183;&#183;&#183;
                                            </p>
                                        }
                                        breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                        pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                        activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                        activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                        previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                        nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                        nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                        disabledClassName={`${styles.icon30} ${styles.center}`}
                                        pageCount={
                                            data?.getVideos?.total
                                                ? parseInt(
                                                      (data?.getVideos?.total -
                                                          1) /
                                                          10,
                                                  ) + 1
                                                : 1
                                        }
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={4}
                                        onPageChange={
                                            loadingVideo ? null : handlePage
                                        }
                                        initialPage={
                                            queryString.parse(location.search)
                                                .page
                                                ? Number(
                                                      queryString.parse(
                                                          location.search,
                                                      ).page,
                                                  ) - 1
                                                : 0
                                        }
                                        containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                    />
                                    {loadingVideo && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundColor:
                                                    'rgba(255, 255, 255, 0.4)',
                                            }}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoHome;
