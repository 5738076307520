import React from 'react';

import styles from '../../../styles/styles.module.scss';

const Location = ({
    location,
    remove,
    last
}) => {
    return (
        <div className={`${styles.row} ${styles.mx0} ${last ? "" : styles.mb15}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                {`${location.sido}${location.sigungu ? ` ${location.sigungu}` : ""}${location.bname ? ` ${location.bname}` : ""}`}
            </p>
            <img src={require("../../../assets/images/icon_close.png").default} alt={"delete"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml15} ${styles.mt3}`} onClick={remove} />
        </div>
    )
}

export default Location;