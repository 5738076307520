import React, { useState, useEffect, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import Calendar from 'react-calendar';
import { ResponsiveBar } from '@nivo/bar';

import styles from '../../styles/styles.module.scss';
import { colors2 } from '../../styles/colors';

const DATA = gql`
    query getMachineTotalSaleGraphBar($machine: String!, $period: String!, $date: String!){
        getMachineTotalSaleGraphBar(machine: $machine, period: $period date: $date){
            success
            data{
                x
                y
            }
        }
    }
`

const MachineTotalSaleBar = ({
    period,
    machine,
    height = 500
}) => {
    const timestamp = useRef(new Date().getTime())

    const [date, setDate] = useState(new Date())
    const [showDate, setShowDate] = useState(false)
    const [data, setData] = useState(null)

    const { refetch } = useQuery(DATA, {
        skip: true
    })

    const getData = async() => {
        setData(null)
        const now = new Date().getTime()
        timestamp.current = now
        const { data } = await refetch({
            machine,
            period,
            date: `${String(date.getFullYear()).slice(2,4)}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`
        });
        if(timestamp.current === now){
            if(data.getMachineTotalSaleGraphBar && data.getMachineTotalSaleGraphBar.success){
                setData(data.getMachineTotalSaleGraphBar.data)
            }
            else{
                setData(null)
            }
        }
    }

    console.log("MachineTotalSaleBar date ?? ", date);

    useEffect(() => {
        setShowDate(false)
        getData()
    }, [date, period])

    return (
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.flex1}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                    전체 판매 매출
                </p>
                <div className={`${styles.ml50}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowDate(!showDate)}>
                        <p className={`${styles.fontB} ${styles.font18} ${styles.black}`}>
                            {(period === "day") ? (
                                `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`
                            ) : (
                                (period === "week") ? (
                                    `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}`
                                ) : (
                                    `${date.getFullYear()}년`
                                )
                            )}
                        </p>
                        <img src={showDate ? require("../../assets/images/icon_dropdown_up.png").default : require("../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                    </div>
                </div>
            </div>
            {showDate && (
                <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentEnd}`}>
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.px20} ${styles.py20} ${styles.overflowHidden}`} style={{ width: 400 }}>
                        <Calendar
                        calendarType={"US"}
                        value={date}
                        maxDate={new Date()}
                        minDate={new Date(2021,0,1)}
                        minDetail={(period === "day") ? "month" : (period === "week") ? "year" : "decade"}
                        maxDetail={(period === "day") ? "month" : (period === "week") ? "year" : "decade"}
                        nextLabel={<span><img src={require('../../assets/images/icon_dropdown_right.png').default} alt={"다음"} className={`${styles.icon15}`} /></span>}
                        next2Label={null}
                        prevLabel={<span><img src={require('../../assets/images/icon_dropdown_left.png').default} alt={"이전"} className={`${styles.icon15}`} /></span>}
                        prev2Label={null}
                        navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                        tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                        onChange={setDate}
                        />
                    </div>
                </div>
            )}
            <div className={`${styles.mt50}`}>
                {data ? (
                    <div>
                        <div style={{ height }}>
                            <ResponsiveBar
                            data={data}
                            indexBy={"x"}
                            keys={["y"]}
                            margin={{ top: 10, right: 80, bottom: 50, left: 80 }}
                            minValue={0}
                            valueFormat={" >-,.2d"}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            groupMode={"grouped"}
                            colors={colors2}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                ...((period === "day") && {
                                    legend: '시',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                })
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '원',
                                legendPosition: 'middle',
                                legendOffset: -60,
                                format: " >-,.2d"
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={"#272221"}
                            />
                        </div>
                    </div>
                ) : (
                    <Skeleton height={height} />
                )}
            </div>
        </div>
    )
}

export default MachineTotalSaleBar;
