import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const Advertise = ({ advertise, remove, loading }) => {
    const history = useHistory();

    const date = new Date(Number(advertise.createdAt));

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {advertise.name}
                </p>
            </div>
            <div
                className={`${styles.flex3} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {advertise.description}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {advertise.ownerName}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {advertise.ownerContact}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${date.getFullYear()}-${
                        date.getMonth() + 1 < 10
                            ? '0' + String(date.getMonth() + 1)
                            : date.getMonth() + 1
                    }-${
                        date.getDate() < 10
                            ? '0' + String(date.getDate())
                            : date.getDate()
                    }`}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {advertise.status === 'ready' && '송출대기'}
                    {advertise.status === 'inuse' && '송출중'}
                    {advertise.status === 'stop' && '송출중지'}
                    {advertise.status === 'expired' && '계약만료'}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {numberWithCommas(advertise.machinesCount)}
                </p>
            </div>
            <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={
                        loading
                            ? null
                            : () =>
                                  history.push({
                                      pathname: '/advertise/edit/',
                                      state: {
                                          advertise,
                                      },
                                  })
                    }>
                    <p
                        className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                        수정
                    </p>
                </div>
                <img
                    src={
                        require('../../../assets/images/icon_trash.png').default
                    }
                    alt={'삭제'}
                    className={`${styles.icon20} ${styles.ml30} ${styles.cursorPointer}`}
                    style={{ opacity: loading ? 0.4 : 1 }}
                    onClick={remove}
                />
            </div>
        </div>
    );
};

export default Advertise;
