import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import styles from "../../../styles/styles.module.scss";
import MemberMachine from "../../../components/Member/PC/MemberMachine";
import Member from "../../../components/Member/PC/Member";

const MACHINE = gql`
  query getMachines($skip: Int!) {
    getMachines(skip: $skip) {
      machines {
        id
        username
        name
        status
        memberTotal
        createdAt
      }
      total
    }
  }
`;

const MEMBER = gql`
  query getExpiringMember($order: String!, $skip: Int!) {
    getExpiringMember(order: $order, skip: $skip) {
      members {
        id
        name
        mobile
        createdAt
        defaultMembership {
          id
          product {
            id
            name
            membershipType
          }
          start
          end
          status
          dDay
          membershipCount
          totalDeduct
        }
        memberships {
          id
          product {
            id
            name
            membershipType
          }
          start
          end
          status
          dDay
          membershipCount
          totalDeduct
        }
        machine {
          id
          username
          name
        }
      }
      total
    }
  }
`;

const MemberHome = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    loading: loadingMachine,
    refetch,
  } = useQuery(MACHINE, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
    },
  });
  const {
    data: member,
    loading: loadingMember,
    refetch: refetchMember,
  } = useQuery(MEMBER, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "end_asc",
    },
  });

  const handlePage = (page) => {
    history.push(`/member/?page=${page.selected + 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "end_asc"}`);
  };

  const handleTPage = (page) => {
    history.push(`/member/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "end_asc"}`);
  };

  const handleOrder = (order) => {
    if (order === "machine") {
      if (queryString.parse(location.search).order === "machine_asc") {
        applyOrder("machine_dsc");
      } else if (queryString.parse(location.search).order === "machine_dsc") {
        applyOrder("machine_asc");
      } else {
        applyOrder("machine_dsc");
      }
    } else if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_dsc");
      }
    } else if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "contact") {
      if (queryString.parse(location.search).order === "contact_asc") {
        applyOrder("contact_dsc");
      } else if (queryString.parse(location.search).order === "contact_dsc") {
        applyOrder("contact_asc");
      } else {
        applyOrder("contact_asc");
      }
    } else if (order === "end") {
      if (queryString.parse(location.search).order === "end_asc") {
        applyOrder("end_dsc");
      } else if (queryString.parse(location.search).order === "end_dsc") {
        applyOrder("end_asc");
      } else {
        applyOrder("end_asc");
      }
    } else {
      applyOrder("end_asc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/member/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  useEffect(() => {
    if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
      window.scrollTo(0, 0);
      refetch({
        skip: 5 * (Number(queryString.parse(location.search).page) - 1),
      });
    }
  }, [queryString.parse(location.search).page]);

  useEffect(() => {
    if (queryString.parse(location.search).tpage && !isNaN(Number(queryString.parse(location.search).tpage))) {
      window.scrollTo(0, 0);
      refetchMember({
        skip: 5 * (Number(queryString.parse(location.search).tpage) - 1),
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "end_asc",
      });
    }
  }, [queryString.parse(location.search).tpage, queryString.parse(location.search).order]);

  useEffect(() => {
    if (queryString.parse(location.search).page && queryString.parse(location.search).tpage && queryString.parse(location.search).order) {
    } else {
      history.replace(
        `/member/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${
          queryString.parse(location.search).order ? queryString.parse(location.search).order : "end_asc"
        }`
      );
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>회원 관리</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기계정</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원수</p>
              </div>
              <div className={`${styles.flex4}`}></div>
            </div>
            {loadingMachine ? (
              <Skeleton height={50} count={3} />
            ) : (
              data?.getMachines?.machines?.map((machine) => {
                return <MemberMachine key={machine.id} machine={machine} />;
              })
            )}
            {data?.getMachines?.total > 5 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getMachines?.total ? parseInt((data?.getMachines?.total - 1) / 5) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingMachine ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingMachine && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>만료 예정 회원</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("machine")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "machine_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "machine_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("contact")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원 연락처</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "contact_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "contact_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 시작일</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("end")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 종료일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "end_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "end_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>횟수</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 상태</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원 등록일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
            </div>
            {loadingMember ? (
              <Skeleton height={50} count={3} />
            ) : (
              member?.getExpiringMember?.members?.map((member) => {
                return <Member key={member.id} member={member} machine={member.machine} />;
              })
            )}
            {member?.getExpiringMember?.total > 5 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={member?.getExpiringMember?.total ? parseInt((member?.getExpiringMember?.total - 1) / 5) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingMember ? null : handleTPage}
                  initialPage={queryString.parse(location.search).tpage ? Number(queryString.parse(location.search).tpage) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingMember && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberHome;
