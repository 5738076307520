import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

import styles from "../../styles/styles.module.scss";
import { numberWithCommas } from "../../common/utility";

const Bill = ({ payment }) => {
  const billRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => billRef.current,
  });

  //console.log(payment);
  //console.log("라커 이용권 이름### " + payment.lockerAreaName);

  return (
    <div className={`${styles.bgWhite}`}>
      <div ref={billRef} className={`${styles.bgWhite} ${styles.py10} ${styles.px30}`}>
        {payment.status === "refund" && <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mb10}`}>{`<취소>`}</p>}
        <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.textCenter}`}>{payment.businessName ? payment.businessName : ""}</p>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt20}`}>{`상호: ${payment.businessName ? payment.businessName : ""}`}</p>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt5}`}>{`대표자: ${payment.businessOwnerName ? payment.businessOwnerName : ""}`}</p>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt5}`}>{`사업자번호: ${payment.businessNo ? payment.businessNo : ""}`}</p>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt5}`}>{`전화번호: ${payment.businessPhoneNo ? payment.businessPhoneNo : ""}`}</p>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.mt5}`}>{`주소: ${payment.businessAddress ? payment.businessAddress : ""}`}</p>
        <div className={`${styles.bgBlack} ${styles.my10}`} style={{ height: 1 }} />
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.flex2}`}>상품명</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>단가</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>수량</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>금액</p>
        </div>
        <div className={`${styles.bgBlack} ${styles.my10}`} style={{ height: 1 }} />
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
          {payment.type === "credit" ? (payment.productName ? payment.productName : "") : ""}
          {payment.type === "credit" && payment.lockerNumber ? " (시작일: " + payment.startDate + ", 락커구역: " + payment.lockerAreaName + ", 락커번호: " + payment.lockerNumber + ")" : " (시작일: " + payment.startDate + ")"}
          {payment.type === "cash" && "현금 거래"}
          {payment.type === "custom" && "금액 직접 입력"}
        </p>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.mt5}`}>
          <div className={`${styles.flex2}`}></div>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>{numberWithCommas(payment.totalAmount)}</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>{`${payment.status === "refund" ? "-" : ""}1`}</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.flex1}`}>{numberWithCommas(payment.totalAmount)}</p>
        </div>
        <div className={`${styles.bgBlack} ${styles.my10}`} style={{ height: 1 }} />
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight}`}>공급가액:</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.ml10}`}>{`${payment.status === "refund" ? "-" : ""}${numberWithCommas(payment.totalAmount - payment.surtax)}원`}</p>
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight}`}>부가세:</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textRight} ${styles.ml10}`}>{`${payment.status === "refund" ? "-" : ""}${numberWithCommas(payment.surtax)}원`}</p>
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
          <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.textRight}`}>합계:</p>
          <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.textRight} ${styles.ml10}`}>{`${payment.status === "refund" ? "-" : ""}${numberWithCommas(payment.totalAmount)}원`}</p>
        </div>
        <div className={`${styles.bgBlack} ${styles.my10}`} style={{ height: 1 }} />
        {payment.type !== "cash" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>카드사명:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>{payment.issuerInfo ? payment.issuerInfo : ""}</p>
          </div>
        )}
        {payment.type !== "cash" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>매입사명:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>{payment.acquireInfo ? payment.acquireInfo : ""}</p>
          </div>
        )}
        {payment.status === "refund" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>취소일시:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
              {payment.cancelDate && payment.cancelTime ? `${payment.cancelDate.slice(0, 2)}/${payment.cancelDate.slice(2, 4)}/${payment.cancelDate.slice(4)} ${payment.cancelTime.slice(0, 2)}:${payment.cancelTime.slice(2, 4)}:${payment.cancelTime.slice(4)}` : ""}
            </p>
          </div>
        )}
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>{payment.status === "refund" ? "원거래일:" : "거래일시:"}</p>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>
            {payment.approvalDate && payment.approvalTime ? `${payment.approvalDate.slice(0, 2)}/${payment.approvalDate.slice(2, 4)}/${payment.approvalDate.slice(4)} ${payment.approvalTime.slice(0, 2)}:${payment.approvalTime.slice(2, 4)}:${payment.approvalTime.slice(4)}` : ""}
          </p>
        </div>
        {payment.type !== "cash" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>카드번호:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>{payment.cardNo ? payment.cardNo : ""}</p>
          </div>
        )}
        {payment.type !== "cash" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>할부개월:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>{payment.installment === 0 ? "일시불" : `${payment.installment}개월`}</p>
          </div>
        )}
        {payment.type !== "cash" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>가맹번호:</p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>{payment.merchantNo ? payment.merchantNo : ""}</p>
          </div>
        )}
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>승인번호:</p>
          <p className={`${styles.fontB} ${styles.font18} ${styles.black} ${styles.ml10}`}>{payment.approvalNo ? payment.approvalNo : ""}</p>
        </div>
        {payment.machine?.usePgBill && (
          <div>
            <div className={`${styles.bgBlack} ${styles.my10}`} style={{ height: 1 }} />
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>결제대행사 정보</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>상호:</p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>한국정보통신(주)</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>대표자:</p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>임명수</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>사업자번호:</p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>116-81-19948</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>전화번호:</p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>1600-1234</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>주소:</p>
              <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.ml10}`}>서울특별시 중구 세종대로 39, 대한상공회의소 7층</p>
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={handlePrint}>
        <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>인쇄</p>
      </div>
    </div>
  );
};

export default Bill;
