import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { sleep } from "../../../common/utility";
import { numberReg } from "../../../common/regex";
import IntroImage from "../../../components/Home/IntroImage";
import { FETCH_URL } from "../../../config";

const CREATE = gql`
  mutation addMachine($username: String!, $password: String!, $name: String!, $status: String!, $intro: String, $images: String!) {
    addMachine(username: $username, password: $password, name: $name, status: $status, intro: $intro, images: $images) {
      machine {
        id
        username
        name
        status
        createdAt
      }
      error
    }
  }
`;

const CHECK = gql`
  query checkMachine($username: String!, $password: String!) {
    checkMachine(username: $username, password: $password)
  }
`;

const MachineAdd = () => {
  const history = useHistory();

  const username = useInput("");
  const password = useInput("");
  const name = useInput("");
  const description = useInput("");

  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { refetch } = useQuery(CHECK, {
    fetchPolicy: "no-cache",
    skip: true,
  });

  const [addMachineM] = useMutation(CREATE);

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const addImage = async (e) => {
    if (!loadingImage) {
      const files = e.target.files;

      if (images.length + files.length > 5) {
        alert("최대 5장의 사진을 선택하실 수 있습니다.");
      } else {
        var _URL = window.URL || window.webkitURL;
        let error = false;
        for (var i = 0; i < files.length; i++) {
          if (files[i].type === "image/jpg" || files[i].type === "image/jpeg" || files[i].type === "image/png") {
          } else {
            error = true;
          }
        }
        if (error) {
          alert("jpg, jpeg, png형식의 사진만 사용하실 수 있습니다.");
        } else {
          setLoadingImage(true);
          let newImages = [...images];
          let newUploadImages = [...uploadImages];
          for (var i = 0; i < files.length; i++) {
            let file = files[i];
            newUploadImages[i + images.length] = file;
            var img = new Image();
            var reader = new FileReader();
            reader.onloadend = () => {
              newImages[i + images.length] = reader.result;
            };
            img.src = await _URL.createObjectURL(file);
            await reader.readAsDataURL(file);
            while (true) {
              if (newImages[i + images.length] === reader.result) {
                break;
              }
              await sleep(500);
            }
          }
          setImages(newImages);
          setUploadImages(newUploadImages);
          setLoadingImage(false);
        }
      }
    }
  };

  const removeImage = (index) => {
    const newImages = [...images];
    const newUploadImages = [...uploadImages];
    newImages.splice(index, 1);
    newUploadImages.splice(index, 1);
    setImages(newImages);
    setUploadImages(newUploadImages);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (username.value && password.value && name.value && status) {
        setLoading(true);
        const { data: check } = await refetch({
          username: username.value,
          password: password.value,
        });
        if (check && check.checkMachine) {
          if (check.checkMachine === "success") {
            try {
              let saveImages = [];
              if (uploadImages && uploadImages.length > 0) {
                const formData = new FormData();
                let index = 0;
                while (index < uploadImages.length) {
                  const resizedImage = await resizeImage(uploadImages[index]);
                  formData.append("introimage[]", resizedImage, uploadImages[index].name);
                  index += 1;
                }
                const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                if (data && data.locations && data.locations.length === uploadImages.length) {
                  saveImages = data.locations;
                }
              }
              if (saveImages.length === uploadImages.length) {
                const { data: result } = await addMachineM({
                  variables: {
                    username: username.value,
                    password: password.value,
                    name: name.value,
                    status,
                    intro: description.value,
                    images: JSON.stringify(saveImages),
                  },
                });
                if (result && result.addMachine) {
                  if (result.addMachine.error) {
                    setLoading(false);
                    alert(result.addMachine.error);
                  } else {
                    setLoading(false);
                    alert("기기를 추가하였습니다.");
                    initialize();
                  }
                } else {
                  setLoading(false);
                  alert("오류가 발생하였습니다.");
                }
              } else {
                setLoading(false);
                alert("오류가 발생하였습니다.");
              }
            } catch {
              setLoading(false);
              alert("오류가 발생하였습니다.");
            }
          } else {
            setLoading(false);
            alert(check.checkMachine);
          }
        } else {
          setLoading(false);
          alert("입력하신 기기 계정에 일치하는 기기가 존재하지 않습니다.");
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newImages = reorder(images, result.source.index, result.destination.index);
    const newUploadImages = reorder(uploadImages, result.source.index, result.destination.index);

    setImages(newImages);
    setUploadImages(newUploadImages);
  };

  const initialize = () => {
    username.setValue("");
    password.setValue("");
    name.setValue("");
    description.setValue("");
    setStatus("");
    setShowStatus(false);
    setImages([]);
    setUploadImages([]);
    setLoadingImage(false);
    setLoading(false);
  };

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>기기 추가</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div>
              <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>*표시는 필수 입력사항입니다.</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기 계정*</p>
              </div>
              <input type={"text"} name={"username"} value={username.value} onChange={username.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"기기에서 가입하신 기기 계정을 입력해주세요."} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기 비밀번호*</p>
              </div>
              <input type={"password"} name={"password"} value={password.value} onChange={password.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"기기 계정 비밀번호를 입력해주세요."} />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기명*</p>
              </div>
              <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"사용하실 기기명을 입력해주세요."} />
            </div>
            <div className={`${styles.mt10}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showStatus ? 0 : 10,
                  borderBottomRightRadius: showStatus ? 0 : 10,
                }}
                onClick={() => setShowStatus(!showStatus)}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상태*</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1}`}>
                  <input type={"text"} value={status === "inuse" ? "사용중" : status === "notuse" ? "미사용" : status === "check" ? "점검중" : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"기기 상태를 선택해주세요."} readOnly={true} />
                  <img src={showStatus ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showStatus && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    <div className={`${styles.py10}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("inuse")}>
                        <p className={`${styles.font14} ${styles.black}`}>사용중</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("notuse")}>
                        <p className={`${styles.font14} ${styles.black}`}>미사용</p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px15} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleStatus("check")}>
                        <p className={`${styles.font14} ${styles.black}`}>점검중</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기 시설 소개 (최대 200자)</p>
              <textarea name={"description"} value={description.value} onChange={description.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py10} ${styles.widthFull}`} style={{ height: 120 }} placeholder={"기기 시설 소개 내용을 입력해주세요."} maxLength={200} />
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>기기 시설 소개 이미지 등록 (최대 5장)</p>
              <div className={`${styles.center}`}>
                <label htmlFor="image">
                  <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.mt10} ${styles.cursorPointer}`}
                    style={{
                      opacity: loadingImage ? 0.4 : 1,
                    }}
                  >
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>이미지 추가하기</p>
                  </div>
                </label>
                <input id={"image"} className={`${styles.none}`} type={"file"} accept={".jpg,.jpeg,.png"} onChange={addImage} multiple={true} maxLength={5} />
              </div>
              {images && images.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(provided) => (
                      <div ref={provided.innerRef} className={`${styles.px25} ${styles.mt25}`} {...provided.droppableProps}>
                        {images.map((image, index) => (
                          <Draggable key={`${index}-${image}`} draggableId={`${index}-${image}`} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <IntroImage image={image} index={index} removeImage={removeImage} />
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className={`${styles.px25} ${styles.mt25}`}>
                  <label htmlFor="image">
                    <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer}`} style={{ height: 150 }}>
                      <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                    </div>
                  </label>
                  <label htmlFor="image">
                    <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer} ${styles.mt15}`} style={{ height: 150 }}>
                      <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                    </div>
                  </label>
                  <label htmlFor="image">
                    <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer} ${styles.mt15}`} style={{ height: 150 }}>
                      <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                    </div>
                  </label>
                  <label htmlFor="image">
                    <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer} ${styles.mt15}`} style={{ height: 150 }}>
                      <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                    </div>
                  </label>
                  <label htmlFor="image">
                    <div className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${styles.cursorPointer} ${styles.mt15}`} style={{ height: 150 }}>
                      <img src={require("../../../assets/images/icon_add.png").default} alt={"add"} className={`${styles.icon20}`} />
                    </div>
                  </label>
                </div>
              )}
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>완료</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MachineAdd;
