import React, { useState } from 'react';
import Switch from 'react-switch';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { gql, useQuery, useMutation } from '@apollo/client';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import { numberReg, emailReg } from '../../../common/regex';
import { termSm } from '../../../common/modal';
import Term from '../../../components/Global/Mobile/Term';

const CHECK_MOBILE = gql`
    query checkMobile($mobile: String!) {
        checkMobile(mobile: $mobile)
    }
`;

const CHECK_USERNAME = gql`
    query checkUsername($username: String!) {
        checkUsername(username: $username)
    }
`;

const SIGNUP = gql`
    mutation signUpAdmin(
        $username: String!
        $password: String!
        $mobile: String!
        $name: String!
        $isAdvertiseAgree: Boolean!
    ) {
        signUpAdmin(
            username: $username
            password: $password
            mobile: $mobile
            name: $name
            isAdvertiseAgree: $isAdvertiseAgree
        ) {
            success
            error
        }
    }
`;

const SignUp = () => {
    const history = useHistory();

    const name = useInput('');
    const mobile = useInput('');
    const username = useInput('');
    const password1 = useInput('');
    const password2 = useInput('');

    const [agree, setAgree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [mobileChecked, setMobileChecked] = useState(false);
    const [usernameChecked, setUsernameChecked] = useState(false);

    const [ageAgree, setAgeAgree] = useState(false);
    const [serviceAgree, setServiceAgree] = useState(false);
    const [personalAgree, setPersonalAgree] = useState(false);
    const [isAdvertiseAgree, setIsAdvertiseAgree] = useState(false);

    const { refetch: refetchMobile } = useQuery(CHECK_MOBILE, {
        skip: true,
        fetchPolicy: 'no-cache',
    });
    const { refetch: refetchUsername } = useQuery(CHECK_USERNAME, {
        skip: true,
        fetchPolicy: 'no-cache',
    });
    const [signUpM] = useMutation(SIGNUP, {
        fetchPolicy: 'no-cache',
    });

    const handleMobile = e => {
        const {
            target: { value },
        } = e;
        if (numberReg.test(value)) {
            mobile.onChange(e);
        }
    };

    const checkMobile = async () => {
        if (!loading) {
            if (mobile.value) {
                setLoading(true);
                const { data } = await refetchMobile({
                    mobile: mobile.value,
                });
                setLoading(false);
                if (data && data.checkMobile) {
                    setMobileChecked(true);
                    alert('사용가능한 휴대폰 번호입니다.');
                } else {
                    alert('휴대폰 번호가 중복됩니다.');
                }
            } else {
                alert('휴대폰 번호를 입력해주세요.');
            }
        }
    };

    const checkUsername = async () => {
        if (!loading) {
            if (username.value) {
                if (emailReg.test(username.value)) {
                    setLoading(true);
                    const { data } = await refetchUsername({
                        username: username.value,
                    });
                    setLoading(false);
                    if (data && data.checkUsername) {
                        setUsernameChecked(true);
                        alert('사용가능한 이메일입니다.');
                    } else {
                        alert('이메일이 중복됩니다.');
                    }
                } else {
                    alert('올바른 형식의 이메일을 입력해주세요.');
                }
            } else {
                alert('이메일을 입력해주세요.');
            }
        }
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (
                name.value &&
                mobile.value &&
                username.value &&
                password1.value &&
                password2.value
            ) {
                if (mobileChecked) {
                    if (emailReg.test(username.value)) {
                        if (usernameChecked) {
                            if (password1.value === password2.value) {
                                // if (agree) {
                                if (ageAgree&&serviceAgree&&personalAgree) {
                                    setLoading(true);
                                    const { data } = await signUpM({
                                        variables: {
                                            username: username.value,
                                            password: password1.value,
                                            mobile: mobile.value,
                                            name: name.value,
                                            isAdvertiseAgree: isAdvertiseAgree
                                        },
                                    });
                                    setLoading(false);
                                    if (data && data.signUpAdmin) {
                                        if (data.signUpAdmin.success) {
                                            history.push('/signup/success/');
                                        } else if (data.signUpAdmin.error) {
                                            alert(data.signUpAdmin.error);
                                        } else {
                                            alert('오류가 발생하였습니다.');
                                        }
                                    } else {
                                        alert('오류가 발생하였습니다.');
                                    }
                                } else {
                                    if (!ageAgree) {
                                        alert('만 14세 미만은 서비스를 이용하실 수 없습니다.');
                                    } else if (!serviceAgree) {
                                        alert('사용약관에 동의하지 않으면 서비스를 이용하실 수 없습니다.');
                                    } else if (!personalAgree) {
                                        alert('개인정보보호 정책에 동의하지 않으면 서비스를 이용하실 수 없습니다.');
                                    }
                                    // alert(
                                    //     '사용약관 및 개인정보보호 정책에 동의하지 않으면 서비스를 이용하실 수 없습니다.',
                                    // );
                                }
                            } else {
                                alert('두 비밀번호가 일치하지 않습니다.');
                            }
                        } else {
                            alert('이메일 중복검사를 해주세요.');
                        }
                    } else {
                        alert('올바른 형식의 이메일을 입력해주세요.');
                    }
                } else {
                    alert('휴대폰 번호 중복검사를 해주세요.');
                }
            } else {
                alert('위의 정보를 입력해주세요.');
            }
        }
    };

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgOrange} ${styles.center}`}>
            <div
                className={`${styles.container} ${styles.overflowHidden} ${styles.py70}`}
                style={{ maxWidth: 600 }}>
                <div className={`${styles.textCenter}`}>
                    <img
                        src={
                            require('../../../assets/images/logo_lg.png')
                                .default
                        }
                        alt={'VEN Brothers'}
                        style={{ width: 200 }}
                    />
                </div>
                <div
                    className={`${styles.borderRadius10} ${styles.bgGrayEf} ${styles.px15} ${styles.pAuthBox} ${styles.mx15} ${styles.mt40} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font25} ${styles.black} ${styles.textCenter}`}>
                        계정 만들기
                    </p>
                    <p
                        className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt10}`}>
                        이미 계정이 있으신가요?{' '}
                        <span
                            className={`${styles.cursorPointer} ${styles.underline}`}
                            onClick={loading ? null : () => history.push('/')}>
                            로그인
                        </span>
                    </p>
                    <form
                        action={''}
                        className={`${styles.mt50}`}
                        onSubmit={submit}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                이름
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 35 }}
                            />
                            <input
                                type={'text'}
                                value={name.value}
                                onChange={name.onChange}
                                placeholder={'이름을 입력해주세요.'}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                휴대폰 번호
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 35 }}
                            />
                            <input
                                type={'tel'}
                                value={mobile.value}
                                onChange={handleMobile}
                                placeholder={
                                    '휴대폰 번호를 입력해주세요. (숫자만 입력)'
                                }
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                                maxLength={11}
                            />
                        </div>
                        <div
                            className={`${
                                mobileChecked ? styles.bgOrange : styles.bgBlack
                            } ${styles.borderRadius10} ${styles.center} ${
                                styles.mt10
                            } ${styles.cursorPointer}`}
                            style={{ height: 45, opacity: loading ? 0.4 : 1 }}
                            onClick={checkMobile}>
                            <p
                                className={`${styles.fontR} ${styles.font14} ${
                                    mobileChecked ? styles.white : styles.grayEf
                                }`}>
                                휴대폰 번호 중복확인
                                {mobileChecked ? ' 완료' : ''}
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                이메일 (아이디)
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 35 }}
                            />
                            <input
                                type={'email'}
                                value={username.value}
                                onChange={username.onChange}
                                placeholder={
                                    '아이디로 사용하실 이메일 주소를 입력해주세요.'
                                }
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>
                        <div
                            className={`${
                                usernameChecked
                                    ? styles.bgOrange
                                    : styles.bgBlack
                            } ${styles.borderRadius10} ${styles.center} ${
                                styles.mt10
                            } ${styles.cursorPointer}`}
                            style={{ height: 45, opacity: loading ? 0.4 : 1 }}
                            onClick={checkUsername}>
                            <p
                                className={`${styles.fontR} ${styles.font14} ${
                                    usernameChecked
                                        ? styles.white
                                        : styles.grayEf
                                }`}>
                                이메일(아이디) 중복확인
                                {usernameChecked ? ' 완료' : ''}
                            </p>
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                비밀번호
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 35 }}
                            />
                            <input
                                type={'password'}
                                value={password1.value}
                                onChange={password1.onChange}
                                placeholder={'비밀번호를 입력해주세요.'}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.bgWhite} ${styles.borderRadius10} ${styles.px15} ${styles.py5} ${styles.containerInput} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}
                                style={{ width: 100 }}>
                                비밀번호 확인
                            </p>
                            <div
                                className={`${styles.bgGrayEf}`}
                                style={{ width: 1, height: 35 }}
                            />
                            <input
                                type={'password'}
                                value={password2.value}
                                onChange={password2.onChange}
                                placeholder={'비밀번호를 다시 입력해주세요.'}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl10}`}
                            />
                        </div>


                        {/*<div className={`${styles.mt40}`}>*/}
                        {/*    <p*/}
                        {/*        className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`}>*/}
                        {/*        사용약관 및 개인정보보호 정책을 읽었으며 <br />*/}
                        {/*        이에 동의합니다.{' '}*/}
                        {/*        <span*/}
                        {/*            className={`${styles.cursorPointer} ${styles.underline}`}*/}
                        {/*            onClick={() => setShowModal(true)}>*/}
                        {/*            내용보기*/}
                        {/*        </span>*/}
                        {/*    </p>*/}
                        {/*    <div className={`${styles.mt20} ${styles.center}`}>*/}
                        {/*        <Switch*/}
                        {/*            onChange={setAgree}*/}
                        {/*            checked={agree}*/}
                        {/*            offColor={'#232222'}*/}
                        {/*            onColor={'#FF7701'}*/}
                        {/*            uncheckedIcon={false}*/}
                        {/*            checkedIcon={false}*/}
                        {/*            width={43}*/}
                        {/*            height={22}*/}
                        {/*            handleDiameter={18}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className={`${styles.mt30}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                (필수) 만 14세 이상입니다.{' '}
                            </p>
                            <div className={`${styles.mt20} ${styles.center}`}>
                                <Switch
                                    onChange={setAgeAgree}
                                    checked={ageAgree}
                                    offColor={'#232222'}
                                    onColor={'#FF7701'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={43}
                                    height={22}
                                    handleDiameter={18}
                                />
                            </div>
                        </div>

                        <div className={`${styles.mt20}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                (필수) 서비스 이용약관{' '}
                                <a className={`${styles.cursorPointer}`} style={{color:"black", textDecoration: "unset"}} target={"_blank"} href="https://www.ven-brothers.com/terms">
                                    (보기)
                                </a>
                            </p>
                            <div className={`${styles.mt20} ${styles.center}`}>
                                <Switch
                                    onChange={setServiceAgree}
                                    checked={serviceAgree}
                                    offColor={'#232222'}
                                    onColor={'#FF7701'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={43}
                                    height={22}
                                    handleDiameter={18}
                                />
                            </div>
                        </div>

                        <div className={`${styles.mt20}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`} style={{fontWeight: "bold"}}>
                                (필수) 개인정보 수집⋅이용 동의{' '}
                                <a className={`${styles.cursorPointer}`} style={{color:"black", textDecoration: "unset"}} target={"_blank"} href="https://app.catchsecu.com/document/C/119cc33b91dbad4">
                                    (보기)
                                </a>
                            </p>
                            <div className={`${styles.mt20} ${styles.center}`}>
                                <Switch
                                    onChange={setPersonalAgree}
                                    checked={personalAgree}
                                    offColor={'#232222'}
                                    onColor={'#FF7701'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={43}
                                    height={22}
                                    handleDiameter={18}
                                />
                            </div>
                        </div>

                        <div className={`${styles.mt20}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black} ${styles.textCenter}`} style={{fontWeight: "bold"}}>
                                (선택) 광고성 정보 수신 동의{' '}
                                <a className={`${styles.cursorPointer}`} style={{color:"black", textDecoration: "unset"}} target={"_blank"} href="https://app.catchsecu.com/document/C/b58cb16bef5e2da">
                                    (보기)
                                </a>
                            </p>
                            <div className={`${styles.mt20} ${styles.center}`}>
                                <Switch
                                    onChange={setIsAdvertiseAgree}
                                    checked={isAdvertiseAgree}
                                    offColor={'#232222'}
                                    onColor={'#FF7701'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    width={43}
                                    height={22}
                                    handleDiameter={18}
                                />
                            </div>
                        </div>

                        <button
                            type={'submit'}
                            className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull}`}
                            style={{ opacity: loading ? 0.4 : 1 }}>
                            <p
                                className={`${styles.fontB} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>
                                계속
                            </p>
                        </button>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={termSm}>
                <div>
                    <div style={{ height: 30 }} />
                    <div
                        className={`${styles.bgWhite} ${styles.hideScroll}`}
                        style={{
                            height: 'calc(80vh - 130px)',
                            minHeight: 400 - 110 - 20,
                            maxHeight: 700 - 110 - 20,
                            overflowY: 'scroll',
                        }}>
                        <Term />
                    </div>
                    <div
                        className={`${styles.center}`}
                        style={{
                            height: 80,
                        }}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.center} ${styles.py15} ${styles.px30} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`}
                            onClick={() => setShowModal(false)}>
                            <p
                                className={`${styles.fontB} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>
                                확인
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SignUp;
