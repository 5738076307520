import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import NumberFormat from "react-number-format";

import styles from "../../../styles/styles.module.scss";
import { changeToFormattedNumber, changeToNegativeNumber, removeNonNumeric, allowNegativeNumeric } from "../../../common/utility";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";

const ADD = gql`
  mutation addCustomPayment($machine: String!, $type: String!, $approvalDate: String!, $approvalTime: String!, $userName: String, $userContact: String, $productName: String, $productCount: Int, $totalAmount: Int!, $status: String!, $extra: String) {
    addCustomPayment(machine: $machine, type: $type, approvalDate: $approvalDate, approvalTime: $approvalTime, userName: $userName, userContact: $userContact, productName: $productName, productCount: $productCount, totalAmount: $totalAmount, status: $status, extra: $extra) {
      payment {
        id
        totalAmount
        installment
        status
        issuerInfo
        approvalDate
        approvalTime
        businessName
        businessAddress
        businessOwnerName
        businessPhoneNo
        businessNo
        cardNo
        surtax
        acquireInfo
        merchantNo
        approvalNo
        cancelDate
        cancelTime
        type
        userName
        userContact
        productName
        extra
      }
      error
    }
  }
`;

const AddCashPayment = () => {
  const history = useHistory();
  const location = useLocation();

  const time = useInput("");
  const amount = useInput("");
  const userName = useInput("");
  const userContact = useInput("");
  const productName = useInput("");
  const productCount = useInput("1");
  const extra = useInput("");

  const [date, setDate] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [status, setStatus] = useState("paid");
  const [showStatus, setShowStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addCustomPaymentM] = useMutation(ADD);

  const handleDate = (date) => {
    setDate(date);
    setShowDate(false);
  };

  const handleTime = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      time.setValue(value);
    }
  };

  const handleAmount = (e) => {
    const {
      target: { value },
    } = e;
    amount.setValue(changeToNegativeNumber(value));
  };

  const handleProductCount = (e) => {
    const {
      target: { value },
    } = e;
    productCount.setValue(changeToFormattedNumber(value));
  };

  const handleContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      userContact.onChange(e);
    }
  };

  const handleStatus = (status) => {
    setStatus(status);
    setShowStatus(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (date && time.value && productCount.value && Number(removeNonNumeric(productCount.value)) > 0 && amount.value && Number(removeNonNumeric(amount.value)) > 0 && status) {
        const hour = time.value.slice(0, 2);
        const minute = time.value.slice(2);
        if (Number(hour) > 23) {
          alert("올바른 결제 시각을 입력해주세요.");
          return;
        }
        if (Number(minute) > 59) {
          alert("올바른 결제 시각을 입력해주세요.");
          return;
        }
        setLoading(true);
        try {
          const { data: result } = await addCustomPaymentM({
            variables: {
              machine: location.state.machine,
              type: "cash",
              extra: extra.value,
              approvalDate: `${String(date.getFullYear()).slice(2)}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`,
              approvalTime: `${time.value}00`,
              userName: userName.value,
              userContact: userContact.value,
              productName: productName.value,
              productCount: Number(removeNonNumeric(productCount.value)),
              totalAmount: Number(allowNegativeNumeric(amount.value)),
              status,
              extra: extra.value,
            },
          });
          if (result.addCustomPayment && result.addCustomPayment.payment) {
            alert("저장되었습니다.");
            initialize();
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          alert("오류가 발생하였습니다.");
        } finally {
          setLoading(false);
        }
      } else {
        alert("위의 정보를 입력해주세요.");
      }
    }
  };

  const initialize = () => {
    time.setValue("");
    amount.setValue("");
    userName.setValue("");
    userContact.setValue("");
    productName.setValue("");
    productCount.setValue("1");
    extra.setValue("");
    setDate(null);
    setShowDate(false);
    setStatus("paid");
    setShowStatus(false);
  };

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>현금 결제 추가</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showDate ? 0 : 10,
                      borderBottomRightRadius: showDate ? 0 : 10,
                    }}
                    onClick={() => setShowDate(!showDate)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 일시*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${date ? styles.black : styles.grayA2}`}>
                        {date ? `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}` : "결제 일시를 선택해주세요."}
                      </p>
                      <img src={showDate ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"결제 일시 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showDate && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 615 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 615 }}>
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <Calendar
                            calendarType={"US"}
                            value={date}
                            minDetail={"month"}
                            nextLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                              </span>
                            }
                            next2Label={null}
                            prevLabel={
                              <span>
                                <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                              </span>
                            }
                            prev2Label={null}
                            navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                            onChange={handleDate}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 시각*</p>
                  </div>
                  <NumberFormat value={time.value} thousandSeparator={false} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} type={"tel"} displayType={"input"} onValueChange={handleTime} placeholder={"결제 시각을 입력해주세요."} format={"##:##"} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자명</p>
                  </div>
                  <input type={"text"} name={"userName"} value={userName.value} onChange={userName.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"구매자명을 입력해주세요."} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자 연락처</p>
                  </div>
                  <input type={"tel"} name={"userContact"} value={userContact.value} onChange={handleContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"구매자 연락처를 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상품명</p>
                  </div>
                  <input type={"text"} name={"productName"} value={productName.value} onChange={productName.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"상품명을 입력해주세요."} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>판매 수량</p>
                  </div>
                  <input type={"tel"} name={"productCount"} value={productCount.value} onChange={handleProductCount} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"판매 수량을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 금액*</p>
                  </div>
                  <input type={"tel"} name={"amount"} value={amount.value} onChange={handleAmount} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"결제금액을 입력해주세요. (환불은 - 입력)"} />
                </div>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                    style={{
                      width: 615,
                      borderBottomLeftRadius: showStatus ? 0 : 10,
                      borderBottomRightRadius: showStatus ? 0 : 10,
                    }}
                    //onClick={() => setShowStatus(!showStatus)}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 상태*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.font16} ${styles.black}`}>
                        {status === "paid" && "결제완료"}
                        {status === "cancelled" && "결제취소"}
                        {status === "refund" && "환불"}
                      </p>
                      {/**
                      <img
                        src={
                          showStatus
                            ? require("../../../assets/images/icon_dropdown_up.png")
                                .default
                            : require("../../../assets/images/icon_dropdown_down.png")
                                .default
                        }
                        alt={"상품 분류 선택"}
                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                      />
                       */}
                    </div>
                  </div>
                  {/**
                  {showStatus && (
                    <div
                      className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                      style={{ width: 615 }}
                    >
                      <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                        style={{ width: 615 }}
                      >
                        <div
                          className={`${styles.borderRightGrayA2} ${styles.px20}`}
                          style={{
                            width: 190,
                          }}
                        ></div>
                        <div className={`${styles.py10} ${styles.flex1}`}>
                          <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() => handleStatus("paid")}
                          >
                            <p className={`${styles.font16} ${styles.black}`}>
                              결제완료
                            </p>
                          </div>
                          <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() => handleStatus("cancelled")}
                          >
                            <p className={`${styles.font16} ${styles.black}`}>
                              결제취소
                            </p>
                          </div>
                          <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() => handleStatus("refund")}
                          >
                            <p className={`${styles.font16} ${styles.black}`}>
                              환불
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                   */}
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>비고</p>
                <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"환불, 결제취소 등 비고 정보 입력"} maxLength={5000} />
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>추가</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCashPayment;
