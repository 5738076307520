import React, { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";

const DETAIL = gql`
  query getPayment($machine: String!, $paymentId: Int!) {
    getPayment(machine: $machine, paymentId: $paymentId) {
      id
      productName
      userName
      userContact
      totalAmount
      status
      issuerInfo
      installment
      approvalDate
      approvalTime
      extra
    }
  }
`;

const EDIT = gql`
  mutation editCustomPayment($machine: String!, $paymentId: Int!, $userName: String, $userContact: String, $productName: String, $extra: String) {
    editCustomPayment(machine: $machine, paymentId: $paymentId, userName: $userName, userContact: $userContact, productName: $productName, extra: $extra) {
      success
      result {
        id
        productName
        userName
        userContact
        extra
      }
    }
  }
`;

const EditCustomPayment = () => {
  const history = useHistory();
  const location = useLocation();

  const userName = useInput("");
  const userContact = useInput("");
  const productName = useInput("");
  const extra = useInput("");

  const [loading, setLoading] = useState(false);

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine,
      paymentId: location.state.payment.id,
    },
  });

  const [editCustomPaymentM] = useMutation(EDIT);

  const handleContact = (e) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      userContact.onChange(e);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (data && data.getPayment) {
        setLoading(true);
        try {
          const { data: result } = await editCustomPaymentM({
            variables: {
              machine: location.state.machine,
              paymentId: data.getPayment.id,
              userName: userName.value,
              userContact: userContact.value,
              productName: productName.value,
              extra: extra.value,
            },
          });
          if (result.editCustomPayment && result.editCustomPayment.success) {
            alert("저장되었습니다.");
          } else {
            alert("오류가 발생하였습니다.");
          }
        } catch {
          alert("오류가 발생하였습니다.");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (data && data.getPayment) {
      userName.setValue(data.getPayment.userName ? data.getPayment.userName : "");
      userContact.setValue(data.getPayment.userContact ? data.getPayment.userContact : "");
      productName.setValue(data.getPayment.productName ? data.getPayment.productName : "");
      extra.setValue(data.getPayment.extra ? data.getPayment.extra : "");
    }
  }, [data]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>자유 결제 정보 입력</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>판매일시</p>
                  </div>
                  <input
                    type={"text"}
                    name={"date"}
                    value={
                      data && data.getPayment
                        ? `${data.getPayment.approvalDate.slice(0, 2)}/${data.getPayment.approvalDate.slice(2, 4)}/${data.getPayment.approvalDate.slice(4, 6)} - ${data.getPayment.approvalTime.slice(0, 2)}:${data.getPayment.approvalTime.slice(2, 4)}:${data.getPayment.approvalTime.slice(4, 6)}`
                        : ""
                    }
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                    readOnly={true}
                  />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자명</p>
                  </div>
                  <input type={"text"} name={"userName"} value={userName.value} onChange={userName.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"구매자명을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>구매자 연락처</p>
                  </div>
                  <input type={"text"} name={"userContact"} value={userContact.value} onChange={handleContact} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"구매자 연락처를 입력해주세요."} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>상품명</p>
                  </div>
                  <input type={"text"} name={"productName"} value={productName.value} onChange={productName.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"상품명을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>판매 수량</p>
                  </div>
                  <input type={"text"} name={"count"} value={data && data.getPayment ? "1" : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 금액</p>
                  </div>
                  <input type={"text"} name={"product"} value={data && data.getPayment ? `${numberWithCommas(data.getPayment.totalAmount)} 원` : ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} readOnly={true} />
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 수단</p>
                  </div>
                  <input
                    type={"text"}
                    name={"issuer"}
                    value={data && data.getPayment ? `${data.getPayment.issuerInfo ? data.getPayment.issuerInfo : ""}(${data.getPayment.installment === 0 ? "일시불" : `${data.getPayment.installment}개월`})` : ""}
                    className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                    readOnly={true}
                  />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 615 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>결제 상태</p>
                  </div>
                  {data && data.getPayment ? (
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                      <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.flex1}`}>
                        {data.getPayment.status === "paid" && "결제완료"}
                        {data.getPayment.status === "cancelled" && "결제취소"}
                        {data.getPayment.status === "refund" && "환불"}
                      </p>
                    </div>
                  ) : (
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}></div>
                  )}
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>비고</p>
                <textarea name={"extra"} value={extra.value} onChange={extra.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.py15}`} style={{ width: 1208, height: 120 }} placeholder={"비고"} maxLength={5000} />
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>완료</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomPayment;
