import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const SiteProduct = ({ product, remove, machine }) => {
  const history = useHistory();

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.isDisplay === true ? "전시 중" : "전시 중단"}</p>
      </div>
      <div className={`${styles.flex20} ${styles.center}`}>
        <div
          className={`${styles.bgWhite} ${styles.borderRadius5} ${styles.btnShadow} ${styles.bgImgContain}`}
          style={{
            backgroundImage: `url(${product.image})`,
            width: 120,
            height: 120,
          }}
        ></div>
      </div>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.name}</p>
      </div>
      <div className={`${styles.flex30} ${styles.px30}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.description}</p>
      </div>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.category.name}</p>
      </div>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.slot ? numberWithCommas(product.slot) : ""}</p>
      </div>
      <div className={`${styles.flex10}`}>
        {product.isDiscount && product.discountPrice > 0 ? (
          <div>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`} style={{ textDecorationLine: "line-through" }}>
              {numberWithCommas(product.price)}원
            </p>
            <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>{numberWithCommas(product.price - product.discountPrice)}원</p>
          </div>
        ) : (
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{numberWithCommas(product.price)}원</p>
        )}
      </div>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.ignoreStock ? "무제한" : numberWithCommas(product.stock)}</p>
      </div>
      <div className={`${styles.flex10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{product.isDiscount && product.discountPrice > 0 ? `${numberWithCommas(product.discountPrice)}원 할인` : "미적용"}</p>
      </div>
      <div className={`${styles.flex20} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
          onClick={() =>
            history.push({
              pathname: `/site/product/edit/`,
              state: {
                machine: machine.username,
                product,
              },
            })
          }
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
        </div>
        <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml30}`} onClick={remove} />
      </div>
    </div>
  );
};

export default SiteProduct;
