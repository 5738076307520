import React from 'react';

import styles from '../../../styles/styles.module.scss';

const MarketingList = ({memberReservation}) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {memberReservation && memberReservation.members && memberReservation.members.length > 0 ? memberReservation.members[0].name : ''}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {memberReservation && memberReservation.members && memberReservation.members.length > 0 ? memberReservation.members[0].mobile : ''}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {memberReservation.gender === 'male' ? '남성' : (memberReservation.gender === 'female' ? '여성' : '')}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {memberReservation.birth}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div>
                    {memberReservation.members ? (memberReservation.members.length > 0) && (
                        memberReservation.members.map((m, idx) => {
                            return (
                                <p key={m.id}
                                   className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    {m.machine.name}
                                </p>
                            )
                        })
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MarketingList;
