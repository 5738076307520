import React from 'react';

import styles from '../../../styles/styles.module.scss';
import NumberFormat from "react-number-format";

const ScheduleTime = ({timeHour, timeMin, timeAmPm, handleTimeAmPm, handleTimeHour, handleTimeMin, infoText}) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15}`}>
            {timeAmPm === 'AM' && (
                <span className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.underline}`}
                      onClick={() => handleTimeAmPm('PM')}>오전</span>
            )}
            {timeAmPm === 'PM' && (
                <span
                    className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.underline}`}
                    onClick={() => handleTimeAmPm('AM')}>오후</span>
            )}

            <NumberFormat
                value={timeHour.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.mr2} ${styles.textRight}`}
                style={{width: 25}}
                type={'tel'}
                displayType={'input'}
                onValueChange={handleTimeHour}
                placeholder={
                    '시'
                }
            />
            <span className={`${styles.font14} ${styles.black}`}>시</span>
            <NumberFormat
                value={timeMin.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.ml2} ${styles.mr2} ${styles.textRight}`}
                style={{width: 25}}
                type={'tel'}
                displayType={'input'}
                onValueChange={handleTimeMin}
                placeholder={
                    '분'
                }
            />
            <span className={`${styles.font14} ${styles.black}`}>{infoText}</span>
        </div>
    );
};

export default ScheduleTime;
