import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styles from '../../../styles/styles.module.scss';
import OrderOrdering from '../../../components/Order/Mobile/OrderOrdering';

const PRODUCT = gql`
    query getProducts($machine: String!, $order: String!) {
        getProducts(type: "order", machine: $machine, order: $order) {
            products {
                id
                category {
                    id
                    name
                }
                image
                name
                price
                stock
                ignoreStock
                description
                isDiscount
                discountPrice
                discountPercent
            }
            total
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            username
            name
        }
    }
`;

const EDIT = gql`
    mutation editProductsIndex($machine: String!, $result: String!) {
        editProductsIndex(machine: $machine, type: "order", result: $result) {
            id
            category {
                id
                name
            }
            image
            name
            price
            stock
            ignoreStock
            description
            isDiscount
            discountPrice
            discountPercent
        }
    }
`;

const OrderProductsOrdering = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const {
        data,
        loading: loadingProduct,
        refetch,
    } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: 'index_asc',
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [editProductsIndexM] = useMutation(EDIT);

    const orderName = async () => {
        if (!loading) {
            const { data } = await refetch({
                machine: location.state.machine,
                order: 'name_asc',
            });
            if (data && data.getProducts) {
                setProducts(data.getProducts.products);
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const newProducts = reorder(
            products,
            result.source.index,
            result.destination.index,
        );

        setProducts(newProducts);
    };

    const saveOrder = async () => {
        if (!loading && products.length > 0) {
            setLoading(true);
            let result = [];
            for (var i = 0; i < products.length; i++) {
                result.push({
                    id: products[i].id,
                    index: i,
                });
            }
            try {
                const { data } = await editProductsIndexM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        result: JSON.stringify(result),
                    },
                });
                setLoading(false);
                if (data && data.editProductsIndex) {
                    setProducts(data.editProductsIndex);
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data && data.getProducts) {
            setProducts(data.getProducts.products);
        }
    }, [data]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`주문 판매 상품 노출 순서 변경`}
                        <br />
                        {`${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <div className={`${styles.center} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() =>
                                history.push({
                                    pathname: `/order/category/ordering/`,
                                    state: {
                                        machine: location.state.machine
                                            ? location.state.machine
                                            : '',
                                    },
                                })
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                카테고리 정렬
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt15}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={orderName}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                가나다순 정렬
                            </p>
                        </div>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
                            onClick={saveOrder}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                저장
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1} ${styles.pr10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                상품명
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingProduct ? (
                            <Skeleton height={50} count={3} />
                        ) : products.length > 0 ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                    droppableId="droppable"
                                    direction="vertical">
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={`${styles.overflowHidden}`}>
                                            {products.map((product, index) => (
                                                <Draggable
                                                    key={`${product.id}`}
                                                    draggableId={`${product.id}`}
                                                    index={index}>
                                                    {provided => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}>
                                                            <OrderOrdering
                                                                key={product.id}
                                                                product={
                                                                    product
                                                                }
                                                            />
                                                            {
                                                                provided.placeholder
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    아직 등록된 상품이 없습니다.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderProductsOrdering;
