import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const Contract = ({
    contract
}) => {
    const history = useHistory()

    const date = new Date(Number(contract.createdAt))
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`} onClick={() => history.push({
            pathname: `/contract/detail/`,
            state: {
                contract
            }
        })}>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {`${date.getMonth() + 1 < 10 ? '0' + String(date.getMonth() + 1) : date.getMonth() + 1}/${date.getDate() < 10 ? '0' + String(date.getDate()) : date.getDate()}-${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.px10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {contract.userName}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {contract.terms.name}
                </p>
            </div>
        </div>
    )
}

export default Contract;