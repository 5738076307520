import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import styles from '../../../styles/styles.module.scss';
import ProductCategory from '../../../components/Global/Mobile/ProductCategory';

const CATEGORY = gql`
    query getCategoryAdmin($machine: String!, $orderName: Boolean) {
        getCategoryAdmin(
            type: "order"
            machine: $machine
            orderName: $orderName
        ) {
            id
            name
        }
    }
`;

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            username
            name
        }
    }
`;

const EDIT = gql`
    mutation editCategoryIndex($machine: String!, $result: String!) {
        editCategoryIndex(machine: $machine, type: "order", result: $result) {
            id
            name
        }
    }
`;

const OrderCategoryOrdering = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [categorys, setCategorys] = useState([]);

    const {
        data,
        loading: loadingCategory,
        refetch,
    } = useQuery(CATEGORY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            orderName: false,
        },
    });
    const { data: machine } = useQuery(DETAIL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
        },
    });

    const [editCategoryIndexM] = useMutation(EDIT);

    const orderName = async () => {
        if (!loading) {
            const { data } = await refetch({
                machine: location.state.machine,
                orderName: true,
            });
            if (data && data.getCategoryAdmin) {
                setCategorys(data.getCategoryAdmin);
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const newCategorys = reorder(
            categorys,
            result.source.index,
            result.destination.index,
        );

        setCategorys(newCategorys);
    };

    const saveOrder = async () => {
        if (!loading && categorys.length > 0) {
            setLoading(true);
            let result = [];
            for (var i = 0; i < categorys.length; i++) {
                result.push({
                    id: categorys[i].id,
                    index: i,
                });
            }
            try {
                const { data } = await editCategoryIndexM({
                    variables: {
                        machine: location.state.machine
                            ? location.state.machine
                            : '',
                        result: JSON.stringify(result),
                    },
                });
                setLoading(false);
                if (data && data.editCategoryIndex) {
                    setCategorys(data.editCategoryIndex);
                    alert('저장하였습니다.');
                } else {
                    alert('오류가 발생하였습니다.');
                }
            } catch {
                setLoading(false);
                alert('오류가 발생하였습니다.');
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data && data.getCategoryAdmin) {
            setCategorys(data.getCategoryAdmin);
        }
    }, [data]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div
                className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`카테고리 노출 순서 변경`}
                        <br />
                        {`${
                            machine && machine.getMachine
                                ? ` (${machine.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={orderName}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                가나다순 정렬
                            </p>
                        </div>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer} ${styles.ml15}`}
                            onClick={saveOrder}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                저장
                            </p>
                        </div>
                    </div>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex1} ${styles.pr10}`}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                카테고리명
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingCategory ? (
                            <Skeleton height={50} count={3} />
                        ) : categorys.length > 0 ? (
                            <>
                                <ProductCategory
                                    category={{
                                        id: -1,
                                        name: '전체',
                                    }}
                                />
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable
                                        droppableId="droppable"
                                        direction="vertical">
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={`${styles.overflowHidden}`}>
                                                {categorys.map(
                                                    (category, index) => (
                                                        <Draggable
                                                            key={`${category.id}`}
                                                            draggableId={`${category.id}`}
                                                            index={index}>
                                                            {provided => (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>
                                                                    <ProductCategory
                                                                        key={
                                                                            category.id
                                                                        }
                                                                        category={
                                                                            category
                                                                        }
                                                                    />
                                                                    {
                                                                        provided.placeholder
                                                                    }
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ),
                                                )}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </>
                        ) : (
                            <div
                                className={`${styles.center}`}
                                style={{ height: 600 }}>
                                <p
                                    className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                    아직 등록된 카테고리가 없습니다.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderCategoryOrdering;
