import React, {useState} from 'react';
import Modal from "react-modal";
import { bill, membership as membershipModal } from "../../../common/modal";
import styles from '../../../styles/styles.module.scss';
import useInput from "../../../hooks/useInput";

const EntranceCategory = ({ idx, machineEntrance, isLast, onRemove, entranceNames, onChange, categories }) => {
    const code = machineEntrance.code ? machineEntrance.code : '';

    const [showCategories, setShowCategories] = useState(false);
    const [showMembership, setShowMembership] = useState(false);

    let selectCategories = '';
    if (machineEntrance.categories) {
        for (var i = 0; i < machineEntrance.categories.length; i++) {
            if (machineEntrance.categories[i].checked) {
                if (selectCategories.length > 0) {
                    selectCategories += ", ";
                }

                selectCategories += machineEntrance.categories[i].name;
            }
        }
    }

    var jsonData = entranceNames?.getEntranceFullName;    
    const entranceNamesFinal = jsonData ? jsonData.map(item => {
        return {
            id: item.id, 
            name: `${item.name}(${item.id})`
        };
    }) : null;

    const handleMembership = (product) => {        
        let _machineEntrance = {...machineEntrance};
        _machineEntrance.code = product.name;
        onChange(_machineEntrance);
        setShowMembership(false);
    };

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${isLast ? null : styles.borderBottomGrayA2}`}>
            <div className={`${styles.flex1}`}>
                <div
                    className={`${styles.py5} ${styles.px5} ${styles.textRight}`}
                    onClick={() => onRemove(idx)}>
                    <img
                        src={require('../../../assets/images/icon_close.png').default}
                        className={`${styles.icon15}`}
                    />
                </div>
                <p
                    className={`${styles.fontR} ${styles.px10} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`조건 ${idx + 1}`}
                </p>
            </div>
            <div className={`${styles.flex4} ${styles.borderLeftGrayA2}`}>
                <div style={{position: 'relative'}}>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.borderBottomGrayA2} ${styles.cursorPointer}`}
                        onClick={() => {
                            setShowCategories(!showCategories);
                        }}>
                        <div className={`${styles.flex1} ${styles.px10} ${styles.py10} ${styles.borderRightGrayA2} ${styles.textCenter}`}>
                            <p
                                className={`${styles.font14} ${styles.black}`}>
                                카테고리
                            </p>
                        </div>
                        <div className={`${styles.flex3}`}>
                            <div className={`${styles.row} ${styles.py10} ${styles.mx0}`}>
                                <p
                                    className={`${styles.flex1} ${styles.fontR} ${styles.pl10} ${styles.font14} ${selectCategories.length > 0 ? styles.black : styles.grayA2} ${selectCategories.length > 0 ? null : styles.textOverflowEllipsis}`}>
                                    {selectCategories.length > 0 ? selectCategories : '카테고리를 선택해주세요.'}
                                </p>
                                <img
                                    src={
                                        showCategories
                                            ? require('../../../assets/images/icon_dropdown_up.png')
                                                .default
                                            : require('../../../assets/images/icon_dropdown_down.png')
                                                .default
                                    }
                                    alt={'카테고리 선택'}
                                    className={`${styles.icon15} ${styles.mt3} ${styles.ml5} ${styles.mr10}`}
                                />
                            </div>
                        </div>
                    </div>
                    {showCategories && (
                        <div
                            className={`${styles.bgWhite} ${styles.flex1} ${styles.dropdown} ${styles.overflowHidden} ${styles.py10}`}
                            style={{left: -1, right: -1}}>
                            <div
                                className={`${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                                <div>
                                    {categories ? categories.map(
                                            (c, index) => {
                                                return (
                                                    <div
                                                        key={
                                                            c.id
                                                        }
                                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                                                        onClick={() => {
                                                            let _machineEntrance = {...machineEntrance};
                                                            if (_machineEntrance.categories) {
                                                                let _category = {..._machineEntrance.categories[index]};

                                                                if (_category.checked) {
                                                                    _category.checked = !_category.checked;
                                                                } else {
                                                                    _category = {..._category, checked: true};
                                                                }

                                                                machineEntrance.categories[index] = _category;
                                                            } else {
                                                                let _categories = [...categories];
                                                                let _category = {..._categories[index], checked: true};
                                                                _categories[index] = _category;
                                                                _machineEntrance.categories = _categories;
                                                            }

                                                            onChange(_machineEntrance);
                                                        }}>
                                                        <div
                                                            className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                                                            {machineEntrance.categories && machineEntrance.categories[index].checked && (
                                                                <div
                                                                    className={`${styles.bgOrange} ${styles.borderRadius5}`}
                                                                    style={{
                                                                        width: 14,
                                                                        height: 14,
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <p
                                                            className={`${styles.font14} ${styles.flex1} ${styles.black}`}>
                                                            {
                                                                c.name
                                                            }
                                                        </p>
                                                    </div>
                                                );
                                            },
                                        )
                                        : null}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${styles.row} ${styles.mx0}`}>
                    <div className={`${styles.flex1} ${styles.px10} ${styles.py10} ${styles.borderRightGrayA2} ${styles.textCenter}`}>
                        <p
                            className={`${styles.font14} ${styles.black}`}>
                            출입그룹
                        </p>
                    </div>
                    {
                    entranceNamesFinal ? 
                        <div className={`${styles.flex3} ${styles.py10} ${styles.borderRightGrayA2}`} onClick={() => setShowMembership(!showMembership)}>                                    
                            <p
                                className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                {machineEntrance.code}
                            </p>
                        </div>
                        :
                        <div className={`${styles.flex3}`}>
                            <div className={`${styles.row} ${styles.py10} ${styles.mx0} ${styles.justifyContentBetween}`}>
                                <input
                                    type={'tel'}
                                    name={'code'}
                                    value={code}
                                    onChange={(e) => {
                                        const { target : { value } } = e;

                                        let _machineEntrance = {...machineEntrance};
                                        _machineEntrance.code = value;
                                        onChange(_machineEntrance);
                                    }}
                                    className={`${styles.inputWhite} ${styles.px10} ${styles.fontR} ${styles.font14} ${styles.widthFull}`}
                                    placeholder={
                                        '출입그룹 코드를 입력해주세요.'
                                    }
                                />

                            </div>
                        </div>
                    }
                </div>
            </div>
            <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={bill}>
                <div
                className={`${styles.bgWhite} ${styles.hideScroll}`}
                style={{
                    maxHeight: 300,
                    overflowX: "hidden",
                    overflowY: "scroll",
                }}
                >
                {entranceNamesFinal
                    ? entranceNamesFinal.map((item) => {                        
                        return (
                            <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} 
                            ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                            <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                            </div>
                        );
                    })
                    : null}
                </div>
                <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center}
                 ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
                <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
                </div>
            </Modal>            
        </div>
    );
};

export default EntranceCategory;
