import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Calendar from "react-calendar";
import NumberFormat from "react-number-format";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";
import { bill, billSm, membership as membershipModal } from "../../../common/modal";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";
import MembershipHolding from "./MembershipHolding";

const ActiveMembershipItem = ({ membership: defaultMembership, index, isEntrance, selected, memberships, setDefaultMembershipId, removeMembership, editMembership, createMembership, changeDefaultMembership }) => {
  const price = useInput("");
  const date = useInput("");
  const [membership, setMembership] = useState({
    ...defaultMembership,
    start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
    end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
    membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
    cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
  });
  const [showMembership, setShowMembership] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showEnd, setShowEnd] = useState(false);
  const [calcMembershipCount, setCalcMembershipCount] = useState(Number(membership.membershipCount) - Number(membership.totalDeduct));
  const [calcCancelCount, setCalcCancelCount] = useState(Number(membership.cancelCount) - Number(membership.totalCancel));
  const [editing, setEditing] = useState(membership.id === -1);
  const [showOption, setShowOption] = useState(false);
  const [showHolding, setShowHolding] = useState(false);

  const [editProduct, setEditProduct] = useState(membership.product);
  const [editStart, setEditStart] = useState(membership.start);
  const [editEnd, setEditEnd] = useState(membership.end);
  const editMembershipCount = useInput(Number(membership.membershipCount) - Number(membership.totalDeduct));
  const editCancelCount = useInput(Number(membership.cancelCount) - Number(membership.totalCancel));
  const [addType, setAddType] = useState(membership.payment ? membership.payment.type : "added_by_admin");
  const [dDay, setDDay] = useState("");

  const handleMembership = (product) => {
    if (membership.isUsed) {
      alert("사용중인 회원권은 회원권 변경이 불가능합니다.");
      return;
    }

    if (product.membershipType !== 1 && membership.id === -1) {
      setCalcMembershipCount(product.membership);
      setCalcCancelCount(product.cancel);
      membership.membershipCount = product.membership;
      membership.cancelCount = product.cancel;
    }

    // setMembership({
    //     ...membership,
    //     product,
    // });

    setEditProduct(product);
    setShowMembership(false);
  };

  const handleShowEdit = (values) => {
    if (values) {
      setEditProduct(membership.product);
      setEditStart(membership.start);
      setEditEnd(membership.end);
    }

    setShowEdit(values);
  };

  const handleStart = (start) => {
    if (membership.id === -1) {
      if (membership.product.duration) {
        // setMembership({
        //     ...membership,
        //     start,
        //     end: new Date(
        //         start.getTime() +
        //             1000 *
        //                 60 *
        //                 60 *
        //                 24 *
        //                 (membership.product.duration - 1),
        //     ),
        // });

        setEditStart(start);
        setEditEnd(new Date(start.getTime() + 1000 * 60 * 60 * 24 * (membership.product.duration - 1)));
      } else {
        setEditStart(start);

        // setMembership({
        //     ...membership,
        //     start,
        // });
      }
    } else {
      setEditStart(start);
      // setMembership({
      //     ...membership,
      //     start,
      // });
    }
    setShowStart(false);
  };

  const handleEnd = (end) => {
    // setMembership({
    //     ...membership,
    //     end,
    // });
    setEditEnd(end);
    setShowEnd(false);
  };

  const handlePrice = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      price.setValue(value);
      setMembership({ ...membership, price: value });
    }
  };

  const handleDate = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      date.setValue(value);
      setMembership({ ...membership, date: value });
    }
  };

  const handleMembershipCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcMembershipCount(value);
      editMembershipCount.setValue(value);
      setMembership({
        ...membership,
        membershipCount: Number(value) + (membership.totalDeduct ? Number(membership.totalDeduct) : 0),
      });
    }
  };

  const handleCancelCount = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      setCalcCancelCount(value);
      editCancelCount.setValue(value);
      setMembership({
        ...membership,
        cancelCount: Number(value) + (membership.totalCancel ? Number(membership.totalCancel) : 0),
      });
    }
  };

  const edit = async () => {
    let tmpPrice = price.value !== "" ? Number(price.value) : null;
    let approvalDate = date.value !== "" ? date.value.substring(0, 6) : null;
    let approvalTime = date.value !== "" ? `${date.value.substring(6, 10)}00` : null;

    if ((tmpPrice === null && date.value !== "") || (tmpPrice !== null && date.value === "")) {
      alert("결제금액과 결제일시는 같이 입력되어야 합니다.");
      return;
    }
    if (approvalTime && approvalTime.length !== 6 && approvalDate && approvalDate.length !== 6) {
      alert("올바른 결제일시를 입력해주세요.");
      return;
    }

    setEditing(false);

    setMembership({
      ...membership,
      start: editStart,
      end: editEnd,
      product: editProduct,
    });

    await editMembership({
      ...membership,
      start: editStart,
      end: editEnd,
      product: editProduct,
      payment: {
        id: -1,
        totalAmount: tmpPrice,
        approvalDate: approvalDate,
        approvalTime: approvalTime,
      },
    }).then(function (res) {
      console.log("res :::::: ", res);
      setShowEdit(!res);
      setEditing(!res);
      setShowOption(!res);
    });
  };

  useEffect(() => {
    setMembership({
      ...defaultMembership,
      start: defaultMembership.start ? new Date(Number(defaultMembership.start)) : null,
      end: defaultMembership.end ? new Date(Number(defaultMembership.end)) : null,
      membershipCount: defaultMembership.membershipCount ? Number(defaultMembership.membershipCount) : "",
      cancelCount: defaultMembership.cancelCount ? Number(defaultMembership.cancelCount) : "",
    });
  }, [editing, defaultMembership]);

  useEffect(() => {
    price.setValue(membership.payment ? membership.payment.totalAmount : "");
    date.setValue(membership.payment ? membership.payment.approvalDate + membership.payment.approvalTime : "");
  }, [membership.payment]);

  useEffect(() => {
    const now = new Date();
    const tmpNow = new Date(now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate());
    let tmpDDay = membership.end - tmpNow;
    setDDay(Math.floor(tmpDDay / 1000 / 60 / 60 / 24));
  }, [membership.end]);

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.py15} ${styles.px0} ${index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf}`}>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowOption(!showOption)}>
        <p className={`${styles.fontR} ${styles.font14} ${membership.product.id === -1 ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>{membership.product.id === -1 ? "선택해주세요." : membership.product.name}</p>
        {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt5}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowOption(!showOption)}>
        <div style={{ display: [1, 3, 4].indexOf(membership.product.membershipType) > -1 ? "flex" : "none" }}>
          <p className={`${styles.fontR} ${styles.font14} ${membership.id === -1 && !membership.start ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.id === -1
              ? membership.start
                ? `${membership.start.getFullYear()}/${membership.start.getMonth() + 1 < 10 ? "0" + String(membership.start.getMonth() + 1) : membership.start.getMonth() + 1}/${membership.start.getDate() < 10 ? "0" + String(membership.start.getDate()) : membership.start.getDate()}`
                : "선택해주세요."
              : membership.start
              ? `${membership.start.getFullYear()}/${membership.start.getMonth() + 1 < 10 ? "0" + String(membership.start.getMonth() + 1) : membership.start.getMonth() + 1}/${membership.start.getDate() < 10 ? "0" + String(membership.start.getDate()) : membership.start.getDate()}`
              : ""}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
        <div className={`${styles.flex2}`} style={{ display: [1, 3, 4].indexOf(membership.product.membershipType) > -1 ? "flex" : "none" }} onClick={() => setShowOption(!showOption)}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.flex1} ${membership.id === -1 && !membership.end ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`}>
            {membership.id === -1
              ? membership.end
                ? `${membership.end.getFullYear()}/${membership.end.getMonth() + 1 < 10 ? "0" + String(membership.end.getMonth() + 1) : membership.end.getMonth() + 1}/${membership.end.getDate() < 10 ? "0" + String(membership.end.getDate()) : membership.end.getDate()}`
                : "선택해주세요."
              : membership.end
              ? `${membership.end.getFullYear()}/${membership.end.getMonth() + 1 < 10 ? "0" + String(membership.end.getMonth() + 1) : membership.end.getMonth() + 1}/${membership.end.getDate() < 10 ? "0" + String(membership.end.getDate()) : membership.end.getDate()}`
              : ""}
          </p>
          {editing && <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"dsc"} className={`${styles.cursorPointer} ${styles.mt10}`} style={{ width: 18 * 0.5, height: 10 * 0.5 }} />}
        </div>
      </div>
      <div className={`${styles.flex2}`} onClick={() => setShowOption(!showOption)}>
        <NumberFormat
          value={membership.product.membershipType === 2 || membership.product.membershipType === 3 ? calcMembershipCount : ""}
          thousandSeparator={true}
          className={`${styles.inputTransparent} ${styles.flex1} ${styles.textCenter}`}
          type={"tel"}
          displayType={"input"}
          onValueChange={handleMembershipCount}
          placeholder={editing ? "횟수를 입력해주세요. (숫자만 입력)" : ""}
          style={{
            width: "100%",
            minWidth: 20,
          }}
          readOnly={true}
        />
      </div>

      <p className={`${styles.fontR} ${styles.font14} ${membership.id === -1 ? styles.grayA2 : styles.black} ${styles.textCenter} ${styles.flex1}`} onClick={() => setShowOption(!showOption)}>
        {membership.id === -1 ? (
          "자동입력"
        ) : (
          <>
            {membership.status === "inactive" && "비활성"}
            {membership.status === "active" && "유효"}
            {membership.status === "active" && <br />}
            {membership.status === "active" && "D-" + dDay}
            {membership.status === "ready" && "사용대기"}
            {membership.status === "expired" && "만료"}
            {membership.status === "holding" && "홀딩중"}
          </>
        )}
      </p>

      <Modal isOpen={showOption} onRequestClose={() => setShowOption(false)} style={billSm}>
        <div style={{ textAlign: "right" }}>
          <img src={require("../../../assets/images/icon_close_menu.png").default} alt={"CLOSE"} className={`${styles.cursorPointer} ${styles.icon20}`} onClick={() => setShowOption(false)} />
        </div>
        <div style={{ display: "flex", flexFlow: "column", gap: 10, marginTop: 10 }}>
          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <button type={"button"} className={`${styles.bgBlack} ${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} onClick={changeDefaultMembership}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>대표</p>
            </button>
            <button type={"button"} className={`${styles.bgBlack} ${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} onClick={() => handleShowEdit(true)}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>변경</p>
            </button>
          </div>

          <div style={{ width: "100%", display: "flex", gap: 10 }}>
            <button type={"button"} className={`${styles.bgBlack} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} onClick={removeMembership}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>삭제</p>
            </button>
            <button type={"button"} className={`${styles.bgBlack} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`} onClick={() => setShowHolding(true)}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>홀딩</p>
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={showEdit} onRequestClose={() => setShowEdit(false)} style={billSm}>
        <div style={{ textAlign: "right" }}>
          <img src={require("../../../assets/images/icon_close_menu.png").default} alt={"CLOSE"} className={`${styles.cursorPointer} ${styles.icon20}`} onClick={() => setShowEdit(false)} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowMembership(true)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>회원권명</p>
          </div>
          <input type={"text"} value={editProduct.name} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} placeholder={"회원권을 선택해주세요."} readOnly={true} />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowStart([1, 3, 4].indexOf(editProduct.membershipType) > -1)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>시작일</p>
          </div>
          <input
            type={"text"}
            value={[1, 3, 4].indexOf(editProduct.membershipType) > -1 ? editStart.getFullYear() + "/" + (editStart.getMonth() + 1).toString().padStart(2, "0") + "/" + editStart.getDate().toString().padStart(2, "0") : ""}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
            style={{ minWidth: 20 }}
            placeholder={"시작일을 선택해주세요."}
            readOnly={true}
          />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"시작일 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`} onClick={() => setShowEnd([1, 3, 4].indexOf(editProduct.membershipType) > -1)}>
          <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
            <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>종료일</p>
          </div>
          <input
            type={"text"}
            value={[1, 3, 4].indexOf(editProduct.membershipType) > -1 && editEnd ? editEnd.getFullYear() + "/" + (editEnd.getMonth() + 1).toString().padStart(2, "0") + "/" + editEnd.getDate().toString().padStart(2, "0") : ""}
            className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
            style={{ minWidth: 20 }}
            placeholder={"종료일을 선택해주세요."}
            readOnly={true}
          />
          <img src={require("../../../assets/images/icon_dropdown_down.png").default} alt={"종료일 선택"} className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`} style={{ alignSelf: "center" }} />
        </div>
        {(editProduct.membershipType === 2 || editProduct.membershipType === 3) && (
          <>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>횟수</p>
              </div>
              <NumberFormat
                value={editMembershipCount.value}
                thousandSeparator={true}
                className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                type={"tel"}
                displayType={"input"}
                onValueChange={handleMembershipCount}
                placeholder={"횟수를 입력해주세요."}
                style={{
                  width: "100%",
                  minWidth: 20,
                }}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>취소 횟수</p>
              </div>
              {membership.product.ignoreCancel ? (
                <input
                  value={"무제한"}
                  readOnly={true}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                  placeholder={"취소 횟수를 입력해주세요."}
                  style={{
                    width: "100%",
                    minWidth: 20,
                  }}
                />
              ) : (
                <NumberFormat
                  value={editCancelCount.value}
                  thousandSeparator={true}
                  className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
                  type={"tel"}
                  displayType={"input"}
                  onValueChange={handleCancelCount}
                  placeholder={"취소 횟수를 입력해주세요."}
                  style={{
                    width: "100%",
                    minWidth: 20,
                  }}
                />
              )}
            </div>
          </>
        )}
        {addType === "added_by_admin" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
            <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제금액</p>
            </div>
            <NumberFormat
              value={price.value}
              thousandSeparator={true}
              className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
              type={"tel"}
              displayType={"input"}
              onValueChange={handlePrice}
              placeholder={"결제 금액을 입력해주세요. (숫자만 입력)"}
              style={{
                width: "100%",
                minWidth: 20,
              }}
            />
          </div>
        )}
        {addType === "added_by_admin" && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
            <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
              <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>결제일시</p>
            </div>
            <NumberFormat
              value={date.value}
              thousandSeparator={true}
              className={`${styles.inputWhite} ${styles.flex1} ${styles.textLeft} ${styles.px15}`}
              type={"tel"}
              displayType={"input"}
              onValueChange={handleDate}
              placeholder={"YYMMDD HH:MM"}
              format={"###### ##:##"}
              style={{
                width: "100%",
                minWidth: 20,
              }}
            />
          </div>
        )}

        <div className={`${styles.mt20}`}>
          <button type={"button"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} onClick={edit}>
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
          </button>
          <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} onClick={() => setShowEdit(false)}>
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
          </div>
        </div>
      </Modal>

      <Modal isOpen={showMembership} onRequestClose={() => setShowMembership(false)} style={billSm}>
        <div
          className={`${styles.bgWhite} ${styles.hideScroll}`}
          style={{
            maxHeight: 300,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {memberships
            ? memberships.map((item) => {
                if (!(item.membershipType === 2 && isEntrance)) {
                  return (
                    <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleMembership(item)}>
                      <p className={`${styles.font16} ${styles.black}`}>{item.name}</p>
                    </div>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => setShowMembership(false)}>
          <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>확인</p>
        </div>
      </Modal>

      <Modal isOpen={showStart} onRequestClose={() => setShowStart(false)} style={billSm}>
        <Calendar
          calendarType={"US"}
          value={editStart}
          maxDate={editEnd ? editEnd : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleStart}
        />
      </Modal>
      <Modal isOpen={showEnd} onRequestClose={() => setShowEnd(false)} style={billSm}>
        <Calendar
          calendarType={"US"}
          value={editEnd}
          minDate={editStart ? editStart : null}
          minDetail={"month"}
          nextLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
            </span>
          }
          next2Label={null}
          prevLabel={
            <span>
              <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
            </span>
          }
          prev2Label={null}
          navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
          tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
          onChange={handleEnd}
        />
      </Modal>
      <Modal isOpen={showHolding} onRequestClose={() => setShowHolding(false)} style={billSm}>
        <MembershipHolding membership={membership} close={() => setShowHolding(false)} />
      </Modal>
    </div>
  );
};

export default ActiveMembershipItem;
