import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import Calendar from 'react-calendar';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import {
    datediff,
    changeToFormattedNumber,
    removeNonNumeric,
} from '../../../common/utility';

const PRODUCT = gql`
    query getProduct($id: Int!, $machine: String!) {
        getProduct(id: $id, machine: $machine) {
            id
            name
            price
            discountPrice
            discountPercent
            discountType
            discountStart
            discountEnd
        }
    }
`;

const EDIT = gql`
    mutation editDiscount(
        $percent: Float!
        $price: Float!
        $discountType: String!
        $start: String!
        $end: String
        $productId: Int!
    ) {
        editDiscount(
            percent: $percent
            price: $price
            discountType: $discountType
            start: $start
            end: $end
            productId: $productId
        ) {
            product {
                id
                discountPrice
                discountPercent
                discountType
                discountStart
                discountEnd
            }
            error
        }
    }
`;

const EditMembershipDiscount = () => {
    const history = useHistory();
    const location = useLocation();

    const percent = useInput('');
    const price = useInput('');

    const [start, setStart] = useState(null);
    const [showStart, setShowStart] = useState(false);
    const [end, setEnd] = useState(null);
    const [showEnd, setShowEnd] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: location.state.product.id ? location.state.product.id : -1,
            machine: location.state.machine ? location.state.machine : '',
        },
        onError: () => {
            alert('회원권이 존재하지 않습니다.');
            history.goBack();
        },
    });

    const [editDiscountM] = useMutation(EDIT);

    const handlePercent = e => {
        const {
            target: { value },
        } = e;
        if (Number(value) <= 100) {
            percent.setValue(value);
            price.setValue('');
        }
    };

    const handlePrice = e => {
        const {
            target: { value },
        } = e;
        if (Number(removeNonNumeric(value)) <= data.getProduct.price) {
            price.setValue(changeToFormattedNumber(value));
            percent.setValue('');
        }
    };

    const handleStart = start => {
        setStart(start);
        setShowStart(false);
    };

    const handleEnd = end => {
        setEnd(end);
        setShowEnd(false);
    };

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            if (data && data.getProduct) {
                if (percent.value && price.value) {
                    alert('할인율과 할인금액 중 한 가지만 입력해주세요.');
                    return;
                }
                if ((percent.value || price.value) && start) {
                    setLoading(true);
                    try {
                        const { data: result } = await editDiscountM({
                            variables: {
                                percent: price.value
                                    ? Number(
                                          (
                                              (Number(
                                                  removeNonNumeric(price.value),
                                              ) /
                                                  data.getProduct.price) *
                                              100
                                          ).toFixed(2),
                                      )
                                    : Number(percent.value),
                                price: percent.value
                                    ? Math.round(
                                          data.getProduct.price *
                                              (Number(percent.value) / 100),
                                      )
                                    : Number(removeNonNumeric(price.value)),
                                discountType: price.value ? 'price' : 'percent',
                                start: `${start.getTime()}`,
                                end: end ? `${end.getTime()}` : null,
                                productId: data.getProduct.id,
                            },
                        });
                        if (result && result.editDiscount) {
                            if (result.editDiscount.error) {
                                setLoading(false);
                                alert(result.editDiscount.error);
                            } else {
                                setLoading(false);
                                alert('할인을 변경하였습니다.');
                            }
                        } else {
                            setLoading(false);
                            alert('오류가 발생하였습니다.');
                        }
                    } catch {
                        setLoading(false);
                        alert('오류가 발생하였습니다.');
                    }
                } else {
                    alert('위의 정보를 입력해주세요.');
                }
            }
        }
    };

    const initialize = () => {
        percent.setValue(
            data.getProduct.discountType === 'percent'
                ? data.getProduct.discountPercent
                : '',
        );
        price.setValue(
            data.getProduct.discountType === 'price'
                ? changeToFormattedNumber(data.getProduct.discountPrice)
                : '',
        );
        if (data && data.getProduct) {
            setStart(
                data.getProduct.discountStart
                    ? new Date(Number(data.getProduct.discountStart))
                    : null,
            );
            setShowStart(false);
            setEnd(
                data.getProduct.discountEnd
                    ? new Date(Number(data.getProduct.discountEnd))
                    : null,
            );
            setShowEnd(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (data && data.getProduct) {
            initialize();
        }
    }, [data]);

    return (
        <div
            className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div
                    className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div
                        className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div
                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
                            <p
                                className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                {`할인 관리${
                                    data && data.getProduct.name
                                        ? ` (${data.getProduct.name})`
                                        : ''
                                }`}
                                <span
                                    className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.ml10}`}>
                                    (모든 할인은 중복으로 적용되지 않으며,
                                    처음에 적용된 할인이 우선 적용 됩니다.)
                                </span>
                            </p>
                            <div
                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                                onClick={history.goBack}>
                                <img
                                    src={
                                        require('../../../assets/images/icon_back.png')
                                            .default
                                    }
                                    alt={'이전'}
                                    className={`${styles.cursorPointer}`}
                                    style={{
                                        width: 45 * 0.2,
                                        height: 79 * 0.2,
                                    }}
                                />
                                <p
                                    className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>
                                    뒤로가기
                                </p>
                            </div>
                        </div>
                        {data && data.getProduct ? (
                            <form
                                action={''}
                                onSubmit={submit}
                                className={`${styles.mt40} ${styles.center}`}>
                                <div style={{ width: 1250 }}>
                                    <p
                                        className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                        *표시는 필수 입력사항입니다.
                                    </p>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt10}`}
                                    style={{ width: 1250 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                        style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                회원권명
                                            </p>
                                        </div>
                                        <input
                                            type={'text'}
                                            name={'name'}
                                            value={
                                                data && data.getProduct
                                                    ? data.getProduct.name
                                                    : ''
                                            }
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                        style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                할인율 (%)*
                                            </p>
                                        </div>
                                        <input
                                            type={'tel'}
                                            name={'percent'}
                                            value={percent.value}
                                            onChange={handlePercent}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            placeholder={
                                                '할인율을 입력해주세요. (숫자만 입력)'
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                    style={{ width: 1250 }}>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                        style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                할인 금액 (원)*
                                            </p>
                                        </div>
                                        <input
                                            type={'tel'}
                                            name={'price'}
                                            value={price.value}
                                            onChange={handlePrice}
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            placeholder={
                                                '할인 금액을 입력해주세요. (숫자만 입력)'
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                            style={{
                                                width: 615,
                                                borderBottomLeftRadius:
                                                    showStart ? 0 : 10,
                                                borderBottomRightRadius:
                                                    showStart ? 0 : 10,
                                            }}
                                            onClick={() =>
                                                setShowStart(!showStart)
                                            }>
                                            <div
                                                className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                                style={{ width: 190 }}>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    할인 적용 시작일*
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                                <p
                                                    className={`${
                                                        styles.font16
                                                    } ${
                                                        start
                                                            ? styles.black
                                                            : styles.grayA2
                                                    }`}>
                                                    {start
                                                        ? `${start.getFullYear()}-${
                                                              start.getMonth() +
                                                                  1 <
                                                              10
                                                                  ? '0' +
                                                                    String(
                                                                        start.getMonth() +
                                                                            1,
                                                                    )
                                                                  : start.getMonth() +
                                                                    1
                                                          }-${
                                                              start.getDate() <
                                                              10
                                                                  ? '0' +
                                                                    String(
                                                                        start.getDate(),
                                                                    )
                                                                  : start.getDate()
                                                          }`
                                                        : '할인 적용 시작일을 선택해주세요.'}
                                                </p>
                                                <img
                                                    src={
                                                        showStart
                                                            ? require('../../../assets/images/icon_dropdown_up.png')
                                                                  .default
                                                            : require('../../../assets/images/icon_dropdown_down.png')
                                                                  .default
                                                    }
                                                    alt={'회원권 분류 선택'}
                                                    className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                                />
                                            </div>
                                        </div>
                                        {showStart && (
                                            <div
                                                className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                    style={{ width: 615 }}>
                                                    <div
                                                        className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                        style={{
                                                            width: 190,
                                                        }}></div>
                                                    <div
                                                        className={`${styles.py10} ${styles.flex1}`}>
                                                        <Calendar
                                                            calendarType={'US'}
                                                            value={start}
                                                            minDetail={'month'}
                                                            maxDate={end}
                                                            nextLabel={
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                                .default
                                                                        }
                                                                        alt={
                                                                            '다음'
                                                                        }
                                                                        className={`${styles.icon15}`}
                                                                    />
                                                                </span>
                                                            }
                                                            next2Label={null}
                                                            prevLabel={
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                                .default
                                                                        }
                                                                        alt={
                                                                            '이전'
                                                                        }
                                                                        className={`${styles.icon15}`}
                                                                    />
                                                                </span>
                                                            }
                                                            prev2Label={null}
                                                            navigationLabel={({
                                                                label,
                                                            }) => (
                                                                <p
                                                                    className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                                                                    {label}
                                                                </p>
                                                            )}
                                                            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                                            onChange={
                                                                handleStart
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.mt20}`}
                                    style={{ width: 1250 }}>
                                    <div>
                                        <div
                                            className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                                            style={{
                                                width: 615,
                                                borderBottomLeftRadius: showEnd
                                                    ? 0
                                                    : 10,
                                                borderBottomRightRadius: showEnd
                                                    ? 0
                                                    : 10,
                                            }}
                                            onClick={() =>
                                                setShowEnd(!showEnd)
                                            }>
                                            <div
                                                className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                                style={{ width: 190 }}>
                                                <p
                                                    className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                    할인 적용 종료일
                                                </p>
                                            </div>
                                            <div
                                                className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
                                                <p
                                                    className={`${
                                                        styles.font16
                                                    } ${
                                                        end
                                                            ? styles.black
                                                            : styles.grayA2
                                                    }`}>
                                                    {end
                                                        ? `${end.getFullYear()}-${
                                                              end.getMonth() +
                                                                  1 <
                                                              10
                                                                  ? '0' +
                                                                    String(
                                                                        end.getMonth() +
                                                                            1,
                                                                    )
                                                                  : end.getMonth() +
                                                                    1
                                                          }-${
                                                              end.getDate() < 10
                                                                  ? '0' +
                                                                    String(
                                                                        end.getDate(),
                                                                    )
                                                                  : end.getDate()
                                                          }`
                                                        : '할인 적용 종료일을 선택해주세요.'}
                                                </p>
                                                <img
                                                    src={
                                                        showEnd
                                                            ? require('../../../assets/images/icon_dropdown_up.png')
                                                                  .default
                                                            : require('../../../assets/images/icon_dropdown_down.png')
                                                                  .default
                                                    }
                                                    alt={'회원권 분류 선택'}
                                                    className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`}
                                                />
                                            </div>
                                        </div>
                                        {showEnd && (
                                            <div
                                                className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
                                                style={{ width: 615 }}>
                                                <div
                                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}
                                                    style={{ width: 615 }}>
                                                    <div
                                                        className={`${styles.borderRightGrayA2} ${styles.px20}`}
                                                        style={{
                                                            width: 190,
                                                        }}></div>
                                                    <div
                                                        className={`${styles.py10} ${styles.flex1}`}>
                                                        <Calendar
                                                            calendarType={'US'}
                                                            value={end}
                                                            minDetail={'month'}
                                                            minDate={start}
                                                            nextLabel={
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            require('../../../assets/images/icon_dropdown_right.png')
                                                                                .default
                                                                        }
                                                                        alt={
                                                                            '다음'
                                                                        }
                                                                        className={`${styles.icon15}`}
                                                                    />
                                                                </span>
                                                            }
                                                            next2Label={null}
                                                            prevLabel={
                                                                <span>
                                                                    <img
                                                                        src={
                                                                            require('../../../assets/images/icon_dropdown_left.png')
                                                                                .default
                                                                        }
                                                                        alt={
                                                                            '이전'
                                                                        }
                                                                        className={`${styles.icon15}`}
                                                                    />
                                                                </span>
                                                            }
                                                            prev2Label={null}
                                                            navigationLabel={({
                                                                label,
                                                            }) => (
                                                                <p
                                                                    className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                                                                    {label}
                                                                </p>
                                                            )}
                                                            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                                                            onChange={handleEnd}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                                        style={{ width: 615 }}>
                                        <div
                                            className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`}
                                            style={{ width: 190 }}>
                                            <p
                                                className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                                할인 기간 (자동 계산)
                                            </p>
                                        </div>
                                        <input
                                            type={'text'}
                                            value={
                                                start && end
                                                    ? end
                                                        ? datediff(start, end)
                                                        : 0
                                                    : 0
                                            }
                                            className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mb70}`}
                                    style={{ width: 1250, marginTop: 500 }}>
                                    <div
                                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`}
                                        style={{ opacity: loading ? 0.4 : 1 }}
                                        onClick={history.goBack}>
                                        <p
                                            className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                            취소
                                        </p>
                                    </div>
                                    <button
                                        type={'submit'}
                                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`}
                                        style={{ opacity: loading ? 0.4 : 1 }}>
                                        <p
                                            className={`${styles.fontB} ${styles.font18} ${styles.white}`}>
                                            완료
                                        </p>
                                    </button>
                                </div>
                            </form>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditMembershipDiscount;
