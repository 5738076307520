import React, {useState, useEffect, useRef, useMemo} from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import useInput from '../../../hooks/useInput';
import cache from "../../../apollo/cache";
import NoticeEditor from "../../../components/Notice/Mobile/NoticeEditor";
import axios from "axios";
import {FETCH_URL} from "../../../config";

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const EDIT_NOTICE = gql`
    mutation editAdminNotice($id: Int!, $subject: String!, $contents:String!) {
        editAdminNotice(id: $id, subject:$subject, contents:$contents) {
            success
            error
        }
    }
`;

const DETAIL_NOTICE = gql`
    query detailNotice($id:Int!) {
        detailNotice(id:$id) {
            id
            subject
            contents
        }
    }
`;

const DELETE_NOTICE = gql`
    mutation deleteNotice($id: Int!) {
        deleteNotice(id: $id) {
            success
            error
        }
    }
`;

const TeacherAdd = () => {
    const history = useHistory();
    const location = useLocation();

    const subject = useInput('');
    const [contents, setContents] = useState('');
    const [editNotice] = useMutation(EDIT_NOTICE);
    const [deleteNoticeM] = useMutation(DELETE_NOTICE);

    const quillRef = useRef();

    const [loading, setLoading] = useState(false);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const { data: noticeData, loading:loadingNotice, refetch:noticeRefetch } = useQuery(DETAIL_NOTICE, {
        fetchPolicy: "cache-and-network",
        variables: {
            id: location.state.notice ? location.state.notice.id : -1
        }
    });

    const onEditorChange = (value) => {
        setContents(value);
    }

    const submit = async e => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);
            if (data && data.getMachine) {
                if (subject.value&&contents) {
                    if (subject.value.length<=40) {
                        console.log('subject : ',subject.value);
                        console.log('contents : ',contents);
                        const { data: result } = await editNotice({
                            variables: {
                                id: location.state.notice ? location.state.notice.id : -1,
                                subject: subject.value,
                                contents: contents
                            }
                        });
                        if (result && result.editAdminNotice.success) {
                            if (result.editAdminNotice.error) {
                                setLoading(false);
                                alert(result.editAdminNotice.error);
                            } else {
                                setLoading(false);
                                alert('공지사항 정보를 수정하였습니다.');
                                noticeRefetch();
                            }
                        } else {
                            setLoading(false);
                            alert('오류가 발생하였습니다.');
                        }
                    } else {
                        setLoading(false);
                        alert('공지명을 40글자 이내로 작성해주세요.');
                    }
                } else {
                    setLoading(false);
                    alert('필수 값을 모두 입력해주세요.');
                }
            }
        }
    }

    const deleteNotice = async () => {
        if (!loading) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await deleteNoticeM({
                    variables: {
                        id: location.state.notice.id
                    }
                });
                setLoading(false);
                if (data && data.deleteNotice.success) {
                    cache.evict({
                        id: `Notice:${location.state.notice.id}`,
                    });
                    alert('공지사항을 삭제하였습니다.');
                    history.goBack();
                } else {
                    alert('오류가 발생하였습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (noticeData) {
            console.log(noticeData.detailNotice);
            subject.setValue(noticeData.detailNotice.subject);
            setContents(noticeData.detailNotice.contents);
        }
    }, [noticeData]);

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    const imageHandler = () => {
        console.log('에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!');

        // 1. 이미지를 저장할 input type=file DOM을 만든다.
        const input = document.createElement('input');
        // 속성 써주기
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
        // input이 클릭되면 파일 선택창이 나타난다.

        // input에 변화가 생긴다면 = 이미지를 선택
        input.addEventListener('change', async () => {
            console.log('온체인지');
            const file = input.files[0];
            // api에 맞는 형식으로 데이터 만들어준다.
            const formData = new FormData();
            formData.append('notice', file, file.name); // formData는 키-밸류 구조
            // 백엔드에 이미지를 보낸다.
            try {
                axios.post(
                    `${FETCH_URL}/api/originalUpload`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                ).then((result) => {
                    if (result&&result.data.location) {
                        console.log('성공 시, 백엔드가 보내주는 데이터', result.data.location);
                        const IMG_URL = result.data.location;
                        // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
                        // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
                        // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

                        // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
                        const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
                        // 1. 에디터 root의 innerHTML을 수정해주기
                        // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
                        // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
                        // editor.root.innerHTML =
                        //     editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

                        // 2. 현재 에디터 커서 위치값을 가져온다
                        const range = editor.getSelection();
                        // 가져온 위치에 이미지를 삽입한다
                        editor.insertEmbed(range.index, 'image', IMG_URL);
                        editor.setSelection(range.index + 1);
                    } else {
                        console.log('실패했어요ㅠ');
                    }
                });
            } catch (error) {
                console.log('실패했어요ㅠㅠ');
            }
        });
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                [{ 'size' : ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline','strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                [{ 'align': [] }, { 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                ['clean'],
            ],
            handlers: {
                image: imageHandler
            },
        }

    }), []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        카테고리 관리
                        {`${
                            data && data.getMachine
                                ? ` (${data.getMachine.name})`
                                : ''
                        }`}
                    </p>
                    <form
                        action={''}
                        onSubmit={submit}
                        className={`${styles.mt40} ${styles.px20}`}>
                        <div>
                            <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.textRight}`}>
                                *표시는 필수 입력사항입니다.
                            </p>
                        </div>

                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}>
                            <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 115 }}>
                                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                    공지명*
                                </p>
                            </div>
                            <input
                                type={'text'}
                                name={'subject'}
                                value={subject.value}
                                onChange={subject.onChange}
                                className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`}
                                placeholder={
                                    '공지명을 입력해주세요.'
                                }
                                maxLength={40}
                            />
                        </div>
                        <div
                            className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.py10} ${styles.mt10}`}>
                            <p
                                className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                                공지 내용*
                            </p>
                            <div>
                                <NoticeEditor value={contents} onChange={onEditorChange} _modules={modules} _ref={quillRef}/>
                            </div>
                        </div>
                        <div style={{marginTop:50}}>
                            <button
                                type={'submit'}
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`}
                                style={{ opacity: loading ? 0.4 : 1 }}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    저장
                                </p>
                            </button>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={history.goBack}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    취소
                                </p>
                            </div>
                            <div
                                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow} ${styles.mt10}`}
                                style={{ opacity: loading ? 0.4 : 1 }}
                                onClick={deleteNotice}>
                                <p
                                    className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                    삭제
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TeacherAdd;
