import React, { useState } from 'react';
import Modal from 'react-modal';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';
import { bill } from '../../../common/modal';
import Bill from '../../Global/Bill';

const SitePayment = ({ payment }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.approvalDate.slice(
                        0,
                        2,
                    )}/${payment.approvalDate.slice(
                        2,
                        4,
                    )}/${payment.approvalDate.slice(
                        4,
                        6,
                    )} - ${payment.approvalTime.slice(
                        0,
                        2,
                    )}:${payment.approvalTime.slice(
                        2,
                        4,
                    )}:${payment.approvalTime.slice(4, 6)}`}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.center}`}>
                <div
                    className={`${styles.bgWhite} ${styles.borderRadius5} ${styles.btnShadow} ${styles.bgImgContain}`}
                    style={{
                        backgroundImage: `url(${payment.productImage})`,
                        width: 120,
                        height: 120,
                    }}></div>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {payment.productName}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}
                    style={
                        payment.status === 'refund'
                            ? { textDecorationLine: 'line-through' }
                            : null
                    }>
                    {numberWithCommas(payment.totalAmount)}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {`${payment.issuerInfo ? payment.issuerInfo : ''}(${
                        payment.installment === 0
                            ? '일시불'
                            : `${payment.installment}개월`
                    })`}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}
                    onClick={() => setShowModal(!showModal)}>
                    영수증
                </p>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={bill}>
                <Bill payment={payment} />
            </Modal>
        </div>
    );
};

export default SitePayment;
