import React, { useState, useEffect } from "react";

import styles from "../../styles/styles.module.scss";

const width = window.outerWidth;
console.log(width);

//이미지 정방향 정렬
const imageSort = (orientation) => {
  let rotate = [{ imageRotate: "", textRotate: "" }];

  switch (orientation) {
    // @details 이미지 회전값이 0인경우 ( 정방향 )
    case 1:
      rotate.imageRotate = "rotate( 0deg )";
      rotate.textRotate = "rotate( 0deg )";
      break;

    // @details 이미지 회전값이 180 기운 경우
    case 3:
      rotate.imageRotate = "rotate( 180deg )";
      rotate.textRotate = "rotate( -180deg )";
      break;

    // @details 이미지 회전값이 270 기운 경우 ( 왼쪽으로 90 기운 경우 )
    case 6:
      rotate.imageRotate = "rotate( 90deg )";
      rotate.textRotate = "rotate( -90deg )";
      break;

    // @details 이미지 회전값이 90 기운 경우
    case 8:
      rotate.imageRotate = "rotate( 270deg )";
      rotate.textRotate = "rotate( -270deg )";
      break;
  }

  return rotate;
};

const IntroImage = ({
  image,
  index,
  removeImage,
  orientation,
  horizontal = false,
}) => {
  const [hover, setHover] = useState(false);
  const rotate = imageSort(orientation);
  return (
    <div
      id="img"
      className={`${styles.bgGrayF4} ${styles.center} ${styles.widthFull} ${
        styles.bgImgContain
      } ${horizontal ? "" : index === 0 ? "" : styles.mt15}`}
      style={{
        textAlign: "center",
        left:
          orientation === 1 || orientation === undefined
            ? 0
            : width < 992
            ? 55
            : 30,
        width: orientation === 1 || orientation === undefined ? 260 : 150,
        height: 150,
        position: "relative",
        backgroundImage: `url(${image})`,
        transform: rotate.imageRotate,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <div
          className={`${styles.center}`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#00000007",
            transform: rotate.textRotate,
          }}
        >
          <p
            className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.cursorPointer}`}
            onClick={() => removeImage(index)}
          >
            삭제
          </p>
        </div>
      )}
    </div>
  );
};

export default IntroImage;
