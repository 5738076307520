import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import MembershipMachine from '../../../components/Membership/PC/MembershipMachine';
import MembershipSaleGraph from '../../../components/Graph/MembershipSale';
import MembershipSaleStatics from '../../../components/Statics/PC/MembershipSale';

const MACHINE = gql`
    query getMachines($skip: Int!){
        getMachines(skip: $skip){
            machines{
                id
                username
                name
                status
                createdAt
                membershipProductTotal
                membershipProductStock{
                    _sum{
                        stock
                    }
                }
                membershipProductDiscountTotal
            }
            total
        }
    }
`

const MembershipHome = () => {
    const history = useHistory()
    const location = useLocation()

    const { data, loading:loadingMachine, refetch } = useQuery(MACHINE, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0
        }
    })

    const handlePage = (page) => {
        history.push(`/membership/?page=${page.selected + 1}`)
    }

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            refetch({
                skip: 5*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                회원권 관리
                            </p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기계정
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태	
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 회원권수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    현재 전체 회원권 수량
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    할인 적용 회원권
                                </p>
                            </div>
                            <div className={`${styles.flex2}`}>
                                
                            </div>
                        </div>
                        {loadingMachine ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            data?.getMachines?.machines?.map(machine => {
                                return(
                                    <MembershipMachine key={machine.id} machine={machine} />
                                )
                            })
                        )}
                        {(data?.getMachines?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(data?.getMachines?.total) ? parseInt((data?.getMachines?.total - 1)/5) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingMachine ? null : handlePage}
                                initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingMachine && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.justifyContentBetween} ${styles.mt20}`}>
                        <MembershipSaleGraph />
                        <MembershipSaleStatics />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MembershipHome;