import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { billSm } from "../../../common/modal";
import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import CheckIn from "../../../components/CheckIn/PC/CheckIn";
import Calendar from "react-calendar";
import { bill } from "../../../common/modal";
import { numberWithCommas } from "../../../common/utility";

import xlsx from "xlsx";
import Modal from "react-modal";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

var progressCount = 0;
const CHECKIN = gql`
  query getCheckIns($machine: String!, $order: String!, $skip: Int!, $q: String, $start: String, $end: String) {
    getCheckIns(machine: $machine, order: $order, skip: $skip, q: $q, start: $start, end: $end) {
      checkIns {
        id
        name
        contact
        temperature
        date
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const CheckInStatus = () => {
  const history = useHistory();
  const location = useLocation();
  const [showExcel, setShowExcel] = useState(false);

  const codeToNamePaymentStatus = (code) => {
    if (code === "paid") return "결제완료";
    if (code === "cancelled") return "결제취소";
    if (code === "refund") return "환불";
    return "";
  };

  const toDateTime = (checkInDate) => {
    const date = new Date(Number(checkInDate));

    return `${date.getFullYear()}/${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}/${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}-${date.getHours() < 10 ? "0" + String(date.getHours()) : date.getHours()}:${
      date.getMinutes() < 10 ? "0" + String(date.getMinutes()) : date.getMinutes()
    }:${date.getSeconds() < 10 ? "0" + String(date.getSeconds()) : date.getSeconds()}`;
  };

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  let startDt = queryString.parse(location.search).start ? new Date(queryString.parse(location.search).start) : null;
  let endDt = queryString.parse(location.search).end ? new Date(queryString.parse(location.search).end) : null;

  const [start, setStart] = useState(startDt);
  const [showStart, setShowStart] = useState(false);
  const [end, setEnd] = useState(endDt);
  const [showEnd, setShowEnd] = useState(false);

  console.log("render::::", "start", start, "end", end);

  const {
    data,
    loading: loadingCheckIn,
    refetch,
  } = useQuery(CHECKIN, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      start: queryString.parse(location.search).start ? queryString.parse(location.search).start : "",
      end: queryString.parse(location.search).end ? queryString.parse(location.search).end : "",
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/checkin/status/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}&start=${
        queryString.parse(location.search).start ? queryString.parse(location.search).start : ""
      }&end=${queryString.parse(location.search).end ? queryString.parse(location.search).end : ""}`,
    });
  };

  const handleOrder = (order) => {
    if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_dsc");
      }
    } else if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "contact") {
      if (queryString.parse(location.search).order === "contact_asc") {
        applyOrder("contact_dsc");
      } else if (queryString.parse(location.search).order === "contact_dsc") {
        applyOrder("contact_asc");
      } else {
        applyOrder("contact_asc");
      }
    } else if (order === "temp") {
      if (queryString.parse(location.search).order === "temp_asc") {
        applyOrder("temp_dsc");
      } else if (queryString.parse(location.search).order === "temp_dsc") {
        applyOrder("temp_asc");
      } else {
        applyOrder("temp_asc");
      }
    } else {
      applyOrder("date_dsc");
    }
  };

  const handleStart = (start) => {
    setStart(new Date(start.getTime() + 1000 * 60 * 60 * 9));
    setShowStart(false);
  };

  const handleEnd = (end) => {
    setEnd(new Date(end.getTime() + 1000 * 60 * 60 * 9));
    setShowEnd(false);
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/checkin/status/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}&start=${
        queryString.parse(location.search).start ? queryString.parse(location.search).start : ""
      }&end=${queryString.parse(location.search).end ? queryString.parse(location.search).end : ""}`,
    });
  };

  const submit = (e) => {
    e.preventDefault();

    searchCheckIn();
  };

  const searchCheckIn = async () => {
    let startStr = "";
    if (start) {
      startStr = start.toISOString().split("T")[0];
    }
    let endStr = "";
    if (end) {
      endStr = end.toISOString().split("T")[0];
    }

    history.push({
      pathname: `/checkin/status/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${q.value}&start=${startStr}&end=${endStr}`,
    });
  };

  const exportExcelFull = async (type) => {
    let startStr = "";
    if (queryString.parse(location.search).start && queryString.parse(location.search).start !== "") {
      let startDt = new Date(queryString.parse(location.search).start);
      startDt = new Date(startDt.getTime() + 1000 * 60 * 60 * 9);
      startStr = startDt.toISOString().split("T")[0];
    }
    let endStr = "";
    if (queryString.parse(location.search).end && queryString.parse(location.search).end !== "") {
      let endDt = new Date(queryString.parse(location.search).end);
      endDt = new Date(endDt.getTime() + 1000 * 60 * 60 * 9);
      endStr = endDt.toISOString().split("T")[0];
    }

    console.log("start, end", startStr, endStr);

    //if (!loading && machine && machine.getMachine.name) {
    if (true) {
      //setLoading(true);
      try {
        const { data: result } = await refetch({
          skip: 0,
          machine: location.state.machine,
          start: startStr,
          end: endStr,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
        console.log("result", result);

        if (result && result.getCheckIns.checkIns.length > 0) {
          console.log("result.total", result.getCheckIns.total);
          var total = result.getCheckIns.total;
          let data = [];
          for (var i = 0; i < total; ) {
            const { data: result } = await refetch({
              skip: i,
              machine: location.state.machine,
              start: startStr,
              end: endStr,
              order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
              q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
            });

            if (result && result.getCheckIns.checkIns.length > 0) {
              for (const checkin of result.getCheckIns.checkIns) {
                data.push({
                  "출석 일시": toDateTime(checkin.date),
                  사용자명: checkin.name,
                  "사용자 연락처": checkin.contact,
                  발열체크: checkin.temperature,
                });
              }
            }
            i += 10;
            progressCount = (i * 100) / total;
          }

          const ws = xlsx.utils.json_to_sheet(data);
          const wb = xlsx.utils.book_new();
          var wscols = [{ wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
          ws["!cols"] = wscols;
          xlsx.utils.book_append_sheet(wb, ws, "출석현황");
          xlsx.writeFile(wb, `Ven-Brothers_출석현황_${machine.getMachine.name}.xlsx`);
        }
      } catch (e) {
        console.log("eeee", e);
        alert("오류가 발생하였습니다.");
      } finally {
        //setLoading(false);
      }

      const { data: result } = await refetch({
        skip: 0,
        machine: location.state.machine,
        start: startStr,
        end: endStr,
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      });

      setShowExcel(false);
    }
  };

  useEffect(() => {
    if (location.state.machine) {
      let startStr = "";
      if (queryString.parse(location.search).start && queryString.parse(location.search).start !== "") {
        let startDt = new Date(queryString.parse(location.search).start);
        startDt = new Date(startDt.getTime() + 1000 * 60 * 60 * 9);
        startStr = startDt.toISOString().split("T")[0];
      }
      let endStr = "";
      if (queryString.parse(location.search).end && queryString.parse(location.search).end !== "") {
        let endDt = new Date(queryString.parse(location.search).end);
        endDt = new Date(endDt.getTime() + 1000 * 60 * 60 * 9);
        endStr = endDt.toISOString().split("T")[0];
      }

      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
          start: startStr,
          end: endStr,
        });
      } else {
        history.replace({
          pathname: `/checkin/status/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}&start=${startStr}&end=${endStr}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q, queryString.parse(location.search).start, queryString.parse(location.search).end]);

  useEffect(() => {
    searchCheckIn();
  }, [start, end]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`출석 현황${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <form action={""} onSubmit={submit} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                  <div className={`${styles.flex1}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowStart(!showStart)} style={{ width: 150, height: 47 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                        <p className={`${styles.inputWhite} ${styles.flex1} ${start ? styles.black : styles.grayA2}`}>
                          {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : "2022-01-01"}
                        </p>
                        <div
                          className={`${styles.py10}`}
                          style={{ width: 35, height: 47, textAlign: "center" }}
                          onClick={(e) => {
                            e.stopPropagation();

                            setStart(null);
                          }}
                        >
                          <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className={`${styles.px10}`}>~</p>
                  <div className={`${styles.flex1} ${styles.mr20}`}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowEnd(!showEnd)} style={{ width: 150, height: 47 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                        <p className={`${styles.inputWhite} ${styles.flex1} ${end ? styles.black : styles.grayA2}`}>
                          {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : "2022-01-01"}
                        </p>
                        <div
                          className={`${styles.py10}`}
                          style={{ width: 35, height: 47, textAlign: "center" }}
                          onClick={(e) => {
                            e.stopPropagation();

                            setEnd(null);
                          }}
                        >
                          <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`} style={{ width: 400 }}>
                    <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon25} ${styles.cursorPointer}`} onClick={submit} />
                    <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"사용자명 및 연락처 검색"} />
                  </div>
                </form>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml40}`}
                  onClick={() => {
                    progressCount = 0;
                    setShowExcel(true);
                  }}
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>출석 현황 내보내기</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.mt45} ${styles.px10}`}>
              <p className={`${styles.black}`}>검색 결과: {data?.getCheckIns?.total}개</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt10}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>출석일시</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>사용자명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("contact")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>사용자 연락처</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "contact_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "contact_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("temp")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>발열체크</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "temp_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "temp_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
            </div>
            <div style={{ minHeight: 600 }}>
              {loadingCheckIn ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getCheckIns && data.getCheckIns.checkIns.length > 0 ? (
                data.getCheckIns.checkIns.map((checkIn) => {
                  return <CheckIn key={checkIn.id} checkIn={checkIn} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>출석 내역이 없습니다.</p>
                </div>
              )}
              {data?.getCheckIns?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={data?.getCheckIns?.total ? parseInt((data?.getCheckIns?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingCheckIn ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {loadingCheckIn && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <Modal isOpen={showStart} onRequestClose={() => setShowStart(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={start}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleStart}
            />
          </Modal>

          <Modal isOpen={showEnd} onRequestClose={() => setShowEnd(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={end}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleEnd}
            />
          </Modal>

          <Modal isOpen={showExcel} onRequestClose={() => setShowExcel(false)} onAfterOpen={() => exportExcelFull()} style={billSm}>
            <div className={`${styles.center} ${styles.py20} ${styles.px20} ${styles.widthFull}`}>
              <p>
                <CircularProgressbar value={progressCount} text={`${Math.min(100, parseInt(progressCount))}%`} />{" "}
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CheckInStatus;
