import React, { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import styles from "../../../styles/styles.module.scss";
import LockerMachine from "../../../components/Locker/PC/LockerMachine";
import Locker from "../../../components/Locker/PC/Locker";

const MACHINE = gql`
  query getMachines($skip: Int!) {
    getMachines(skip: $skip) {
      machines {
        id
        username
        name
        status
        createdAt
        lockerTotal
        lockerAvailableTotal
        lockerProductTotal
      }
      total
    }
  }
`;

const LOCKER = gql`
  query getExpiredLockers($skip: Int!, $order: String!) {
    getExpiredLockers(skip: $skip, order: $order) {
      lockers {
        id
        payment {
          id
          installment
          approvalDate
          approvalTime
          productName
          totalAmount
          issuerInfo
          status
          lockerNumber
        }
        lockerArea {
          id
          machine {
            id
            username
            name
          }
        }
        member {
          id
          name
          mobile
        }
        product {
          name
        }
        number
        status
        start
        end
      }
      total
    }
  }
`;

const LockerHome = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    loading: loadingMachine,
    refetch,
  } = useQuery(MACHINE, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
    },
  });

  const {
    data: locker,
    loading: loadingLocker,
    refetch: refetchLocker,
  } = useQuery(LOCKER, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
    },
  });

  const handlePage = (page) => {
    history.push(`/locker/?page=${page.selected + 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`);
  };

  const handleTPage = (page) => {
    history.push(`/locker/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`);
  };

  const handleOrder = (order) => {
    if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_dsc");
      }
    } else if (order === "machine") {
      if (queryString.parse(location.search).order === "machine_asc") {
        applyOrder("machine_dsc");
      } else if (queryString.parse(location.search).order === "machine_dsc") {
        applyOrder("machine_asc");
      } else {
        applyOrder("machine_asc");
      }
    } else if (order === "member") {
      if (queryString.parse(location.search).order === "member_asc") {
        applyOrder("member_dsc");
      } else if (queryString.parse(location.search).order === "member_dsc") {
        applyOrder("member_asc");
      } else {
        applyOrder("member_asc");
      }
    } else if (order === "product") {
      if (queryString.parse(location.search).order === "product_asc") {
        applyOrder("product_dsc");
      } else if (queryString.parse(location.search).order === "product_dsc") {
        applyOrder("product_asc");
      } else {
        applyOrder("product_asc");
      }
    } else if (order === "amount") {
      if (queryString.parse(location.search).order === "amount_asc") {
        applyOrder("amount_dsc");
      } else if (queryString.parse(location.search).order === "amount_dsc") {
        applyOrder("amount_asc");
      } else {
        applyOrder("amount_asc");
      }
    } else if (order === "payment") {
      if (queryString.parse(location.search).order === "payment_asc") {
        applyOrder("payment_dsc");
      } else if (queryString.parse(location.search).order === "payment_dsc") {
        applyOrder("payment_asc");
      } else {
        applyOrder("payment_asc");
      }
    } else if (order === "number") {
      if (queryString.parse(location.search).order === "number_asc") {
        applyOrder("number_dsc");
      } else {
        applyOrder("number_asc");
      }
    } else if (order === "start") {
      if (queryString.parse(location.search).order === "start_asc") {
        applyOrder("start_dsc");
      } else {
        applyOrder("start_asc");
      }
    } else if (order === "end") {
      if (queryString.parse(location.search).order === "end_asc") {
        applyOrder("end_dsc");
      } else {
        applyOrder("end_asc");
      }
    } else {
      applyOrder("date_dsc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/locker/",
      state: location.state,
      search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=1&order=${order}`,
    });
  };

  useEffect(() => {
    if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
      window.scrollTo(0, 0);
      refetch({
        skip: 5 * (Number(queryString.parse(location.search).page) - 1),
      });
    }
  }, [queryString.parse(location.search).page]);

  useEffect(() => {
    if (queryString.parse(location.search).tpage && !isNaN(Number(queryString.parse(location.search).tpage))) {
      window.scrollTo(0, 0);
      refetchLocker({
        skip: 5 * (Number(queryString.parse(location.search).tpage) - 1),
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
      });
    }
  }, [queryString.parse(location.search).tpage, queryString.parse(location.search).order]);

  useEffect(() => {
    if (queryString.parse(location.search).page && queryString.parse(location.search).tpage && queryString.parse(location.search).order) {
    } else {
      history.replace(
        `/locker/?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&tpage=${queryString.parse(location.search).tpage ? queryString.parse(location.search).tpage : 1}&order=${
          queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"
        }`
      );
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>락커 관리</p>
              <p className={`${styles.fontR} ${styles.font13} ${styles.black}`}>*락커 이용권은 키오스크 기기의 회원권 구매에서 별도의 카테고리로 표시되고, 회원권 구매 창에서 구매가 가능합니다.</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기계정</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용 가능한 락커수</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>현재 전체 락커수</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>현재 등록된 락커 이용권수</p>
              </div>
              <div className={`${styles.flex3}`}></div>
            </div>
            {loadingMachine ? (
              <Skeleton height={50} count={3} />
            ) : (
              data?.getMachines?.machines?.map((machine) => {
                return <LockerMachine key={machine.id} machine={machine} />;
              })
            )}
            {data?.getMachines?.total > 5 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getMachines?.total ? parseInt((data?.getMachines?.total - 1) / 5) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingMachine ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingMachine && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mt20}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>만료 및 만료 예정 락커</p>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("machine")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "machine_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "machine_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("member")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>구매자명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "member_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "member_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>구매자 연락처</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("product")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용권명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "product_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "product_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("number")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>락커번호</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "number_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "number_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("start")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용권 시작일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "start_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "start_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("end")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용권 종료일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "end_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "end_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>이용권 상태</p>
              </div>
              <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>판매일시</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
            </div>
            {loadingLocker ? (
              <Skeleton height={50} count={3} />
            ) : (
              locker?.getExpiredLockers?.lockers?.map((locker) => {
                return <Locker key={locker.id} locker={locker} />;
              })
            )}
            {locker?.getExpiredLockers?.total > 5 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={locker?.getExpiredLockers?.total ? parseInt((locker?.getExpiredLockers?.total - 1) / 5) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingLocker ? null : handleTPage}
                  initialPage={queryString.parse(location.search).tpage ? Number(queryString.parse(location.search).tpage) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingLocker && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerHome;
