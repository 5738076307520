import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';
import ReactPaginate from "react-paginate";
import PointList from "../../../components/Point/Mobile/PointList";

const PROFILE = gql`
    {
        getProfileAdmin {
            id
            username
            name
            superuser
        }
    }
`;

const GET_POINTS = gql`
    query getUserPoints($order: String!, $skip: Int!) {
        getUserPoints(order: $order, skip: $skip) {
            points {
                id
                type
                division
                point
                balance
                createdAt
                userPointPayment {
                    id
                    cardName
                    installment
                }
            }
            total
        }
    }
`;

const PointUserMain = () => {
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const { data, loading:loadingProfile } = useQuery(PROFILE, {
        fetchPolicy: 'cache-and-network',
    });

    console.log("data:::", data);

    const {data: getPointList, loading:loadingPoint, refetch: pointRefetch} = useQuery(GET_POINTS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            skip: 0
        }
    });

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            pointRefetch({
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                skip: 10*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order]);

    const handlePage = (page) => {
        history.replace({
            pathname: `/point/user/main`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`
        })
    }

    useEffect(() => {
        if (!data) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        알림톡 포인트 충전 및 사용 내역
                    </p>
                    <div
                        className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div
                            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`}
                            onClick={() =>
                                history.push({
                                    pathname: `/point/user/add/`,
                                })
                            }>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                포인트 충전하기
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px30} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex2}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                충전 및 사용일시
                            </p>
                        </div>
                        <div className={`${styles.flex1} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                구분
                            </p>
                        </div>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                포인트
                            </p>
                        </div>
                        <div className={`${styles.flex1}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                결제수단
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingPoint ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            (getPointList && getPointList.getUserPoints && (getPointList.getUserPoints.points.length > 0)) ? (
                                getPointList.getUserPoints.points.map(point => {
                                    return(
                                        <PointList key={point.id} point={point} />
                                    )
                                })
                            ) : (
                                <div className={`${styles.center}`} style={{ height: 600 }}>
                                    <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        사용 내역이 없습니다.
                                    </p>
                                </div>
                            )
                        )}
                        {(getPointList?.getUserPoints?.total > 10) ? (
                            <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(getPointList?.getUserPoints?.total) ? parseInt((getPointList?.getUserPoints?.total - 1)/10) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingPoint ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingPoint && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointUserMain;
