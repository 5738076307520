import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";

const LOCKER = gql`
  query getLockerArea($machine: String!, $lockerId: Int!) {
    getLockerArea(machine: $machine, lockerId: $lockerId) {
      id
      name
      sizeX
      sizeY
      isDisplay
      lockers {
        id
        positionX
        positionY
        status
        number
        member {
          id
          name
        }
        start
        end
      }
    }
  }
`;

const EDIT = gql`
  mutation editLockerArea($machine: String!, $lockerId: Int!, $name: String!, $isDisplay: Boolean) {
    editLockerArea(machine: $machine, lockerId: $lockerId, editArray: false, name: $name, isDisplay: $isDisplay) {
      lockerArea {
        id
        name
        sizeX
        sizeY
        isDisplay
        lockers {
          id
          positionX
          positionY
          status
          number
        }
      }
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
    }
  }
`;

const LockerEdit = () => {
  const EMPTY_LOCKER = [
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
  ];

  const history = useHistory();
  const location = useLocation();

  const name = useInput("");

  const [locker, setLocker] = useState([...EMPTY_LOCKER]);
  const [loading, setLoading] = useState(false);
  const [showIsDisplay, setShowIsDisplay] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);

  const { data } = useQuery(LOCKER, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      lockerId: location.state.lockerId ? location.state.lockerId : -1,
    },
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [editLockerAreaM] = useMutation(EDIT);

  const handleIsDisplay = (isDisplay) => {
    setIsDisplay(isDisplay);
    setShowIsDisplay(false);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      try {
        if (!name.value) {
          alert("락커 구역을 입력해주세요.");
          return;
        }
        setLoading(true);
        const { data } = await editLockerAreaM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            lockerId: location.state.lockerId ? location.state.lockerId : -1,
            name: name.value,
            isDisplay,
          },
        });
        setLoading(false);
        if (data && data.editLockerArea.lockerArea) {
          alert("수정하였습니다.");
          initialize(data.editLockerArea.lockerArea);
        } else if (data && data.editLockerArea.error) {
          alert(data.editLockerArea.error);
        } else {
          alert("오류가 발생하였습니다.");
        }
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    }
  };

  const initialize = (lockerArea) => {
    if (lockerArea) {
      name.setValue(lockerArea.name);
      let newLocker = [...EMPTY_LOCKER];
      for (const item of lockerArea.lockers) {
        newLocker[item.positionY][item.positionX] = {
          ...item,
          type: item.status,
        };
      }
      setIsDisplay(lockerArea.isDisplay);
      setLocker(newLocker);
    } else {
      if (data && data.getLockerArea) {
        name.setValue(data.getLockerArea.name);
        let newLocker = [...EMPTY_LOCKER];
        for (const item of data.getLockerArea.lockers) {
          newLocker[item.positionY][item.positionX] = {
            ...item,
            type: item.status,
          };
        }
        setLocker(newLocker);
        setIsDisplay(data.getLockerArea.isDisplay);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data && data.getLockerArea) {
      initialize();
    }
  }, [data]);

  useEffect(() => {
    if (!location.state.machine || !location.state.lockerId) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
      <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
        <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>{`락커 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
          <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.px20}`}>
            <div className={`${styles.mt0}`} style={{ position: "relative" }}>
              <div
                className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                style={{
                  borderBottomLeftRadius: showIsDisplay ? 0 : 10,
                  borderBottomRightRadius: showIsDisplay ? 0 : 10,
                }}
              >
                <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                  <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>전시 상태*</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.cursorPointer}`} onClick={() => setShowIsDisplay(!showIsDisplay)}>
                  <input type={"text"} value={isDisplay ? "전시 중" : "전시 중단"} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `} style={{ minWidth: 20 }} readOnly={true} />
                  <img src={showIsDisplay ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                </div>
              </div>
              {showIsDisplay && (
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ left: 0, right: 0 }}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`}>
                    <div className={`${styles.borderRightGrayA2} ${styles.pl15}`} style={{ width: 110 }}></div>
                    <div className={`${styles.py10} ${styles.flex1}`}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`}>
                        <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleIsDisplay(true)}>
                          전시 중
                        </p>
                      </div>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.flex1} ${styles.px15} ${styles.py10}`} style={{ display: "flex" }}>
                        <p className={`${styles.font14} ${styles.black} ${styles.cursorPointer} ${styles.flex1}`} onClick={() => handleIsDisplay(false)}>
                          전시 중단
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.mt10} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}>
              <div className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`} style={{ width: 110 }}>
                <p className={`${styles.fontM} ${styles.font14} ${styles.black}`}>상품명*</p>
              </div>
              <input type={"text"} name={"name"} value={name.value || ""} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`} placeholder={"락커 구역을 입력해주세요."} />
            </div>
            <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px15} ${styles.pt10} ${styles.pb25} ${styles.mt10}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
                <div className={`${styles.borderRadius5} ${styles.icon15} ${styles.bgGrayD8}`} />
                <p className={`${styles.fontM} ${styles.font13} ${styles.black} ${styles.ml5}`}>사용중</p>
                <div className={`${styles.borderRadius5} ${styles.icon15} ${styles.bgPink} ${styles.ml15}`} />
                <p className={`${styles.fontM} ${styles.font13} ${styles.black} ${styles.ml5}`}>만료</p>
                <div className={`${styles.borderRadius5} ${styles.icon15} ${styles.bgSky} ${styles.ml15}`} />
                <p className={`${styles.fontM} ${styles.font13} ${styles.black} ${styles.ml5}`}>사용가능</p>
              </div>
              <table className={`${styles.py30} ${styles.center}`} style={{ overflow: "scroll" }}>
                <tbody className={`${styles.widthFull}`}>
                  {locker.map((row, y) => {
                    return (
                      <tr key={y}>
                        {row.map((col, x) => {
                          return (
                            <td
                              key={`${y}-${x}`}
                              className={`${col.type === "none" ? "" : styles.cursorPointer}`}
                              style={{
                                width: "calc((100vw - 104px)/15 - 2px)",
                                height: "calc((100vw - 104px)/15 - 2px)",
                                borderRadius: 5,
                                backgroundColor: col.type === "available" ? "#7b99ff" : col.type === "expired" ? "#edb9b9" : col.type === "inuse" ? "#d8d8d8" : "#fff",
                                opacity: col.type === "none" ? 0 : 1,
                              }}
                              onClick={
                                col.id
                                  ? () =>
                                      history.push({
                                        pathname: `/locker/detail/`,
                                        state: {
                                          machine: location.state.machine ? location.state.machine : "",
                                          lockerId: col.id,
                                        },
                                      })
                                  : null
                              }
                            >
                              <p className={`${styles.fontR} ${styles.font13} ${styles.white} ${styles.textCenter}`}>{col.number}</p>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className={`${styles.mt45}`}>
              <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>저장</p>
              </button>
              <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>취소</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LockerEdit;
