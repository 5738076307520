import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string"; // queryString 모듈 가져오기
import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";
import { useState } from "react";
import Modal from "react-modal";
import { bill } from "../../../common/modal";

const EntranceMachine = ({ machine }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const countDeviceIds = (deviceIdString) => {
    if (deviceIdString === "") {
      return 0;
    }
    return deviceIdString.split(",").length;
  };

  const formatDate = (date) => (date ? date.toISOString().split("T")[0] : "");

  const getTodayStartEnd = () => {
    const now = new Date();
    const start = new Date(now.setHours(0, 0, 0, 0));
    const end = new Date(now.setHours(23, 59, 59, 999));
    return { start, end };
  };

  const { start: defaultStart, end: defaultEnd } = getTodayStartEnd();

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{machine.username}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{machine.name}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {machine.status === "inuse" && "사용중"}
          {machine.status === "notuse" && "미사용"}
          {machine.status === "check" && "점검중"}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{countDeviceIds(machine.deviceId)}</p>
      </div>
      <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.mx10}`}
          onClick={() => {
            if (machine.deviceId === "") {
              setShowModal(true);
              return false;
            }

            history.push({
              pathname: `/entrance/door/list/`,
              state: {
                machine: machine.username,
                deviceId: machine.deviceId,
                ip: machine.entranceIp,
              },
              search: "?order=date_dsc&page=1&q=",
            });
          }}
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>출입문 관리</p>
        </div>
        <div
          className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`}
          onClick={() => {
            if (machine.deviceId === "") {
              setShowModal(true);
              return false;
            }
            const startStr = formatDate(defaultStart); // 현재 날짜를 시작 날짜로 설정
            const endStr = formatDate(defaultEnd); // 현재 날짜를 종료 날짜로 설정
            history.push({
              pathname: `/entrance/door/log/`,
              state: {
                deviceId: machine.deviceId,
                name: machine.name,
                ip: machine.entranceIp,
              },
              search: queryString.stringify({
                q: "",
                start: startStr,
                end: endStr,
                column: "",
              }),
            });
          }}
        >
          <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>출입 기록 관리</p>
        </div>
      </div>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={bill}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>출입관리</p>
          <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>출입관리 기능은 추가 가입이 필요합니다.</div>
          <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
            <div
              className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center}
                            ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`}
              onClick={() => {
                window.open("https://pf.kakao.com/_jWexgK");
                setShowModal(false);
              }}
            >
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>가입 문의 하기</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EntranceMachine;
