import React, {useEffect, useState} from 'react';
import { gql, useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import ScheduleMachine from "../../../components/Schedule/PC/ScheduleMachine";
import Modal from "react-modal";
import {bill} from "../../../common/modal";

const MACHINE = gql`
    query getMachines($skip: Int!){
        getMachines(skip: $skip){
            machines{
                id
                username
                name
                status
                createdAt
                scheduleTotal
                teacherTotal
                memberReservationTotal
                useSchedule
            }
            total
        }
    }
`

const ScheduleHome = () => {
    const history = useHistory()
    const location = useLocation()

    const { data, loading:loadingMachine, refetch } = useQuery(MACHINE, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0
        }
    })

    const [showModal, setShowModal] = useState(false);

    const handlePage = (page) => {
        history.push(`/schedule/?page=${page.selected + 1}`)
    }

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            refetch({
                skip: 5*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
            <div className={`${styles.safearea}`}>
                <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30} ${styles.heightFull}`}>
                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.heightFull}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                                스케쥴 관리
                            </p>
                        </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기계정
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    기기명
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    상태
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 스케쥴수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 강사수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    등록된 회원수
                                </p>
                            </div>
                            <div className={`${styles.flex1}`}>

                            </div>
                        </div>
                        {loadingMachine ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            data?.getMachines?.machines?.map(machine => {
                                return(
                                    <ScheduleMachine key={machine.id} machine={machine} setShowModal={setShowModal} />
                                )
                            })
                        )}
                        {(data?.getMachines?.total > 5) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(data?.getMachines?.total) ? parseInt((data?.getMachines?.total - 1)/5) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingMachine ? null : handlePage}
                                initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingMachine && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                style={bill}>
                <div>
                    <p
                        className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        예약
                    </p>
                    <div className={`${styles.widthFull} ${styles.mt30} ${styles.textCenter}`}>
                        예약과 공지 기능은 추가 가입이 필요합니다.
                    </div>
                    <div className={`${styles.flex} ${styles.widthFull} ${styles.mt30}`} style={{ display: "flex" }}>
                        <div
                            className={`${styles.flex1} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center}
                            ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.cursorPointer}`}
                            onClick={() => {
                                window.open('https://pf.kakao.com/_jWexgK');
                                setShowModal(false);
                            }}>
                            <p
                                className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>
                                가입 문의 하기
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ScheduleHome;
