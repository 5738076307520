import React, {useState, useEffect} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import {useHistory, useLocation} from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

import '../../../styles/scheduler_css.css';
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import TeacherList from "../../../components/Teacher/Mobile/TeacherList";
import cache from "../../../apollo/cache";

const DETAIL = gql`
    query getMachine($machine: String!) {
        getMachine(machine: $machine) {
            id
            name
        }
    }
`;

const GET_TEACHER = gql`
    query getTeacher($machine: String!, $order: String!, $skip: Int!) {
        getTeacher(machine: $machine, order: $order, skip: $skip) {
            teacher{
                id
                name
                createdAt
                color
                scheduleCnt
                scheduleReservationCnt
                scheduleWaitingCnt
                machine{
                    id
                    name
                    username
                }
            }
            total
        }
    }
`;
const DELETE_TEACHER = gql`
    mutation deleteTeacher($id: Int!) {
        deleteTeacher(id: $id) {
            success
            error
        }
    }
`;
const TeacherMain = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [deleteTeacherM] = useMutation(DELETE_TEACHER);

    const { data, loading:loadingMachine, refetch:machineRefetch } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ''
        }
    });

    const {data: getTeacherList, loading:loadingTeacher, refetch: teacherRefetch} = useQuery(GET_TEACHER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            machine: location.state.machine ? location.state.machine : '',
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            skip: 0
        },
        onCompleted: t => {
            console.log(t);
        }
    });

    const handleOrder = (order) => {
        if(order === "date"){
            if(queryString.parse(location.search).order === "date_asc"){
                applyOrder("date_dsc")
            }
            else if(queryString.parse(location.search).order === "date_dsc"){
                applyOrder("date_asc")
            }
            else{
                applyOrder("date_dsc")
            }
        } else if(order === "name"){
            if(queryString.parse(location.search).order === "name_asc"){
                applyOrder("name_dsc")
            }
            else if(queryString.parse(location.search).order === "name_dsc"){
                applyOrder("name_asc")
            }
            else{
                applyOrder("name_asc")
            }
        }
    };

    const applyOrder = (order) => {
        history.replace({
            pathname: `/teacher/main/`,
            state: location.state,
            search: `?page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&order=${order}`
        });
    };

    useEffect(() => {
        if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
            window.scrollTo(0,0)
            teacherRefetch({
                machine: location.state.machine ? location.state.machine : '',
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                skip: 10*(Number(queryString.parse(location.search).page) - 1)
            })
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order]);

    const handlePage = (page) => {
        history.replace({
            pathname: `/teacher/main/`,
            state: location.state,
            search: `?page=${page.selected + 1}&order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}`
        })
    }

    const remove = async t => {
        console.log('delete teacher id : ', t.id);
        if (!loading) {
            const confirm = window.confirm('정말 삭제하시겠습니까?');
            if (confirm) {
                setLoading(true);
                const { data } = await deleteTeacherM({
                    variables: {
                        id: t.id
                    }
                });
                setLoading(false);
                if (data && data.deleteTeacher.success) {
                    cache.evict({
                        id: `Teacher:${t.id}`,
                    });
                    teacherRefetch();
                    alert('강사를 삭제하였습니다.');
                } else {
                    alert(data.deleteTeacher.error);
                }
            }
        }
    };

    useEffect(() => {
        if (!location.state.machine) {
            alert('잘못된 접근입니다');
            history.goBack();
        }
    }, []);

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>

                    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.py50} ${styles.btnShadow}`}>
                            <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                                {`담당 강사 관리${data && data.getMachine ? ` (${data.getMachine.name})` : ''}`}
                            </p>
                            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                                    style={{minWidth: 150, marginTop: 43}}
                                    onClick={() => history.push({
                                        pathname: `/teacher/add/`,
                                        state: {
                                            machine: location.state.machine
                                        },
                                    })}>
                                    <p className={`${styles.fontB} ${styles.font14} ${styles.white} ${styles.textCenter}`}>강사 추가하기</p>
                                </div>
                            </div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt28}`}>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                    강사명
                                </p>
                            </div>
                            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>

                            </div>
                        </div>
                        {loadingTeacher ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            getTeacherList?.getTeacher?.teacher?.map(teacher => {
                                return (
                                    <TeacherList key={teacher.id} teacher={teacher} remove={() => remove(teacher)}/>
                                )
                            })
                        )}
                        {(getTeacherList?.getTeacher?.total < 1) ? (
                            <div className={`${styles.row} ${styles.justifyContentCenter}`} style={{marginTop:30}}>등록된 강사가 없습니다.</div>
                        ):null}
                        {(getTeacherList?.getTeacher?.total > 10) ? (
                            <div className={`${styles.mt60} ${styles.mb10}`} style={{position: "relative"}}>
                                <ReactPaginate
                                    previousLabel={(
                                        <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    nextLabel={(
                                        <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'}
                                             className={`${styles.cursorPointer}`}
                                             style={{width: 45 * 0.2, height: 79 * 0.2}}/>
                                    )}
                                    breakLabel={(
                                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                            &#183;&#183;&#183;
                                        </p>
                                    )}
                                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                    disabledClassName={`${styles.icon30} ${styles.center}`}
                                    pageCount={(getTeacherList?.getTeacher?.total) ? parseInt((getTeacherList?.getTeacher?.total - 1) / 10) + 1 : 1}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={4}
                                    onPageChange={loadingTeacher ? null : handlePage}
                                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {(loadingMachine || loadingTeacher) && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "rgba(255, 255, 255, 0.4)"
                                    }}/>
                                )}
                            </div>
                        ) : null}
                    </div>


            </div>
        </div>
    );
}

export default TeacherMain;
