import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const LockerMachine = ({
    machine
}) => {
    const history = useHistory()

    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px15} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.pr10}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {machine.name}
                </p>
            </div>
            <div className={`${styles.flex3} ${styles.alignItemsEnd}`} style={{ display: "flex", flexDirection: "column" }}>
                <div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd} ${styles.flex2}`}>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`} onClick={() => history.push({
                        pathname: `/locker/list/`,
                        state: {
                            machine: machine.username
                        }
                    })}>
                        <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            락커 관리
                        </p>
                    </div>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => history.push({
                        pathname: `/locker/product/list/`,
                        state: {
                            machine: machine.username
                        },
                        search: '?order=name_asc'
                    })}>
                        <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            이용권 관리
                        </p>
                    </div>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.mt10}`}>
                    <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`} onClick={() => history.push({
                        pathname: `/locker/product/payment/status/`,
                        state: {
                            machine: machine.username
                        },
                        search: '?order=date_dsc&page=1&q='
                    })}>
                        <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            판매 현황
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default LockerMachine;