import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import xlsx from "xlsx";
import axios from "axios";
import { billSm } from "../../../common/modal";
import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { bill } from "../../../common/modal";
import Member from "../../../components/Member/PC/Member";
import { numberReg } from "../../../common/regex";
import { FETCH_URL } from "../../../config";
import cache from "../../../apollo/cache";
import ScheduleMember from "../../../components/Schedule/PC/ScheduleMember";
import TodayReservationMember from "../../../components/Schedule/PC/TodayReservationMember";
import Calendar from "react-calendar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

var progressCount = 0;
const RESERVATIONS = gql`
  query getSearchListScheduleReservation($machine: String!, $order: String!, $skip: Int!, $q: String, $start: String, $end: String) {
    getSearchListScheduleReservation(machine: $machine, order: $order, skip: $skip, q: $q, start: $start, end: $end) {
      scheduleReservations {
        id
        status
        scheduleid
        schedulename
        scheduledate
        schedulestart
        scheduleendtime
        membername
        mobile
        gender
      }
      total
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      username
    }
  }
`;

const RESERVATION = gql`
  mutation addScheduleReservation($machine: String!, $memberId: Int!, $scheduleId: Int!, $status: Int!, $over: Boolean!) {
    addScheduleReservation(machine: $machine, memberId: $memberId, scheduleId: $scheduleId, status: $status, over: $over) {
      success
      error
      over
    }
  }
`;

const ScheduleSearchList = () => {
  const history = useHistory();
  const location = useLocation();
  const [showExcel, setShowExcel] = useState(false);

  const toPeriodFromTo = (date, startTime, endTime) => {
    return date + " " + startTime + " ~ " + date + " " + endTime;
  };

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  let startDt = queryString.parse(location.search).start ? new Date(queryString.parse(location.search).start) : null;
  let endDt = queryString.parse(location.search).end ? new Date(queryString.parse(location.search).end) : null;

  const [showOverModal, setShowOverModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalMember, setModalMember] = useState(null);
  const [startDate, setStartDate] = useState(startDt);
  const [endDate, setEndDate] = useState(endDt);
  const [showStartDate, setShowStartData] = useState(false);
  const [showEndDate, setShowEndData] = useState(false);

  const [addReservationM] = useMutation(RESERVATION);

  const {
    data,
    loading: loadingMember,
    refetch,
  } = useQuery(RESERVATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc",
      skip: queryString.parse(location.search).page ? parseInt((queryString.parse(location.search).page - 1) / 10) : 0,
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      start: queryString.parse(location.search).start ? queryString.parse(location.search).start : "",
      end: queryString.parse(location.search).end ? queryString.parse(location.search).end : "",
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const handlePage = (page) => {
    let startStr = "";
    if (startDate) {
      startStr = startDate.toISOString().split("T")[0];
    }
    let endStr = "";
    if (endDate) {
      endStr = endDate.toISOString().split("T")[0];
    }
    console.log("page.selected", page.selected);

    history.push({
      pathname: `/schedule/reservation/search_list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc"}&page=${page.selected + 1}&start=${startStr}&end=${endStr}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleStart = (start) => {
    if (endDate && start > endDate) {
      alert("시작일이 마지막일보다 클 수 없습니다.");
    } else {
      setStartDate(new Date(start.getTime() + 1000 * 60 * 60 * 9));
      setShowStartData(false);
    }
  };

  const handleEnd = (end) => {
    let endTime = end.getTime() + 1000 * 60 * 60 * 9;
    if (startDate && startDate > endTime) {
      alert("마지막일이 시작일보다 작을 수 없습니다.");
    } else {
      setEndDate(new Date(end.getTime() + 1000 * 60 * 60 * 9));
      setShowEndData(false);
    }
  };

  const handleOrder = (order) => {
    if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_asc");
      }
    } else if (order === "user_name") {
      if (queryString.parse(location.search).order === "user_name_asc") {
        applyOrder("user_name_dsc");
      } else if (queryString.parse(location.search).order === "user_name_dsc") {
        applyOrder("user_name_asc");
      } else {
        applyOrder("user_name_asc");
      }
    } else if (order === "gender") {
      if (queryString.parse(location.search).order === "gender_asc") {
        applyOrder("gender_dsc");
      } else if (queryString.parse(location.search).order === "gender_dsc") {
        applyOrder("gender_asc");
      } else {
        applyOrder("gender_asc");
      }
    } else {
      applyOrder("date_asc");
    }
  };

  const applyOrder = (order) => {
    history.replace({
      pathname: "/schedule/reservation/search_list/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? (queryString.parse(location.search).page - 1) / 10 + 1 : 1}&start=${queryString.parse(location.search).start}&end=${queryString.parse(location.search).end}&q=${
        queryString.parse(location.search).q ? queryString.parse(location.search).q : ""
      }`,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    searchCheckIn();
  };

  const searchCheckIn = async () => {
    let startStr = "";
    if (startDate) {
      startStr = startDate.toISOString().split("T")[0];
    }
    let endStr = "";
    if (endDate) {
      endStr = endDate.toISOString().split("T")[0];
    }

    console.log("startStr", startStr);
    console.log("endStr", endStr);
    console.log(queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc");
    history.push({
      pathname: `/schedule/reservation/search_list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc"}&page=1&start=${startStr}&end=${endStr}&q=${q.value ? q.value : ""}`,
    });
  };

  const exportExcelFull = async (type) => {
    //if (!loading && machine && machine.getMachine.name) {
    if (true) {
      //setLoading(true);
      try {
        const { data: result } = await refetch({
          skip: 0,
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        });
        console.log("result", result);

        if (result && result.getSearchListScheduleReservation.scheduleReservations.length > 0) {
          console.log("result.total", result.getSearchListScheduleReservation.total);
          var total = result.getSearchListScheduleReservation.total;
          let data = [];
          let pageIndex = 0;
          for (var i = 0; i < total; ) {
            console.log("i", i);

            const { data: result } = await refetch({
              skip: pageIndex++,
              machine: location.state.machine,
              order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
              q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
              start: queryString.parse(location.search).start ? queryString.parse(location.search).start : "",
              end: queryString.parse(location.search).end ? queryString.parse(location.search).end : "",
            });

            console.log("result", result);

            if (result && result.getSearchListScheduleReservation.scheduleReservations.length > 0) {
              for (const payment of result.getSearchListScheduleReservation.scheduleReservations) {
                console.log(payment.membername);
                data.push({
                  "예약 수업명": payment.schedulename,
                  "예약한 수업의 시간": toPeriodFromTo(payment.scheduledate, payment.schedulestart, payment.scheduleendtime),
                  회원명: payment.membername,
                  "구매자 연락처": payment.mobile,
                  성별: payment.gender === "male" ? "남성" : "여성",
                });
              }
            }
            i += 10;
            progressCount = (i * 100) / total;
          }

          //const arr = Array.from(data);
          const ws = xlsx.utils.json_to_sheet(data);
          const wb = xlsx.utils.book_new();
          var wscols = [{ wpx: 100 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 300 }];
          ws["!cols"] = wscols;
          xlsx.utils.book_append_sheet(wb, ws, "예약현황");
          xlsx.writeFile(wb, `Ven-Brothers_예약현황_${machine.getMachine.name}.xlsx`);
        }
      } catch (e) {
        console.log("eeee", e);
        alert("오류가 발생하였습니다.");
      } finally {
        //setLoading(false);
      }

      const { data: result } = await refetch({
        skip: 0,
        machine: location.state.machine,
        order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      });

      setShowExcel(false);
    }
  };

  useEffect(() => {
    let startStr = "";
    if (startDate) {
      startStr = startDate.toISOString().split("T")[0];
    }
    let endStr = "";
    if (endDate) {
      endStr = endDate.toISOString().split("T")[0];
    }
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: Number(queryString.parse(location.search).page) - 1,
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc",
          q: q.value ? q.value : "",
        });
      } else {
        history.replace({
          pathname: `/schedule/reservation/search_list/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc"}&page=1&start=${startStr}&end=${endStr}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>
                예약 검색
                {`${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}
              </p>
              <form action={""} onSubmit={submit} className={`${styles.mt10} ${styles.px40} ${styles.row} ${styles.flex1} ${styles.end}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.mb10} ${styles.mr10}`}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowStartData(!showStartDate)} style={{ width: 140, height: 42 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                      <p className={`${styles.inputWhite} ${styles.flex1} ${startDate ? styles.black : styles.grayA2}`}>
                        {startDate ? `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + String(startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 10 ? "0" + String(startDate.getDate()) : startDate.getDate()}` : "2022-01-01"}
                      </p>
                      <div
                        className={`${styles.py10}`}
                        style={{ width: 35, height: 42, textAlign: "center" }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setStartDate(null);
                        }}
                      >
                        <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                      </div>
                    </div>
                  </div>
                  <p className={`${styles.px5} ${styles.py10}`}>~</p>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowEndData(!showEndDate)} style={{ width: 140, height: 42 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                      <p className={`${styles.inputWhite} ${styles.flex1} ${endDate ? styles.black : styles.grayA2}`}>
                        {endDate ? `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + String(endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 10 ? "0" + String(endDate.getDate()) : endDate.getDate()}` : "2022-01-01"}
                      </p>
                      <div
                        className={`${styles.py10}`}
                        style={{ width: 35, height: 42, textAlign: "center" }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setEndDate(null);
                        }}
                      >
                        <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.mb12} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.width40} ${styles.px20} ${styles.py10}`}>
                  <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
                  <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} placeholder={"회원명, 연락처 및 수업명으로 검색"} />
                </div>
              </form>
              <div
                className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                onClick={() => {
                  progressCount = 0;
                  setShowExcel(true);
                }}
              >
                <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>예약 현황 내보내기</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} `}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img
                    src={require("../../../assets/images/icon_back.png").default}
                    alt={"이전"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 45 * 0.2,
                      height: 79 * 0.2,
                    }}
                  />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>예약 수업명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                onClick={() => {
                  handleOrder("date");
                }}
              >
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>예약한 수업의 시간</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>

              <div
                className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}
                onClick={() => {
                  handleOrder("user_name");
                }}
              >
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "user_name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "user_name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                // onClick={() => {handleOrder('phone_number')}}
              >
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>연락처</p>
              </div>
              <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`}
                onClick={() => {
                  handleOrder("gender");
                }}
              >
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>성별</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "gender_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "gender_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ minHeight: 600 }}>
              {loadingMember ? (
                <Skeleton height={50} count={3} />
              ) : data && data.getSearchListScheduleReservation && data.getSearchListScheduleReservation.scheduleReservations.length > 0 ? (
                data.getSearchListScheduleReservation.scheduleReservations.map((scheduleReservations) => {
                  return <TodayReservationMember key={scheduleReservations.id} scheduleReservations={scheduleReservations} machine={machine && machine.getMachine ? machine.getMachine : {}} />;
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>회원 목록이 없습니다.</p>
                </div>
              )}
              {data?.getSearchListScheduleReservation?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={require("../../../assets/images/icon_back.png").default}
                        alt={"이전"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    nextLabel={
                      <img
                        src={require("../../../assets/images/icon_front.png").default}
                        alt={"다음"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={data?.getSearchListScheduleReservation?.total ? parseInt((data?.getSearchListScheduleReservation?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingMember ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {loadingMember && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <Modal isOpen={showStartDate} onRequestClose={() => setShowStartData(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={startDate}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleStart}
            />
          </Modal>

          <Modal isOpen={showEndDate} onRequestClose={() => setShowEndData(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={endDate}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleEnd}
            />
          </Modal>
          <Modal isOpen={showExcel} onRequestClose={() => setShowExcel(false)} onAfterOpen={() => exportExcelFull()} style={billSm}>
            <div className={`${styles.center} ${styles.py20} ${styles.px20} ${styles.widthFull}`}>
              <p>
                <CircularProgressbar value={progressCount} text={`${Math.min(100, parseInt(progressCount))}%`} />{" "}
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSearchList;
