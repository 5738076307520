import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

import "../../../styles/scheduler_css.css";
import Calendar from "react-calendar";
import SchedulerMain from "../../../components/Schedule/PC/SchedulerMain";
import { AppointmentMeta, AppointmentModel } from "@devexpress/dx-react-scheduler";
import useInput from "../../../hooks/useInput";
import queryString from "query-string";
import Modal from "react-modal";
import { bill } from "../../../common/modal";

const DATA = gql`
  query getMainSchedule($machine: String!, $date: String, $viewDateType: String) {
    getMainSchedule(machine: $machine, date: $date, viewDateType: $viewDateType) {
      schedules {
        id
        name
        date
        startTime
        endTime
        teacherId
        categoryId
        limit
        #                reservationCnt
        reservationStatus1And3Cnt
        teacher {
          name
          color
        }
      }
      reservationCnt
      attendanceCnt
      absentCnt
      displayDate
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const CATEGORY = gql`
  query getCategoryAdmin($machine: String!) {
    getCategoryAdmin(type: "schedule", machine: $machine, orderName: true) {
      id
      name
      productCount
    }
  }
`;

const ScheduleMain = () => {
  const history = useHistory();
  const location = useLocation();

  const offset = new Date().getTimezoneOffset() * -60000;

  let startDt = queryString.parse(location.search).start ? new Date(queryString.parse(location.search).start) : null;
  let endDt = queryString.parse(location.search).end ? new Date(queryString.parse(location.search).end) : null;
  const [data, setData] = useState(null);
  const [date, setDate] = useState(new Date(Date.now() + offset).toISOString().split("T")[0]);
  const [startDate, setStartDate] = useState(startDt);
  const [endDate, setEndDate] = useState(endDt);
  const [showDate, setShowDate] = useState(false);
  const [showStartDate, setShowStartData] = useState(false);
  const [showEndDate, setShowEndData] = useState(false);
  const [displayDate, setDisplayDate] = useState("");
  const [viewDateType, setViewDateType] = useState("day");
  const [scheduleData, setScheduleData] = useState([]);
  const [resource, setResource] = useState([]);
  const [appointmentMeta, setAppointmentMeta] = useState({ target: null, data: {} });
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");

  const { refetch } = useQuery(DATA, {
    skip: true,
  });

  const { data: categories } = useQuery(CATEGORY, {
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const toggleTooltipVisibility = () => {
    setTooltipVisibility(!tooltipVisibility);
  };

  const submit = async (e) => {
    e.preventDefault();
    searchCheckIn();
  };

  const searchCheckIn = async () => {
    let startStr = "";
    if (startDate) {
      startStr = startDate.toISOString().split("T")[0];
    }
    let endStr = "";
    if (endDate) {
      endStr = endDate.toISOString().split("T")[0];
    }

    console.log(startStr, endStr);

    history.push({
      pathname: `/schedule/reservation/search_list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_asc"}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&start=${startStr}&end=${endStr}&q=${q.value ? q.value : ""}`,
    });
  };

  const handleStart = (start) => {
    console.log("start", start);
    console.log("endDate", endDate);
    if (endDate && start > endDate) {
      alert("시작일이 마지막일보다 클 수 없습니다.");
    } else {
      setStartDate(new Date(start.getTime() + 1000 * 60 * 60 * 9));
      setShowStartData(false);
    }
  };

  const handleEnd = (end) => {
    console.log("startDate", startDate);
    console.log("end", end);
    let endTime = end.getTime() + 1000 * 60 * 60 * 9;
    if (startDate && startDate > endTime) {
      alert("마지막일이 시작일보다 작을 수 없습니다.");
    } else {
      setEndDate(new Date(end.getTime() + 1000 * 60 * 60 * 9));
      setShowEndData(false);
    }
  };

  const getData = async () => {
    setData(null);

    const { data } = await refetch({
      machine: location.state.machine ? location.state.machine : "",
      date,
      viewDateType,
    });
    if (data.getMainSchedule && !data.getMainSchedule.error) {
      let dataList = [];
      for (var i = 0; i < data.getMainSchedule.schedules.length; i++) {
        let schedule = data.getMainSchedule.schedules[i];

        let _scheduleEndTime = schedule.endTime;
        if (schedule.endTime === "00:00") {
          _scheduleEndTime = "24:00";
        }

        dataList.push({
          id: schedule.id,
          // title: schedule.name + " (" + schedule.reservationCnt + "/" + schedule.limit + ")",
          title: schedule.name + " (" + schedule.reservationStatus1And3Cnt + "/" + schedule.limit + ")",
          scheduleName: schedule.name,
          startDate: new Date(schedule.date + " " + schedule.startTime),
          endDate: new Date(schedule.date + " " + _scheduleEndTime),
          limit: schedule.limit,
          // reservationCnt: schedule.reservationCnt,
          reservationCnt: schedule.reservationStatus1And3Cnt,
          teacher: schedule.teacherId,
          teacherName: schedule.teacher.name,
          category: schedule.categoryId,
          color: schedule.teacher.color,
        });
      }

      // 데이터가 아무것도 없으면 표시도 안되기 때문에 데이터 넣어주기
      if (dataList.length < 1) {
        let today = new Date();
        if (date) {
          today = new Date(date);
        }
        let day = today.getDay() + 1;

        dataList.push({
          id: -1,
          title: "",
          scheduleName: "",
          startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - day, 23, 59, 0),
          endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - day, 23, 59, 0),
          teacher: -1,
          category: -1,
          limit: 0,
          reservationCnt: 0,
          teacherName: "",
          color: "#ffffff",
        });
      }

      setScheduleData(dataList);
      setData(data.getMainSchedule);
      setDisplayDate(data.getMainSchedule.displayDate);
    } else {
      setData(null);
    }
  };

  useEffect(() => {
    getData();
  }, [viewDateType]);

  useEffect(() => {
    getData();
    setShowDate(false);
    setShowStartData(false);
  }, [date]);

  useEffect(() => {
    const categoryList = [];
    if (categories && categories.getCategoryAdmin) {
      for (var i = 0; i < categories.getCategoryAdmin.length; i++) {
        categoryList.push({
          id: categories.getCategoryAdmin[i].id,
          text: categories.getCategoryAdmin[i].name,
        });
      }
    }

    if (categoryList.length < 1) {
      categoryList.push({
        id: -1,
        text: " ",
      });
    }

    const resources = [
      {
        fieldName: "category",
        title: "Category",
        instances: categoryList,
      },
    ];
    setResource(resources);
  }, [categories]);

  useEffect(() => {
    if (!location.state.machine) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.pt50} ${styles.pb30} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`예약 제한 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer}`}
                  style={{ minWidth: 150 }}
                  onClick={() =>
                    history.push({
                      pathname: `/schedule/reservation/limit/`,
                      state: {
                        machine: location.state.machine,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>예약 제한 관리</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  style={{ minWidth: 150 }}
                  onClick={() =>
                    history.push({
                      pathname: `/teacher/main/`,
                      state: {
                        machine: location.state.machine,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>담당 강사 관리</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  style={{ minWidth: 150 }}
                  onClick={() =>
                    history.push({
                      pathname: `/category/main/`,
                      state: {
                        machine: location.state.machine,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>카테고리 관리</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  style={{ minWidth: 150 }}
                  onClick={() =>
                    history.push({
                      pathname: `/holiday/main/`,
                      state: {
                        machine: location.state.machine,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>휴일 스케쥴 관리</p>
                </div>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  style={{ minWidth: 150 }}
                  onClick={() =>
                    history.push({
                      pathname: `/schedule/add/`,
                      state: {
                        machine: location.state.machine,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white} ${styles.textCenter}`}>스케쥴 추가하기</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img
                    src={require("../../../assets/images/icon_back.png").default}
                    alt={"이전"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 45 * 0.2,
                      height: 79 * 0.2,
                    }}
                  />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div>
              <form action={""} onSubmit={submit} className={`${styles.mt45} ${styles.px40} ${styles.row} ${styles.end}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.mb10} ${styles.mr10}`}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowStartData(!showStartDate)} style={{ width: 140, height: 42 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                      <p className={`${styles.inputWhite} ${styles.flex1} ${startDate ? styles.black : styles.grayA2}`}>
                        {startDate ? `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? "0" + String(startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 10 ? "0" + String(startDate.getDate()) : startDate.getDate()}` : "2022-01-01"}
                      </p>
                      <div
                        className={`${styles.py10}`}
                        style={{ width: 35, height: 42, textAlign: "center" }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setStartDate(null);
                        }}
                      >
                        <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                      </div>
                    </div>
                  </div>
                  <p className={`${styles.px5} ${styles.py10}`}>~</p>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`} onClick={() => setShowEndData(!showEndDate)} style={{ width: 140, height: 42 }}>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                      <p className={`${styles.inputWhite} ${styles.flex1} ${endDate ? styles.black : styles.grayA2}`}>
                        {endDate ? `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? "0" + String(endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 10 ? "0" + String(endDate.getDate()) : endDate.getDate()}` : "2022-01-01"}
                      </p>
                      <div
                        className={`${styles.py10}`}
                        style={{ width: 35, height: 42, textAlign: "center" }}
                        onClick={(e) => {
                          e.stopPropagation();

                          setEndDate(null);
                        }}
                      >
                        <img src={require("../../../assets/images/icon_close.png").default} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.mb10} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.width30} ${styles.px20} ${styles.py10}`}>
                  <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
                  <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} placeholder={"회원명, 연락처 및 수업명으로 검색"} />
                </div>
              </form>
            </div>
          </div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.justifyContentBetween} ${styles.mt20}`}>
            <div
              onClick={() => {
                history.push({
                  pathname: "/schedule/reservation/today_list/",
                  state: {
                    machine: location.state.machine,
                    machineId: location.state.machineId,
                    date: date,
                    viewDateType,
                    listType: 1,
                  },
                  search: "?page=1&listType=1",
                });
              }}
              className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow} ${styles.cursorPointer}`}
            >
              <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>오늘의 예약인원</p>
              {data ? <p className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>{numberWithCommas(data.reservationCnt)}</p> : <Skeleton height={30} width={180} style={{ marginTop: 5 }} />}
            </div>
            <div
              onClick={() => {
                history.push({
                  pathname: "/schedule/reservation/today_list/",
                  state: {
                    machine: location.state.machine,
                    machineId: location.state.machineId,
                    date: date,
                    viewDateType,
                    listType: 2,
                  },
                  search: "?page=1&listType=2",
                });
              }}
              className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow} ${styles.mx20} ${styles.cursorPointer}`}
            >
              <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>오늘의 출석인원</p>
              {data ? <p className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>{numberWithCommas(data.attendanceCnt)}</p> : <Skeleton height={30} width={180} style={{ marginTop: 5 }} />}
            </div>
            <div
              onClick={() => {
                history.push({
                  pathname: "/schedule/reservation/today_list/",
                  state: {
                    machine: location.state.machine,
                    machineId: location.state.machineId,
                    date: date,
                    viewDateType,
                    listType: 3,
                  },
                  search: "?page=1&tpage=1&listType=3",
                });
              }}
              className={`${styles.flex1} ${styles.center} ${styles.bgWhite} ${styles.borderRadius10} ${styles.py35} ${styles.btnShadow} ${styles.cursorPointer}`}
            >
              <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.textCenter}`}>오늘의 결석인원</p>
              {data ? <p className={`${styles.fontB} ${styles.font24} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>{numberWithCommas(data.absentCnt)}</p> : <Skeleton height={30} width={180} style={{ marginTop: 5 }} />}
            </div>
          </div>
          <div className={`${styles.mt20} ${styles.bgWhite} ${styles.borderRadius10} ${styles.btnShadow}`}>
            <div className={`${styles.pt50} ${styles.pr45} ${styles.pb30}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.px10} ${styles.alignItemsCenter} ${styles.justifyContentBetween} `}>
                <div className={`${styles.flex1}`}></div>
                <p className={`${styles.flex1} ${styles.fontB} ${styles.font32} ${styles.textCenter} ${styles.cursorPointer}`} onClick={() => setShowDate(!showDate)}>
                  {displayDate}
                  <img src={require("../../../assets/images/icon_dropdown_down.png").default} className={`${styles.icon15} ${styles.ml10}`} />
                </p>
                <div className={`${styles.flex1} ${styles.row} ${styles.mx0}`} style={{ justifyContent: "right" }}>
                  <div className={`${viewDateType === "day" ? styles.bgOrange : styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer}`} onClick={() => setViewDateType("day")}>
                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>일간</p>
                  </div>
                  <div className={`${viewDateType === "week" ? styles.bgOrange : styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setViewDateType("week")}>
                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>주간</p>
                  </div>
                </div>
              </div>
            </div>
            {showDate && (
              <div className={`${styles.row} ${styles.mx0} ${styles.pr45}`} style={{ justifyContent: "center" }}>
                <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.px20} ${styles.py20} ${styles.overflowHidden}`} style={{ width: 400 }}>
                  <Calendar
                    calendarType={"US"}
                    value={new Date(date)}
                    // maxDate={new Date()}
                    // minDate={new Date(2022,0,1)}
                    // minDetail={(viewDateType === "day") ? "month" : (viewDateType === "week") ? "year" : "decade"}
                    // maxDetail={(viewDateType === "day") ? "month" : (viewDateType === "week") ? "year" : "decade"}
                    nextLabel={
                      <span>
                        <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                      </span>
                    }
                    next2Label={null}
                    prevLabel={
                      <span>
                        <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                      </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={(selectDate) => {
                      let _selectDate = new Date(selectDate);
                      _selectDate.setHours(+9);
                      console.log(selectDate);
                      setDate(_selectDate.toISOString().split("T")[0]);
                      setShowDate(false);
                    }}
                  />
                </div>
              </div>
            )}

            <div className={`${styles.px45}`}>
              {resource.length > 0 && scheduleData.length > 0 && (
                <SchedulerMain
                  resource={resource}
                  scheduleData={scheduleData}
                  date={date}
                  viewDateType={viewDateType}
                  tooltipVisibility={tooltipVisibility}
                  toggleTooltipVisibility={toggleTooltipVisibility}
                  appointmentMeta={appointmentMeta}
                  onAppointmentMetaChange={({ target, data }) => {
                    setAppointmentMeta({
                      target: target,
                      data: data,
                    });
                  }}
                  toScheduleList={(scheduleId) => {
                    localStorage.setItem("scheduleId", `${scheduleId}`);
                    history.push({
                      pathname: `/schedule/list/`,
                      state: {
                        machine: location.state.machine,
                        scheduleId: scheduleId,
                      },
                      search: "?order=date_asc&page=1",
                    });
                  }}
                  toReservationList={(scheduleId) => {
                    history.push({
                      pathname: "/schedule/reservation/list/",
                      state: {
                        machine: location.state.machine,
                        scheduleId: scheduleId,
                      },
                      search: "?page=1&tpage=1",
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Modal isOpen={showStartDate} onRequestClose={() => setShowStartData(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={startDate}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleStart}
            />
          </Modal>
          <Modal isOpen={showEndDate} onRequestClose={() => setShowEndData(false)} style={bill}>
            <Calendar
              calendarType={"US"}
              value={endDate}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={handleEnd}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ScheduleMain;
