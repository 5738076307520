import React from 'react';

import styles from '../../../styles/styles.module.scss';

const Category = ({
    category,
    remove,
    index
}) => {
    return (
        <div className={`${styles.row} ${styles.mx0} ${(index === 0) ? "" : styles.ml35}`}>
            <p className={`${styles.fontR} ${styles.font16} ${styles.black}`}>
                {category.name}
            </p>
            <img src={require("../../../assets/images/icon_close.png").default} alt={"delete"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml15} ${styles.mt3}`} onClick={remove} />
        </div>
    )
}

export default Category;