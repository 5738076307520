import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { TOKEN_KEY } from "../../../common/constants";

import styles from "../../../styles/styles.module.scss";
import {
  ADMIN_DEVICE_ROUTE,
  ADVERTISE_ROUTE,
  CASH_ROUTE,
  CHECKIN_ROUTE,
  CONSULT_ROUTE,
  CONTRACT_ROUTE,
  CUSTOM_ROUTE,
  DEVICE_ROUTE,
  LOCKER_ROUTE,
  MEMBERSHIP_ROUTE,
  MEMBER_ROUTE,
  ORDER_ROUTE,
  SITE_ROUTE,
  USERS_ROUTE,
  VIDEO_ROUTE,
  SCHEDULE_ROUTE,
  NOTICE_ROUTE,
  MEMBER_RESERVATION_ROUTE,
  // EVENTLOG_ROUTE,
  ENTRANCE_ROUTE,
} from "../../../common/urls";
import { useAuthContext } from "../../../context/Auth";
import queryString from "query-string";
import Modal from "react-modal";
import { bill, term } from "../../../common/modal";
import Term from "./Term";
import { numberWithCommas } from "../../../common/utility";

const PROFILE = gql`
  {
    getProfileAdmin {
      id
      username
      name
      status
      superuser
    }
  }
`;

const POINT = gql`
  {
    getUserPointAdmin {
      id
      point
      balance
    }
  }
`;

const Navigation = () => {
  const location = useLocation();
  const history = useHistory();

  const AuthContext = useAuthContext();

  const { data, refetch: getUserData } = useQuery(PROFILE, {
    fetchPolicy: "cache-and-network",
  });

  const { data: point_data } = useQuery(POINT, {
    fetchPolicy: "cache-and-network",
  });

  //console.log("point_data::", point_data);

  // const [menuType, setMenuType] = useState(SCHEDULE_ROUTE.includes(location.pathname)||NOTICE_ROUTE.includes(location.pathname) ? 'S' : 'K');
  const [showKiosk, setShowKiosk] = useState(true);
  const [showSchedule, setShowSchedule] = useState(true);

  const [date] = useState(new Date());
  const [signOutTxt, setSignOutTxt] = useState("");

  useEffect(() => {
    let adminToken = localStorage.getItem("adminToken");
    if (adminToken) {
      setSignOutTxt("관리접속 해제");
    } else {
      setSignOutTxt("로그아웃");
    }
  }, []);

  useEffect(() => {
    if (data && data.getProfileAdmin) {
      let isAdmin = localStorage.getItem("viaItem");
      if (data.getProfileAdmin.status === "expire" && !isAdmin) {
        localStorage.removeItem(TOKEN_KEY);
        AuthContext.setIsLoggedIn(false);
      }
    }
  }, [data]);

  const handleAuthChk = () => {
    getUserData();
  };

  const logout = () => {
    let adminToken = localStorage.getItem("adminToken");

    if (adminToken) {
      localStorage.removeItem("adminToken");
      AuthContext.login(adminToken, true);
      window.location.href = "/?page=1";
    } else {
      localStorage.removeItem("viaItem", false);
      AuthContext.logout();
    }
  };

  return (
    <div
      className={`${styles.bgWhite} ${styles.btnShadow} ${styles.justifyContentBetween}`}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        width: 210,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          flex: 1,
          overflowY: "hidden",
          position: "relative",
        }}
      >
        <div className={`${styles.mt40} ${styles.center}`}>
          <img src={require("../../../assets/images/logo_orange.png").default} alt={"VEN Brothers"} className={`${styles.cursorPointer}`} style={{ maxWidth: 125 }} onClick={() => history.push("/?page=1")} />
        </div>
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsEnd} ${styles.mt70} ${styles.px30} ${styles.cursorPointer}`} onClick={() => history.push("/profile/edit/")}>
          <p className={`${styles.fontB} ${styles.font16} ${styles.black}`}>{data?.getProfileAdmin?.name} 님</p>
          <img src={require("../../../assets/images/icon_profile.png").default} alt={data?.getProfileAdmin?.name} className={`${styles.icon15} ${styles.ml15} ${styles.mb4}`} />
        </div>
        <p className={`${styles.fontR} ${styles.font13} ${styles.black} ${styles.px30}`}>
          {date.getFullYear()}-{date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-{date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}
          &#183;
          {date.getHours() < 10 ? "0" + String(date.getHours()) : date.getHours()}:{date.getMinutes() < 10 ? "0" + String(date.getMinutes()) : date.getMinutes()}
        </p>
        {data && data.getProfileAdmin && !data.getProfileAdmin.superuser && (
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsEnd} ${styles.mt10} ${styles.px30} ${styles.justifyContentBetween}`}>
            <p className={`${styles.fontB} ${styles.font13} ${styles.black}`}>{point_data && point_data.getUserPointAdmin && point_data.getUserPointAdmin.balance ? numberWithCommas(point_data.getUserPointAdmin.balance) : 0} P</p>
            <p onClick={() => history.push(`/point/user/main?page=1&order=date_dsc`)} className={`${styles.font12} ${styles.black} ${styles.cursorPointer}`} style={{ textDecorationLine: "underline", letterSpacing: -0.72 }}>
              포인트 조회/충전
            </p>
          </div>
        )}
        <div
          className={`${styles.flex1} ${data && data.getProfileAdmin ? (data.getProfileAdmin.superuser ? styles.mt55 : styles.mt35) : null}`}
          style={{
            position: "relative",
            maxHeight: "70%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {data && data.getProfileAdmin ? (
            data.getProfileAdmin.superuser ? (
              <div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${USERS_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push(`/?page=1&order=date_dsc&q=`)}>
                  <img src={USERS_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_users_white.png").default : require("../../../assets/images/icon_users.png").default} alt={"관리자 계정 관리"} className={`${styles.icon20}`} />
                  <p className={`${USERS_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${USERS_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>관리자 계정 관리</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${ADVERTISE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push(`/advertise/?page=1&order=name_asc&&q=`)}>
                  <img src={ADVERTISE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_advertise_white.png").default : require("../../../assets/images/icon_advertise.png").default} alt={"광고 관리"} className={`${styles.icon20}`} />
                  <p className={`${ADVERTISE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${ADVERTISE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>광고 관리</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${VIDEO_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push(`/video/?page=1&order=name_asc&&q=`)}>
                  <img src={VIDEO_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_advertise_white.png").default : require("../../../assets/images/icon_advertise.png").default} alt={"운동 영상 관리"} className={`${styles.icon20}`} />
                  <p className={`${VIDEO_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${VIDEO_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>운동 영상 관리</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${ADMIN_DEVICE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push(`/device/?page=1`)}>
                  <img src={ADMIN_DEVICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_device_white.png").default : require("../../../assets/images/icon_device.png").default} alt={"기기관리"} className={`${styles.icon20}`} />
                  <p className={`${ADMIN_DEVICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${ADMIN_DEVICE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>기기관리</p>
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push("/member_reservation/marketing/")}>
                  <img src={MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_member_white.png").default : require("../../../assets/images/icon_member.png").default} alt={"회원 관리"} className={`${styles.icon20}`} />
                  <p className={`${MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${MEMBER_RESERVATION_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>회원 관리</p>
                </div>
                {/* <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${VIDEO_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push(`/video/?page=1&e=${`${date.getFullYear()}`}`)}>
                                <img src={VIDEO_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_video_white.png").default : require("../../../assets/images/icon_video.png").default} alt={"영상 관리"} className={`${styles.icon20}`} />
                                <p className={`${VIDEO_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${VIDEO_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>
                                    영상 관리
                                </p>
                            </div> */}
              </div>
            ) : (
              <div>
                <div className={`${styles.px25} ${styles.mb10} ${styles.row} ${styles.cursorPointer}`} onClick={() => setShowKiosk(!showKiosk)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml20} ${styles.flex1}`}>키오스크</p>
                  <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"asc"} style={{ width: 18 * 0.5, height: 10 * 0.5, marginTop: 10 }} />
                </div>
                {showKiosk && (
                  <div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${DEVICE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/?page=1`);
                      }}
                    >
                      <img src={DEVICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_device_white.png").default : require("../../../assets/images/icon_device.png").default} alt={"기기관리"} className={`${styles.icon20}`} />
                      <p className={`${DEVICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${DEVICE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>기기관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pl30} ${styles.py10} ${styles.cursorPointer} ${SITE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/site/?page=1`);
                      }}
                    >
                      <img src={SITE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_site_white.png").default : require("../../../assets/images/icon_site.png").default} alt={"현장 판매 상품 관리"} className={`${styles.icon20}`} />
                      <p className={`${SITE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${SITE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>현장 판매 상품 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pl30} ${styles.py10} ${styles.cursorPointer} ${ORDER_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/order/?page=1&tpage=1&order=date_dsc`);
                      }}
                    >
                      <img src={ORDER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_order_white.png").default : require("../../../assets/images/icon_order.png").default} alt={"주문 판매 상품 관리"} className={`${styles.icon20}`} />
                      <p className={`${ORDER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${ORDER_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>주문 판매 상품 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${MEMBERSHIP_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/membership/?page=1`);
                      }}
                    >
                      <img src={MEMBERSHIP_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_membership_white.png").default : require("../../../assets/images/icon_membership.png").default} alt={"회원권 관리"} className={`${styles.icon20}`} />
                      <p className={`${MEMBERSHIP_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${MEMBERSHIP_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>회원권 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${LOCKER_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/locker/?page=1&tpage=1&order=date_dsc`);
                      }}
                    >
                      <img src={LOCKER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_membership_white.png").default : require("../../../assets/images/icon_membership.png").default} alt={"락커 관리"} className={`${styles.icon20}`} />
                      <p className={`${LOCKER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${LOCKER_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>락커 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${CONSULT_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/consult/?page=1&tpage=1&order=date_dsc");
                      }}
                    >
                      <img src={CONSULT_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_pt_white.png").default : require("../../../assets/images/icon_pt.png").default} alt={"상담 관리"} className={`${styles.icon20}`} />
                      <p className={`${CONSULT_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${CONSULT_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>상담 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${CUSTOM_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/custom/?page=1");
                      }}
                    >
                      <img src={CUSTOM_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_custom_payment_white.png").default : require("../../../assets/images/icon_custom_payment.png").default} alt={"자유 결제 관리"} className={`${styles.icon20}`} />
                      <p className={`${CUSTOM_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${CUSTOM_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>자유 결제 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${CASH_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/cash/?page=1");
                      }}
                    >
                      <img src={CASH_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_cash_receipt_white.png").default : require("../../../assets/images/icon_cash_receipt.png").default} alt={"현금 결제 관리"} className={`${styles.icon20}`} />
                      <p className={`${CASH_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${CASH_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>현금 결제 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${CHECKIN_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/checkin/?page=1");
                      }}
                    >
                      <img src={CHECKIN_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_checkin_white.png").default : require("../../../assets/images/icon_checkin.png").default} alt={"출석 관리"} className={`${styles.icon20}`} />
                      <p className={`${CHECKIN_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${CHECKIN_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>출석 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${MEMBER_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push("/member/?page=1&tpage=1&order=end_asc");
                      }}
                    >
                      <img src={MEMBER_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_member_white.png").default : require("../../../assets/images/icon_member.png").default} alt={"회원 관리"} className={`${styles.icon20}`} />
                      <p className={`${MEMBER_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${MEMBER_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>회원 관리</p>
                    </div>

                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${CONTRACT_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => history.push("/contract/?page=1&tpage=1&order=date_dsc")}
                    >
                      <img src={CONTRACT_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_terms_white.png").default : require("../../../assets/images/icon_terms.png").default} alt={"약관 관리"} className={`${styles.icon20}`} />
                      <p className={`${CONTRACT_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${CONTRACT_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>약관 관리</p>
                    </div>

                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${ENTRANCE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`}
                      onClick={() => {
                        handleAuthChk();
                        history.push(`/entrance/?page=1`);
                      }}
                    >
                      <img src={ENTRANCE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_entrance_white.png").default : require("../../../assets/images/icon_entrance.png").default} alt={"출입 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${ENTRANCE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${ENTRANCE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>출입 관리</p>
                    </div>
                  </div>
                )}
                <div className={`${styles.px25} ${styles.mt35} ${styles.mb10} ${styles.row} ${styles.cursorPointer}`} onClick={() => setShowSchedule(!showSchedule)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml20} ${styles.flex1}`}>스케쥴앱</p>
                  <img src={require("../../../assets/images/icon_sort_down.png").default} alt={"asc"} style={{ width: 18 * 0.5, height: 10 * 0.5, marginTop: 10 }} />
                </div>
                {showSchedule && (
                  <div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.cursorPointer} ${SCHEDULE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite}`} onClick={() => history.push("/schedule/?page=1")}>
                      <img src={SCHEDULE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_schedule_white.png").default : require("../../../assets/images/icon_schedule.png").default} alt={"스캐쥴 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${SCHEDULE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${SCHEDULE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>스케쥴 관리</p>
                    </div>
                    <div
                      className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.py10} ${styles.mb10} ${styles.cursorPointer} ${NOTICE_ROUTE.includes(location.pathname) ? styles.bgOrange : styles.bgWhite} ${styles.mb30}`}
                      onClick={() => history.push("/notice/?page=1")}
                    >
                      <img src={NOTICE_ROUTE.includes(location.pathname) ? require("../../../assets/images/icon_notice_white.png").default : require("../../../assets/images/icon_notice.png").default} alt={"공지사항 관리"} className={`${styles.icon20} ${styles.px2}`} />
                      <p className={`${NOTICE_ROUTE.includes(location.pathname) ? styles.fontB : styles.fontM} ${styles.font13} ${NOTICE_ROUTE.includes(location.pathname) ? styles.white : styles.black} ${styles.ml10}`}>공지사항 관리</p>
                    </div>
                  </div>
                )}
              </div>
            )
          ) : null}
        </div>
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.mt10} ${styles.mb10} ${styles.cursorPointer} ${data && data.getProfileAdmin.superuser ? styles.none : styles.show}`}>
        <p className={`${styles.fontM} ${styles.font13} ${styles.black} ${styles.ml10}`} style={{ fontWeight: "bold" }}>
          <a style={{ textDecoration: "underline", color: "black" }} target={"_blank"} href="https://app.catchsecu.com/document/P/e025b657ce8369f">
            개인정보 처리방침
          </a>
        </p>
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.px30} ${styles.mt10} ${styles.mb40} ${styles.cursorPointer}`} onClick={logout}>
        <img src={require("../../../assets/images/icon_logout.png").default} alt={"로그아웃"} className={`${styles.icon20}`} />
        <p className={`${styles.fontM} ${styles.font13} ${styles.black} ${styles.ml10}`}>{signOutTxt}</p>
      </div>
    </div>
  );
};

export default Navigation;
