import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const MemberEnd = ({
    machine,
    member
}) => {
    const history = useHistory()
    
    const end = (member.defaultMembership && member.defaultMembership.end) ? new Date(Number(member.defaultMembership.end)) : null
    return(
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`} onClick={() => history.push({
            pathname: `/member/edit/`,
            state: {
                machine: machine.username,
                member
            }
        })}>
            <div className={`${styles.flex1}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>
                    {member.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                    <p className={`${styles.fontR} ${styles.font14} ${((member.defaultMembership?.dDay !== null ) && (member.defaultMembership?.dDay <= 10) &&(member.defaultMembership?.dDay >= 0)) ? styles.orange : styles.black}`}>
                        {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                    </p>
                    {((member.defaultMembership?.dDay !== null ) && (member.defaultMembership?.dDay <= 10) &&(member.defaultMembership?.dDay >= 0)) && (
                        <p className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px10} ${styles.ml10}`}>
                            {`D-${member.defaultMembership.dDay}`}
                        </p>
                    )}
                </div>
                {member.memberships ? (member.memberships.length > 0) && (
                    member.memberships.map(membership => {
                        if(member.defaultMembership){
                            if(membership.id !== member.defaultMembership.id){
                                const end = membership.end ? new Date(Number(membership.end)) : null
                                return(
                                    <div key={membership.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                        <p className={`${styles.fontR} ${styles.font14} ${styles.grayA2}`}>
                                            {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                                        </p>
                                        {((membership.dDay !== null ) && (membership.dDay <= 10) &&(membership.dDay >= 0)) && (
                                            <p className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px10} ${styles.ml10}`}>
                                                {`D-${membership.dDay}`}
                                            </p>
                                        )}
                                    </div>
                                )
                            }
                        }
                        else{
                            const end = membership.end ? new Date(Number(membership.end)) : null
                            return(
                                <div key={membership.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                                    <p className={`${styles.fontR} ${styles.font14} ${styles.grayA2}`}>
                                        {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                                    </p>
                                    {((membership.dDay !== null ) && (membership.dDay <= 10) &&(membership.dDay >= 0)) && (
                                        <p className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px10} ${styles.ml10}`}>
                                            {`D-${membership.dDay}`}
                                        </p>
                                    )}
                                </div>
                            )
                        }
                    })
                ) : null}
            </div>
        </div>
    )
}

export default MemberEnd;