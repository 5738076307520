import React, { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useState } from "react";
import Switch from "react-switch";
import { gql, useMutation, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { emailReg, numberReg, mobileReg } from "../../../common/regex";

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      username
      contact
      email
      address
      extraAddress
      addressType
      sido
      sigungu
      bname
      name
      logo
      status
      color
      notice
      intro
      installmentPrice
      createdAt
      images {
        id
        image
      }
      talkContact
      talkPayment
      talkStock
      talkConsult
      talkCustomPayment
      useShopBill
      useAppTalk
      isAppPushSchedule
      isAppPushNotice
      isAppPushMembership
      appPushScheduleTime
      strappPushMembership1Day
      strappPushMembership2Cnt
      strappPushMembership3Day
      strappPushMembership3Cnt
      useSchedule
      useReportTalk
      useMembershipExpiry
    }
  }
`;

const AlarmInputTelModule = ({ curScroll, item, index, handleDelInputTel, handleTalkContact, handleSetExtraTalks }) => {
  const location = useLocation();
  const talkContact = useInput("");

  const dropdown = useRef();

  const [talks, setTalks] = useState([]);
  const [showTalk, setShowTalk] = useState(false);

  useEffect(() => {
    initialize(item);
  }, []);

  const initialize = (item) => {
    let tempTalks = [];
    if (item.talkPayment) tempTalks.push("payment");
    if (item.talkStock) tempTalks.push("stock");
    if (item.talkConsult) tempTalks.push("consult");
    if (item.talkCustomPayment) tempTalks.push("custom_payment");
    if (item.useReportTalk) tempTalks.push("useReportTalk");
    setTalks(tempTalks);
  };

  const handleShowTalk = (showTalk) => {
    setShowTalk(showTalk);
    dropdown.current.focus();
  };

  const handleDel = (index) => {
    handleDelInputTel(index);
  };

  const { data } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handleTalks = (talk, index) => {
    const findIndex = talks.findIndex((t) => t === talk);
    let newTalks = [...talks];
    if (findIndex > -1) {
      newTalks.splice(findIndex, 1);
      setTalks(newTalks);
    } else {
      if (talk === "useReportTalk") {
        if (!item.tel) {
          alert("먼저 기기 알림 수신 연락처를 작성해주세요.");
        } else {
          newTalks.push(talk);
          setTalks([...talks, talk]);
        }
      } else {
        newTalks.push(talk);
        setTalks([...talks, talk]);
      }
    }
    handleSetExtraTalks(newTalks, index);
  };

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.overflowHidden} ${styles.borderBottomGrayA2}`} style={{ width: 680 }} ref={dropdown}>
      <div className={`${styles.px20} ${styles.py15} ${styles.fontB} ${styles.borderRightGrayA2}`} style={{ width: 50 }}>
        {index + 1}
      </div>
      <div className={`${styles.px20} ${styles.py15} ${styles.borderRightGrayA2}`} style={{ width: 100 }}>
        전화번호
      </div>
      <input
        className={`${styles.inputWhite} ${styles.flex1} ${styles.px20} ${styles.py15} ${styles.fontB} ${styles.borderRightGrayA2}`}
        style={{ width: 50 }}
        type={"tel"}
        name={`talkContact${index}`}
        value={item.tel || ""}
        readOnly={false}
        placeholder="010-1234-5678"
        onChange={(e) => handleTalkContact(e, index)}
      />
      <div className={`${styles.mx0} ${styles.justifyContentBetween} ${styles.borderRightGrayA2}`} style={{ width: 325 }}>
        <div
          className={`${styles.row} ${styles.mx0} ${styles.pt15} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.overflowHidden} ${styles.cursorPointer} ${styles.borderRightGrayA2}`}
          style={{
            width: 325,
          }}
          onClick={() => handleShowTalk(!showTalk, 30)}
        >
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`}>
            <p className={`${styles.font16} ${talks.length > 0 ? styles.black : styles.grayA2} ${styles.flex1}`}>{talks.length > 0 ? `${talks.length}개의 알림` : "수신할 알림을 선택해주세요."}</p>
            <img src={showTalk ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"알림 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
          </div>
        </div>
        {showTalk && (
          <div
            className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`}
            style={{
              width: 325,
              transform: `translateY(-${curScroll.current.scrollTop}px)`,
            }}
          >
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 325 }}>
              <div
                className={`${styles.borderRightGrayA2} ${styles.px20}`}
                style={{
                  width: 325,
                }}
              ></div>
              <div className={`${styles.py10} ${styles.flex1}`}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                  <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>주문판매/회원권판매 결제 알림</p>
                  <Switch onChange={() => handleTalks("payment", index)} checked={talks.findIndex((t) => t === "payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                  <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>재고 부족 알림</p>
                  <Switch onChange={() => handleTalks("stock", index)} checked={talks.findIndex((t) => t === "stock") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                  <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>상담 신청 알림</p>
                  <Switch onChange={() => handleTalks("consult", index)} checked={talks.findIndex((t) => t === "consult") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                  <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>자유/현금 결제 알림</p>
                  <Switch onChange={() => handleTalks("custom_payment", index)} checked={talks.findIndex((t) => t === "custom_payment") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                </div>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10}`}>
                  <p className={`${styles.font16} ${styles.black} ${styles.flex1}`}>일일 리포트</p>
                  <Switch onChange={() => handleTalks("useReportTalk", index)} checked={talks.findIndex((t) => t === "useReportTalk") > -1} offColor={"#232222"} onColor={"#FF7701"} uncheckedIcon={false} checkedIcon={false} width={43} height={22} handleDiameter={18} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.pl5} ${styles.py15} ${styles.fontB} ${styles.cursorPointer}`} style={{ width: 55 }}>
        <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} onClick={() => handleDel(index)} />
      </div>
    </div>
  );
};

export default AlarmInputTelModule;
