import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import CheckIn from "../../../components/CheckIn/Mobile/CheckIn";
import Calendar from "react-calendar";
import Modal from "react-modal";
import {bill, billSm} from "../../../common/modal";

const CHECKIN = gql`
  query getCheckIns(
    $machine: String!
    $order: String!
    $skip: Int!
    $q: String
    $start: String
    $end: String
  ) {
    getCheckIns(machine: $machine, order: $order, skip: $skip, q: $q, start: $start, end: $end) {
      checkIns {
        id
        name
        contact
        temperature
        date
      }
      total
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
    }
  }
`;

const CheckInStatus = () => {
  const history = useHistory();
  const location = useLocation();

  const q = useInput(
    queryString.parse(location.search).q
      ? queryString.parse(location.search).q
      : ""
  );

  let startDt = queryString.parse(location.search).start ? new Date(queryString.parse(location.search).start) : null;
  let endDt = queryString.parse(location.search).end ? new Date(queryString.parse(location.search).end) : null;

  const [start, setStart] = useState(startDt);
  const [showStart, setShowStart] = useState(false);
  const [end, setEnd] = useState(endDt);
  const [showEnd, setShowEnd] = useState(false);

  const {
    data,
    loading: loadingCheckIn,
    refetch,
  } = useQuery(CHECKIN, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order
        ? queryString.parse(location.search).order
        : "date_dsc",
      q: queryString.parse(location.search).q
        ? queryString.parse(location.search).q
        : "",
      start: queryString.parse(location.search).start
          ? queryString.parse(location.search).start
          : "",
      end: queryString.parse(location.search).end
          ? queryString.parse(location.search).end
          : "",
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const handlePage = (page) => {
    history.push({
      pathname: `/checkin/status/`,
      state: location.state,
      search: `?order=${
        queryString.parse(location.search).order
          ? queryString.parse(location.search).order
          : "date_dsc"
      }&page=${page.selected + 1}&q=${
        queryString.parse(location.search).q
          ? queryString.parse(location.search).q
          : ""
      }&start=${
          queryString.parse(location.search).start
              ? queryString.parse(location.search).start
              : ""
      }&end=${
          queryString.parse(location.search).end
              ? queryString.parse(location.search).end
              : ""
      }`,
    });
  };

  const handleStart = start => {
    setStart(new Date(start.getTime() + 1000*60*60*9));
    setShowStart(false);
  };

  const handleEnd = end => {
    setEnd(new Date(end.getTime() + 1000*60*60*9));
    setShowEnd(false);
  };

  const submit = async (e) => {
    e.preventDefault();

    searchCheckIn();
  };

  const searchCheckIn = async () => {
    let startStr = "";
    if (start) {
      startStr = start.toISOString().split("T")[0];
    }
    let endStr = "";
    if (end) {
      endStr = end.toISOString().split("T")[0];
    }

    history.push({
      pathname: `/checkin/status/`,
      state: location.state,
      search: `?order=${
          queryString.parse(location.search).order
              ? queryString.parse(location.search).order
              : "date_dsc"
      }&page=${
          queryString.parse(location.search).page
              ? queryString.parse(location.search).page
              : 1
      }&q=${q.value}&start=${startStr}&end=${endStr}`,
    });
  }

  useEffect(() => {
    if (location.state.machine) {
      let startStr = "";
      if (queryString.parse(location.search).start && queryString.parse(location.search).start !== '') {
        let startDt = new Date(queryString.parse(location.search).start);
        startDt = new Date(startDt.getTime() + 1000*60*60*9);
        startStr = startDt.toISOString().split("T")[0];
      }
      let endStr = "";
      if (queryString.parse(location.search).end && queryString.parse(location.search).end !== '') {
        let endDt = new Date(queryString.parse(location.search).end);
        endDt = new Date(endDt.getTime() + 1000*60*60*9);
        endStr = endDt.toISOString().split("T")[0];
      }

      if (
        queryString.parse(location.search).page &&
        !isNaN(Number(queryString.parse(location.search).page))
      ) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order
            ? queryString.parse(location.search).order
            : "date_dsc",
          q: queryString.parse(location.search).q
            ? queryString.parse(location.search).q
            : "",
          start: startStr,
          end: endStr
        });
      } else {
        history.replace({
          pathname: `/checkin/status/`,
          state: location.state,
          search: `?order=${
            queryString.parse(location.search).order
              ? queryString.parse(location.search).order
              : "date_dsc"
          }&page=1&q=${
            queryString.parse(location.search).q
              ? queryString.parse(location.search).q
              : ""
          }&start=${startStr}&end=${endStr}`,
        });
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [
    queryString.parse(location.search).page,
    queryString.parse(location.search).order,
    queryString.parse(location.search).q,
    queryString.parse(location.search).start,
    queryString.parse(location.search).end,
  ]);

  useEffect(() => {
    searchCheckIn();
  }, [start, end]);

  return (
    <div
      className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}
    >
      <div
        className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}
      >
        <div
          className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}
        >
          <p
            className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}
          >
            {`출석 현황${
              machine && machine.getMachine
                ? ` (${machine.getMachine.name})`
                : ""
            }`}
          </p>
          <form
            action={""}
            onSubmit={submit}
            className={`${styles.mt45} ${styles.px40}`}
          >
            <div
              className={`${styles.row} ${styles.mx0} ${styles.mb10} ${styles.justifyContentBetween}`}
            >
              <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  onClick={() =>
                      setShowStart(!showStart)
                  }
                  style={{width: '45%', height: 42}}>
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                  <p
                      className={`${styles.inputWhite} ${styles.flex1} ${
                          start
                              ? styles.black
                              : styles.grayA2
                      }`}>
                    {start
                        ? `${start.getFullYear()}/${
                            start.getMonth() + 1 <
                            10
                                ? '0' +
                                String(
                                    start.getMonth() +
                                    1,
                                )
                                : start.getMonth() +
                                1
                        }/${
                            start.getDate() < 10
                                ? '0' +
                                String(
                                    start.getDate(),
                                )
                                : start.getDate()
                        }`
                        : '2022-01-01'}
                  </p>
                  <div
                      className={`${styles.py10}`}
                      style={{width: 35, height: 42, textAlign: 'center'}}
                      onClick={(e) => {
                        e.stopPropagation();

                        setStart(null);
                      }}>
                    <img
                        src={require('../../../assets/images/icon_close.png').default}
                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                    />
                  </div>
                </div>
              </div>
              <p className={`${styles.px5} ${styles.py10}`}>~</p>
              <div
                  className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.cursorPointer}`}
                  onClick={() =>
                      setShowEnd(!showEnd)
                  }
                  style={{width: '45%', height: 42}}>
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.pl20}`}>
                  <p
                      className={`${styles.inputWhite} ${styles.flex1} ${
                          end
                              ? styles.black
                              : styles.grayA2
                      }`}>
                    {end
                        ? `${end.getFullYear()}/${
                            end.getMonth() + 1 <
                            10
                                ? '0' +
                                String(
                                    end.getMonth() +
                                    1,
                                )
                                : end.getMonth() +
                                1
                        }/${
                            end.getDate() < 10
                                ? '0' +
                                String(
                                    end.getDate(),
                                )
                                : end.getDate()
                        }`
                        : '2022-01-01'}
                  </p>
                  <div
                      className={`${styles.py10}`}
                      style={{width: 35, height: 42, textAlign: 'center'}}
                      onClick={(e) => {
                        e.stopPropagation();

                        setEnd(null);
                      }}>
                    <img
                        src={require('../../../assets/images/icon_close.png').default}
                        className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}
            >
              <img
                src={require("../../../assets/images/icon_search.png").default}
                alt={"search"}
                className={`${styles.icon20} ${styles.cursorPointer}`}
                onClick={submit}
              />
              <input
                type={"text"}
                name={"q"}
                value={q.value}
                onChange={q.onChange}
                className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`}
                placeholder={"사용자명 및 연락처 검색"}
              />
            </div>
          </form>
          <div
              className={`${styles.mt45} ${styles.px10}`}>
            <p className={`${styles.black}`}>
              검색 결과: {data?.getCheckIns?.total}개
            </p>
          </div>
          <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt10}`}
          >
            <div className={`${styles.flex2}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                출석일시
              </p>
            </div>
            <div className={`${styles.flex2} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                사용자명
              </p>
            </div>
            <div className={`${styles.flex1}`}>
              <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                발열체크
              </p>
            </div>
          </div>
          <div>
            {loadingCheckIn ? (
              <Skeleton height={50} count={3} />
            ) : data &&
              data.getCheckIns &&
              data.getCheckIns.checkIns.length > 0 ? (
              data.getCheckIns.checkIns.map((checkIn) => {
                return <CheckIn key={checkIn.id} checkIn={checkIn} />;
              })
            ) : (
              <div className={`${styles.center}`} style={{ height: 600 }}>
                <p
                  className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}
                >
                  출석 내역이 없습니다.
                </p>
              </div>
            )}
            {data?.getCheckIns?.total > 10 ? (
              <div
                className={`${styles.mt35}`}
                style={{ position: "relative" }}
              >
                <ReactPaginate
                  previousLabel={
                    <img
                      src={
                        require("../../../assets/images/icon_back.png").default
                      }
                      alt={"이전"}
                      className={`${styles.cursorPointer}`}
                      style={{ width: 45 * 0.2, height: 79 * 0.2 }}
                    />
                  }
                  nextLabel={
                    <img
                      src={
                        require("../../../assets/images/icon_front.png").default
                      }
                      alt={"다음"}
                      className={`${styles.cursorPointer}`}
                      style={{ width: 45 * 0.2, height: 79 * 0.2 }}
                    />
                  }
                  breakLabel={
                    <p
                      className={`${styles.fontM} ${styles.font16} ${styles.black}`}
                    >
                      &#183;&#183;&#183;
                    </p>
                  }
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={
                    data?.getCheckIns?.total
                      ? parseInt((data?.getCheckIns?.total - 1) / 10) + 1
                      : 1
                  }
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingCheckIn ? null : handlePage}
                  initialPage={
                    queryString.parse(location.search).page
                      ? Number(queryString.parse(location.search).page) - 1
                      : 0
                  }
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingCheckIn && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.4)",
                    }}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
          isOpen={showStart}
          onRequestClose={() => setShowStart(false)}
          style={billSm}>
        <Calendar
            calendarType={'US'}
            value={start}
            minDetail={'month'}
            nextLabel={
              <span>
                            <img
                                src={
                                  require('../../../assets/images/icon_dropdown_right.png')
                                      .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
            }
            next2Label={null}
            prevLabel={
              <span>
                            <img
                                src={
                                  require('../../../assets/images/icon_dropdown_left.png')
                                      .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
            }
            prev2Label={null}
            navigationLabel={({ label }) => (
                <p
                    className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                  {label}
                </p>
            )}
            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
            onChange={handleStart}
        />
      </Modal>

      <Modal
          isOpen={showEnd}
          onRequestClose={() => setShowEnd(false)}
          style={billSm}>
        <Calendar
            calendarType={'US'}
            value={end}
            minDetail={'month'}
            nextLabel={
              <span>
                            <img
                                src={
                                  require('../../../assets/images/icon_dropdown_right.png')
                                      .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
            }
            next2Label={null}
            prevLabel={
              <span>
                            <img
                                src={
                                  require('../../../assets/images/icon_dropdown_left.png')
                                      .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
            }
            prev2Label={null}
            navigationLabel={({ label }) => (
                <p
                    className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                  {label}
                </p>
            )}
            tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
            onChange={handleEnd}
        />
      </Modal>
    </div>
  );
};

export default CheckInStatus;
