import React from "react";

import styles from "../../../styles/styles.module.scss";
import { numberWithCommas } from "../../../common/utility";

const SitePayment = ({ payment }) => {
  // 영수증 출력
  const showBill = () => {
    window.open("https://admin.ven-brothers.com/receipt/" + payment.approvalDate + payment.approvalNo);
  };

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.px40} ${styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`} onClick={showBill}>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>{`${payment.approvalDate.slice(2, 4)}/${payment.approvalDate.slice(4, 6)} - ${payment.approvalTime.slice(0, 2)}:${payment.approvalTime.slice(2, 4)}`}</p>
      </div>
      <div className={`${styles.flex2} ${styles.px10}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`}>{payment.productName}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black}`} style={payment.status === "refund" ? { textDecorationLine: "line-through" } : null}>
          {numberWithCommas(payment.totalAmount)}
        </p>
      </div>
    </div>
  );
};

export default SitePayment;
