import React from "react";
import { useHistory } from "react-router-dom";

import styles from "../../../styles/styles.module.scss";

const Member = ({ machine, member, showCheckbox, showOption, selectedMemberIds, setSelectedMemberIds, remove }) => {
  const history = useHistory();

  const date = new Date(Number(member.createdAt));
  const start = member.defaultMembership && member.defaultMembership.start ? new Date(Number(member.defaultMembership.start)) : null;
  const end = member.defaultMembership && member.defaultMembership.end ? new Date(Number(member.defaultMembership.end)) : null;
  const membershipType = member.defaultMembership && member.defaultMembership.product.membershipType ? Number(member.defaultMembership.product.membershipType) : null;
  const membershipCount = member.defaultMembership && member.defaultMembership.membershipCount ? Number(member.defaultMembership.membershipCount) : null;
  const totalDeduct = member.defaultMembership && member.defaultMembership.totalDeduct ? Number(member.defaultMembership.totalDeduct) : 0;

  let checked = false;
  if (selectedMemberIds && showCheckbox) {
    for (let i = 0; i < selectedMemberIds.length; i++) {
      if (selectedMemberIds[i] === member.id) {
        checked = true;
        break;
      }
    }
  }

  const changeLimitMemberships = (e) => {
    if (member.memberships.length <= 5) {
      return;
    }

    let hasAfter = getComputedStyle(e.target, "::after").content !== "none";
    if (!hasAfter) {
      return;
    }

    e.stopPropagation();

    let parentNode = e.target.parentNode;

    if (parentNode.tagName !== "DIV") {
      parentNode = parentNode.parentNode;
    }

    let showLimitMemberships = parentNode.classList.contains(`${styles.showLimitMemberships}`);
    if (showLimitMemberships) {
      parentNode.classList.remove(`${styles.showLimitMemberships}`);
    } else {
      parentNode.classList.add(`${styles.showLimitMemberships}`);
    }

    let elements = parentNode.parentNode.querySelectorAll(`.memberships`);
    for (let i = 0; i < elements.length; i++) {
      let has = elements[i].classList.contains(`${styles.showLimitMemberships}`);
      if (showLimitMemberships) {
        if (has) {
          elements[i].classList.remove(`${styles.showLimitMemberships}`);
        }
      } else {
        elements[i].classList.add(`${styles.showLimitMemberships}`);
      }
    }
  };

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      {showCheckbox && (
        <div
          className={`${styles.ml10} ${styles.mr10} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
          onClick={() => {
            let _selectedMemberId = [...selectedMemberIds];

            if (checked) {
              let idx = _selectedMemberId.indexOf(member.id);
              if (idx > 0) {
                _selectedMemberId.splice(idx, 1);
              }
            } else {
              _selectedMemberId.push(member.id);
            }

            setSelectedMemberIds(_selectedMemberId);
          }}
        >
          {checked && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
        </div>
      )}

      <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{machine.name}</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{member.name}</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{member.mobile}</p>
        </div>
        <div className={`${styles.flex2} ${styles.showLimitMemberships} memberships`} onClick={changeLimitMemberships}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{member.defaultMembership ? member.defaultMembership.product.name : ""}</p>
          {member.memberships
            ? member.memberships.length > 0 &&
              member.memberships.map((membership, idx) => {
                if (member.defaultMembership) {
                  if (membership.id !== member.defaultMembership.id) {
                    return (
                      <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        {membership.product.name}
                      </p>
                    );
                  }
                } else {
                  return (
                    <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                      {membership.product.name}
                    </p>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.flex1} ${styles.showLimitMemberships} memberships`} onClick={changeLimitMemberships}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
            {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : ""}
          </p>
          {member.memberships
            ? member.memberships.length > 0 &&
              member.memberships.map((membership) => {
                if (member.defaultMembership) {
                  if (membership.id !== member.defaultMembership.id) {
                    const start = membership.start ? new Date(Number(membership.start)) : null;
                    return (
                      <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : "-"}
                      </p>
                    );
                  }
                } else {
                  const start = membership.start ? new Date(Number(membership.start)) : null;
                  return (
                    <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                      {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : "-"}
                    </p>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.flex1} ${styles.showLimitMemberships} memberships`} onClick={changeLimitMemberships} style={{ position: "relative" }}>
          <p className={`${styles.fontR} ${styles.font14} ${member.defaultMembership?.dDay !== null && member.defaultMembership?.dDay <= 10 && member.defaultMembership?.dDay >= 0 ? styles.orange : styles.black} ${styles.textCenter}`}>
            {member.defaultMembership?.dDay !== null && member.defaultMembership?.dDay <= 10 && member.defaultMembership?.dDay >= 0 && (
              <span className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px5}`} style={{ position: "absolute", right: 0 }}>
                {`D-${member.defaultMembership.dDay}`}
              </span>
            )}
            <span>{end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : "-"}</span>
          </p>
          {member.memberships
            ? member.memberships.length > 0 &&
              member.memberships.map((membership) => {
                if (member.defaultMembership) {
                  if (membership.id !== member.defaultMembership.id) {
                    const end = membership.end ? new Date(Number(membership.end)) : null;
                    return (
                      <p key={membership.id} className={`${styles.textCenter} ${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        {membership.dDay !== null && membership.dDay <= 10 && membership.dDay >= 0 && (
                          <span className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px5}`} style={{ position: "absolute", right: 0 }}>
                            {`D-${membership.dDay}`}
                          </span>
                        )}
                        <span>{end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : "-"}</span>
                      </p>
                    );
                  }
                } else {
                  const end = membership.end ? new Date(Number(membership.end)) : null;
                  return (
                    <p key={membership.id}>
                      {membership.dDay !== null && membership.dDay <= 10 && membership.dDay >= 0 && (
                        <span className={`${styles.fontR} ${styles.font14} ${styles.white} ${styles.textCenter} ${styles.bgOrange} ${styles.px5}`} style={{ position: "absolute", right: 0 }}>
                          {`D-${membership.dDay}`}
                        </span>
                      )}
                      <span className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : "-"}
                      </span>
                    </p>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.flex1} ${styles.showLimitMemberships} memberships`}>
          <p className={`${styles.fontR} ${styles.font14} ${member.defaultMembership?.dDay !== null && member.defaultMembership?.dDay <= 10 && member.defaultMembership?.dDay >= 0 ? styles.orange : styles.black} ${styles.textCenter}`}>
            <span>{membershipType === 1 ? "-" : membershipCount ? membershipCount - totalDeduct : "-"}</span>
          </p>
          {member.memberships
            ? member.memberships.length > 0 &&
              member.memberships.map((membership) => {
                if (member.defaultMembership) {
                  if (membership.id !== member.defaultMembership.id) {
                    return (
                      <p key={membership.id} className={`${styles.textCenter} ${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        <span>{membership.product.membershipType === 1 ? "-" : Number(membership.membershipCount) - (membership.totalDeduct ? Number(membership.totalDeduct) : 0)}</span>
                      </p>
                    );
                  }
                } else {
                  return (
                    <p key={membership.id} className={`${styles.textCenter} ${styles.fontR} ${styles.font14}`}>
                      <span className={member.defaultMembership && membership.id !== member.defaultMembership.id ? `${styles.grayA2}` : ""}>{membership.product.membershipType === 1 ? "-" : Number(membership.membershipCount) - (membership.totalDeduct ? Number(membership.totalDeduct) : 0)}</span>
                    </p>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.flex1} ${styles.showLimitMemberships} memberships`} onClick={changeLimitMemberships}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
            {member.defaultMembership ? (
              <span>
                {member.defaultMembership.status === "inactive" && "비활성"}
                {member.defaultMembership.status === "active" && "유효"}
                {member.defaultMembership.status === "ready" && "사용대기"}
                {member.defaultMembership.status === "expired" && "만료"}
                {member.defaultMembership.status === "holding" && "홀딩중"}
              </span>
            ) : (
              ""
            )}
          </p>
          {member.memberships
            ? member.memberships.length > 0 &&
              member.memberships.map((membership) => {
                if (member.defaultMembership) {
                  if (membership.id !== member.defaultMembership.id) {
                    return (
                      <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                        <span>
                          {membership.status === "inactive" && "비활성"}
                          {membership.status === "active" && "유효"}
                          {membership.status === "ready" && "사용대기"}
                          {membership.status === "expired" && "만료"}
                          {membership.status === "holding" && "홀딩중"}
                        </span>
                      </p>
                    );
                  }
                } else {
                  return (
                    <p key={membership.id} className={`${styles.fontR} ${styles.font14} ${styles.grayA2} ${styles.textCenter}`}>
                      <span>
                        {membership.status === "inactive" && "비활성"}
                        {membership.status === "active" && "유효"}
                        {membership.status === "ready" && "사용대기"}
                        {membership.status === "expired" && "만료"}
                        {membership.status === "holding" && "홀딩중"}
                      </span>
                    </p>
                  );
                }
              })
            : null}
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
            {`${date.getFullYear()}/${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}/${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}-${date.getHours() < 10 ? "0" + String(date.getHours()) : date.getHours()}:${
              date.getMinutes() < 10 ? "0" + String(date.getMinutes()) : date.getMinutes()
            }:${date.getSeconds() < 10 ? "0" + String(date.getSeconds()) : date.getSeconds()}`}
          </p>
        </div>
        <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`} style={{ display: showOption ? "flex" : "none" }}>
          <div
            className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
            onClick={() => {
              history.push({
                pathname: `/member/edit/`,
                state: {
                  machine: machine.username,
                  machineId: machine.id,
                  member,
                },
              });
            }}
          >
            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
          </div>
          <img src={require("../../../assets/images/icon_trash.png").default} alt={"삭제"} className={`${styles.icon20} ${styles.cursorPointer} ${styles.ml30}`} onClick={remove} />
        </div>
      </div>
    </div>
  );
};

export default Member;
