import React from 'react';

import styles from '../../../styles/styles.module.scss';
import { numberWithCommas } from '../../../common/utility';

const ProductCategory = ({ category, extra }) => {
    return (
        <div
            className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1}`}></div>
            <div className={`${styles.flex2}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {category.name}
                </p>
            </div>
            <div className={`${styles.flex1}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {category.productCount !== undefined
                        ? numberWithCommas(category.productCount)
                        : ''}
                </p>
            </div>
            <div className={`${styles.flex10}`}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {extra}
                </p>
            </div>
        </div>
    );
};

export default ProductCategory;
