import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from '../../../styles/styles.module.scss';

const TeacherList = ({ teacher, remove }) => {
    const history = useHistory();
    return (
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                </p>
                {new Date(parseInt(teacher.createdAt)+32400000).toISOString().split('T')[0].replace('-', '/').replace('-', '/')}
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {teacher.name}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {teacher.scheduleCnt}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {teacher.scheduleReservationCnt}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                    {teacher.scheduleWaitingCnt}
                </p>
            </div>
            <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py3} ${styles.cursorPointer}`}
                    onClick={() =>
                        history.push({
                            pathname: `/teacher/edit/`,
                            state: {
                                machine: teacher.machine.username,
                                teacher,
                            },
                        })
                    }>
                    <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>관리</p>
                </div>
                <img src={require('../../../assets/images/icon_trash.png').default}
                     alt={'삭제'}
                     className={`${styles.icon20} ${styles.cursorPointer} ${styles.cursorPointer} ${styles.ml30}`}
                     onClick={remove}
                />
            </div>
        </div>
    );
};

export default TeacherList;
