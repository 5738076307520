import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import styles from '../../styles/styles.module.scss';
import cache from '../../apollo/cache';
import options from '../../apollo/options';
import NavController from './NavController';
import { AuthProvider } from '../../context/Auth';

const App = () => {
    const [client, setClient] = useState(null);

    const preload = async () => {
        await persistCache({
            cache,
            storage: new LocalStorageWrapper(window.localStorage),
        });

        const client = new ApolloClient({
            cache,
            ...options,
        });

        setClient(client);
    };

    useEffect(() => {
        preload();
    }, []);

    return client ? (
        <ApolloProvider client={client}>
            <AuthProvider>
                <NavController />
            </AuthProvider>
        </ApolloProvider>
    ) : (
        <div
            className={`${styles.widthFull} ${styles.bgOrange}`}
            style={{ minWidth: 320 }}>
            <div className={`${styles.minHeightFull} ${styles.center}`}>
                <img
                    src={require('../../assets/images/logo_lg.png').default}
                    alt={'VEN Brothers'}
                    className={`${styles.p3}`}
                    style={{ maxWidth: 300 }}
                />
            </div>
        </div>
    );
};

export default App;
