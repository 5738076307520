import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import styles from "../../../styles/styles.module.scss";
import ActiveMembershipItem from "./ActiveMembershipItem";
import { bill, billSm, membership as membershipModal } from "../../../common/modal";
import Calendar from "react-calendar";
import Modal from "react-modal";
import { numberReg } from "../../../common/regex";

const MEMBERSHIP = gql`
  query getMemberships($machine: String!, $machineId: Int) {
    getMemberships(machine: $machine, machineId: $machineId) {
      id
      name
      duration
      membershipType
      membership
      cancel
    }
  }
`;

const ActiveMemberships = ({ machine, memberships, defaultMembershipId: id, isEntrance, removeMembership, editMembership, createMembership, changeDefaultMembership, machineId }) => {
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [defaultMembershipId, setDefaultMembershipId] = useState(id);

  const { data } = useQuery(MEMBERSHIP, {
    variables: {
      machine,
      machineId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    setDefaultMembershipId(id);
  }, [id]);

  return (
    <div className={`${styles.bgWhite}`}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py10} ${styles.px0} ${styles.borderBottomGrayF4}`}>
        <div className={`${styles.flex2}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권명</p>
        </div>
        <div className={`${styles.flex2} ${styles.px5}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>시작일</p>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>종료일</p>
        </div>
        <div className={`${styles.flex2}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>횟수</p>
        </div>
        <div className={`${styles.flex1}`}>
          <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
        </div>
      </div>
      <div className={`${styles.bgWhite}`}>
        {memberships.map((membership, index) => {
          if (membership.status === "active" || membership.status === "ready" || membership.status === "holding") {
            return (
              <ActiveMembershipItem
                key={membership.id}
                membership={membership}
                index={index}
                isEntrance={isEntrance}
                memberships={data?.getMemberships}
                selected={membership.id === defaultMembershipId}
                setDefaultMembershipId={() => setDefaultMembershipId(membership.id)}
                removeMembership={() => removeMembership(membership)}
                editMembership={editMembership}
                changeDefaultMembership={() => changeDefaultMembership(membership.id)}
              />
            );
            // const start = membership.start ? new Date(Number(membership.start)) : null
            // const end = membership.end ? new Date(Number(membership.end)) : null
            // return(
            //     <div key={membership.id} className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px15} ${styles.py15} ${((index % 2) === 0) ? styles.bgWhite : styles.bgGrayEf}`}
            //         onClick={ () => showModalOption(true, membership) }
            //     >
            //         <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex2}`}>
            //             {membership.product.name}
            //         </p>
            //         <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex2} ${styles.px5}`}>
            //             {start ? `${start.getFullYear()}/${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}/${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()}` : ""}<br/>
            //             {end ? `${end.getFullYear()}/${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}/${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
            //         </p>
            //         <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex2} ${styles.px5}`}>
            //             { membership.membershipCount }
            //         </p>
            //         <p className={`${styles.fontR} ${styles.font12} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
            //             {(membership.status === "inactive") && "비활성"}
            //             {(membership.status === "active") && "유효"}
            //             {(membership.status === "ready") && "사용대기"}
            //             {(membership.status === "expired") && "만료"}
            //             {(membership.status === "holding") && "홀딩중"}
            //         </p>
            //     </div>
            // )
          }
        })}
      </div>
    </div>
  );
};

export default ActiveMemberships;
