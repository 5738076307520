import React from "react";
import styles from "../../../styles/styles.module.scss";

const EventSearch = ({ data, codename }) => {
  const convertToKST = (date) => {
    const utc = date.getTime() + date.getTimezoneOffset() * 60000; // Change to 60000 to correctly convert minutes to milliseconds
    const kst = new Date(utc + 3600000 * 9);
    return kst;
  };

  const date = convertToKST(new Date(data.datetime));

  return (
    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py20} ${styles.bgWhite} ${styles.borderBottomGrayF4}`}>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {`${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}-${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{data.door && data.door.length > 0 ? data.door[0].name : ""}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {data.device_id}
          {/* {console.log("device_id: ", data.device_id)} */}
        </p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{data.device_name}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{data.user_group_id}</p>
      </div>
      <div className={`${styles.flex1}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
          {data.user_id}
          {"\n"}({data.user_name})
        </p>
      </div>
      <div className={`${styles.flex2}`}>
        <p className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter}`}>{codename}</p>
      </div>
    </div>
  );
};

export default EventSearch;
