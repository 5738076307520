import React, { useRef, useState } from "react";
import styles from "../../../styles/styles.module.scss";

const MenuModule = ({ item, index, handleModifyMenuList, handleMenuText, handleChecked }) => {
  let showCheckbox = item.ischked;

  const [checked, setChecked] = useState(item.ischked);

  return (
    <div className={`${styles.row} ${styles.flex1} ${styles.borderTopGrayA2} ${styles.mx0} ${styles.justifyContentBetween}`}>
      <div className={`${styles.py10} ${styles.pl8}`}>
        <img src={require("../../../assets/images/icon_menu.png").default} alt={"이동"} className={`${styles.icon15}`} />
      </div>
      <div className={`${styles.font14} ${styles.fontM} ${styles.px2} ${styles.py10} ${styles.borderRightGrayA2} ${styles.textOverflowLimit} ${styles.widthFull}`} style={{ width: 96, height: 40 }}>
        {item.menuName || ""}
      </div>
      <div className={`${styles.font14} ${styles.fontM} ${styles.px0} ${styles.py10}`}>
        <input type={"text"} value={item.menuText || ""} className={`${styles.inputWhite} ${styles.flex1} ${styles.textOverflowLimit}`} onChange={(e) => handleMenuText(e, index)} style={{ width: 130 }} placeholder="(선택) 미입력 시 기본 메뉴명으로 표시" />
      </div>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.bgNone} ${styles.textCenter} ${styles.borderLeftGrayA2}`}>
        <div className={`${styles.mx0} ${styles.bgNone} ${styles.py10} ${styles.px10} ${styles.bgNone} ${styles.borderBottomGrayF4} ${styles.cursorPointer}`}>
          {!showCheckbox && (
            <div className={`${styles.bgNone} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`} onClick={() => handleChecked(item, index)}>
              <div className={`${styles.bgWhite} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />
            </div>
          )}
          {showCheckbox && (
            <div className={`${styles.bgNone} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`} onClick={() => handleChecked(item, index)}>
              <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuModule;
