import React from 'react';

import styles from '../../../styles/styles.module.scss';

const ScheduleDays = ({selected, idx, handleSelectDays}) => {
    const days = ["일", "월", "화", "수", "목", "금", "토"];

    console.log('idx::::', idx);

    return (
        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.pr20} ${styles.cursorPointer}`} onClick={() => handleSelectDays(idx)}>
            <div
                className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.mr15} ${styles.icon20}`}>
                {selected && (
                    <div
                        className={`${styles.bgOrange} ${styles.borderRadius5}`}
                        style={{
                            width: 14,
                            height: 14,
                        }}
                    />
                )}
            </div>
            <p
                className={`${styles.font16} ${styles.black}`}>
                {days[idx]}
            </p>
        </div>
    );
};

export default ScheduleDays;
