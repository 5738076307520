import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import TableDragSelect from "react-table-drag-select";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { numberReg } from "../../../common/regex";

const LOCKER = gql`
  query getLockerArea($machine: String!, $lockerId: Int!) {
    getLockerArea(machine: $machine, lockerId: $lockerId) {
      id
      name
      sizeX
      sizeY
      isDisplay
      lockers {
        id
        positionX
        positionY
        status
        number
        member {
          id
          name
        }
        start
        end
      }
    }
  }
`;

const EDIT = gql`
  mutation editLockerArea($machine: String!, $lockerId: Int!, $editArray: Boolean!, $name: String!, $lockers: String, $isDisplay: Boolean) {
    editLockerArea(machine: $machine, lockerId: $lockerId, editArray: $editArray, name: $name, lockers: $lockers, isDisplay: $isDisplay) {
      lockerArea {
        id
        name
        sizeX
        sizeY
        isDisplay
        lockers {
          id
          positionX
          positionY
          status
          number
        }
      }
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      entranceSystem
    }
  }
`;

const LockerEdit = () => {
  const EMPTY_LOCKER = [
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
    [{ type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }, { type: "none" }],
  ];
  const EMPTY_EDITED_LOCKER = [
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
    [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
  ];

  const history = useHistory();
  const location = useLocation();

  const name = useInput("");

  const [locker, setLocker] = useState([...EMPTY_LOCKER]);
  const [editedLocker, setEditedLocker] = useState([...EMPTY_EDITED_LOCKER]);
  const [lockerNumbers, setLockerNumbers] = useState([]);
  const [editArray, setEditArray] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showIsDisplay, setShowIsDisplay] = useState(false);
  const [isDisplay, setIsDisplay] = useState(true);

  const { data } = useQuery(LOCKER, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
      lockerId: location.state.lockerId ? location.state.lockerId : -1,
    },
  });
  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });

  const [editLockerAreaM] = useMutation(EDIT);

  const handleIsDisplay = (isDisplay) => {
    setIsDisplay(isDisplay);
    setShowIsDisplay(false);
  };

  const onChangeNumber = (e, x, y) => {
    const {
      target: { value },
    } = e;
    if (numberReg.test(value)) {
      let newLockerNumbers = [...lockerNumbers];
      newLockerNumbers[y][x] = {
        ...newLockerNumbers[y][x],
        number: parseInt(value),
      };
      setLockerNumbers(newLockerNumbers);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!loading) {
      try {
        if (editArray) {
          if (step === 1) {
            let check = false;
            for (const row of editedLocker) {
              for (const col of row) {
                if (col) {
                  check = true;
                }
              }
            }
            if (!check) {
              alert("사용하실 락커를 선택해주세요.");
              return;
            }
            let newLockerNumbers = [];
            for (var y = 0; y < editedLocker.length; y++) {
              let row = [];
              for (var x = 0; x < editedLocker[y].length; x++) {
                if (editedLocker[y][x]) {
                  row.push({
                    x,
                    y,
                    enabled: true,
                    number: locker[y][x].number ? locker[y][x].number : "",
                    originStatus: locker[y][x].type,
                  });
                } else {
                  row.push({
                    x,
                    y,
                    enabled: false,
                    number: null,
                    originStatus: locker[y][x].type,
                  });
                }
              }
              newLockerNumbers.push(row);
            }
            setLockerNumbers(newLockerNumbers);
            setStep(2);
          } else if (step === 2) {
            if (!name.value) {
              alert("락커 구역을 입력해주세요.");
              return;
            }
            setLoading(true);
            let check = true;
            for (const row of lockerNumbers) {
              for (const col of row) {
                if (col.enabled && !col.number) {
                  check = false;
                }
                const findIndex = lockerNumbers.findIndex((r) => {
                  for (const c of r) {
                    if (col.enabled && c.enabled && !(c.x === col.x && c.y === col.y) && c.number === col.number) {
                      return true;
                    }
                  }
                  return false;
                });
                if (findIndex > -1) {
                  check = false;
                }
              }
            }
            if (!check) {
              setLoading(false);
              alert("중복되는 락커번호 또는 번호가 입력되지 않은 락커가 있습니다.");
              return;
            }
            setLoading(true);
            const { data } = await editLockerAreaM({
              variables: {
                machine: location.state.machine ? location.state.machine : "",
                lockerId: location.state.lockerId ? location.state.lockerId : -1,
                editArray,
                name: name.value,
                lockers: JSON.stringify(lockerNumbers),
                isDisplay,
              },
            });
            setLoading(false);
            if (data && data.editLockerArea.lockerArea) {
              alert("수정하였습니다.");
              initialize(data.editLockerArea.lockerArea);
            } else if (data && data.editLockerArea.error) {
              alert(data.editLockerArea.error);
            } else {
              alert("오류가 발생하였습니다.");
            }
          }
        } else {
          if (!name.value) {
            alert("락커 구역을 입력해주세요.");
            return;
          }
          setLoading(true);
          const { data } = await editLockerAreaM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              lockerId: location.state.lockerId ? location.state.lockerId : -1,
              editArray,
              name: name.value,
              isDisplay,
            },
          });
          setLoading(false);
          if (data && data.editLockerArea.lockerArea) {
            initialize(data.editLockerArea.lockerArea);
            alert("수정하였습니다.");
          } else if (data && data.editLockerArea.error) {
            alert(data.editLockerArea.error);
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    }
  };

  const initialize = (lockerArea) => {
    if (lockerArea) {
      name.setValue(lockerArea.name);
      let newLocker = [...EMPTY_LOCKER];
      let newEditedLocker = [...EMPTY_EDITED_LOCKER];
      for (const item of lockerArea.lockers) {
        newLocker[item.positionY][item.positionX] = {
          ...item,
          type: item.status,
        };
        newEditedLocker[item.positionY][item.positionX] = true;
      }
      setLocker(newLocker);
      setEditedLocker(newEditedLocker);
      setIsDisplay(lockerArea.isDisplay);
    } else {
      if (data && data.getLockerArea) {
        name.setValue(data.getLockerArea.name);
        let newLocker = [...EMPTY_LOCKER];
        let newEditedLocker = [...EMPTY_EDITED_LOCKER];
        for (const item of data.getLockerArea.lockers) {
          newLocker[item.positionY][item.positionX] = {
            ...item,
            type: item.status,
          };
          newEditedLocker[item.positionY][item.positionX] = true;
        }
        setLocker(newLocker);
        setEditedLocker(newEditedLocker);
        setIsDisplay(data.getLockerArea.isDisplay);
      }
    }
    setLockerNumbers([]);
    setLoading(false);
    setEditArray(false);
    setStep(1);
  };

  useEffect(() => {
    if (data && data.getLockerArea) {
      initialize();
    }
  }, [data]);

  useEffect(() => {
    if (!location.state.machine || !location.state.lockerId) {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, []);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10} ${styles.pb30} ${styles.borderBottomGrayA2}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`락커 관리${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={history.goBack}>
                <img
                  src={require("../../../assets/images/icon_back.png").default}
                  alt={"이전"}
                  className={`${styles.cursorPointer}`}
                  style={{
                    width: 45 * 0.2,
                    height: 79 * 0.2,
                  }}
                />
                <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
              </div>
            </div>
            <form action={""} onSubmit={submit} className={`${styles.mt40} ${styles.center}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween} ${styles.my10}`} style={{ width: 1250 }}>
                <div>
                  <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`}
                    style={{ width: 1250, borderBottomLeftRadius: showIsDisplay ? 0 : 10, borderBottomRightRadius: showIsDisplay ? 0 : 10 }}
                  >
                    <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>전시 상태*</p>
                    </div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20}`} onClick={() => setShowIsDisplay(!showIsDisplay)}>
                      <p className={`${styles.font16} ${styles.black}`}>{isDisplay ? "전시 중" : "전시 중단"}</p>
                      <img src={showIsDisplay ? require("../../../assets/images/icon_dropdown_up.png").default : require("../../../assets/images/icon_dropdown_down.png").default} alt={"회원권 분류 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5} ${styles.mr10}`} />
                    </div>
                  </div>
                  {showIsDisplay && (
                    <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite}`} style={{ width: 1250 }}>
                        <div className={`${styles.borderRightGrayA2} ${styles.px20}`} style={{ width: 190 }}></div>
                        <div className={`${styles.py10}`}>
                          <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleIsDisplay(true)}>
                            <p className={`${styles.font16} ${styles.black}`}>전시 중</p>
                          </div>
                          <div className={`${styles.row} ${styles.mx0} ${styles.cursorPointer} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.flex1} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => handleIsDisplay(false)}>
                            <p className={`${styles.font16} ${styles.black}`}>전시 중단</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentBetween}`} style={{ width: 1250 }}>
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden}`} style={{ width: 1250 }}>
                  <div className={`${styles.borderRightGrayA2} ${styles.px20} ${styles.py15}`} style={{ width: 190 }}>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 구역*</p>
                  </div>
                  <input type={"text"} name={"name"} value={name.value} onChange={name.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"락커 구역을 입력해주세요."} />
                </div>
              </div>
              <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py15} ${styles.mt20}`} style={{ width: 1250 }}>
                {editArray ? (
                  <div>
                    {step === 1 && (
                      <div>
                        <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 배치 수정 (락커의 구조에 맞게 클릭 및 드래그하여 선택해주세요. 현재 이용중인 락커는 배치 수정이 불가합니다.)</p>
                        <div className={`${styles.py30} ${styles.center}`}>
                          <TableDragSelect value={editedLocker} onChange={(cells) => setEditedLocker(cells)}>
                            {editedLocker.map((row, y) => {
                              return (
                                <tr key={y}>
                                  {row.map((_, x) => {
                                    return <td disabled={locker[y][x].type === "expired" || locker[y][x].type === "inuse"} key={`${y}-${x}`} />;
                                  })}
                                </tr>
                              );
                            })}
                          </TableDragSelect>
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <div>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween}`}>
                          <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>락커 번호 등록 (직접 번호를 수정할 수 있습니다.)</p>
                        </div>
                        <div className={`${styles.py30} ${styles.center}`}>
                          <table>
                            <tbody>
                              {lockerNumbers.map((row, y) => {
                                return (
                                  <tr key={y}>
                                    {row.map((col, x) => {
                                      return (
                                        <td
                                          key={`${y}-${x}`}
                                          style={{
                                            width: 1206 / 15 - 2,
                                            height: 1206 / 15 - 2,
                                            borderRadius: 5,
                                            cursor: "pointer",
                                            backgroundColor: col.enabled ? "#ff7700" : "#D8D8D8",
                                          }}
                                        >
                                          <input
                                            type={"tel"}
                                            name={`${y}-${x}`}
                                            value={col.number}
                                            onChange={(e) => onChangeNumber(e, x, y)}
                                            className={`${styles.inputTransparent} ${styles.textCenter} ${styles.fontB} ${styles.white} ${styles.widthFull}`}
                                            style={{
                                              fontSize: 33,
                                              padding: 0,
                                            }}
                                            readOnly={!col.enabled}
                                          />
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentEnd}`}>
                      <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgGrayD8}`} />
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>사용중</p>
                      <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgPink} ${styles.ml20}`} />
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>만료</p>
                      <div className={`${styles.borderRadius5} ${styles.icon20} ${styles.bgSky} ${styles.ml20}`} />
                      <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.ml5}`}>사용가능</p>
                      <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px15} ${styles.py5} ${styles.center} ${styles.ml25} ${styles.cursorPointer}`} onClick={() => setEditArray(true)}>
                        <p className={`${styles.fontB} ${styles.font13} ${styles.white}`}>락커 배치 수정</p>
                      </div>
                    </div>
                    <table className={`${styles.py30} ${styles.center}`}>
                      <tbody>
                        {locker.map((row, y) => {
                          return (
                            <tr key={y}>
                              {row.map((col, x) => {
                                const start = col.start ? new Date(Number(col.start)) : null;
                                const end = col.end ? new Date(Number(col.end)) : null;
                                return (
                                  <td
                                    key={`${y}-${x}`}
                                    className={`${col.type === "none" ? "" : styles.cursorPointer}`}
                                    style={{
                                      width: 1206 / 15 - 2,
                                      height: 1206 / 15 - 2,
                                      borderRadius: 5,
                                      backgroundColor: col.type === "available" ? "#7b99ff" : col.type === "expired" ? "#edb9b9" : col.type === "inuse" ? "#d8d8d8" : "#fff",
                                      opacity: col.type === "none" ? 0 : 1,
                                    }}
                                    onClick={
                                      col.id
                                        ? () =>
                                            history.push({
                                              pathname: `/locker/detail/`,
                                              state: {
                                                machine: location.state.machine ? location.state.machine : "",
                                                lockerId: col.id,
                                              },
                                            })
                                        : null
                                    }
                                  >
                                    <p className={`${styles.fontM} ${styles.font8} ${col.type === "available" ? styles.white : styles.black} ${styles.textCenter}`}>{col.number}</p>
                                    <div className={`${styles.mt5} ${col.type === "available" ? styles.hidden : ""}`}>
                                      <p
                                        className={`${styles.fontM} ${styles.font8} ${col.type === "available" ? styles.white : styles.black} ${styles.textCenter}`}
                                        style={{
                                          height: 15,
                                        }}
                                      >
                                        {col.member ? col.member.name : ""}
                                      </p>
                                      <p
                                        className={`${styles.fontM} ${styles.font8} ${col.type === "available" ? styles.white : styles.black} ${styles.textCenter}`}
                                        style={{
                                          height: 30,
                                        }}
                                      >
                                        {start ? `${start.getFullYear()}.${start.getMonth() + 1 < 10 ? "0" + String(start.getMonth() + 1) : start.getMonth() + 1}.${start.getDate() < 10 ? "0" + String(start.getDate()) : start.getDate()} ~` : ""}
                                        <br />
                                        {end ? `${end.getFullYear()}.${end.getMonth() + 1 < 10 ? "0" + String(end.getMonth() + 1) : end.getMonth() + 1}.${end.getDate() < 10 ? "0" + String(end.getDate()) : end.getDate()}` : ""}
                                      </p>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt65} ${styles.mb70}`} style={{ width: 1250 }}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={history.goBack}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>취소</p>
                </div>
                <button type={"submit"} className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px100} ${styles.py15} ${styles.cursorPointer} ${styles.ml10}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontB} ${styles.font18} ${styles.white}`}>{editArray ? (step === 1 ? "다음" : "저장") : "저장"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockerEdit;
