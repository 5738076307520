import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";

import styles from "../../../styles/styles.module.scss";
import cache from "../../../apollo/cache";
import Machine from "../../../components/Home/PC/Machine";
import TotalSaleGraph from "../../../components/Graph/TotalSale";
import TotalSaleStatics from "../../../components/Statics/PC/TotalSale";

const MACHINE = gql`
  query getMachines($skip: Int!) {
    getMachines(skip: $skip) {
      machines {
        id
        username
        name
        status
        createdAt
        productTotal
      }
      total
    }
  }
`;

const DELETE = gql`
  mutation deleteMachine($machine: String!) {
    deleteMachine(machine: $machine)
  }
`;

const MachineHome = () => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: loadingMachine,
    refetch,
  } = useQuery(MACHINE, {
    fetchPolicy: "cache-and-network",
    variables: {
      skip: 0,
    },
  });

  const [deleteMachineM] = useMutation(DELETE);

  const handlePage = (page) => {
    history.push(`/?page=${page.selected + 1}`);
  };

  const deleteMachine = async (machine) => {
    if (!loading) {
      if (machine) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data: result } = await deleteMachineM({
            variables: {
              machine: machine.username,
            },
          });
          setLoading(false);
          if (result && result.deleteMachine) {
            cache.evict({
              id: `Machine:${machine.id}`,
            });
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  useEffect(() => {
    if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
      window.scrollTo(0, 0);
      refetch({
        skip: 5 * (Number(queryString.parse(location.search).page) - 1),
      });
    }
  }, [queryString.parse(location.search).page]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>기기 관리</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer}`} onClick={() => history.push("/device/find/")}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>기기계정 찾기</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => history.push("/device/add/")}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>기기 추가하기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4} ${styles.mt45}`}>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기계정</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>상태</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>등록된 상품수</p>
              </div>
              <div className={`${styles.flex1}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>등록일자</p>
              </div>
              <div className={`${styles.flex1}`}></div>
              <div className={`${styles.flex1}`}></div>
            </div>
            {loadingMachine ? (
              <Skeleton height={50} count={3} />
            ) : (
              data?.getMachines?.machines?.map((machine) => {
                return <Machine key={machine.id} machine={machine} deleteMachine={() => deleteMachine(machine)} />;
              })
            )}
            {data?.getMachines?.total > 5 ? (
              <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                <ReactPaginate
                  previousLabel={<img src={require("../../../assets/images/icon_back.png").default} alt={"이전"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  nextLabel={<img src={require("../../../assets/images/icon_front.png").default} alt={"다음"} className={`${styles.cursorPointer}`} style={{ width: 45 * 0.2, height: 79 * 0.2 }} />}
                  breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                  breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  breakLinkClassName={`${styles.icon30} ${styles.center}`}
                  pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                  activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                  activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                  previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  previousLinkClassName={`${styles.icon30} ${styles.center}`}
                  nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                  nextLinkClassName={`${styles.icon30} ${styles.center}`}
                  disabledClassName={`${styles.icon30} ${styles.center}`}
                  pageCount={data?.getMachines?.total ? parseInt((data?.getMachines?.total - 1) / 5) + 1 : 1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={4}
                  onPageChange={loadingMachine ? null : handlePage}
                  initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                  containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                />
                {loadingMachine && <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />}
              </div>
            ) : null}
          </div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.justifyContentBetween} ${styles.mt20}`}>
            <TotalSaleGraph />
            <TotalSaleStatics />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineHome;
