import React from 'react';

import styles from '../../styles/styles.module.scss';

const Error404 = () => {
    return(
        <div className={`${styles.widthFull} ${styles.bgOrange}`} style={{ minWidth: 320 }}>
            <div className={`${styles.minHeightFull} ${styles.center}`}>
                <div className={`${styles.py15} ${styles.px15} ${styles.center}`}>
                    <p className={`${styles.fontB} ${styles.black} ${styles.textCenter}`} style={{ fontSize: 100 }}>
                        404
                    </p>
                    <p className={`${styles.fontM} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.mt5}`}>
                        페이지를 찾을 수 없습니다.
                    </p>
                    <img src={require("../../assets/images/logo_lg.png").default} alt={"VEN Brothers"} className={`${styles.mt15}`} style={{ width: 100 }} />
                </div>
            </div>
        </div>
    )
}

export default Error404;