import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import styles from '../../../styles/styles.module.scss';
import Terms from '../../../components/Contract/Mobile/Terms';
import useInput from '../../../hooks/useInput';

const TERMS = gql`
    query getTerms($machine: String!, $skip: Int!, $order: String!, $search: String){
        getTerms(machine: $machine, skip: $skip, order: $order, search: $search){
            terms{
                id
                name
                file
                default
                contractTotal
                createdAt
            }
            total
        }
    }
`

const DETAIL = gql`
    query getMachine($machine: String!){
        getMachine(machine: $machine){
            id
            name
        }
    }
`

const EDIT = gql`
    mutation editDefaultTerms($machine: String!, $termId: Int!){
        editDefaultTerms(machine: $machine, termId: $termId){
            terms{
                id
                name
                default
                contractTotal
                createdAt
            }
            error
        }
    }
`

const ContractTermList = () => {
    const history = useHistory()
    const location = useLocation()

    const search = useInput("")

    const [loading, setLoading] = useState(false)

    const { data, loading:loadingTerms, refetch } = useQuery(TERMS, {
        fetchPolicy: "cache-and-network",
        variables: {
            skip: 0,
            machine: location.state.machine ? location.state.machine : "",
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
            search: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        }
    })
    const { data:machine } = useQuery(DETAIL, {
        fetchPolicy: "cache-and-network",
        variables: {
            machine: location.state.machine ? location.state.machine : ""
        }
    })

    const [editDefaultTermsM] = useMutation(EDIT)

    const submit = (e) => {
        e.preventDefault()
        history.push({
            pathname: `/contract/terms/list/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${search.value}`
        })
    }

    const handlePage = (page) => {
        history.push({
            pathname: `/contract/terms/list/`,
            state: location.state,
            search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
        })
    }

    const editDefaultTerms = async(terms) => {
        if(!loading && terms && !terms.default){
            const confirm = window.confirm("해당 약관을 기본으로 변경하시겠습니까?")
            if(confirm){
                setLoading(true)
                const { data } = await editDefaultTermsM({
                    variables: {
                        machine: location.state.machine ? location.state.machine : "",
                        termId: terms.id
                    }
                })
                setLoading(false)
                if(data && data.editDefaultTerms.error){
                    alert(data.editDefaultTerms.error)
                }
                else{
                    alert("변경하였습니다.")
                }
            }
        }
    }

    useEffect(() => {
        if(location.state.machine){
            if(queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))){
                window.scrollTo(0,0)
                refetch({
                    skip: 10*(Number(queryString.parse(location.search).page) - 1),
                    machine: location.state.machine,
                    order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc",
                    search: queryString.parse(location.search).q ? queryString.parse(location.search).q : ""
                })
            }
            else{
                history.replace({
                    pathname: `/contract/terms/list/`,
                    state: location.state,
                    search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "date_dsc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`
                })
            }
        }
        else{
            alert("잘못된 접근입니다.")
            history.goBack()
        }
    }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q])

    return (
        <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf}`}>
            <div className={`${styles.containerLoggedIn} ${styles.px15} ${styles.py30} ${styles.safeareaMobile}`}>
                <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.pt40} ${styles.pb60} ${styles.btnShadow}`}>
                    <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>
                        {`계약 양식 관리${(machine && machine.getMachine) ? ` (${machine.getMachine.name})` : ""}`}
                    </p>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.mt45}`}>
                        <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px20} ${styles.py10} ${styles.cursorPointer}`} onClick={() => history.push({
                            pathname: `/contract/terms/add/`,
                            state: {
                                machine: location.state.machine ? location.state.machine : ""
                            }
                        })}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                양식 추가
                            </p>
                        </div>
                    </div>
                    <form action={""} onSubmit={submit} className={`${styles.mt15} ${styles.px40}`}>
                        <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`}>
                            <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon20} ${styles.cursorPointer}`} onClick={submit} />
                            <input 
                            type={"text"} 
                            name={"search"} 
                            value={search.value} 
                            onChange={search.onChange} 
                            className={`${styles.inputWhite} ${styles.flex1} ${styles.pl15}`} 
                            placeholder={"약관명 검색"}
                            />
                        </div>
                    </form>
                    <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.px40} ${styles.bgGrayF4} ${styles.mt30}`}>
                        <div className={`${styles.flex3}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black}`}>
                                약관명
                            </p>
                        </div>
                        <div className={`${styles.flex1} ${styles.px10}`}>
                            <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                                사용 여부
                            </p>
                        </div>
                    </div>
                    <div>
                        {loadingTerms ? (
                            <Skeleton height={50} count={3}/>
                        ) : (
                            (data && data.getTerms && (data.getTerms.terms.length > 0)) ? (
                                data.getTerms.terms.map(terms => {
                                    return(
                                        <Terms 
                                        key={terms.id} 
                                        terms={terms} 
                                        editDefaultTerms={() => editDefaultTerms(terms)}
                                        loading={loading}
                                        />
                                    )
                                })
                            ) : (
                                <div className={`${styles.center}`} style={{ height: 600 }}>
                                    <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>
                                        아직 등록된 양식이 없습니다.
                                    </p>
                                </div>
                            )
                        )}
                        {(data?.getTerms?.total > 5) ? (
                            <div className={`${styles.mt35}`} style={{ position: "relative" }}>
                                <ReactPaginate
                                previousLabel={(
                                    <img src={require('../../../assets/images/icon_back.png').default} alt={'이전'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                nextLabel={(
                                    <img src={require('../../../assets/images/icon_front.png').default} alt={'다음'} className={`${styles.cursorPointer}`} style={{ width: 45*0.2, height: 79*0.2 }} />
                                )}
                                breakLabel={(
                                    <p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>
                                        &#183;&#183;&#183;
                                    </p>
                                )}
                                breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                breakLinkClassName={`${styles.icon30} ${styles.center}`}
                                pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                                activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                                activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                                previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                previousLinkClassName={`${styles.icon30} ${styles.center}`}
                                nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                                nextLinkClassName={`${styles.icon30} ${styles.center}`}
                                disabledClassName={`${styles.icon30} ${styles.center}`}
                                pageCount={(data?.getTerms?.total) ? parseInt((data?.getTerms?.total - 1)/10) + 1 : 1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={4}
                                onPageChange={loadingTerms ? null : handlePage}
                                initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                                containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                                />
                                {loadingTerms && (
                                    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractTermList;