import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import xlsx from "xlsx";
import axios from "axios";
import { resizeImage } from "../../../common/utility";

import styles from "../../../styles/styles.module.scss";
import useInput from "../../../hooks/useInput";
import { bill } from "../../../common/modal";
import Member from "../../../components/Member/PC/Member";
import { numberReg } from "../../../common/regex";
import { FETCH_URL } from "../../../config";
import cache from "../../../apollo/cache";
import { numberWithCommas } from "../../../common/utility";

import Loading from "../../../assets/images/loading.gif";

const USER_BASE = true;
const MEMBERSHIP_BASE = false;
const STATUS_MEMBERSHIP_ALL = "all";
const STATUS_MEMBERSHIP_ACTIVE = "active";
const STATUS_MEMBERSHIP_EXPIRE = "expired";
var progressCount = 0;
var total = 0;
const MEMBER = gql`
  query getMembers($machine: String!, $order: String!, $skip: Int!, $q: String, $membershipType: String, $machineId: Int, $isAll: Boolean) {
    getMembers(machine: $machine, order: $order, skip: $skip, q: $q, membershipType: $membershipType, machineId: $machineId, isAll: $isAll) {
      members {
        id
        name
        mobile
        birth
        gender
        purpose
        address
        extraAddress
        extra
        createdAt
        defaultMembershipId
        memberships {
          id
          lastreservationend
          start
          end
          status
          dDay
          membershipCount
          cancelCount
          totalDeduct
          totalCancel
          product {
            id
            name
            membershipType
            ignoreCancel
          }
          payment {
            totalAmount
          }
        }
      }
      total
    }
  }
`;

const MEMBERS = gql`
  query getAllMember($machine: String!, $order: String!, $skip: Int!, $take: Int!, $q: String, $membershipType: String, $searchMembershipType: String, $machineId: Int) {
    getAllMember(machine: $machine, order: $order, skip: $skip, take: $take, q: $q, membershipType: $membershipType, searchMembershipType: $searchMembershipType, machineId: $machineId) {
      members {
        id
        name
        mobile
        birth
        gender
        purpose
        address
        extraAddress
        extra
        createdAt
        defaultMembership {
          id
          product {
            id
            name
            membershipType
            ignoreCancel
          }
          start
          end
          status
          dDay
          membershipCount
          totalDeduct
          totalCancel
        }
        memberships {
          id
          product {
            id
            name
            membershipType
            ignoreCancel
          }
          start
          end
          status
          dDay
          membershipCount
          cancelCount
          totalDeduct
          totalCancel
          payment {
            totalAmount
          }
        }
      }
      total
    }
  }
`;

//엑셀 내보내기용
const MEMBERS_FAST = gql`
  query getAllMemberFast($q: String, $machineId: String, $membershipStatus: String) {
    getAllMemberFast(q: $q, machineId: $machineId, membershipStatus: $membershipStatus) {
      members {
        name
        mobile
        birth
        gender
        productName
        start
        end
        remainCount
        cancelCount
        price
        totalCount
        address
        purpose
        extra
        lastreservationend
      }
    }
  }
`;

const VALID_EXPIRED_MEMBER_CNT = gql`
  query getValidExpiredMemberCnt($machine: String!, $q: String, $machineId: Int) {
    getValidExpiredMemberCnt(machine: $machine, q: $q, machineId: $machineId) {
      error
      validMemberCnt
      expiredMemberCnt
    }
  }
`;

const EXTEND = gql`
  mutation extendMemberships($machine: String!, $duration: Int!, $type: String!, $machineId: Int) {
    extendMemberships(machine: $machine, duration: $duration, type: $type, machineId: $machineId) {
      success
      error
    }
  }
`;

const DETAIL = gql`
  query getMachine($machine: String!) {
    getMachine(machine: $machine) {
      id
      name
      username
    }
  }
`;

const IMPORT = gql`
  mutation importMember($machine: String!, $file: String!, $machineId: Int) {
    importMember(machine: $machine, file: $file, machineId: $machineId) {
      success
      error
    }
  }
`;

const PROFILE = gql`
  {
    getProfileAdmin {
      id
      username
      name
      mobile
      status
      superuser
    }
  }
`;

const DELETE_MEMBERS = gql`
  mutation deleteMembers($machine: String!, $memberIds: [Int!], $machineId: Int) {
    deleteMembers(machine: $machine, memberIds: $memberIds, machineId: $machineId)
  }
`;

const DELETE = gql`
  mutation deleteMember($machine: String!, $memberId: Int!, $machineId: Int) {
    deleteMember(machine: $machine, memberId: $memberId, machineId: $machineId)
  }
`;

const MemberList = () => {
  const history = useHistory();
  const location = useLocation();

  const excelRef = useRef();

  const q = useInput(queryString.parse(location.search).q ? queryString.parse(location.search).q : "");
  const duration = useInput("");

  const [type, setType] = useState("active");
  const [membershipType, setMembershipType] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedAllMembers, setSelectedAllMembers] = useState([]);
  const [memberCntData, setMemberCntData] = useState(null);
  const [loadData, setLoadData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { data: profileData } = useQuery(PROFILE, {
    fetchPolicy: "cache-and-network",
  });

  const [deleteMemberM] = useMutation(DELETE);

  const {
    data,
    refetch,
    loading: loadingMember,
  } = useQuery(MEMBER, {
    fetchPolicy: "network-only",
    variables: {
      skip: 0,
      machine: location.state.machine ? location.state.machine : "",
      order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
      q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
      membershipType,
      searchMembershipType: membershipType,
      machineId: location.state.machineId ? location.state.machineId : null,
    },
  });

  const { data: machine } = useQuery(DETAIL, {
    fetchPolicy: "cache-and-network",
    variables: {
      machine: location.state.machine ? location.state.machine : "",
    },
  });
  const { refetch: refetchMembers } = useQuery(MEMBERS, {
    fetchPolicy: "network-only",
    skip: true,
  });

  const { refetch: refetchMembersFast } = useQuery(MEMBERS_FAST, {
    fetchPolicy: "cache-and-network",
    skip: true,
  });

  const { refetch: memberCntDataRefetch } = useQuery(VALID_EXPIRED_MEMBER_CNT, { skip: true });

  const [extendMembershipsM] = useMutation(EXTEND);
  const [importMemberM] = useMutation(IMPORT);
  const [deleteMembersM] = useMutation(DELETE_MEMBERS);

  const handleDuration = (values) => {
    const { value } = values;
    if (numberReg.test(value)) {
      duration.setValue(value);
    }
  };

  const handlePage = (page) => {
    setLoadData(false);
    history.replace({
      pathname: `/member/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=${page.selected + 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleOrder = (order) => {
    if (order === "date") {
      if (queryString.parse(location.search).order === "date_asc") {
        applyOrder("date_dsc");
      } else if (queryString.parse(location.search).order === "date_dsc") {
        applyOrder("date_asc");
      } else {
        applyOrder("date_dsc");
      }
    } else if (order === "name") {
      if (queryString.parse(location.search).order === "name_asc") {
        applyOrder("name_dsc");
      } else if (queryString.parse(location.search).order === "name_dsc") {
        applyOrder("name_asc");
      } else {
        applyOrder("name_asc");
      }
    } else if (order === "contact") {
      if (queryString.parse(location.search).order === "contact_asc") {
        applyOrder("contact_dsc");
      } else if (queryString.parse(location.search).order === "contact_dsc") {
        applyOrder("contact_asc");
      } else {
        applyOrder("contact_asc");
      }
    } else if (order === "membership_start") {
      if (queryString.parse(location.search).order === "membership_start_asc") {
        applyOrder("membership_start_dsc");
      } else if (queryString.parse(location.search).order === "membership_start_dsc") {
        applyOrder("membership_start_asc");
      } else {
        applyOrder("membership_start_dsc");
      }
    } else if (order === "membership_end") {
      if (queryString.parse(location.search).order === "membership_end_asc") {
        applyOrder("membership_end_dsc");
      } else if (queryString.parse(location.search).order === "membership_end_dsc") {
        applyOrder("membership_end_asc");
      } else {
        applyOrder("membership_end_dsc");
      }
    } else if (order === "membership_membershipCount") {
      if (queryString.parse(location.search).order === "membership_membershipCount_asc") {
        applyOrder("membership_membershipCount_dsc");
      } else if (queryString.parse(location.search).order === "membership_membershipCount_dsc") {
        applyOrder("membership_membershipCount_asc");
      } else {
        applyOrder("membership_membershipCount_dsc");
      }
    } else {
      applyOrder("name_asc");
    }
  };

  const applyOrder = (order) => {
    setLoadData(false);
    history.replace({
      pathname: "/member/list/",
      state: location.state,
      search: `?order=${order}&page=${queryString.parse(location.search).page ? queryString.parse(location.search).page : 1}&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const handleMembershipType = (membershipType) => {
    setMembershipType(membershipType);
    setShowTypeModal(false);
    setLoadData(false);
    history.replace({
      pathname: "/member/list/",
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    if (decodeURI(history.location.search) === `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${q.value}`) {
      return false;
    }
    setLoadData(false);
    history.replace({
      pathname: `/member/list/`,
      state: location.state,
      search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${q.value}`,
      /*
            ${
                queryString.parse(location.search).page
                    ? queryString.parse(location.search).page
                    : 1
            }
             */
    });
  };

  const extend = async () => {
    if (!loading) {
      if (!(duration.value && type)) {
        alert("위의 정보를 입력해주세요.");
        return;
      }
      if (Number(duration.value) <= 0) {
        alert("1 이상의 일수를 입력해주세요.");
        return;
      }
      setLoading(true);
      try {
        const { data } = await extendMembershipsM({
          variables: {
            machine: location.state.machine ? location.state.machine : "",
            machineId: location.state.machineId ? location.state.machineId : null,
            duration: Number(duration.value),
            type,
          },
        });
        setLoading(false);
        if (data && data.extendMemberships.success) {
          refetch({
            skip: 10 * (Number(queryString.parse(location.search).page) - 1),
            machine: location.state.machine,
            order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
            q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
            membershipType,
          });
          alert("연장하였습니다.");
        } else if (data && data.extendMemberships.error) {
          alert("extendMemberships");
        } else {
          alert("오류가 발생하였습니다.");
        }
        setShowModal(false);
      } catch {
        setLoading(false);
        alert("오류가 발생하였습니다.");
      }
    }
  };

  function getToday() {
    var date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);

    return year + "/" + month + "/" + day;
  }

  const toDisplayCount = (count) => {
    if (count == null || count.length == 0) return "";
    else return count + "개";
  };

  const toDisplayGender = (gender) => {
    if (gender == null) return "";
    else if (gender == "male") return "남성";
    else return "여성";
  };

  const isBetween = (today, start, end, remainCount) => {
    var strStart = start == null ? "" : start.replaceAll("/", "");
    var strEnd = end == null ? "" : end.replaceAll("/", "");
    var strToday = today == null ? "" : today.replaceAll("/", "");
    console.log("start, today, end", start, today, end, start <= today && today <= end);

    //시작일, 종료일 빈 경우 처리 추가
    if (strStart == "" && strEnd == "") return true;

    if (strStart == "") {
      if (today <= end) return true;
      else return false;
    }

    if (strEnd == "") {
      if (start <= today) return true;
      else return false;
    }

    if (start <= today && today <= end) {
      if (remainCount && remainCount > 0) {
        return true;
      } else {
        return false;
      }
    } else return false;
  };

  const isEqual = (member_Cur, member_Prv) => {
    if (member_Cur.name == member_Prv.name && member_Cur.mobile == member_Prv.mobile) return true;
    else return false;
  };

  const resultToData = (result, membershipStatus, isUserBase) => {
    var today = getToday();

    let data = [];

    if (isUserBase) {
      //회원 기준
      var member_Prv = null;
      var mergeName_P = ""; //상품명
      var mergeDate_S = ""; //시작일
      var mergeDate_E = ""; //종료일
      var mergeCountR = ""; //남은 횟수
      var mergeCountC = ""; //취소 횟수
      var mergePriceP = ""; //상품 금액
      var mergeStatus = ""; //회원권 상태
      var isAcitveUser = false;
      for (const member of result.getAllMemberFast.members) {
        if (member_Prv) {
          var isNextMember = !isEqual(member, member_Prv);
          var isLastMember = member == result.getAllMemberFast.members[result.getAllMemberFast.members.length - 1];
          //var isFirstLine = (isNextMember || isLastMember);
          var isAcitve = isBetween(today, member_Prv.start, member_Prv.end, member_Prv.remainCount);
          if (isAcitve) isAcitveUser = true;

          console.log(member_Prv.productName, isNextMember, isLastMember);

          mergeName_P += member_Prv.productName + (isNextMember ? "" : "\n");
          mergeDate_S += member_Prv.start + (isNextMember ? "" : "\n");
          mergeDate_E += member_Prv.end + (isNextMember ? "" : "\n");
          mergeCountR += toDisplayCount(member_Prv.remainCount) + (isNextMember ? "" : "\n");
          mergeCountC += toDisplayCount(member_Prv.cancelCount) + (isNextMember ? "" : "\n");
          mergePriceP += member_Prv.price + (isNextMember ? "" : "\n");
          mergeStatus += (isAcitve ? "유효" : "만료") + (isNextMember ? "" : "\n");

          if (isNextMember) {
            if (membershipStatus === STATUS_MEMBERSHIP_ALL || (membershipStatus === STATUS_MEMBERSHIP_ACTIVE && isAcitveUser) || (membershipStatus === STATUS_MEMBERSHIP_EXPIRE && !isAcitveUser)) {
              data.push({
                회원명: member_Prv.name,
                "회원 연락처": `010${member_Prv.mobile}`,
                생년월일: member_Prv.birth,
                성별: toDisplayGender(member_Prv.gender),
                회원권: mergeName_P,
                "회원권 시작일": mergeDate_S,
                "회원권 종료일": mergeDate_E,
                "회원권 횟수": mergeCountR,
                "회원권 취소 횟수": mergeCountC,
                "회원권 상태": mergeStatus,
                "회원권 결제 금액": numberWithCommas(mergePriceP),
                "회원권 총 구매 횟수": member_Prv.totalCount,
                "회원권 주소": member_Prv.address,
                "운동 목적": member_Prv.purpose,
                비고: member_Prv.extra,
              });
            }

            mergeName_P = "";
            mergeDate_S = "";
            mergeDate_E = "";
            mergeCountR = "";
            mergeCountC = "";
            mergePriceP = "";
            mergeStatus = "";
            isAcitveUser = false;
          }

          if (isLastMember) {
            var isAcitve = isBetween(today, member.start, member.end, member.remainCount);
            if (isAcitve) isAcitveUser = true;

            console.log("lastMember");

            mergeName_P += member.productName;
            mergeDate_S += member.start;
            mergeDate_E += member.end;
            mergeCountR += toDisplayCount(member.remainCount);
            mergeCountC += toDisplayCount(member.cancelCount);
            mergePriceP += member.price;
            mergeStatus += isAcitve ? "유효" : "만료";

            if (membershipStatus === STATUS_MEMBERSHIP_ALL || (membershipStatus === STATUS_MEMBERSHIP_ACTIVE && isAcitveUser) || (membershipStatus === STATUS_MEMBERSHIP_EXPIRE && !isAcitveUser)) {
              data.push({
                회원명: member.name,
                "회원 연락처": `010${member.mobile}`,
                생년월일: member.birth,
                성별: toDisplayGender(member.gender),
                회원권: mergeName_P,
                "회원권 시작일": mergeDate_S,
                "회원권 종료일": mergeDate_E,
                "회원권 횟수": mergeCountR,
                "회원권 취소 횟수": mergeCountC,
                "회원권 상태": mergeStatus,
                "회원권 결제 금액": numberWithCommas(mergePriceP),
                "회원권 총 구매 횟수": member.totalCount,
                "회원권 주소": member.address,
                "운동 목적": member.purpose,
                비고: member.extra,
              });
            }
          }
        }
        member_Prv = member;
      }
    } else {
      //회원권 기준

      for (const member of result.getAllMemberFast.members) {
        var isAcitve = isBetween(today, member.start, member.end, member.remainCount);

        if (membershipStatus === STATUS_MEMBERSHIP_ALL || (membershipStatus === STATUS_MEMBERSHIP_ACTIVE && isAcitve) || (membershipStatus === STATUS_MEMBERSHIP_EXPIRE && !isAcitve)) {
          data.push({
            회원명: member.name,
            "회원 연락처": `010${member.mobile}`,
            생년월일: member.birth,
            성별: toDisplayGender(member.gender),
            회원권: member.productName,
            "회원권 시작일": member.start,
            "회원권 종료일": member.end,
            "회원권 횟수": toDisplayCount(member.remainCount),
            "회원권 취소 횟수": toDisplayCount(member.cancelCount),
            "회원권 상태": isAcitve ? "유효" : "만료",
            "회원권 결제 금액": Number(member.price),
            "회원권 총 구매 횟수": member.totalCount,
            "회원권 주소": member.address,
            "운동 목적": member.purpose,
            비고: member.extra,
          });
        }
      }
    }

    console.log("data-len", data.length);
    return data;
  };

  const exportExcel = async (membershipStatus, isUserBase) => {
    setIsLoading(true);
    var queryKeyword = queryString.parse(location.search).q;

    if (!loading && machine && machine.getMachine.name) {
      setLoading(true);
      try {
        const { data: result } = await refetchMembersFast({
          q: queryKeyword ? queryKeyword : "",
          //machineId: location.state.machineId ? location.state.machineId : null
          machineId: location.state.machine ? location.state.machine : null,
          membershipStatus: membershipStatus,
        });

        if (!result || result.getAllMemberFast.members.length == 0) {
          alert("내보낼 회원이 없습니다.");
          setLoading(false);
          return;
        }

        const data = resultToData(result, membershipStatus, isUserBase);

        const ws = xlsx.utils.json_to_sheet(data);
        const wb = xlsx.utils.book_new();
        var wscols = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }];
        ws["!cols"] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, "회원목록");
        xlsx.writeFile(wb, getExportFileName(membershipStatus, isUserBase, machine.getMachine.name));
      } catch (e) {
        console.log("eeee", e);
        alert("오류가 발생하였습니다.");
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    }
  };

  const getExportFileName = (membershipStatus, isUserBase, machineName) => {
    if (isUserBase) {
      if (membershipStatus == STATUS_MEMBERSHIP_ALL) return `Ven-Brothers_전체회원목록_${machineName}.xlsx`;
      if (membershipStatus == STATUS_MEMBERSHIP_ACTIVE) return `Ven-Brothers_유효회원목록_${machineName}.xlsx`;
      if (membershipStatus == STATUS_MEMBERSHIP_EXPIRE) return `Ven-Brothers_만료회원목록_${machineName}.xlsx`;
    } else {
      if (membershipStatus == STATUS_MEMBERSHIP_ALL) return `Ven-Brothers_전체회원권목록_${machineName}.xlsx`;
      if (membershipStatus == STATUS_MEMBERSHIP_ACTIVE) return `Ven-Brothers_유효회원권목록_${machineName}.xlsx`;
      if (membershipStatus == STATUS_MEMBERSHIP_EXPIRE) return `Ven-Brothers_만료회원권목록_${machineName}.xlsx`;
    }
    return `Ven-Brothers_회원목록_${machineName}.xlsx`;
  };

  const importExcel = async (e) => {
    if (!loading) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      console.log("file", file);
      console.log("file.type", file.type);
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        const confirm = window.confirm("벤브라더스 무인 출입 시스템(Suprema)을 이용하는 경우, 일괄 추가 기능을 사용하지 마십시오.\n일괄 추가로 추가된 회원은 무인 출입 시스템과 연동되지 않습니다!");
        if (confirm) {
          setLoading(true);
          try {
            const formData = new FormData();
            formData.append("excel", file, file.name);
            const { data } = await axios.post(`${FETCH_URL}/api/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (data && data.location) {
              const { data: result } = await importMemberM({
                variables: {
                  machine: location.state.machine ? location.state.machine : "",
                  file: data.location,
                  machineId: location.state.machineId ? location.state.machineId : null,
                },
              });
              refetch({
                skip: 10 * (Number(queryString.parse(location.search).page) - 1),
                machine: location.state.machine,
                order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
                q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
                membershipType,
              });

              if (result && result.importMember.success) {
                alert("회원정보를 저장하였습니다.");
              } else {
                let msg = "오류가 발생하였습니다.";
                if (result.importMember.error) {
                  msg = result.importMember.error;
                }
                alert(msg);
              }

              setShowExcel(false);
            } else {
              setLoading(false);
              alert("오류가 발생하였습니다.");
            }
          } catch {
            alert("오류가 발생하였습니다.");
          } finally {
            if (excelRef.current) {
              excelRef.current.value = null;
            }
            setLoading(false);
          }
        }
      } else {
        alert("xlsx형식의 엑셀 파일만 사용하실 수 있습니다.");
      }
    }
  };

  const downloadExcelForm = () => {
    if (!loading) {
      setLoading(true);
      try {
        let data = [];
        data.push({
          회원명: "홍길동",
          "회원 연락처": `01012345678`,
          생년월일: "880917",
          성별: "남성",
          // '회원권 타입': '기간+횟수\n횟수',
          회원권: "헬스 1개월\nPT 20회",
          "회원권 시작일": "2022-06-01\n-",
          "회원권 종료일": "2022-07-01\n-",
          "회원권 횟수": "10\n5",
          "회원권 취소 횟수": "3\n1",
          "회원 주소 (시/도)": "경기",
          "회원 주소 (구/군)": "성남시 분당구",
          "회원 주소 (동)": "삼평동",
          "회원 주소 (나머지 주소)": "681 15층 102호",
          "운동 목적": "체중 감량",
          비고: "기초 체력이 좋음",
        });
        data.push({
          회원명: "임꺽정",
          "회원 연락처": `01098765432`,
          생년월일: null,
          성별: null,
          // '회원권 타입': '기간',
          회원권: "헬스 3개월",
          "회원권 시작일": "2022-06-01",
          "회원권 종료일": "2022-09-01",
          "회원권 횟수": "-",
          "회원권 취소 횟수": "-",
          "회원 주소 (시/도)": "인천",
          "회원 주소 (구/군)": "남동구",
          "회원 주소 (동)": "구월동",
          "회원 주소 (나머지 주소)": "1138 101동 102호",
          "운동 목적": null,
          비고: null,
        });
        const ws = xlsx.utils.json_to_sheet(data);
        const wb = xlsx.utils.book_new();
        var wscols = [{ wpx: 100 }, { wpx: 100 }, { wpx: 50 }, { wpx: 50 }, { wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 50 }, { wpx: 50 }, { wpx: 50 }, { wpx: 200 }, { wpx: 150 }, { wpx: 200 }];
        ws["!cols"] = wscols;
        xlsx.utils.book_append_sheet(wb, ws, "회원목록");
        xlsx.writeFile(wb, `Ven-Brothers_회원목록양식.xlsx`);
      } catch {
        alert("오류가 발생하였습니다.");
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteMembers = async () => {
    if (selectedMemberIds.length < 1) {
      alert("삭제하실 회원을 선택해주세요.");
      return;
    }

    const confirm = window.confirm("선택하신 회원을 삭제하시겠습니까?");

    if (confirm) {
      setLoading(true);

      const { data } = await deleteMembersM({
        variables: {
          machine: location.state.machine ? location.state.machine : "",
          memberIds: selectedMemberIds,
          machineId: location.state.machineId ? location.state.machineId : null,
        },
      });

      setLoading(false);
      if (data && data.deleteMembers) {
        alert("회원을 삭제하였습니다.");

        selectedMemberIds.forEach((id) => {
          cache.evict({
            id: `Member:${id}`,
          });
        });

        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
          membershipType,
        });
      } else {
        alert("오류가 발생하였습니다.");
      }
    }
  };

  const remove = async (member) => {
    if (!loading) {
      if (member) {
        const confirm = window.confirm("정말 삭제하시겠습니까?");
        if (confirm) {
          setLoading(true);
          const { data } = await deleteMemberM({
            variables: {
              machine: location.state.machine ? location.state.machine : "",
              memberId: member.id,
              machineId: location.state.machineId ? location.state.machineId : null,
            },
          });
          setLoading(false);
          if (data && data.deleteMember) {
            alert("회원을 삭제하였습니다.");
            cache.evict({
              id: `Member:${member.id}`,
            });
          } else {
            alert("오류가 발생하였습니다.");
          }
        }
      }
    }
  };

  const controlEndDate = (arr) => {
    let tmpArr = JSON.parse(JSON.stringify(arr));
    let now = new Date();
    const tmpNow = new Date(now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate());
    let today = now.getUTCFullYear();
    today += "-";
    today += now.getUTCMonth() + 1 < 10 ? "0" + (now.getUTCMonth() + 1) : now.getUTCMonth() + 1;
    today += "-";
    today += now.getUTCDate() < 10 ? "0" + now.getUTCDate() : now.getUTCDate();
    for (let i = 0; i < tmpArr.length; i++) {
      for (let j = 0; j < tmpArr[i].memberships.length; j++) {
        if ([2, 3].indexOf(tmpArr[i].memberships[j].product.membershipType) > -1) {
          let lastScheduleEnd = new Date(tmpArr[i].memberships[j].lastreservationend);
          if (tmpArr[i].memberships[j].lastreservationend && tmpArr[i].memberships[j].lastreservationend >= tmpArr[i].memberships[j].end) {
            tmpArr[i].memberships[j].end = lastScheduleEnd.getTime();
          }
          if (tmpArr[i].memberships[j].lastreservationend && tmpArr[i].memberships[j].lastreservationend >= today) {
            tmpArr[i].memberships[j].status = "active";
            let tmpDDay = lastScheduleEnd - tmpNow;
            tmpArr[i].memberships[j].dDay = Math.floor(tmpDDay / (1000 * 60 * 60 * 24));
          }
        }
      }
    }
    return tmpArr;
  };

  useEffect(() => {
    duration.setValue("");
  }, [showModal]);

  useEffect(async () => {
    setSelectedMemberIds([]);
    let index = 0;
    let fristActiveIndex = 0;
    let firstHoldingIndex = 0;
    if (data?.getMembers?.members && data?.getMembers?.members != loadData) {
      let controledMembers = controlEndDate(data?.getMembers?.members);
      let tmpMembers = JSON.parse(JSON.stringify(controledMembers));
      for (let i = 0; i < tmpMembers.length; i++) {
        index = 0;
        fristActiveIndex = 0;
        firstHoldingIndex = 0;
        if (tmpMembers[i].memberships.length > 0) {
          for (let j = tmpMembers[i].memberships.length - 1; 0 <= j; j--) {
            if (tmpMembers[i].memberships[j].status === "active") {
              fristActiveIndex = j;
            } else if (tmpMembers[i].memberships[j].status === "holding") {
              firstHoldingIndex = j;
            }

            if (tmpMembers[i].defaultMembershipId === tmpMembers[i].memberships[j].id) {
              index = j;
            }
          }
          if (tmpMembers[i].memberships[index].status !== "active" && fristActiveIndex !== null) {
            if (firstHoldingIndex > 0 && fristActiveIndex === 0) {
              index = firstHoldingIndex;
            } else {
              index = fristActiveIndex;
            }
          }

          tmpMembers[i].defaultMembership = JSON.parse(JSON.stringify(tmpMembers[i].memberships[index]));
        }
      }

      setLoadData([...tmpMembers]);
    } else if (data?.getMembers?.members && data?.getMembers?.members.length === 0) {
      let controledMembers = controlEndDate(data?.getMembers?.members);
      let tmpMembers = JSON.parse(JSON.stringify(controledMembers));
      for (let i = 0; i < tmpMembers.length; i++) {
        index = 0;
        fristActiveIndex = 0;
        firstHoldingIndex = 0;
        if (tmpMembers[i].memberships.length > 0) {
          for (let j = tmpMembers[i].memberships.length - 1; 0 <= j; j--) {
            if (tmpMembers[i].memberships[j].status === "active") {
              fristActiveIndex = j;
            } else if (tmpMembers[i].memberships[j].status === "holding") {
              firstHoldingIndex = j;
            }

            if (tmpMembers[i].defaultMembershipId === tmpMembers[i].memberships[j].id) {
              index = j;
            }
          }
          if (tmpMembers[i].memberships[index].status !== "active" && fristActiveIndex !== null) {
            if (firstHoldingIndex > 0 && fristActiveIndex === 0) {
              index = firstHoldingIndex;
            } else {
              index = fristActiveIndex;
            }
          }

          tmpMembers[i].defaultMembership = JSON.parse(JSON.stringify(tmpMembers[i].memberships[index]));
        }
      }

      setLoadData([...tmpMembers]);
    }
    if (!memberCntData) {
      const { data: _memberCntData } = await memberCntDataRefetch({
        machine: location.state.machine ? location.state.machine : "",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        machineId: location.state.machineId ? location.state.machineId : null,
      });

      if (_memberCntData) {
        setMemberCntData(_memberCntData);
      }
    }
  }, [data]);

  useEffect(() => {
    let selectedAll = false;
    if (data && data.getMembers) {
      selectedAll = selectedMemberIds.length > 0 && selectedMemberIds.length === data.getMembers.members.length;
    }
    setSelectedAllMembers(selectedAll);
  }, [selectedMemberIds]);

  useEffect(async () => {
    if (location.state.machine) {
      if (queryString.parse(location.search).page && !isNaN(Number(queryString.parse(location.search).page))) {
        window.scrollTo(0, 0);
        refetch({
          skip: 10 * (Number(queryString.parse(location.search).page) - 1),
          machine: location.state.machine,
          order: queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc",
          q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
          membershipType,
        });
      } else {
        setLoadData(false);
        history.replace({
          pathname: `/member/list/`,
          state: location.state,
          search: `?order=${queryString.parse(location.search).order ? queryString.parse(location.search).order : "name_asc"}&page=1&q=${queryString.parse(location.search).q ? queryString.parse(location.search).q : ""}`,
        });
      }
      const { data: _memberCntData } = await memberCntDataRefetch({
        machine: location.state.machine ? location.state.machine : "",
        q: queryString.parse(location.search).q ? queryString.parse(location.search).q : "",
        machineId: location.state.machineId ? location.state.machineId : null,
      });

      if (_memberCntData) {
        setMemberCntData(_memberCntData);
      }
    } else {
      alert("잘못된 접근입니다");
      history.goBack();
    }
  }, [queryString.parse(location.search).page, queryString.parse(location.search).order, queryString.parse(location.search).q, membershipType]);

  return (
    <div className={`${styles.minHeightFull} ${styles.wrapper} ${styles.bgGrayEf} ${styles.center}`}>
      <div className={`${styles.safearea}`}>
        <div className={`${styles.containerLoggedIn} ${styles.px30} ${styles.py30}`}>
          <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow}`}>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
              <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>{`회원 현황${machine && machine.getMachine ? ` (${machine.getMachine.name})` : ""}`}</p>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
                <form action={""} onSubmit={submit}>
                  <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.px20} ${styles.py10}`} style={{ width: 480 }}>
                    <img src={require("../../../assets/images/icon_search.png").default} alt={"search"} className={`${styles.icon25} ${styles.cursorPointer}`} onClick={submit} />
                    <input type={"text"} name={"q"} value={q.value} onChange={q.onChange} className={`${styles.inputWhite} ${styles.flex1} ${styles.px20}`} placeholder={"회원명, 연락처 및 회원권으로 검색"} />
                  </div>
                </form>
                <div
                  className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                  onClick={() =>
                    history.push({
                      pathname: `/member/add/`,
                      state: {
                        machine: location.state.machine ? location.state.machine : "",
                        machineId: location.state.machineId ? location.state.machineId : null,
                      },
                    })
                  }
                >
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>회원 등록</p>
                </div>
                <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setShowModal(true)}>
                  <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>회원권 일괄 연장</p>
                </div>
                {profileData && profileData.getProfileAdmin.superuser ? (
                  <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={() => setShowExcel(true)}>
                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>내보내기/불러오기</p>
                  </div>
                ) : (
                  <div
                    className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`}
                    // onClick={exportExcel}
                    onClick={() => setShowExcel(true)}
                  >
                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>회원 현황 내보내기</p>
                  </div>
                )}
                {profileData && profileData.getProfileAdmin.superuser && (
                  <div className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.px35} ${styles.py7} ${styles.cursorPointer} ${styles.ml10}`} onClick={deleteMembers}>
                    <p className={`${styles.fontB} ${styles.font16} ${styles.white}`}>회원 삭제</p>
                  </div>
                )}
                <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.ml30}`} onClick={history.goBack}>
                  <img
                    src={require("../../../assets/images/icon_back.png").default}
                    alt={"이전"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 45 * 0.2,
                      height: 79 * 0.2,
                    }}
                  />
                  <p className={`${styles.fontB} ${styles.font16} ${styles.black} ${styles.ml10}`}>뒤로가기</p>
                </div>
              </div>
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentEnd} ${styles.alignItemsCenter} ${styles.mr10}`} style={{ height: 45 }}>
              유효 회원 : {memberCntData && memberCntData.getValidExpiredMemberCnt ? numberWithCommas(memberCntData.getValidExpiredMemberCnt.validMemberCnt) : "-"}&nbsp;/&nbsp; 만료 회원 :{" "}
              {memberCntData && memberCntData.getValidExpiredMemberCnt ? numberWithCommas(memberCntData.getValidExpiredMemberCnt.expiredMemberCnt) : "-"}
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.py15} ${styles.bgGrayF4}`}>
              {profileData && profileData.getProfileAdmin && profileData.getProfileAdmin.superuser && (
                <div
                  className={`${styles.ml10} ${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20} ${styles.cursorPointer}`}
                  onClick={() => {
                    let _selectedMemberIds = [];
                    if (!selectedAllMembers) {
                      loadData.forEach((item) => {
                        _selectedMemberIds.push(item.id);
                      });
                    }

                    setSelectedMemberIds(_selectedMemberIds);
                  }}
                >
                  {selectedAllMembers && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}
                </div>
              )}
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("machine")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>기기명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "machine_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "machine_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{ width: 18 * 0.5, height: 10 * 0.5 }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("name")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원명</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "name_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "name_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("contact")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원 연락처</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "contact_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "contact_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex2} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권</p>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("membership_start")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 시작일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "membership_start_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "membership_start_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("membership_end")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원권 종료일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "membership_end_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "membership_end_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("membership_membershipCount")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>횟수</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "membership_membershipCount_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "membership_membershipCount_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => setShowTypeModal(true)}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>
                  {membershipType === "all" && `회원권 상태(전체)`}
                  {membershipType === "active" && `회원권 상태(유효)`}
                  {membershipType === "expired" && `회원권 상태(만료)`}
                  {membershipType === "ready" && `회원권 상태(대기)`}
                  {membershipType === "holding" && `회원권 상태(홀딩중)`}
                  {membershipType === "inactive" && `회원권 상태(비활성)`}
                </p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.cursorPointer}`} onClick={() => handleOrder("date")}>
                <p className={`${styles.fontB} ${styles.font14} ${styles.black} ${styles.textCenter}`}>회원 등록일</p>
                <div className={`${styles.ml10} ${styles.center}`}>
                  <img
                    src={queryString.parse(location.search).order === "date_asc" ? require("../../../assets/images/icon_sort_up_orange.png").default : require("../../../assets/images/icon_sort_up.png").default}
                    alt={"asc"}
                    className={`${styles.cursorPointer}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                  <img
                    src={queryString.parse(location.search).order === "date_dsc" ? require("../../../assets/images/icon_sort_down_orange.png").default : require("../../../assets/images/icon_sort_down.png").default}
                    alt={"dsc"}
                    className={`${styles.cursorPointer} ${styles.mt5}`}
                    style={{
                      width: 18 * 0.5,
                      height: 10 * 0.5,
                    }}
                  />
                </div>
              </div>
              <div className={`${styles.flex1}`}></div>
            </div>
            <div style={{ minHeight: 600 }}>
              {!loadData ? (
                <Skeleton height={50} count={3} />
              ) : loadData && loadData.length > 0 ? (
                loadData.map((member) => {
                  return (
                    <Member
                      key={member.id}
                      member={member}
                      machine={machine && machine.getMachine ? machine.getMachine : {}}
                      selectedMemberIds={selectedMemberIds}
                      setSelectedMemberIds={setSelectedMemberIds}
                      showCheckbox={profileData && profileData.getProfileAdmin && profileData.getProfileAdmin.superuser}
                      showOption={true}
                      remove={() => remove(member)}
                    />
                  );
                })
              ) : (
                <div className={`${styles.center}`} style={{ height: 600 }}>
                  <p className={`${styles.fontM} ${styles.font15} ${styles.black} ${styles.textCenter}`}>등록된 회원이 없습니다.</p>
                </div>
              )}
              {data?.getMembers?.total > 10 ? (
                <div className={`${styles.mt60} ${styles.mb10}`} style={{ position: "relative" }}>
                  <ReactPaginate
                    previousLabel={
                      <img
                        src={require("../../../assets/images/icon_back.png").default}
                        alt={"이전"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    nextLabel={
                      <img
                        src={require("../../../assets/images/icon_front.png").default}
                        alt={"다음"}
                        className={`${styles.cursorPointer}`}
                        style={{
                          width: 45 * 0.2,
                          height: 79 * 0.2,
                        }}
                      />
                    }
                    breakLabel={<p className={`${styles.fontM} ${styles.font16} ${styles.black}`}>&#183;&#183;&#183;</p>}
                    breakClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    breakLinkClassName={`${styles.icon30} ${styles.center}`}
                    pageClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    pageLinkClassName={`${styles.icon30} ${styles.center} ${styles.font16} ${styles.black}`}
                    activeClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer}`}
                    activeLinkClassName={`${styles.icon30} ${styles.center} ${styles.fontB} ${styles.font16} ${styles.black}`}
                    previousClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    previousLinkClassName={`${styles.icon30} ${styles.center}`}
                    nextClassName={`${styles.icon30} ${styles.center} ${styles.cursorPointer} ${styles.bgTransparent}`}
                    nextLinkClassName={`${styles.icon30} ${styles.center}`}
                    disabledClassName={`${styles.icon30} ${styles.center}`}
                    pageCount={data?.getMembers?.total ? parseInt((data?.getMembers?.total - 1) / 10) + 1 : 1}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={4}
                    onPageChange={loadingMember ? null : handlePage}
                    initialPage={queryString.parse(location.search).page ? Number(queryString.parse(location.search).page) - 1 : 0}
                    containerClassName={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter} ${styles.my2}`}
                  />
                  {!loadData && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(255, 255, 255, 0.4)",
                      }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={bill}>
        <div>
          <p className={`${styles.fontB} ${styles.font20} ${styles.black} ${styles.textCenter}`}>연장 일수</p>
          <div className={`${styles.flex1} ${styles.mt20}`}>
            <NumberFormat
              value={duration.value}
              thousandSeparator={true}
              className={`${styles.inputWhite} ${styles.widthFull} ${styles.px15} ${styles.py10} ${styles.bgGrayEf}`}
              type={"tel"}
              displayType={"input"}
              onValueChange={handleDuration}
              placeholder={"연장할 일 수를 입력해주세요.(숫자만 입력)"}
            />
            <div className={`${styles.center} ${styles.mt15}`}>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setType("active")}>
                <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>{type === "active" && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}</div>
                <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>현재 유효한 회원권만 적용</p>
              </div>
              <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer} ${styles.mt10}`} onClick={() => setType("ready")}>
                <div className={`${styles.bgWhite} ${styles.borderGrayA2} ${styles.borderRadius5} ${styles.center} ${styles.icon20}`}>{type === "ready" && <div className={`${styles.bgOrange} ${styles.borderRadius5}`} style={{ width: 14, height: 14 }} />}</div>
                <p className={`${styles.fonrR} ${styles.font14} ${styles.black} ${styles.ml10}`}>미래 회원권까지 모두 적용</p>
              </div>
            </div>
          </div>
          <div className={`${styles.mt30} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={extend}>
            <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>연장</p>
          </div>
        </div>
      </Modal>
      <Modal isOpen={showExcel} onRequestClose={() => setShowExcel(false)} style={bill}>
        <div>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}>
            <div className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.ml10} ${styles.mr20}`}>전체 회원 목록 내보내기</div>
            <div className={`${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => exportExcel("all", USER_BASE)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원 기준</p>
            </div>
            <div className={`${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} `} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => exportExcel("all", MEMBERSHIP_BASE)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원권 기준</p>
            </div>

            <div className={`${styles.mt10} ${styles.fontR} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.ml10} ${styles.mr20}`}>유효 회원 목록 내보내기</div>
            <div className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} ${styles.mr10}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => exportExcel("active", USER_BASE)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원 기준</p>
            </div>
            <div className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} `} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => exportExcel("active", MEMBERSHIP_BASE)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원권 기준</p>
            </div>

            <div className={`${styles.mt10} ${styles.fontR} ${styles.font16} ${styles.black} ${styles.textCenter} ${styles.ml10} ${styles.mr20}`}>만료 회원 목록 내보내기</div>
            <div
              className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} ${styles.mr10}`}
              style={{ opacity: loading ? 0.4 : 1 }}
              onClick={() => exportExcel("expired", USER_BASE)}
            >
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원 기준</p>
            </div>
            <div className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px35} ${styles.btnShadow} ${styles.width45} ${styles.cursorPointer} `} style={{ opacity: loading ? 0.4 : 1 }} onClick={() => exportExcel("expired", MEMBERSHIP_BASE)}>
              <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원권 기준</p>
            </div>
          </div>

          {profileData && profileData.getProfileAdmin.superuser && (
            <div>
              <label htmlFor="excel">
                <div className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }}>
                  <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>회원 목록 불러오기</p>
                </div>
              </label>
              <div className={`${styles.mt10} ${styles.bgBlack} ${styles.borderRadius5} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} style={{ opacity: loading ? 0.4 : 1 }} onClick={downloadExcelForm}>
                <p className={`${styles.fontR} ${styles.font16} ${styles.grayEf} ${styles.textCenter}`}>불러오기 양식 다운</p>
              </div>
              <input ref={excelRef} id={"excel"} className={`${styles.none}`} type={"file"} accept={".xlsx"} onChange={importExcel} />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={showTypeModal}
        onRequestClose={() => setShowTypeModal(false)}
        style={{
          content: {
            ...bill.content,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
          overlay: {
            ...bill.overlay,
          },
        }}
      >
        <div>
          <div className={`${membershipType === "all" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("all")}>
            <p className={`${membershipType === "all" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "all" ? styles.orange : styles.black} ${styles.textCenter}`}>전체</p>
          </div>
          <div className={`${membershipType === "active" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("active")}>
            <p className={`${membershipType === "active" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "active" ? styles.orange : styles.black} ${styles.textCenter}`}>유효</p>
          </div>
          <div className={`${membershipType === "expired" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("expired")}>
            <p className={`${membershipType === "expired" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "expired" ? styles.orange : styles.black} ${styles.textCenter}`}>만료</p>
          </div>
          <div className={`${membershipType === "ready" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("ready")}>
            <p className={`${membershipType === "ready" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "ready" ? styles.orange : styles.black} ${styles.textCenter}`}>대기</p>
          </div>
          <div className={`${membershipType === "holding" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("holding")}>
            <p className={`${membershipType === "holding" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "holding" ? styles.orange : styles.black} ${styles.textCenter}`}>홀딩중</p>
          </div>
          <div className={`${membershipType === "inactive" ? styles.bgGrayEf : styles.bgWhite} ${styles.borderBottomGrayF4} ${styles.center} ${styles.py10} ${styles.px20} ${styles.btnShadow} ${styles.widthFull} ${styles.cursorPointer}`} onClick={() => handleMembershipType("inactive")}>
            <p className={`${membershipType === "inactive" ? styles.fontB : styles.fontR} ${styles.font16} ${membershipType === "inactive" ? styles.orange : styles.black} ${styles.textCenter}`}>비활성</p>
          </div>
        </div>
      </Modal>
      {isLoading && (
        <div className={`${styles.widthFull} ${styles.heightFull}`} style={{ position: "fixed", display: "flex", top: 0, left: 0, zIndex: 999, justifyContent: "center", pointerEvents: "none" }}>
          <div style={{ position: "absolute", display: "flex", top: "50%", marginTop: "-75px", width: 150, height: 150, backgroundColor: "white", justifyContent: "center" }}>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img style={{ width: 100, height: 100 }} src={Loading} />
              </div>
              <p align="center" className={`${styles.font12}`}>
                다운로드 준비중입니다.
              </p>
              <p align="center" className={`${styles.font12}`}>
                잠시만 기다려주세요.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberList;
