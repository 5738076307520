import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Calendar from 'react-calendar';

import styles from '../../../styles/styles.module.scss';
import {bill, billSm} from '../../../common/modal';
import useInput from '../../../hooks/useInput';

const MembershipHoldingItem = ({
    holding: defaultHolding,
    index,
    remove,
    edit,
}) => {
    const reason = useInput(defaultHolding.reason ? defaultHolding.reason : '');

    const [holding, setHolding] = useState({
        ...defaultHolding,
        start: defaultHolding.start
            ? new Date(Number(defaultHolding.start))
            : null,
        end: defaultHolding.end ? new Date(Number(defaultHolding.end)) : null,
    });
    const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [editing, setEditing] = useState(false);

    const [showOption, setShowOption] = useState(false);

    const handleStart = start => {
        setHolding({
            ...holding,
            start,
        });
        setShowStart(false);
    };

    const handleEnd = end => {
        setHolding({
            ...holding,
            end,
        });
        setShowEnd(false);
    };

    const editHolding = async () => {
        if (holding.start && holding.end) {
            await edit({
                ...holding,
                reason: reason.value,
            });
            setEditing(false);
        } else {
            alert('시작일과 종료일을 선택해주세요.');
        }
    };

    useEffect(() => {
        setHolding({
            ...defaultHolding,
            start: defaultHolding.start
                ? new Date(Number(defaultHolding.start))
                : null,
            end: defaultHolding.end
                ? new Date(Number(defaultHolding.end))
                : null,
        });
        reason.setValue(defaultHolding.reason ? defaultHolding.reason : '');
    }, [editing, defaultHolding]);

    return (
        <div
            className={`${styles.row} ${styles.mx0} ${
                styles.alignItemsCenter
            } ${styles.justifyContentBetween} ${styles.py15} ${styles.px15} ${
                index % 2 === 0 ? styles.bgWhite : styles.bgGrayEf
            }`}>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter}`}
                onClick={() => setShowOption(!showOption)}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                    {index + 1}
                </p>
            </div>
            <div
                className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`}
                onClick={() => setShowOption(!showOption)}>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                    {holding.start
                        ? `${holding.start.getFullYear()}/${
                              holding.start.getMonth() + 1 < 10
                                  ? '0' + String(holding.start.getMonth() + 1)
                                  : holding.start.getMonth() + 1
                          }/${
                              holding.start.getDate() < 10
                                  ? '0' + String(holding.start.getDate())
                                  : holding.start.getDate()
                          }`
                        : ''}
                </p>
                <p
                    className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}
                    onClick={() => setShowOption(!showOption)}>
                    {holding.end
                        ? `${holding.end.getFullYear()}/${
                            holding.end.getMonth() + 1 < 10
                                ? '0' + String(holding.end.getMonth() + 1)
                                : holding.end.getMonth() + 1
                        }/${
                            holding.end.getDate() < 10
                                ? '0' + String(holding.end.getDate())
                                : holding.end.getDate()
                        }`
                        : ''}
                </p>
            </div>
            <p
                className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.flex1}`}>
                {holding.status === 'active' && '홀딩중'}
                {holding.status === 'ready' && '홀딩예정'}
                {holding.status === 'expired' && '종료'}
            </p>
            {/*{editing ? (*/}
            {/*    <div*/}
            {/*        className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>*/}
            {/*        <p*/}
            {/*            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}*/}
            {/*            onClick={editHolding}>*/}
            {/*            저장*/}
            {/*        </p>*/}
            {/*        <p*/}
            {/*            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}*/}
            {/*            onClick={() => setEditing(false)}>*/}
            {/*            취소*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*) : (*/}
            {/*    <div*/}
            {/*        className={`${styles.flex1} ${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentCenter}`}>*/}
            {/*        <p*/}
            {/*            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer}`}*/}
            {/*            onClick={() => setEditing(true)}>*/}
            {/*            수정*/}
            {/*        </p>*/}
            {/*        <p*/}
            {/*            className={`${styles.fontR} ${styles.font14} ${styles.black} ${styles.textCenter} ${styles.underline} ${styles.cursorPointer} ${styles.ml10}`}*/}
            {/*            onClick={remove}>*/}
            {/*            삭제*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*)}*/}

            <Modal
                isOpen={showOption}
                onRequestClose={() => setShowOption(false)}
                style={billSm}>
                <div style={{ textAlign: "right" }}>
                    <img
                        src={
                            require('../../../assets/images/icon_close_menu.png')
                                .default
                        }
                        alt={'CLOSE'}
                        className={`${styles.cursorPointer} ${styles.icon20}`}
                        onClick={() => setShowOption(false)}
                    />
                </div>
                <div style={{ display: "flex", flexFlow: "column", gap: 10, marginTop: 10 }}>
                    <div style={{ width: "100%", display: "flex", gap: 10 }}>
                        <button
                            type={'button'}
                            className={`${styles.bgBlack} ${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`}
                            onClick={() => setEditing(true)}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                수정
                            </p>
                        </button>
                        <button
                            type={'button'}
                            className={`${styles.bgBlack} ${styles.py15} ${styles.widthFull} ${styles.center} ${styles.cursorPointer} ${styles.btnShadow}`}
                            onClick={remove}>
                            <p
                                className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                                삭제
                            </p>
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={editing}
                onRequestClose={() => setEditing(false)}
                style={billSm}>
                <div style={{ textAlign: "right" }}>
                    <img
                        src={
                            require('../../../assets/images/icon_close_menu.png')
                                .default
                        }
                        alt={'CLOSE'}
                        className={`${styles.cursorPointer} ${styles.icon20}`}
                        onClick={() => setEditing(false)}
                    />
                </div>
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}
                    onClick={() => setShowStart(true)}>
                    <div
                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                        style={{ width: 110 }}>
                        <p
                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                            시작일
                        </p>
                    </div>
                    <input
                        type={'text'}
                        value={
                            holding.start ?
                                holding.start.getFullYear()
                                + "/" + (holding.start.getMonth() + 1).toString().padStart(2, "0")
                                + "/" + holding.start.getDate().toString().padStart(2, "0") : ""
                        }
                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                        style={{ minWidth: 20 }}
                        placeholder={
                            '시작일을 선택해주세요.'
                        }
                        readOnly={true}
                    />
                    <img
                        src={require('../../../assets/images/icon_dropdown_down.png').default}
                        alt={'시작일 선택'}
                        className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`}
                        style={{ alignSelf: "center" }}
                    />
                </div>
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}
                    onClick={() => setShowEnd(true)}>
                    <div
                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                        style={{ width: 110 }}>
                        <p
                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                            종료일
                        </p>
                    </div>
                    <input
                        type={'text'}
                        value={
                            holding.end ?
                                holding.end.getFullYear()
                                + "/" + (holding.end.getMonth() + 1).toString().padStart(2, "0")
                                + "/" + holding.end.getDate().toString().padStart(2, "0") : ""
                        }
                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15} `}
                        style={{ minWidth: 20 }}
                        placeholder={
                            '종료일을 선택해주세요.'
                        }
                        readOnly={true}
                    />
                    <img
                        src={require('../../../assets/images/icon_dropdown_down.png').default}
                        alt={'종료일 선택'}
                        className={`${styles.icon15} ${styles.ml5} ${styles.mr10}`}
                        style={{ alignSelf: "center" }}
                    />
                </div>
                <div
                    className={`${styles.row} ${styles.mx0} ${styles.alignItemsStretch} ${styles.bgWhite} ${styles.borderRadius10} ${styles.borderGrayA2} ${styles.overflowHidden} ${styles.mt10}`}
                >
                    <div
                        className={`${styles.borderRightGrayA2} ${styles.pl15} ${styles.py10}`}
                        style={{ width: 110 }}>
                        <p
                            className={`${styles.fontM} ${styles.font14} ${styles.black}`}>
                            사유
                        </p>
                    </div>
                    <input
                        type={'text'}
                        name={'reason'}
                        value={reason.value}
                        onChange={reason.onChange}
                        className={`${styles.inputWhite} ${styles.flex1} ${styles.px15}`}
                        placeholder={'입력해주세요. (선택사항)'}
                        style={{
                            width: '100%',
                            minWidth: 20,
                        }}
                    />
                </div>
                <div className={`${styles.mt20}`}>
                    <button
                        type={'button'}
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer}`}
                        onClick={editHolding}>
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            저장
                        </p>
                    </button>
                    <div
                        className={`${styles.bgBlack} ${styles.borderRadiusRound} ${styles.widthFull} ${styles.py15} ${styles.center} ${styles.cursorPointer} ${styles.mt10}`}
                        onClick={() => setEditing(false)}>
                        <p
                            className={`${styles.fontB} ${styles.font14} ${styles.white}`}>
                            취소
                        </p>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showStart}
                onRequestClose={() => setShowStart(false)}
                style={billSm}>
                <Calendar
                    calendarType={'US'}
                    value={holding.start}
                    maxDate={holding.end ? holding.end : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleStart}
                />
            </Modal>
            <Modal
                isOpen={showEnd}
                onRequestClose={() => setShowEnd(false)}
                style={billSm}>
                <Calendar
                    calendarType={'US'}
                    value={holding.end}
                    minDate={holding.start ? holding.start : null}
                    minDetail={'month'}
                    nextLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_right.png')
                                        .default
                                }
                                alt={'다음'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    next2Label={null}
                    prevLabel={
                        <span>
                            <img
                                src={
                                    require('../../../assets/images/icon_dropdown_left.png')
                                        .default
                                }
                                alt={'이전'}
                                className={`${styles.icon15}`}
                            />
                        </span>
                    }
                    prev2Label={null}
                    navigationLabel={({ label }) => (
                        <p
                            className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>
                            {label}
                        </p>
                    )}
                    tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
                    onChange={handleEnd}
                />
            </Modal>
        </div>
    );
};

export default MembershipHoldingItem;
