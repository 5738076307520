import React, { useState, useEffect, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import { ResponsiveLine } from "@nivo/line";

import styles from "../../styles/styles.module.scss";
import { colors, colors2, colors3 } from "../../styles/colors";
import { numberWithCommas } from "../../common/utility";

const DATA = gql`
  query getTotalSaleGraph($date: String!) {
    getTotalSaleGraph(date: $date) {
      id
      sum
      data {
        x
        y
      }
    }
  }
`;

const TotalSaleGraph = () => {
  const location = useLocation();

  const timestamp = useRef(new Date().getTime());

  const [date, setDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [data, setData] = useState(null);

  const { refetch } = useQuery(DATA, {
    skip: true,
  });

  const getData = async () => {
    setData(null);
    const now = new Date().getTime();
    timestamp.current = now;
    const { data } = await refetch({
      date: `${String(date.getFullYear()).slice(2, 4)}${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`,
    });
    if (timestamp.current === now) {
      if (data.getTotalSaleGraph) {
        setData(data.getTotalSaleGraph);
      } else {
        setData(null);
      }
    }
  };

  useEffect(() => {
    setShowDate(false);
    getData();
  }, [date]);

  useEffect(() => {
    setShowDate(false);
  }, [queryString.parse(location.search).page]);

  return (
    <div className={`${styles.bgWhite} ${styles.borderRadius10} ${styles.px50} ${styles.py50} ${styles.btnShadow} ${styles.mr10} ${styles.flex7}`}>
      <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.justifyContentBetween} ${styles.px10}`}>
        <p className={`${styles.fontB} ${styles.font20} ${styles.black}`}>기기별 통합 매출 (현장 상품 + 주문 상품 + 회원권 + 락커 + 자유 결제 + 현금 결제)</p>
        <div className={`${styles.ml50}`}>
          <div className={`${styles.row} ${styles.mx0} ${styles.alignItemsCenter} ${styles.cursorPointer}`} onClick={() => setShowDate(!showDate)}>
            <p className={`${styles.fontB} ${styles.font18} ${styles.black}`}>{`${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + String(date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? "0" + String(date.getDate()) : date.getDate()}`}</p>
            <img src={showDate ? require("../../assets/images/icon_dropdown_up.png").default : require("../../assets/images/icon_dropdown_down.png").default} alt={"날짜 선택"} className={`${styles.icon15} ${styles.mt5} ${styles.ml5}`} />
          </div>
        </div>
      </div>
      {showDate && (
        <div className={`${styles.row} ${styles.mx0} ${styles.justifyContentEnd}`}>
          <div className={`${styles.bgWhite} ${styles.dropdown} ${styles.px20} ${styles.py20} ${styles.overflowHidden}`} style={{ width: 400 }}>
            <Calendar
              calendarType={"US"}
              value={date}
              maxDate={new Date()}
              minDate={new Date(2021, 0, 1)}
              minDetail={"month"}
              nextLabel={
                <span>
                  <img src={require("../../assets/images/icon_dropdown_right.png").default} alt={"다음"} className={`${styles.icon15}`} />
                </span>
              }
              next2Label={null}
              prevLabel={
                <span>
                  <img src={require("../../assets/images/icon_dropdown_left.png").default} alt={"이전"} className={`${styles.icon15}`} />
                </span>
              }
              prev2Label={null}
              navigationLabel={({ label }) => <p className={`${styles.fontR} ${styles.font16} ${styles.black} ${styles.px60}`}>{label}</p>}
              tileClassName={`${styles.fontR} ${styles.font16} ${styles.py10}`}
              onChange={setDate}
            />
          </div>
        </div>
      )}
      <div className={`${styles.mt50}`}>
        {data ? (
          <div>
            <div style={{ height: 250 }}>
              <ResponsiveLine
                data={data}
                colors={data.length === 2 ? colors2 : data.length === 3 ? colors3 : colors}
                margin={{
                  top: 10,
                  right: 80,
                  bottom: 50,
                  left: 80,
                }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat={" >-,.2d"}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "시",
                  legendOffset: 30,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "원",
                  legendOffset: -60,
                  legendPosition: "middle",
                }}
                pointSize={4}
                pointColor={{ from: "color", modifiers: [] }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                enableGridX={false}
              />
            </div>
            <div className={`${styles.row} ${styles.mx0} ${styles.mt20} ${styles.px80} ${styles.flexWrap}`}>
              {data.map((item, index) => {
                const color = data.length === 2 ? colors2[index] : data.length === 3 ? colors3[index] : colors[index];
                return (
                  <div key={item.id} className={`${styles.row} ${styles.mx0} ${styles.mr15}`}>
                    <div
                      style={{
                        width: 6,
                        height: 6,
                        borderRadius: 3,
                        marginTop: 7,
                        backgroundColor: color,
                      }}
                    />
                    <div className={`${styles.ml10}`}>
                      <p className={`${styles.fontB} ${styles.font13}`} style={{ color }}>
                        {item.id}
                      </p>
                      <p className={`${styles.fontB} ${styles.font13}`} style={{ color }}>
                        {numberWithCommas(item.sum)}원
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Skeleton height={250} />
        )}
      </div>
    </div>
  );
};

export default TotalSaleGraph;
